import React from 'react';
import { Client_client_children_goals } from '../../../../../graphql/queries/types/Client';
import GoalCard from '../../Goal/GoalCard';

interface Props {
  productType: string;
  goals: Array<Client_client_children_goals>;
  handleGoalModalOpen: (id: string, step: string) => void;
}

const GoalTab = ({ productType, goals, handleGoalModalOpen }: Props) => {
  const reverseGoal = [...goals].reverse();

  return (
    <div style={{ paddingBottom: '20px' }}>
      <GoalCard
        productType={productType}
        goals={reverseGoal}
        handleGoalModalOpen={handleGoalModalOpen}
        height={120}
        line={1}
        marginBottom={15}
        marginRight={0}
        step={'two'}
      />
    </div>
  );
};
export default GoalTab;
