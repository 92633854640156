import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../common/Header';
import { TherapistContext } from './TherapistContextt';
import MyTasksComponent from './MyTasks';
import MyClientsComponent from './MyClients';
import AssistantInteractionComponent from './Communication/AssistantInteractionComponent';
import { MenuLink, Container } from './styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReturnMessageComponent from '../common/ReturnMessageComponent';

const TherapistAppComponent = () => {
  const { returnMessage, therapist } = useContext(TherapistContext);

  const { t } = useTranslation();

  return (
    <div>
      <Header />
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <Container>
        <div style={{ display: 'flex' }}>
          <MenuLink data-cy="myTasksButton" to={'/tasks'}>
            {t('therapist.dashboard.topbar.myTasks')}
          </MenuLink>
          <MenuLink data-cy="myClientsButton" to={'/clients'}>
            {t('therapist.dashboard.topbar.myClients')}
          </MenuLink>
          <MenuLink data-cy="myAssistantsButton" to={'/assistants'}>
            {t('therapist.dashboard.topbar.myAssistants')}
          </MenuLink>
        </div>
        <div style={{ margin: '20px 0' }}>
          <Switch>
            <Route exact path={'/tasks'}>
              <MyTasksComponent therapistId={therapist.id} />
            </Route>
            <Route exact path={'/clients'}>
              <MyClientsComponent therapistId={therapist.id} />
            </Route>
            <Route exact path={'/assistants'}>
              <AssistantInteractionComponent therapistId={therapist.id} />
            </Route>
            <Redirect from={'/'} to={'/tasks'} />
          </Switch>
        </div>
      </Container>
    </div>
  );
};

export default TherapistAppComponent;
