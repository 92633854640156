import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import { SmsDropdownContainer, DropdownContent, DropdownOption, SubDropdown, SmsButton } from '../styles';
import { GetTherapistSmsOptions, GetClientSmsOptions } from '../../../../constants/smsDropdown';

const SmsDropdownButton = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionOnclick = (option) => {
    props.setSms(option);
    props.handleMsgModalOpen();
  };

  const isTherapist = props.therapist;
  const options = isTherapist ? GetTherapistSmsOptions() : GetClientSmsOptions();
  return (
    <SmsDropdownContainer therapist={isTherapist}>
      <p style={{ fontSize: 12, fontStyle: 'italic', textAlign: 'center', marginTop: '1rem', marginBottom: '0' }}>
        {t('presetSms.introduction')}
      </p>
      <SmsButton onClick={handleClick}>
        <MessageOutlined style={{ fontSize: 20, color: '#f7c042', marginRight: '8px' }} translate={undefined} />
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{t('presetSms.title')}</p>
      </SmsButton>
      <DropdownContent className={isOpen ? 'open' : ''} therapist={isTherapist}>
        {options.map((option) => {
          return <DropdownOption onClick={() => handleOptionOnclick(option)}>{option.title}</DropdownOption>;
        })}
      </DropdownContent>
    </SmsDropdownContainer>
  );
};

export default SmsDropdownButton;
