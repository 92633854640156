import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloneIcon,
  ExampleGif,
  ModalContainer,
  PasswordModalButton,
  PasswordModalButtonContainer,
  PasswordModalContainer,
  PasswordModalContentContainer,
  PasswordModalHeaderContainer,
  PasswordModalMask,
  PasswordActionContainer,
  PasswordContainer,
} from '../styles';
import { ActionTitle, Description, H2Title } from '../../../../style';
import CopyPasswwordExample from '../assets/copyPassword.gif';
import useLockBodyScroll from '../../../../util/Hooks/useLockBodyScroll';

interface Props {
  show: boolean;
  selected: boolean;
  videoPassword: string;
  copyPassword: () => void;
  handleCloseModal: () => void;
}

const PasswordModal = ({ show, selected, videoPassword, copyPassword, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  useLockBodyScroll(show);
  return (
    <ModalContainer show={show && selected}>
      <PasswordModalMask onClick={handleCloseModal}></PasswordModalMask>
      <PasswordModalContainer>
        <PasswordModalHeaderContainer>
          <H2Title>{t('client.video.modal.title')}</H2Title>
        </PasswordModalHeaderContainer>
        <PasswordModalContentContainer>
          <Description>{t('client.video.modal.content1')}</Description>
          <ExampleGif src={CopyPasswwordExample} alt="copy password example" />
          <Description>{t('client.video.modal.content2')}</Description>
          <PasswordActionContainer>
            <PasswordContainer>
              <Description>{videoPassword}</Description>
            </PasswordContainer>
            <CloneIcon link className="clone outline" onClick={copyPassword}></CloneIcon>
          </PasswordActionContainer>
        </PasswordModalContentContainer>
        <PasswordModalButtonContainer>
          <PasswordModalButton onClick={handleCloseModal}>
            <ActionTitle>{t('client.video.modal.button')}</ActionTitle>
          </PasswordModalButton>
        </PasswordModalButtonContainer>
      </PasswordModalContainer>
    </ModalContainer>
  );
};

export default PasswordModal;
