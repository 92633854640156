import { Typography } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChildMessages_child_room_directMessages } from '../../../../../graphql/queries/types/ChildMessages';
import { TherapistContext } from '../../../TherapistContextt';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import MessageComponent from './components/Message';
import NewMessageComponent from './components/NewMessage';

interface Props {
  messages: ChildMessages_child_room_directMessages[];
  roomId: string;
}

const MessagesComponent = ({ messages, roomId }: Props) => {
  const {
    therapist: { id: therapistId },
  } = useContext(TherapistContext);
  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.dashboard.myClient.clientInfo.topbar.messages')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        {messages.map((message) => (
          <MessageComponent key={message.id} message={message} />
        ))}
        <div style={{ marginTop: '15px' }}>
          <NewMessageComponent roomId={roomId} therapistId={therapistId} />
        </div>
      </RouteContentContainer>
    </>
  );
};

export default MessagesComponent;
