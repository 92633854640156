import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Client_client_children_goals } from '../../../../../graphql/queries/types/Client';
import { ActionTitle, SmallSectionTitle } from '../../../../../style';
import useLockBodyScroll from '../../../../../util/Hooks/useLockBodyScroll';
import { HighlightBottomEdge, TabMenuContainer, TabMenuItem } from '../../../../common/TabMenu/styles';
import ActivityCard from './ActivityCard';
import OverallTab from './OverallTab';
import {
  GoalModalMask,
  InsideCloseButtonContainer,
  BackButtonContainer,
  GoalModalContainerWithClose,
  GoalModalContainer,
  GoalModalHeader,
  GoalModalContent,
  GoalModalTabContainer,
  ContentContainer,
  GoalCategoryBadge,
  GoalTitle,
} from '../../styles';

interface Props {
  goals: Array<Client_client_children_goals>;
  goalId: string;
  show: boolean;
  productType: string;
  handleClose: () => void;
  modalStep: string;
}

const GoalModal = ({ show, productType, goals, goalId, handleClose, modalStep }: Props) => {
  const { t } = useTranslation();
  useLockBodyScroll(show);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState('overall');
  const isOverall = activeItem === 'overall';
  const isActivity = activeItem === 'activity';
  const goal = goals.filter((g) => g.id === goalId);

  const handleReset = () => {
    setActiveItem('overall');
    setShowMore(false);
    handleClose();
  };

  const handleClick = () => {
    setShowMore(!showMore);
  };

  if (goals.length === 0) {
    return <></>;
  }
  return (
    <GoalModalMask open={show}>
      <GoalModalContainerWithClose>
        <GoalModalContainer>
          <GoalModalHeader>
            <InsideCloseButtonContainer>
              {modalStep === 'one' && (
                <Icon className="close" size="large" link style={{ margin: '15px 0 15px 0' }} onClick={handleReset} />
              )}
              {modalStep === 'two' && (
                <BackButtonContainer onClick={handleReset}>
                  <Icon className="chevron left" size="large" link style={{ margin: '15px 0 15px 0' }} />
                  <ActionTitle>{t('client.dashboard.goal.backButton')}</ActionTitle>
                </BackButtonContainer>
              )}
            </InsideCloseButtonContainer>
            <GoalCategoryBadge>{goal[0].skill.category.name}</GoalCategoryBadge>
            <GoalTitle>{goal[0].skill.name}</GoalTitle>
          </GoalModalHeader>
          <GoalModalTabContainer>
            <TabMenuContainer>
              <HighlightBottomEdge isSelected={isOverall}>
                <TabMenuItem active={isOverall} onClick={() => setActiveItem('overall')}>
                  <ActionTitle>{t('client.program.goal.tab.overall')}</ActionTitle>
                </TabMenuItem>
              </HighlightBottomEdge>
              <HighlightBottomEdge isSelected={isActivity}>
                <TabMenuItem active={isActivity} onClick={() => setActiveItem('activity')}>
                  <ActionTitle>{t('client.program.goal.tab.activity')}</ActionTitle>
                </TabMenuItem>
              </HighlightBottomEdge>
            </TabMenuContainer>
          </GoalModalTabContainer>
          <GoalModalContent>
            {isOverall && (
              <ContentContainer>
                <OverallTab goal={goal[0]} productType={productType} handleClick={handleClick} showMore={showMore} />
              </ContentContainer>
            )}
            {isActivity && (
              <ContentContainer>
                <SmallSectionTitle>
                  {t('client.program.tasksGoalsCard.goal.popup.activitiesIncluded')}
                </SmallSectionTitle>
                <ActivityCard goal={goal[0]} />
              </ContentContainer>
            )}
          </GoalModalContent>
        </GoalModalContainer>
      </GoalModalContainerWithClose>
    </GoalModalMask>
  );
};
export default React.memo(GoalModal);
