import gql from 'graphql-tag';

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export interface Data {
  logout: boolean;
}
