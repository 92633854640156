import gql from 'graphql-tag';

export const LITE_QUERY = gql`
  query Product($productId: ID!) {
    product(productId: $productId) {
      id
      qnaSessions {
        id
        link
        description
        startTime
        endTime
        order
      }
      course {
        id
        name
        courseSections {
          id
          startTime
          endTime
          title
          description
          lecturer
          order
          courseLinks {
            id
            description
            link
            linkType
            read
            linkLength
            linkLengthMinutes
            linkLengthSeconds
            videoPassword
            order
          }
        }
        qnaSessions {
          id
          link
          description
          startTime
          endTime
          order
        }
      }
      courseSections {
        id
        startTime
        endTime
        title
        description
        lecturer
        order
        courseLinks {
          id
          description
          link
          linkType
          read
          linkLength
          linkLengthMinutes
          linkLengthSeconds
          videoPassword
          order
        }
      }
    }
  }
`;
