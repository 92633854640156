import gql from 'graphql-tag';

export const UPDATE_CLIENT_WELCOME_STATUS_MUTATION = gql`
  mutation UpdateClientWelcomeStatus($clientId: ID!, $status: Boolean!) {
    updateClientWelcomeStatus(clientId: $clientId, status: $status) {
      returnMessage {
        status
        message
      }
    }
  }
`;
