import gql from 'graphql-tag';
import { GoalRecord, ReturnMessage } from '../../types';

export const CREATE_GOAL_RECORD_MUTATION = gql`
  mutation CreateGoalRecord(
    $goalId: ID!
    $consultationId: ID!
    $currentNumerator: Int!
    $currentDenominator: Int!
    $description: String!
    $preskillIds: [ID!]!
  ) {
    createGoalRecord(
      goalId: $goalId
      consultationId: $consultationId
      currentNumerator: $currentNumerator
      currentDenominator: $currentDenominator
      description: $description
      preskillIds: $preskillIds
    ) {
      goalRecord {
        id
        currentNumerator
        currentDenominator
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  createGoalRecord: { goalRecord: GoalRecord; returnMessage: ReturnMessage };
}

export interface Variables {
  goalId: number;
  consultationId: number;
  currentNumerator: number;
  currentDenominator: number;
  description: string;
  preskillIds: number[];
}
