import gql from 'graphql-tag';

export const GET_ACTIVE_LIBRARIES = gql`
  query getActiveSelfLearningLibraries($locale: String!, $contentType: String!) {
    getActiveSelfLearningLibraries(locale: $locale, contentType: $contentType) {
      id
      title
      body
      category
      publishedDate
      readTime
      contentType
      contentUrl
      mediaUrl
    }
  }
`;
