import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { ClientContext } from '../../ClientContext';
import { CLIENT_QUERY } from '../../../../graphql/queries/client';
import { REQUEST_BUY_MORE_MUTATION } from '../../../../graphql/mutations/requestBuyMore';
import { RequestBuyMore, RequestBuyMoreVariables } from '../../../../graphql/mutations/types/RequestBuyMore';
import { Client_client, Client, ClientVariables } from '../../../../graphql/queries/types/Client';

interface Props {
  client: Client_client;
}

const RequestBuyMoreButton = ({ client }: Props) => {
  const { setReturnMessage } = useContext(ClientContext);
  const { t } = useTranslation();

  const [requestBuyMore] = useMutation<RequestBuyMore, RequestBuyMoreVariables>(REQUEST_BUY_MORE_MUTATION, {
    update(cache, { data }) {
      if (!data) {
        return null;
      }

      const queryResult = cache.readQuery<Client, ClientVariables>({
        query: CLIENT_QUERY,
        variables: { clientId: client.id.toString() },
      });

      if (data.requestBuyMore.returnMessage.status === 'SUCCESS' && queryResult && queryResult.client) {
        const newClient = queryResult ? queryResult.client : client;
        newClient.requestBuyMore = true;
        cache.writeQuery<Client, ClientVariables>({
          query: CLIENT_QUERY,
          variables: { clientId: client.id.toString() },
          data: { client: newClient },
        });
      }

      if (data && data.requestBuyMore) {
        setReturnMessage(data.requestBuyMore.returnMessage);
        setTimeout(() => {
          setReturnMessage(undefined);
        }, 3000);
      }
      return data;
    },
  });

  return (
    <Button
      data-cy="extendSubscriptionButton"
      style={{
        margin: '5px',
        borderRadius: '25px',
        border: '2px solid #CF5272',
        backgroundColor: 'white',
        color: '#CF5272',
      }}
      onClick={() => requestBuyMore({ variables: { childId: client.children[0].id, productId: null } })}
    >
      {t('client.program.extendSubscription')}
    </Button>
  );
};

export default RequestBuyMoreButton;
