import React from 'react';
import { Modal, Button, Icon, Image } from 'semantic-ui-react';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import CNYholidayTW from '../assets/CNYholiday-TW.png';
import CNYholidayCN from '../assets/CNYholiday-CN.png';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickNotShowMore: (notificationId: string) => void;
  notificationId: string;
}

const CNYAnnouncement2021 = ({ handleClickNotShowMore, notificationId }: Props) => {
  const language = i18n.use(LanguageDetector).language;
  let image = '';
  if (language === 'zh-TW') {
    image = CNYholidayTW;
  } else if (language === 'zh-CN') {
    image = CNYholidayCN;
  }

  return (
    <div>
      <Modal.Content style={{ padding: '0px', borderRadius: '15px' }}>
        <img src={image} style={{ width: '350px', marginLeft: '-1px' }} />
        <div style={{ marginTop: '-475px', marginLeft: '305px' }}>
          <Button
            style={{ background: 'none', fontSize: '23px', color: 'white' }}
            onClick={() => {
              handleClickNotShowMore(notificationId);
            }}
          >
            <i className="remove circle icon"></i>
          </Button>
        </div>
      </Modal.Content>
    </div>
  );
};

export default CNYAnnouncement2021;
