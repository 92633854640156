import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Therapist_therapist_consultations,
  Therapist as Data,
  TherapistVariables as Variables,
} from '../../../graphql/queries/types/Therapist';
import ConsultationComponent from './components/TodayTaskList';
import { useQuery } from 'react-apollo';
import { THERAPIST_CONSULTATIONS_QUERY } from '../../../graphql/queries/therapist';
import MyTasksContext from './MyTasksContext';
import TaskList from './components/TaskList';
import ErrorPage from '../../common/ErrorPage';

interface Props {
  therapistId: string;
}

const MyTasksComponent = ({ therapistId }: Props) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<Data, Variables>(THERAPIST_CONSULTATIONS_QUERY, {
    variables: { therapistId },
  });

  if (loading) return <p>{t('system.loading')}</p>;
  if (error || !data || !data.therapist) return <ErrorPage />;

  const {
    therapist: { consultationUrl, cnConsultationUrl, consultations },
  } = data;

  const consultationsToday: Therapist_therapist_consultations[] = [];
  const consultationsAll: Therapist_therapist_consultations[] = [];
  const today = new Date();

  consultations.forEach((consultation) => {
    const consultationDate = new Date(consultation.consultationTime);
    // console.log(consultation);
    if (
      today.getDate() === consultationDate.getDate() &&
      today.getMonth() === consultationDate.getMonth() &&
      today.getFullYear() === consultationDate.getFullYear()
    ) {
      consultationsToday.push(consultation);
    }
    return;
  });

  consultations.forEach((consultation) => {
    consultationsAll.push(consultation);
  });

  return (
    <MyTasksContext.Provider value={{ consultationUrl, cnConsultationUrl }}>
      <ConsultationComponent
        consultations={consultationsToday}
        title={t('therapist.dashboard.myTasks.todayConsultations')}
        therapistId={therapistId}
      />
      <br />
      <TaskList
        consultations={consultationsAll.reverse()}
        title={t('therapist.dashboard.myTasks.allTasks')}
        therapistId={therapistId}
      />
    </MyTasksContext.Provider>
  );
};

export default MyTasksComponent;
