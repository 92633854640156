import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalHeader, ModalMask } from '../../../../../../../style';

export const EditWordModalMask = styled(ModalMask)``;

export const EditWordModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)``;

export const ButtonContainer = styled(ModalButtonContainer)`
  justify-content: flex-end;
`;
