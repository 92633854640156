import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import SelectTaskComponent from './SelectTaskComponent';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import TasksContext from './TasksContext';
import { ADD_ACTIVITIES_TO_CHILD } from '../../../../graphql/mutations/addActivitiesToChild';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { GetActivitiesFromPreskills_getActivitiesFromPreskills } from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import {
  AddActivitiesToChild,
  AddActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/AddActivitiesToChild';
import { RecommendActivityContainer, RecommendActivityText, RecommendActivityButton } from './styles';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../../../style';

const SelectTaskDashboardComponent = () => {
  const {
    consultation: { id, child },
    selectedActivities,
    setSelectedActivities,
  } = useContext(TherapistConsultationScreenContext);
  const { setIsHomework, setIsOverview } = useContext(TasksContext);

  const activityIds = selectedActivities.map(
    (activity: GetActivitiesFromPreskills_getActivitiesFromPreskills) => activity.id,
  );

  const [addActivitiesToChild] = useMutation<AddActivitiesToChild, AddActivitiesToChildVariables>(
    ADD_ACTIVITIES_TO_CHILD,
    { refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: id } }] },
  );

  const handleNext = async () => {
    await addActivitiesToChild({
      variables: {
        childId: child.id,
        activityIds: activityIds,
        consultationId: id,
      },
    });
    setSelectedActivities([]);
    setIsHomework(false);
    setIsOverview(true);
  };

  const handleActivtiyRecommend = () => {
    window.open(t('therapist.consultationScreen.task.activityIdea.link'), '_blank');
  };

  const { t } = useTranslation();

  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => setIsHomework(false)}
        onClickNext={() => handleNext()}
        nextText={t('therapist.consultationScreen.finish')}
      />
      <div style={{ margin: '10px 5px' }}>
        <SectionTitle>{t('therapist.consultationScreen.task.taskAndHw.selectTaskForThisWeek')}</SectionTitle>
        <SelectTaskComponent />
        <RecommendActivityContainer>
          <RecommendActivityText>{t('therapist.consultationScreen.task.activityIdea.text')}</RecommendActivityText>
          <RecommendActivityButton onClick={handleActivtiyRecommend}>
            {t('therapist.consultationScreen.task.activityIdea.button')}
          </RecommendActivityButton>
        </RecommendActivityContainer>
      </div>
    </div>
  );
};

export default SelectTaskDashboardComponent;
