import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Lite, LiteVariables } from '../../../../../graphql/queries/types/Client/Lite';
import { LITE_QUERY } from '../../../../../graphql/queries/Client/lite';
import { ClientContext } from '../../../ClientContext';
import SideBar from '../VideoList';
import { Loader } from 'semantic-ui-react';
import VideoDetail from '../VideoDetail';
import ErrorPage from '../../../../common/ErrorPage';

interface Props {
  platform: string;
  sectionIndex: number;
  itemIndex: number;
  video: string;
}

const CourseVideoPage = ({ platform, sectionIndex, itemIndex, video }: Props) => {
  const { t } = useTranslation();
  const { child, courseSections, setCourseSections } = useContext(ClientContext);
  const [liteQuery, { called, loading, error, data }] = useLazyQuery<Lite, LiteVariables>(LITE_QUERY);

  useEffect(() => {
    if (data && data.product) {
      setCourseSections(data.product.courseSections);
    }
  }, [data]);

  if (courseSections.length === 0 && !loading && !error && !data) {
    liteQuery({
      variables: { productId: child.product.id },
    });
  }

  if (called && loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }
  if (courseSections.length === 0 && (error || !data || !data.product)) {
    return <ErrorPage />;
  }

  return (
    <>
      <VideoDetail platform={platform} sectionIndex={sectionIndex} itemIndex={itemIndex} video={video} />
      <SideBar sectionIndex={sectionIndex} itemIndex={itemIndex} />
    </>
  );
};

export default CourseVideoPage;
