import React, { useContext, useEffect, useState } from 'react';
import '../styles.css';
import { Button } from 'semantic-ui-react';
import { useQuery, useMutation } from 'react-apollo';
import { Modal, List, Button as ButtonAntd, Tooltip, Progress } from 'antd';
import { DownloadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  SendSmsToTherapistBefConsultation,
  SendSmsToTherapistBefConsultationVariables,
} from '../../../../graphql/mutations/types/sendSmsToTherapistBefConsultation';
import { SEND_SMS_TO_THERAPIST_BEF_CONSULTATION } from '../../../../graphql/mutations/sendSmsToTherapistBefConsultation';
import {
  Client_client_children_uploadedVideos,
  Client_client_children_consultations,
} from '../../../../graphql/queries/types/Client';
import {
  UpdateDownloadedVideoStatusData,
  UpdateDownloadedVideoStatusVariables,
} from '../../../../graphql/mutations/updateDownloadedVideoStatus';
import { UPDATE_DOWNLOADED_VIDEO_STATUS_MUTATION } from '../../../../graphql/mutations/updateDownloadedVideoStatus';
import { useTranslation } from 'react-i18next';
import { ClientContext } from '../../ClientContext';
import { isFuture, isPast, isToday } from '../../../../util/DateAndTime';
import { openInNewTab, S3consultationVideoRoute } from '../../../../helpers/functions';
import { getBackupRoomSmsLog } from '../../../../constants/smsDropdown';
import ErrorPage from '../../../common/ErrorPage';
import SmsDropdownButton from '../../../Therapist/MyTasks/components/SmsDropdownButton';
import { utcToFullDateTime } from '../../../../constants/timezoneConversion';
import ConfirmMsgModal from '../../../Therapist/MyTasks/components/ConfirmMsgModal';
import { OptionButton } from '../../../common/styles';
import { StartButton, StartVideoButton } from '../../../Therapist/MyTasks/styles';
import { BACKUP_LINK_EN, BACKUP_LINK_CN } from '../../../../constants/backupLink';
import { CLIENT_QUERY } from '../../../../graphql/queries/client';
interface Props {
  clientId: string;
  routeLocale?: string;
  routeTimezone?: string;
  therapistUrl: string | null;
  showPreviousConsultations: boolean;
}

interface Next_Consultation {
  id: string;
  consultationTime: string;
}

const TimelineComponent = ({
  showPreviousConsultations,
  clientId,
  routeLocale,
  routeTimezone,
  therapistUrl,
}: Props) => {
  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const [backupLinkAvailable, setBackupLinkAvailable] = useState(false);
  const [therapistUUID, setTherapistUUID] = useState('');
  const [therapistName, setTherapistName] = useState('');
  const [downloadVideoModalOpen, setDownloadVideoModalOpen] = useState(false);
  const [pastConsultations, setPastConsultations] = useState<Client_client_children_consultations[]>([]);
  const [upcommingConsultations, setUpcommingConsultations] = useState<Client_client_children_consultations[]>([]);
  const [nextConsultation, setNextConsultation] = useState<Next_Consultation>({
    id: '',
    consultationTime: '',
  });
  const [downloadProgress, setDownloadProgress] = useState<any>([]);
  const [isNextConsultationOnToday, setIsNextConsultationOnToday] = useState(false);
  const [currentConsultationOnDownloadVideoModalIndex, setCurrentConsultationOnDownloadVideoModalIndex] = useState(0);
  const [isSmsDisabled, setIsSmsDisabled] = useState(false);
  const [options, setOptions] = useState([]);
  const today = new Date();
  let {
    client: {
      user: { locale },
      user,
      timeZone,
    },
    child,
    child: { name: childName },
  } = useContext(ClientContext);
  const [backupLink] = useState(user.country === 'cn' ? BACKUP_LINK_CN : BACKUP_LINK_EN);
  const sortConsultations = async (consultations) => {
    const sortedConsultationsByDateTime = consultations.sort(
      (a, b) => new Date(a.consultationTime).getTime() - new Date(b.consultationTime).getTime(),
    );
    return sortedConsultationsByDateTime;
  };

  const getUpcommingConsultations = async (sortedConsultations: any) => {
    let upcommingConsultations: Client_client_children_consultations[] = [];
    for (const consultation of sortedConsultations) {
      const consultationDateTime = new Date(consultation.consultationTime);
      if (isFuture(consultationDateTime.getTime()) || isToday(today, consultationDateTime)) {
        upcommingConsultations.push(consultation);
        if (isToday(today, consultationDateTime)) {
          setBackupLinkAvailable(getBackupRoomSmsLog(consultation, consultation.id, user.id));
        }
      }
    }
    return upcommingConsultations;
  };

  const getPastConsultations = async (sortedConsultations: any) => {
    let pastConsultations: Client_client_children_consultations[] = [];
    let defaultDownloadProgress = downloadProgress;
    for (const consultation of sortedConsultations) {
      const consultationDateTime = new Date(consultation.consultationTime);
      if (isPast(consultationDateTime.getTime()) && !isToday(today, consultationDateTime)) {
        pastConsultations.push(consultation);
        for (const uploadVideo of consultation.uploadedVideos) {
          defaultDownloadProgress[uploadVideo.id] = 0;
        }
      }
    }
    setDownloadProgress(defaultDownloadProgress);
    return pastConsultations;
  };

  const setPastConsultationsRelatedStates = async () => {
    try {
      const sortedConsultations = await sortConsultations([...child.consultations]);
      const pastConsultations = await getPastConsultations(sortedConsultations);
      setPastConsultations(pastConsultations);
    } catch (error) {
      console.error('Error setting past consultations:', error);
    }
  };

  const setConsultationsRelatedStates = async () => {
    try {
      const sortedConsultations = await sortConsultations([...child.consultations]);
      const pastConsultations = await getPastConsultations(sortedConsultations);
      const upcommingConsultations = await getUpcommingConsultations(sortedConsultations);
      setPastConsultations(pastConsultations);
      setUpcommingConsultations(upcommingConsultations);
      if (upcommingConsultations.length > 0) setNextConsultation(upcommingConsultations[0]);
      const nextConsultationDateTime = new Date(upcommingConsultations[0].consultationTime);
      setIsNextConsultationOnToday(isToday(today, nextConsultationDateTime));
    } catch (error) {
      console.error('Error setting consultations related states:', error);
    }
  };

  useEffect(() => {
    setConsultationsRelatedStates();
  }, []);

  useEffect(() => {
    setPastConsultationsRelatedStates();
  }, [child.consultations]);

  if (!locale && routeLocale) {
    locale = routeLocale;
  }
  if (!timeZone && routeTimezone) {
    timeZone = routeTimezone;
  }

  const [sendSmsToTherapistBefConsultation] = useMutation<
    SendSmsToTherapistBefConsultation,
    SendSmsToTherapistBefConsultationVariables
  >(SEND_SMS_TO_THERAPIST_BEF_CONSULTATION);

  const [updateDownloadedVideoStatus] = useMutation<
    UpdateDownloadedVideoStatusData,
    UpdateDownloadedVideoStatusVariables
  >(UPDATE_DOWNLOADED_VIDEO_STATUS_MUTATION, {
    refetchQueries: [{ query: CLIENT_QUERY, variables: { clientId: clientId } }],
  });
  const { t } = useTranslation();

  const handleMsgModalOpen = (consultationTime: any) => {
    const consultationDateTime = new Date(consultationTime);
    setIsSmsDisabled(!isToday(today, consultationDateTime));
    setMsgModalOpen(true);
  };

  const handleMsgModalClose = () => {
    setMsgModalOpen(false);
  };

  const setMsgOptions = (option) => {
    setOptions(option);
  };
  const sendSmsToTherapist = async (option) => {
    await sendSmsToTherapistBefConsultation({
      variables: {
        consultationId: parseInt(nextConsultation.id),
        msg: option.msg,
        details: JSON.stringify(option),
      },
    });
  };

  const handleOpenDownloadModal = (index: number, therapistUUID: string, therapistName: string) => {
    setDownloadVideoModalOpen(true);
    setCurrentConsultationOnDownloadVideoModalIndex(index);
    setTherapistUUID(therapistUUID);
    setTherapistName(therapistName);
  };

  const handleCloseDownloadModal = () => {
    setDownloadVideoModalOpen(false);
  };

  const downloadVideoFileFromS3 = async (filename: string, uploadedVideoId: string) => {
    const currentConsultationIndex = pastConsultations[currentConsultationOnDownloadVideoModalIndex].id;

    const consultationVideoRoute = await S3consultationVideoRoute(
      therapistUUID,
      therapistName,
      childName,
      currentConsultationIndex.toString(),
      filename,
    );

    const response = await fetch(consultationVideoRoute);
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Failed to get reader from response body');
    }

    const contentLengthHeader = response.headers.get('Content-Length');
    if (!contentLengthHeader) {
      throw new Error('Failed to get Content-Length from response headers');
    }
    const contentLength = parseInt(contentLengthHeader, 10);
    let receivedLength = 0;
    const chunks: Uint8Array[] = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
      receivedLength += value.length;

      const progress = Math.round((receivedLength / contentLength) * 100);
      setDownloadProgress((prevProgress) => ({
        ...prevProgress,
        [uploadedVideoId]: progress,
      }));
    }

    const blob = new Blob(chunks);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    await updateDownloadedVideoStatus({
      variables: {
        uploadedVideoId: parseInt(uploadedVideoId),
      },
    });
  };

  const handleDownloadOneVideo = async (uploadedVideoName: string, uploadedVideoId: string) => {
    await downloadVideoFileFromS3(uploadedVideoName, uploadedVideoId);
  };

  const disableScroll = () => {
    document.addEventListener('wheel', preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener('wheel', preventDefault, false);
  };

  const preventDefault = (e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  const handleClickStartVideo = () => {
    therapistUrl && openInNewTab(therapistUrl);
  };

  const handleClickBackupLink = () => {
    openInNewTab(backupLink);
  };

  return (
    <div>
      <Modal
        key={currentConsultationOnDownloadVideoModalIndex}
        open={downloadVideoModalOpen}
        onCancel={handleCloseDownloadModal}
        title={
          <div>
            <b>{t('therapist.childInfo.general.button.uploadedVideo')}</b>
            <p
              style={{ marginTop: '8px', fontSize: 14, fontWeight: 'normal', color: '#5a5a5a' }}
              dangerouslySetInnerHTML={{ __html: t('client.consultation.downloadVideo.instruction') }}
            ></p>
          </div>
        }
        footer={[
          <ButtonAntd key="back" type="ghost" onClick={handleCloseDownloadModal}>
            Close
          </ButtonAntd>,
        ]}
      >
        <List
          size="small"
          bordered
          dataSource={
            pastConsultations.length > 0 &&
            pastConsultations[currentConsultationOnDownloadVideoModalIndex] &&
            pastConsultations[currentConsultationOnDownloadVideoModalIndex].uploadedVideos
              ? pastConsultations[currentConsultationOnDownloadVideoModalIndex].uploadedVideos.sort(
                  (a, b) => parseInt(a.id) - parseInt(b.id),
                )
              : undefined
          }
          renderItem={(item) => (
            <List.Item
              key={item.id}
              style={{ display: 'flex', alignContent: 'space-between', alignItems: 'center', wordBreak: 'break-all' }}
            >
              <div>{item.uploadedVideoName}</div>

              {downloadProgress[item.id] === 0 ? null : (
                <Progress
                  key={item.id}
                  percent={downloadProgress[item.id]}
                  style={{ margin: '0 8px', width: '150px' }}
                  showInfo={false}
                />
              )}

              {item.isDownloaded ? (
                <Tooltip placement="right" title={t('client.consultation.downloadVideo.downloaded')}>
                  <CheckCircleOutlined translate={null} style={{ cursor: 'pointer' }} />
                </Tooltip>
              ) : (
                <Tooltip placement="right" title={t('client.consultation.downloadVideo.download')}>
                  <DownloadOutlined
                    onClick={() => handleDownloadOneVideo(item.uploadedVideoName, item.id)}
                    style={{
                      cursor: 'pointer',
                      fontSize: '16px',
                    }}
                    translate={undefined}
                  />
                </Tooltip>
              )}
            </List.Item>
          )}
        ></List>
      </Modal>
      {msgModalOpen && (
        <ConfirmMsgModal
          consultationId={nextConsultation.id}
          modalHeader1={t('modal.header')}
          modalHeader2={t('modal.headerNoSubOption')}
          cancelText={t('modal.cancel')}
          actionText={t('modal.action')}
          disabled={isSmsDisabled}
          option={options}
          disableScroll={disableScroll}
          enableScroll={enableScroll}
          onCancel={handleMsgModalClose}
          onAction={sendSmsToTherapist}
        ></ConfirmMsgModal>
      )}
      {upcommingConsultations.length === 0 ? (
        !showPreviousConsultations ? (
          <div>{t('client.consultation.noConsultation')}</div>
        ) : null
      ) : (
        <Button
          style={{
            margin: '5px',
            borderRadius: '25px',
            color: 'white',
            background: '#5ABAAE',
          }}
          fluid
          onClick={() => handleClickStartVideo()}
          target="_blank"
          rel="noreferrer"
          disabled={!isNextConsultationOnToday || backupLinkAvailable}
        >
          {t('client.consultation.startVideo')}
        </Button>
      )}
      {showPreviousConsultations && pastConsultations.length > 0 ? (
        <div className="pastTimeline">
          {pastConsultations.map((consultation, index) => {
            const {
              consultationTime,
              uploadedVideos,
              therapist: {
                user: { uuid, name },
              },
            } = consultation;
            return (
              <div key={consultation.id} className="pastTimeline-container right">
                <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="pastConsultationContainer">
                    <div className="consultationDateTime">{utcToFullDateTime(consultationTime, timeZone, locale)}</div>
                    {uploadedVideos.length > 0 ? (
                      <ButtonAntd
                        size="small"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => handleOpenDownloadModal(index, uuid, name)}
                      >
                        <DownloadOutlined
                          style={{
                            fontSize: '16px',
                            marginRight: '8px',
                          }}
                          translate={undefined}
                        />
                        <p style={{ fontSize: '12px' }}>Download videos</p>
                      </ButtonAntd>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="timeline">
          {upcommingConsultations.length > 0 &&
            upcommingConsultations.map((upcommingConsultation) => {
              const consultationTime = upcommingConsultation.consultationTime;
              return (
                <div key={upcommingConsultation.id} className="timeline-container right">
                  <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="consultationContainer">
                      <div className="consultationDateTime">
                        {utcToFullDateTime(consultationTime, timeZone, locale)}
                      </div>
                      {backupLinkAvailable && (
                        <StartVideoButton
                          style={{
                            width: '140px',
                          }}
                          onClick={() => handleClickBackupLink()}
                        >
                          {t('client.consultation.backupLink')}
                        </StartVideoButton>
                      )}
                    </div>
                    <SmsDropdownButton
                      therapist={false}
                      handleMsgModalOpen={() => handleMsgModalOpen(consultationTime)}
                      setSms={setMsgOptions}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default TimelineComponent;
