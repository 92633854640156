import React from 'react';

const DoubleQuotesLeftIcon = () => {
  return (
    <svg
      style={{ position: 'absolute', top: 0, left: 0 }}
      width="40"
      height="18"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.84 1.48C3.64 2.6 2.28 4.68 2.24 6.44C2.48 6.24 2.92 6.08 3.36 6.08C4.88 6.08 5.88 7.24 5.88 8.68C5.88 9.92 4.96 11.28 3.16 11.28C1.4 11.28 0 9.68 0 7.48C0 3.88 2.44 1.16 5 0L5.84 1.48ZM13.48 1.48C11.32 2.6 9.96 4.68 9.92 6.44C10.16 6.24 10.6 6.08 11.04 6.08C12.56 6.08 13.52 7.24 13.52 8.68C13.52 9.92 12.6 11.28 10.84 11.28C9.08 11.28 7.68 9.68 7.68 7.48C7.68 3.88 10.12 1.16 12.68 0L13.48 1.48Z"
        fill="#8B8B8B"
      />
    </svg>
  );
};

export default DoubleQuotesLeftIcon;
