import styled from 'styled-components';
import { Button } from 'antd';

export const ZoomButton = styled(Button)`
  &:hover {
    border-color: #2d8cff;
    color: #2d8cff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

export const ConsultationConfirmationButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  padding: 0 16px;
  display: grid;
  gap: 8px;
`;

export const DetailsItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 7px;
`;

export const UploadVideoButton = styled.label`
  background-color: #f0f0f0;
  color: black;
  padding: 0.5rem;

  font-family: sans-serif;
  border-radius: 0.3rem;
  border: 1px solid black;
  cursor: pointer;
`;
