import React, { useContext, useState, useEffect } from 'react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import api_address, { eval_backend_api_address } from '../../../../constants/config';
import { useQuery } from 'react-apollo';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { Category } from '../../../../graphql/queries/types/Category';
import { CATEGORIES_QUERY } from '../../../../graphql/queries/categories';
import { RiArrowDropDownLine } from 'react-icons/ri';

export const Segment = styled.div`
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  padding: 28px;
  @media only screen and (min-width: 850px) {
  }
`;

export const Title = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
  display: flex;
  text-align: center;
  justify-content: center;

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const MAX_WEAKER_SKILLS_PER_CATEGORY_COUNT = 5;

function displayWeakestSkills(weakerSkills, category, skillNameObj, t, isDisplayAllSkills) {
  const ageBracketSkills = {};

  let skillCount = 0;

  for (const i in weakerSkills[category]['weakerSkills']) {
    const ageBracket = weakerSkills[category]['weakerSkills'][i]['ageBracket'];
    const skillId = weakerSkills[category]['weakerSkills'][i]['skillId'];

    if (skillCount >= MAX_WEAKER_SKILLS_PER_CATEGORY_COUNT && !isDisplayAllSkills) {
      break;
    }

    if (ageBracketSkills[ageBracket]) {
      if (!ageBracketSkills[ageBracket].includes(skillId)) {
        ageBracketSkills[ageBracket].push(skillId);
        skillCount++;
      }
    } else {
      ageBracketSkills[ageBracket] = [skillId];
      skillCount++;
    }
  }

  const divList: React.ReactElement[] = [];

  for (const ageBracket of Object.keys(ageBracketSkills)) {
    const [startAgeBracket, endAgeBracket] = weakerSkills[category]['ageRange'][ageBracket];

    divList.push(
      <div
        style={{
          fontSize: '13px',
          marginTop: '8px',
          textDecoration: 'underline',
        }}
      >
        {`${startAgeBracket}-${endAgeBracket} ${t('therapist.consultationScreen.progress.weakerSkills.months')}`}
      </div>,
    );

    ageBracketSkills[ageBracket].sort((a, b) => {
      if (skillNameObj[a] !== undefined && skillNameObj[b] !== undefined) {
        return skillNameObj[a].localeCompare(skillNameObj[b]);
      } else {
        return 0;
      }
    });

    for (const skillId of ageBracketSkills[ageBracket]) {
      divList.push(
        <div
          style={{
            fontSize: '13px',
          }}
        >
          {skillNameObj[skillId]}
        </div>,
      );
    }
  }
  return <div>{divList}</div>;
}

const WeakerSkills = () => {
  const {
    consultation: {
      child: {
        client: {
          user: { uuid },
        },
      },
    },
  } = useContext(TherapistConsultationScreenContext);

  const [weakerSkills, setWeakerSkills] = useState<any>(undefined);
  const [transactionId, setTransactionId] = useState<string>('');
  const [skillNameObj, setSkillNameObj] = useState<any>({});
  const [categoryNameObj, setCategoryNameObj] = useState<any>({});
  const [isDisplayAllSkills, setIsDisplayAllSkills] = useState<boolean>(false);

  const getWeakestSkills = async () => {
    const token = JS_COOKIE.get('token');
    const userUuid = uuid;
    const res = await axios.get(eval_backend_api_address + 'api/results/getResultByUserUuid', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userUuid,
      },
    });

    if (res) {
      setWeakerSkills(res.data.weakerSkills);
      setTransactionId(res.data.transactionId);
      return res;
    }
  };

  const { t } = useTranslation();

  const handleViewAllButtonClick = () => {
    setIsDisplayAllSkills(!isDisplayAllSkills);
  };

  const ViewMoreActivities = () => {
    return (
      <div>
        {
          <div
            onClick={handleViewAllButtonClick}
            style={{
              color: 'teal',
              fontWeight: 600,
              cursor: 'pointer',
              position: 'absolute',
              top: '15px',
              right: '20px',
            }}
          >
            {isDisplayAllSkills
              ? t('therapist.consultationScreen.activities.viewLess')
              : t('therapist.consultationScreen.activities.viewAll')}
          </div>
        }
      </div>
    );
  };

  function getTranslatedCatName(str) {
    const catId = { comprehension: 1, expression: 2, preliteracy: 3, oral: 5, social: 6 };
    return categoryNameObj[catId[str]];
  }

  useEffect(() => {
    getWeakestSkills();
  }, []);

  const { loading, error, data } = useQuery<Category, {}>(CATEGORIES_QUERY);

  useEffect(() => {
    if (data) {
      const skillList = {};
      const categoryList = {};
      for (const catIndex in data.categories) {
        const cat = data.categories[catIndex];
        categoryList[cat.id] = cat.name;
        for (const subCatIndex in cat.subcategories) {
          const subCat = cat.subcategories[subCatIndex];
          for (const skillIndex in subCat.skills) {
            const skill = subCat.skills[skillIndex];
            const uid = skill.uid;
            const skillName = skill.name;
            skillList[uid] = skillName;
          }
        }
      }
      setCategoryNameObj(categoryList);
      setSkillNameObj(skillList);
    }
  }, [data]);

  return (
    <>
      {weakerSkills !== undefined && (
        <Segment
          style={{
            padding: '22px 20px',
          }}
        >
          <Title
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginTop: '-10px',
              marginBottom: '15px',
            }}
          >
            {t('therapist.consultationScreen.progress.weakerSkills.title')}
          </Title>
          <ViewMoreActivities />
          {transactionId !== '' && (
            <Title
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
                marginTop: '-10px',
                marginBottom: '15px',
              }}
              onClick={() => window.open(api_address + `core-report/${transactionId}`)}
            >
              <a style={{ textDecoration: 'underline' }}>{`(ID: ${transactionId})`}</a>
            </Title>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '-30px' }}>
            {Object.keys(weakerSkills).map(
              (category) =>
                weakerSkills[category]['weakerSkills'].length !== 0 && (
                  <>
                    <div style={{ flex: 1, marginRight: '10px' }}>
                      <div style={{ fontSize: '12px', fontWeight: 650, marginBottom: '5px' }}>
                        {getTranslatedCatName(category)}
                      </div>
                      {displayWeakestSkills(weakerSkills, category, skillNameObj, t, isDisplayAllSkills)}
                    </div>
                  </>
                ),
            )}
          </div>

          <div
            style={{
              textAlign: 'left',
              marginTop: '20px',
              marginBottom: '-10px',
              fontSize: '11px',
            }}
          >
            {t('therapist.consultationScreen.progress.weakerSkills.note')}
          </div>

          <div
            onClick={handleViewAllButtonClick}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              margin: `12px 0 -20px 0`,
              fontSize: '30px',
              color: 'lightgrey',
              transform: isDisplayAllSkills ? 'rotate(180deg)' : '',
            }}
          >
            <RiArrowDropDownLine />
          </div>
        </Segment>
      )}
    </>
  );
};

export default WeakerSkills;
