import React, { useContext } from 'react';
import { Avatar, Menu, Space, Typography } from 'antd';
import { TherapistContext } from '../../../TherapistContextt';
import { NavLink } from 'react-router-dom';
import { TranslationOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LogoutComponent } from '../../feature/LogoutComponent';
import { localeValue, useLanguageSwitcher } from '../../feature/LanguageSwitcher';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { therapist } = useContext(TherapistContext);

  const enSwitcher = useLanguageSwitcher('en');
  const zhCNSwitcher = useLanguageSwitcher('zh-CN');
  const zhTWSwitcher = useLanguageSwitcher('zh-TW');

  const localeToDisplay = (locale: localeValue) => {
    switch (locale) {
      case 'zh-CN':
        return '简体中文';
      case 'zh-TW':
        return '繁體中文';
      case 'en':
        return 'English';
      default:
        return 'English';
    }
  };

  return (
    <Menu
      style={{ width: '280px' }}
      items={[
        {
          key: '0',
          label: (
            <>
              <Space align="center" style={{ padding: '11px 4px' }}>
                <Avatar size={64} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                  {therapist.user.name.charAt(0)}
                </Avatar>
                <div>
                  <Text strong>{therapist.user.name}</Text>
                  <div>
                    <Text type="secondary">{therapist.user.email}</Text>
                  </div>
                </div>
              </Space>
              <NavLink to={`/account`} />
            </>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '1',
          label: t('auth.locale'),
          icon: <TranslationOutlined translate={undefined} />,
          children: [
            {
              key: '1-1',
              label: <div onClick={enSwitcher}>{localeToDisplay('en')}</div>,
            },
            {
              key: '1-2',
              label: <div onClick={zhCNSwitcher}>{localeToDisplay('zh-CN')}</div>,
            },
            {
              key: '1-3',
              label: <div onClick={zhTWSwitcher}>{localeToDisplay('zh-TW')}</div>,
            },
          ],
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          label: <LogoutComponent />,
          icon: <LogoutOutlined translate={undefined} />,
        },
      ]}
    />
  );
};

export default ProfileDropdown;
