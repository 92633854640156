import styled from 'styled-components';

const paletteGreen = {
  primary: '#ffffff',
  secondary: '#5abaae',
  black: '#333333',
  grey: '#B4B4B4',
};

export const InstallBannerContainer = styled.div`
  width: 90vw;
  border-radius: 10px;
  transform: translateY(5%);
  background: ${paletteGreen.primary};
  height: 12vh;
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.15fr 0.15fr 0.1fr;
  display: none;
  border: 0px ${paletteGreen.black} solid;
  box-shadow: 0 0 3px rgba(140, 140, 140, 1);
  @media only screen and (min-width: 922px) {
    width: 80vw;
  }
  @media only screen and (max-width: 750px) {
    display: flex;
    width: 28rem;
    flex-direction: column;
    height: 22rem;
    margin: 0rem 1rem 0rem 1rem;
    padding: 1.1rem 0rem;
    transform: translateY(2%);
    border: 1px ${paletteGreen.grey} solid;
  }
`;

export const InstallBannerButton = styled.button`
  width: 10rem;
  padding: 0.8rem 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  color: ${paletteGreen.primary};
  background: ${paletteGreen.secondary};
  transition-duration: 0.3s;
  margin: 0rem 0.5rem;
  grid-column-start: 3;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 750px) {
    width: 9rem;
    padding: 0.65rem 1rem;
  }
`;

export const InstallBannerAntiActionButton = styled(InstallBannerButton)`
  background: ${paletteGreen.primary};
  color: ${paletteGreen.secondary};
  font-weight: 400;
  border: ${paletteGreen.secondary} 1px solid;
  grid-column-start: 4;

  @media only screen and (max-width: 750px) {
    border: none;
    background: transparent;
    color: ${paletteGreen.black};
    margin-top: 0rem;
    font-weight: 500;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  color: ${paletteGreen.black};
  font-size: 1.1rem;
`;
export const Checkbox = styled.input`
  margin-right: 0.5rem;
  accent-color: #ffffff;
`;

export const CheckboxWrapper = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  color: ${paletteGreen.secondary};
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: salmon;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: #eee;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
`;

export const InstallBannerButtonsContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const InstallBannerText = styled.div`
  color: ${paletteGreen.secondary};
  grid-column-start: 2;
  font-size: 2rem;
  text-align: center;
  @media only screen and (max-width: 750px) {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 500;
    width: 70%;
    margin-top: 2.5rem;
    height: 5rem;
    max-height:5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${paletteGreen.black}
}
  }
`;

export const InstallBannerIcon = styled.img`
  width: 4.5vw;
  left: 8vw;
  position: fixed;
  grid-column-start: 2;
  display: none;
  @media only screen and (max-width: 750px) {
    display: none;
    width: 3.5rem;
  }
`;

export const BigContainer = styled.div<{
  display: boolean;
}>`
  bottom: 0;
  position: fixed;
  width: 100%;
  display: ${(props) => (props.display ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: 0.3s ${(props) => (props.display ? 'moveUp' : 'moveDown')} 0.1s forwards;
  @keyframes moveDown {
    to {
      transform: translateY(50%) scale(0%);
      display: none;
    }
  }

  @keyframes moveUp {
    from {
      transform: translateY(100%) scale(0%);
    }
    to {
      transform: translateY(0%) scale(1);
    }
  }

  @media only screen and (min-width: 992px) {
    padding-left: 220px;
  }
`;
