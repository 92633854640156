import React, { useState } from 'react';
import { Col, ConfigProvider, Empty, Progress, Row, Table, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import type { TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { CategoryBadge } from '../TasksRoute/styles';
import { findCatOrderIndexByCatId } from '../../../../../util/Category';
import { GoalsObj } from '../../TherapistChildContext';
import { CategoryOption, goalPercentage } from '../../utils';

interface Props {
  goal: GoalsObj[];
  // onSelect: (activityId: string) => void;
}

export const developmentProgressTooltipData = (goal: GoalsObj, t) => {
  return (
    <Row gutter={16}>
      <Col>
        <div>{t('therapist.childInfo.goal.general.developmentProgress')}:</div>
        <div>{t('therapist.dashboard.myClient.clientInfo.notes.progress')}:</div>
        <div style={{ margin: '10px 0', height: '1px', backgroundColor: 'white' }}></div>
        <div>{t('therapist.childInfo.goal.general.initProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.objectiveProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.highestProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.metric')}:</div>
      </Col>
      <Col>
        <div>{goalPercentage(goal.currentNumerator, goal.initNumerator, goal.successNumerator)}%</div>
        <div>{goal.currentNumerator}</div>
        <div style={{ margin: '10px 0 10px -16px', height: '1px', backgroundColor: 'white' }}></div>
        <div>{goal.initNumerator}</div>
        <div>{goal.successNumerator}</div>
        <div>{goal.initDenominator}</div>
        <div>{t(`therapist.consultationScreen.goalTable.metrics.${goal.metric}`)}</div>
      </Col>
    </Row>
  );
};

const GoalTable = ({ goal }: Props) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const [sortedInfo, setSortedInfo] = useState<SorterResult<GoalsObj>>({});
  const handleChange: TableProps<GoalsObj>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<GoalsObj>);
  };

  // table columns
  const goalName: ColumnType<GoalsObj> = {
    title: t('therapist.dashboard.myClient.clientInfo.notes.goal'),
    render: (goal: GoalsObj) => (
      <>
        <Tooltip placement="topLeft" title={`${t('therapist.childInfo.general.todoType.goal')} ${goal.goalSequence}`}>
          {`${t('therapist.childInfo.general.todoType.goal')} ${goal.goalSequence}`}
        </Tooltip>
      </>
    ),
    fixed: 'left',
  };

  const description: ColumnType<GoalsObj> = {
    title: t('therapist.childInfo.goal.goalTable.description'),
    render: (goal: GoalsObj) => (
      <>
        <Tooltip placement="topLeft" title={goal.description}>
          {goal.description}
        </Tooltip>
      </>
    ),
    width: 360,
  };

  const developmentProgress: ColumnType<GoalsObj> = {
    title: t('therapist.childInfo.goal.general.developmentProgress'),
    key: 'developmentProgress',
    sorter: (a, b) =>
      goalPercentage(a.currentNumerator, a.initNumerator, a.successNumerator) -
      goalPercentage(b.currentNumerator, b.initNumerator, b.successNumerator),
    sortOrder: sortedInfo.columnKey === 'developmentProgress' ? sortedInfo.order : null,
    ellipsis: {
      showTitle: false,
    },
    render: (goal: GoalsObj) => (
      <>
        <Tooltip
          placement="topLeft"
          title={developmentProgressTooltipData(goal, t)}
          style={{ minWidth: 'fit-content' }}
        >
          <div style={{ display: 'flex' }}>
            <Progress
              percent={goalPercentage(goal.currentNumerator, goal.initNumerator, goal.successNumerator)}
              size="small"
              status="active"
              format={() => `${goalPercentage(goal.currentNumerator, goal.initNumerator, goal.successNumerator)} %`}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text type="secondary">{`${goal.initNumerator} / ${goal.currentNumerator} / 
            ${goal.successNumerator} ${t(`therapist.consultationScreen.goalTable.metrics.${goal.metric}`)}
            `}</Text>
          </div>
        </Tooltip>
      </>
    ),
    width: 200,
  };

  const mainSkill: ColumnType<GoalsObj> = {
    title: t('therapist.dashboard.myClient.clientInfo.notes.skill'),
    key: 'mainSkill',
    ellipsis: {
      showTitle: false,
    },
    render: (goal: GoalsObj) => (
      <Tooltip placement="topLeft" title={goal.skill.name}>
        {goal.skill.name}
      </Tooltip>
    ),
    width: 240,
  };

  const category: ColumnType<GoalsObj> = {
    title: t('therapist.childInfo.goal.goalTable.category'),
    key: 'category',
    filters: CategoryOption(),
    onFilter: (value, goal) => findCatOrderIndexByCatId(goal.skill.category.id) === value,
    sorter: (a, b) => findCatOrderIndexByCatId(a.skill.category.id) - findCatOrderIndexByCatId(b.skill.category.id),
    sortOrder: sortedInfo.columnKey === 'category' ? sortedInfo.order : null,
    render: (goal: GoalsObj) => (
      <CategoryBadge catIndex={findCatOrderIndexByCatId(goal.skill.category.id)}>
        {goal.skill.category.name}
      </CategoryBadge>
    ),
    width: 240,
  };

  // table columns setting
  const Columns: ColumnsType<GoalsObj> = [goalName, description, developmentProgress, mainSkill, category];

  // table empty state
  const customizeRenderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Activity</span>}></Empty>
  );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        dataSource={goal}
        columns={Columns}
        onChange={handleChange}
        pagination={false}
        // onRow={(goal) => {
        //   return {
        //     onClick: () => {
        //       onSelect(goal.id);
        //     },
        //   };
        // }}
        scroll={{ x: 1120 }}
      />
    </ConfigProvider>
  );
};

export default GoalTable;
