import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  EmptyStateContainer,
  GeneralSegment,
  H2Title,
  ModalContainer,
  ModalContainerWithClose,
  SmallSectionTitle,
} from '../../../style';
import { BackgroundColor, MainColor } from '../WordTrackingRoute/styles';
import SVG from 'react-inlinesvg';

// general card container
export const DashboardCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DashboardCardScrollContainer = styled.div`
  display: flex;
  padding: 10px 0 25px 10px;
  margin-left: -10px;
  margin-right: -10px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;
// general card title and arrow button components
export const CardTopFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const CardMoreIcon = styled(Icon)`
  margin: 5px 0 0 0 !important;
`;
export const CardTitle = styled(SmallSectionTitle)<{ line: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
`;

// general metric segment
export const MetricsSegment = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding-bottom: 20px;
  border-radius: 8px;
  margin: 15px 0 45px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
// general metric tab component
export const MetricsFlexBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (min-width: 768px) {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 0px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      width: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0) !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0) !important;
    }
  }
`;
export const MetricsBox = styled.div`
  border-radius: 8px;
  padding: 24px;
  line-height: 1.8rem;
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    min-width: max-content;
  }
`;
export const InteractiveMetricsBox = styled(MetricsBox)<{ isSelected: boolean }>`
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => (props.isSelected ? MainColor : '#606060')};
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background: ${BackgroundColor};
      color: ${(props) => (props.isSelected ? MainColor : '#030303')};
    }
  }
`;
export const HighlightTopEdge = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  cursor: pointer;
  :: before {
    box-sizing: border-box;
    background: ${(props) => (props.isSelected ? MainColor : 'rgba(0,0,0,0)')};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    content: '';
    height: 4px;
    left: 24px;
    position: absolute;
    top: 0;
    width: calc(100% - 48px);
  }
`;
// general metric chart
export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;
export const ChartContainer = styled.div`
  height: 300px;
  min-width: 600px;
  width: 100%;
`;

// general empty state component
export const DashboardEmptyStateSegment = styled(GeneralSegment)`
  height: 250px;
  margin: 10px 0 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DashboardEmptyStateContainer = styled(EmptyStateContainer)`
  height: 250px;
`;

// general skill component for goal and category
export const SkillsContainer = styled.div`
  height: max-content;
  cursor: default;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
  width: max-content;
`;
export const SkillStatusBadge = styled.div<{ type: string }>`
  background-color: ${({ type }) => {
    if (type === 'mastered') {
      return '#FCA549';
    } else if (type === 'inProgress') {
      return '#FFF8F1';
    } else if (type === 'notYetInProgress') {
      return '#f5f5f5';
    }
    return '#f5f5f5';
  }};
  color: ${({ type }) => {
    if (type === 'mastered') {
      return 'white';
    } else if (type === 'inProgress') {
      return '#FCA549';
    } else if (type === 'notYetInProgress') {
      return '#909090';
    }
    return '#909090';
  }};
  border-radius: 15px;
  min-width: max-content;
  max-width: min-content;
  padding: 4px 10px;
  letter-spacing: 0.2px;
  font-size: 1.2rem;
  font-weight: 800;
  white-space: break-spaces;
`;
export const SkillCountBadge = styled(SkillStatusBadge)<{ type: string }>`
  padding: 2px 10px;
  margin: 0 0 10px 8px;
`;
export const SkillsIcon = styled(SVG)`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-bottom: 2px;
`;

// general dashboard modal
export const GoalModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  background-color: #00000090;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  @media only screen and (min-width: 768px) {
    bottom: initial;
    right: initial;
    align-items: center;
    justify-content: center;
    text-align: -webkit-center;
  }
`;

export const InsideCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;
export const BackButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
`;
export const GoalModalContainerWithClose = styled(ModalContainerWithClose)`
  height: 100%;
  width: 100%;
  padding-top: 10%;
  @media only screen and (min-width: 768px) {
    padding-top: 0;
    height: initial;
    width: initial;
  }
`;
export const GoalModalContainer = styled(ModalContainer)`
  display: grid;
  grid-template-rows: 1fr 0.5fr 8fr;
  padding: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  @media only screen and (min-width: 768px) {
    display: block;
    padding: 0 0 40px 0;
    border-radius: 15px;
  }
`;

export const ContainerWidth = styled.div`
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 500px;
  }
  @media only screen and (min-width: 992px) {
    width: 600px;
  }
  @media only screen and (min-width: 1200px) {
    width: 700px;
  }
`;
export const GoalModalHeader = styled(ContainerWidth)`
  margin-bottom: 20px;
  padding: 0 23px 20px 23px;
  text-align: left;
  background-color: ${MainColor};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @media only screen and (min-width: 768px) {
    padding: 10px 48px 20px 48px;
  }
`;

export const GoalModalContent = styled(ContainerWidth)`
  height: 100%;
  text-align: left;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0 15px;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
  @media only screen and (min-width: 768px) {
    height: 400px;
    padding: 0;
  }
`;

export const GoalModalTabContainer = styled(ContainerWidth)`
  margin-bottom: 10px;
  padding: 0 15px;
  @media only screen and (min-width: 768px) {
    padding: 0 40px;
  }
`;

export const ContentContainer = styled.div`
  padding: 5px 8px;
  width: 100%;
  @media only screen and (min-width: 768px) {
    padding: 5px 48px 0 48px;
  }
`;

export const GoalCategoryBadge = styled.div`
  border-radius: 15px;
  min-width: max-content;
  max-width: min-content;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin: 0 10px 10px 0;
  background: white;
  color: ${MainColor};
  border: 1px solid ${MainColor};
`;

export const GoalTitle = styled(H2Title)`
  color: white;
`;
