import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CountWordModalMask,
  CountWordModalContainer,
  CountButton,
  Header,
  ButtonContainer,
  FlexContainer,
  Count,
  CountWordModalContent,
  AddCountImg,
} from './styles';
import { ActionTitle, HeaderTitle, CancelButton, SaveButton, HeaderDescription } from '../../../../../../../style';
import { Word } from '../../../../../../../graphql/queries/types/WordTracking';
import TalkBubble from '../../../../assets/TalkBubble.svg';

type Props = {
  type: string;
  wordItem: Word;
  showModal: boolean;
  handleClose: () => void;
  handleEditCountWord: (wordId: number, value: number) => void;
};

const CountWordModal = ({ type, wordItem, showModal, handleEditCountWord, handleClose }: Props) => {
  const { t } = useTranslation();
  const word = wordItem || {};
  const [latestCount, setLatestCount] = useState<number>(0);

  useEffect(() => {
    if (wordItem != null) {
      setLatestCount(wordItem[type]);
    }
  }, [wordItem]);

  const addCount = () => {
    setLatestCount(latestCount + 1);
  };

  const subtractCount = () => {
    if (latestCount > 0) {
      setLatestCount(latestCount - 1);
    }
    return;
  };

  const onSubmitClick = () => {
    handleEditCountWord(wordItem.id, latestCount);
    handleClose();
  };

  return (
    <CountWordModalMask open={showModal}>
      <CountWordModalContainer>
        <Header>
          <AddCountImg src={TalkBubble}></AddCountImg>
          <HeaderTitle>{t('client.wordTracking.modal.count.title')}</HeaderTitle>
          <HeaderDescription>
            {t('client.wordTracking.modal.count.total')} {word.word} {t(`client.wordTracking.modal.count.${type}`)}
          </HeaderDescription>
        </Header>
        <CountWordModalContent>
          <FlexContainer>
            <CountButton onClick={subtractCount}>&#8722;</CountButton>
            <Count>{latestCount}</Count>
            <CountButton onClick={addCount}>+</CountButton>
          </FlexContainer>
        </CountWordModalContent>
        <ButtonContainer>
          <CancelButton onClick={handleClose}>
            <ActionTitle>{t('client.wordTracking.modal.count.close')}</ActionTitle>
          </CancelButton>
          <SaveButton onClick={onSubmitClick}>
            <ActionTitle>{t('client.wordTracking.modal.count.submit')}</ActionTitle>
          </SaveButton>
        </ButtonContainer>
      </CountWordModalContainer>
    </CountWordModalMask>
  );
};

export default CountWordModal;
