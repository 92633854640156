import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { Word } from '../queries/types/WordTracking';

export const EDIT_WORD_MUTATION = gql`
  mutation EditWord($wordId: ID!, $attribute: String!, $value: String!) {
    editWord(wordId: $wordId, attribute: $attribute, value: $value) {
      word {
        id
        childId
        word
        repeatCount
        speakCount
        editable
        complete
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface EditWordData {
  word: Word;
  returnMessage: ReturnMessage;
}

export interface EditWordVariables {
  wordId: number;
  attribute: 'word' | 'repeat_count' | 'speak_count';
  value: string;
}
