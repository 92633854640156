import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalHeader, ModalMask, SaveButton } from '../../../../../../../style';

export const DeleteWordModalMask = styled(ModalMask)``;

export const DeleteWordModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)``;

export const ButtonContainer = styled(ModalButtonContainer)``;

export const DeleteButton = styled(SaveButton)`
  border: solid 1px #fa3e3e;
  background: #fa3e3e;
`;
