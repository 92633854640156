import styled from 'styled-components';
import { MetricsSegment } from '../styles';

export const ActivityMetricsSegment = styled(MetricsSegment)``;

export const TooltipContainer = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  white-space: nowrap;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
`;
