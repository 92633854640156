import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import HomeworkDashboardComponent from '../Communication/components/HomeworkDashboardComponent';
import { RouteSectionContainer } from '../styles';
import { ClientContext } from '../ClientContext';
import { Client_client_children_product } from '../../../graphql/queries/types/Client';
import ActivityTab from './ActivityTab';
import PlanTab from './PlanTab';
import RouteTabMenu from '../../common/RouteTabMenu';
import { CPC_PRODUCT_TYPES } from '../../../util/Product';

interface Props {
  product: Client_client_children_product;
}

interface TaskRouteTab {
  taskPath: string;
}

const TaskRoute = ({ product }: Props) => {
  const { t } = useTranslation();
  const { client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  let { taskPath } = useParams() as TaskRouteTab;
  const history = useHistory();

  const tabData = {
    hw: {
      title: t('client.task.hwTabTitle'),
      content: <HomeworkDashboardComponent roomId={child.room.id} childId={child.id} />,
    },
    plan: { title: t('client.task.planTabTitle'), content: <PlanTab /> },
    activity: {
      title: t('client.task.activityTabTitle'),
      content: <ActivityTab productType={productType} />,
    },
  };

  const tabs = isCPC ? ['plan', 'activity', 'hw'] : ['plan', 'activity'];

  const handleTabSelect = (tab: string) => {
    history.push('/task/' + tab);
  };

  return (
    <RouteSectionContainer>
      <RouteTabMenu tabList={tabs} tabData={tabData} path={taskPath} handleTabSelect={handleTabSelect} />
    </RouteSectionContainer>
  );
};

export default TaskRoute;
