import React, { useState, useEffect } from 'react';
import { UserInfo, UserInfoText, ButtonGroup, CancelButton, SaveButton, UserInfoEditButton } from '../styles';
import { ReturnMessage } from '../../../../types';
import { useMutation } from 'react-apollo';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { Client_client_children } from '../../../../graphql/queries/types/Client';
import { Select } from 'antd';
import { getDate } from '../../../../helpers/functions';

interface Props {
  userId: string;
  value: string;
  children: Client_client_children[];
  language: string;
  setChild: React.Dispatch<React.SetStateAction<Client_client_children>>;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
}

const ActiveChildDropdown = ({ userId, value, children, language, setChild, setReturnMessage }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  // update message near header
  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
  }, [data]);

  function handleChange(value) {
    setNewValue(value);
  }

  const handleClickCancel = () => {
    setNewValue(value);
    setIsEdit(false);
  };

  const handleClickSave = async () => {
    await editUserInformation({ variables: { userId, attribute: 'Active Child Id', value: newValue } });
    const newActiveChild = children.filter((child) => child.id == newValue)[0];
    setChild(newActiveChild);
    setIsEdit(false);
  };

  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const currentChildInfo =
    children.filter((child) => child.id == value)[0].name +
    ' (' +
    children.filter((child) => child.id == value)[0].product.name +
    ') - ' +
    getDate(children.filter((child) => child.id == value)[0].startServiceAt, language);

  return (
    <UserInfo>
      <div>
        {/* <UserInfoText>{t('auth.locale')}</UserInfoText> */}
        <UserInfoText>{'Children'}</UserInfoText>
        {isEdit ? (
          <Select
            style={{ width: '100%' }}
            placeholder="Please select child"
            defaultValue={currentChildInfo}
            onChange={handleChange}
          >
            {children.map((child) => (
              <Option key={child.id} value={child.id}>
                {child.name + ' (' + child.product.name + ') - ' + getDate(child.startServiceAt, language)}
              </Option>
            ))}
          </Select>
        ) : (
          <p style={{ inlineSize: '150px' }}>{currentChildInfo}</p>
        )}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={() => handleClickCancel()}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={() => handleClickSave()}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={() => setIsEdit(true)}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default ActiveChildDropdown;
