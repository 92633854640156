import React, { useContext } from 'react';
import { Button, Collapse, List, Tooltip, Typography } from 'antd';
import { ConsultationsAndPlanObj, TherapistChildContext } from '../../TherapistChildContext';
import { useTranslation } from 'react-i18next';
import CreatePlanButton from '../../feature/CreatePlanButton';
import { SnippetsOutlined } from '@ant-design/icons';
import { ButtonContainer, DetailsItemContainer } from '../../components/ConsultationAndPlanCard/styles';
import { CaretRightOutlined, SolutionOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  consultationList: ConsultationsAndPlanObj[];
}

const PublishedPlanList = ({ consultationList }: Props) => {
  const { t } = useTranslation();
  const { Text, Title } = Typography;
  const { Panel } = Collapse;
  const history = useHistory();
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);

  const genExtra = (item: ConsultationsAndPlanObj) => (
    <>
      <ButtonContainer>
        <CreatePlanButton consultation={item} showTitle={true}></CreatePlanButton>
        <Button
          type="default"
          shape="round"
          icon={<SolutionOutlined translate={undefined} />}
          onClick={() => history.push(`/child/${childId}/plan/${item.id}`)}
        >
          {t('therapist.childInfo.general.button.view')}
        </Button>
      </ButtonContainer>
    </>
  );

  return (
    <List
      itemLayout="vertical"
      dataSource={consultationList}
      grid={{ column: 1 }}
      renderItem={(item, index) => (
        <List.Item style={{ border: '1px solid rgba(0,0,0,.06)', marginBottom: '20px', minWidth: '600px' }}>
          <div style={{ width: '100%' }}>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined translate={undefined} rotate={isActive ? 90 : 0} />}
              style={{ width: '100%' }}
            >
              <Panel
                header={
                  <div style={{ display: 'block' }}>
                    <Title style={{ marginBottom: '0px' }} level={5}>
                      {item.title}
                    </Title>
                  </div>
                }
                style={{ padding: '5px 0' }}
                key={index}
                extra={genExtra(item)}
              >
                <DetailsItemContainer>
                  <Tooltip title={t('therapist.dashboard.myClient.clientInfo.notes.internalNotes')} arrowPointAtCenter>
                    <SnippetsOutlined translate={undefined} style={{ paddingTop: '3px' }} />
                  </Tooltip>
                  <Text>
                    {item.notes ? item.notes.internalNotes : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
                  </Text>
                </DetailsItemContainer>
              </Panel>
            </Collapse>
          </div>
        </List.Item>
      )}
    />
  );
};

export default PublishedPlanList;
