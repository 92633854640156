import { Divider, Menu, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useRouteMatch } from 'react-router';
import BasicInfo from './BasicInfo';
import { ChildInfoSider, CollapseButton, CollapseSiderButtonContainer, SiderSectionTitleContainer } from '../styles';
import {
  CarryOutOutlined,
  ReadOutlined,
  MessageOutlined,
  RightOutlined,
  LeftOutlined,
  BarChartOutlined,
  VideoCameraOutlined,
  FundProjectionScreenOutlined,
  RocketOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ChildSider = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [hoverCollapsed, setHoverCollapsed] = useState(true);
  const [showCollapseButton, setShowCollapseButton] = useState(false);
  const showSider = collapsed ? hoverCollapsed : collapsed;
  const selectedKeys = pathname.split('/').slice(0, 4).join('/');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleOnHoverSider = () => {
    if (collapsed) {
      setHoverCollapsed(false);
    } else {
      setShowCollapseButton(true);
    }
  };

  const handleOnLeaveSider = () => {
    if (collapsed) {
      setHoverCollapsed(true);
    } else {
      setShowCollapseButton(false);
    }
  };

  const handleOnHoverButton = () => {
    if (!collapsed) {
      setShowCollapseButton(true);
    }
  };

  const handleOnLeaveButton = () => {
    if (!collapsed) {
      setShowCollapseButton(false);
    }
  };

  if (!match) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div onMouseOver={handleOnHoverSider} onMouseOut={handleOnLeaveSider}>
        <ChildInfoSider
          theme="light"
          collapsible
          collapsed={showSider}
          onCollapse={(value) => setCollapsed(value)}
          trigger={null}
          collapsedWidth={20}
          width={240}
        >
          <Menu mode="inline" selectedKeys={[selectedKeys]} style={{ borderRight: 'none' }}>
            <BasicInfo />
            <div style={{ padding: '0 30px' }}>
              <Divider style={{ margin: 0 }} />
            </div>
            <SiderSectionTitleContainer>
              <Text type="secondary">{t('therapist.childInfo.sider.task')}</Text>
            </SiderSectionTitleContainer>
            <Menu.Item key={`${match.url}/todo`} style={{ paddingLeft: '30px' }}>
              <CarryOutOutlined translate={undefined} />
              <span>{t('therapist.childInfo.sider.todo')}</span>
              <NavLink to={`${match.url}/todo`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/consultation`} style={{ paddingLeft: '30px' }}>
              <VideoCameraOutlined translate={undefined} />
              <span>{t('therapist.childInfo.sider.consultation')}</span>
              <NavLink to={`${match.url}/consultation`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/plan`} style={{ paddingLeft: '30px' }}>
              <SolutionOutlined translate={undefined} />
              <span>{t('therapist.childInfo.sider.plan')}</span>
              <NavLink to={`${match.url}/plan`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/homeworks`} style={{ paddingLeft: '30px' }}>
              <ReadOutlined translate={undefined} />
              <span>{t('therapist.dashboard.myClient.clientInfo.topbar.homeworks')}</span>
              <NavLink to={`${match.url}/homeworks`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/messages`} style={{ paddingLeft: '30px' }}>
              <MessageOutlined translate={undefined} />
              <span>{t('therapist.dashboard.myClient.clientInfo.topbar.messages')}</span>
              <NavLink to={`${match.url}/messages`} />
            </Menu.Item>
            <SiderSectionTitleContainer>
              <Text type="secondary">{t('therapist.childInfo.sider.childStatus')}</Text>
            </SiderSectionTitleContainer>
            <Menu.Item key={`${match.url}/dashboard`} style={{ paddingLeft: '30px' }}>
              <BarChartOutlined translate={undefined} />
              <span>{t('therapist.childInfo.sider.dashboard')}</span>
              <NavLink to={`${match.url}/dashboard`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/goal`} style={{ paddingLeft: '30px' }}>
              <FundProjectionScreenOutlined translate={undefined} />
              <span>{t('therapist.childInfo.sider.goal')}</span>
              <NavLink to={`${match.url}/goal`} />
            </Menu.Item>
            <Menu.Item key={`${match.url}/tasks`} style={{ paddingLeft: '30px' }}>
              <RocketOutlined translate={undefined} />
              <span>{t('therapist.dashboard.myClient.clientInfo.topbar.tasks')}</span>
              <NavLink to={`${match.url}/tasks`} />
            </Menu.Item>
          </Menu>
        </ChildInfoSider>
      </div>
      <Tooltip title={collapsed ? 'Expand' : 'Collapse'} placement="right">
        <CollapseSiderButtonContainer
          onMouseOver={handleOnHoverButton}
          onMouseOut={handleOnLeaveButton}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <CollapseButton
            type="default"
            shape="circle"
            size="small"
            show={collapsed ? true : showCollapseButton}
            icon={collapsed ? <RightOutlined translate={undefined} /> : <LeftOutlined translate={undefined} />}
          />
        </CollapseSiderButtonContainer>
      </Tooltip>
    </>
  );
};

export default React.memo(ChildSider);
