import React from 'react';
import { ActionTitle } from '../../../style';
import { TabContent, TabMenuContainer, TabMenuItem, HighlightBottomEdge } from '../TabMenu/styles';

type Props = {
  tabList: string[];
  tabData: {
    [key: string]: {
      title: string;
      content: JSX.Element;
    };
  };
  path: string;
  handleTabSelect: (tab: string) => void;
};

const RouteTabMenu = (props: Props) => {
  const { tabList, path, tabData, handleTabSelect } = props;
  window.scroll(0, 0);
  return (
    <>
      <TabMenuContainer>
        {tabList.map((tab: string) => {
          const isSelect = tab === path;
          return (
            <HighlightBottomEdge isSelected={isSelect}>
              <TabMenuItem active={isSelect} onClick={() => handleTabSelect(tab)}>
                <ActionTitle>{tabData[tab].title}</ActionTitle>
              </TabMenuItem>
            </HighlightBottomEdge>
          );
        })}
      </TabMenuContainer>
      <TabContent>{tabData[path].content}</TabContent>
    </>
  );
};

export default RouteTabMenu;
