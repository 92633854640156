import React, { useState } from 'react';
import { Child } from '../../../../../../graphql/queries/types/Child';
import { Segment, Image, Button, TextArea } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import {
  CreateMessage,
  CreateMessageVariables,
  CreateMessage_createMessage_meta_Reply,
} from '../../../../../../graphql/mutations/types/CreateMessage';
import { CREATE_MESSAGE } from '../../../../../../graphql/mutations/createMessage';
import { CHILD_QUERY } from '../../../../../../graphql/queries/child';
import {
  ChildHomeworks_child_homeworks_homeworkSubmission,
  ChildHomeworks_child_homeworks_homeworkSubmission_message,
} from '../../../../../../graphql/queries/types/ChildHomeworks';
import UploadMessageComponent from '../../../../../Client/Communication/components/UploadMessageComponent';
import { renderReplyContent } from '../../../../../Client/Communication/components/ClientMessageChatBox';

interface Props {
  message: ChildHomeworks_child_homeworks_homeworkSubmission_message;
  roomId: string;
  homeworkId: string;
  childId: string;
  userId: string;
}

const HomeworkReplyComponent = ({ message, roomId, homeworkId, childId, userId }: Props) => {
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');

  const [createMessage] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    update(cache, { data }) {
      // TODO: currently not work, should check what happened
      // if (!data) {
      //   return null;
      // }
      // const queryData = cache.readQuery<Child, ChildVariables>({
      //   query: CHILD_QUERY,
      //   variables: { childId },
      // });
      // if (!queryData || !queryData.child) {
      //   return null;
      // }
      // const newReply = data.createMessage.meta as CreateMessage_createMessage_meta_Reply;
      // const c = queryData.child;
      // const homeworkIndex = c.homeworks.findIndex(h => h.id == homeworkId);
      // const h = c.homeworks[homeworkIndex].homeworkSubmission as ChildHomeworks_child_homeworks_homeworkSubmission;
      // h.message.replies.push(newReply);
      // queryData.child.homeworks[homeworkIndex].homeworkSubmission = h;
      // cache.writeQuery<Child, ChildVariables>({
      //   query: CHILD_QUERY,
      //   variables: { childId },
      //   data: { child: queryData.child },
      // });
    },
  });

  const handleClickSend = async () => {
    await createMessage({
      variables: {
        body: replyText,
        userId,
        roomId,
        homeworkId: null,
        mainMessageId: message.id,
        file: null,
        fileName: null,
      },
    });

    setIsReply(false);
    setReplyText('');
  };

  const { replies } = message;
  return (
    <div>
      {replies.length > 0 && (
        <Segment.Group>
          {replies.map((reply) => (
            <Segment key={reply.id} compact>
              <div style={{ display: 'flex' }}>
                <Image circular size="mini" bordered src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" />
                <p style={{ margin: '8px' }}>{reply.message.user.name}</p>
              </div>
              <div style={{ marginLeft: '45px' }}>
                {renderReplyContent(reply.message)}
                <p style={{ color: '#B2B2B2', fontSize: '11px' }}>{reply.message.createdAt}</p>
              </div>
            </Segment>
          ))}
        </Segment.Group>
      )}
      {isReply ? (
        <div>
          <UploadMessageComponent roomId={roomId} mainMessageId={message.id} />
          <Button
            size="tiny"
            style={{ color: 'teal', border: 'none', background: 'none' }}
            onClick={() => setIsReply(false)}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button size="tiny" color="teal" basic style={{ borderRadius: '22.5px' }} onClick={() => setIsReply(true)}>
          Reply
        </Button>
      )}
    </div>
  );
};

export default HomeworkReplyComponent;
