import { Consultation } from '../types';
import AWS from 'aws-sdk';

export function getAge(dateString: Date): number[] {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (0 > m || (0 === m && today.getDate() < birthDate.getDate())) {
    age--;
    m += 12;
  }
  return [age, m];
}

export function getPreviousConsultation(consultations: Consultation[]): Consultation | undefined {
  return consultations
    .slice()
    .reverse()
    .find((consultation: Consultation) => Date.parse(consultation.consultationTime) < Date.now());
}

export function getNextConsultation(consultations: Consultation[]): Consultation | undefined {
  return consultations.find((consultation: Consultation) => Date.parse(consultation.consultationTime) > Date.now());
}

export function getDate(date: string, locale: string): string {
  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

export function openInNewTab(url: string): void {
  const win = window.open(url, '_blank') as Window;
  win.focus();
}

AWS.config.region = process.env.REACT_APP_AWS_UPLOADER_S3_REGION;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID ?? '',
});

export async function getSignedUrl(folder: string, name: string, bucket: string) {
  const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_UPLOADER_S3_REGION,
  });
  const url = await s3.getSignedUrlPromise('getObject', {
    Bucket: bucket as string,
    Key: `${folder}/${name}`,
  });
  return url;
}

export async function S3consultationVideoRoute(
  therapistUUID: string,
  therapistName: string,
  childName: string,
  consultationId: string,
  fileName: string,
): Promise<string> {
  const S3_UPLOADER_BUCKET = process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET ?? '';
  const folderName = `consultation-video-upload/${therapistUUID}-${therapistName}/${childName}/consultation-id-${consultationId}`;
  const url = await getSignedUrl(folderName, fileName, S3_UPLOADER_BUCKET);
  return url;
}
