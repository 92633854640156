import styled from 'styled-components';

export const TimelineComponent = styled.div`
  border-left: 4px solid #47e4c2;
  margin: 10px auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 0 0 10px 15px;
  list-style: none;
  font-weight: 100;
  max-width: 80%;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    box-shadow: 0 0 0 4px #47e4c2;
    left: -7.5px;
    background: white;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    z-index: 1;
  }
  @media only screen and (max-width: 600px) {
    padding: 0 0 10px 20px;
  }
`;

interface TimelineEventProps {
  time: string;
}

export const TimelineEvent = styled.div`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 25px;
  margin-bottom: 30px;
  position: relative;
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
`;

export const DateTimeText = styled.p`
  left: -140px;
  content: '\a';
  text-align: right;
  font-weight: bolder;
  position: absolute;
  display: block;
  white-space: pre-wrap;
  margin: 5px 0px;
  min-width: 90px;
  @media only screen and (max-width: 600px) {
    left: -110px;
    font-size: 0.6em;
    min-width: 75px;
    width: 75px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    display: inline;
    justify-content: none;
  }
`;

export const ConsultationInfoContainer = styled.div`
  width: 70%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ConsultationButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 180px;
  @media only screen and (max-width: 600px) {
    align-items: unset;
  }
`;

interface TextProps {
  size?: string;
  weight?: string | number;
}

export const Text = styled.span<TextProps>`
  display: inline-block;
  margin-right: 20px;
  font-size: ${(props: TextProps) => props.size};
  font-weight: ${(props: TextProps) => props.weight};
`;

interface PreviousConcernProps {
  text: string;
}

export const PreviousConcern = styled.div<PreviousConcernProps>`
  &:before {
    content: '${({ text }: PreviousConcernProps) => `${text}: `}';
    font-weight: bold;
  }
  width: 75%;
  @media only screen and (max-width: 600px) {
    margin-top: 10px;
    width: 100%;
  }
`;

interface StartButtonProps {
  isBackupLinkAvailable?: boolean;
}

export const StartVideoButton = styled.button<StartButtonProps>`
  display: block;
  cursor: ${(props) => (!props.isBackupLinkAvailable ? 'pointer' : 'default')};
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  background-color: ${(props) => (props.isBackupLinkAvailable ? 'grey' : 'teal')};
  color: white;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

export const StartButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  border: 2px solid #47e4c2;
  background-color: white;
  color: #47e4c2;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

export const CancelButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;
  background-color: #ff7d7d;
  color: white;
  min-width: 160px;
  @media only screen and (max-width: 600px) {
    display: inline-block;
    min-width: 40px;
  }
`;

export const AcceptButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  background-color: #ff6d93;
  color: white;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

export const AcceptedButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  background-color: #c9c9c9;
  color: white;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

export const MessageButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  border: 2px solid #47e4c2;
  background-color: white;
  color: #47e4c2;
  width: 100%;
`;

export const SmsDropdownContainer = styled.div<{ therapist: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 180px;
  @media only screen and (max-width: 600px) {
    width: auto;
    max-width: unset;
  }
`;

export const SmsButton = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  border-radius: 22.5px;
  padding: 2px 12px;
  font-family: sans-serif;
  margin: 4px 0;

  border: 2px solid #ffd77a;
  background-color: white;
  color: #505050;
  width: 100%;
  @media only screen and (max-width: 600px) {
    padding: 2px 24px;
  }
`;

export const SmsIcon = styled.div`
  display: flex;
  font-size: 2.5em;
  cursor: pointer;
  align-items: center;
`;

export const DropdownContent = styled.div<{ therapist: boolean }>`
  max-height: 0;
  flex-direction: row;
  overflow: hidden;
  border: 0 solid;
  border-radius: 12px;
  line-height: 0rem;
  width: fit-content;
  &.open {
    max-height: 100%;
    line-height: 100%;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const DropdownOption = styled.div`
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px;
  &:hover {
    color: black;
    background-color: #ffd77a;
    border-radius: 8px;
    margin: 8px;
    cursor: pointer;
  }
`;

export const SubDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
`;
