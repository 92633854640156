import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

function GoogleLoginComponent({ handleOAuthLogin }) {
  return (
    <div>
      <GoogleLogin
        width="380px"
        onSuccess={(credentialResponse) => {
          handleOAuthLogin(jwt_decode(credentialResponse.credential ?? ''));
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
}

export default GoogleLoginComponent;
