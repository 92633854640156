import { createContext } from 'react';

interface State {
  consultationUrl: string | null;
  cnConsultationUrl: string | null;
}

const defaultState: State = {
  consultationUrl: '',
  cnConsultationUrl: '',
};

export default createContext(defaultState);
