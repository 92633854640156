import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Divider } from 'semantic-ui-react';
import defaultChild from '../../../assets/defaultChild.png';
import ChildCardModal from './components/ChildCardModal';
import RequestBuyMoreButton from './components/RequestBuyMoreButton';
import ReactHtmlParser from 'react-html-parser';
import ClientGoalsCards from './components/ClientGoalsCards';
import { RouteSectionContainer } from '../styles';
import { ClientContext } from '../ClientContext';
import { Client_client_children_product } from '../../../graphql/queries/types/Client';
import { Description, GeneralSegment, H1Title, SectionTitle, SmallContentTitle } from '../../../style';
import { getSignedUrl } from '../../../helpers/functions';
import { THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3 } from '../../../constants/s3MediaFolder';
import {
  UserProfileContainer,
  UserProfilePictureContainer,
  TherapistContainer,
  TherapistSectionTitle,
  TherapistName,
  TherapistTitle,
  TherapistInfoContainer,
  BioContainer,
  BioContentContainer,
  BioBulletPoint,
} from './styles';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../../util/Product';

interface Props {
  product: Client_client_children_product;
}

const ProgramRoute = ({ product }: Props) => {
  window.scroll(0, 0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [therapistAvatar, setTherapistAvatar] = useState<string>('');
  const {
    client,
    client: {
      user: { locale },
    },
  } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const { t } = useTranslation();
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);
  const productName = child.product.name;
  const membershipDue = client.membershipDue.toString().substring(0, 10);
  const therapistBios = child.therapist.therapistBios;

  const checkAndSetTherapistAvatar = async () => {
    if (!child.therapist.avatarFilename) {
      return;
    }

    const avatarFilename = child.therapist.avatarFilename;
    const therapistProfilePictureRoute = await getSignedUrl(
      THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3,
      avatarFilename,
      process.env.REACT_APP_AWS_PUBLIC_S3_BUCKET ?? '',
    );

    try {
      const response = await fetch(therapistProfilePictureRoute);
      if (response.status === 200) {
        setTherapistAvatar(therapistProfilePictureRoute);
      }
    } catch (error) {
      console.error('Error fetching the URL:', error);
    }
  };

  // const goToGroup = () => {
  //   window.open(HBI_LITE_PRODUCT_INFO[product.name].groupLink, '_blank', 'noreferrer');
  // };

  useEffect(() => {
    checkAndSetTherapistAvatar();
  }, []);

  return (
    <RouteSectionContainer>
      {isCPC && <H1Title>{t('client.program.programTitle')}</H1Title>}
      {isPEA && <H1Title>{productName}</H1Title>}
      <GeneralSegment>
        <ChildCardModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          pic={child.pictureUrl || defaultChild}
          childId={child.id}
        />
        <UserProfileContainer data-cy="avatar">
          <UserProfilePictureContainer
            src={child.pictureUrl || defaultChild}
            alt="child's smiling face"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <span data-cy="name" style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: '10px' }}>
            {child.name}
          </span>
        </UserProfileContainer>
        <Description>
          {t('client.program.consultationsRemaining')}: <span>{client.consultationTimesRemain}</span>
        </Description>
        <Description>
          {t('client.program.membershipDue')}: <span>{membershipDue}</span>
        </Description>
        {client.consultationTimesRemain == 0 && !client.requestBuyMore && <RequestBuyMoreButton client={client} />}
      </GeneralSegment>
      <GeneralSegment>
        <SectionTitle>{t('client.program.sixMonthTitle')}</SectionTitle>
        <div>
          {child.childSixMonthGoal
            ? ReactHtmlParser(child.childSixMonthGoal.description)
            : t('client.program.tasksGoalsCard.goal.noSixMonthGoal')}
        </div>
      </GeneralSegment>
      <GeneralSegment>
        <SectionTitle>{t('client.program.threeMonthTitle')}</SectionTitle>
        <ClientGoalsCards goals={child.goals} productType={productType} />
      </GeneralSegment>
      <GeneralSegment>
        <SectionTitle>{t('client.program.teamTitle')}</SectionTitle>
        <Divider />
        {isPEA && (
          <>
            <SmallContentTitle>{t('client.program.supportingTeacher')}</SmallContentTitle>
            <div>{product.owner}</div>
            <Divider />
          </>
        )}
        <TherapistContainer>
          <TherapistSectionTitle>{t('client.program.supportingTherapist')}</TherapistSectionTitle>
          <UserProfileContainer data-cy="avatar">
            <UserProfilePictureContainer
              src={therapistAvatar.length > 0 ? therapistAvatar : defaultChild}
              alt="therapist's smiling face"
            />
            <TherapistInfoContainer>
              <TherapistName>{child.therapist.user.name}</TherapistName>
              <TherapistTitle>{child.therapist.title}</TherapistTitle>
            </TherapistInfoContainer>
          </UserProfileContainer>
        </TherapistContainer>
        <Divider />
        {therapistBios
          .filter((bio) => bio.language === locale.toUpperCase())
          .slice(0, 3)
          .map((bio) => (
            <BioContainer>
              <BioBulletPoint></BioBulletPoint>
              <BioContentContainer>{bio.bio}</BioContentContainer>
            </BioContainer>
          ))}
        {/* <Description onClick={goToGroup}>Join Class A Wechat Group</Description> */}
      </GeneralSegment>
    </RouteSectionContainer>
  );
};

export default ProgramRoute;
