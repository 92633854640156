import React from 'react';
import GoalProgressBar from '../../Goal/GoalProgressBar';
import { DescriptionContainer, GoalCardSegment } from './styles';
import { Client_client_children_goals } from '../../../../../graphql/queries/types/Client';
import { SmallDescription } from '../../../../../style';
import { CardTitle, CardMoreIcon, CardTopFlexContainer } from '../../styles';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../../../../util/Product';

interface Props {
  productType: string;
  goals: Array<Client_client_children_goals>;
  handleGoalModalOpen: (id: string, step: string) => void;
  height: number;
  line: number;
  marginBottom: number;
  marginRight: number;
  step: string;
}

const GoalCard = ({
  productType,
  goals,
  handleGoalModalOpen,
  height,
  line,
  marginBottom,
  marginRight,
  step,
}: Props) => {
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);
  if (goals.length === 0) {
    return <></>;
  }
  const goalCardContent = goals.map((goal: Client_client_children_goals, index: number) => {
    return (
      <div>
        <GoalCardSegment
          marginRight={marginRight}
          marginBottom={marginBottom}
          height={height}
          key={goal.id}
          onClick={() => handleGoalModalOpen(goal.id, step)}
        >
          <div>
            <CardTopFlexContainer>
              <CardTitle line={line}>{goal.skill.name}</CardTitle>
              <CardMoreIcon className="chevron right" />
            </CardTopFlexContainer>
            {isPEA && (
              <DescriptionContainer>
                <SmallDescription>
                  <span data-cy="goalDescription">{goal.description}</span>
                </SmallDescription>
              </DescriptionContainer>
            )}
          </div>
          <div>{isCPC && <GoalProgressBar goal={goal} height={3} />}</div>
        </GoalCardSegment>
      </div>
    );
  });

  return <>{goalCardContent}</>;
};
export default GoalCard;
