const countryList = [
  {
    key: 'af',
    value: 'af',
    text: 'Afghanistan',
  },
  {
    key: 'al',
    value: 'al',
    text: 'Albania',
  },
  {
    key: 'dz',
    value: 'dz',
    text: 'Algeria',
  },
  {
    key: 'ad',
    value: 'ad',
    text: 'Andorra',
  },
  {
    key: 'ao',
    value: 'ao',
    text: 'Angola',
  },
  {
    key: 'ag',
    value: 'ag',
    text: 'Antigua and Barbuda',
  },
  {
    key: 'ar',
    value: 'ar',
    text: 'Argentina',
  },
  {
    key: 'am',
    value: 'am',
    text: 'Armenia',
  },
  {
    key: 'au',
    value: 'au',
    text: 'Australia',
  },
  {
    key: 'at',
    value: 'at',
    text: 'Austria',
  },
  {
    key: 'az',
    value: 'az',
    text: 'Azerbaijan',
  },
  {
    key: 'bs',
    value: 'bs',
    text: 'Bahamas',
  },
  {
    key: 'bh',
    value: 'bh',
    text: 'Bahrain',
  },
  {
    key: 'bd',
    value: 'bd',
    text: 'Bangladesh',
  },
  {
    key: 'bb',
    value: 'bb',
    text: 'Barbados',
  },
  {
    key: 'by',
    value: 'by',
    text: 'Belarus',
  },
  {
    key: 'be',
    value: 'be',
    text: 'Belgium',
  },
  {
    key: 'bz',
    value: 'bz',
    text: 'Belize',
  },
  {
    key: 'bj',
    value: 'bj',
    text: 'Benin',
  },
  {
    key: 'bt',
    value: 'bt',
    text: 'Bhutan',
  },
  {
    key: 'bo',
    value: 'bo',
    text: 'Bolivia',
  },
  {
    key: 'ba',
    value: 'ba',
    text: 'Bosnia and Herzegovina',
  },
  {
    key: 'bw',
    value: 'bw',
    text: 'Botswana',
  },
  {
    key: 'br',
    value: 'br',
    text: 'Brazil',
  },
  {
    key: 'bn',
    value: 'bn',
    text: 'Brunei',
  },
  {
    key: 'bg',
    value: 'bg',
    text: 'Bulgaria',
  },
  {
    key: 'bf',
    value: 'bf',
    text: 'Burkina Faso',
  },
  {
    key: 'bi',
    value: 'bi',
    text: 'Burundi',
  },
  {
    key: 'kh',
    value: 'kh',
    text: 'Cambodia',
  },
  {
    key: 'cm',
    value: 'cm',
    text: 'Cameroon',
  },
  {
    key: 'ca',
    value: 'ca',
    text: 'Canada',
  },
  {
    key: 'cv',
    value: 'cv',
    text: 'Cape Verde',
  },
  {
    key: 'cf',
    value: 'cf',
    text: 'Central African Republic',
  },
  {
    key: 'td',
    value: 'td',
    text: 'Chad',
  },
  {
    key: 'cl',
    value: 'cl',
    text: 'Chile',
  },
  {
    key: 'cn',
    value: 'cn',
    text: 'China',
  },
  {
    key: 'co',
    value: 'co',
    text: 'Colombia',
  },
  {
    key: 'km',
    value: 'km',
    text: 'Comoros',
  },
  {
    key: 'cd',
    value: 'cd',
    text: 'Congo (Democratic Republic of the)',
  },
  {
    key: 'cg',
    value: 'cg',
    text: 'Congo (Republic of the)',
  },
  {
    key: 'cr',
    value: 'cr',
    text: 'Costa Rica',
  },
  {
    key: 'hr',
    value: 'hr',
    text: 'Croatia',
  },
  {
    key: 'cu',
    value: 'cu',
    text: 'Cuba',
  },
  {
    key: 'cy',
    value: 'cy',
    text: 'Cyprus',
  },
  {
    key: 'cz',
    value: 'cz',
    text: 'Czech Republic',
  },
  {
    key: 'dk',
    value: 'dk',
    text: 'Denmark',
  },
  {
    key: 'dj',
    value: 'dj',
    text: 'Djibouti',
  },
  {
    key: 'dm',
    value: 'dm',
    text: 'Dominica',
  },
  {
    key: 'do',
    value: 'do',
    text: 'Dominican Republic',
  },
  {
    key: 'tl',
    value: 'tl',
    text: 'East Timor',
  },
  {
    key: 'ec',
    value: 'ec',
    text: 'Ecuador',
  },
  {
    key: 'eg',
    value: 'eg',
    text: 'Egypt',
  },
  {
    key: 'sv',
    value: 'sv',
    text: 'El Salvador',
  },
  {
    key: 'gq',
    value: 'gq',
    text: 'Equatorial Guinea',
  },
  {
    key: 'er',
    value: 'er',
    text: 'Eritrea',
  },
  {
    key: 'ee',
    value: 'ee',
    text: 'Estonia',
  },
  {
    key: 'et',
    value: 'et',
    text: 'Ethiopia',
  },
  {
    key: 'fj',
    value: 'fj',
    text: 'Fiji',
  },
  {
    key: 'fi',
    value: 'fi',
    text: 'Finland',
  },
  {
    key: 'fr',
    value: 'fr',
    text: 'France',
  },
  {
    key: 'ga',
    value: 'ga',
    text: 'Gabon',
  },
  {
    key: 'gm',
    value: 'gm',
    text: 'Gambia',
  },
  {
    key: 'ge',
    value: 'ge',
    text: 'Georgia',
  },
  {
    key: 'de',
    value: 'de',
    text: 'Germany',
  },
  {
    key: 'gh',
    value: 'gh',
    text: 'Ghana',
  },
  {
    key: 'gr',
    value: 'gr',
    text: 'Greece',
  },
  {
    key: 'gd',
    value: 'gd',
    text: 'Grenada',
  },
  {
    key: 'gt',
    value: 'gt',
    text: 'Guatemala',
  },
  {
    key: 'gn',
    value: 'gn',
    text: 'Guinea',
  },
  {
    key: 'gw',
    value: 'gw',
    text: 'Guinea-Bissau',
  },
  {
    key: 'gy',
    value: 'gy',
    text: 'Guyana',
  },
  {
    key: 'ht',
    value: 'ht',
    text: 'Haiti',
  },
  {
    key: 'hn',
    value: 'hn',
    text: 'Honduras',
  },
  {
    key: 'hu',
    value: 'hu',
    text: 'Hungary',
  },
  {
    key: 'is',
    value: 'is',
    text: 'Iceland',
  },
  {
    key: 'in',
    value: 'in',
    text: 'India',
  },
  {
    key: 'id',
    value: 'id',
    text: 'Indonesia',
  },
  {
    key: 'ir',
    value: 'ir',
    text: 'Iran',
  },
  {
    key: 'iq',
    value: 'iq',
    text: 'Iraq',
  },
  {
    key: 'ie',
    value: 'ie',
    text: 'Ireland',
  },
  {
    key: 'il',
    value: 'il',
    text: 'Israel',
  },
  {
    key: 'it',
    value: 'it',
    text: 'Italy',
  },
  {
    key: 'jm',
    value: 'jm',
    text: 'Jamaica',
  },
  {
    key: 'jp',
    value: 'jp',
    text: 'Japan',
  },
  {
    key: 'jo',
    value: 'jo',
    text: 'Jordan',
  },
  {
    key: 'kz',
    value: 'kz',
    text: 'Kazakhstan',
  },
  {
    key: 'ke',
    value: 'ke',
    text: 'Kenya',
  },
  {
    key: 'ki',
    value: 'ki',
    text: 'Kiribati',
  },
  {
    key: 'kp',
    value: 'kp',
    text: 'North Korea',
  },
  {
    key: 'kr',
    value: 'kr',
    text: 'South Korea',
  },
  {
    key: 'kw',
    value: 'kw',
    text: 'Kuwait',
  },
  {
    key: 'kg',
    value: 'kg',
    text: 'Kyrgyzstan',
  },
  {
    key: 'la',
    value: 'la',
    text: 'Laos',
  },
  {
    key: 'lv',
    value: 'lv',
    text: 'Latvia',
  },
  {
    key: 'lb',
    value: 'lb',
    text: 'Lebanon',
  },
  {
    key: 'ls',
    value: 'ls',
    text: 'Lesotho',
  },
  {
    key: 'lr',
    value: 'lr',
    text: 'Liberia',
  },
  {
    key: 'ly',
    value: 'ly',
    text: 'Libya',
  },
  {
    key: 'li',
    value: 'li',
    text: 'Liechtenstein',
  },
  {
    key: 'lt',
    value: 'lt',
    text: 'Lithuania',
  },
  {
    key: 'lu',
    value: 'lu',
    text: 'Luxembourg',
  },
  {
    key: 'mk',
    value: 'mk',
    text: 'North Macedonia',
  },
  {
    key: 'mg',
    value: 'mg',
    text: 'Madagascar',
  },
  {
    key: 'mw',
    value: 'mw',
    text: 'Malawi',
  },
  {
    key: 'my',
    value: 'my',
    text: 'Malaysia',
  },
  {
    key: 'mv',
    value: 'mv',
    text: 'Maldives',
  },
  {
    key: 'ml',
    value: 'ml',
    text: 'Mali',
  },
  {
    key: 'mt',
    value: 'mt',
    text: 'Malta',
  },
  {
    key: 'mh',
    value: 'mh',
    text: 'Marshall Islands',
  },
  {
    key: 'mr',
    value: 'mr',
    text: 'Mauritania',
  },
  {
    key: 'mu',
    value: 'mu',
    text: 'Mauritius',
  },
  {
    key: 'mx',
    value: 'mx',
    text: 'Mexico',
  },
  {
    key: 'fm',
    value: 'fm',
    text: 'Micronesia',
  },
  {
    key: 'md',
    value: 'md',
    text: 'Moldova',
  },
  {
    key: 'mc',
    value: 'mc',
    text: 'Monaco',
  },
  {
    key: 'mn',
    value: 'mn',
    text: 'Mongolia',
  },
  {
    key: 'me',
    value: 'me',
    text: 'Montenegro',
  },
  {
    key: 'ma',
    value: 'ma',
    text: 'Morocco',
  },
  {
    key: 'mz',
    value: 'mz',
    text: 'Mozambique',
  },
  {
    key: 'mm',
    value: 'mm',
    text: 'Myanmar (Burma)',
  },
  {
    key: 'na',
    value: 'na',
    text: 'Namibia',
  },
  {
    key: 'nr',
    value: 'nr',
    text: 'Nauru',
  },
  {
    key: 'np',
    value: 'np',
    text: 'Nepal',
  },
  {
    key: 'nl',
    value: 'nl',
    text: 'Netherlands',
  },
  {
    key: 'nz',
    value: 'nz',
    text: 'New Zealand',
  },
  {
    key: 'ni',
    value: 'ni',
    text: 'Nicaragua',
  },
  {
    key: 'ne',
    value: 'ne',
    text: 'Niger',
  },
  {
    key: 'ng',
    value: 'ng',
    text: 'Nigeria',
  },
  {
    key: 'no',
    value: 'no',
    text: 'Norway',
  },
  {
    key: 'om',
    value: 'om',
    text: 'Oman',
  },
  {
    key: 'pk',
    value: 'pk',
    text: 'Pakistan',
  },
  {
    key: 'pw',
    value: 'pw',
    text: 'Palau',
  },
  {
    key: 'pa',
    value: 'pa',
    text: 'Panama',
  },
  {
    key: 'pg',
    value: 'pg',
    text: 'Papua New Guinea',
  },
  {
    key: 'py',
    value: 'py',
    text: 'Paraguay',
  },
  {
    key: 'pe',
    value: 'pe',
    text: 'Peru',
  },
  {
    key: 'ph',
    value: 'ph',
    text: 'Philippines',
  },
  {
    key: 'pl',
    value: 'pl',
    text: 'Poland',
  },
  {
    key: 'pt',
    value: 'pt',
    text: 'Portugal',
  },
  {
    key: 'qa',
    value: 'qa',
    text: 'Qatar',
  },
  {
    key: 'ro',
    value: 'ro',
    text: 'Romania',
  },
  {
    key: 'ru',
    value: 'ru',
    text: 'Russia',
  },
  {
    key: 'rw',
    value: 'rw',
    text: 'Rwanda',
  },
  {
    key: 'kn',
    value: 'kn',
    text: 'Saint Kitts and Nevis',
  },
  {
    key: 'lc',
    value: 'lc',
    text: 'Saint Lucia',
  },
  {
    key: 'vc',
    value: 'vc',
    text: 'Saint Vincent and the Grenadines',
  },
  {
    key: 'ws',
    value: 'ws',
    text: 'Samoa',
  },
  {
    key: 'sm',
    value: 'sm',
    text: 'San Marino',
  },
  {
    key: 'st',
    value: 'st',
    text: 'Sao Tome and Principe',
  },
  {
    key: 'sa',
    value: 'sa',
    text: 'Saudi Arabia',
  },
  {
    key: 'sn',
    value: 'sn',
    text: 'Senegal',
  },
  {
    key: 'rs',
    value: 'rs',
    text: 'Serbia',
  },
  {
    key: 'sc',
    value: 'sc',
    text: 'Seychelles',
  },
  {
    key: 'sl',
    value: 'sl',
    text: 'Sierra Leone',
  },
  {
    key: 'sg',
    value: 'sg',
    text: 'Singapore',
  },
  {
    key: 'sk',
    value: 'sk',
    text: 'Slovakia',
  },
  {
    key: 'si',
    value: 'si',
    text: 'Slovenia',
  },
  {
    key: 'sb',
    value: 'sb',
    text: 'Solomon Islands',
  },
  {
    key: 'so',
    value: 'so',
    text: 'Somalia',
  },
  {
    key: 'za',
    value: 'za',
    text: 'South Africa',
  },
  {
    key: 'es',
    value: 'es',
    text: 'Spain',
  },
  {
    key: 'lk',
    value: 'lk',
    text: 'Sri Lanka',
  },
  {
    key: 'sd',
    value: 'sd',
    text: 'Sudan',
  },
  {
    key: 'sr',
    value: 'sr',
    text: 'Suriname',
  },
  {
    key: 'sz',
    value: 'sz',
    text: 'Eswatini',
  },
  {
    key: 'se',
    value: 'se',
    text: 'Sweden',
  },
  {
    key: 'ch',
    value: 'ch',
    text: 'Switzerland',
  },
  {
    key: 'sy',
    value: 'sy',
    text: 'Syria',
  },
  {
    key: 'tw',
    value: 'tw',
    text: 'Taiwan',
  },
  {
    key: 'tj',
    value: 'tj',
    text: 'Tajikistan',
  },
  {
    key: 'tz',
    value: 'tz',
    text: 'Tanzania',
  },
  {
    key: 'th',
    value: 'th',
    text: 'Thailand',
  },
  {
    key: 'tg',
    value: 'tg',
    text: 'Togo',
  },
  {
    key: 'to',
    value: 'to',
    text: 'Tonga',
  },
  {
    key: 'tt',
    value: 'tt',
    text: 'Trinidad and Tobago',
  },
  {
    key: 'tn',
    value: 'tn',
    text: 'Tunisia',
  },
  {
    key: 'tr',
    value: 'tr',
    text: 'Turkey',
  },
  {
    key: 'tm',
    value: 'tm',
    text: 'Turkmenistan',
  },
  {
    key: 'tv',
    value: 'tv',
    text: 'Tuvalu',
  },
  {
    key: 'ug',
    value: 'ug',
    text: 'Uganda',
  },
  {
    key: 'ua',
    value: 'ua',
    text: 'Ukraine',
  },
  {
    key: 'ae',
    value: 'ae',
    text: 'United Arab Emirates',
  },
  {
    key: 'gb',
    value: 'gb',
    text: 'United Kingdom',
  },
  {
    key: 'us',
    value: 'us',
    text: 'United States',
  },
  {
    key: 'uy',
    value: 'uy',
    text: 'Uruguay',
  },
  {
    key: 'uz',
    value: 'uz',
    text: 'Uzbekistan',
  },
  {
    key: 'vu',
    value: 'vu',
    text: 'Vanuatu',
  },
  {
    key: 'va',
    value: 'va',
    text: 'Vatican City',
  },
  {
    key: 've',
    value: 've',
    text: 'Venezuela',
  },
  {
    key: 'vn',
    value: 'vn',
    text: 'Vietnam',
  },
  {
    key: 'ye',
    value: 'ye',
    text: 'Yemen',
  },
  {
    key: 'zm',
    value: 'zm',
    text: 'Zambia',
  },
  {
    key: 'zw',
    value: 'zw',
    text: 'Zimbabwe',
  },
];

export default countryList;
