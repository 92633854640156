import React from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_assignedActivities } from '../../../../../../graphql/queries/types/Client';
import { SmallSectionTitle, UserComment } from '../../../../../../style';
import { renderStringToList } from '../../../../../../util/renderStringToList';
import WeeklyCheckInView from '../component/WeeklyCheckInView';
import { ContentSectionContainer, MobileSpace } from '../styles';
import { ActivityMainSkill } from './styles';

interface Props {
  activity: Client_client_children_assignedActivities;
  currentWeekday: number;
  tPath: string;
}

const OverallTab = ({ activity, currentWeekday, tPath }: Props) => {
  const { t } = useTranslation();
  const summary = renderStringToList(activity.summary);
  const parentInteraction = renderStringToList(activity.parentInteraction);

  return (
    <div>
      <SmallSectionTitle>{t(`${tPath}.overall.weekRecord`)}</SmallSectionTitle>
      <WeeklyCheckInView activity={activity} currentWeekday={currentWeekday} />
      <div style={{ height: '30px' }}></div>
      <SmallSectionTitle>{t(`${tPath}.overall.skill`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <ActivityMainSkill>{activity.mainSkill.name}</ActivityMainSkill>
        {activity.skills.map((s) => {
          return <ActivityMainSkill>{s.name}</ActivityMainSkill>;
        })}
      </ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.overall.summary`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <UserComment>{summary}</UserComment>
      </ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.overall.parentInteraction`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <UserComment>{parentInteraction}</UserComment>
      </ContentSectionContainer>
      <MobileSpace />
    </div>
  );
};

export default React.memo(OverallTab);
