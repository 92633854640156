import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const HowToUseGuide = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const tPath = 'client.profile.howToUse';
  return (
    <div>
      <div
        onClick={() => history.push('/addShortcut')}
        style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'none' }}
      >
        {t(`${tPath}.addShortcutText`)}
      </div>
    </div>
  );
};

export default HowToUseGuide;
