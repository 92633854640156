import React from 'react';
import { SmallSectionTitle, UserComment } from '../../../../../../style';
import { Index, PracticeContent, SetupCardSegment, StepContainer } from '../styles';
import { useTranslation } from 'react-i18next';

export const SetupScrollCard = (activity): JSX.Element[] => {
  const { t } = useTranslation();
  const SetupData = ['frequency', 'scenario', 'tools'];
  return SetupData.map((data: string, index: number) => {
    return (
      <div key={index}>
        <SetupCardSegment marginBottom={0} marginRight={15} height={150}>
          <div>
            <SmallSectionTitle>{t(`client.task.activities.practice.${data}`)}</SmallSectionTitle>
            <UserComment>
              {activity[data] != null ? activity[data] : t(`client.task.activities.noConstraint`)}
            </UserComment>
          </div>
        </SetupCardSegment>
      </div>
    );
  });
};

export const StringToListComponent = (data: string): JSX.Element[] => {
  const splitString = data.split('\n');
  return splitString.map((s, index) => {
    if (s === '') {
      return <div></div>;
    }
    return (
      <StepContainer>
        <div style={{ width: '40px' }}>
          <Index>{index + 1}</Index>
        </div>
        <PracticeContent>{s}</PracticeContent>
      </StepContainer>
    );
  });
};
