import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(toggle) {
  useLayoutEffect((): (() => void) => {
    // Get original body overflow
    const originalStyle: string = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (toggle === true) {
      document.body.style.overflow = 'hidden';
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [toggle]);
}
