import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import {
  Consultation_consultation_child_activityChildren,
  Consultation_consultation_child_goals,
} from '../../../../graphql/queries/types/Consultation';
import { Consultation_consultation_child_goals_preskills } from '../../../../graphql/queries/types/Consultation';
import ProgressBarComponent from './ProgressBarComponent';
import { Col, Row } from 'antd';
import { RoundBorderButton, CircleButton, PopUpDiv, CloseXButton } from './StyledComponents';
import { PRESKILL_ACTIVITIES_QUERY } from '../../../../graphql/queries/getActivitiesFromPreskills';
import {
  GetActivitiesFromPreskills,
  GetActivitiesFromPreskillsVariables,
  GetActivitiesFromPreskills_getActivitiesFromPreskills,
} from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import ActivityCard from './ActivityCard';
import { LuRefreshCw, LuRefreshCwOff } from 'react-icons/lu';
import { BsPlusLg, BsTrash3 } from 'react-icons/bs';
import ActivityRemoveButton from '../Tasks/ActivtiyRemoveButton';
import { useTranslation } from 'react-i18next';
import {
  AddActivitiesToChild,
  AddActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/AddActivitiesToChild';
import { ADD_ACTIVITIES_TO_CHILD } from '../../../../graphql/mutations/addActivitiesToChild';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { FiEdit2 } from 'react-icons/fi';
import { FAST_POLL } from '../constants';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

const BUTTON_HEIGHT = 25;
const MAX_ACTIVITY_CHECK_INS = 20;
const RANDOM_COUNT = 2;
const DEFAULT_INDEX = -1;
interface Props {
  skill: Consultation_consultation_child_goals_preskills;
  isDropDown: boolean;
  handleDropDownClick: () => void;
  goal: Consultation_consultation_child_goals;
}

function getRandomElements<T>(array: T[], numberOfElements: number): T[] {
  const randomIndices: number[] = [];
  const result: T[] = [];

  if (numberOfElements >= array.length) {
    return array.slice(); // Return a shallow copy of the original array
  }

  while (randomIndices.length < numberOfElements) {
    const randomIndex = Math.floor(Math.random() * array.length);

    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
      result.push(array[randomIndex]);
    }
  }

  return result;
}

const ActivityComponent = ({ skill, isDropDown, handleDropDownClick, goal }: Props) => {
  const { t } = useTranslation();

  const {
    consultation,
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
    pollingCount,
    setPollingCount,
  } = useContext(TherapistConsultationScreenContext);

  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);
  const curGoal = consultation.child.goals.find((g) => {
    return g.id === goal.id;
  });

  const curSkill = curGoal?.goalPreskills.find((pS) => pS.skillId === skill.id);

  const [assignedActivities, setAssignedActivities] = useState<Consultation_consultation_child_activityChildren[]>([]);

  useEffect(() => {
    setAssignedActivities(consultation.child.activityChildren.filter((a) => a.activity.mainSkill.id == skill.id));
  }, [consultation]);

  const { data } = useQuery<GetActivitiesFromPreskills, GetActivitiesFromPreskillsVariables>(
    PRESKILL_ACTIVITIES_QUERY,
    {
      variables: { preskillIds: [skill.id] },
    },
  );

  const [allActivities, setAllActivities] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>([]);

  const [allAvailableActivities, setAllAvailableActivities] = useState<
    GetActivitiesFromPreskills_getActivitiesFromPreskills[]
  >([]);

  const [randomAvailableActivities, setRandomAvailableActivities] = useState<
    GetActivitiesFromPreskills_getActivitiesFromPreskills[]
  >([]);

  useEffect(() => {
    if (data && data.getActivitiesFromPreskills && data.getActivitiesFromPreskills.length > 0) {
      setAllActivities(data?.getActivitiesFromPreskills?.filter((a) => a.mainSkill.id == skill.id));
    }
  }, [data]);

  useEffect(() => {
    setAllAvailableActivities(allActivities.filter((a) => !assignedActivities.some((b) => b.activity.id === a.id)));
  }, [allActivities, assignedActivities]);

  const [selectedActivityIndex, setSelectedActivityIndex] = useState(DEFAULT_INDEX);
  const [randomCount, setRandomCount] = useState(RANDOM_COUNT);
  const [isActivitySelectionOpen, setIsActivitySelectionOpen] = useState(false);

  const closeActivitySelection = () => {
    setSelectedActivityIndex(DEFAULT_INDEX);
    setRandomCount(RANDOM_COUNT);
    setIsActivitySelectionOpen(false);
  };

  const refreshRandomAvailableActivities = () => {
    setSelectedActivityIndex(DEFAULT_INDEX);
    setRandomAvailableActivities(getRandomElements(allAvailableActivities, randomCount));
  };

  useEffect(() => {
    refreshRandomAvailableActivities();
  }, [randomCount]);

  useEffect(() => {
    if (randomAvailableActivities.length == 1) setSelectedActivityIndex(0); // auto select activity if there is only 1 available activity
  }, [randomAvailableActivities]);

  const clickAddActivities = () => {
    refreshRandomAvailableActivities();
    setIsActivitySelectionOpen(true);
  };

  const viewMoreActivities = () => {
    if (allAvailableActivities.length > randomCount) {
      setRandomCount(allAvailableActivities.length);
    } else {
      setRandomCount(RANDOM_COUNT);
    }
  };

  const [viewActivity, setViewActivity] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills | null>(null);
  const [isActivityDetailsOpen, setIsActivityDetailsOpen] = useState(false);
  const [consultationActivity, setConsultationActivity] =
    useState<Consultation_consultation_child_activityChildren | null>(null);

  const openActivityDetails = (ac: Consultation_consultation_child_activityChildren) => {
    setConsultationActivity(ac);
    setViewActivity(allActivities.filter((a) => a.id == ac.activity.id)[0]);
    setIsActivityDetailsOpen(true);
  };

  const closeActivityDetails = async () => {
    setIsActivityDetailsOpen(false);
    setViewActivity(null);
  };

  const NumOfAvailableActivities = () => {
    return (
      <RoundBorderButton style={{ padding: '0px 15px', cursor: 'default' }}>{`${t(
        'therapist.consultationScreen.activities.numberOfActivities',
      )} ${allAvailableActivities.length}`}</RoundBorderButton>
    );
  };

  const redirectActivtiyRecommend = () => {
    window.open(t('therapist.consultationScreen.task.activityIdea.link'), '_blank');
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const Tooltip = styled.div`
    position: absolute;
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 4px;
    z-index: 1;
    top: 25px;
    left: 22px;
    width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 13px;
  `;

  const RefreshButton = () => {
    return (
      <div>
        {allAvailableActivities.length > randomCount ? (
          <div style={{ position: 'relative' }}>
            <CircleButton
              onClick={refreshRandomAvailableActivities}
              style={{ margin: '0px 5px' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <LuRefreshCw />
            </CircleButton>
            {showTooltip && <Tooltip>{t('therapist.consultationScreen.activities.refreshButtonToolTip')}</Tooltip>}
          </div>
        ) : (
          <CircleButton style={{ backgroundColor: 'lightgrey', margin: '0 5px', cursor: 'default' }}>
            <LuRefreshCwOff />
          </CircleButton>
        )}
      </div>
    );
  };

  const ViewMoreActivities = () => {
    return (
      <div>
        {allAvailableActivities.length > RANDOM_COUNT && (
          <div onClick={viewMoreActivities} style={{ color: 'teal', fontWeight: 600, cursor: 'pointer' }}>
            {allAvailableActivities.length > randomCount
              ? t('therapist.consultationScreen.activities.viewAll')
              : t('therapist.consultationScreen.activities.viewLess')}
          </div>
        )}
      </div>
    );
  };

  const [addActivitiesToChild] = useMutation<AddActivitiesToChild, AddActivitiesToChildVariables>(
    ADD_ACTIVITIES_TO_CHILD,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
      onError: (error) => {
        console.error('Mutation error:', error);
      },
    },
  );

  const assignActivityToChild = async () => {
    setPollingCount((prevCount) => prevCount + 1);
    await addActivitiesToChild({
      variables: {
        childId: consultation.child.id,
        activityIds: [randomAvailableActivities[selectedActivityIndex].id],
        consultationId: consultation.id,
      },
    });
    closeActivitySelection();
  };

  const RandomActivityComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxHeight: `${window.innerHeight * 0.8}px`,
          maxWidth: `${window.innerWidth * 0.6 + 35}px`,
          overflow: 'auto',
          margin: '10px 0px',
        }}
      >
        {randomAvailableActivities.map(
          (activity: GetActivitiesFromPreskills_getActivitiesFromPreskills, index: number) => (
            <div onClick={() => setSelectedActivityIndex(index)} style={{ cursor: 'default' }}>
              <ActivityCard activity={activity} isSelected={selectedActivityIndex == index} />
            </div>
          ),
        )}
      </div>
    );
  };

  const RandomActivityConfirmOrSuggest = () => {
    return (
      <div>
        {allAvailableActivities.length > 0 ? (
          <Row justify="center">
            {selectedActivityIndex != DEFAULT_INDEX ? (
              <RoundBorderButton
                onClick={assignActivityToChild}
                style={{ width: '100px', fontSize: '14px', margin: '5px 0 0 0' }}
              >
                {t('therapist.consultationScreen.confirm')}
              </RoundBorderButton>
            ) : (
              <RoundBorderButton
                style={{
                  width: '100px',
                  fontSize: '14px',
                  margin: '5px 0 0 0',
                  backgroundColor: 'lightgrey',
                  cursor: 'default',
                }}
              >
                {t('therapist.consultationScreen.confirm')}
              </RoundBorderButton>
            )}
          </Row>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ margin: '30px 100px' }}>{t('therapist.consultationScreen.activityPopUp.noActivities')}</div>

            <RoundBorderButton onClick={redirectActivtiyRecommend} style={{ fontSize: '14px', margin: '8px 0 0 0' }}>
              {t('therapist.consultationScreen.activityPopUp.recommendNewActivity')}
            </RoundBorderButton>
          </div>
        )}
      </div>
    );
  };

  const RemoveActivity = ({ isLongButton }) => {
    const h = 28;
    const removeActivityIcon = <BsTrash3 />;
    return (
      <div>
        {isLongButton ? (
          <RoundBorderButton style={{ backgroundColor: 'teal', margin: '0px 10px', padding: '0px 15px' }}>
            <div style={{ padding: '0 3px 0 0' }}>{t('therapist.consultationScreen.removeActivity')}</div>
            {removeActivityIcon}
          </RoundBorderButton>
        ) : (
          <div
            style={{
              height: `${h}px`,
              width: `${h}px`,
              borderRadius: '50%',
              backgroundColor: 'teal',
              color: 'white',
              fontSize: '13px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {removeActivityIcon}
          </div>
        )}
      </div>
    );
  };

  const RemoveActivityComponent = ({ activityToRemove, isLongButton }) => {
    return (
      <Row>
        {activityToRemove && (
          <ActivityRemoveButton
            activityId={activityToRemove.activity.id}
            consultationId={activityToRemove.consultation.id}
            trigger={<RemoveActivity isLongButton={isLongButton} />}
            onClickFinish={closeActivityDetails}
          />
        )}
      </Row>
    );
  };

  const TotalCheckInsActivity = () => {
    const totalDenominator = assignedActivities.length * 20;

    const curSkillLastAssignedActivities = consultation.child.assignedActivities.filter((a) =>
      assignedActivities.some((b) => a.id === b.activity.id),
    );
    const totalNumerator = curSkillLastAssignedActivities.reduce((accumulator, currentObject) => {
      return accumulator + (currentObject.checkInCount ?? 0);
    }, 0);

    return (
      <div onClick={handleDropDownClick} style={{ marginBottom: '20px' }}>
        <Row>
          <Col span={24}>{t('therapist.consultationScreen.totalCheckIns')}</Col>
          <Col span={24} style={{ margin: '8px 0 0 0' }}>
            <ProgressBarComponent value={totalNumerator} maxValue={totalDenominator} overall={true} />
          </Col>
        </Row>
      </div>
    );
  };

  const Activities = () => {
    return (
      <div>
        {assignedActivities.map((ac: Consultation_consultation_child_activityChildren, index: number) => (
          <div>
            <Row justify="space-between" align="middle">
              <Col span={21}>
                <Row onClick={() => openActivityDetails(ac)}>
                  <Col span={24}>
                    <Row align="middle">
                      <Col>{`${ac.activity.name}`}</Col>
                      <FiEdit2 style={{ margin: '0 6px' }} />
                    </Row>
                  </Col>

                  <Col span={24} style={{ margin: '8px 0 0 0' }}>
                    <ProgressBarComponent
                      value={
                        consultation.child.assignedActivities.find((a) => a.id === ac.activity.id)?.checkInCount ?? 0
                      }
                      maxValue={MAX_ACTIVITY_CHECK_INS}
                      overall={false}
                    />
                  </Col>
                </Row>
              </Col>

              {isCPC && (
                <Col span={2}>
                  <Row justify="end">
                    <RemoveActivityComponent activityToRemove={ac} isLongButton={false} />
                  </Row>
                </Col>
              )}
            </Row>
            <div style={{ height: '20px' }}></div>
          </div>
        ))}
      </div>
    );
  };

  const AddActivityButton = () => {
    return (
      <RoundBorderButton onClick={clickAddActivities} style={{ height: `${BUTTON_HEIGHT}px` }}>
        {t('therapist.consultationScreen.activities.addActivities')} <BsPlusLg />
      </RoundBorderButton>
    );
  };

  return (
    <div>
      {isActivitySelectionOpen && (
        <PopUpDiv>
          <Col>
            <div onClick={closeActivitySelection} style={{ position: 'absolute', right: '0px' }}>
              <CloseXButton />
            </div>

            <Row align="middle" justify="space-between">
              <Row>
                <NumOfAvailableActivities />
                <RefreshButton />
              </Row>
              <div style={{ margin: '0 40px 0 0' }}>
                <ViewMoreActivities />
              </div>
            </Row>

            <RandomActivityComponent />

            <RandomActivityConfirmOrSuggest />
          </Col>
        </PopUpDiv>
      )}

      {isActivityDetailsOpen && (
        <PopUpDiv>
          <Col>
            <div onClick={closeActivityDetails} style={{ position: 'absolute', right: '0px' }}>
              <CloseXButton />
            </div>

            {isCPC && <RemoveActivityComponent activityToRemove={consultationActivity} isLongButton={true} />}

            {viewActivity && <ActivityCard activity={viewActivity} isSelected={true} />}
          </Col>
        </PopUpDiv>
      )}

      {assignedActivities.length > 0 ? (
        <div>
          {isDropDown ? (
            <div>
              <Activities />
              <Row justify="end">
                <AddActivityButton />
              </Row>
            </div>
          ) : (
            <TotalCheckInsActivity />
          )}
        </div>
      ) : (
        isCPC && (
          <div>
            {curSkill?.mastered ? (
              <div style={{ opacity: '0.7', cursor: 'default', color: 'teal' }}>
                {`[${t('therapist.consultationScreen.activities.achieved')}]`}
              </div>
            ) : (
              <Row justify="space-between">
                <div style={{ opacity: '0.5', cursor: 'default' }}>
                  {`[${t('therapist.consultationScreen.activities.toBeAssigned')}]`}
                </div>
                <AddActivityButton />
              </Row>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default ActivityComponent;
