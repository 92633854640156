import React from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_assignedActivities } from '../../../../../../graphql/queries/types/Client';

interface Props {
  activity: Client_client_children_assignedActivities;
  tPath: string;
}

const ExtensionTab = ({ activity, tPath }: Props) => {
  const { t } = useTranslation();

  return <p>{activity.extension ? activity.extension : t(`${tPath}.no`)}</p>;
};

export default React.memo(ExtensionTab);
