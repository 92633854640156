import React, { useEffect, useState } from 'react';
import { MainColor } from '../../../../../styles';
import { Container, Checked, UnChecked } from './styles';

type Props = {
  index: number;
  isChecked: boolean;
  handleClick: (index: number, status: boolean) => void;
};

const Checkbox = ({ index, isChecked, handleClick }: Props) => {
  const onClick = () => {
    handleClick(index, !isChecked);
  };
  return <Container onClick={onClick}>{isChecked ? <Checked /> : <UnChecked />}</Container>;
};

export default React.memo(Checkbox);
