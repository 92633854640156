import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  CoursesSegment,
  QnaSessionsSegment,
  TasksSegment,
  ConsultationsSegment,
  SectionDes,
  SegmentContainer,
  GoToButton,
  SegmentTitle,
  ProgramSegment,
  WordTrackerSegment,
  HomeContainer,
} from './styles';
import { BiggerRouteSectionContainer } from '../styles';
// component
import { HBI_LITE_PRODUCT_INFO } from '../../../util/Product';
// assets
import { ClientContext } from '../ClientContext';
import { H1Title } from '../../../style';
import ConsentFormModal from '../../common/ConsentFormModal';

interface Props {}

const HomeRoute = ({}: Props) => {
  window.scroll(0, 0);
  const { t } = useTranslation();
  const { child } = useContext(ClientContext);
  const history = useHistory();

  const goToCourses = () => {
    history.push('/courses');
  };
  const gotoQnaSession = () => {
    history.push('/qnaSessions');
  };
  const goToTask = () => {
    history.push('/task/activity');
  };
  const goToConsultation = () => {
    history.push('/consultations');
  };
  const goToProgram = () => {
    history.push('/program');
  };
  const goToWordTracker = () => {
    history.push('/word-tools');
  };

  return (
    <BiggerRouteSectionContainer>
      <ConsentFormModal></ConsentFormModal>
      <HomeContainer>
        <H1Title>{t('client.home.welcome')}</H1Title>
        <SegmentContainer>
          <GoToButton data-duration={t('client.home.goToCourses')}>
            <CoursesSegment onClick={goToCourses}>
              <SegmentTitle>{t('client.home.coursesTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.coursesDes')}</SectionDes>
            </CoursesSegment>
          </GoToButton>
          <GoToButton data-duration={t('client.home.goToTask')}>
            <TasksSegment onClick={goToTask}>
              <SegmentTitle>{t('client.home.TaskTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.TaskDes')}</SectionDes>
            </TasksSegment>
          </GoToButton>
          <GoToButton data-duration={t('client.home.goToQnaSessions')}>
            <QnaSessionsSegment onClick={gotoQnaSession}>
              <SegmentTitle>{t('client.home.qnaSessionsTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.qnaSessionsDes')}</SectionDes>
            </QnaSessionsSegment>
          </GoToButton>
          <GoToButton data-duration={t('client.home.goToConsultation')}>
            <ConsultationsSegment onClick={goToConsultation}>
              <SegmentTitle>{t('client.home.ConsultationTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.ConsultationDes')}</SectionDes>
            </ConsultationsSegment>
          </GoToButton>
          <GoToButton data-duration={t('client.home.goToProgram')}>
            <ProgramSegment onClick={goToProgram}>
              <SegmentTitle>{t('client.home.ProgramTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.ProgramDes')}</SectionDes>
            </ProgramSegment>
          </GoToButton>
          <GoToButton data-duration={t('client.home.goToWordTracker')}>
            <WordTrackerSegment onClick={goToWordTracker}>
              <SegmentTitle>{t('client.home.WordTrackerTitle')}</SegmentTitle>
              <SectionDes>{t('client.home.WordTrackerDes')}</SectionDes>
            </WordTrackerSegment>
          </GoToButton>
        </SegmentContainer>
      </HomeContainer>
    </BiggerRouteSectionContainer>
  );
};

export default HomeRoute;
