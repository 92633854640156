import React, { useState, useEffect, useRef, useContext } from 'react';

import {
  Container,
  Title,
  TitleContainer,
  Text,
  ItalicText,
  FormContainer,
  NameContainer,
  FirstNameContainer,
  LastNameContainer,
  NameTitle,
  NameField,
  SectionContainer,
  SectionTitle,
  BiggerContainer,
  SectionsContainer,
  SectionText,
  SectionBox,
  OrderListItem,
  OrderList,
  CheckBox,
  CheckBoxContainer,
  CheckBoxText,
  NoScrollSectionBox,
  SubmitButton,
  NoScrollSectionText,
  BigModalContainer,
  ModalTitle,
  ModalText,
  ModalButton,
  ModalMask,
  Banner,
  Logo,
  CarouselItem,
  Carousel,
  Inner,
  BallsContainer,
  Ball,
  NoScrollSectionBoxMobile,
  SectionBoxTitle,
  BoldedText,
  RedBoldedText,
  RedBoldedTextMobile,
} from './styles';

import enLogo from '../../../assets/Logo_EN_black.png';
import cnLogo from '../../../assets/Logo_CN_black.png';
import twLogo from '../../../assets/Logo_TW_black.png';
import { useTranslation } from 'react-i18next';

// backend shit
import { ClientContext } from '../ClientContext';
import UserContext from '../../common/UserContext';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-apollo';
import {
  UpdateConsentFormStatusVariables,
  UpdateConsentFormStatus,
} from '../../../graphql/mutations/types/UpdateConsentFormStatus';
import { CLIENT_QUERY } from '../../../graphql/queries/client';
import { VIEWER_QUERY } from '../../../graphql/queries/viewer';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../graphql/mutations/types/EditUserInformation';
import { EDIT_USER_INFORMATION } from '../../../graphql/mutations/editUserInformation';

import { UPDATE_CONSENT_FORM_COMPLETED_STATUS_MUTATION } from '../../../graphql/mutations/updateConsentFormStatus';
import { Loader } from 'semantic-ui-react';
import { Client, ClientVariables } from '../../../graphql/queries/types/Client';

import { JS_COOKIE } from '../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../constants/config';

const ConsentFormRouteCPC = (props) => {
  const [section1Checked, setSection1Checked] = useState(false);
  const [section2Checked, setSection2Checked] = useState(false);
  const [section3Checked, setSection3Checked] = useState(false);
  const [section4aChecked, setSection4aChecked] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [section1Red, setsection1Red] = useState(false);
  const [section2Red, setsection2Red] = useState(false);
  const [section3Red, setsection3Red] = useState(false);
  const [section4Red, setsection4Red] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const { t } = useTranslation();

  //Backend declarations
  const { user } = useContext(UserContext);
  const { uuid, id, name, locale } = user;
  const { client, setChild } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const history = useHistory();
  const consentFormCompleted = child.consentFormCompleted;
  const smsNotificationEnabled = client.user.smsNotification;
  const [section4bChecked, setSection4bChecked] = useState(smsNotificationEnabled);
  const goBackToProfile = () => history.push('/profile');
  const goBackToHome = () => {
    history.push('/dashboard');
  };

  //Handle check boxes
  const handleSection1Change = () => {
    setSection1Checked(!section1Checked);
  };

  const handleSection2Change = () => {
    setSection2Checked(!section2Checked);
  };

  const handleSection3Change = () => {
    setSection3Checked(!section3Checked);
  };

  const handleSection4aChange = () => {
    setSection4aChecked(!section4aChecked);
  };

  const handleSection4bChange = () => {
    setSection4bChecked(!section4bChecked);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);

    //Checks which checkbox has not been checked and will fly to it depending on the highest one
    let uncheckedBox;
    if (!section1Checked) {
      uncheckedBox = document.getElementById('section-1');
    } else if (!section2Checked) {
      uncheckedBox = document.getElementById('section-2');
    } else if (!section3Checked) {
      uncheckedBox = document.getElementById('section-3');
    } else if (!section4aChecked) {
      uncheckedBox = document.getElementById('section-4a');
    }
    //Scrolling to unchecked box
    if (uncheckedBox) {
      uncheckedBox.scrollIntoView({ behavior: 'smooth' });
    }
    //Set state to change the text to red if unchecked
    if (!section1Checked) {
      setsection1Red(true);
    }
    if (!section2Checked) {
      setsection2Red(true);
    }
    if (!section3Checked) {
      setsection3Red(true);
    }
    if (!section4aChecked) {
      setsection4Red(true);
    }
  };

  //After the submit button is pressed, will check if the box is ticked, if it is then it will turn the red off
  useEffect(() => {
    if (section1Checked) {
      setsection1Red(false);
    }
    if (section2Checked) {
      setsection2Red(false);
    }
    if (section3Checked) {
      setsection3Red(false);
    }
    if (section4aChecked) {
      setsection4Red(false);
    }
  }, [section1Checked, section2Checked, section3Checked, section4aChecked]);

  const updateUserInfo = async () => {
    editUserInformation({
      variables: { userId: id, attribute: 'SMS Notification', value: section4bChecked.toString() },
    }); // update guide sms_notification
    handleUpdateEval(section4bChecked, uuid); // update eval sms_notification
    updateConsentFormStatus({ variables: { inputChildId: child.id, status: true } }); // update guide consent_form_completed
  };

  const handleClickedSubmit = async () => {
    if (section1Checked && section2Checked && section3Checked && section4aChecked) {
      // section4bChecked: receive sms set to optional
      setSubmitButtonClicked(true);
      await updateUserInfo();
      setOpenSuccessModal(true);
    } else {
      setOpenErrorModal(true);
    }
  };
  const { loading, error, data } = useQuery<Client, ClientVariables>(CLIENT_QUERY, {
    variables: { clientId: client.id },
  });

  const handleUpdateEval = async (smsNotification, userUuid) => {
    const token = JS_COOKIE.get('token');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updateSmsNotificationFromGuide',
      {
        smsNotification: smsNotification,
        userUuid: userUuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const [updateConsentFormStatus, {}] = useMutation<UpdateConsentFormStatus, UpdateConsentFormStatusVariables>(
    UPDATE_CONSENT_FORM_COMPLETED_STATUS_MUTATION,
    {
      refetchQueries: [{ query: CLIENT_QUERY, variables: { clientId: client.id } }],
    },
  );

  const [editUserInformation, {}] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  //Checks if the user is referencing the form or filling
  useEffect(() => {
    if (consentFormCompleted) {
      setSection1Checked(true);
      setSection2Checked(true);
      setSection3Checked(true);
      setSection4aChecked(true);
    }
    setSubmitButtonClicked(false);
    setShowLoader(false);
  }, []);

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    goBackToHome();
  };

  //Disable scrolling when modal appears
  //Disable scrolling functions when modal opens
  const disableScroll = () => {
    document.addEventListener('wheel', preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener('wheel', preventDefault, false);
  };

  const preventDefault = (e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  useEffect(() => {
    if (windowSize.current[0] >= 820) {
      setIsVisible3(true);
    }

    enableScroll();
  }, []);

  //Checks if they have read up till section 5 of the information, if yes then sets isVisible to true
  // Then checkbox becomes enabled and they can check it
  // For Section 1
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (isVisible) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (targetRef.current && !isVisible) {
      observer.observe(targetRef.current);
    }

    // Cleanup
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  //Checker for section 2

  const [isVisible2, setIsVisible2] = useState(false);
  const targetRef2 = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible2(entry.isIntersecting);
        if (isVisible2) {
          setIsVisible2(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (targetRef2.current && !isVisible) {
      observer.observe(targetRef2.current);
    }

    // Cleanup
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  //Swipe Handlers
  const divRef = useRef(null);
  const [pageNum, setPageNum] = useState(0);

  const [section1Ball1Lighted, setSection1Ball1Lighted] = useState(true);
  const [section1Ball2Lighted, setSection1Ball2Lighted] = useState(false);
  const [section1Ball3Lighted, setSection1Ball3Lighted] = useState(false);
  const [section1Ball4Lighted, setSection1Ball4Lighted] = useState(false);
  const [section1Ball5Lighted, setSection1Ball5Lighted] = useState(false);
  const [section1Ball6Lighted, setSection1Ball6Lighted] = useState(false);
  const [section1Ball7Lighted, setSection1Ball7Lighted] = useState(false);
  const [section1Ball8Lighted, setSection1Ball8Lighted] = useState(false);
  const [section1Ball9Lighted, setSection1Ball9Lighted] = useState(false);

  let touchStartX;
  let touchEndX;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchDiff = touchStartX - touchEndX;
    if (touchDiff > 0) {
      //swipe left
      updatePageNum(pageNum + 1);
    } else if (touchDiff < 0) {
      //swiped right

      updatePageNum(pageNum - 1);
    }
  };

  //Check if its the first or last page
  const updatePageNum = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    }
    //TO-DO: Update code so its not hard coded here
    else if (newIndex >= 8) {
      newIndex = 8;
      setIsVisible(true);
    }
    setPageNum(newIndex);

    //Checks if it hits page 5, if yes then allow checkbox

    //Sets the colour of the balls
    switch (newIndex) {
      case 0:
        setSection1Ball1Lighted(true);
        setSection1Ball2Lighted(false);
        break;
      case 1:
        setSection1Ball1Lighted(false);
        setSection1Ball2Lighted(true);
        setSection1Ball3Lighted(false);

        break;
      case 2:
        setSection1Ball2Lighted(false);
        setSection1Ball3Lighted(true);
        setSection1Ball4Lighted(false);

        break;
      case 3:
        setSection1Ball3Lighted(false);
        setSection1Ball4Lighted(true);
        setSection1Ball5Lighted(false);
        break;
      case 4:
        setSection1Ball4Lighted(false);
        setSection1Ball5Lighted(true);
        setSection1Ball6Lighted(false);
        break;
      case 5:
        setSection1Ball5Lighted(false);
        setSection1Ball6Lighted(true);
        setSection1Ball7Lighted(false);
        break;
      case 6:
        setSection1Ball6Lighted(false);
        setSection1Ball7Lighted(true);
        setSection1Ball8Lighted(false);
        break;
      case 7:
        setSection1Ball7Lighted(false);
        setSection1Ball8Lighted(true);
        setSection1Ball9Lighted(false);
        break;
      case 8:
        setSection1Ball8Lighted(false);
        setSection1Ball9Lighted(true);
        break;
    }
  };

  //Update for section 2

  const [pageNum2, setPageNum2] = useState(0);
  const [section2Ball1Lighted, setSection2Ball1Lighted] = useState(true);
  const [section2Ball2Lighted, setSection2Ball2Lighted] = useState(false);
  const [section2Ball3Lighted, setSection2Ball3Lighted] = useState(false);
  const [section2Ball4Lighted, setSection2Ball4Lighted] = useState(false);
  const [section2Ball5Lighted, setSection2Ball5Lighted] = useState(false);
  const [section2Ball6Lighted, setSection2Ball6Lighted] = useState(false);
  const [section2Ball7Lighted, setSection2Ball7Lighted] = useState(false);
  const [section2Ball8Lighted, setSection2Ball8Lighted] = useState(false);
  const [section2Ball9Lighted, setSection2Ball9Lighted] = useState(false);

  let touchStartX2;
  let touchEndX2;

  const handleTouchStart2 = (e) => {
    touchStartX2 = e.touches[0].clientX;
  };

  const handleTouchMove2 = (e) => {
    touchEndX2 = e.touches[0].clientX;
  };

  const handleTouchEnd2 = () => {
    const touchDiff = touchStartX2 - touchEndX2;
    if (touchDiff > 0) {
      //swiped left

      updatePageNum2(pageNum2 + 1);
    } else if (touchDiff < 0) {
      //swiped right

      updatePageNum2(pageNum2 - 1);
    }
  };

  const updatePageNum2 = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    }
    //TO-DO: Update code so its not hard coded here
    else if (newIndex >= 5) {
      newIndex = 5;
      setIsVisible2(true);
    }
    setPageNum2(newIndex);

    //Checks if it hits page 5, if yes then allow checkbox

    //Sets the colour of the balls
    switch (newIndex) {
      case 0:
        setSection2Ball1Lighted(true);
        setSection2Ball2Lighted(false);
        break;
      case 1:
        setSection2Ball1Lighted(false);
        setSection2Ball2Lighted(true);
        setSection2Ball3Lighted(false);

        break;
      case 2:
        setSection2Ball2Lighted(false);
        setSection2Ball3Lighted(true);
        setSection2Ball4Lighted(false);

        break;
      case 3:
        setSection2Ball3Lighted(false);
        setSection2Ball4Lighted(true);
        setSection2Ball5Lighted(false);
        break;
      case 4:
        setSection2Ball4Lighted(false);
        setSection2Ball5Lighted(true);
        setSection2Ball6Lighted(false);
        break;
      case 5:
        setSection2Ball5Lighted(false);
        setSection2Ball6Lighted(true);
        setSection2Ball7Lighted(false);
        break;
      case 6:
        setSection2Ball6Lighted(false);
        setSection2Ball7Lighted(true);
        setSection2Ball8Lighted(false);
        break;
      case 7:
        setSection2Ball7Lighted(false);
        setSection2Ball8Lighted(true);
        setSection2Ball9Lighted(false);
        break;
      case 8:
        setSection2Ball8Lighted(false);
        setSection2Ball9Lighted(true);
        break;
    }
  };

  //Section 3
  const [pageNum3, setPageNum3] = useState(0);
  const [section3Ball1Lighted, setSection3Ball1Lighted] = useState(true);
  const [section3Ball2Lighted, setSection3Ball2Lighted] = useState(false);
  const [section3Ball3Lighted, setSection3Ball3Lighted] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  let touchStartX3;
  let touchEndX3;

  const handleTouchStart3 = (e) => {
    touchStartX2 = e.touches[0].clientX;
  };

  const handleTouchMove3 = (e) => {
    touchEndX2 = e.touches[0].clientX;
  };

  const handleTouchEnd3 = () => {
    const touchDiff = touchStartX2 - touchEndX2;
    if (touchDiff > 0) {
      //swiped left

      updatePageNum3(pageNum3 + 1);
    } else if (touchDiff < 0) {
      //swiped right

      updatePageNum3(pageNum3 - 1);
    }
  };

  const [isVisible3, setIsVisible3] = useState(false);

  const updatePageNum3 = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    }
    //TO-DO: Update code so its not hard coded here
    else if (newIndex >= 2) {
      newIndex = 2;
      setIsVisible3(true);
    }
    setPageNum3(newIndex);

    //Checks if it hits page 5, if yes then allow checkbox

    //Sets the colour of the balls
    switch (newIndex) {
      case 0:
        setSection3Ball1Lighted(true);
        setSection3Ball2Lighted(false);
        break;
      case 1:
        setSection3Ball1Lighted(false);
        setSection3Ball2Lighted(true);
        setSection3Ball3Lighted(false);

        break;
      case 2:
        setSection3Ball2Lighted(false);
        setSection3Ball3Lighted(true);
    }
  };

  return (
    <BiggerContainer>
      {showLoader && (
        <ModalMask style={{ backgroundColor: '#FFFFFF' }}>
          <Loader active={showLoader}>{t('system.loading')}</Loader>
        </ModalMask>
      )}

      {openErrorModal && (
        <ModalMask>
          <BigModalContainer>
            <ModalTitle>{t('onboarding.consentForm.failModalTitle')}</ModalTitle>
            <ModalText>{t('onboarding.consentForm.failModalText')}</ModalText>
            <ModalButton onClick={handleCloseErrorModal}>{t('onboarding.consentForm.failModalButton')}</ModalButton>
          </BigModalContainer>
        </ModalMask>
      )}

      {openSuccessModal && (
        <ModalMask>
          <BigModalContainer>
            <ModalTitle>{t('onboarding.consentForm.successModalTitle')}</ModalTitle>
            <ModalText>{t('onboarding.consentForm.successModalText')}</ModalText>
            <ModalButton onClick={handleCloseSuccessModal}>
              {t('onboarding.consentForm.successModalButton')}
            </ModalButton>
          </BigModalContainer>
        </ModalMask>
      )}

      <Container>
        <Banner>
          <Logo src={locale == 'en' ? enLogo : locale == 'zh-CN' ? cnLogo : twLogo}></Logo>
        </Banner>
        <TitleContainer>
          <Title>
            {t('onboarding.consentForm.consentFormTitle1')}
            <Title>{t('onboarding.consentForm.consentFormTitle2')}</Title>
          </Title>
          <Text>{t('onboarding.consentForm.consentFormSubTitle1')}</Text>
          <ItalicText>{t('onboarding.consentForm.consentFormSubTitle2')}</ItalicText>
          <FormContainer>
            <NameContainer>
              <FirstNameContainer>
                <NameTitle>{t('onboarding.consentForm.parentName')}</NameTitle>
                <NameField placeholder={name}></NameField>
              </FirstNameContainer>
              <LastNameContainer>
                <NameTitle>{t('onboarding.consentForm.childName')}</NameTitle>
                <NameField placeholder={child.name}></NameField>
              </LastNameContainer>
            </NameContainer>
          </FormContainer>
        </TitleContainer>

        <hr style={{ width: '90%', border: '0.5px solid #E0E0E0', marginTop: '12px' }}></hr>

        <SectionsContainer>
          <SectionContainer style={{ marginTop: '0' }}>
            <SectionTitle id="section-1">{t('onboarding.consentForm.privateEducationTitle')}</SectionTitle>
            <SectionText>{t('onboarding.consentForm.privateEducationSubTitle')}</SectionText>
            <Carousel
              ref={divRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{ borderColor: section1Red ? 'red' : '#b8b8b8' }}
            >
              <Inner style={{ transform: `translateX(-${pageNum * 100}%) ` }}>
                <CarouselItem>
                  <BoldedText fontSize="16px">1. {t('onboarding.consentForm.privateEducationSectionText1')}</BoldedText>
                  1.1 {t('onboarding.consentForm.privateEducationSectionText1Sub1')}
                </CarouselItem>

                <CarouselItem>1.2 {t('onboarding.consentForm.privateEducationSectionText1Sub2')}</CarouselItem>

                <CarouselItem>
                  <BoldedText fontSize="16px">
                    2. {t('onboarding.consentForm.privateEducationSection1Text2Title')}
                  </BoldedText>

                  {t('onboarding.consentForm.privateEducationSection1Text2')}
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    3. {t('onboarding.consentForm.privateEducationSection1Text3Title')}{' '}
                  </BoldedText>

                  {t('onboarding.consentForm.privateEducationSection1Text3')}
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    4. {t('onboarding.consentForm.privateEducationSection1Text4Title')}
                  </BoldedText>

                  {t('onboarding.consentForm.privateEducationSection1Text4')}
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    5. {t('onboarding.consentForm.privateEducationSection1Text5Title')}
                  </BoldedText>

                  {t('onboarding.consentForm.privateEducationSection1Text5')}
                </CarouselItem>
                <CarouselItem>
                  5.1 {t('onboarding.consentForm.privateEducationSection1Text51')}
                  <br></br>
                  <br></br>
                  5.2 {t('onboarding.consentForm.privateEducationSection1Text52')}
                </CarouselItem>
                <CarouselItem>
                  <p>
                    5.3 {t('onboarding.consentForm.privateEducationSection1Text53')}{' '}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection1Text54')}
                    </RedBoldedTextMobile>{' '}
                    {t('onboarding.consentForm.privateEducationSection1Text55')}{' '}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection1Text56')}
                    </RedBoldedTextMobile>{' '}
                    {t('onboarding.consentForm.privateEducationSection1Text57')}
                  </p>
                </CarouselItem>
                <CarouselItem ref={targetRef}>
                  <BoldedText fontSize="16px">
                    6. {t('onboarding.consentForm.privateEducationSection1Text6Title')}
                  </BoldedText>

                  {t('onboarding.consentForm.privateEducationSection1Text6')}
                </CarouselItem>
              </Inner>
              <BallsContainer>
                <Ball style={{ background: section1Ball1Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball2Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball3Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball4Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball5Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball6Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball7Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball8Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section1Ball9Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
              </BallsContainer>
            </Carousel>

            <SectionBox style={{ borderColor: section1Red ? 'red' : '#b8b8b8' }}>
              <OrderList>
                <OrderListItem>
                  <SectionText>
                    <BoldedText fontSize="18px">{t('onboarding.consentForm.privateEducationSectionText1')}</BoldedText>
                    <OrderList>
                      <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                        <SectionText>{t('onboarding.consentForm.privateEducationSectionText1Sub1')}</SectionText>
                      </OrderListItem>
                      <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                        <SectionText>{t('onboarding.consentForm.privateEducationSectionText1Sub2')}</SectionText>
                      </OrderListItem>
                    </OrderList>
                  </SectionText>
                </OrderListItem>
                <OrderListItem>
                  <BoldedText fontSize="18px">
                    {t('onboarding.consentForm.privateEducationSection1Text2Title')}
                  </BoldedText>
                  <SectionText>{t('onboarding.consentForm.privateEducationSection1Text2')}</SectionText>
                </OrderListItem>
                <OrderListItem>
                  <BoldedText fontSize="18px">
                    {t('onboarding.consentForm.privateEducationSection1Text3Title')}
                  </BoldedText>
                  <SectionText>{t('onboarding.consentForm.privateEducationSection1Text3')}</SectionText>
                </OrderListItem>
                <OrderListItem>
                  <BoldedText fontSize="18px">
                    {t('onboarding.consentForm.privateEducationSection1Text4Title')}
                  </BoldedText>
                  <SectionText>{t('onboarding.consentForm.privateEducationSection1Text4')}</SectionText>
                </OrderListItem>
                <OrderListItem>
                  <BoldedText fontSize="18px">
                    {t('onboarding.consentForm.privateEducationSection1Text5Title')}
                  </BoldedText>
                  <SectionText>
                    {t('onboarding.consentForm.privateEducationSection1Text5')}

                    <OrderList>
                      <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                        <SectionText>{t('onboarding.consentForm.privateEducationSection1Text51')}</SectionText>
                      </OrderListItem>
                      <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                        <SectionText>{t('onboarding.consentForm.privateEducationSection1Text52')}</SectionText>
                      </OrderListItem>
                      <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                        <SectionText>
                          {t('onboarding.consentForm.privateEducationSection1Text53')}{' '}
                          <RedBoldedText>{t('onboarding.consentForm.privateEducationSection1Text54')}</RedBoldedText>{' '}
                          {t('onboarding.consentForm.privateEducationSection1Text55')}{' '}
                          <RedBoldedText>{t('onboarding.consentForm.privateEducationSection1Text56')}</RedBoldedText>{' '}
                          {t('onboarding.consentForm.privateEducationSection1Text57')}
                        </SectionText>
                      </OrderListItem>
                    </OrderList>
                  </SectionText>
                </OrderListItem>
                <OrderListItem>
                  <BoldedText fontSize="18px">
                    {t('onboarding.consentForm.privateEducationSection1Text6Title')}
                  </BoldedText>
                  <SectionText ref={targetRef}>{t('onboarding.consentForm.privateEducationSection1Text6')}</SectionText>
                </OrderListItem>
              </OrderList>
            </SectionBox>

            <CheckBoxContainer>
              <CheckBoxText style={{ color: isVisible || section1Checked ? 'black' : '#B7B7B7' }}>
                <CheckBox
                  type="checkbox"
                  checked={section1Checked || consentFormCompleted}
                  disabled={(isVisible || section1Checked) && !consentFormCompleted ? false : true}
                  onChange={handleSection1Change}
                ></CheckBox>
                {t('onboarding.consentForm.privateEducationSection1CheckboxText')}
              </CheckBoxText>
            </CheckBoxContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle id="section-2">{t('onboarding.consentForm.privateEducationSection2Title')}</SectionTitle>
            <SectionText>{t('onboarding.consentForm.privateEducationSection2SubTitle')}</SectionText>
            <Carousel
              ref={divRef}
              onTouchStart={handleTouchStart2}
              onTouchMove={handleTouchMove2}
              onTouchEnd={handleTouchEnd2}
              style={{ borderColor: section2Red ? 'red' : '#b8b8b8' }}
            >
              <Inner style={{ transform: `translateX(-${pageNum2 * 100}%) ` }}>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    1.1 {t('onboarding.consentForm.privateEducationSection2Text1Text1Title')}
                  </BoldedText>
                  <p>
                    {t('onboarding.consentForm.privateEducationSection2Text1Text1')}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection2Text1Text1red')}
                    </RedBoldedTextMobile>
                    {t('onboarding.consentForm.privateEducationSection2Text1Text1red1')}
                  </p>
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    1.2 {t('onboarding.consentForm.privateEducationSection2Text1Text2Title')}
                  </BoldedText>
                  <p>
                    {t('onboarding.consentForm.privateEducationSection2Text1Text2')}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection2Text1Textred3')}
                    </RedBoldedTextMobile>

                    {t('onboarding.consentForm.privateEducationSection2Text1Textredred2')}
                    <OrderList>
                      <OrderListItem style={{ listStyleType: 'lower-alpha', fontSize: '16px' }}>
                        <p>
                          {t('onboarding.consentForm.privateEducationSection2Text1Text21')}
                          <RedBoldedTextMobile>
                            {t('onboarding.consentForm.privateEducationSection2Text1Textred')}
                          </RedBoldedTextMobile>
                          {t('onboarding.consentForm.privateEducationSection2Text1Textred1')}
                          <RedBoldedTextMobile>
                            {t('onboarding.consentForm.privateEducationSection2Text1Textred3')}
                          </RedBoldedTextMobile>

                          {t('onboarding.consentForm.privateEducationSection2Text1Textredred2')}
                        </p>
                      </OrderListItem>
                      <OrderListItem style={{ listStyleType: 'lower-alpha', fontSize: '16px' }}>
                        <p>
                          {t('onboarding.consentForm.privateEducationSection2Text1Text22')}
                          <RedBoldedTextMobile>
                            {t('onboarding.consentForm.privateEducationSection2Text1Text22red')}
                          </RedBoldedTextMobile>
                          {t('onboarding.consentForm.privateEducationSection2Text1Text22red1')}
                        </p>
                      </OrderListItem>
                    </OrderList>
                  </p>
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    {t('onboarding.consentForm.privateEducationSection2Text1Text4Title')}
                  </BoldedText>
                  {t('onboarding.consentForm.privateEducationSection2Text1Text4')}
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    2.1 {t('onboarding.consentForm.privateEducationSection2Text2Text1Title')}
                  </BoldedText>
                  {t('onboarding.consentForm.privateEducationSection2Text2Text1')}
                  <br></br>
                  <br></br>
                  <BoldedText fontSize="16px">
                    2.2 {t('onboarding.consentForm.privateEducationSection2Text2Text2Title')}
                  </BoldedText>
                  {t('onboarding.consentForm.privateEducationSection2Text2Text2')}
                </CarouselItem>

                <CarouselItem>
                  <BoldedText fontSize="16px">
                    3.1 {t('onboarding.consentForm.privateEducationSection2Text3Text1TitleMobile')}
                  </BoldedText>
                  <p>
                    {t('onboarding.consentForm.privateEducationSection2Text3Text1')}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text1Red')}
                    </RedBoldedTextMobile>
                    {t('onboarding.consentForm.privateEducationSection2Text3Text1Red2')}
                  </p>
                </CarouselItem>
                <CarouselItem>
                  <BoldedText fontSize="16px">
                    3.2 {t('onboarding.consentForm.privateEducationSection2Text3Text1TitleMobile')}
                  </BoldedText>
                  {t('onboarding.consentForm.privateEducationSection2Text3Text2')}
                  <br></br>
                  <br></br>
                  <BoldedText fontSize="16px" ref={targetRef2}>
                    3.3 {t('onboarding.consentForm.privateEducationSection2Text3Text1TitleMobile')}
                  </BoldedText>
                  <p ref={targetRef2}>
                    {t('onboarding.consentForm.privateEducationSection2Text3Text3')}
                    <RedBoldedTextMobile>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text3red')}
                    </RedBoldedTextMobile>
                    {t('onboarding.consentForm.privateEducationSection2Text3Text3red2')}
                  </p>
                </CarouselItem>
              </Inner>
              <BallsContainer>
                <Ball style={{ background: section2Ball1Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section2Ball2Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section2Ball3Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section2Ball4Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section2Ball5Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section2Ball6Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
              </BallsContainer>
            </Carousel>
            <SectionBox style={{ borderColor: section2Red ? 'red' : '#b8b8b8' }}>
              <SectionText>
                <SectionBoxTitle>{t('onboarding.consentForm.privateEducationSection2Text1Title')}</SectionBoxTitle>
                <OrderList>
                  <OrderListItem>
                    <SectionText>
                      <BoldedText fontSize="18px">
                        {t('onboarding.consentForm.privateEducationSection2Text1Text1Title')}
                      </BoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text1Text1')}
                      <RedBoldedText>{t('onboarding.consentForm.privateEducationSection2Text1Text1red')}</RedBoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text1Text1red1')}
                    </SectionText>
                  </OrderListItem>
                  <OrderListItem>
                    <SectionText>
                      <BoldedText fontSize="18px">
                        {t('onboarding.consentForm.privateEducationSection2Text1Text2Title')}
                      </BoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text1Text2')}
                      <OrderList>
                        <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                          <SectionText>
                            {t('onboarding.consentForm.privateEducationSection2Text1Text21')}
                            <RedBoldedText>
                              {t('onboarding.consentForm.privateEducationSection2Text1Textred')}
                            </RedBoldedText>
                            {t('onboarding.consentForm.privateEducationSection2Text1Textred1')}
                            <RedBoldedText>
                              {t('onboarding.consentForm.privateEducationSection2Text1Textred3')}
                            </RedBoldedText>

                            {t('onboarding.consentForm.privateEducationSection2Text1Textredred2')}
                          </SectionText>
                        </OrderListItem>
                        <OrderListItem style={{ listStyleType: 'lower-alpha' }}>
                          <SectionText>
                            {t('onboarding.consentForm.privateEducationSection2Text1Text22')}
                            <RedBoldedText>
                              {t('onboarding.consentForm.privateEducationSection2Text1Text22red')}
                            </RedBoldedText>

                            {t('onboarding.consentForm.privateEducationSection2Text1Text22red1')}
                          </SectionText>
                        </OrderListItem>
                      </OrderList>
                    </SectionText>
                  </OrderListItem>
                  <SectionText>
                    <BoldedText fontSize="18px">
                      {t('onboarding.consentForm.privateEducationSection2Text1Text4Title')}
                    </BoldedText>
                    {t('onboarding.consentForm.privateEducationSection2Text1Text4')}
                  </SectionText>
                </OrderList>
              </SectionText>

              <SectionText>
                <SectionBoxTitle>{t('onboarding.consentForm.privateEducationSection2Text2Title')}</SectionBoxTitle>
                <OrderList>
                  <OrderListItem>
                    <SectionText>
                      <BoldedText fontSize="18px">
                        {t('onboarding.consentForm.privateEducationSection2Text2Text1Title')}
                      </BoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text2Text1')}
                    </SectionText>
                  </OrderListItem>
                  <OrderListItem>
                    <SectionText>
                      <BoldedText fontSize="18px">
                        {t('onboarding.consentForm.privateEducationSection2Text2Text2Title')}
                      </BoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text2Text2')}
                    </SectionText>
                  </OrderListItem>
                </OrderList>
              </SectionText>

              <SectionText>
                <SectionBoxTitle>{t('onboarding.consentForm.privateEducationSection2Text3Title')}</SectionBoxTitle>
                <OrderList>
                  <OrderListItem>
                    <SectionText>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text1')}
                      <RedBoldedText>{t('onboarding.consentForm.privateEducationSection2Text3Text1Red')}</RedBoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text1Red2')}
                    </SectionText>
                  </OrderListItem>
                  <OrderListItem>
                    <SectionText>{t('onboarding.consentForm.privateEducationSection2Text3Text2')}</SectionText>
                  </OrderListItem>
                  <OrderListItem>
                    <SectionText ref={targetRef2}>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text3')}
                      <RedBoldedText>{t('onboarding.consentForm.privateEducationSection2Text3Text3red')}</RedBoldedText>
                      {t('onboarding.consentForm.privateEducationSection2Text3Text3red2')}
                    </SectionText>
                  </OrderListItem>
                </OrderList>
              </SectionText>
            </SectionBox>
            <CheckBoxContainer>
              <CheckBoxText style={{ color: isVisible2 || section2Checked ? 'black' : '#B7B7B7' }}>
                <CheckBox
                  type="checkbox"
                  checked={section2Checked || consentFormCompleted}
                  disabled={(isVisible2 || section2Checked) && !consentFormCompleted ? false : true}
                  onChange={handleSection2Change}
                ></CheckBox>
                {t('onboarding.consentForm.privateEducationSection2CheckboxText')}
              </CheckBoxText>
            </CheckBoxContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle id="section-3"> {t('onboarding.consentForm.serivcePolicyTitle')} </SectionTitle>
            <SectionText></SectionText>
            <Carousel
              ref={divRef}
              onTouchStart={handleTouchStart3}
              onTouchMove={handleTouchMove3}
              onTouchEnd={handleTouchEnd3}
              style={{ borderColor: section3Red ? 'red' : '#b8b8b8' }}
            >
              <Inner style={{ transform: `translateX(-${pageNum3 * 100}%) ` }}>
                <CarouselItem>1. {t('onboarding.consentForm.serivcePolicyText1')}</CarouselItem>
                <CarouselItem>2. {t('onboarding.consentForm.serivcePolicyText2')}</CarouselItem>
                <CarouselItem>3. {t('onboarding.consentForm.serivcePolicyText3')}</CarouselItem>
              </Inner>
              <BallsContainer>
                <Ball style={{ background: section3Ball1Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section3Ball2Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
                <Ball style={{ background: section3Ball3Lighted ? '#5ABAAE' : '#D9D9D9' }}></Ball>
              </BallsContainer>
            </Carousel>
            <NoScrollSectionBoxMobile style={{ borderColor: section3Red ? 'red' : '#b8b8b8' }}>
              <OrderList>
                <OrderListItem>
                  <SectionText>{t('onboarding.consentForm.serivcePolicyText1')}</SectionText>
                </OrderListItem>
                <OrderListItem>
                  <SectionText>{t('onboarding.consentForm.serivcePolicyText2')}</SectionText>
                </OrderListItem>
                <OrderListItem>
                  <SectionText>{t('onboarding.consentForm.serivcePolicyText3')}</SectionText>
                </OrderListItem>
              </OrderList>
            </NoScrollSectionBoxMobile>
            <CheckBoxContainer>
              <CheckBoxText style={{ color: isVisible3 || section3Checked ? 'black' : '#B7B7B7' }}>
                <CheckBox
                  type="checkbox"
                  checked={section3Checked || consentFormCompleted}
                  disabled={(isVisible3 || section3Checked) && !consentFormCompleted ? false : true}
                  onChange={handleSection3Change}
                ></CheckBox>
                {t('onboarding.consentForm.servicePolicyCheckboxText')}
              </CheckBoxText>
            </CheckBoxContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle id="section-4"> {t('onboarding.consentForm.privatePolicyTitle')} </SectionTitle>
            <SectionText></SectionText>
            <NoScrollSectionBox style={{ borderColor: section4Red ? 'red' : '#b8b8b8' }}>
              <NoScrollSectionText style={{ marginBottom: '1%' }}>
                {t('onboarding.consentForm.privatePolicyText')}{' '}
                <a href="https://eval.qierbao.com/privacy-policy" target="_blank">
                  https://eval.qierbao.com/privacy-policy
                </a>
                {t('onboarding.consentForm.privatePolicyText2')}
              </NoScrollSectionText>
            </NoScrollSectionBox>
            <CheckBoxContainer>
              <CheckBoxText>
                <CheckBox
                  type="checkbox"
                  checked={section4aChecked || consentFormCompleted}
                  onChange={handleSection4aChange}
                  disabled={consentFormCompleted ? true : false}
                ></CheckBox>
                {t('onboarding.consentForm.privatePolicyCheckbox')}
              </CheckBoxText>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <CheckBoxText>
                <CheckBox
                  type="checkbox"
                  checked={section4bChecked}
                  onChange={handleSection4bChange}
                  disabled={consentFormCompleted ? true : false}
                ></CheckBox>
                <span>
                  {t('onboarding.consentForm.smsConsentPolicy1')}
                  <br />
                  <a href="https://eval.qierbao.com/privacy-policy" target="_blank" rel="noreferrer">
                    {t('onboarding.consentForm.smsConsentPolicy2')}
                  </a>
                </span>
              </CheckBoxText>
            </CheckBoxContainer>
          </SectionContainer>
        </SectionsContainer>

        <SubmitButton onClick={handleClickedSubmit} style={{ display: consentFormCompleted ? 'none' : '' }}>
          {t('onboarding.consentForm.submit')}
        </SubmitButton>

        <SubmitButton onClick={goBackToProfile} style={{ display: !consentFormCompleted ? 'none' : '' }}>
          {t('onboarding.consentForm.back')}
        </SubmitButton>
      </Container>
    </BiggerContainer>
  );
};

export default ConsentFormRouteCPC;
