import styled from 'styled-components';

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
`;

export const PasswordInput = styled.input`
  font-size: 14px;
`;

export const SubmitButton = styled.button`
  width: 100%;
`;
