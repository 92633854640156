import styled from 'styled-components';
import { GeneralSegment } from '../../../../../style';

// category card component
export const Card = styled(GeneralSegment)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 300px;
  height: 240px;
  margin: 0 15px 0 0;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: #5abaae;
  }
`;
export const PieChartFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`;
export const TotalSkillMetricContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80px;
`;
export const CategoryMetricsDetailsContainer = styled.div`
  margin-top: 20px;
`;
export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const ColorIndicator = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 5px;
  height: 14px;
  margin-right: 5px;
`;
