import styled from 'styled-components';

export const FeedbackModalContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 25px 25px 30px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 15px;
  height: max-content;
  text-align: center;
  overscroll-behavior: contain;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  z-index: 99;

  @media only screen and (max-width: 430px) {
    width: 300px;
  } ;
`;

export const FeedbackModalTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const FeedbackModalText = styled.div`
  font-size: 16px;
  width: 90%;
`;

export const FeedbackModalButton = styled.button<{ marginTop: string; marginBottom: string; disabled: boolean }>`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  font-size: 20px;
  border-radius: 5px;
  border: ${(props) => (props.disabled ? '#D9D9D9' : '#5abaae')} 1px solid;
  padding: 5px 24px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background: ${(props) => (props.disabled ? '#D9D9D9' : '#5abaae')};
  color: white;
  transition-duration: 0.3s;

  @media only screen and (max-width: 572px) {
    text-align: center;
    margin-top: 30px;
  } ;
`;
export const FeedbackEndingModal = styled.div``;
export const FeedbackActivityModal = styled.div<{
  display: boolean;
}>`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 2rem 25px 2rem;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 15px;
  height: max-content;
  text-align: center;
  overscroll-behavior: contain;

  flex-direction: column;
  align-items: center;
  z-index: 99;

  display: ${(props) => (props.display ? 'flex' : 'none')};
  width: 450px;

  @media only screen and (max-width: 650px) {
    padding-left: 0;
    padding-right: 0;
    width: 340px;
  }
`;
export const ActivityContainer = styled.div<{}>`
  display: inline-flex;
  text-align: left;
  align-items: center;
  padding-left: 30px;

  @media only screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
`;

export const ActivityPicture = styled.div<{
  inputUrl: string;
}>`
  width: 100px;
  height: 100px;
  border: 1px solid #333333;
  background-image: url(${(props) => props.inputUrl});
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (max-width: 650px) {
  }
  margin-left: 0px;
`;

export const ActivityTextContainer = styled.div`
  margin-left: 3rem;
  margin-bottom: 0.5rem;
  width: 220px;
  @media only screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;
export const FeedbackActivityModalTitle = styled.div`
  font-size: 2rem;
  margin-bottom: 2.5rem;
`;

export const FeedbackModalActivityTitle = styled.div`
  font-weight: bold;
  font-size: 2.1rem;
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;

  @media only screen and (max-width: 650px) {
    width: 250px;
    justify-content: center;
    height: auto;
  }
`;

export const ActivityCompletedTitle = styled.div``;

export const MoreDetailsLink = styled.a`
  text-decoration: underline;
`;

export const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
`;

export const DidNotCompleteCheckbox = styled.input`
  margin: 0;
  margin-top: 3px;
  margin-right: 1rem;
`;

export const StarsContainer = styled.div<{ didNotComplete: boolean }>`
margin:4rem 0 1rem 0;
transform: scale(1)pointer-events: ${(props) => (props.didNotComplete ? 'none' : '')};
cursor: ${(props) => (props.didNotComplete ? ' not-allowed' : 'pointer')};

@media only screen and (max-width:650px){
  margin: 2rem 0 1rem 0;
}
`;

export const NextButton = styled.div``;

export const PageNumber = styled.div`
  color: #979797;
  margin-top: 1rem;
`;
export const DetailsContainer = styled.div`
  margin-top: 3rem;
  width: 300px;
  height: 300px;
  border: 1px solid #333333;
  text-align: left;
  padding: 1rem;
  overflow-y: auto;

  @media only screen and (max-width: 650px) {
    width: 290px;
  }
`;
export const ImageNotAvaliable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  margin: 0 1rem 0 1rem;
  width: 20rem;
`;

export const RadioButtonLabel = styled.label`
  margin: 1rem 0 0 0;
  display: flex;
  cursor: pointer;
  text-align: left;
`;
export const RadioButton = styled.input`
  margin: 0 0.5rem 0 0;
  outline: none !important;
  outline: 1px solid red;
`;

export const RadioTextBox = styled.textarea`
  height: 100px;
  width: 200px;
  resize: none;
  margin-top: 0.5rem;
  &:focus {
    outline: none !important;
    border: 2px solid #5abaae;
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
