/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Collapse, List, Progress, Timeline, Tooltip, Typography } from 'antd';
import { ConsultationsAndPlanObj, TherapistChildContext } from '../../../TherapistChildContext';
import {
  ButtonContainer,
  DetailsContainer,
  DetailsItemContainer,
  UploadVideoButton,
} from '../../../components/ConsultationAndPlanCard/styles';
import DefaultDetails from '../../../components/ConsultationAndPlanCard/DefaultDetails';
import {
  CaretRightOutlined,
  SolutionOutlined,
  FieldTimeOutlined,
  CarryOutOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  CloudUploadOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import ViewVideoModal from '../../../components/ViewVideoModal';
import { consultationActionButton } from '../../TodoRoute/component/todoTableUtils';
import { useHistory } from 'react-router';
import Uploader from '../../../../../../util/Uploader';
import UserContext from '../../../../../common/UserContext';
import { useMutation } from 'react-apollo';
import {
  UPLOAD_VIDEO_MUTATION,
  UploadVideoData,
  UploadVideoVariables,
} from '../../../../../../graphql/mutations/uploadVideo';
import { CHILD_CONSULTATION_NOTES } from '../../../../../../graphql/queries/childConsultationNotes';
import { S3consultationVideoRoute } from '../../../../../../helpers/functions';

interface Props {
  consultation: ConsultationsAndPlanObj[];
  type: 'next' | 'reschedule' | 'post';
}
const NextTab = ({ consultation, type }: Props) => {
  const { t } = useTranslation();
  const { Title, Link, Text } = Typography;
  const { Panel } = Collapse;
  const history = useHistory();
  const {
    child: { id: childId, name: childName },
  } = useContext(TherapistChildContext);
  const { child } = useContext(TherapistChildContext);
  const {
    user,
    user: { name: therapistName, uuid: therapistUUID },
  } = useContext(UserContext);
  const [currentConsultationIndex, setCurrentConsultationIndex] = useState(0);
  const [uploadedVideoName, setUploadedVideoName] = useState('');
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [fileUploaded, setUploadedFile] = useState<File>();
  const [activeUpload, setActiveUpload] = useState('');
  const [videoRoute, setVideoRoute] = useState('');
  const [uploadVideo, { data }] = useMutation<UploadVideoData, UploadVideoVariables>(UPLOAD_VIDEO_MUTATION, {
    refetchQueries: [{ query: CHILD_CONSULTATION_NOTES, variables: { childId: child.id } }],
  });
  const genExtra = (item: ConsultationsAndPlanObj) => (
    <>
      {type !== 'post' && (
        <ButtonContainer>
          {(item.isNextConsultation || item.status === 'Ongoing') && (
            <Button
              type="default"
              shape="round"
              icon={<SolutionOutlined translate={undefined} />}
              onClick={() => history.push(`/child/${childId}/plan`)}
            >
              {t('therapist.childInfo.general.button.viewLastPlan')}
            </Button>
          )}
          {consultationActionButton(item, true, t)}
        </ButtonContainer>
      )}
    </>
  );

  const badgeBackgroundColor = (status: string): string => {
    if (status === 'Ongoing') {
      return '#e6f7ff';
    } else if (status === 'Next') {
      return '#fffbe6';
    } else if (status === 'Reschedule') {
      return '#fff1f0';
    } else {
      return '#f6ffed';
    }
  };

  const badgeBorderColor = (status: string): string => {
    if (status === 'Ongoing') {
      return '#91d5ff';
    } else if (status === 'Next') {
      return '#ffe58f';
    } else if (status === 'Reschedule') {
      return '#ffa39e';
    } else {
      return '#b7eb8f';
    }
  };

  const badgeColor = (status: string): string => {
    if (status === 'Ongoing') {
      return '#096dd9';
    } else if (status === 'Next') {
      return '#d48806';
    } else if (status === 'Reschedule') {
      return '#cf1322';
    } else {
      return '#389e0d';
    }
  };

  const timelineColor = (status: string): string => {
    if (status === 'Ongoing') {
      return '#1890ff';
    } else if (status === 'Next') {
      return 'gold';
    } else if (status === 'Reschedule') {
      return 'red';
    } else {
      return 'green';
    }
  };

  const timelineDot = (status: string): JSX.Element => {
    if (status === 'Ongoing') {
      return <VideoCameraOutlined translate={undefined} />;
    } else if (status === 'Next') {
      return <CalendarOutlined translate={undefined} />;
    } else if (status === 'Reschedule') {
      return <FieldTimeOutlined translate={undefined} />;
    } else {
      return <CarryOutOutlined translate={undefined} />;
    }
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    setActiveUpload(e.target.id);
    if (!files || files.length === 0) {
      return;
    }
    const f = files[0];

    if (f && f.type.includes('video')) {
      setUploadedFile(f);
      handleSaveFile(f, e.target.id);
    }
  };

  const handleSaveFile = (file: File, consultationId: string) => {
    if (!file) {
      return;
    }

    const encodedFolderName =
      'consultation-video-upload/' +
      user.uuid +
      '-' +
      user.name +
      '/' +
      child.name +
      '/consultation-id-' +
      consultationId;
    const fileName = file.name;
    Uploader(file, encodedFolderName, fileName)
      .on('httpUploadProgress', function (evt) {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(uploaded);
      })
      .send(function (err) {
        if (err) {
          setUploadStatus('exception');
          return;
        }
        uploadVideo({
          variables: {
            consultationId: parseInt(consultationId),
            fileName: file.name,
          },
        });
        handleUploadAgain();
        return file.name;
      });
  };
  const getS3consultationVideoRoute = async () => {
    if (uploadedVideoName === '') {
      setVideoRoute('');
      return;
    }
    const consultationVideoRoute = await S3consultationVideoRoute(
      therapistUUID,
      therapistName,
      childName,
      currentConsultationIndex.toString(),
      uploadedVideoName,
    );
    if (!consultationVideoRoute) return;
    setVideoRoute(consultationVideoRoute);
  };

  const handleUploadAgain = () => {
    setUploadedFile(undefined);
    setUploadProgress(0);
    setUploadStatus('');
  };

  const handleOpenViewVideoModal = async (consultationId: string, uploadedVideoName: string) => {
    setCurrentConsultationIndex(parseInt(consultationId));
    setUploadedVideoName(uploadedVideoName);
    setVideoModalOpen(true);
  };
  const handleCloseViewVideoModal = () => {
    setUploadedVideoName('');
    setVideoModalOpen(false);
  };

  useEffect(() => {
    getS3consultationVideoRoute();
  }, [uploadedVideoName]);

  return (
    <Timeline>
      {type === 'post' &&
        (currentConsultationIndex !== 0 && videoRoute !== '' ? (
          <ViewVideoModal src={videoRoute} isModalOpen={videoModalOpen} handleCloseModal={handleCloseViewVideoModal} />
        ) : null)}

      <List
        itemLayout="vertical"
        dataSource={consultation}
        grid={{ column: 1 }}
        renderItem={(item, index) => (
          <Timeline.Item color={timelineColor(item.status)} dot={timelineDot(item.status)}>
            {item.status === 'Ongoing' && (
              <Badge status="processing" style={{ top: '-3px', left: '-30px', position: 'absolute' }} />
            )}
            <div style={{ display: 'flex', gap: '20px' }}>
              <div style={{ width: '40px' }}>
                <Text type="secondary">{item.month}</Text>
                <Title level={3} style={{ margin: '0px' }}>
                  {item.date}
                </Title>
                <Text type="secondary">{item.weekday}</Text>
              </div>
              <div style={{ width: '100%' }}>
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => <CaretRightOutlined translate={undefined} rotate={isActive ? 90 : 0} />}
                  defaultActiveKey={type === 'next' ? 0 : ''}
                  style={{ width: '100%' }}
                >
                  <Panel
                    header={
                      <div style={{ display: 'block' }}>
                        <Badge
                          count={item.time}
                          style={{
                            backgroundColor: badgeBackgroundColor(item.status),
                            borderColor: badgeBorderColor(item.status),
                            color: badgeColor(item.status),
                            marginBottom: '5px',
                          }}
                        />
                        <Title style={{ marginBottom: '0px' }} level={5}>
                          {item.title}
                        </Title>
                      </div>
                    }
                    style={{ padding: '5px 0' }}
                    key={index}
                    extra={genExtra(item)}
                  >
                    <List.Item>
                      <DetailsContainer>
                        <DefaultDetails consultation={item}></DefaultDetails>
                        {type !== 'post' && (
                          <DetailsItemContainer>
                            <Tooltip
                              title={t('therapist.childInfo.general.button.tooltip.consultationLink')}
                              arrowPointAtCenter
                            >
                              <VideoCameraOutlined translate={undefined} style={{ paddingTop: '3px' }} />
                            </Tooltip>
                            <Link href={item.consultationUrl} target="_blank">
                              {t('therapist.childInfo.general.button.consultationLink')}
                            </Link>
                          </DetailsItemContainer>
                        )}
                        {type == 'post' ? (
                          <DetailsItemContainer>
                            <Tooltip title={t('therapist.childInfo.general.button.uploadVideo')} arrowPointAtCenter>
                              <CloudUploadOutlined translate={undefined} style={{ paddingTop: '4px' }} />
                            </Tooltip>
                            {fileUploaded !== undefined && activeUpload == item.id ? (
                              <>
                                {uploadProgress < 100 ? (
                                  uploadStatus == 'exception' ? (
                                    <>
                                      <Progress percent={uploadProgress} status="exception" />
                                      <Button onClick={handleUploadAgain}>
                                        {t('therapist.childInfo.general.button.retry')}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Progress percent={uploadProgress} />
                                    </>
                                  )
                                ) : uploadProgress == 100 ? (
                                  <>
                                    <label>{fileUploaded.name}</label>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  id={item.id}
                                  accept="video/*"
                                  onChange={handleUploadFile}
                                  disabled={uploadProgress < 100 && uploadProgress > 0}
                                  hidden
                                />
                                <UploadVideoButton htmlFor={item.id}>
                                  {t('therapist.childInfo.general.button.uploadVideoButton')}
                                </UploadVideoButton>
                              </>
                            )}
                          </DetailsItemContainer>
                        ) : null}
                        <DetailsItemContainer>
                          {item.uploadedVideos.length > 0 ? (
                            <List
                              size="small"
                              header={
                                <b>
                                  <u>{t('therapist.childInfo.general.button.uploadedFile')}</u>
                                </b>
                              }
                              bordered
                              dataSource={item.uploadedVideos}
                              renderItem={(item) => (
                                <List.Item style={{ display: 'flex' }}>
                                  {item.uploadedVideoName}
                                  <PlayCircleOutlined
                                    onClick={() =>
                                      handleOpenViewVideoModal(item.consultation.id, item.uploadedVideoName)
                                    }
                                    style={{
                                      display: 'flex',
                                      marginLeft: '24px',
                                      alignItems: 'center',
                                      fontSize: '16px',
                                    }}
                                    translate={undefined}
                                  />
                                </List.Item>
                              )}
                            />
                          ) : null}
                        </DetailsItemContainer>
                      </DetailsContainer>
                    </List.Item>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </Timeline.Item>
        )}
      />
    </Timeline>
  );
};

export default NextTab;
