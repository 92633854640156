import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import ClientMessageChatBox from './ClientMessageChatBox';
import { Loader } from 'semantic-ui-react';
import { GET_ROOM_MESSAGES_QUERY } from '../../../../graphql/queries/getRoomMessagesByType';
import {
  GetRoomMessagesByType,
  GetRoomMessagesByTypeVariables,
  GetRoomMessagesByType_getRoomMessagesByType,
} from '../../../../graphql/queries/types/GetRoomMessagesByType';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  roomId: string;
  messageType: string;
}

const ShowMessageButtonComponent = ({ roomId, messageType }: Props) => {
  const { loading, error, data } = useQuery<GetRoomMessagesByType, GetRoomMessagesByTypeVariables>(
    GET_ROOM_MESSAGES_QUERY,
    {
      variables: { roomId, messageType },
    },
  );
  const [roomData, setRoomData] = useState<Array<GetRoomMessagesByType_getRoomMessagesByType> | undefined>(undefined);
  useEffect(() => {
    if (data) {
      const { getRoomMessagesByType } = data;
      const copyRoomData = [...getRoomMessagesByType];
      setRoomData(copyRoomData.reverse());
    }
  }, [data]);
  const { t } = useTranslation();

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  return (
    <div style={{ overflowY: 'auto', height: '80vh' }} className="scrollDiv">
      {roomData && roomData.length > 0 ? (
        roomData.map((message: GetRoomMessagesByType_getRoomMessagesByType) => (
          <ClientMessageChatBox key={message.id} message={message} roomId={roomId} messageType={messageType} />
        ))
      ) : (
        <div>
          {messageType === 'DirectMessage' && (
            <div style={{ padding: '5px 25px' }}>{t('client.communicationCard.noRecord')}</div>
          )}
          {messageType === 'HomeworkSubmission' && (
            <div style={{ padding: '5px 25px' }}>{t('client.communicationCard.noHomework')}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowMessageButtonComponent;
