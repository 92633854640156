import React from 'react';
import { Tooltip } from 'antd';
import { isPast } from '../../../../../../util/DateAndTime';
import { ZoomButton } from '../../../components/ConsultationAndPlanCard/styles';
import { ConsultationsAndPlanObj } from '../../../TherapistChildContext';
import { VideoCameraOutlined } from '@ant-design/icons';
import CreatePlanButton from '../../../feature/CreatePlanButton';
import RescheduleConsultationButton from '../../../feature/RescheduleConsultationButton';

export const todoTableActionButton = (todo: ConsultationsAndPlanObj, showTitle: boolean, t): JSX.Element => {
  if (todo.type === 'plan') {
    return planActionButton(todo, showTitle, t);
  } else if (todo.type === 'consultation') {
    return consultationActionButton(todo, showTitle, t);
  } else {
    return <div></div>;
  }
};

export const consultationActionButton = (todo: ConsultationsAndPlanObj, showTitle: boolean, t): JSX.Element => {
  if (todo.accepted && isPast(todo.twoHourBeforeConsultationMilliseconds)) {
    return (
      <Tooltip title={t('therapist.childInfo.general.button.tooltip.zoom')} arrowPointAtCenter>
        <ZoomButton
          type="default"
          shape="round"
          icon={<VideoCameraOutlined translate={undefined} />}
          onClick={(event) => event.stopPropagation()}
          target="blank"
          href={todo.consultationUrl}
        >
          {showTitle && <>{t('therapist.childInfo.general.button.zoom')}</>}
        </ZoomButton>
      </Tooltip>
    );
  } else {
    return <RescheduleConsultationButton consultation={todo} showTitle={showTitle}></RescheduleConsultationButton>;
  }
};

export const planActionButton = (todo: ConsultationsAndPlanObj, showTitle: boolean, t): JSX.Element => {
  return (
    <Tooltip title={t('therapist.childInfo.general.button.tooltip.create')} arrowPointAtCenter>
      <div style={{ width: 'fit-content' }}>
        <CreatePlanButton consultation={todo} showTitle={showTitle}></CreatePlanButton>
      </div>
    </Tooltip>
  );
};

export const dueTimeTextSelector = (todo: ConsultationsAndPlanObj, length: 'long' | 'short'): string => {
  if (todo.type === 'plan') {
    return length === 'short'
      ? todo.thirtySixHourAfterConsultationEndShortDate
      : todo.thirtySixHourAfterConsultationEndDate;
  } else {
    return '';
  }
};

export const dueTimeValueSelector = (todo: ConsultationsAndPlanObj): number => {
  if (todo.type === 'plan') {
    return todo.thirtySixHourAfterConsultationEndMilliseconds;
  } else {
    return 0;
  }
};
