import styled from 'styled-components';

export const RecommendActivityContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 20px;
  width: 100%;
  height: 100px;
  text-align: center;
  font-weight: bold;
`;
export const RecommendActivityText = styled.div`
  color: #000000;
`;
export const RecommendActivityButton = styled.button`
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 24px;
  border: 0px;
  margin: 5px 0;
  color: white;
  background-color: #5dd9c4;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
`;

export const ActivityModalHeaderText = styled.div`
  margin: 5px;
  font-size: 18px;
  display: inline;
`;

export const ActivityModalContentContainer = styled.div`
  margin-top: 10px;
  font-size: 18px;
`;

export const ActivityModalContentText = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const ActivityModalContentParagraph = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
