import styled from 'styled-components';

// badge scroll list container
export const BadgeScrollListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px 0;
`;

// left right button
export const ArrowContainerLeft = styled.div`
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 50%);
  padding: 0 20px 0 0;
  position: absolute;
  display: flex;
  align-items: center;
  margin: -1px 0 0 -1px;
`;
export const ArrowContainerRight = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 50%);
  padding: 0 0 0 20px;
  position: absolute;
  display: flex;
  align-items: center;
  margin: -1px -1px 0 0;
  align-self: flex-end;
`;
export const ArrowButtonLeft = styled.img`
  height: 34px;
  width: 34px;
  transform: rotate(180deg);
  cursor: pointer;
`;
export const ArrowButtonRight = styled.img`
  height: 34px;
  width: 34px;
  cursor: pointer;
`;

// option badge scroll list
export const BadgeContainer = styled.div`
  display: flex;
  // margin-bottom: 25px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;
export const BadgeButton = styled.div<{ status: boolean }>`
  margin-right: 10px;
  word-warp: none;
  white-space: nowrap;
  border-radius: 25px;
  background-color: ${(props) => (props.status ? '#5abaae' : '#e7e7e7')};
  color: ${(props) => (props.status ? '#fff' : '#29292')};
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 150ms;
  :hover {
    background-color: ${(props) => (props.status ? '#5abaae' : '#ddd')};
  }
`;
