import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

export const CategoryOption = () => {
  const { t } = useTranslation();
  const option: ColumnFilterItem[] = [
    { text: t('utils.fiveCategories.2.title'), value: 0 },
    { text: t('utils.fiveCategories.1.title'), value: 1 },
    { text: t('utils.fiveCategories.5.title'), value: 2 },
    { text: t('utils.fiveCategories.6.title'), value: 3 },
    { text: t('utils.fiveCategories.3.title'), value: 4 },
    { text: t('utils.fiveCategories.4.title'), value: 5 },
    { text: t('utils.fiveCategories.7.title'), value: 6 },
  ];
  return option;
};

export const goalPercentage = (goalRecordCurrentNumerator, goalInitNumerator, goalSuccessNumerator): number => {
  const goalNumerator = goalRecordCurrentNumerator - goalInitNumerator;
  const goalDenominator = goalSuccessNumerator - goalInitNumerator;
  const goalProgress = goalNumerator / goalDenominator;
  const goalProgressNumber = isNaN(goalProgress) ? 0 : goalProgress;
  const goalPercentage = Math.round(goalProgressNumber * 100);
  return goalPercentage;
};
