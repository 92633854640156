import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SixMonthGoal from './SixMonthGoal';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import { Typography } from 'antd';
import GoalTable from './GoalTable';
import { TherapistChildContext } from '../../TherapistChildContext';

const GoalsRoute = () => {
  const { Title } = Typography;
  const { goals } = useContext(TherapistChildContext);
  const { t } = useTranslation();

  return (
    <div>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.childInfo.goal.pageTitle')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
          <SixMonthGoal />
          <div>
            <Title level={5}>{t('therapist.dashboard.myClient.clientInfo.notes.progressOfGoals')}</Title>
            <GoalTable goal={goals}></GoalTable>
          </div>
        </div>
      </RouteContentContainer>
    </div>
  );
};

export default GoalsRoute;
