import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { HurrayModalMask, HurrayModalContainer, Header, Content, HurrayImg, ButtonContainer } from './styles';
import HurrayImage from '../../../../assets/Hurray.gif';
import { ActionTitle, HeaderTitle, SaveButton } from '../../../../../../../style';
import { ClientContext } from '../../../../../ClientContext';
import {
  CompleteWordsData,
  CompleteWordsVariables,
  COMPLETE_WORDS_MUTATION,
} from '../../../../../../../graphql/mutations/completeWords';
import { Word, WordTracking, WordTrackingVariables } from '../../../../../../../graphql/queries/types/WordTracking';
import { CHILD_WORD_TRACKING_QUERY } from '../../../../../../../graphql/queries/childTrackingWords';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  wordIds: number[];
  completeWords: (words: Word[]) => void;
  handleClearActionWords: () => void;
};

const HurrayModal = ({ handleClearActionWords, completeWords, wordIds, showModal, handleClose }: Props) => {
  const { t } = useTranslation();
  const { child } = useContext(ClientContext);

  const [completeWord] = useMutation<CompleteWordsData, CompleteWordsVariables>(COMPLETE_WORDS_MUTATION, {
    update(cache, { data }) {
      const wordData = cache.readQuery<WordTracking, WordTrackingVariables>({
        variables: { childId: child.id },
        query: CHILD_WORD_TRACKING_QUERY,
      });
      if (wordData != null && data != null) {
        const newWords = wordData.words.map((word, index) => {
          if (wordIds.includes(word.id)) {
            word.complete = true;
          }
          return word;
        });
        wordData.words = newWords;
        cache.writeQuery<WordTracking, WordTrackingVariables>({
          variables: { childId: child.id },
          query: CHILD_WORD_TRACKING_QUERY,
          data: wordData,
        });
        handleClearActionWords();
        completeWords(newWords);
      }
    },
  });

  useEffect(() => {
    if (showModal) {
      completeWord({
        variables: {
          wordIds,
        },
      });
    }
  }, [showModal]);

  return (
    <HurrayModalMask open={showModal}>
      <HurrayModalContainer>
        <Header>
          <HurrayImg src={HurrayImage} alt="Hurray" />
        </Header>
        <Content>
          <HeaderTitle>{t('client.wordTracking.modal.hurray.title')}</HeaderTitle>
        </Content>
        <ButtonContainer>
          <SaveButton onClick={handleClose}>
            <ActionTitle>{t('client.wordTracking.modal.hurray.submit')}</ActionTitle>
          </SaveButton>
        </ButtonContainer>
      </HurrayModalContainer>
    </HurrayModalMask>
  );
};

export default HurrayModal;
