import styled from 'styled-components';
import { SmallSectionTitle, H1Title } from '../../../../style';
import Background1 from '../assets/SummaryBackground1.svg';
import Background2 from '../assets/SummaryBackground2.svg';
import Background3 from '../assets/SummaryBackground3.svg';
import Background4 from '../assets/SummaryBackground4.svg';
import Image1 from '../assets/SummaryImage1.svg';
import Image2 from '../assets/SummaryImage2.svg';
import Image3 from '../assets/SummaryImage3.svg';
import Image4 from '../assets/SummaryImage4.svg';

export const SummaryContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 230px;
  min-height: 120px;
  margin: 0 4px 0 4px;
  background-size: 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  position: relative;
`;
export const TotalWordContainer = styled(ContentContainer)`
  background-image: url(${Background2});
`;
export const TotalMasteredContainer = styled(ContentContainer)`
  background-image: url(${Background1});
`;
export const WordImitatedContainer = styled(ContentContainer)`
  background-image: url(${Background4});
`;
export const WordSpontaneousContainer = styled(ContentContainer)`
  background-image: url(${Background3});
`;

export const Avatar = styled.div`
  background-size: 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
`;
export const TotalWordAvatar = styled(Avatar)`
  background-image: url(${Image2});
  width: 125px;
  height: 123px;
`;
export const TotalMasteredAvatar = styled(Avatar)`
  background-image: url(${Image1});
  width: 100px;
  height: 113px;
`;
export const WordImitatedAvatar = styled(Avatar)`
  background-image: url(${Image4});
  width: 124px;
  height: 119px;
`;
export const WordSpontaneousAvatar = styled(Avatar)`
  background-image: url(${Image3});
  width: 90px;
  height: 115px;
`;

export const Title = styled(SmallSectionTitle)`
  position: absolute;
  right: 15px;
  top: 30px;
  text-align: right;
  white-space: pre-wrap;
  color: white;
`;

export const Count = styled(H1Title)`
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: white;
  margin-bottom: 0;
`;
