import React, { useState } from 'react';
import { FeedbackModalContainer, FeedbackModalText, FeedbackModalTitle, FeedbackModalButton } from './styles';

const FeedbackStartingModal = (props) => {
  return (
    <>
      {props.display && (
        <FeedbackModalContainer>
          <FeedbackModalTitle>{props.title}</FeedbackModalTitle>
          <FeedbackModalText>{props.text}</FeedbackModalText>
          <FeedbackModalButton disabled={false} onClick={props.onClick} marginTop={'25px'} marginBottom={'0px'}>
            {props.buttonText}
          </FeedbackModalButton>
        </FeedbackModalContainer>
      )}
    </>
  );
};

export default FeedbackStartingModal;
