import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Tooltip, Typography } from 'antd';
import { DetailsItemContainer } from './styles';
import { UserOutlined, ManOutlined, WomanOutlined, CalendarOutlined, TranslationOutlined } from '@ant-design/icons';
import { ConsultationsAndPlanObj, TherapistChildContext } from '../../TherapistChildContext';

interface Props {
  consultation: ConsultationsAndPlanObj;
}

const DefaultDetails = ({ consultation }: Props) => {
  const { t } = useTranslation();
  const { child, setShowUserInfoDrawer } = useContext(TherapistChildContext);
  const { Text } = Typography;

  return (
    <>
      <DetailsItemContainer>
        <Tooltip title={t('therapist.childInfo.general.consultationDetailsTooltip.time')} arrowPointAtCenter>
          <CalendarOutlined translate={undefined} style={{ paddingTop: '3px' }} />
        </Tooltip>
        <Text>
          {consultation.consultationStartDate} ~ {consultation.consultationEndTime}
        </Text>
      </DetailsItemContainer>
      <DetailsItemContainer>
        <Tooltip title={t('therapist.childInfo.general.consultationDetailsTooltip.people')} arrowPointAtCenter>
          <UserOutlined translate={undefined} style={{ paddingTop: '3px' }} />
        </Tooltip>
        <div>
          <Tag style={{ cursor: 'pointer' }} onClick={() => setShowUserInfoDrawer(true)}>
            {child.client.user.name}
          </Tag>
          <Tag style={{ cursor: 'pointer' }} onClick={() => setShowUserInfoDrawer(true)}>
            {child.name}
            {child.gender === 'male' ? (
              <ManOutlined style={{ marginLeft: '4px', color: 'rgb(95,197,250)' }} translate={undefined} />
            ) : (
              <WomanOutlined style={{ marginLeft: '4px', color: 'rgb(250,104,109)' }} translate={undefined} />
            )}
          </Tag>
        </div>
      </DetailsItemContainer>
      <DetailsItemContainer>
        <Tooltip title={t('therapist.childInfo.general.consultationDetailsTooltip.language')} arrowPointAtCenter>
          <TranslationOutlined translate={undefined} style={{ paddingTop: '3px' }} />
        </Tooltip>
        <Text>{t(`therapist.dashboard.myClient.card.languageOptions.${child.client.user.locale}`)}</Text>
      </DetailsItemContainer>
    </>
  );
};

export default DefaultDetails;
