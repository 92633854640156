import gql from 'graphql-tag';
import { User, ReturnMessage } from '../../types';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!, $locale: String!) {
    login(email: $email, password: $password, locale: $locale) {
      user {
        id
        uuid
        name
        email
        metaType
        isOauth
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  login: {
    user?: User;
    returnMessage: ReturnMessage;
  };
}

export interface Variables {
  email: string;
  password: string;
  locale: string;
}
