import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { SectionTitle } from '../../../../../style';
import { TitleFlexBox } from '../../../../common/SectionActionTitle/styles';
import { SectionActionContainer, TodayButton } from './styles';

interface Props {
  thisWeek: boolean;
  currentWeekday: number;
  startDiffDay: number;
  dateRange: number;
  editMode: boolean;
  setStartDiffDay: (num: number) => void;
  handleSetDate: (current: number, index: number) => void;
}

const TitleAction = ({
  thisWeek,
  currentWeekday,
  startDiffDay,
  dateRange,
  editMode,
  setStartDiffDay,
  handleSetDate,
}: Props) => {
  const { t } = useTranslation();

  const [handlePrevWeekDisabled, handleNextWeekDisabled] = useMemo(() => {
    const prevWeekDisabled = startDiffDay <= -dateRange || editMode;
    const nextWeekDisabled = startDiffDay >= 1 || editMode;
    return [prevWeekDisabled, nextWeekDisabled];
  }, [startDiffDay, editMode]);

  const handleSetNextWeek = () => {
    setStartDiffDay(startDiffDay + 7);
  };

  const handleSetPrevWeek = () => {
    setStartDiffDay(startDiffDay - 7);
  };

  const handleSetToday = () => {
    handleSetDate(0, currentWeekday - 1);
    setStartDiffDay(1 - currentWeekday);
  };

  return (
    <TitleFlexBox>
      <SectionTitle style={{ marginBottom: '0' }}>{t('client.task.activityTab.dateTitle')}</SectionTitle>
      <SectionActionContainer>
        {!thisWeek && <TodayButton onClick={handleSetToday}>{t('client.task.activityTab.todayButton')}</TodayButton>}
        <Icon
          className="chevron left"
          circular
          link={!handlePrevWeekDisabled}
          disabled={handlePrevWeekDisabled}
          onClick={handleSetPrevWeek}
        />
        <Icon
          className="chevron right"
          circular
          link={!handleNextWeekDisabled}
          disabled={handleNextWeekDisabled}
          onClick={handleSetNextWeek}
        />
      </SectionActionContainer>
    </TitleFlexBox>
  );
};

export default React.memo(TitleAction);
