import React, { useState, useEffect } from 'react';
import { ReturnMessage } from '../../../types';
import Header from '../../common/Header';
import { Container, Loader } from 'semantic-ui-react';
import TherapistConsultationScreenInfoComponent from './InfoComponent';
import TherapistConsultationScreenActivitiesComponent from './ActivitiesComponent';
import TherapistConsultationActivitiesSelectionComponent from './Progress/ActivitySelectionComponent';
import TherapistConsultationScreenGoalsComponent from './GoalsComponent';
import TherapistConsultationScreenNotesComponent from './Notes/NotesComponent';
import TherapistConsultationScreenProgressComponent from './Progress/ProgressComponent';
import TherapistConsultationScreenLongerTermGoalsComponent from './Progress/LongerTermGoalsComponent';
import TherapistConsultationScreenTasksComponent from './Tasks/TasksComponent';
import TherapistConsultationScreenCommentForParentComponent from './CommentForParent/CommentForParentComponent';
import TherapistConsultationScreenContext from './TherapistConsultationScreenContext';
import { useQuery } from 'react-apollo';
import { CONSULTATION_QUERY } from '../../../graphql/queries/consultation';
import {
  Consultation,
  ConsultationVariables,
  Consultation_consultation_notes,
} from '../../../graphql/queries/types/Consultation';
import {
  Category_categories_subcategories_skills,
  Category_categories,
  Category_categories_subcategories,
} from '../../../graphql/queries/types/Category';
import { Preskills_getPreskillList } from '../../../graphql/queries/types/Preskills';
import { GetActivitiesFromPreskills_getActivitiesFromPreskills } from '../../../graphql/queries/types/GetActivitiesFromPreskills';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ConsultationScreenGrid } from './styles';
import ErrorPage from '../../common/ErrorPage';
import { Consultation_consultation_child_goals } from '../../../graphql/queries/types/Consultation';
import AchievedGoalModal from './Progress/AchievedGoal';
import { GeneralSegment } from '../../../style';
import { FAST_POLL, SLOW_POLL } from './constants';
interface MatchParams {
  id: string;
}

const TherapistConsultationScreenComponent = () => {
  const { id: consultationId } = useParams<MatchParams>();
  const [activeItem, setActiveItem] = useState('Notes');
  const [notes, setNotes] = useState<Consultation_consultation_notes>({
    id: '',
    mainComplaint: '',
    internalNotes: '',
    commentForParents: '',
  });
  const [newGoal, setNewGoal] = useState(false);
  const [newHomework, setNewHomework] = useState(false);
  const [category, setCategory] = useState<Category_categories | null>(null);
  const [subCategory, setSubCategory] = useState<Category_categories_subcategories | null>(null);
  const [skill, setSkill] = useState<Category_categories_subcategories_skills | null>(null);
  const [selectedPreskills, setSelectedPreskills] = useState<Preskills_getPreskillList[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>(
    [],
  );
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);
  const [pollingCount, setPollingCount] = useState<number>(0);
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<Consultation, ConsultationVariables>(CONSULTATION_QUERY, {
    variables: { consultationId },
    pollInterval: activeItem === 'Activities' || activeItem === 'Progress' ? FAST_POLL : SLOW_POLL,
  });

  const [showAchievedGoals, setShowAchievedGoals] = useState<boolean>(false);

  const [achievedGoals, setAchievedGoals] = useState<Consultation_consultation_child_goals[]>([]);

  useEffect(() => {
    if (data && data.consultation && data.consultation.notes) {
      setNotes(data.consultation.notes);
      if (data.consultation && data.consultation.child) {
        setAchievedGoals(data.consultation.child.goals.filter((g) => g.achieved));
      }
    }
  }, [data]);

  if (loading) {
    return <Loader active>Loading</Loader>;
  }

  if (error || !data || !data.consultation) {
    return <ErrorPage />;
  }

  const { consultation } = data;

  return (
    <TherapistConsultationScreenContext.Provider
      value={{
        consultation,
        activeItem,
        setActiveItem,
        notes,
        setNotes,
        newGoal,
        setNewGoal,
        newHomework,
        setNewHomework,
        category,
        setCategory,
        subCategory,
        setSubCategory,
        skill,
        setSkill,
        selectedPreskills,
        setSelectedPreskills,
        selectedActivities,
        setSelectedActivities,
        returnMessage,
        setReturnMessage,
        pollingCount,
        setPollingCount,
      }}
    >
      <AchievedGoalModal open={showAchievedGoals} setOpen={setShowAchievedGoals} goals={achievedGoals} />
      <div>
        <Header />
        {returnMessage && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: '50%',
              transform: 'translate(-50%, 0%)',
              zIndex: 1,
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '20px',
              fontSize: '.85em',
            }}
          >
            {returnMessage.status === 'SUCCESS' ? (
              <p style={{ color: '#5DD9C4' }}>
                &#10004; <span data-cy="ReturnMessage">{returnMessage.message}</span>
              </p>
            ) : (
              <p style={{ color: 'red' }}>
                &#10007; <span data-cy="ReturnMessage">{returnMessage.message}</span>
              </p>
            )}
          </div>
        )}
        <Container>
          <ConsultationScreenGrid>
            <div>
              <TherapistConsultationScreenInfoComponent />
              <GeneralSegment
                style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', cursor: 'pointer' }}
                onClick={() => setShowAchievedGoals(true)}
              >
                {t('therapist.consultationScreen.progress.seeAchievedGoal')}
              </GeneralSegment>
              <TherapistConsultationScreenActivitiesComponent />
              <TherapistConsultationScreenGoalsComponent />
            </div>
            <div>
              {activeItem === 'Notes' ? <TherapistConsultationScreenNotesComponent /> : null}
              {activeItem === 'LongerTermGoals' ? <TherapistConsultationScreenLongerTermGoalsComponent /> : null}
              {activeItem === 'Progress' ? <TherapistConsultationScreenProgressComponent /> : null}
              {activeItem === 'Activities' ? <TherapistConsultationActivitiesSelectionComponent /> : null}
              {activeItem === 'Plan' ? <TherapistConsultationScreenTasksComponent /> : null}
              {activeItem === 'CommentForParent' ? <TherapistConsultationScreenCommentForParentComponent /> : null}
            </div>
          </ConsultationScreenGrid>
        </Container>
      </div>
    </TherapistConsultationScreenContext.Provider>
  );
};

export default TherapistConsultationScreenComponent;
