import React, { useState, useMemo, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ActivityModal from './ActivityModal';
import {
  AllActivityGrid,
  SummarySegment,
  SummaryMainSkill,
  SummaryName,
  SummaryContainer,
  SummaryCheckInCount,
  SummaryImage,
  SummaryMainSkillContainer,
} from './ActivityModal/styles';
import { Client_client_children_assignedActivities } from '../../../../graphql/queries/types/Client';
import SectionActionTitle from '../../../common/SectionActionTitle';
import { CurrentWeekday } from '../../../../util/Date';

interface Props {
  activities: Array<Client_client_children_assignedActivities>;
  childId: string;
  handleActivityCheckin: (activityIDs: Array<string>) => void;
  handleViewDailyPlan: () => void;
}

const AllActivities = ({ activities, handleActivityCheckin, handleViewDailyPlan }: Props) => {
  const { t } = useTranslation();
  const tPath = 'client.task.activities';
  const [open, setOpen] = useState<boolean>(false);
  const [acIndex, setAcIndex] = useState<number>(-1);
  const handleClick = (index: number) => {
    setOpen(true);
    setAcIndex(index);
  };

  const [lsActivityId, setActivityId] = useState(localStorage.getItem('activityId') || '');
  useEffect(() => {
    if (lsActivityId !== '') {
      const activityIndex = activities.findIndex((ac) => ac.id === lsActivityId);
      setAcIndex(activityIndex);
      setOpen(true);
    }
    return () => {
      localStorage.removeItem('activityId');
    };
  }, [activities]);

  const currentWeekday = CurrentWeekday();
  const showActivities = useMemo(() => {
    return activities.length > 0 ? (
      <AllActivityGrid>
        {activities.map((ac: Client_client_children_assignedActivities, index: number) => {
          const total = ac.checkInCountDaily.slice(0, currentWeekday).reduce((a, b) => a + b, 0);
          return (
            <SummarySegment key={ac.id} onClick={() => handleClick(index)}>
              <div style={{ display: 'flex' }}>
                <SummaryImage src={ac.mediaUrl} />
                <SummaryContainer>
                  <SummaryMainSkillContainer>
                    <SummaryMainSkill>{ac.mainSkill.name}</SummaryMainSkill>
                  </SummaryMainSkillContainer>
                  <SummaryName data-cy="activitieName">{ac.name}</SummaryName>
                  <SummaryCheckInCount>
                    {t(`${tPath}.hadCompleted`)}
                    <span style={{ color: '#5ABAAE', margin: '0 2px' }}> {total} </span>
                    {t(`${tPath}.completedTimes`)}
                  </SummaryCheckInCount>
                </SummaryContainer>
              </div>
              <Icon name="chevron right" size="large" link style={{ alignSelf: 'center' }} />
            </SummarySegment>
          );
        })}
      </AllActivityGrid>
    ) : (
      <p>{t(`${tPath}.noAssignedActivities`)}</p>
    );
  }, [activities]);

  return (
    <>
      {acIndex >= 0 && (
        <ActivityModal
          open={open}
          setOpen={setOpen}
          activity={{ ...activities[acIndex] }}
          handleActivityCheckin={handleActivityCheckin}
          currentWeekday={currentWeekday}
        />
      )}
      <SectionActionTitle
        title={t('client.task.activityTab.allActivity')}
        actionText={t('client.task.activityTab.backDailyButton')}
        shorten={false}
        handleClick={handleViewDailyPlan}
      />
      {showActivities}
    </>
  );
};

export default React.memo(AllActivities);
