import React, { useContext, useEffect } from 'react';
import { FieldTimeOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ConsultationsDateObj } from '../TherapistChildContext';
import { useMutation } from 'react-apollo';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  CancelConsultation,
  CancelConsultationVariables,
} from '../../../../graphql/mutations/types/CancelConsultation';
import { CANCEL_CONSULTATION_MUTATION } from '../../../../graphql/mutations/cancelConsultation';
import { THERAPIST_CONSULTATIONS_QUERY } from '../../../../graphql/queries/therapist';
import { TherapistContext } from '../../TherapistContextt';
import { Popconfirm, Tooltip } from 'antd';
import { isPast } from '../../../../util/DateAndTime';
import { ButtonWrapper, CancelButton } from './styles';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  consultation: ConsultationsDateObj;
  showTitle: boolean;
}

const RescheduleConsultationButton = ({ consultation, showTitle }: Props) => {
  const { t } = useTranslation();
  const consultationId = consultation?.id;
  const {
    therapist: { id: therapistId },
    setReturnMessage,
  } = useContext(TherapistContext);

  const [cancelConsultation, cancelInfo] = useMutation<CancelConsultation, CancelConsultationVariables>(
    CANCEL_CONSULTATION_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: CONSULTATION_QUERY, variables: { consultationId } },
        { query: THERAPIST_CONSULTATIONS_QUERY, variables: { therapistId } },
      ],
    },
  );
  const { loading: cancelLoading, error: cancelError, data: cancelData } = cancelInfo;

  useEffect(() => {
    if (cancelData && cancelData.cancelConsultation) {
      setReturnMessage(cancelData.cancelConsultation.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [cancelData, setReturnMessage]); // <-- only update when data is changed

  const disable = (consultation: ConsultationsDateObj) => {
    const past = isPast(consultation.consultationStartMilliseconds);
    const canceled = consultation.canceled;
    return past || canceled;
  };

  if (cancelError) {
    return <ErrorPage />;
  }
  return (
    <div onClick={(e) => e.stopPropagation()} style={{ width: 'fit-content' }}>
      <ButtonWrapper disable={disable(consultation)}>
        <Popconfirm
          title={<div style={{ maxWidth: '300px' }}>{t('therapist.childInfo.general.button.caution.content')}</div>}
          onConfirm={() => cancelConsultation({ variables: { consultationId: consultation.id } })}
          okText={t('therapist.childInfo.general.button.caution.yes')}
          okType="danger"
          cancelText={t('therapist.childInfo.general.button.caution.no')}
          placement="bottomRight"
          disabled={disable(consultation)}
        >
          <div style={{ width: 'fit-content' }}>
            <Tooltip title={t('therapist.childInfo.general.button.tooltip.reschedule')} placement="bottomRight">
              <CancelButton
                status={consultation.canceled}
                disable={disable(consultation)}
                type="default"
                shape="round"
                icon={
                  cancelLoading ? (
                    <LoadingOutlined translate={undefined} />
                  ) : (
                    <FieldTimeOutlined translate={undefined} />
                  )
                }
              >
                {showTitle && <>{t('therapist.childInfo.general.button.reschedule')}</>}
              </CancelButton>
            </Tooltip>
          </div>
        </Popconfirm>
      </ButtonWrapper>
    </div>
  );
};

export default RescheduleConsultationButton;
