import React from 'react';
import Star from '../../../../../assets/star.svg';
import { WordContainer, Icon, Text } from './styles';

type Props = {
  word: string;
  editable: boolean;
};

const WordText = ({ word, editable }: Props) => {
  return (
    <WordContainer>
      {!editable && <Icon src={Star} />}
      <Text>{word}</Text>
    </WordContainer>
  );
};

export default WordText;
