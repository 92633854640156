import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const DELETE_WORDS_MUTATION = gql`
  mutation DeleteWords($wordIds: [ID!]!) {
    deleteWords(wordIds: $wordIds) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface DeleteWordsData {
  returnMessage: ReturnMessage;
}

export interface DeleteWordsVariables {
  wordIds: number[];
}
