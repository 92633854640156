import { ApolloProvider } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import client from './graphql/client';
import Root from './Root';
import mixpanel from 'mixpanel-browser';
import './App.less';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App: React.FC = () => {
  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
      debug: true,
    });
  }, []);

  const [deferredPrompt, setdeferredPrompt] = useState<any>();
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    setdeferredPrompt(event);
  });

  return (
    <GoogleOAuthProvider clientId="439795220548-tj46dda251s0l8gs1chmdam49g6h34on.apps.googleusercontent.com">
      <ApolloProvider client={client}>
        <Root deferredPrompt={deferredPrompt} />
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
