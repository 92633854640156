import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterWordModalMask,
  FilterWordModalContainer,
  Header,
  ButtonContainer,
  FilterContentFlexContainer,
  FilterImg,
  FilterModalContent,
} from './styles';
import Dropdown from '../../../../Dropdown';
import { FilterTypes, FilterSigns } from '../../../../../../../constants/wordTracking';
import {
  HeaderTitle,
  SaveButton,
  CancelButton,
  HeaderDescription,
  ActionTitle,
  Input,
} from '../../../../../../../style';
import Filter from '../../../../assets/Filter.svg';
type FilterModalProps = {
  filterType: string;
  signFilter: string;
  countFilter: number;
  showModal: boolean;
  handleClose: () => void;
  handleSubmitFilterWordModal: (type: string, sign: string, value: number) => void;
};

const FilterWordModal = (props: FilterModalProps) => {
  const { t } = useTranslation();
  const { filterType, signFilter, countFilter } = props;
  const [filterModalType, setFilterModalType] = useState(filterType);
  const [signFilterModal, setSignFilterModal] = useState(signFilter);
  const [countFilterModal, setCountFilterModal] = useState(countFilter);

  useEffect(() => {
    setFilterModalType(props.filterType);
    setSignFilterModal(props.signFilter);
    setCountFilterModal(props.countFilter);
  }, [props.filterType, props.signFilter, props.countFilter]);

  const signOption = FilterSigns.map((value: string, index: number) => ({
    key: index,
    value,
    text: t('client.wordTracking.toolBox.filter.sign.' + value),
  }));

  const typeOption = FilterTypes.map((value: string, index: number) => ({
    key: index,
    value,
    text: t('client.wordTracking.toolBox.filter.type.' + value),
  }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setCountFilterModal(+e.currentTarget.value);

  const onCloseClick = () => {
    setFilterModalType(filterType);
    setSignFilterModal(signFilter);
    setCountFilterModal(countFilter);
    props.handleClose();
  };

  const onSubmitClick = () => {
    props.handleSubmitFilterWordModal(filterModalType, signFilterModal, countFilterModal);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitClick();
    }
  };

  return (
    <FilterWordModalMask open={props.showModal}>
      <FilterWordModalContainer>
        <Header>
          <FilterImg src={Filter}></FilterImg>
          <HeaderTitle>{t('client.wordTracking.modal.filter.title')}</HeaderTitle>
          <HeaderDescription>{t('client.wordTracking.modal.filter.des')}</HeaderDescription>
        </Header>
        <FilterModalContent>
          <Dropdown
            size="large"
            placeHolder="Type"
            selectedText={t('client.wordTracking.toolBox.filter.type.' + filterModalType)}
            options={typeOption}
            handleOptionChange={setFilterModalType}
          />
          <FilterContentFlexContainer>
            <Dropdown
              size="large"
              selectedText={signFilterModal}
              options={signOption}
              handleOptionChange={setSignFilterModal}
            />
            <Input type="number" value={`${countFilterModal}`} onChange={handleInputChange} onKeyDown={onKeyDown} />
          </FilterContentFlexContainer>
        </FilterModalContent>
        <ButtonContainer>
          <CancelButton onClick={onCloseClick}>
            <ActionTitle>{t('client.wordTracking.modal.filter.close')}</ActionTitle>
          </CancelButton>
          <SaveButton onClick={onSubmitClick}>
            <ActionTitle>{t('client.wordTracking.modal.filter.submit')}</ActionTitle>
          </SaveButton>
        </ButtonContainer>
      </FilterWordModalContainer>
    </FilterWordModalMask>
  );
};

export default FilterWordModal;
