import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { ClientContext } from '../../../../ClientContext';
import { ACTIVITY_CHECKIN_MUTATION } from '../../../../../../graphql/mutations/activityCheckin';
import {
  AddCheckinToActivities,
  AddCheckinToActivitiesVariables,
} from '../../../../../../graphql/mutations/types/AddCheckinToActivities';
import { useTranslation } from 'react-i18next';
import { CheckInButton } from '../styles';
import { ActionTitle } from '../../../../../../style';
import { AddIcon } from '../../../../WordTrackingRoute/ToolBox/AddButton/styles';

interface Props {
  activityId: string;
  handleActivityCheckin: (activityIDs: Array<string>) => void;
}

const CheckinButton = ({ activityId, handleActivityCheckin }: Props) => {
  const { t } = useTranslation();
  const { child, setReturnMessage } = useContext(ClientContext);
  const childId = child.id;
  const [addCheckinToActivities, { loading, error, data }] = useMutation<
    AddCheckinToActivities,
    AddCheckinToActivitiesVariables
  >(ACTIVITY_CHECKIN_MUTATION);

  // update message near header
  useEffect(() => {
    if (data && data.addCheckinToActivities) {
      setReturnMessage(data.addCheckinToActivities.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const handleCheckIn = async () => {
    await addCheckinToActivities({
      variables: {
        childId,
        activityIds: [activityId],
      },
    });
    handleActivityCheckin([activityId]);
  };

  return (
    <CheckInButton onClick={handleCheckIn}>
      <AddIcon className="plus" />
      <ActionTitle>{t('client.task.activities.overall.check')}</ActionTitle>
    </CheckInButton>
  );
};

export default React.memo(CheckinButton);
