import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Col, Collapse, Progress, Row, Statistic, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { CollapseContentBox } from '../../route/TasksRoute/ActivityModal/styles';
import { PlanGoalObj } from '../../TherapistChildContext';
import { changedGoalPercentage } from '../../route/PlanDetailsRoute/PlanGoalTable';
import { goalPercentage } from '../../utils';

interface Props {
  goalData: PlanGoalObj;
}

const CollapseContent = ({ goalData }: Props) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);
  const { Title, Text, Link } = Typography;
  const { Panel } = Collapse;

  const progressScale = goalData.goal.successNumerator - goalData.goal.initNumerator;

  return (
    <CollapseContentBox
      bordered={false}
      defaultActiveKey={['1', '2']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} translate={undefined} />}
      className="site-collapse-custom-collapse"
      expandIconPosition="end"
    >
      <Panel
        header={
          <Title level={5} style={{ marginBottom: '0' }}>
            {t('therapist.childInfo.goal.goalModal.progress')}
          </Title>
        }
        key="1"
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={t('therapist.childInfo.goal.general.developmentProgress')}
                value={`${goalPercentage(
                  goalData.currentNumerator,
                  goalData.goal.initNumerator,
                  goalData.goal.successNumerator,
                )}%`}
                suffix={
                  <div>
                    {goalData.changedInNumerator > 0 && (
                      <div style={{ margin: '0 0 2px 5px' }}>
                        <Badge
                          count={`↑${changedGoalPercentage(goalData)}%`}
                          style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                      </div>
                    )}
                    {goalData.changedInNumerator < 0 && (
                      <div style={{ margin: '0 0 2px 5px' }}>
                        <Badge count={`↓${-changedGoalPercentage(goalData)}%`}></Badge>
                      </div>
                    )}
                  </div>
                }
              />
            </Col>
            <Col span={12}>
              <Statistic
                title={t('therapist.dashboard.myClient.clientInfo.notes.progress')}
                value={goalData.currentNumerator - goalData.goal.initNumerator}
                suffix={
                  <div style={{ display: 'flex' }}>
                    / {goalData.goal.successNumerator - goalData.goal.initNumerator}
                    {` ${t(`therapist.consultationScreen.goalTable.metrics.${goalData.goal.metric}`)}`}
                    {goalData.changedInNumerator > 0 && (
                      <div style={{ margin: '0 0 2px 5px' }}>
                        <Badge count={'↑' + goalData.changedInNumerator} style={{ backgroundColor: '#52c41a' }}></Badge>
                      </div>
                    )}
                    {goalData.changedInNumerator < 0 && (
                      <div style={{ margin: '0 0 2px 5px' }}>
                        <Badge count={'↓' + -goalData.changedInNumerator}></Badge>
                      </div>
                    )}
                  </div>
                }
              />
            </Col>
          </Row>
          <div>
            <div className="ant-statistic-title">{t('therapist.childInfo.goal.goalModal.progressBar')}</div>
            <div>
              <Progress
                percent={goalPercentage(
                  goalData.currentNumerator,
                  goalData.goal.initNumerator,
                  goalData.goal.successNumerator,
                )}
                success={{ percent: changedGoalPercentage(goalData) }}
                showInfo={false}
                size="small"
                status="active"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: ' space-between',
                }}
              >
                <Text type="secondary">{goalData.goal.initNumerator}</Text>
                <Text type="secondary">{goalData.goal.successNumerator}</Text>
              </div>
              {goalData.changedInNumerator > 0 ? (
                <div
                  style={{
                    width: `calc(100% /${progressScale}*${goalData.changedInNumerator})`,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-25px',
                  }}
                >
                  <div style={{ margin: '0 0 2px 5px' }}>
                    <Badge count={'↑' + goalData.changedInNumerator} style={{ backgroundColor: '#52c41a' }}></Badge>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: `calc(100% /${progressScale}*${
                      goalData.goal.currentNumerator - goalData.goal.initNumerator
                    })`,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-22px',
                  }}
                >
                  <Text type="secondary">{goalData.goal.currentNumerator}</Text>
                </div>
              )}
            </div>
            {!showMore && (
              <Link onClick={() => setShowMore(!showMore)}>{t('therapist.childInfo.goal.goalModal.moreDetails')}</Link>
            )}
          </div>
          <div>
            {showMore && (
              <div onClick={() => setShowMore(!showMore)}>
                <Row gutter={16}>
                  <Col>
                    <div>
                      <Text type="secondary">{t('therapist.childInfo.goal.general.initProgress')}:</Text>
                    </div>
                    <div>
                      <Text type="secondary">{t('therapist.dashboard.myClient.clientInfo.notes.progress')}:</Text>
                    </div>
                    <div>
                      <Text type="secondary">{t('therapist.childInfo.goal.general.objectiveProgress')}:</Text>
                    </div>
                    <div>
                      <Text type="secondary">{t('therapist.childInfo.goal.general.highestProgress')}:</Text>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <Text type="secondary">{goalData.goal.initNumerator}</Text>
                    </div>
                    <div>
                      <Text type="secondary">{goalData.currentNumerator}</Text>
                    </div>
                    <div>
                      <Text type="secondary">{goalData.goal.successNumerator}</Text>
                    </div>
                    <div>
                      <Text type="secondary">{goalData.goal.initDenominator}</Text>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </Panel>
    </CollapseContentBox>
  );
};

export default CollapseContent;
