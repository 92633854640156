import AWS, { S3 } from 'aws-sdk';

AWS.config.region = process.env.REACT_APP_AWS_UPLOADER_S3_REGION;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID ?? '',
});

const Uploader = (file: File, folder: string, name: string) => {
  const s3 = new S3({
    region: process.env.REACT_APP_AWS_UPLOADER_S3_REGION,
  });

  const params = {
    Bucket: process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET as string,
    Key: `${folder}/${name}`,
    Body: file,
    ACL: 'private',
    ContentType: file.type,
  };

  return s3.upload(params);
};

export default Uploader;
