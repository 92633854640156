import styled from 'styled-components';
import { UserComment } from '../../../../../../../../style';
import { DataItemContainer } from '../../../../styles';

export const WordContainer = styled(DataItemContainer)``;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const Text = styled(UserComment)``;
