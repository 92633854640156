import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-apollo';
import {
  ChildMessages as Data,
  ChildMessagesVariables as Variables,
  ChildMessages_child_room_directMessages,
} from '../../../../../graphql/queries/types/ChildMessages';
import { CHILD_MESSAGES } from '../../../../../graphql/queries/childMessage';
import MessagesComponent from './Component';
import MessagesContext from './MessagesContext';
import { TherapistChildContext } from '../../TherapistChildContext';
import { LoadingContainer } from '../../layout/styles';
import { Spin } from 'antd';

const MessagesRoute = () => {
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);
  const { loading, error, data } = useQuery<Data, Variables>(CHILD_MESSAGES, { variables: { childId } });
  const [roomData, setRoomData] = useState<Array<ChildMessages_child_room_directMessages>>([]);
  useEffect(() => {
    if (data) {
      const { directMessages } = data.child.room;
      const copyRoomData = [...directMessages];
      // setRoomData(copyRoomData.reverse());
      setRoomData(copyRoomData);
    }
  }, [data]);

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  if (error || !data) {
    return <p>Error...</p>;
  }

  const {
    child: {
      room: { id: roomId, directMessages },
    },
  } = data;

  return (
    <MessagesContext.Provider value={{ roomId, childId }}>
      <MessagesComponent messages={roomData} roomId={roomId} />
    </MessagesContext.Provider>
  );
};

export default React.memo(MessagesRoute);
