import React, { useState } from 'react';
import UploadMessageComponent from './UploadMessageComponent';
import { Divider, Radio } from 'semantic-ui-react';
import ShowMessageButtonComponent from './ShowMessageButtonComponent';
import { Client_client_children_therapist } from '../../../../graphql/queries/types/Client/Consultation';
import { useTranslation } from 'react-i18next';
import { Description } from '../../../../style';

interface Props {
  roomId: string;
  therapist: Client_client_children_therapist;
}

const TherapistInteractionDashboardComponent = ({ therapist, roomId }: Props) => {
  const [messageShow, setMessageShow] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Description style={{ verticalAlign: 'middle' }}>
          {`${t('client.communicationCard.therapistInteraction.message')} ${therapist.user.name}`}
        </Description>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px' }}>
          <Description style={{ margin: '0px 10px' }}>{t('client.communicationCard.viewYourMessages')}</Description>
          <Radio toggle onChange={() => setMessageShow(!messageShow)} />
        </div>
      </div>
      <div style={{ verticalAlign: 'middle' }}>
        <Description>{t('client.communicationCard.therapistInteraction.reminder1')}</Description>
        <Description>{t('client.communicationCard.therapistInteraction.reminder2')}</Description>
      </div>
      <Divider fitted />
      {messageShow ? (
        <ShowMessageButtonComponent roomId={roomId} messageType="DirectMessage" />
      ) : (
        <UploadMessageComponent roomId={roomId} />
      )}
    </>
  );
};

export default TherapistInteractionDashboardComponent;
