import React, { PureComponent } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { CheckIn } from '../../../../../graphql/queries/types/ChildProgress';
import { TooltipContainer } from '../styles';

type Props = {
  weeklyCheckIns: CheckIn[];
} & WithTranslation;

type weeklyActivityData = {
  period: string;
  accAssignedAcCount: number;
  accTrainedAcCount: number;
  newlyAssignedAcCount: number;
  newlyTrainedAcCount: number;
  startDate: string;
  endDate: string;
}[];

interface CustomTooltipProps {
  active: any;
  payload: any;
  label: any;
  data: weeklyActivityData;
}

const getPeriodDate = (label, data: weeklyActivityData) => {
  return data.map((periodData) => {
    if (label === periodData.period) {
      return <div>{`${periodData.startDate} - ${periodData.endDate}`}</div>;
    }
    return <div />;
  });
};

const getPeriodChanges = (label, data: weeklyActivityData, t) => {
  return data.map((periodData) => {
    if (label === periodData.period) {
      return (
        <div>
          {periodData.newlyAssignedAcCount > 0 && (
            <div>{`${t('client.dashboard.activity.chart.newlyAssignedActivityCount')}： ${
              periodData.newlyAssignedAcCount
            }`}</div>
          )}
          {periodData.newlyTrainedAcCount > 0 && (
            <div>{`${t('client.dashboard.activity.chart.newlyTrainedActivityCount')}： ${
              periodData.newlyTrainedAcCount
            }`}</div>
          )}
        </div>
      );
    }
    return <div />;
  });
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, data }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        {label}
        {getPeriodDate(label, data)}
        <div style={{ color: '#8884d8' }}>
          {`${t('client.dashboard.activity.chart.assignedActivityCount')}： ${payload[0].value}`}
        </div>
        <div style={{ color: '#ff7300' }}>
          {`${t('client.dashboard.activity.chart.trainedActivityCount')}： ${payload[1].value}`}
        </div>
        {getPeriodChanges(label, data, t)}
      </TooltipContainer>
    );
  }
  return null;
};

class ActivityCountChart extends PureComponent<Props> {
  data: weeklyActivityData;
  constructor(props: Props) {
    super(props);
    this.state = {
      focusBar: null,
      mouseLeave: true,
    };
    this.data = (() => {
      const { t, weeklyCheckIns } = this.props;
      const assignedAcCount = weeklyCheckIns.map((data) => {
        return data.newlyAssignedActivityCount;
      });
      const trainedAcCount = weeklyCheckIns.map((data) => {
        return data.newlyTrainedActivityCount;
      });
      const accAssignedAcCount = [] as number[];
      assignedAcCount.forEach((b: number, index: number) => {
        const perWeek = index === 0 ? assignedAcCount[0] : accAssignedAcCount[accAssignedAcCount.length - 1] + b;
        accAssignedAcCount.push(perWeek);
      });
      const accTrainedAcCount = [] as number[];
      trainedAcCount.forEach((b: number, index: number) => {
        const perWeek = index === 0 ? trainedAcCount[0] : accTrainedAcCount[accTrainedAcCount.length - 1] + b;
        accTrainedAcCount.push(perWeek);
      });
      return weeklyCheckIns.map((planData, index) => {
        const planCount = index + 1;
        const startDateData = new Date(planData.startDate.split(' ')[0]);
        const startDateMonth = t(`utils.month.${startDateData.getMonth()}`);
        const endDateData = new Date(planData.endDate.split(' ')[0]);
        const endDateMonth = t(`utils.month.${endDateData.getMonth()}`);
        return {
          period:
            t('client.dashboard.activity.chart.xAxisWeek1') +
            ' ' +
            planCount +
            ' ' +
            t('client.dashboard.activity.chart.xAxisWeek2'),
          accAssignedAcCount: accAssignedAcCount[index],
          accTrainedAcCount: accTrainedAcCount[index],
          newlyAssignedAcCount: assignedAcCount[index],
          newlyTrainedAcCount: trainedAcCount[index],
          startDate: startDateMonth + ' ' + startDateData.getDate(),
          endDate: endDateMonth + ' ' + endDateData.getDate(),
        };
      });
    })();
  }
  render() {
    const { t } = this.props;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={this.data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="period" scale="band" />
          <YAxis label={{ value: t('client.dashboard.activity.chart.index'), angle: -90, position: 'insideLeft' }} />
          <Tooltip
            content={<CustomTooltip active={undefined} payload={undefined} label={undefined} data={this.data} />}
          />
          <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={{ bottom: 0, right: 20 }} />
          <Line
            name={t('client.dashboard.activity.chart.assignedActivity')}
            type="stepAfter"
            dataKey="accAssignedAcCount"
            stroke="#8884d8"
          />
          <Line
            name={t('client.dashboard.activity.chart.trainedActivity')}
            type="monotone"
            dataKey="accTrainedAcCount"
            stroke="#ff7300"
            activeDot={{ r: 8 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default withTranslation()(ActivityCountChart);
