import React, { useMemo, useState } from 'react';
import CategoryCard from './CategoryCard';
import { CategoryCardContainer, CategoryCardScrollContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { EmtpyStateContent, ContentTitle } from '../../../../style';
import { DashboardEmptyStateContainer, DashboardEmptyStateSegment } from '../styles';
import { SkillCategory } from '../../../../util/Category';
import CategoryModal from './CategoryModal';
import GoalModal from '../Goal/GoalModal';
import { Client_client_children } from '../../../../graphql/queries/types/Client';

interface Props {
  child: Client_client_children;
  productType: string;
  category: SkillCategory[];
}

const Category = ({ child, productType, category }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<string>(child?.goals[0]?.id);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [showGoalModal, setShowGoalModal] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<string>('one');

  const handleClick = (index: number) => {
    setShow(true);
    setCategoryIndex(index);
  };

  const handleClose = useMemo(
    () => () => {
      setShow(false);
    },
    [],
  );

  const handleGoalModalOpen = useMemo(
    () => (id: string, step: string) => {
      setShowGoalModal(true);
      setGoalId(id);
      setModalStep(step);
    },
    [],
  );

  const handleGoalModalClose = useMemo(
    () => () => {
      setShowGoalModal(false);
    },
    [],
  );

  const showCategoryComponent = category.map((c, index) => {
    return (
      <div onClick={() => handleClick(index)}>
        <CategoryCard category={c} productType={productType} />
      </div>
    );
  });

  const emptyStatus = category[0].name === '';

  return (
    <>
      <CategoryModal
        show={show}
        showGoalModal={showGoalModal}
        category={category[categoryIndex]}
        goals={child.goals}
        goalIndex={category[categoryIndex].id}
        productType={productType}
        handleClose={handleClose}
        handleGoalModalOpen={handleGoalModalOpen}
      />
      <GoalModal
        show={showGoalModal}
        goals={child.goals}
        goalId={goalId}
        productType={productType}
        handleClose={handleGoalModalClose}
        modalStep={modalStep}
      />
      <div style={{ marginBottom: '80px' }}>
        {!emptyStatus && (
          <CategoryCardContainer>
            <CategoryCardScrollContainer>{showCategoryComponent}</CategoryCardScrollContainer>
          </CategoryCardContainer>
        )}
        {emptyStatus && (
          <DashboardEmptyStateSegment>
            <DashboardEmptyStateContainer>
              <ContentTitle>{t('client.dashboard.skill.emptyStateTitle')}</ContentTitle>
              <EmtpyStateContent>{t('client.dashboard.skill.emptyStateContent')}</EmtpyStateContent>
            </DashboardEmptyStateContainer>
          </DashboardEmptyStateSegment>
        )}
      </div>
    </>
  );
};

export default Category;
