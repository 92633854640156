import React from 'react';
import { RowContainer, DataContainer, StickyColumnContainer } from '../../styles';
import CountWord from './RowFunction/CountWord';
import Checkbox from './RowFunction/Checkbox';
import MoreOptionsButton from './RowFunction/MoreOptionsButton';
import CompleteButton from './RowFunction/CompleteButton';
import { Word } from '../../../../../../graphql/queries/types/WordTracking';
import WordText from './RowFunction/Word';
import Usage from './RowFunction/Usage';

type Props = {
  index: number;
  word: Word;
  isChecked: boolean;
  hasSelectedItems: boolean;
  handleEditRepeatCount: (wordId: number, value: number) => void;
  handleEditSpeakCount: (wordId: number, value: number) => void;
  handleCompleteClick: (index: number) => void;
  handleCheckboxClick: (index: number, status: boolean) => void;
  handleCountWord: (indices: number, type: string) => void;
  handleEditWord: (indices: number) => void;
  handleDeleteWord: (index: number) => void;
};

const TableRow = (props: Props) => {
  const { id, word, editable, repeatCount, speakCount, totalCount } = props.word;
  const showAction = !props.hasSelectedItems;
  return (
    <RowContainer isChecked={props.isChecked}>
      <StickyColumnContainer isChecked={props.isChecked}>
        <DataContainer center={true}>
          <Checkbox index={props.index} isChecked={props.isChecked} handleClick={props.handleCheckboxClick} />
          {/* {showAction && <CompleteButton id={id} handleCompleteClick={props.handleCompleteClick} />} */}
        </DataContainer>
        <DataContainer center={false}>
          <WordText word={word} editable={editable} />
        </DataContainer>
      </StickyColumnContainer>
      <DataContainer center={true}>
        <CountWord
          index={id}
          count={repeatCount}
          rowIndex={props.index}
          type={'repeatCount'}
          setCount={props.handleEditRepeatCount}
          handleCountWord={props.handleCountWord}
        />
      </DataContainer>
      <DataContainer center={true}>
        <CountWord
          index={id}
          count={speakCount}
          rowIndex={props.index}
          type={'speakCount'}
          setCount={props.handleEditSpeakCount}
          handleCountWord={props.handleCountWord}
        />
      </DataContainer>
      <DataContainer center={true}>
        <Usage totalCount={totalCount}></Usage>
      </DataContainer>
      <DataContainer center={true}>
        {showAction && (
          <MoreOptionsButton
            editable={editable}
            index={props.index}
            handleEditWord={props.handleEditWord}
            handleDeleteWord={props.handleDeleteWord}
            handleCompleteClick={props.handleCompleteClick}
          />
        )}
      </DataContainer>
    </RowContainer>
  );
};

export default TableRow;
