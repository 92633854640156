import gql from 'graphql-tag';

export const GOAL_QUERY = gql`
  query Child($childId: ID!) {
    child(childId: $childId) {
      id
      goals {
        id
        description
        metric
        initNumerator
        initDenominator
        currentNumerator
        currentDenominator
        successNumerator
        successDenominator
        achieved
        deleted
        skill {
          id
          name
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
        }
        goalPreskills {
          skillId
          name
          mastered
        }
        goalRecords {
          id
          consultation {
            id
            consultationTime
          }
          currentNumerator
          currentDenominator
        }
      }
    }
  }
`;
