import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Client_client_children_assignedActivities } from '../../../../../graphql/queries/types/Client';
import { PenguinSmartDays } from '../../../../../util/Date';
import { DailyPlanCalendarContainer, DateContainer, DateTitle, WeekDayTitle } from './styles';

interface DateCardProps {
  d: Date;
  check: boolean;
  isSelect: boolean;
  editMode: boolean;
  isToday: boolean;
  planBeginDateTime: Date;
  handleSetDate: (current: number, index: number) => void;
  curr: number;
  index: number;
}

const DateCard = React.memo(
  ({ d, check, isSelect, editMode, isToday, planBeginDateTime, handleSetDate, curr, index }: DateCardProps) => {
    const { t } = useTranslation();
    return (
      <DateContainer
        check={check}
        status={isSelect}
        disabled={(editMode && !isToday) || d < planBeginDateTime}
        onClick={() => handleSetDate(-curr, index)}
      >
        <WeekDayTitle style={{ fontSize: '8px' }} status={isSelect}>
          {t(`utils.month.${d.getMonth()}`)}
        </WeekDayTitle>
        <DateTitle>{d.getDate()}</DateTitle>
        <WeekDayTitle status={isSelect}>
          {isToday ? (
            <WeekDayTitle style={{ fontWeight: 'bold' }} status={isSelect}>
              {t('utils.day.today')}
            </WeekDayTitle>
          ) : (
            t(`utils.day.${PenguinSmartDays[index]}`)
          )}
        </WeekDayTitle>
        {check && <Icon name="check circle" size="large" style={{ color: '#5abaae', margin: '0px' }} />}
      </DateContainer>
    );
  },
);

interface Props {
  planActivity: Client_client_children_assignedActivities[][][];
  daysDiffToday: number;
  startDiffDay: number;
  editMode: boolean;
  planBeginDate: Date;
  handleSetDate: (current: number, index: number) => void;
}

const DailyPlanCalendar = ({
  startDiffDay,
  daysDiffToday,
  planActivity,
  editMode,
  planBeginDate,
  handleSetDate,
}: Props) => {
  const planBeginDateTime = useMemo(() => new Date(planBeginDate.setUTCHours(0, 0, 0, 0)), [planBeginDate]);
  const dates: Date[] = useMemo(
    () =>
      Array(7)
        .fill(0)
        .map((_, index) => {
          const d = new Date();
          d.setDate(d.getDate() + (index + startDiffDay));
          return d;
        }),
    [startDiffDay],
  );

  const weekCalendar = () => {
    return dates.map((d, index) => {
      const day = (d.getDay() + 6) % 7;
      let check = false;
      const curr = -startDiffDay - index;
      const isNotFuture = curr >= 0;
      if (planActivity.length > 0 && isNotFuture) {
        check = planActivity[day].some((activitiesInOneTimeSlot) =>
          activitiesInOneTimeSlot.some((ac) => ac.checkInCountDaily[curr] > 0),
        );
      }
      const isSelect = daysDiffToday === -curr;
      const isToday = curr === 0;
      return (
        <DateCard
          d={d}
          check={check}
          isSelect={isSelect}
          editMode={editMode}
          isToday={isToday}
          planBeginDateTime={planBeginDateTime}
          handleSetDate={handleSetDate}
          curr={curr}
          index={index}
        />
      );
    });
  };
  return <DailyPlanCalendarContainer>{weekCalendar()}</DailyPlanCalendarContainer>;
};

export default DailyPlanCalendar;
