import React, { useMemo } from 'react';
import * as qs from 'query-string';
import { VideoRouteContainer } from './styles';
import LibraryVideoPage from './component/LibraryVideoPage';
import CourseVideoPage from './component/CourseVideoPage';
import QnAVideoPage from './component/QnAVideoPage';

const Video = () => {
  const { s, p, k, i, v, l, t, d, u } = qs.parse(window.location.search);
  const [source, platform, sectionIndex, itemIndex, video, language, videoTitle, description, videoUrl] =
    useMemo(() => {
      if (
        s === null ||
        p === null ||
        k === null ||
        i === null ||
        v === null ||
        l === null ||
        t === null ||
        d === null ||
        u === null
      ) {
        return ['', '', 0, 0, '', ''];
      }
      const pageSource = s as string;
      const videoPlatform = p as string;
      const sectionIndex = parseInt(k as string);
      const itemIndex = parseInt(i as string);
      const videoKey = v as string;
      const language = l as string;
      const videoTitle = t as string;
      const description = d as string;
      const videoUrl = u as string;
      return [
        pageSource,
        videoPlatform,
        sectionIndex,
        itemIndex,
        videoKey,
        language,
        videoTitle,
        description,
        videoUrl,
      ];
    }, [s, p, k, i, v, l, t, d, u]);

  return (
    <VideoRouteContainer>
      {source === 'library' && (
        <LibraryVideoPage
          videoTitle={videoTitle ?? ''}
          description={description ?? ''}
          videoUrl={videoUrl ?? ''}
        ></LibraryVideoPage>
      )}
      {source === 'qna' && <QnAVideoPage videoTitle={videoTitle ?? ''} videoUrl={videoUrl ?? ''}></QnAVideoPage>}
      {source === 'course' && (
        <CourseVideoPage
          platform={platform}
          sectionIndex={sectionIndex}
          itemIndex={itemIndex}
          video={video}
        ></CourseVideoPage>
      )}
    </VideoRouteContainer>
  );
};

export default Video;
