import React from 'react';
import { UsageContainer, UsageText } from './styles';

type Props = {
  totalCount: number;
};

const Usage = ({ totalCount }: Props) => {
  return (
    <UsageContainer>
      <UsageText>{totalCount}</UsageText>
    </UsageContainer>
  );
};

export default Usage;
