import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { Col, Row } from 'antd';
interface Props {
  value: number;
  maxValue: number;
  overall: boolean;
}

const ProgressBarComponent = ({ value, maxValue, overall }: Props) => {
  const valueCapped = Math.floor(Math.min(value, maxValue));
  const percentage = Math.floor((valueCapped / maxValue) * 100);
  const barColor = overall ? '#40A0A0' : '#80D9CE';

  return (
    <Row align="middle" justify="space-between">
      <Col span={21} style={{ border: `3px solid ${barColor}`, borderRadius: '50px' }}>
        <ProgressBar
          completed={percentage}
          maxCompleted={100}
          animateOnRender={true}
          bgColor={barColor}
          baseBgColor="whilte"
          labelAlignment="center"
          isLabelVisible={false}
          transitionDuration="2s"
          transitionTimingFunction="ease-in-out"
          labelColor="black"
          labelSize="12px"
          height="15px"
        />
      </Col>
      <Col span={2} style={{ textAlign: 'right' }}>{`${value}/${maxValue}`}</Col>
    </Row>
  );
};

export default ProgressBarComponent;
