import gql from 'graphql-tag';
import { Message } from '../../types';

export const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $body: String!
    $userId: ID!
    $roomId: ID!
    $homeworkId: ID
    $mainMessageId: ID
    $file: Upload
    $fileName: String
  ) {
    createMessage(
      body: $body
      userId: $userId
      roomId: $roomId
      homeworkId: $homeworkId
      mainMessageId: $mainMessageId
      file: $file
      fileName: $fileName
    ) {
      id
      body
      createdAt
      user {
        id
        name
      }
      meta {
        __typename
        ... on DirectMessage {
          id
        }
        ... on HomeworkSubmission {
          id
          homework {
            id
            description
          }
        }
        ... on Reply {
          id
          message {
            id
            body
            user {
              id
              name
            }
            createdAt
          }
          mainMessage {
            id
            body
          }
        }
      }
      replies {
        id
        message {
          id
          body
          user {
            id
            name
          }
          createdAt
        }
      }
      homeworkUrl
      homeworkContentType
    }
  }
`;

export interface Data {
  createMessage: Message;
}

export interface Variables {
  body: string;
  userId: string;
  roomId: number;
  homeworkId: number | null;
  mainMessageId: number | null;
  file: File | null;
  fileName: string | null;
}
