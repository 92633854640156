import styled from 'styled-components';
import { Description } from '../../../style';

export const DivideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.1);
  font-size: 14px;
`;

export const DivideLine = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const OrText = styled(Description)`
  margin: 0 10px;
  letter-spacing: 0.2px;
  min-width: max-content;
`;
