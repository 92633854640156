import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CategoryMetricsDetailsContainer,
  ColorIndicator,
  DetailsContainer,
  DetailsTitleContainer,
  PieChartFlexContainer,
  TotalSkillMetricContainer,
} from './styles';
import { MetricsTitle, MetricsNumber, SmallDescription, SmallSectionTitle } from '../../../../../style';
import SkillPieChart from './SkillPieChart';
import { CardMoreIcon, CardTopFlexContainer } from '../../styles';
import { SkillCategory } from '../../../../../util/Category';
import { CPC_PRODUCT_TYPES } from '../../../../../util/Product';
interface Props {
  category: SkillCategory;
  productType: string;
}
interface renderDetails {
  color: string;
  title: string;
  count: number;
}
const RenderDetails = ({ color, title, count }: renderDetails) => {
  return (
    <DetailsContainer>
      <DetailsTitleContainer>
        <ColorIndicator color={color}></ColorIndicator>
        <SmallDescription>{title}</SmallDescription>
      </DetailsTitleContainer>
      <SmallDescription>{count}</SmallDescription>
    </DetailsContainer>
  );
};

const CategoryCard = ({ category, productType }: Props) => {
  const { t } = useTranslation();
  const totalCount = category.skills.length;

  const [masteredCount, CPCinProgressCount, CPCnotYetInProgressCount, PEAinProgressCount, PEAnotYetInProgressCount] =
    useMemo(() => {
      // CPC skills
      const masteredCount = category.skills.filter((s) => s.mastered).length;
      const CPCinProgressCount = category.skills.filter((s) => s.count > 0 && !s.mastered).length;
      const CPCnotYetInProgressCount = category.skills.filter((s) => s.count === 0 && !s.mastered).length;
      // PEA skills
      const PEAinProgressCount = category.skills.filter((s) => s.count > 0).length;
      const PEAnotYetInProgressCount = category.skills.filter((s) => s.count === 0).length;
      return [
        masteredCount,
        CPCinProgressCount,
        CPCnotYetInProgressCount,
        PEAinProgressCount,
        PEAnotYetInProgressCount,
      ];
    }, [category]);

  // show skills count
  const showInProgressCount = productType === ' cpc' ? CPCinProgressCount : PEAinProgressCount;
  const showNotYetInProgressCount = productType === ' cpc' ? CPCnotYetInProgressCount : PEAnotYetInProgressCount;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  return (
    <Card>
      <CardTopFlexContainer>
        <SmallSectionTitle>{category.name}</SmallSectionTitle>
        <CardMoreIcon className="chevron right" />
      </CardTopFlexContainer>
      <PieChartFlexContainer>
        <SkillPieChart
          masteredSkill={masteredCount}
          inProgressSkill={showInProgressCount}
          notYetInProgressSkill={showNotYetInProgressCount}
          productType={productType}
        />
        <TotalSkillMetricContainer>
          <MetricsTitle>{t('client.dashboard.skill.totalSkill')}</MetricsTitle>
          <MetricsNumber>{totalCount}</MetricsNumber>
        </TotalSkillMetricContainer>
      </PieChartFlexContainer>
      <CategoryMetricsDetailsContainer>
        {isCPC && (
          <RenderDetails color={'#FF7A2F'} title={t('client.dashboard.skill.totalMastered')} count={masteredCount} />
        )}
        <RenderDetails
          color={isCPC ? '#fecea9' : '#FF7A2F'}
          title={t('client.dashboard.skill.practicedSkill')}
          count={showInProgressCount}
        />
        <RenderDetails
          color={'#f5f5f5'}
          title={t('client.dashboard.skill.notYetInProgressSkill')}
          count={showNotYetInProgressCount}
        />
      </CategoryMetricsDetailsContainer>
    </Card>
  );
};
export default CategoryCard;
