import React, { useContext, useState, useRef } from 'react';
import { Image, Segment, TextArea, Button } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { CREATE_MESSAGE } from '../../../../graphql/mutations/createMessage';
import {
  GetRoomMessagesByType_getRoomMessagesByType,
  GetRoomMessagesByType_getRoomMessagesByType_replies,
} from '../../../../graphql/queries/types/GetRoomMessagesByType';
import { CreateMessageVariables, CreateMessage } from '../../../../graphql/mutations/types/CreateMessage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../common/UserContext';
import { ClientContext } from '../../ClientContext';
import { GET_ROOM_MESSAGES_QUERY } from '../../../../graphql/queries/getRoomMessagesByType';
import defaultChild from '../../../../assets/defaultChild.png';
import Linkify from 'react-linkify';
import ErrorPage from '../../../common/ErrorPage';
import { utcToFullDateTime } from '../../../../constants/timezoneConversion';
import UploadMessageComponent from './UploadMessageComponent';

interface Props {
  message: GetRoomMessagesByType_getRoomMessagesByType;
  roomId: string;
  messageType: string;
}
export const renderMessageWithFile = (url: string, contentType: string, body: string) => {
  if (contentType.includes('image')) {
    return (
      <div>
        <Image src={url} size="small" />
        <br />
        <p>{body}</p>
      </div>
    );
  } else if (contentType.includes('video')) {
    return (
      <div>
        <video src={url} width={320} height={240} controls /> <br />
        <p>{body}</p>
      </div>
    );
  } else {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {body}
      </a>
    );
  }
};

export const renderReplyContent = (message) => {
  console.log(message, 'test debug message from therapist side');

  if (message.homeworkUrl && message.homeworkContentType) {
    return renderMessageWithFile(message.homeworkUrl, message.homeworkContentType, message.body);
  }

  if (message.messageFileUrl && message.messageFileContentType) {
    return renderMessageWithFile(message.messageFileUrl, message.messageFileContentType, message.body);
  }

  if (message.replyMessageFileUrl && message.replyMessageFileContentType) {
    return renderMessageWithFile(message.replyMessageFileUrl, message.replyMessageFileContentType, message.body);
  }

  return (
    <Linkify>
      <span>{message.body}</span>
    </Linkify>
  );
};
const ClientMessageChatBox = ({ message, roomId, messageType }: Props) => {
  const { user } = useContext(UserContext);
  const { client } = useContext(ClientContext);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');

  const [createMessage, { loading, error, data }] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    refetchQueries: [{ query: GET_ROOM_MESSAGES_QUERY, variables: { roomId, messageType } }],
  });

  const { t } = useTranslation();

  if (error || !user) {
    return <ErrorPage />;
  }

  const { id: userId, locale } = user;

  const handleClickSend = async () => {
    await createMessage({
      variables: {
        body: replyText,
        userId,
        roomId,
        homeworkId: null,
        mainMessageId: message.id,
        file: null,
        fileName: null,
      },
    });

    setIsReply(false);
    setReplyText('');
  };

  const renderMessageContent = () => {
    if (message.homeworkUrl && message.homeworkContentType) {
      return renderMessageWithFile(message.homeworkUrl, message.homeworkContentType, message.body);
    }

    if (message.messageFileUrl && message.messageFileContentType) {
      return renderMessageWithFile(message.messageFileUrl, message.messageFileContentType, message.body);
    }

    return (
      <Linkify>
        <span>{message.body}</span>
      </Linkify>
    );
  };

  return (
    <div style={{ margin: '10px 0px', padding: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '0px 10px' }}>
            <Image
              circular
              style={{ height: '35px', width: '35px', objectFit: 'cover' }}
              size="mini"
              bordered
              src={message.avatarUrl ? message.avatarUrl : defaultChild}
            />
          </div>
          <div style={{ display: 'flex', margin: '3px', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{message.user.name}</p>
            <p style={{ color: '#B2B2B2', fontSize: '12px' }}>
              {utcToFullDateTime(message.createdAt, client.timeZone, user.locale)}
            </p>
          </div>
        </div>
      </div>
      <div style={{ margin: '5px 50px', fontSize: '12px' }}>
        {renderMessageContent()}
        {message.replies.length > 0 ? (
          <Segment.Group>
            {message.replies.map((reply: GetRoomMessagesByType_getRoomMessagesByType_replies) => (
              <Segment key={reply.id} compact>
                <div style={{ display: 'flex' }}>
                  <Image
                    circular
                    style={{ height: '35px', width: '35px', objectFit: 'cover' }}
                    size="mini"
                    bordered
                    src={reply.message.avatarUrl ? reply.message.avatarUrl : defaultChild}
                  />
                  <p style={{ margin: '8px' }}>{reply.message.user.name}</p>
                </div>
                <div style={{ marginLeft: '45px' }}>
                  {renderReplyContent(reply.message)}
                  <p style={{ color: '#B2B2B2', fontSize: '11px' }}>{reply.message.createdAt}</p>
                </div>
              </Segment>
            ))}
          </Segment.Group>
        ) : null}
        {isReply ? (
          <div>
            <UploadMessageComponent roomId={roomId} mainMessageId={message.id} />
            <Button
              size="tiny"
              style={{ color: 'teal', border: 'none', background: 'none' }}
              onClick={() => setIsReply(false)}
            >
              {t('client.communicationCard.cancel')}
            </Button>
          </div>
        ) : (
          <div>
            <Button size="tiny" color="teal" basic style={{ borderRadius: '22.5px' }} onClick={() => setIsReply(true)}>
              {t('client.communicationCard.reply')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientMessageChatBox;
