import React from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useHistory } from 'react-router';
import {
  UploadfileContainer,
  UploadfileTitle,
  UploadfileInstructionContainer,
  UploadfileInstructionTitle,
  UploadfileInstructionContentContainer,
  UploadfileInstructionContentText,
  UploadfileInstructionContentImg,
} from '../styles';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';

interface Props {}

const AddShortcut = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const tPath = 'utils.addShortcut';
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/profile')} />
      <UploadfileContainer>
        <UploadfileTitle>{t(`${tPath}.title`)}</UploadfileTitle>
        <UploadfileInstructionContainer>
          <Linkify>
            <UploadfileInstructionTitle>{t(`${tPath}.instruction1`)}</UploadfileInstructionTitle>
          </Linkify>
          <UploadfileInstructionTitle>{t(`${tPath}.instruction2`)}</UploadfileInstructionTitle>
          <UploadfileInstructionTitle>{t(`${tPath}.instruction3`)}</UploadfileInstructionTitle>
          <UploadfileInstructionTitle>{t(`${tPath}.instruction4`)}</UploadfileInstructionTitle>
          <UploadfileInstructionContentContainer>
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.img1`)}`).default} />
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.img2`)}`).default} />
          </UploadfileInstructionContentContainer>
        </UploadfileInstructionContainer>
      </UploadfileContainer>
    </RouteSectionContainer>
  );
};

export default AddShortcut;
