import gql from 'graphql-tag';
import { ConsultationNote } from '../../types';

export const LAST_NOTES_QUERY = gql`
  query getLastNote($childId: ID!) {
    getLastNote(childId: $childId) {
      id
      mainComplaint
      internalNotes
      commentForParents
    }
  }
`;

export interface Data {
  getLastNote: ConsultationNote;
}

export interface Variables {
  childId: number;
}
