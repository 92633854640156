import gql from 'graphql-tag';

export const CHILD_CONSULTATION_NOTES = gql`
  query ChildConsultationnotes($childId: ID!) {
    child(childId: $childId) {
      id
      name
      gender
      dob
      pictureUrl
      childSixMonthGoal {
        id
        description
      }
      consultations {
        id
        consultationTime
        accepted
        canceled
        published
        notes {
          id
          mainComplaint
          internalNotes
          commentForParents
        }
        uploadedVideos {
          id
          consultation {
            id
          }
          uploadedVideoName
          isDownloaded
        }
      }
      client {
        id
        user {
          id
          uuid
          name
          locale
          country
        }
        consultationTimesRemain
        membershipDue
      }
      product {
        id
        name
        description
      }
      nextConsultation {
        id
        consultationTime
      }
      previousConsultation {
        id
        consultationTime
      }
    }
  }
`;
