import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ButtonStyle } from '../../../../../style';
import { MainColor } from '../../styles';

export const AddWordButton = styled(ButtonStyle)`
  height: 40px;
  margin: 0 0 10px 0;
  padding: 0 20px;
  background: ${MainColor};
  color: white;
`;

export const AddIcon = styled(Icon)`
  align-self: center;
  margin-bottom: 4px !important;
`;
