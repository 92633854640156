import React, { useContext, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

// Client Query

import { ClientContext, ClientContextProvider } from './ClientContext';
import ModalNotification from './ModalNotification';
import ReturnMessageComponent from '../common/ReturnMessageComponent';

// Routes
import Account from '../common/Account';
import Guidelines from './ProfileRoute/Notification/components/Guidelines';
import Overview from './ProfileRoute/Notification/components/Overview';
import LiteOverview from './ProfileRoute/Notification/components/LiteOverview';
import UploadFile from './ProfileRoute/Notification/components/UploadFile';
import AddShortcut from './ProfileRoute/Notification/components/AddShortcut';
import DashboardRoute from './DashboardRoute';
import ProgramRoute from './ProgramRoute';
import HomeRoute from './HomeRoute';
import CoursesRoute from './CoursesRoute';
import QnaSessionsRoute from './QnaSessionsRoute';
import TaskRoute from './TaskRoute';
import WordTrackingRoute from './WordTrackingRoute';
import VideoRoute from './VideoRoute';
import ConsultationRoute from './ConsultationRoute';
import LibraryRoute from './LibraryRoute';
import ProfileRoute from './ProfileRoute';
import { Container } from './styles';
import Navbar from '../common/Navbar';
import UserContext from '../common/UserContext';
import GeneralOnboarding from './Onboarding/GeneralOnboarding';
import ConsentFormRouteCPC from './ConsentFormCPCRoute';
import ConsentFormRoutePEA from './ConsentFormPEARoute';
import ConsentFormModal from '../common/ConsentFormModal';
import PEAOverview from './ProfileRoute/Notification/components/PEAOverview';
import CPCOverview from './ProfileRoute/Notification/components/CPCOverview';
import FeedbackModal from '../common/FeedbackModal';
import InstallBanner from '../common/InstallBannerModal/InstallBannerIndex';
import ChangeCountryModal from '../common/ChangeCountryModal';

const ClientRoutes = (props) => {
  const { user } = useContext(UserContext);
  const { metaId: clientId, locale } = user;

  useEffect(() => {
    mixpanel.identify(user.uuid);
    mixpanel.register({
      Email: user.email,
      Role: user.metaType,
    });
  }, [user.email, user.metaType, user.uuid]);

  if (!clientId) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <ClientContextProvider clientId={clientId} locale={locale}>
        <ClientRoutesChild deferredPrompt={props.deferredPrompt} clientId={clientId} />
      </ClientContextProvider>
    </Switch>
  );
};

const ClientRoutesChild = ({ clientId, deferredPrompt }) => {
  const { user } = useContext(UserContext);
  const { child, defaultRoute, returnMessage } = useContext(ClientContext);
  const defaultRouteFunc = () => {
    return <Redirect to={'/' + defaultRoute} />;
  };
  const location = useLocation();
  const showNavbarAndModal = location.pathname !== '/consentFormCPC' && location.pathname !== '/consentFormPEA';

  return (
    <Container>
      {showNavbarAndModal && (
        <>
          <Navbar product={child.product} />
          <ModalNotification clientId={clientId} />
        </>
      )}
      <Suspense fallback="loading">
        <ConsentFormModal />
        <ChangeCountryModal />
        <FeedbackModal />
        <GeneralOnboarding />
        <InstallBanner user={user} deferredPrompt={deferredPrompt} />
        <Switch>
          <Route path="/dashboard" render={() => <DashboardRoute />} />
          <Route path="/program" render={() => <ProgramRoute product={child.product} />} />
          <Route path="/task/:taskPath" render={() => <TaskRoute product={child.product} />} />
          <Route path="/consultations" render={() => <ConsultationRoute />} />
          <Route path="/library" render={() => <LibraryRoute />} />
          <Route path="/profile" render={() => <ProfileRoute />} />
          <Route path="/home" render={() => <HomeRoute />} />
          <Route path="/qnaSessions" render={() => <QnaSessionsRoute />} />
          <Route path="/courses" render={() => <CoursesRoute />} />
          <Route path="/word-tools" render={() => <WordTrackingRoute />} />
          <Route path="/guidelines" render={() => <Guidelines />} />
          <Route path="/overview" render={() => <Overview />} />
          <Route path="/liteOverview" render={() => <LiteOverview />} />
          <Route path="/PEAOverview" render={() => <PEAOverview />} />
          <Route path="/CPCOverview" render={() => <CPCOverview />} />
          <Route path="/addShortcut" render={() => <AddShortcut />} />
          <Route path="/uploadfile" render={() => <UploadFile />} />
          <Route path="/account" render={() => <Account />} />
          <Route path="/video" render={() => <VideoRoute />} />
          <Route path="/consentFormCPC" render={() => <ConsentFormRouteCPC />} />
          <Route path="/consentFormPEA" render={() => <ConsentFormRoutePEA />} />
          <Route exact path="/" render={defaultRouteFunc} />
          <Route path="*">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </Suspense>
    </Container>
  );
};

export default ClientRoutes;
