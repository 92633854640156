import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: ID!, $newPassword: String!) {
    resetPassword(userId: $userId, newPassword: $newPassword) {
      user {
        id
        name
        email
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
