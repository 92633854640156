import React, { useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import ContentComponent from './ContentComponent';
import { BadgeButton } from '../BadgeScrollList/style';
import { BadgeTitle } from '../../../../style';
import BadgeScrollList from '../BadgeScrollList';
import UserContext from '../../../common/UserContext';
import { GET_ACTIVE_LIBRARIES } from '../../../../graphql/queries/getActiveSelfLearningLibraries';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { BlogOptionType } from './utils';

const Blog = () => {
  const user = useContext(UserContext);
  const locale = user.user.locale;
  const contentType = 'article';
  const { data, refetch } = useQuery(GET_ACTIVE_LIBRARIES, { variables: { locale, contentType } });
  const [activeBadge, setActiveBadge] = useState(0);

  const [content, setContent] = useState<{ key: number; name: string; content: any }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      let separatedArrays = {};
      for (let item of data?.getActiveSelfLearningLibraries) {
        let category = item.category;
        if (separatedArrays[category]) {
          separatedArrays[category].push(item);
        } else {
          separatedArrays[category] = [item];
        }
      }
      let formattedArrays: { key: number; name: string; content: any }[] = [];
      let key = 0;

      for (let category in separatedArrays) {
        formattedArrays.push({
          key: key++,
          name: category,
          content: separatedArrays[category],
        });
      }
      setContent(formattedArrays);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    refetch({ locale, contentType });
  }, [locale]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!content || content.length === 0) {
    return <p>{t('utils.content.notAvailable')}</p>;
  }
  const handleSelectBadge = (option: BlogOptionType) => {
    setActiveBadge(option.key);
    mixpanel.track('LibraryBlogBadge', {
      option: `${option.name}`,
    });
  };
  const Badge = content.map((option: BlogOptionType) => {
    return (
      <BadgeButton status={activeBadge === option.key} onClick={() => handleSelectBadge(option)}>
        <BadgeTitle>{option.name}</BadgeTitle>
      </BadgeButton>
    );
  });

  const blogCat = content.filter((option) => option.key === activeBadge);
  const blogList = blogCat[0].content;

  return (
    <>
      <BadgeScrollList badge={Badge} />
      <ContentComponent content={blogList} />
    </>
  );
};

export default Blog;
