import React, { useContext, useMemo, useCallback } from 'react';
import { ClientContext } from '../ClientContext';
import { useQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CHILD_PROGRESS } from '../../../graphql/queries/childProgress';
import { ChildProgress, ChildProgressVariables } from '../../../graphql/queries/types/ChildProgress';
import { BiggerRouteSectionContainer } from '../styles';
import Activity from './Activity';
import Goal from './Goal';
import SectionActionTitle from '../../common/SectionActionTitle';
import Category from './Category';
import ErrorPage from '../../common/ErrorPage';
import ConsentFormModal from '../../common/ConsentFormModal';

const defaultChildProgress = {
  id: '',
  completedTasks: 0,
  homeworkFinishCount: 0,
  goalImprovementPercent: 0,
  masteredSkillsCount: 0,
  requiredSkillsCount: 0,
  goals: [],
  weeklyCheckIns: [],
};

const DashboardRoute = () => {
  const { client, categories } = useContext(ClientContext);
  const history = useHistory();
  const { t } = useTranslation();
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;

  const { loading, error, data } = useQuery<ChildProgress, ChildProgressVariables>(CHILD_PROGRESS, {
    variables: { childId: child.id },
    fetchPolicy: 'network-only',
  });

  const childProgress = useMemo(() => {
    if (data == null || data.childProgress === null) {
      return defaultChildProgress;
    }
    return data.childProgress;
  }, [data]);

  const goToProgram = useCallback(() => {
    history.push('/program');
  }, [history]);

  const goToActivity = useCallback(() => {
    history.push('/task/activity');
  }, [history]);

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }
  if (error || !data || !data.childProgress) {
    return <ErrorPage />;
  }
  return (
    <BiggerRouteSectionContainer>
      <div className="tour_dashboard_goal">
        <SectionActionTitle
          title={t('client.program.threeMonthTitle')}
          actionText={t('client.dashboard.goal.viewAll')}
          shorten={true}
          handleClick={goToProgram}
        />
        <Goal goals={child.goals} productType={productType} />
      </div>
      <div className="tour_dashboard_activity">
        <SectionActionTitle
          title={t('client.dashboard.activity.title')}
          actionText={t('client.dashboard.activity.goToActivity')}
          shorten={false}
          handleClick={goToActivity}
        />
        <Activity childProgress={childProgress} />
      </div>
      <div className="tour_dashboard_skill">
        <SectionActionTitle
          title={t('client.dashboard.skill.title')}
          actionText={t('client.dashboard.skill.goToPractice')}
          shorten={false}
          handleClick={goToActivity}
        />
        <Category productType={productType} child={child} category={categories} />
      </div>
    </BiggerRouteSectionContainer>
  );
};

export default DashboardRoute;
