import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import {
  AddCheckinToActivities,
  AddCheckinToActivitiesVariables,
} from '../../../../../graphql/mutations/types/AddCheckinToActivities';
import { ACTIVITY_CHECKIN_MUTATION } from '../../../../../graphql/mutations/activityCheckin';
import EmptyActivity from '../../assets/emptyActivity.svg';
import {
  EmptyStateContainer,
  EmptyStateImg,
  EmtpyStateTitle,
  EmtpyStateContent,
  EmptyStateButton,
  ActionTitle,
  SmallSectionTitle,
} from '../../../../../style';
import {
  DailyActivityGrid,
  SummarySegment,
  SummaryMainSkill,
  SummaryName,
  SummaryContainer,
  SummaryCheckInCount,
  SummaryImage,
  SummaryMainSkillContainer,
} from '../ActivityModal/styles';
import { PenguinSmartTimeSlot } from '../../../../../util/Date';
import { ContentContainer, FloatButton, MasteredIcon, TimeSlotContainer } from './styles';
import { AddIcon } from '../../../WordTrackingRoute/ToolBox/AddButton/styles';
import GreenMastered from '../../../WordTrackingRoute/assets/GreenMastered.svg';
import { Client_client_children_assignedActivities } from '../../../../../graphql/queries/types/Client';
import SectionActionTitle from '../../../../common/SectionActionTitle';

interface ActivityContentProps {
  editMode: boolean;
  ac: Client_client_children_assignedActivities;
  daysDiffToday: number;
  thisWeek: boolean;
  currentWeekday: number;
}

const ActivityContent = React.memo(
  ({ editMode, ac, daysDiffToday, thisWeek, currentWeekday }: ActivityContentProps) => {
    const { t } = useTranslation();
    const total = ac.checkInCountDaily.slice(0, currentWeekday).reduce((a, b) => a + b, 0);
    return (
      <ContentContainer status={editMode}>
        <SummaryImage src={ac.mediaUrl} />
        {ac.checkInCountDaily[-daysDiffToday] > 0 && (
          <Icon
            name="check circle"
            size="big"
            style={{ color: '#5abaae', position: 'absolute', top: '10px', left: '10px' }}
          />
        )}
        <SummaryContainer>
          <SummaryMainSkillContainer>
            <SummaryMainSkill>{ac.mainSkill.name}</SummaryMainSkill>
          </SummaryMainSkillContainer>
          <SummaryName data-cy="activitieName">{ac.name}</SummaryName>
          {thisWeek ? (
            <SummaryCheckInCount>
              {t(`client.task.activities.hadCompleted`)}
              <span style={{ color: '#5ABAAE', margin: '0 2px' }}> {total} </span>
              {t(`client.task.activities.completedTimes`)}
            </SummaryCheckInCount>
          ) : (
            <SummaryCheckInCount />
          )}
        </SummaryContainer>
      </ContentContainer>
    );
  },
);

interface Props {
  selectedDayPlan: Client_client_children_assignedActivities[][];
  currentWeekday: number;
  daysDiffToday: number;
  thisWeek: boolean;
  editMode: boolean;
  childId: string;
  handleViewAll: () => void;
  showActivityDetail: (tsIndex: number, acIndex: number) => void;
  setEditMode: (editMode: boolean) => void;
  handleActivityCheckin: (activityIds: Array<string>) => void;
}

const DailyActivity = ({
  selectedDayPlan,
  currentWeekday,
  daysDiffToday,
  thisWeek,
  editMode,
  childId,
  handleViewAll,
  showActivityDetail,
  setEditMode,
  handleActivityCheckin,
}: Props) => {
  const { t } = useTranslation();
  const [checkInArray, setCheckInArray] = useState<Array<string>>([]);
  const [addCheckinToActivities, { loading, error, data }] = useMutation<
    AddCheckinToActivities,
    AddCheckinToActivitiesVariables
  >(ACTIVITY_CHECKIN_MUTATION, {
    update(cache, { data }) {
      handleActivityCheckin(checkInArray);
      setCheckInArray([]);
      setEditMode(false);
    },
  });

  const isToday = daysDiffToday === 0;
  const emptyStatus = selectedDayPlan.every((ts) => {
    return ts.length === 0;
  });

  const handleClick = (activity: Client_client_children_assignedActivities, tsIndex: number, acIndex: number) => {
    editMode ? handleClickActivityCheckIn(activity) : showActivityDetail(tsIndex, acIndex);
  };

  const handleClickActivityCheckIn = (activity: Client_client_children_assignedActivities) => {
    if (checkInArray.includes(activity.id)) {
      const newCheckInArray = checkInArray.filter((unselectActivityId: string) => unselectActivityId !== activity.id);
      setCheckInArray([...newCheckInArray]);
    } else {
      checkInArray.push(activity.id);
      setCheckInArray([...checkInArray]);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleComplete = async () => {
    if (checkInArray.length > 0) {
      await addCheckinToActivities({
        variables: {
          childId,
          activityIds: checkInArray,
        },
      });
    } else {
      setEditMode(false);
    }
  };

  if (emptyStatus) {
    return (
      <EmptyStateContainer>
        <EmptyStateImg src={EmptyActivity} />
        <EmtpyStateTitle>{t('client.task.activityTab.emptyTitle')}</EmtpyStateTitle>
        <EmtpyStateContent>{t('client.task.activityTab.emptyContent')}</EmtpyStateContent>
        <EmptyStateButton onClick={() => handleViewAll()}>
          <ActionTitle>{t('client.task.activityTab.emptyButton')}</ActionTitle>
        </EmptyStateButton>
      </EmptyStateContainer>
    );
  }
  return (
    <>
      <div>
        {isToday &&
          thisWeek &&
          (editMode ? (
            <FloatButton status={checkInArray.length !== 0} onClick={() => handleComplete()}>
              <MasteredIcon src={GreenMastered} />
              <ActionTitle>{t('client.task.activityTab.saveButton')}</ActionTitle>
            </FloatButton>
          ) : (
            <FloatButton status={true} onClick={() => handleEdit()}>
              <AddIcon className="plus" />
              <ActionTitle>{t('client.task.activityTab.checkInButton')}</ActionTitle>
            </FloatButton>
          ))}
      </div>
      <SectionActionTitle
        title={t('client.task.activityTab.activityTitle')}
        actionText={t('client.task.activityTab.viewAllButton')}
        shorten={true}
        handleClick={handleViewAll}
      />
      <DailyActivityGrid>
        {selectedDayPlan.map((activitiesInOneTimeSlot, tsIndex) => {
          if (activitiesInOneTimeSlot.length === 0) {
            return <div></div>;
          }
          return (
            <TimeSlotContainer>
              <SmallSectionTitle>{t(`utils.timeslot.${PenguinSmartTimeSlot[tsIndex]}`)}</SmallSectionTitle>
              {activitiesInOneTimeSlot.map((ac, acIndex) => {
                return (
                  <SummarySegment key={ac.id} onClick={() => handleClick(ac, tsIndex, acIndex)}>
                    <ActivityContent
                      editMode={editMode}
                      ac={{ ...ac }}
                      daysDiffToday={daysDiffToday}
                      thisWeek={thisWeek}
                      currentWeekday={currentWeekday}
                    />
                    {editMode ? (
                      <Icon
                        name={checkInArray.includes(ac.id) ? 'check circle' : 'check circle outline'}
                        size="big"
                        link
                        style={{ color: '#5abaae', alignSelf: 'center' }}
                      />
                    ) : (
                      <Icon name="chevron right" size="large" link style={{ alignSelf: 'center', color: '#808080' }} />
                    )}
                  </SummarySegment>
                );
              })}
            </TimeSlotContainer>
          );
        })}
      </DailyActivityGrid>
    </>
  );
};

export default React.memo(DailyActivity);
