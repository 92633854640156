import React, { useEffect, useState } from 'react';
import BraftEditor, { EditorState } from 'braft-editor';
import 'braft-editor/dist/index.css';
import { useTranslation } from 'react-i18next';

interface Props {
  initialString: string;
  placeholder: string;
  setNote: (note: string) => void;
}

const NoteEditor = ({ initialString, placeholder, setNote }: Props) => {
  const [editor, setEditor] = useState<EditorState>(BraftEditor.createEditorState(initialString));
  const { t } = useTranslation();
  useEffect(() => {
    if (editor.toHTML() !== initialString) {
      const text = BraftEditor.createEditorState('');
      setEditor(text);
      setNote(text.toHTML());
    }
  });
  const handleEditorChange = (editorState: EditorState) => {
    setEditor(editorState);
    setNote(editorState.toHTML());
  };

  return (
    <div
      style={{
        resize: 'none',
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
      }}
    >
      <BraftEditor
        placeholder={placeholder}
        value={editor}
        onChange={handleEditorChange}
        contentStyle={{ height: 210 }}
        language={'en'}
      />
    </div>
  );
};
export default NoteEditor;
