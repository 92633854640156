import styled from 'styled-components';

export const CheckBox = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 12px;
  margin-left: 8px;
  &: checked {
    background-color: #5abaae;
  }
`;
export const CheckBoxContainer = styled.div`
  margin-top: 3px;
  display: flex;
  align-items: center;
`;
