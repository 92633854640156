import gql from 'graphql-tag';

export const ACTIVITY_CHECKIN_MUTATION = gql`
  mutation AddCheckinToActivities($childId: ID!, $activityIds: [ID!]!) {
    addCheckinToActivities(childId: $childId, activityIds: $activityIds) {
      success
      returnMessage {
        status
        message
      }
    }
  }
`;
