import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps, Dropdown } from 'semantic-ui-react';
import { REMOVE_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeActivitiesToChild';
import { useMutation } from 'react-apollo';
import { ADD_ACTIVITY_REJECT_MUTATION } from '../../../../graphql/mutations/addActivityReject';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  RemoveActivitiesToChild,
  RemoveActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/RemoveActivitiesToChild';
import { AddActivityReject, AddActivityRejectVariables } from '../../../../graphql/mutations/types/AddActivityReject';
import { FAST_POLL } from '../constants';
interface Props {
  activityId: string;
  consultationId: string;
  trigger: JSX.Element;
  onClickFinish: () => void;
}

const ActivityRemoveButton = ({ activityId, consultationId, trigger, onClickFinish }: Props) => {
  const { t } = useTranslation();

  const {
    setReturnMessage,
    consultation: { id, child },
    setPollingCount,
  } = useContext(TherapistConsultationScreenContext);

  const [removeActivitiesToChild, { data }] = useMutation<RemoveActivitiesToChild, RemoveActivitiesToChildVariables>(
    REMOVE_ACTIVITIES_TO_CHILD_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
    },
  );

  async function handleRemoveActivity() {
    await removeActivitiesToChild({
      variables: {
        activityIds: [activityId],
        childId: child.id,
        consultationIds: [consultationId],
      },
    });
  }

  const handleClick = async (data: DropdownItemProps) => {
    setPollingCount((prevCount) => prevCount + 1);
    await handleRemoveActivity();
    await addActivityReject({
      variables: { activityId: activityId, childId: child.id, reason: data.text as string },
    });
    onClickFinish();
  };

  const [addActivityReject] = useMutation<AddActivityReject, AddActivityRejectVariables>(ADD_ACTIVITY_REJECT_MUTATION);

  useEffect(() => {
    if (data && data.removeActivitiesToChild) {
      setReturnMessage(data.removeActivitiesToChild.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]);

  return (
    <Dropdown style={{ height: 'max-content' }} direction="left" icon={null} trigger={trigger}>
      <Dropdown.Menu style={{ textAlign: 'center' }}>
        <Dropdown.Header content={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReason')} />
        <Dropdown.Divider />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.alreadyFinished')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooSimple')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooDifficult')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.doesNotMatchWithGoal')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.notSuitable')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.otherReason')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActivityRemoveButton;
