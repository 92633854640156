import styled from 'styled-components';
import { UserComment, SmallDescription, GeneralSegment } from '../../../../../style';
import { GoalCardSegment } from '../../../DashboardRoute/Goal/GoalCard/styles';
import { DashboardCardContainer, DashboardCardScrollContainer } from '../../../DashboardRoute/styles';
import { MainColor } from '../../../WordTrackingRoute/styles';

// Activities Summary
export const AllActivityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 20px;
`;
export const DailyActivityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 20px;
`;
export const SummarySegment = styled.div`
  max-height: fit-content;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  position: relative;
  margin-bottom: 15px;
`;

export const SummaryImage = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  object-fit: cover;
`;
export const SummaryContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
`;
export const SummaryMainSkillContainer = styled.div`
  height: 33.33%;
  display: flex;
  align-items: self-start;
`;
export const SummaryMainSkill = styled.div`
  background-color: #5abaae20;
  border-radius: 15px;
  max-width: 185px;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #5abaae;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media only screen and (min-width: 576px) {
    max-width: fit-content;
  }
`;
export const SummaryName = styled.div`
  font-size: 18px;
  font-weight: bold;
  height: 33.33%;
  display: flex;
  align-items: center;
`;
export const SummaryCheckInCount = styled.div`
  color: #808080;
  font-size: 12px;
  height: 33.33%;
  align-items: flex-end;
  display: flex;
`;

// Activity header
export const ActivityHeaderContainer = styled.div`
  display: flex;
`;
export const ActivityImage = styled.img`
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;
export const ActivityNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 20px;
  justify-content: space-between;
  height: 84px;
`;

export const TitleMainSkill = styled.div`
  background-color: white;
  border-radius: 15px;
  min-width: max-content;
  max-width: min-content;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${MainColor};
`;

export const Selective = styled(SmallDescription)`
  color: white;
`;

// Overall

export const CheckInButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  background: ${MainColor};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
  margin: 20px 0 0 0;
  position: fixed;
  width: 92%;
  left: 4%;
  right: 4%;
  bottom: 20px;
  @media only screen and (min-width: 768px) {
    width: 86%;
    position: absolute;
    left: 7%;
    right: 7%;
  }
`;

// Practice ActivitySteps
export const StepContainer = styled(GeneralSegment)`
  display: flex;
  padding: 15px 10px 15px 10px;
  border-radius: 8px;
  align-items: center;
  margin: 0 0 10px 0;
`;
export const PracticeContent = styled(UserComment)``;
export const Index = styled.div`
  border: 1px solid #5abaae;
  color: #5abaae;
  border-radius: 10px;
  display: flex;
  font-size: 15px;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
`;
export const TipsIcon = styled.img`
  height: 28px;
  width: 28px;
  padding: 2px;
  margin-right: 10px;
`;

export const MobileSpace = styled.div`
  height: 50px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

// setup section
export const SetupCardContainer = styled(DashboardCardContainer)`
  margin: -10px 0 0 0;
`;
export const SetupCardScrollContainer = styled(DashboardCardScrollContainer)`
  padding: 10px 0 30px 10px;
`;

export const SetupCardSegment = styled(GoalCardSegment)`
  cursor: default;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: initial;
  }
`;

export const ContentSectionContainer = styled.div`
  padding-bottom: 30px;
`;
