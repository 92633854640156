import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Description, ContentTitle, H2Title, OrderList, List, ActionTitle } from '../../../style';
import {
  MobileIcon,
  IconContainer,
  ActionContainer,
  RJRTooltipContentContainer,
  RJRTooltipHeaderContainer,
  RJRBigTooltipContainer,
  ChevronIcon,
  RJRSmallTooltipContainer,
  ActionFlexContainer,
  TextContainer,
} from './styles';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import AddShortcutTooltip from './AddShortcutTooltip';
import { ClientContext } from '../ClientContext';
import { UPDATE_CLIENT_WELCOME_STATUS_MUTATION } from '../../../graphql/mutations/updateClientWelcomeStatus';
import { useMutation } from 'react-apollo';
import { CLIENT_QUERY } from '../../../graphql/queries/client';
import { NavPathLink } from '../../common/Navbar';
import {
  UpdateClientWelcomeStatus,
  UpdateClientWelcomeStatusVariables,
} from '../../../graphql/mutations/types/UpdateClientWelcomeStatus';
import { CPC_PRODUCT_TYPES } from '../../../util/Product';

interface Props {}
const GeneralOnboarding = ({}: Props) => {
  const { client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;
  const addShortcut: React.MutableRefObject<any> = React.useRef(null);
  const [run, setRun] = useState(!client.welcomeFinished);

  const { t } = useTranslation();
  let width = window.screen.width;
  const mobile = width <= 992;
  let helperss: StoreHelpers;

  const getHelpers = (helpers: StoreHelpers) => {
    helperss = helpers;
  };

  const [finishWelcomeTour, { loading, error, data }] = useMutation<
    UpdateClientWelcomeStatus,
    UpdateClientWelcomeStatusVariables
  >(UPDATE_CLIENT_WELCOME_STATUS_MUTATION, {
    refetchQueries: [{ query: CLIENT_QUERY, variables: { clientId: client.id, status: true } }],
  });

  const handleAddShortcut = () => {
    setRun(false);
    finishWelcomeTour({ variables: { clientId: client.id, status: true } });
    if (addShortcut.current) {
      addShortcut.current.handleShowTooltip();
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      finishWelcomeTour({ variables: { clientId: client.id, status: true } });
    }
  };

  const WelcomeOnboard = (
    <RJRBigTooltipContainer>
      <RJRTooltipHeaderContainer>
        <H2Title>{t('onboarding.welcomeTour.welcome')}</H2Title>
      </RJRTooltipHeaderContainer>
    </RJRBigTooltipContainer>
  );

  const PEAintro = (
    <RJRBigTooltipContainer>
      <RJRTooltipHeaderContainer>
        <H2Title>{t('onboarding.welcomeTour.pea')}</H2Title>
      </RJRTooltipHeaderContainer>
      <RJRTooltipContentContainer>
        <Description>{t('onboarding.welcomeTour.peaIntroOpening')}</Description>
        <OrderList>
          <List>
            <Description>{t('onboarding.welcomeTour.peaIntro1')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.peaIntro2')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.peaIntro3')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.peaIntro4')}</Description>
          </List>
        </OrderList>
      </RJRTooltipContentContainer>
    </RJRBigTooltipContainer>
  );

  const CPCintro = (
    <RJRBigTooltipContainer>
      <RJRTooltipHeaderContainer>
        <H2Title>{t('onboarding.welcomeTour.cpc')}</H2Title>
      </RJRTooltipHeaderContainer>
      <RJRTooltipContentContainer>
        <Description>{t('onboarding.welcomeTour.cpcIntroOpening')}</Description>
        <OrderList>
          <List>
            <Description>{t('onboarding.welcomeTour.cpcIntro1')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.cpcIntro2')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.cpcIntro3')}</Description>
          </List>
          <List>
            <Description>{t('onboarding.welcomeTour.cpcIntro4')}</Description>
          </List>
        </OrderList>
      </RJRTooltipContentContainer>
    </RJRBigTooltipContainer>
  );

  const SmallToolTipComponent = (title: string, description: string) => {
    return (
      <RJRSmallTooltipContainer>
        <ContentTitle>{title}</ContentTitle>
        <Description>{description}</Description>
      </RJRSmallTooltipContainer>
    );
  };

  const TaskIntro = SmallToolTipComponent(t('client.home.TaskTitle'), t('client.home.TaskDes'));

  const CourseIntro = SmallToolTipComponent(t('client.home.coursesTitle'), t('client.home.coursesDes'));

  const QnaSessionsIntro = SmallToolTipComponent(t('client.home.qnaSessionsTitle'), t('client.home.qnaSessionsDes'));

  const LibraryIntro = SmallToolTipComponent(t('client.home.LibraryTitle'), t('client.home.LibraryDes'));

  const WordTrackerIntro = SmallToolTipComponent(t('client.home.WordTrackerTitle'), t('client.home.WordTrackerDes'));

  const AddToHomescreen = (
    <RJRBigTooltipContainer>
      <RJRTooltipHeaderContainer>
        <H2Title>{t('onboarding.welcomeTour.ending')}</H2Title>
      </RJRTooltipHeaderContainer>
      <RJRTooltipContentContainer>
        <ActionContainer onClick={handleAddShortcut}>
          <ActionFlexContainer>
            <IconContainer type={'left'}>
              <MobileIcon className="circular mobile alternate"></MobileIcon>
            </IconContainer>
            <TextContainer>
              <ActionTitle>{t('onboarding.welcomeTour.action')}</ActionTitle>
              <Description>{t('onboarding.welcomeTour.actionDescription')}</Description>
            </TextContainer>
          </ActionFlexContainer>
          <IconContainer type={'right'}>
            <ChevronIcon className="chevron right" link />
          </IconContainer>
        </ActionContainer>
      </RJRTooltipContentContainer>
    </RJRBigTooltipContainer>
  );

  const location = mobile ? 'bottom' : 'side';
  const targetFromNavPath = (navPath: NavPathLink) => `.tour--${navPath}--` + location;
  const placement = () => (mobile ? 'auto' : 'right');

  const welcome: Step = {
    target: 'body',
    placement: 'center',
    content: WelcomeOnboard,
  };

  const cpcIntroStep: Step = {
    target: 'body',
    placement: 'center',
    content: CPCintro,
  };

  const peaIntroStep: Step = {
    target: 'body',
    placement: 'center',
    content: PEAintro,
  };

  const addToHomescreenStep: Step = {
    target: 'body',
    placement: 'center',
    content: AddToHomescreen,
  };

  const coursesStep: Step = {
    target: targetFromNavPath('courses'),
    placement: placement(),
    content: CourseIntro,
  };

  const taskStep: Step = {
    target: targetFromNavPath('task'),
    placement: placement(),
    content: TaskIntro,
  };

  const libraryStep: Step = {
    target: targetFromNavPath('library'),
    placement: placement(),
    content: LibraryIntro,
  };

  const qnaSessionStep: Step = {
    target: targetFromNavPath('qnaSessions'),
    placement: placement(),
    content: QnaSessionsIntro,
  };

  const wordToolsStep: Step = {
    target: targetFromNavPath('word-tools'),
    placement: placement(),
    content: WordTrackerIntro,
  };

  // order should be different for mobile and desktop to match direction of bottom navbar
  const cpcMobileSteps = [welcome, cpcIntroStep, libraryStep, taskStep, wordToolsStep, addToHomescreenStep];
  const cpcDesktopSteps = [welcome, cpcIntroStep, taskStep, libraryStep, wordToolsStep, addToHomescreenStep];

  const peaMobileSteps = [welcome, peaIntroStep, taskStep, coursesStep, qnaSessionStep, addToHomescreenStep];
  const peaDesktopSteps = [welcome, peaIntroStep, coursesStep, taskStep, qnaSessionStep, addToHomescreenStep];

  const CPC_TOUR_STEPS = mobile ? cpcMobileSteps : cpcDesktopSteps;
  const PEA_TOUR_STEPS = mobile ? peaMobileSteps : peaDesktopSteps;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  return (
    <>
      <AddShortcutTooltip ref={addShortcut} />
      <Joyride
        steps={isCPC ? CPC_TOUR_STEPS : PEA_TOUR_STEPS}
        run={run && child.consentFormCompleted}
        continuous={true}
        scrollToFirstStep={true}
        disableScrolling={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        getHelpers={getHelpers}
        locale={{
          open: t('onboarding.open'),
          next: t('onboarding.next'),
          back: t('onboarding.back'),
          close: t('onboarding.close'),
          last: t('onboarding.last'),
          skip: t('onboarding.skip'),
        }}
        styles={{
          options: {
            arrowColor: '#fff',
            primaryColor: '#81CAC1',
            textColor: '#333',
            width: 'max-content',
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

export default GeneralOnboarding;
