import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalHeader, ModalMask } from '../../../../../../../style';

export const AddWordModalMask = styled(ModalMask)``;

export const AddWordModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)``;

export const ButtonContainer = styled(ModalButtonContainer)`
  justify-content: flex-end;
`;
