import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_goals } from '../../../../../graphql/queries/types/Client';
import { ActionTitle } from '../../../../../style';
import useLockBodyScroll from '../../../../../util/Hooks/useLockBodyScroll';
import { HighlightBottomEdge, TabMenuContainer, TabMenuItem } from '../../../../common/TabMenu/styles';
import ModalInsideCloseButton from '../../../../common/ModalInsideCloseButton';
import {
  ContentContainer,
  GoalModalContainer,
  GoalModalContainerWithClose,
  GoalModalContent,
  GoalModalHeader,
  GoalModalMask,
  GoalModalTabContainer,
  GoalTitle,
} from '../../styles';
import GoalTab from './GoalTab';
import OverallTab from './OverallTab';
import { SkillCategory } from '../../../../../util/Category';

interface Props {
  show: boolean;
  productType: string;
  category: SkillCategory;
  handleClose: () => void;
  goals: Array<Client_client_children_goals>;
  goalIndex: string;
  handleGoalModalOpen: (id: string, step: string) => void;
  showGoalModal: boolean;
}

const CategoryModal = ({
  showGoalModal,
  show,
  productType,
  category,
  handleClose,
  goals,
  goalIndex,
  handleGoalModalOpen,
}: Props) => {
  const { t } = useTranslation();
  const showModal = showGoalModal ? false : show;
  useLockBodyScroll(show);
  const [activeItem, setActiveItem] = useState('skill');
  const isSkillTab = activeItem === 'skill';
  const isGoalTab = activeItem === 'goal';

  const handleReset = useCallback(() => {
    setActiveItem('skill');
    handleClose();
  }, [handleClose]);

  const CategoryModalGoals = goals.filter((g) => {
    return g.skill.category.id === goalIndex;
  });

  return (
    <GoalModalMask open={showModal}>
      <GoalModalContainerWithClose>
        <GoalModalContainer>
          <GoalModalHeader>
            <ModalInsideCloseButton handleClose={handleReset} />
            <GoalTitle>{category.name}</GoalTitle>
          </GoalModalHeader>
          <GoalModalTabContainer>
            <TabMenuContainer>
              <HighlightBottomEdge isSelected={isSkillTab}>
                <TabMenuItem active={isSkillTab} onClick={() => setActiveItem('skill')}>
                  <ActionTitle>{t('client.dashboard.skill.overallTab')}</ActionTitle>
                </TabMenuItem>
              </HighlightBottomEdge>
              <HighlightBottomEdge isSelected={isGoalTab}>
                <TabMenuItem active={isGoalTab} onClick={() => setActiveItem('goal')}>
                  <ActionTitle>{t('client.dashboard.skill.goalTab')}</ActionTitle>
                </TabMenuItem>
              </HighlightBottomEdge>
            </TabMenuContainer>
          </GoalModalTabContainer>
          <GoalModalContent>
            {isSkillTab && (
              <ContentContainer>
                <OverallTab category={category} productType={productType} show={show} />
              </ContentContainer>
            )}
            {isGoalTab && (
              <ContentContainer>
                <GoalTab
                  goals={CategoryModalGoals}
                  productType={productType}
                  handleGoalModalOpen={handleGoalModalOpen}
                />
              </ContentContainer>
            )}
          </GoalModalContent>
        </GoalModalContainer>
      </GoalModalContainerWithClose>
    </GoalModalMask>
  );
};
export default CategoryModal;
