import styled from 'styled-components';
import enLogo from '../../../assets/Logo_EN.png';

export const Container = styled.div`
  text-align: center;
  position: relative;
  height: auto;
  z-index: 9999;
  background: white;
  border-radius: 7px;
  width: 70%;
  padding: 2%;
  border: 0px solid #333333;
  box-shadow: 5px 5px 5px #a5a5a5;

  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 16px;
  } ;
`;

export const BiggerContainer = styled.div`
  cursor: default;
  display: flex;
  justify-content: center;
  background: #d9d9d9;
  padding: 2%;
  overflow-x: hidden;

  @media only screen and (max-width: 990px) {
    padding: 4%;
  } ;
`;

export const CarouselItem = styled.div`
  height: auto;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  white-space: normal;
  padding: 6% 10% 10% 10%;
  flex-wrap: wrap;
  font-size: 17px;
  font-size: 16px;
`;
export const Carousel = styled.div`
  margin-top: 2%;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  padding: 0;
  border: 1px solid #b8b8b8;

  @media only screen and (min-width: 819px) {
    display: none;
  } ;
`;
export const Inner = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
`;

export const BallsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
export const Ball = styled.div`
  margin: 0% 5px;
  border-radius: 5px;
  width: 15px;
  height: 5px;
  background: #d9d9d9;
  transition: 0.3s;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  flex-direction: column;
  margin-top: 16px;
`;

export const Banner = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 206px;
  height: auto;
`;

export const Title = styled.div`
  font-size: 36px;
  width: 100%;
  color: #5abaae;
  font-weight: bold;

  @media only screen and (max-width: 990px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 28px;
  }

  @media only screen and (max-width: 330px) {
    font-size: 24px;
  } ;
`;

export const Text = styled.div`
  margin-top: 3%;
  font-size: 18px;
  width: 55%;
  text-align: left;
  @media only screen and (max-width: 990px) {
    width: 75%;
  } ;
`;

export const ItalicText = styled(Text)`
  font-style: italic;
`;

export const SectionText = styled.div`
  margin-top: 1%;
  text-align: left;
  font-size: 18px;
`;

export const SectionBoxTitle = styled.b`
  text-decoration: underline;
  font-size: 20px;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 3%;
  margin-top: 3%;

  @media only screen and (max-width: 700px) {
    margin-top: 0%;
  }
  @media only screen and (max-width: 990px) {
    margin-bottom: 32px;
    width: 100%;
  } ;
`;
export const NameContainer = styled.div`
  flex-direction: row;
  display: flex;

  justify-content: center;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  } ;
`;

export const FirstNameContainer = styled.div`
  margin: 0px 7% 0 0%;
  @media only screen and (max-width: 700px) {
    margin-top: 5%;
    margin-right: 0%;
  } ;
`;

export const LastNameContainer = styled(FirstNameContainer)`
  margin-right: 0;
  @media only screen and (min-width: 1400px) {
    margin-left: 10%;
  }

  @media only screen and (max-width: 700px) {
    margin-left: 0%;
  } ;
`;
export const NameTitle = styled.div`
  text-align: left;
  font-weight: bold;
  @media only screen and (max-width: 700px) {
    text-align: center;
  } ;
`;

export const NameField = styled.input`
  border: 1px solid #333333;
  border-radius: 0px;
  border-style: none none solid none;
  pointer-events: none;
  font-size: 20px;
  padding: 5px;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
    text-align: center;
  }

  @media only screen and (max-width: 0px) {
  }
`;
export const ChildNameContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 3%;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  } ;
`;
export const ChildName = styled(NameField)``;

export const ChildNameContainer2 = styled(FirstNameContainer)``;

export const SectionContainer = styled.div`
  width: 90%;
  margin-top: 6%;
  text-align: left;
`;

export const SectionTitle = styled.div`
  font-size: 28px;
  text-align: left;
  color: #5abaae;
  font-weight: bold;
  @media only screen and (max-width: 572px) {
    text-align: left;
    margin-top: 32px;
    font-size: 24px;
  } ;
`;

export const SectionsContainer = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  flex-direction: column;
`;

export const SectionBox = styled.div`
  margin-top: 2%;
  border: 1px solid #b8b8b8;
  padding: 1% 3%;
  overflow-y: auto;
  height: 45vh;
  @media only screen and (max-width: 819px) {
    display: none;
  } ;
`;

export const RedBoldedText = styled.span`
  font-weight: bold;
  color: red;
  font-size: 18px;
`;

export const RedBoldedTextMobile = styled(RedBoldedText)`
  font-size: 16px;
`;

export const BoldedText = styled.b<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  text-align: center;
`;

export const OrderList = styled.ol`
  padding-inline-start: 20px;
  font-size: 20px;
`;

export const OrderListItem = styled.li`
  margin-top: 20px;
`;

export const CheckBox = styled.input`
  margin-right: 10px;
  &: checked {
    background-color: #5abaae;
  }
`;

export const CheckBoxContainer = styled.div`
  margin-top: 10px;
`;
export const CheckBoxText = styled.label<{}>`
  margin-top: 12px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
`;

export const NoScrollSectionBox = styled.div`
  height: auto;
  padding: 10px 30px;
  margin-top: 2%;
  border: 1px solid #b8b8b8;
  padding: 1% 3%;
  overflow-y: auto;

  @media only screen and (max-width: 900px) {
    padding: 20px 30px;
  } ;
`;

export const NoScrollSectionBoxMobile = styled(NoScrollSectionBox)`
  @media only screen and (max-width: 819px) {
    display: none;
  }
`;

export const SubmitButton = styled.button`
  margin-top: 68px;
  margin-bottom: 28px;
  font-size: 20px;
  border-radius: 5px;
  background: #5abaae;
  color: white;
  border: 1px #5abaae solid;
  padding: 5px 24px;
  cursor: pointer;

  @media only screen and (max-width: 572px) {
    text-align: center;
    margin-top: 52px;
  } ;
`;

export const NoScrollSectionText = styled(SectionText)`
  margin: 0;
  margin-top: 8px;
  margin-bottom: 6px;
  font-size: 16px;

  @media only screen and (max-width: 819px) {
    font-size: 14px;
  }
`;

export const ModalContainer = styled.div`
  background-color: #fff;
`;

export const ModalContainer2 = styled.div`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 25px 25px 30px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 15px;
  height: max-content;
  text-align: center;
  overscroll-behavior: contain;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;

  @media only screen and (max-width: 430px) {
    width: 300px;
  } ;
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  color: #5abaae;
  margin: 0;
  margin-bottom: 14px;
`;

export const ModalText = styled.div`
  text-align: center;
  font-size: 16px;
  width: 90%;
  margin: 0;
`;

export const ModalButton = styled(SubmitButton)`
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 30px;
`;

export const ModalMask = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000099;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
`;
