import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { MainColor } from '../../styles';
import { Input } from '../../../../../style';

export const SearchBarContainer = styled.div`
  width: 150px;
  position: relative;
  margin: 0 10px 10px 0;
  @media only screen and (min-width: 992px) {
    width: 200px;
  }
`;

export const SearchBarInput = styled(Input)`
  padding-left: 40px;
`;

export const SearchIcon = styled(SVG)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto 0;
  path {
    fill: #909090;
  }
  @media (hover: hover) and (pointer: fine) {
    ${SearchBarContainer}: hover & path {
      fill: ${MainColor};
    }
  }
  ${SearchBarContainer}: focus-within & path {
    fill: ${MainColor};
  }
`;
