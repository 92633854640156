import gql from 'graphql-tag';
import { Goal, ReturnMessage } from '../../types';

export const CREATE_GOAL_MUTATION = gql`
  mutation CreateGoal(
    $description: String!
    $initNumerator: Int!
    $initDenominator: Int!
    $successNumerator: Int!
    $successDenominator: Int!
    $metric: String!
    $skillId: ID!
    $preskillIds: [ID!]!
    $childId: ID!
    $consultationId: ID!
  ) {
    createGoal(
      description: $description
      initNumerator: $initNumerator
      initDenominator: $initDenominator
      successNumerator: $successNumerator
      successDenominator: $successDenominator
      metric: $metric
      skillId: $skillId
      preskillIds: $preskillIds
      childId: $childId
      consultationId: $consultationId
    ) {
      goal {
        id
        description
        metric
        initNumerator
        initDenominator
        currentNumerator
        currentDenominator
        successNumerator
        successDenominator
        achieved
        skill {
          id
          name
          category {
            id
            name
            subcategories {
              id
              name
              skills {
                id
                name
              }
            }
          }
          subcategory {
            id
            name
          }
        }
        preskills {
          id
          name
        }
        goalPreskills {
          skillId
          mastered
        }
        goalRecords {
          id
          consultation {
            id
            consultationTime
          }
          currentNumerator
          currentDenominator
        }
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  createGoal: { goal: Goal; returnMessage: ReturnMessage };
}

export interface Variables {
  description: string;
  initNumerator: number;
  initDenominator: number;
  successNumerator: number;
  successDenominator: number;
  metric: string;
  skillId: number;
  preskillIds: number[];
  childId: number;
  consultationId: number;
}
