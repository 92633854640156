import React, { useContext } from 'react';
import { DropdownItemProps, Button, Dropdown } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { ADD_ACTIVITY_REJECT_MUTATION, Data, Variables } from '../../../../graphql/mutations/addActivityReject';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { AddActivityReject, AddActivityRejectVariables } from '../../../../graphql/mutations/types/AddActivityReject';
import { useTranslation } from 'react-i18next';

interface Props {
  activityId: string;
  handleClickSwitch: (data: DropdownItemProps) => void;
}

const ActivitySwitchComponent = ({ activityId, handleClickSwitch }: Props) => {
  const {
    consultation: { child },
  } = useContext(TherapistConsultationScreenContext);

  const [addActivityReject, { loading, error, data }] = useMutation<AddActivityReject, AddActivityRejectVariables>(
    ADD_ACTIVITY_REJECT_MUTATION,
  );

  const handleSwitch = async (data: DropdownItemProps) => {
    handleClickSwitch(data);
    await addActivityReject({
      variables: { activityId: activityId, childId: child.id, reason: data.text as string },
    });
  };

  const { t } = useTranslation();

  return (
    <Button
      style={{
        padding: '10px 10px',
        borderRadius: '24px',
        margin: '5px 0',
        color: 'white',
        backgroundColor: '#5DD9C4',
      }}
    >
      <Dropdown text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switch')} direction="left" icon={null}>
        <Dropdown.Menu style={{ textAlign: 'center' }}>
          <Dropdown.Header content={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReason')} />
          <Dropdown.Divider />
          <Dropdown.Item
            text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooSimple')}
            onClick={(e, data: DropdownItemProps) => handleSwitch(data)}
          />
          <Dropdown.Item
            text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooDifficult')}
            onClick={(e, data: DropdownItemProps) => handleSwitch(data)}
          />
          <Dropdown.Item
            text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.doesNotMatchWithGoal')}
            onClick={(e, data: DropdownItemProps) => handleSwitch(data)}
          />
          <Dropdown.Item
            text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.notSuitable')}
            onClick={(e, data: DropdownItemProps) => handleSwitch(data)}
          />
          <Dropdown.Item
            text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.otherReason')}
            onClick={(e, data: DropdownItemProps) => handleSwitch(data)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button>
  );
};

export default ActivitySwitchComponent;
