import styled from 'styled-components';
import { FooterText } from '../../../../../style';
import { MainColor } from '../../../WordTrackingRoute/styles';

// progress bar
export const ProgressBar = styled.div<{ height: number }>`
  justify-content: flex-start;
  border-radius: 4px;
  align-items: center;
  position: relative;
  display: flex;
  height: ${(props) => props.height}px;
  width: 100%;
  background: #f5f5f5;
  margin-bottom: 10px;
`;
export const ProgressBarValue = styled.div<{ width: number; height: number }>`
  box-shadow: 0 3px 6px -1px ${MainColor};
  border-radius: 4px;
  background: ${MainColor};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}%;
`;
export const ProgressFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
export const ProgressBarIndicator = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const IndicatorText = styled(FooterText)`
  font-size: 1rem;
`;
export const CurrentIndicatorText = styled(FooterText)<{ marginLeft: number }>`
  font-size: 1rem;
  margin-left: ${(props) => props.marginLeft}%;
  margin-top: -1.8rem;
`;
