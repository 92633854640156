import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { MainColor } from '../../../WordTrackingRoute/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { CheckIn } from '../../../../../graphql/queries/types/ChildProgress';
import { TooltipContainer } from '../styles';

type Props = {
  weeklyCheckIns: CheckIn[];
} & WithTranslation;

interface States {
  focusBar: number | undefined;
  mouseLeave: boolean;
}

type weeklyCheckInData = {
  week: any;
  checkInCount: number;
  trainedActivityCount: number;
  startDate: string;
  endDate: string;
}[];

interface CustomTooltipProps {
  active: any;
  payload: any;
  label: any;
  data: weeklyCheckInData;
}

const getPeriodDate = (label, data: weeklyCheckInData) => {
  return data.map((periodData) => {
    if (label === periodData.week) {
      return <div>{`${periodData.startDate} - ${periodData.endDate}`}</div>;
    }
    return <div />;
  });
};

const getCheckInAcCount = (label, data: weeklyCheckInData, t) => {
  return data.map((periodData) => {
    if (label === periodData.week && periodData.trainedActivityCount > 0) {
      return (
        <div>{`${t('client.dashboard.activity.chart.checkInActivityCount')}： ${periodData.trainedActivityCount}`}</div>
      );
    }
    return <div />;
  });
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, data }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        {label}
        {getPeriodDate(label, data)}
        <div style={{ color: MainColor }}>
          {`${t('client.dashboard.activity.chart.checkInCount')}： ${payload[0].value}`}
        </div>
        {getCheckInAcCount(label, data, t)}
      </TooltipContainer>
    );
  }
  return null;
};

class CheckInChart extends PureComponent<Props, States> {
  data: weeklyCheckInData;
  constructor(props: Props) {
    super(props);
    this.state = {
      focusBar: undefined,
      mouseLeave: true,
    };
    this.data = (() => {
      const { t, weeklyCheckIns } = this.props;
      return weeklyCheckIns.map((weekData, index) => {
        const weekCount = index + 1;
        const startDateData = new Date(weekData.startDate.split(' ')[0]);
        const startDateMonth = t(`utils.month.${startDateData.getMonth()}`);
        const endDateData = new Date(weekData.endDate.split(' ')[0]);
        const endDateMonth = t(`utils.month.${endDateData.getMonth()}`);
        return {
          week:
            t('client.dashboard.activity.chart.xAxisWeek1') +
            ' ' +
            weekCount +
            ' ' +
            t('client.dashboard.activity.chart.xAxisWeek2'),
          checkInCount: weekData.checkInCount,
          trainedActivityCount: weekData.trainedActivityCount,
          startDate: startDateMonth + ' ' + startDateData.getDate(),
          endDate: endDateMonth + ' ' + endDateData.getDate(),
        };
      });
    })();
  }

  render() {
    const { focusBar, mouseLeave } = this.state;
    const { t } = this.props;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              this.setState({ focusBar: state.activeTooltipIndex, mouseLeave: false });
            } else {
              this.setState({ focusBar: undefined, mouseLeave: true });
            }
          }}
          data={this.data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="week" scale="band" />
          <YAxis label={{ value: t('client.dashboard.activity.chart.index'), angle: -90, position: 'insideLeft' }} />
          <Tooltip
            cursor={false}
            content={<CustomTooltip active={undefined} payload={undefined} label={undefined} data={this.data} />}
          />
          <Legend align="right" height={6} />
          <Bar name={t('client.dashboard.activity.chart.checkIn')} dataKey="checkInCount" barSize={20} fill={MainColor}>
            {this.data.map((entry, index) => (
              <Cell fill={focusBar === index || mouseLeave ? MainColor : '#5abaae60'} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default withTranslation()(CheckInChart);
