import React from 'react';
import { Space, Tag, Typography } from 'antd';

interface Props {
  title: string;
  titleLevel: 1 | 3 | 2 | 4 | 5 | undefined;
  count: number;
  status: string;
}

const SectionTitleWithCount = ({ title, titleLevel, count, status }: Props) => {
  const { Title } = Typography;
  return (
    <Space align="center" style={{ gap: '8px' }}>
      <Title style={{ margin: 0 }} level={titleLevel}>
        {title}
      </Title>
      <Tag color={status}>{count}</Tag>
    </Space>
  );
};

export default React.memo(SectionTitleWithCount);
