import gql from 'graphql-tag';
import { Client } from '../../types';

export const CLIENT_QUERY = gql`
  query Client($clientId: ID!) {
    client(clientId: $clientId) {
      id
      user {
        id
        name
        email
        metaId
        locale
        activeChildId
        country
        smsNotification
      }
      referral
      timeZone
      children {
        id
        name
        dob
        gender
        completedTasks
        consentFormCompleted
        assignedActivities {
          id
          name
          mediaUrl
          videoUrl
          videoRemarks
          done
          mainSkill {
            id
            name
            category {
              id
              name
            }
          }
          skills {
            id
            name
            category {
              id
              name
            }
          }
          summary
          parentInteraction
          scenario
          setup
          frequency
          tools
          steps
          parentReaction
          notes
          extension
          checkInCount
          checkInCountDaily
        }
        childSixMonthGoal {
          id
          description
        }
        product {
          id
          name
          description
          owner
          productTypesName
        }
        therapist {
          id
          avatarFilename
          title
          therapistBios {
            id
            bio
            language
          }
          user {
            id
            name
            email
          }
          consultationUrl
          cnConsultationUrl
          pictureUrl
        }
        consultations {
          id
          therapist {
            id
            user {
              id
              uuid
              name
            }
          }
          consultationTime
          smsLogs {
            id
            userId
            consultationId
            details
            createdAt
          }
          notes {
            id
            mainComplaint
            internalNotes
            commentForParents
          }
          canceled
          accepted
          published
          uploadedVideos {
            id
            consultation {
              id
            }
            uploadedVideoName
            isDownloaded
          }
        }

        goals {
          id
          description
          initNumerator
          currentNumerator
          currentDenominator
          successNumerator
          successDenominator
          metric
          skill {
            id
            name
            category {
              id
              name
            }
          }
          preskills {
            id
            name
          }
          goalPreskills {
            id
            skillId
            mastered
            name
            skill {
              id
              category {
                id
                name
              }
            }
          }
          assignedActivities {
            id
            name
            summary
            mediaUrl
            checkInCount
            videoUrl
            videoRemarks
            mainSkill {
              id
              name
            }
          }
        }
        lastAssignedActivities {
          id
          name
          mediaUrl
          done
          videoUrl
          videoRemarks
          mainSkill {
            id
            name
            category {
              id
              name
            }
          }
          skills {
            id
            name
            category {
              id
              name
            }
          }
          summary
          parentInteraction
          scenario
          setup
          frequency
          tools
          steps
          parentReaction
          notes
          extension
          checkInCount
          checkInCountDaily
        }
        activityPlan {
          id
          weekday
          timeslot
          activityId
        }
        room {
          id
        }
        assistantRoom {
          id
        }
        pictureUrl
        latestConsultation {
          createdAt
        }
        nextConsultation {
          id
          consultationTime
        }
        startServiceAt
        currentWeek
        currentCourse
      }
      onBoardDocumentFinished
      onBoardDocumentUrl
      therapistAssistant {
        id
        user {
          id
          name
        }
      }
      requestBuyMore
      consultationTimesRemain
      membershipDue
      welcomeFinished
    }
  }
`;

export interface Data {
  client: Client;
}

export interface Variables {
  clientId: string;
}
