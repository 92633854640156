import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const COMPLETE_WORDS_MUTATION = gql`
  mutation CompleteWords($wordIds: [ID!]!) {
    completeWords(wordIds: $wordIds) {
      success
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface CompleteWordsData {
  success: boolean;
  returnMessage: ReturnMessage;
}

export interface CompleteWordsVariables {
  wordIds: number[];
}
