import styled from 'styled-components';
import { SmallContentTitle, SmallDescription } from '../../../../style';

// grid container
export const VideoGridContainer = styled.div`
  display: grid;
  gap: 3rem 0.8rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

// video container
export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// video thumbnail
export const VideoThumbnail = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  min-width: 250px;
  min-height: 150px;
  background-color: #aaa;
`;
export const TimeContainer = styled(SmallDescription)`
  position: relative;
  display: flex;
  :: before {
    position: absolute;
    right: 5px;
    bottom: 5px;
    content: attr(data-duration);
    background-color: #000000;
    color: #ffffff;
    border-radius: 2px;
    padding: 1px 3px;
  }
`;

// video content
export const TitleContainer = styled.div`
  padding: 5px 0 0 0;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 62px;
`;
export const VideoTitle = styled(SmallContentTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
`;
