import React from 'react';
import { ActionTitle } from '../../../../../../style';
import { HighlightBottomEdge, TabMenuItem } from '../../../../../common/TabMenu/styles';

interface Props {
  isSelect: boolean;
  index: number;
  title: string;
  handleTabSelect: (index: number) => void;
}
const Tab = React.memo(({ isSelect, title, index, handleTabSelect }: Props) => {
  return (
    <HighlightBottomEdge isSelected={isSelect}>
      <TabMenuItem active={isSelect} onClick={() => handleTabSelect(index)}>
        <ActionTitle>{title}</ActionTitle>
      </TabMenuItem>
    </HighlightBottomEdge>
  );
});

export default React.memo(Tab);
