import gql from 'graphql-tag';
import { Activity, ReturnMessage } from '../../types';

export const ADD_ACTIVITIES_TO_CHILD = gql`
  mutation AddActivitiesToChild($childId: ID!, $activityIds: [ID!]!, $consultationId: ID!) {
    addActivitiesToChild(childId: $childId, activityIds: $activityIds, consultationId: $consultationId) {
      activities {
        id
        name
        summary
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  addActivitiesToChild: { activities: Activity[]; returnMessage: ReturnMessage };
}

export interface Variables {
  childId: number;
  activityIds: number[];
  consultationId: number;
}
