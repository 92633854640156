import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientContext } from '../../../ClientContext';
import ActivityModal from '../ActivityModal';
import DailyActivity from './DailyActivity';
import DailyPlanCalendar from './DailyPlanCalendar';
import TitleAction from './TitleAction';
import { ActivityListContainer } from './styles';
import { EmptyStateContainer, EmptyStateImg, EmtpyStateTitle, EmtpyStateContent } from '../../../../../style';
import EmptyActivity from '../../assets/emptyActivity.svg';
import {
  Child_consultation,
  Client_client_children_assignedActivities,
} from '../../../../../graphql/queries/types/Client';

interface Props {
  planActivity: Client_client_children_assignedActivities[][][];
  latestConsultation: Child_consultation;
  handleActivityCheckin: (activityIds: Array<string>) => void;
  handleViewAll: () => void;
}

const ActivityPlan = ({ planActivity, latestConsultation, handleActivityCheckin, handleViewAll }: Props) => {
  const { t } = useTranslation();
  const { child, setReturnMessage } = useContext(ClientContext);
  const childId = child.id;
  const [daysDiffToday, setDaysDiffToday] = useState<number>(0);
  const [startDiffDay, setStartDiffDay] = useState<number>(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [acIndex, setAcIndex] = useState<number>(-1);
  const [tsIndex, setTsIndex] = useState<number>(-1);

  useEffect(() => {
    const now = new Date();
    const todayDate = now.getDay();
    const todayWeekday = ((todayDate + 6) % 7) + 1;
    setSelectedDay(todayWeekday - 1);
    setStartDiffDay(1 - todayWeekday);
  }, []);

  const [currentWeekday, dateRange, planBeginDate] = useMemo(() => {
    const now = new Date();
    const todayDate = now.getDay();
    const todayWeekday = ((todayDate + 6) % 7) + 1;
    const latestConsultationCreatedAt = new Date(latestConsultation?.createdAt);
    const differenceInTime = now.getTime() - latestConsultationCreatedAt.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    return [todayWeekday, differenceInDays, latestConsultationCreatedAt];
  }, [latestConsultation]);

  // // update message near header
  // useEffect(() => {
  //   if (data && data.addCheckinToActivities) {
  //     setReturnMessage(data.addCheckinToActivities.returnMessage);
  //     setTimeout(() => {
  //       setReturnMessage(undefined);
  //     }, 3000);
  //   }
  // }, [data]); // <-- only update when data is changed

  const thisWeek = useMemo(() => {
    return startDiffDay <= 0 && startDiffDay >= -6;
  }, [startDiffDay]);

  const handleSetDate = useMemo(
    () => (current: number, index: number) => {
      setDaysDiffToday(current);
      setSelectedDay(index);
    },
    [],
  );

  const showActivityDetail = useMemo(
    () => (tsIndex: number, acIndex: number) => {
      setOpen(true);
      setAcIndex(acIndex);
      setTsIndex(tsIndex);
    },
    [],
  );

  const emptyState = useMemo(() => {
    return planActivity.length === 0;
  }, [planActivity]);

  return (
    <>
      {acIndex >= 0 && (
        <ActivityModal
          open={open}
          setOpen={setOpen}
          activity={{ ...planActivity[selectedDay][tsIndex][acIndex] }}
          handleActivityCheckin={handleActivityCheckin}
          currentWeekday={currentWeekday}
        />
      )}
      <TitleAction
        thisWeek={thisWeek}
        currentWeekday={currentWeekday}
        startDiffDay={startDiffDay}
        dateRange={dateRange}
        editMode={editMode}
        setStartDiffDay={setStartDiffDay}
        handleSetDate={handleSetDate}
      />
      <DailyPlanCalendar
        planActivity={planActivity}
        daysDiffToday={daysDiffToday}
        startDiffDay={startDiffDay}
        editMode={editMode}
        planBeginDate={planBeginDate}
        handleSetDate={handleSetDate}
      />
      <ActivityListContainer>
        {!emptyState && (
          <DailyActivity
            childId={childId}
            selectedDayPlan={planActivity[selectedDay]}
            currentWeekday={currentWeekday}
            daysDiffToday={daysDiffToday}
            thisWeek={thisWeek}
            editMode={editMode}
            handleViewAll={handleViewAll}
            setEditMode={setEditMode}
            showActivityDetail={showActivityDetail}
            handleActivityCheckin={handleActivityCheckin}
          />
        )}
        {emptyState && (
          <EmptyStateContainer>
            <EmptyStateImg src={EmptyActivity} />
            <EmtpyStateTitle>{t('client.task.activityTab.emptyTitle')}</EmtpyStateTitle>
            <EmtpyStateContent>{t('client.dashboard.goal.emptyStateContent')}</EmtpyStateContent>
          </EmptyStateContainer>
        )}
      </ActivityListContainer>
    </>
  );
};

export default React.memo(ActivityPlan);
