import gql from 'graphql-tag';
import { Message } from '../../types';

export const GET_ROOM_MESSAGES_QUERY = gql`
  query GetRoomMessagesByType($roomId: ID!, $messageType: String) {
    getRoomMessagesByType(roomId: $roomId, messageType: $messageType) {
      id
      body
      createdAt
      user {
        id
        name
      }
      avatarUrl
      messageFileUrl
      messageFileContentType
      meta {
        __typename
        ... on DirectMessage {
          id
        }
        ... on HomeworkSubmission {
          id
          homework {
            id
            description
          }
        }
      }
      replies {
        id
        message {
          id
          body
          user {
            id
            name
          }
          avatarUrl
          replyMessageFileUrl
          replyMessageFileContentType
          createdAt
        }
      }
      homeworkUrl
      homeworkContentType
    }
  }
`;

export interface Data {
  getRoomMessagesByType: Message[];
}

export interface Variables {
  roomId: number;
  messageType?: string;
}
