import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider } from 'semantic-ui-react';
import CourseLink from './courseLink';
import { CollapsibleHeaderText } from '../styles';
import { ClientContext } from '../../ClientContext';
import CollapsibleContent from './CollapsibleContent';
import ReactHtmlParser from 'react-html-parser';
import { SectionTitle, SmallContentTitle, SmallDescription, SmallSectionTitle, UserComment } from '../../../../style';
import { TitleFlexBox } from '../../../common/SectionActionTitle/styles';
import { momentToTzDate } from '../../../../constants/timezoneConversion';

const Course = () => {
  const { t } = useTranslation();
  const { courseSections, client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const isIcc = child.product.productTypesName === 'peaICC' ? true : false;

  const sortCourse = useMemo(() => {
    courseSections.sort((a, b) => {
      return a.order - b.order;
    });
    return courseSections.slice(0, child.currentCourse);
  }, [courseSections]);

  const openIndex = useMemo(() => {
    let nearestDate = -1;
    const dateToCheckFor = moment().format('YYYY/MM/DD');
    sortCourse.forEach((c1, index1) => {
      const datesToBeChecked = moment(c1.startTime).format('YYYY/MM/DD');

      let diff = moment(datesToBeChecked).diff(dateToCheckFor, 'days');
      if (diff <= 0) {
        nearestDate = index1;
      }
    });
    return nearestDate;
  }, [sortCourse]);

  const [ordinalArray] = useMemo(() => {
    const ordinal = courseSections.map((c) => c.courseLinks.length);
    const ordinalArray = [] as number[];
    let count = 0;
    for (let i = 0; i < ordinal.length; i++) {
      ordinalArray.push(count);
      count += ordinal[i];
    }
    return [ordinalArray];
  }, [courseSections]);

  const show = sortCourse.map((c, index) => {
    const availableTime = moment(c.startTime).isBefore();
    const sortCourseLinks = c.courseLinks.sort((a, b) => {
      return a.order - b.order;
    });
    const links = sortCourseLinks.map((cl, index2) => {
      return (
        <CourseLink
          cIndex={index}
          clIndex={index2}
          key={index + '-' + index2}
          courseLink={cl}
          timeStatus={availableTime}
          selected={false}
          ordinal={ordinalArray[index] + index2 + 1}
        />
      );
    });
    const desString = c.description ? ReactHtmlParser(c.description) : <>{t('client.course.noCourseDes')}</>;
    return (
      <div>
        <CollapsibleContent
          defaultOpen={openIndex === index}
          show={true}
          header={
            <CollapsibleHeaderText>
              <SmallSectionTitle>
                {t(`client.course.videoList.${index}`)}
                {c.title}
              </SmallSectionTitle>
              <SmallDescription>
                {momentToTzDate(
                  moment(child.startServiceAt).add(14 * (c.order - 1) * (isIcc ? 2 : 1), 'days'),
                  client.timeZone,
                  client.user.locale,
                )}
              </SmallDescription>
            </CollapsibleHeaderText>
          }
          content={
            <div>
              <SmallContentTitle>{t('client.course.courseTeacherTitle')}</SmallContentTitle>
              <UserComment>{c.lecturer}</UserComment>
              <SmallContentTitle>{t('client.course.courseDesTitle')}</SmallContentTitle>
              <UserComment>{desString}</UserComment>
              <Divider />
              {links}
            </div>
          }
        />
      </div>
    );
  });
  return (
    <>
      <TitleFlexBox>
        <SectionTitle style={{ marginBottom: '0' }}>{t('client.course.title')}</SectionTitle>
      </TitleFlexBox>
      {show}
    </>
  );
};

export default Course;
