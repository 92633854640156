import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Grid, Segment, Modal, Container } from 'semantic-ui-react';
import { ActivitiesSegmentImage, FlexContainer, ViewMoreButton } from './styles';
import {
  Client_client_children_goals,
  Client_client_children_goals_preskills,
  Client_client_children_goals_assignedActivities,
} from '../../../../graphql/queries/types/Client';
import { useTranslation } from 'react-i18next';
import {
  ContentTitle,
  Description,
  GeneralSegment,
  H2Title,
  SmallDescription,
  SmallSectionTitle,
} from '../../../../style';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

interface Props {
  goals: Array<Client_client_children_goals>;
  productType: string;
}

const ClientGoalsCards = ({ goals, productType }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [goal, setGoal] = useState<Client_client_children_goals>(goals[0]);

  const handleClick = (goal: Client_client_children_goals) => {
    setOpen(true);
    setGoal(goal);
  };
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  const goalRender = goals.reverse().map((goal: Client_client_children_goals, index: number) => {
    const goalPercentage = (goal.currentNumerator - goal.initNumerator) / (goal.successNumerator - goal.initNumerator);
    const goalPercentageNumber = isNaN(goalPercentage) ? 0 : goalPercentage;
    const goalPercentageWidth = Math.round((goalPercentageNumber <= 1 ? goalPercentageNumber : 1) * 100);

    return (
      <Grid.Column key={goal.id} style={{ margin: '5px 0px', cursor: 'pointer' }} onClick={() => handleClick(goal)}>
        <GeneralSegment>
          <FlexContainer>
            <div>
              <ContentTitle>
                {/* {`${t('client.program.tasksGoalsCard.goal.goal')} : ${goal.skill.category.name}`} */}
                {goal.skill.category.name}
              </ContentTitle>
              <SmallDescription>
                {/* {t('client.program.tasksGoalsCard.goalDescription')} */}
                <span data-cy="goalDescription">{goal.description}</span>
              </SmallDescription>
            </div>
            <ViewMoreButton>
              {t('client.program.viewMore')}
              <Icon name="caret right" style={{ marginLeft: 3 }} />
            </ViewMoreButton>
          </FlexContainer>
          {isCPC && (
            <div style={{ margin: '15px 0 -5px 0', display: 'flex', justifyContent: 'start' }}>
              <div
                style={{
                  height: '1rem',
                  backgroundColor: 'grey',
                  borderRadius: '1rem',
                  display: 'inline-block',
                  width: '80%',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    height: '100%',
                    backgroundColor: '#47E4C2',
                    marginBottom: '1pt',
                    borderRadius: '1rem',
                    width: `${goalPercentageWidth}%`,
                    color: 'white',
                  }}
                ></div>
              </div>
              <p style={{ fontSize: '12px', color: '#00b5ad', fontWeight: 'bold', marginLeft: '5px' }}>
                {`${Math.round(goalPercentageNumber * 100)}%`}
              </p>
            </div>
          )}
        </GeneralSegment>
      </Grid.Column>
    );
  });
  const showGoals = () => {
    const modalGoalPercentage =
      (goal.currentNumerator - goal.initNumerator) / (goal.successNumerator - goal.initNumerator);
    const modalGoalPercentageNumber = isNaN(modalGoalPercentage) ? 0 : modalGoalPercentage;
    const modalGoalPercentageWidth = Math.round((modalGoalPercentageNumber <= 1 ? modalGoalPercentageNumber : 1) * 100);
    return (
      <>
        <Grid columns={2} stackable>
          <Grid.Row>{goalRender}</Grid.Row>
        </Grid>
        <Modal open={open} centered={false} onClose={() => setOpen(false)} closeIcon>
          <Modal.Header style={{ background: 'linear-gradient(45deg, #58D1D0 0%, #63E2B7 100%)' }}>
            <H2Title>{t('client.program.tasksGoalsCard.goal.popup.goalProgressDetail')}</H2Title>
          </Modal.Header>
          <Modal.Content>
            <Container style={{ padding: '5px 30px' }}>
              <div style={{ margin: '25px 3px' }}>
                <SmallSectionTitle style={{ borderLeft: '5px solid #47E4C2', paddingLeft: '10px' }}>
                  {t('client.program.tasksGoalsCard.goal.popup.goalProgress')}
                </SmallSectionTitle>
                <Segment>
                  <Description>
                    {t('client.program.tasksGoalsCard.goal.popup.goalCategory')}
                    {goal.skill.category.name}
                  </Description>
                  <Description>
                    {t('client.program.tasksGoalsCard.goal.popup.goalDescription')} {goal.description}
                  </Description>
                  {isCPC && (
                    <div style={{ margin: '15px 0 -5px 0', display: 'flex', justifyContent: 'start' }}>
                      <div style={{ margin: '15px 0 -5px 0', display: 'flex', justifyContent: 'start' }}>
                        <div style={{ display: 'flex', justifyContent: 'start', fontSize: '12px' }}>
                          <p style={{ paddingRight: '6px', borderRight: '2px solid black', height: '80%' }}>
                            {`${t('client.program.tasksGoalsCard.goal.currentProgress')} ${goal.currentNumerator} / ${
                              goal.currentDenominator
                            }`}
                          </p>
                          <p style={{ paddingLeft: '6px' }}>
                            {`${t('client.program.tasksGoalsCard.goal.objective')} ${goal.successNumerator} / ${
                              goal.successDenominator
                            }`}
                          </p>
                        </div>
                        <div
                          style={{
                            height: '1rem',
                            backgroundColor: 'grey',
                            borderRadius: '1rem',
                            display: 'inline-block',
                            width: '120px',
                            marginLeft: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                              height: '100%',
                              backgroundColor: '#47E4C2',
                              marginBottom: '1pt',
                              borderRadius: '1rem',
                              width: `${modalGoalPercentageWidth}%`,
                              color: 'white',
                            }}
                          ></div>
                        </div>
                        <p style={{ fontSize: '12px', color: '#00b5ad', fontWeight: 'bold', marginLeft: '5px' }}>
                          {`${Math.round(modalGoalPercentageNumber * 100)}%`}
                        </p>
                      </div>
                    </div>
                  )}
                </Segment>
              </div>
              <div style={{ margin: '25px 3px' }}>
                <SmallSectionTitle style={{ borderLeft: '5px solid #47E4C2', paddingLeft: '10px' }}>
                  {t('client.program.tasksGoalsCard.goal.popup.preskills')}
                </SmallSectionTitle>
                {goal.preskills.map((preskill: Client_client_children_goals_preskills) => (
                  <li style={{ fontSize: '1.4rem' }} key={preskill.id}>
                    {preskill.name}
                  </li>
                ))}
              </div>
              <div style={{ margin: '25px 3px' }}>
                <SmallSectionTitle style={{ borderLeft: '5px solid #47E4C2', paddingLeft: '10px' }}>
                  {t('client.program.tasksGoalsCard.goal.popup.activitiesIncluded')}
                </SmallSectionTitle>

                {goal.assignedActivities.map(
                  (activity: Client_client_children_goals_assignedActivities, index: number) => (
                    <Segment key={activity.id}>
                      <Grid columns={2}>
                        <Grid.Column mobile={5} tablet={2} computer={2}>
                          <ActivitiesSegmentImage style={{ width: '60px' }} src={activity.mediaUrl || undefined} />
                        </Grid.Column>
                        <Grid.Column mobile={11} tablet={14} computer={14} style={{ fontSize: '12px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0' }}>{`Activity #${index + 1}`}</p>
                          <p>{activity.summary}</p>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  ),
                )}
              </div>
            </Container>
          </Modal.Content>
        </Modal>
      </>
    );
  };

  return <div>{goals.length > 0 ? showGoals() : t('client.program.tasksGoalsCard.goal.noGoal')}</div>;
};

export default ClientGoalsCards;
