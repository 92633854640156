import gql from 'graphql-tag';

export const EDIT_CLIENT_INFORMATION = gql`
  mutation EditClientInformation($clientId: ID!, $attribute: String!, $value: String!) {
    editClientInformation(clientId: $clientId, attribute: $attribute, value: $value) {
      client {
        id
        timeZone
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
