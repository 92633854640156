import React, { useRef, useEffect, useState } from 'react';
import {
  DropDownContainer,
  DropDownHeader,
  HeaderText,
  DropDownListContainer,
  DropDownList,
  Arrow,
  Placeholder,
} from './styles';
import Item, { optionType } from './Item';

type Props = {
  size?: 'small' | 'medium' | 'large';
  type?: string;
  selectedText: string;
  options: Array<optionType>;
  placeHolder?: string;
  handleOptionChange: (value: string) => void;
};

const DropDown = ({ size, type, placeHolder, selectedText, options, handleOptionChange }: Props) => {
  const toggling = (): void => setToggle(!toggle);

  const onOptionClicked = (option: optionType) => {
    const { value } = option;
    setToggle(false);
    handleOptionChange(value);
  };

  const dropdownRef = useRef() as React.RefObject<HTMLDivElement>;
  const [toggle, setToggle] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && dropdownRef.current != null && !dropdownRef.current.contains(event.target as Node)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropDownContainer id={type} size={size} ref={dropdownRef}>
      <DropDownHeader onClick={toggling}>
        {selectedText === '' ? <Placeholder>{placeHolder}</Placeholder> : <HeaderText>{selectedText}</HeaderText>}
        <Arrow className="angle down" isOpen={toggle} />
      </DropDownHeader>
      {toggle && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option: optionType, index: number) => (
              <Item option={option} index={index} onOptionClicked={onOptionClicked} />
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
