import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalContent, ModalHeader, ModalMask } from '../../../../../../../style';
import { MainColor } from '../../../../styles';
import SVG from 'react-inlinesvg';

export const CountWordModalMask = styled(ModalMask)``;

export const CountWordModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)`
  text-align: center;
`;

export const ButtonContainer = styled(ModalButtonContainer)``;

export const CountWordModalContent = styled(ModalContent)`
  text-align: center;
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;
export const CountButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0 4px;
  border: 1.5px solid ${MainColor};
  border-radius: 8px;
  font-size: 22px;
  font-family: Times New Roman;
  font-weight: bold;
  color: ${MainColor};
  cursor: pointer;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  font-weight: 500;
  width: 55px;
  height: 40px;
`;

export const AddCountImg = styled(SVG)`
  width: inherit;
  margin-top: -58px;
  height: 90px;
`;
