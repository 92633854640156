import React from 'react';
import {
  UserWithResetPasswordToken as Data,
  UserWithResetPasswordTokenVariables as Variables,
} from '../../../../graphql/queries/types/UserWithResetPasswordToken';
import { USER_WITH_RESET_PASSWORD_TOKEN } from '../../../../graphql/queries/userWithResetPasswordToken';
import { useQuery } from 'react-apollo';
import ResetPasswordComponent from '../../ResetPassword';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import ExpiredToken from './ExpiredToken';

interface Props {
  token: string;
}

const Token = ({ token }: Props) => {
  const { loading, error, data } = useQuery<Data, Variables>(USER_WITH_RESET_PASSWORD_TOKEN, { variables: { token } });
  const { t } = useTranslation();
  if (loading) {
    return <p>{t('system.loading')}</p>;
  }

  if (error || !data || !data.userWithResetPasswordToken) {
    return <ExpiredToken />;
  }
  const { id, locale } = data.userWithResetPasswordToken;

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }

  return <ResetPasswordComponent userId={id} locale={locale} />;
};

export default Token;
