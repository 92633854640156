import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { DeleteWordModalMask, DeleteWordModalContainer, Header, ButtonContainer, DeleteButton } from './styles';
import {
  DeleteWordsData,
  DeleteWordsVariables,
  DELETE_WORDS_MUTATION,
} from '../../../../../../../graphql/mutations/deleteWords';
import { HeaderTitle, CancelButton, ActionTitle } from '../../../../../../../style';
import { WordTracking, WordTrackingVariables } from '../../../../../../../graphql/queries/types/WordTracking';
import { CHILD_WORD_TRACKING_QUERY } from '../../../../../../../graphql/queries/childTrackingWords';
import { ClientContext } from '../../../../../ClientContext';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  wordIds: number[];
};

const DeleteWordModal = ({ showModal, wordIds, handleClose, handleDelete }: Props) => {
  const { t } = useTranslation();
  const { child } = useContext(ClientContext);
  const [deleteWord] = useMutation<DeleteWordsData, DeleteWordsVariables>(DELETE_WORDS_MUTATION, {
    update(cache, { data }) {
      const wordData = cache.readQuery<WordTracking, WordTrackingVariables>({
        variables: { childId: child.id },
        query: CHILD_WORD_TRACKING_QUERY,
      });
      if (wordData != null && data != null) {
        wordData.words = wordData.words.filter(({ id }) => !wordIds.includes(id));
        cache.writeQuery<WordTracking, WordTrackingVariables>({
          variables: { childId: child.id },
          query: CHILD_WORD_TRACKING_QUERY,
          data: wordData,
        });
        handleDelete();
      }
    },
  });

  const onSubmitClick = () => {
    deleteWord({
      variables: {
        wordIds,
      },
    });
  };

  return (
    <DeleteWordModalMask open={showModal}>
      <DeleteWordModalContainer>
        <Header>
          <HeaderTitle>{t('client.wordTracking.modal.delete.title')}</HeaderTitle>
        </Header>
        <ButtonContainer>
          <CancelButton onClick={() => handleClose()}>
            <ActionTitle>{t('client.wordTracking.modal.delete.close')}</ActionTitle>
          </CancelButton>
          <DeleteButton onClick={onSubmitClick}>
            <ActionTitle>{t('client.wordTracking.modal.delete.submit')}</ActionTitle>
          </DeleteButton>
        </ButtonContainer>
      </DeleteWordModalContainer>
    </DeleteWordModalMask>
  );
};

export default DeleteWordModal;
