import React from 'react';
import { useLocation, Redirect } from 'react-router';
import Token from './components/Token';

const ResetPasswordToken = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('r_token');
  if (!token) {
    return <Redirect to="/" />;
  }

  return <Token token={token} />;
};

export default ResetPasswordToken;
