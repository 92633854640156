import logo from '../../../assets/Logo_Icon.png';
import { Viewer_viewer_deviceNotification } from '../../../graphql/queries/types/Viewer';
import { ValidURL } from '../../../util/ValidUrl';

const ShowNotification = (notifiction: Viewer_viewer_deviceNotification) => {
  const { description, title, url, id } = notifiction;
  const notifyConfig = {
    body: description,
    icon: logo,
  };
  new Notification(title, notifyConfig);
  const notify = new Notification(title, notifyConfig);
  if (url !== null && ValidURL(url)) {
    notify.onclick = function (e) {
      e.preventDefault();
      window.open(url);
    };
  }
};

export default ShowNotification;
