import styled from 'styled-components';

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  background: red;
`;
export const Container = styled.div`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 25px 25px 30px;
  margin-bottom: 15px;
  text-align: left;
`;

export const WCArrowImgHolder = styled.img`
  margin-left: 85vw;
  animation: 1s containerMoveDown ease-in-out 0s infinite alternate;

  @keyframes containerMoveDown {
    from {
      transform: translateY(-48vh);
    }
    to {
      transform: translateY(-55vh);
    }
  }
`;

export const LineArrowImgHolder = styled.img`
  margin-left: 85vw;
  animation: 1s containerMoveDown ease-in-out 0s infinite alternate;
  @keyframes containerMoveDown {
    from {
      transform: translateY(10vh);
    }
    to {
      transform: translateY(20vh);
    }
  }
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const AlertText = styled.div`
  font-size: 18px;
  color: #2ab2ac;
  margin-bottom: 15px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const InstructionText = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const ImgContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const InstructionContentImg = styled.img`
  src: ${(props) => props.src};
  width: 240px;
  min-width: 200px;
  margin: 0px 10px;
  @media only screen and (max-width: 800px) {
    margin: 0 0 20px 0;
  }
`;

// notification
export const Notification = styled.div`
  display: flex;
  left: 16px;
  bottom: 10px;
  height: 68px;
  position: fixed;
  padding: 16px 10px;
  border-radius: 8px;
  min-width: 288px;
  background-color: #242526;
  color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 998;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 280ms;
  transition-timing-function: cubic-bezier(0.14, 1, 0.34, 1);
  opacity: 1;
`;
export const NotificationContent = styled.div<{ status: boolean }>`
  color: ${({ status }) => (status ? '#52c41a' : '#ff4d4f')};
  display: flex;
  align-items: center;
`;
export const IconContainer = styled.div`
  padding: 0 6px;
`;

export const ContainerWidth = styled.div`
  width: 300px;
  @media only screen and (min-width: 768px) {
    width: 330px;
  }
  @media only screen and (min-width: 992px) {
    width: 360px;
  }
  @media only screen and (min-width: 1200px) {
    width: 400px;
  }
`;
export const ModalHeader = styled(ContainerWidth)`
  margin-bottom: 20px;
  text-align: center;
`;

export const H2Title = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  color: #030303;
`;

export const HeaderTitle = styled(H2Title)`
  color: #6898e3;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

  @media (min-width: 768px) {
    text-align: center;
  }
`;

export const ModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000099;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
`;
export const ModalContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background-color: white;
  border-radius: 15px;
  height: max-content;
  max-height: 95vh;
  overflow: auto;
  width: max-content;
  position: relative;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 50px;
  }
`;
export const ModalContainerWithClose = styled.div`
  height: max-content;
  width: max-content;
  padding: 40px 30px;
  margin: 2rem;
  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 50px;
  }
`;

export const ModalContent = styled(ContainerWidth)`
  overflow: auto;
  overflow-y: hidden;
  max-height: 400px;
`;

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: #606060;
`;

export const ContentText = styled(Description)`
  color: #030303;
  text-align: center;
  font-size: 16px;
  line-height: normal;

  @media (min-width: 768px) {
    text-align: center;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 50px;
  padding: 0 40px 0 40px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;
// export const OptionButton = styled(ButtonStyle)`
export const OptionButton = styled(ButtonStyle)<{ clicked: boolean }>`
  color: #333333;
  background: white;
  border: solid 1px #333333;
  border-radius: 12px;
  margin: 0 16px;
  width: 140px;
  border: ${({ clicked }) => (clicked ? 'solid 3px #63e2b7' : 'solid 1px #333333')};
  // font-weight: ${({ clicked }) => (clicked ? '900' : 'normal')};
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const ShowErrNextButton = styled(ButtonStyle)`
  color: teal;
  background: white;
  border: solid 1px teal;
  border-radius: 50px;
  margin-right: 20px;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const NextButton = styled(ButtonStyle)`
  background: #f7c042;
  pointer-events: cursor;
  color: white;
  border-radius: 50px;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.007px;
`;

export const LogOutButton = styled.button`
  border: none;
  margin: 5px;
  border-radius: 20px;
  color: white;
  background: #2da5ff;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  @media only screen and (min-width: 750px) {
    font-size: 1.5rem;
    margin: 0 1rem;
  }
`;

export const ProfilePageLogOutButton = styled.button`
  border: none;
  margin: 5px;
  border-radius: 20px;
  color: white;
  background: #2da5ff;
  font-size: 1.3rem;
  padding: 1.2rem 10rem;
  cursor: pointer;
  width: 100%;
  @media only screen and (min-width: 750px) {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;

export const AccountLabel = styled.div`
  font-size: 1.2rem;
  color: #f5f5f5;
  margin: 0 0.4rem;
  @media only screen and (min-width: 750px) {
    font-size: 1.5rem;
    margin: 0 1rem;
  }
`;

export const NavbarItemsContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column-start: 2;
  justify-content: flex-end;
`;

export const NavbarContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  background: linear-gradient(45deg, #58d1d0 0%, #63e2b7 100%);
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;
`;
export const NavbarLogoContainer = styled.div`
  grid-column-start: 1;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  @media only screen and (min-width: 750px) {
    padding-left: 5rem;
  }
`;

export const NavbarLogo = styled.img`
  cursor: pointer;

  @media only screen and (max-width: 450px) {
    width: 120px;
  }
`;
