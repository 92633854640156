import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ModalMask, Container, Title } from './styles';
import { SubmitButton, BigModalContainer, ModalTitle, ModalButton } from '../Client/ConsentFormCPCRoute/styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

//backend
import { ClientContext } from '../Client/ClientContext';
import { useQuery } from 'react-apollo';

import { Child } from '../../types';
import { CHILD_QUERY } from '../../graphql/queries/child';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../util/Product';

const ConsentFormModal = () => {
  const [showModal, setshowModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  //Checks if the consent form has been completed, if yes
  const { client, setChild } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);
  const { loading, error, data } = useQuery(CHILD_QUERY, {
    variables: { child_id: child.id },
  });

  //sends user back to consent form page
  const handleGoToConsentForm = () => {
    setshowModal(false);
    if (isCPC) {
      goToConsentFormCPC();
    } else if (isPEA) {
      goToConsentFormPEA();
    } else {
      //TODO Return error message if not pea or cpc
    }
  };

  useEffect(() => {
    if (!child.consentFormCompleted) {
      setshowModal(true);
    }
  }, [child.consentFormCompleted]);

  const goToConsentFormCPC = useCallback(() => {
    history.push('/consentFormCPC');
  }, [history]);

  const goToConsentFormPEA = useCallback(() => {
    history.push('/consentFormPEA');
  }, [history]);

  return (
    <>
      {showModal && (
        <ModalMask style={{ backgroundColor: '#333333' }} open={true}>
          <BigModalContainer>
            <ModalTitle style={{ color: 'black', fontSize: '22px' }}>
              {t('onboarding.consentForm.dashboardmodalTitle')}
            </ModalTitle>
            <ModalButton style={{ marginTop: '16px' }} onClick={handleGoToConsentForm}>
              {t('onboarding.consentForm.dashboardmodalButton')}
            </ModalButton>
          </BigModalContainer>
        </ModalMask>
      )}
    </>
  );
};

export default ConsentFormModal;
