import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lite_product_qna_sessions } from '../../../../graphql/queries/types/Client/Lite';
import QnaLink from './qnaLink';
import { ClientContext } from '../../ClientContext';
import TabMenu from '../../../common/TabMenu';

interface Props {
  forceUpdate: number;
}

const Qna = ({}: Props) => {
  window.scroll(0, 0);
  const { t } = useTranslation();
  const [selection, setSelection] = useState(0);
  const { child, sessions } = useContext(ClientContext);

  const newSessions = useMemo(() => {
    sessions.sort((a, b) => {
      return a.order - b.order;
    });
    return sessions.slice(child.currentCourse - 1, sessions.length);
  }, [sessions]);
  const oldSessions = useMemo(() => {
    sessions.sort((a, b) => {
      return a.order - b.order;
    });
    return sessions.slice(0, child.currentCourse - 1);
  }, [sessions]);

  // get latest Session
  const latestSession =
    newSessions.length > 0
      ? newSessions.reduce((a: Lite_product_qna_sessions, c: Lite_product_qna_sessions): Lite_product_qna_sessions => {
          return a.order < c.order ? a : c;
        }, newSessions[0])
      : null;

  const showNextQA = latestSession ? (
    <QnaLink qnaSession={latestSession} currentStatus={'latest'} />
  ) : (
    <div>{t('client.qnaSession.noUpComing')}</div>
  );

  // get old Sessions
  const showOldSessions =
    oldSessions && oldSessions.length > 0 ? (
      oldSessions.map((l, index) => {
        return <QnaLink key={index} qnaSession={l} currentStatus={'old'} />;
      })
    ) : (
      <div>{t('client.qnaSession.noHistory')}</div>
    );

  // get future Sessions
  const showFutureSessions = latestSession ? (
    newSessions
      .filter((l) => l.id !== latestSession.id)
      .map((l, index) => {
        return <QnaLink key={index} qnaSession={l} currentStatus={'future'} />;
      })
  ) : (
    <></>
  );

  const tabTitles = [t('client.qnaSession.upComing'), t('client.qnaSession.history')];

  const tabContents = [
    <>
      {showNextQA}
      {showFutureSessions}
    </>,
    <>{showOldSessions}</>,
  ];

  return (
    <TabMenu tabTitles={tabTitles} tabContents={tabContents} selection={selection} handleTabClick={setSelection} />
  );
};

export default Qna;
