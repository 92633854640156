import styled from 'styled-components';
import { GeneralSegment } from '../../../style';

export const Container = styled.div`
  @media only screen and (max-width: 575px) {
    padding: 0 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 20%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 20%;
  }
`;

export const OptionSegment = styled(GeneralSegment)`
  max-height: fit-content;
  margin: 15px 0 30px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
