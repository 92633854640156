import gql from 'graphql-tag';

export const THERAPIST_CONSULTATIONS_QUERY = gql`
  query Therapist($therapistId: ID!) {
    therapist(therapistId: $therapistId) {
      id
      user {
        id
        locale
        name
        email
      }
      consultationUrl
      cnConsultationUrl
      consultations {
        id
        consultationTime
        smsLogs {
          id
          userId
          consultationId
          details
          createdAt
        }
        child {
          id
          client {
            id
            user {
              id
              metaId
              name
              email
              country
            }
          }
          name
          gender
          dob
          previousConsultation {
            id
            consultationTime
            notes {
              id
              mainComplaint
            }
          }
        }
        canceled
        accepted
        published
        uploadedVideos {
          id
          consultation {
            id
          }
          uploadedVideoName
          isDownloaded
        }
      }
    }
  }
`;
