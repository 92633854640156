import React, { useState } from 'react';
import { ConfigProvider, Empty, Table, Tooltip } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import type { TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CategoryBadge } from '../TasksRoute/styles';
import { findCatOrderIndexByCatId } from '../../../../../util/Category';
import { PlanActivityObj } from '../../TherapistChildContext';
import { useTranslation } from 'react-i18next';
import { CategoryOption } from '../../utils';

interface Props {
  activityList: PlanActivityObj[];
  onSelect: (activityId: string) => void;
}

const ActivityTable = ({ activityList, onSelect }: Props) => {
  const { t } = useTranslation();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<PlanActivityObj>>({});
  const handleChange: TableProps<PlanActivityObj>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<PlanActivityObj>);
  };

  // table columns
  const title: ColumnType<PlanActivityObj> = {
    title: t('therapist.childInfo.activity.general.activity'),
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
    render: (activity: PlanActivityObj) => (
      <Tooltip placement="topLeft" title={activity.name}>
        {activity.name}
      </Tooltip>
    ),
    fixed: 'left',
  };

  const checkIn: ColumnType<PlanActivityObj> = {
    title: t('therapist.childInfo.activity.general.activityTable.totalCheckIns'),
    key: 'allTimeCheckIn',
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => a.allTimeCheckInCount - b.allTimeCheckInCount,
    sortOrder: sortedInfo.columnKey === 'allTimeCheckIn' ? sortedInfo.order : null,
    render: (activity: PlanActivityObj) => (
      <Tooltip placement="topLeft" title={activity.allTimeCheckInCount}>
        {activity.allTimeCheckInCount}
      </Tooltip>
    ),
    width: 200,
  };

  const mainSkill: ColumnType<PlanActivityObj> = {
    title: t('therapist.childInfo.activity.general.mainSkill'),
    ellipsis: {
      showTitle: false,
    },
    render: (activity: PlanActivityObj) => (
      <Tooltip placement="topLeft" title={activity.mainSkill.name}>
        {activity.mainSkill.name}
      </Tooltip>
    ),
    width: 240,
  };

  const category: ColumnType<PlanActivityObj> = {
    title: t('therapist.childInfo.activity.general.category'),
    key: 'category',
    filters: CategoryOption(),
    onFilter: (value, activity) => findCatOrderIndexByCatId(activity.mainSkill.category.id) === value,
    sorter: (a, b) =>
      findCatOrderIndexByCatId(a.mainSkill.category.id) - findCatOrderIndexByCatId(b.mainSkill.category.id),
    sortOrder: sortedInfo.columnKey === 'category' ? sortedInfo.order : null,
    render: (activity: PlanActivityObj) => (
      <CategoryBadge catIndex={findCatOrderIndexByCatId(activity.mainSkill.category.id)}>
        {activity.mainSkill.category.name}
      </CategoryBadge>
    ),
    width: 240,
  };

  // table columns setting
  const Columns: ColumnsType<PlanActivityObj> = [title, checkIn, mainSkill, category];

  // table empty state
  const customizeRenderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Activity</span>}></Empty>
  );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        className={'activityTable'}
        dataSource={activityList}
        columns={Columns}
        onChange={handleChange}
        pagination={false}
        onRow={(activity) => {
          return {
            onClick: () => {
              onSelect(activity.id);
            },
          };
        }}
        scroll={{ x: 1000 }}
      />
    </ConfigProvider>
  );
};

export default ActivityTable;
