import styled from 'styled-components';

export const UpdateMasteredModalText = styled.div`
  margin-top: 12px;
  font-size: 14px;
  text-align: center;
`;

export const UpdateMasteredModalTextSpan = styled.span`
  margin-top: 12px;
  color: #5ad2db;
`;

export const UpdateMasteredModalButtonContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  margin-top: 12px;
  line-height: 30px;
`;

export const UpdateMasteredModalButtonCancel = styled.div`
  width: 80px;
  height: 32px;
  margin: 0 10px;
  border: 1px solid #7fd6c4;
  box-sizing: border-box;
  border-radius: 20px;
  color: #5ad2db;
  cursor: pointer;
`;

export const UpdateMasteredModalButtonConfirm = styled.div`
  width: 80px;
  height: 32px;
  margin: 0 10px;
  background: #5ad2db;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
`;

export const GoalsCardSkillsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const GoalsCardSkillsTabContainer = styled.div<{ pointer: boolean }>`
  height: 30px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  display: flex;
  margin-right: 20px;
`;

export const GoalsCardSkillsTabText = styled.div<{ mastered: boolean }>`
  color: ${({ mastered }) => (mastered ? '#767676' : '#5AD2DB')};
`;

export const GoalsCardSkillsTabImg = styled.img<{ display: boolean }>`
  height: 20px;
  width: 20px;
  display: ${({ display }) => (display ? 'inherit' : 'none')};
  margin-left: 10px;
`;

// ProgressComponent

export const ProgressTitle = styled.div`
  display: flex;
  margin: 10px;
  justify-content: space-between;
`;

export const ProgressShowAchievedGoalButton = styled.div`
  width: 150px;
  height: 40px;
  background-color: #7fd6c4;
  display: flex;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 10px;
  justify-content: space-between;
  cursor: pointer;
`;

export const ProgressShowAchievedGoalText = styled.div`
  color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
`;

export const GoalsCardDeleteGoalDiv = styled.div`
  padding: 50px;
  min-height: 350px;
  background-color: white;
`;

export const GoalsCardDeleteGoalTitle = styled.div<{ goal?: boolean }>`
  font-size: 18px;
  color: ${({ goal }) => (goal ? '#7FD6C4' : 'inherit')};
  line-height: 25px;
`;

export const GoalsCardDeleteGoalSubTitle = styled.div`
  font-size: 14px;
  margin-top: 30px;
`;

export const GoalCardDeleteGoalButtonDiv = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const GoalCardDeleteGoalButton = styled.div<{ confirm?: boolean; reason?: boolean }>`
  width: ${({ reason }) => (reason ? '200px' : '150px')};
  height: 40px;
  background-color: ${({ confirm }) => (confirm ? '#7FD6C4' : '#E0E0E0')};
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  margin: auto;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const GoalCardAchieveGoalButton = styled.div`
  margin-left: 25%;
  @media only screen and (max-width: 600px) {
    margin-left: 35%;
  }
  width: 150px;
  height: 28px;
  background-color: #7ed6c4;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 5px;
`;
