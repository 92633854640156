import React from 'react';
import { ChevronIcon, PaginationContainer, ActionButton, DotButton } from './styles';

type Props = {
  pageCount: number;
  selectedItem: number;
  handlePageChange: (offset: number) => void;
};

const DotItem = () => {
  return (
    <DotButton active={false} onClick={() => {}}>
      ⋯
    </DotButton>
  );
};

const Pagination = ({ pageCount, selectedItem, handlePageChange }: Props) => {
  const handlePrevious = () => {
    const next = selectedItem - 1 >= 1 ? selectedItem - 1 : 1;
    handlePageChange(next);
  };
  const handleNext = () => {
    const next = selectedItem + 1 <= pageCount ? selectedItem + 1 : pageCount;
    handlePageChange(next);
  };

  if (pageCount <= 6) {
    return (
      <PaginationContainer>
        <ActionButton active={false} onClick={handlePrevious}>
          <ChevronIcon className="chevron left" size="small" />
        </ActionButton>
        {Array.from({ length: pageCount }, (_, x) => x + 1).map((item: number) => {
          return (
            <ActionButton onClick={() => handlePageChange(item)} active={item === selectedItem}>
              {item}
            </ActionButton>
          );
        })}
        <ActionButton active={false} onClick={handleNext}>
          <ChevronIcon className="chevron right" size="small" />
        </ActionButton>
      </PaginationContainer>
    );
  }

  let centerIndex = selectedItem;
  if (selectedItem < 1 + 3) {
    centerIndex = 4;
  } else if (selectedItem > pageCount - 3) {
    centerIndex = pageCount - 3;
  }

  return (
    <PaginationContainer>
      <ActionButton active={false} onClick={handlePrevious}>
        <ChevronIcon className="chevron left" size="small" />
      </ActionButton>
      <ActionButton active={selectedItem === 1} onClick={() => handlePageChange(1)}>
        {1}
      </ActionButton>
      {selectedItem < 3 + 1 ? (
        <ActionButton active={selectedItem === 2} onClick={() => handlePageChange(2)}>
          {2}
        </ActionButton>
      ) : (
        <DotItem />
      )}
      {[centerIndex - 1, centerIndex, centerIndex + 1].map((index: number) => (
        <ActionButton onClick={() => handlePageChange(index)} active={index === selectedItem}>
          {index}
        </ActionButton>
      ))}
      {selectedItem < pageCount - 3 ? (
        <DotItem />
      ) : (
        <ActionButton
          active={selectedItem === pageCount - 1}
          onClick={() => {
            handlePageChange(pageCount - 1);
          }}
        >
          {pageCount - 1}
        </ActionButton>
      )}
      <ActionButton active={selectedItem === pageCount} onClick={() => handlePageChange(pageCount)}>
        {pageCount}
      </ActionButton>
      <ActionButton active={false} onClick={handleNext}>
        <ChevronIcon className="chevron right" size="small" />
      </ActionButton>
    </PaginationContainer>
  );
};

export default Pagination;
