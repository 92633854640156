import React from 'react';
import mixpanel from 'mixpanel-browser';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Loader, Button } from 'semantic-ui-react';
import '../../index.css';
import Cookies from 'js-cookie';

import { LOGOUT_MUTATION, Data } from '../../graphql/mutations/logout';
import { JS_COOKIE } from '../../util/Cookie';
import ErrorPage from './ErrorPage';
import { LogOutButton, ProfilePageLogOutButton } from './styles';

const LogoutComponentProfilePage = () => {
  const [logout, { loading, error }] = useMutation<Data, {}>(LOGOUT_MUTATION);

  const { t } = useTranslation();

  const handleClick = async () => {
    await logout();
    JS_COOKIE.remove('token');
    document.cookie = 'X-CSRF-Token=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
    window.location.href = '/';
    mixpanel.track('logout');
  };

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  return <ProfilePageLogOutButton onClick={() => handleClick()}>{t('auth.logout')}</ProfilePageLogOutButton>;
};

export default LogoutComponentProfilePage;
