import React, { useState, FormEvent, useEffect } from 'react';
import {
  UserInfo,
  UserInfoText,
  UserInfoEditButton,
  SaveButton,
  CancelButton,
  InputField,
  ErrorText,
  EditContainer,
  ButtonGroup,
  EmailInputField,
} from '../styles';
import { useMutation } from 'react-apollo';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { ReturnMessage } from '../../../../types';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../../constants/config';

interface Props {
  userId: string;
  title: string;
  attribute: string;
  value: string;
  userUuid: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
}

const UserInfoComponent: React.FC<Props> = ({ userId, title, attribute, value, setReturnMessage, userUuid }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  const { t } = useTranslation();

  // Update message near header
  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    const timer = setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
    return () => clearTimeout(timer);
  }, [data, setReturnMessage]);

  const handleClickCancel = () => {
    setNewValue(value);
    setIsEdit(false);
    setEmailError(null);
  };

  const handleUpdateEval = async (email: string, userUuid: string) => {
    const token = JS_COOKIE.get('token');
    await axios.post(
      eval_backend_api_address + 'api/user/updateEmailFromGuide',
      { email, userUuid },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  };

  const validateEmail = (email: string) => {
    const pattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleClickSave = async () => {
    if (attribute === 'Email' && !newValue) {
      setEmailError(t('auth.emailNull'));
      return;
    } else if (attribute === 'Email' && !validateEmail(newValue)) {
      setEmailError(t('auth.login.invalidEmail'));
      return;
    }
    setEmailError(null);

    await editUserInformation({ variables: { userId, attribute, value: newValue } });
    await handleUpdateEval(newValue, userUuid);
    setIsEdit(false);
  };

  return (
    <UserInfo>
      <div>
        <UserInfoText>{title}</UserInfoText>
        {isEdit ? (
          <>
            <EditContainer>
              <EmailInputField
                type="text"
                value={newValue}
                onChange={(e: FormEvent<HTMLInputElement>) => setNewValue(e.currentTarget.value)}
                placeholder={t('auth.email')}
              />
            </EditContainer>
            {emailError && <ErrorText>{emailError}</ErrorText>}
          </>
        ) : (
          <p>{value}</p>
        )}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={handleClickCancel}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={handleClickSave}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={() => setIsEdit(true)}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default UserInfoComponent;
