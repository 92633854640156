import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import {
  UserInfo,
  UserInfoText,
  UserInfoEditButton,
  SaveButton,
  CancelButton,
  ButtonGroup,
  ErrorText,
  EmailInputField,
  Select,
  EditContainer,
  Label,
} from '../styles';
import { useMutation } from 'react-apollo';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { ReturnMessage } from '../../../../types';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CountryCodeList from './CountryCodeList';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../../constants/config';

interface Props {
  userId: string;
  userUuid: string;
  title: string;
  attribute: string;
  value: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
}

const PhoneComponent: React.FC<Props> = ({ userId, title, attribute, value, setReturnMessage, userUuid }) => {
  // Parsing the phone number and extracting country code and number
  const phone = parsePhoneNumberFromString(value);
  const country = phone && phone.country ? phone.country.toUpperCase() : 'US';
  const countryIndex = CountryCodeList.findIndex((c) => c.code === country.toUpperCase());
  const code = CountryCodeList[countryIndex]?.dial_code || '';
  const number = phone ? phone.nationalNumber.toString() : '';

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [numberValue, setNumberValue] = useState<string>(number);
  const [countryValue, setCountryValue] = useState<string>(code);
  const [validationError, setValidationError] = useState<string>('');
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  const dropdownRef = useRef<HTMLSelectElement>(null);

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    const timer = setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
    return () => clearTimeout(timer);
  }, [data, setReturnMessage]);

  useEffect(() => {
    if (dropdownRef.current) {
      const selectedOption = dropdownRef.current.options[dropdownRef.current.selectedIndex];
      const textWidth = getTextWidth(selectedOption.text, getComputedStyle(dropdownRef.current).font);
      // Increase the width of the dropdown to show the full country name and code in one line,
      // the code will be in the second line without this code,
      // but if the country name is too long, then it is normal to have the code in the second line.
      const increaseDropDownSetWidth = 40;
      setDropdownWidth(textWidth + increaseDropDownSetWidth);
    }
  }, [countryValue, isEdit]);

  const getTextWidth = (text: string, font: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }
    return 0;
  };

  const handleClickCancel = () => {
    setNumberValue(number);
    setCountryValue(code);
    setIsEdit(false);
    setValidationError('');
  };

  const handleUpdateEvalPhone = async (phone: string, userUuid: string) => {
    const token = JS_COOKIE.get('token');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updatePhoneFromGuide',
      { phone, userUuid },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return data;
  };

  const handleUpdateEvalCountryCode = async (countryCode: string, userUuid: string) => {
    const token = JS_COOKIE.get('token');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updateCountryCodeFromGuide',
      { countryCode, userUuid },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return data;
  };

  const validatePhoneNumber = (number: string): boolean => {
    const numberOnlyRegex = /^\d+$/;
    return numberOnlyRegex.test(number);
  };

  const handleClickSave = async () => {
    if (numberValue === '') {
      setValidationError(t('auth.phoneNumberNullMessage'));
      return;
    }

    if (!validatePhoneNumber(numberValue)) {
      setValidationError(t('auth.invalidPhoneNumber'));
      return;
    }

    setValidationError('');
    const finalPhone = countryValue + numberValue;
    await editUserInformation({ variables: { userId, attribute, value: finalPhone } });
    await handleUpdateEvalPhone(numberValue, userUuid);
    await handleUpdateEvalCountryCode(countryValue, userUuid);
    setIsEdit(false);
  };

  const handleClickEdit = () => {
    setIsEdit(true);
    setNumberValue(number); // Initialize numberValue without country code
    setCountryValue(code);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCountryValue(e.target.value);
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumberValue(e.target.value);
  };

  const countryCodeOptions = CountryCodeList.map((c) => (
    <option key={c.code} value={c.dial_code}>
      {c.name + ' '}
      {c.dial_code}
    </option>
  ));

  return (
    <UserInfo>
      <div>
        <UserInfoText>{title}</UserInfoText>
        {isEdit ? (
          <EditContainer>
            <Label>{t('auth.countryCode')}</Label>
            <Select ref={dropdownRef} value={countryValue} onChange={handleCountryChange} width={dropdownWidth}>
              {countryCodeOptions}
            </Select>
            <Label>{t('auth.phoneNumber')}</Label>
            <EmailInputField
              type="text"
              value={numberValue}
              onChange={handleNumberChange}
              placeholder={t('auth.phoneNumber')}
            />
          </EditContainer>
        ) : (
          <p>{value}</p>
        )}
        {validationError && <ErrorText>{validationError}</ErrorText>}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={handleClickCancel}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={handleClickSave}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={handleClickEdit}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default PhoneComponent;
