import React, { useContext } from 'react';
import { Grid, Segment, Icon } from 'semantic-ui-react';
import TherapistConsultationScreenSkillsComponent from './SkillsComponent';
import TherapistConsultationScreenGoalsCardComponent from './GoalsCardComponent';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { CATEGORIES_QUERY } from '../../../../graphql/queries/categories';
import { useQuery } from 'react-apollo';
import { Category, Category_categories } from '../../../../graphql/queries/types/Category';
import { Consultation_consultation_child_goals } from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  addGoal?: (goal: Consultation_consultation_child_goals) => void;
}

const TherapistConsultationScreenCategoriesComponent = ({ addGoal }: Props) => {
  const { category, setCategory, subCategory, setSubCategory, skill, setSkill } = useContext(
    TherapistConsultationScreenContext,
  );

  const { loading, error, data } = useQuery<Category, {}>(CATEGORIES_QUERY);
  const { t } = useTranslation();
  if (loading) {
    return <p>{t('system.loading')}</p>;
  }
  if (error || !data || !data.categories) {
    return <ErrorPage />;
  }
  const { categories } = data;

  return (
    <div>
      {category ? (
        <div>
          {skill && subCategory ? (
            <div>
              <TherapistConsultationScreenGoalsCardComponent
                goal={{
                  id: '',
                  description: '',
                  metric: '',
                  initNumerator: 0,
                  initDenominator: 0,
                  currentNumerator: 0,
                  currentDenominator: 0,
                  successNumerator: 0,
                  successDenominator: 0,
                  skill: skill,
                  preskills: [],
                  goalRecords: [],
                  goalPreskills: [],
                  achieved: false,
                }}
                subCategory={subCategory}
                category={category}
                isEdit={true}
                addGoal={addGoal}
              />
            </div>
          ) : (
            <TherapistConsultationScreenSkillsComponent
              category={category}
              setSkill={setSkill}
              setSubCategory={setSubCategory}
            />
          )}
        </div>
      ) : (
        <Segment textAlign="center">
          <h4>
            {t('therapist.consultationScreen.progress.chooseMainCategory')}
            <Icon name="angle down" color="teal" size="large"></Icon>
          </h4>
          <ul>
            <Grid columns={3} textAlign="left">
              {categories.map((c: Category_categories) => (
                <Grid.Column key={c.id}>
                  <li
                    onClick={() => setCategory(c)}
                    style={{ cursor: 'pointer', color: 'teal', fontWeight: 600 }}
                    data-cy="mainCategoryList"
                  >
                    {c.name}
                  </li>
                </Grid.Column>
              ))}
            </Grid>
          </ul>
        </Segment>
      )}
    </div>
  );
};

export default TherapistConsultationScreenCategoriesComponent;
