export interface SkillCategory {
  id: string;
  name: string;
  skills: Skill[];
}

export interface Skill {
  id: string;
  name: string;
  mastered: boolean;
  count: number;
}

export const getCatData = (skills, skillTrainedByActivitiesDict) => {
  const categories: SkillCategory[] = [];
  skills.forEach((skill) => {
    const categoryId = skill.skill.category.id;
    const categoryFoundId = categories.findIndex((c) => {
      return c.id === categoryId;
    });
    if (categoryFoundId === -1) {
      categories.push({
        id: categoryId,
        name: skill.skill.category.name,
        skills: [],
      } as SkillCategory);
    }
    const categoryArrayId = categoryFoundId !== -1 ? categoryFoundId : categories.length - 1;
    const skillId = skill.skill.id;
    categories[categoryArrayId].skills.push({
      id: skillId,
      name: skill.name,
      mastered: skill.mastered,
      count: skillTrainedByActivitiesDict[skillId] ? skillTrainedByActivitiesDict[skillId] : 0,
    } as Skill);
  });
  return categories;
};

export const getPlanCatData = (planSkill, skillTrainedByActivitiesDict) => {
  const categories: SkillCategory[] = [];
  planSkill.forEach((skill) => {
    const categoryId = skill.category.id;
    const categoryFoundId = categories.findIndex((c) => {
      return c.id === categoryId;
    });
    if (categoryFoundId === -1) {
      categories.push({
        id: categoryId,
        name: skill.category.name,
        skills: [],
      } as SkillCategory);
    }
    const categoryArrayId = categoryFoundId !== -1 ? categoryFoundId : categories.length - 1;
    const skillId = skill.id;
    categories[categoryArrayId].skills.push({
      id: skillId,
      name: skill.name,
      mastered: false,
      count: skillTrainedByActivitiesDict[skillId] ? skillTrainedByActivitiesDict[skillId] : 0,
    } as Skill);
  });
  return categories;
};

// 2: language expression
// 1: language comprehension
// 5: oral muscle
// 6: social communication
// 3: pre-literacy
// 4: Pre-Linguistic
// 7: parent implementation
export const catOrder = ['2', '1', '5', '6', '3', '4', '7'];
export const findCatOrderIndexByCatId = (catId: string): number => {
  return catOrder.findIndex((object) => {
    return object === catId;
  });
};

const customizedCategoryOrder = (categories, catIdMap) => {
  const result: SkillCategory[] = [];
  catOrder.forEach((o: string) => {
    const index = catIdMap[o];
    if (index != null) {
      result.push(categories[index]);
    }
  });
  if (result.length === 0) {
    result.push({ id: '0', name: '', skills: [] });
  }
  return result;
};

const getCatIdMap = (categories) => {
  const catIdMap = {};
  categories.forEach((_, i) => {
    const id = categories[i].id;
    catIdMap[id] = i;
  });
  return catIdMap;
};

export const getCategory = (categories) => {
  const catIdMap = getCatIdMap(categories);
  return customizedCategoryOrder(categories, catIdMap);
};
