import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';
import {
  Client_client_children_goals,
  Client_client_children_goals_assignedActivities,
} from '../../../../../../graphql/queries/types/Client';
import {
  SummaryCheckInCount,
  SummaryContainer,
  SummaryImage,
  SummaryMainSkill,
  SummaryMainSkillContainer,
  SummaryName,
  SummarySegment,
} from '../../../../TaskRoute/ActivityTab/ActivityModal/styles';
import { ActivityCardContainer } from './style';

interface Props {
  goal: Client_client_children_goals;
}

const ActivityCard = ({ goal }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (id: string) => {
    localStorage.setItem('activityId', id);
    history.push('/task/activity');
  };
  return (
    <ActivityCardContainer>
      {goal.assignedActivities.map((ac: Client_client_children_goals_assignedActivities, index: number) => (
        <SummarySegment key={ac.id} onClick={() => handleClick(ac.id)}>
          <div style={{ display: 'flex' }}>
            <SummaryImage src={ac.mediaUrl || undefined} />
            <SummaryContainer>
              <SummaryMainSkillContainer>
                <SummaryMainSkill>{ac.mainSkill.name}</SummaryMainSkill>
              </SummaryMainSkillContainer>
              <SummaryName data-cy="activitieName">{ac.name}</SummaryName>
              <SummaryCheckInCount>
                {t(`client.task.activities.hadCompleted`)}
                <span style={{ color: '#5ABAAE', margin: '0 2px' }}> {ac.checkInCount} </span>
                {t(`client.task.activities.completedTimes`)}
              </SummaryCheckInCount>
            </SummaryContainer>
          </div>
          <Icon name="chevron right" size="large" link style={{ alignSelf: 'center' }} />
        </SummarySegment>
      ))}
    </ActivityCardContainer>
  );
};

export default ActivityCard;
