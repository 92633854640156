import styled from 'styled-components';
import { Button, Drawer, Layout } from 'antd';
const { Header, Sider, Content } = Layout;

export const HeaderHeight = 56;
export const BreadCrumbHeight = 47;
export const TitleHeight = 54;
export const RoutePadding = 25;
export const RouteBottomPadding = 50;
export const FixedTopHeight = HeaderHeight + BreadCrumbHeight + TitleHeight;

export const ChildInfoHeader = styled(Header)`
  background-color: white;
  padding: 0;
  box-shadow: 0 2px 8px #f0f1f2;
  z-index: 500;
  position: sticky;
  top: 0;
  height: ${HeaderHeight}px;
`;

export const ChildInfoContentContainer = styled(Content)``;

export const RouteContentContainer = styled.div`
  padding: 0 ${RoutePadding}px ${RouteBottomPadding}px ${RoutePadding}px;
`;

export const ChildInfoSider = styled(Sider)`
  position: sticky;
  top: ${HeaderHeight}px;
  height: calc(100vh - ${HeaderHeight}px);
  overflow: auto;
  bottom: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: -8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 8px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  :hover {
    .ant-btn {
      display: none;
    }
  }
  transition: all 0.5s;
`;

export const CollapseSiderButtonContainer = styled.div`
  position: sticky;
  top: calc(${HeaderHeight}px + 15px);
  margin-left: -20px;
  z-index: 2;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    .ant-btn {
      color: #5abaae;
      border-color: #5abaae;
    }
  }
  .ant-btn {
    :focus {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
    }
    :hover {
      color: #5abaae;
      border-color: #5abaae;
    }
  }
`;

export const CollapseButton = styled(Button)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  display: flex;
`;

export const DescriptionItem = styled.p`
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
`;

export const SiderSectionTitleContainer = styled.div`
  padding: 30px 30px 10px 30px;
  display: flex;
  align-items: flex-end;
`;

export const LoadingContainer = styled.div`
  height: calc(100vh - ${HeaderHeight}px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    justify-content: left;
    padding-left: 28px;
  }
`;

export const HamburgerMenuContainer = styled.div`
  top: 0;
  left: 0;
  z-index: 999;
  height: ${HeaderHeight}px;
  position: absolute;
  width: max-content;
  padding-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const ProfileMenuContainer = styled.div`
  top: 0;
  right: 0;
  z-index: 999;
  height: ${HeaderHeight}px;
  position: absolute;
  width: max-content;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    padding-right: 28px;
  }
`;

export const MenuDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 16px 1px;
  }
`;

export const BreadCrumbContainer = styled.div`
  height: ${BreadCrumbHeight}px;
  padding: 25px ${RoutePadding}px 0;
  top: ${HeaderHeight}px;
  position: sticky;
  z-index: 300;
  background-color: white;
`;

export const RouteTitleContainer = styled.div`
  height: ${TitleHeight}px;
  padding: 16px ${RoutePadding}px 0;
  top: calc(${HeaderHeight}px + ${BreadCrumbHeight}px);
  position: sticky;
  z-index: 300;
  background-color: white;
`;
