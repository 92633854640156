import gql from 'graphql-tag';
import { Feedback } from '../../types';

export const GET_FEEDBACK_FROM_CHILD_AND_WEEK = gql`
  query GetFeedbackFromChildAndWeek($childId: ID!, $currentWeek: Int!) {
    getFeedbackFromChildAndWeek(childId: $childId, currentWeek: $currentWeek) {
      activityId
      currentWeek
    }
  }
`;

export interface Data {
  getActivityFeedback: Feedback[];
}

export interface Variables {
  childId: number;
  currentWeek: number;
}
