import React, { useState, useEffect, useContext } from 'react';
import TherapistConsultationScreenSelectTaskCard from './SelectTaskCard';
import { useQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { PRESKILL_ACTIVITIES_QUERY } from '../../../../graphql/queries/getActivitiesFromPreskills';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { Preskills_getPreskillList } from '../../../../graphql/queries/types/Preskills';
import {
  GetActivitiesFromPreskills,
  GetActivitiesFromPreskills_getActivitiesFromPreskills,
  GetActivitiesFromPreskillsVariables,
} from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../common/ErrorPage';

const SelectTaskComponent = () => {
  const [activitiesDisplay, setActivitiesDisplay] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>(
    [],
  );
  const [activitiesStack, setActivitiesStack] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>([]);

  const { selectedPreskills } = useContext(TherapistConsultationScreenContext);

  const { t } = useTranslation();
  const { loading, error, data } = useQuery<GetActivitiesFromPreskills, GetActivitiesFromPreskillsVariables>(
    PRESKILL_ACTIVITIES_QUERY,
    {
      variables: { preskillIds: selectedPreskills.map((preskill: Preskills_getPreskillList) => preskill.skillId) },
    },
  );

  useEffect(() => {
    if (data && data.getActivitiesFromPreskills && data.getActivitiesFromPreskills.length > 0) {
      setActivitiesDisplay(data.getActivitiesFromPreskills.slice(0, 4));
      setActivitiesStack(data.getActivitiesFromPreskills.slice(4));
    }
  }, [data]); // <-- only update when data is changed

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      {activitiesDisplay.map((activity: GetActivitiesFromPreskills_getActivitiesFromPreskills, index: number) => (
        <TherapistConsultationScreenSelectTaskCard
          key={activity.id}
          index={index}
          activity={activity}
          setActivitiesDisplay={setActivitiesDisplay}
          activitiesStack={activitiesStack}
          setActivitiesStack={setActivitiesStack}
        />
      ))}
    </>
  );
};

export default SelectTaskComponent;
