import React, { useEffect, useState } from 'react';
import { Header, ContentContainer, Content, Arrow, CollapsibleSegment } from './styles';

interface Props {
  defaultOpen: boolean;
  header: JSX.Element;
  content: JSX.Element;
  show: boolean;
}

const Collapsible = ({ header, content, defaultOpen, show }: Props) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // if show === false, means that open state need to reset as false
    if (show === false) {
      setOpen(false);
    }
  }, [show]);

  return (
    <CollapsibleSegment>
      <Header isOpen={open} onClick={handleClick}>
        {header}
        <Arrow className="angle down" isOpen={open} />
      </Header>
      <ContentContainer isOpen={open}>
        <Content>{content}</Content>
      </ContentContainer>
    </CollapsibleSegment>
  );
};

export default Collapsible;
