import React from 'react';
import Header from '../../Header';
import { useTranslation } from 'react-i18next';

const ExpiredToken = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <div style={{ textAlign: 'center' }}>
        <h3>{t('auth.resetPassword.linkExpired')}</h3>
        <a href="/">{t('auth.resetPassword.returnToHomepage')}</a>
      </div>
    </div>
  );
};

export default ExpiredToken;
