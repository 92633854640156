import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ModalMask, Container, Title } from './styles';
import {
  SubmitButton,
  BigModalContainer,
  ModalTitle,
  ModalText,
  ModalButton,
} from '../Client/ConsentFormCPCRoute/styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ClientContext } from '../Client/ClientContext';
import CountryDropdown from './Account/components/CountryDropdown';
import UserContext from './UserContext';
import { ReturnMessage } from '../../types';
import { Select } from 'antd';
import countryList from './Account/components/constants/CountryList';
import { EDIT_USER_INFORMATION } from '../../graphql/mutations/editUserInformation';
import axios from 'axios';
import { useMutation } from 'react-apollo';
import { eval_backend_api_address } from '../../constants/config';
import { EditUserInformation, EditUserInformationVariables } from '../../graphql/mutations/types/EditUserInformation';
import { VIEWER_QUERY } from '../../graphql/queries/viewer';
import i18n from '../../i18n';
import { JS_COOKIE } from '../../util/Cookie';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

const { Option } = Select;

const ChangeCountryModal = () => {
  const [showModal, setshowModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [newCountry, setNewCountry] = useState<string>();
  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  // const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);

  const handleGoToProfile = () => {
    setshowModal(false);
    history.push('/profile');
  };

  const { client } = useContext(ClientContext);
  const { user } = useContext(UserContext);

  const filterOption = (input, option) => {
    const countryText = option.children;
    return countryText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    if (!client.user.country) {
      setshowModal(true);
    }
  }, []);

  const handleUpdateEval = async (country, userUuid) => {
    const token = JS_COOKIE.get('token');
    console.log(eval_backend_api_address + 'api/user/updateCountryFromGuide');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updateCountryFromGuide',
      {
        country: country,
        userUuid: userUuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { value } = data;
    setNewCountry(value as string);
  };

  const handleSubmit = async () => {
    const userId = client.user.id;
    console.log(userId, newCountry, user.uuid, ' asdasdasdas');
    await editUserInformation({ variables: { userId, attribute: 'Country', value: newCountry || '' } });
    handleUpdateEval(newCountry, user.uuid);
    setshowModal(false);
  };

  return (
    <>
      {showModal && (
        <ModalMask style={{ backgroundColor: '#00000099' }} open={true}>
          <BigModalContainer>
            <ModalTitle style={{ color: 'black', fontSize: '22px' }}>{t('onboarding.changeCountry')}</ModalTitle>
            <ModalText style={{ color: 'black', fontSize: '16px' }}>{t('onboarding.changeCountryText')}</ModalText>
            <Dropdown
              placeholder={t('auth.login.chooseCountry')}
              fluid
              selection
              search
              onChange={handleChange}
              options={countryList}
              style={{ width: '250px', marginTop: '16px' }}
            />
            <ModalButton style={{ marginTop: '16px' }} onClick={handleSubmit}>
              {t('onboarding.consentForm.dashboardmodalButton')}
            </ModalButton>
          </BigModalContainer>
        </ModalMask>
      )}
    </>
  );
};

export default ChangeCountryModal;
