import React, { ReactNode } from 'react';
import { NavbarText, NavbarLinkButton } from './styles';

interface Props {
  selected: boolean;
  handleClick: () => void;
  icon: ReactNode;
  text: string;
  className: string;
}

const NavbarLink = ({ selected, handleClick, icon, text, className }: Props) => {
  return (
    <NavbarLinkButton className={className} status={selected} onClick={() => handleClick()}>
      {icon}
      <NavbarText>{text}</NavbarText>
    </NavbarLinkButton>
  );
};

export default NavbarLink;
