import React, { useState, useEffect } from 'react';
import Header from '../Header';
import { Container, SubmitButton, EmailInput, InputText } from './styles';
import { useMutation } from 'react-apollo';
import {
  SendResetPasswordInstructions as Data,
  SendResetPasswordInstructionsVariables as Variables,
} from '../../../graphql/mutations/types/SendResetPasswordInstructions';
import { SEND_RESET_PASSWORD } from '../../../graphql/mutations/sendResetPasswordInstructions';
import { ReturnMessage } from '../../../types';
import ReturnMessageComponent from '../ReturnMessageComponent';
import { useTranslation } from 'react-i18next';

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);

  const [sendResetPasswordInstructions, { data }] = useMutation<Data, Variables>(SEND_RESET_PASSWORD);

  // update message near header
  useEffect(() => {
    if (data && data.sendResetPasswordInstructions) {
      setReturnMessage(data.sendResetPasswordInstructions.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setButtonDisabled(true);
    await sendResetPasswordInstructions({ variables: { email } });
    setButtonDisabled(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <Header />
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <Container>
        <form onSubmit={(e) => handleSubmit(e)}>
          <InputText>{t('auth.forgotPassword.title')}</InputText>
          <EmailInput
            type="email"
            placeholder={t('auth.forgotPassword.email')}
            size={50}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <SubmitButton disabled={buttonDisabled} type="submit">
            {buttonDisabled ? t('system.loading') : t('auth.forgotPassword.submit')}
          </SubmitButton>
        </form>
      </Container>
    </div>
  );
};

export default ForgotPasswordComponent;
