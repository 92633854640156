import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { ChildTasks as Data, ChildTasksVariables as Variables } from '../../../../../graphql/queries/types/ChildTasks';
import { CHILD_TASKS } from '../../../../../graphql/queries/childTasks';
import TasksComponent from './Component';
import { TherapistChildContext } from '../../TherapistChildContext';
import { LoadingContainer } from '../../layout/styles';
import { Spin } from 'antd';

const TasksRoute = () => {
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);
  const { loading, error, data } = useQuery<Data, Variables>(CHILD_TASKS, { variables: { childId } });

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  if (error || !data) {
    return <p>Error...</p>;
  }

  const {
    child: { assignedActivities },
    childConsultations,
  } = data;

  return (
    <TasksComponent consultations={childConsultations.slice().reverse()} assignedActivities={assignedActivities} />
  );
};

export default React.memo(TasksRoute);
