import gql from 'graphql-tag';

export const SEND_SMS_TO_CLIENT_BEF_CONSULTATION = gql`
  mutation SendSmsToClientBefConsultation($consultationId: ID!, $msg: String!, $details: String!) {
    sendSmsToClientBefConsultation(consultationId: $consultationId, msg: $msg, details: $details) {
      returnMessage {
        status
        message
      }
    }
  }
`;
