import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { BiggerRouteSectionContainer } from '../styles';
import Video from './Video';
import Blog from './Blog';
import TabMenu from '../../common/TabMenu';

const LibraryRoute = () => {
  window.scroll(0, 0);
  const { t } = useTranslation();
  const [selection, setSelection] = useState<number>(0);

  const handleSelect = (index: number, title: string) => {
    let option = '';
    if (title === t('client.library.video')) {
      option = 'video';
    } else if (title === t('client.library.blog.menuTitle')) {
      option = 'blog';
    }
    mixpanel.track('Library', {
      option,
    });
    setSelection(index);
  };

  const tabTitles = [t('client.library.video'), t('client.library.blog.menuTitle')];

  const tabContents = [<Video />, <Blog />];

  return (
    <BiggerRouteSectionContainer>
      <TabMenu tabTitles={tabTitles} tabContents={tabContents} selection={selection} handleTabClick={handleSelect} />
    </BiggerRouteSectionContainer>
  );
};

export default LibraryRoute;
