import styled from 'styled-components';

export const Container = styled.div`
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const InputText = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const EmailInput = styled.input`
  font-size: 16px;
  padding: 5px 2px;
  width: 100%;
  margin: 10px 0;
`;

export const SubmitButton = styled.button`
  width: 100%;
  background-color: #47e4c2;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: white;
  cursor: pointer;

  &:disabled {
    background-color: #47e4c2;
    opacity: 0.5;
  }
`;
