export const ROW_PER_PAGE = 15;

export const FilterType = {
  total: 'total',
  imitated: 'imitated',
  spontaneous: 'spontaneous',
};

export const FilterTypes = [FilterType.total, FilterType.imitated, FilterType.spontaneous];

export const FilterSign = {
  '≥': '≥',
  '=': '=',
  '≤': '≤',
};

export const FilterSigns = [FilterSign['≥'], FilterSign['='], FilterSign['≤']];

export const IconName = {
  word: 'sort alphabet down',
  'word desc': 'sort alphabet up',
  repeatCount: 'sort numeric down',
  'repeatCount desc': 'sort numeric up',
  speakCount: 'sort numeric down',
  'speakCount desc': 'sort numeric up',
  totalCount: 'sort amount up',
  'totalCount desc': 'sort amount down',
};
