import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import { ChildTasks_child_assignedActivities } from '../../../../../../../../graphql/queries/types/ChildTasks';
import WeeklyCheckInView from '../../../../../../../Client/TaskRoute/ActivityTab/ActivityModal/component/WeeklyCheckInView';
import { SkillBadge } from '../../styles';
import { renderStringToList } from '../../../../../../../../util/renderStringToList';
import { BackgroundColor } from '../../../../../../../Client/WordTrackingRoute/styles';
import { CurrentWeekday } from '../../../../../../../../util/Date';

interface Props {
  activity: ChildTasks_child_assignedActivities;
}

const OverallTab = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const summary = renderStringToList(activity.summary);
  const parentInteraction = renderStringToList(activity.parentInteraction);

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.weekRecord`)}</Title>
        <WeeklyCheckInView activity={activity} currentWeekday={CurrentWeekday()} />
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.skill`)}</Title>
        <SkillBadge BackgroundColor={BackgroundColor}>{activity.mainSkill.name}</SkillBadge>
        {activity.skills.map((s) => {
          return <SkillBadge BackgroundColor={BackgroundColor}>{s.name}</SkillBadge>;
        })}
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.summary`)}</Title>
        <Text>{summary}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.parentInteraction`)}</Title>
        <Text>{parentInteraction}</Text>
      </div>
    </Space>
  );
};

export default OverallTab;
