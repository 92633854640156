import React, { useState, useEffect } from 'react';
import { UserInfo, UserInfoText, ButtonGroup, CancelButton, SaveButton, UserInfoEditButton } from '../styles';
import { ReturnMessage } from '../../../../types';
import { useMutation } from 'react-apollo';
import {
  EditClientInformation,
  EditClientInformationVariables,
} from '../../../../graphql/mutations/types/EditClientInformation';
import { EDIT_CLIENT_INFORMATION } from '../../../../graphql/mutations/editClientInformation';
import { CLIENT_QUERY } from '../../../../graphql/queries/client';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../../constants/config';
import { getUserBrowserTz, getAllTzDropDown, tzToAbbr } from '../../../../constants/timezoneConversion';

interface Props {
  userId: string;
  value: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
  userUuid: string;
}

const TimeZoneDropdown = ({ userId, value, setReturnMessage, userUuid }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);
  const timeZoneList = getAllTzDropDown();

  const [editClientInformation, { data }] = useMutation<EditClientInformation, EditClientInformationVariables>(
    EDIT_CLIENT_INFORMATION,
    { refetchQueries: [{ query: CLIENT_QUERY, variables: { eval: false } }] },
  );

  // update message near header
  useEffect(() => {
    if (data && data.editClientInformation) {
      setReturnMessage(data.editClientInformation.returnMessage);
      console.log(data);
      console.log(data.editClientInformation.returnMessage);
    }
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
  }, [data]);

  function handleChange(inputChange) {
    setNewValue(inputChange);
  }

  const handleClickCancel = () => {
    setNewValue(value);
    setIsEdit(false);
  };

  const handleClickEdit = () => {
    setIsEdit(true);
    setNewValue(getUserBrowserTz());
  };

  const handleUpdateEval = async (timeZone, userUuid) => {
    const token = JS_COOKIE.get('token');
    console.log(eval_backend_api_address + 'api/user/updateTimeZoneFromGuide');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updateTimeZoneFromGuide',
      {
        timeZone: timeZone,
        userUuid: userUuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const handleClickSave = async () => {
    await editClientInformation({ variables: { clientId: userId, attribute: 'Time Zone', value: newValue } });
    handleUpdateEval(newValue, userUuid);
    setIsEdit(false);
    i18n.changeLanguage(newValue);
  };

  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const filterOption = (input, option) => {
    const tzText = option.children.toLowerCase();
    return tzText.indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <UserInfo>
      <div>
        <UserInfoText>{t('auth.timeZone')}</UserInfoText>
        {isEdit ? (
          <Select
            style={{ width: '350px' }}
            showSearch
            filterOption={filterOption}
            placeholder={t('auth.login.chooseTimeZone')}
            defaultValue={newValue}
            onChange={handleChange}
          >
            {timeZoneList.map((tz) => (
              <Option key={tz.key} value={tz.value}>
                {tz.text}
              </Option>
            ))}
          </Select>
        ) : (
          <p>{`${newValue} (${tzToAbbr(newValue)})`}</p>
        )}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={() => handleClickCancel()}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={() => handleClickSave()}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={() => handleClickEdit()}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default TimeZoneDropdown;
