import React, { useState } from 'react';

import OverviewComponent from './OverviewComponent';
import TasksContext from './TasksContext';
import SelectTaskDashboardComponent from './SelectTaskDashboardComponent';
import SelectPreskillsComponent from './SelectPreskillsComponent';
import { Consultation_consultation_child_activityChildren } from '../../../../graphql/queries/types/Consultation';

const TasksComponent = () => {
  const [isHomework, setIsHomework] = useState(false);
  const [isOverview, setIsOverview] = useState<boolean>(true);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [removedActivityChildren, setRemovedActivityChildren] = useState<
    Consultation_consultation_child_activityChildren[]
  >([]);

  return (
    <TasksContext.Provider
      value={{
        isOverview,
        setIsOverview,
        isHomework,
        setIsHomework,
        removedActivityChildren,
        setRemovedActivityChildren,
        isAgree,
        setIsAgree,
      }}
    >
      {isOverview ? (
        <OverviewComponent />
      ) : (
        <>{isHomework ? <SelectTaskDashboardComponent /> : <SelectPreskillsComponent />}</>
      )}
    </TasksContext.Provider>
  );
};

export default TasksComponent;
