import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';
import NoteEditor from '../../../../../common/NoteEditor';
import { CREATE_SIX_MONTH_GOAL_MUTATION } from '../../../../../../graphql/mutations/createSixMonthGoal';
import {
  CreateSixMonthGoalVariables,
  CreateSixMonthGoal,
} from '../../../../../../graphql/mutations/types/CreateSixMonthGoal';
import { Tooltip, Typography } from 'antd';
import { TherapistChildContext } from '../../../TherapistChildContext';
import { EditOutlined, SaveOutlined, CloseSquareOutlined } from '@ant-design/icons';

const SixMonthGoal = () => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<boolean>(false);
  const { child } = useContext(TherapistChildContext);
  const [goal, setGoal] = useState<string>(child.childSixMonthGoal ? child.childSixMonthGoal.description : '');
  const [createSixMonthGoal, { loading, error, data }] = useMutation<CreateSixMonthGoal, CreateSixMonthGoalVariables>(
    CREATE_SIX_MONTH_GOAL_MUTATION,
  );
  const { Title, Text, Link } = Typography;
  const handleCancelEdit = () => {
    setEditing(false);
    setGoal(child.childSixMonthGoal ? child.childSixMonthGoal.description : '');
  };
  const updateSixMonthGoal = async () => {
    await createSixMonthGoal({
      variables: {
        description: goal,
        childId: child.id,
      },
    });
    setEditing(false);
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Title level={5}>{t('therapist.dashboard.myClient.card.sixMonthGoal.title')}</Title>
      {editing ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px', height: '40px' }}>
            <Tooltip title={t('therapist.dashboard.myClient.card.sixMonthGoal.cancelEdit')} arrowPointAtCenter>
              <Link
                onClick={handleCancelEdit}
                style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3px' }}
              >
                <CloseSquareOutlined translate={undefined} style={{ fontSize: '18px' }} />
                <div>{t('therapist.dashboard.myClient.card.sixMonthGoal.cancelEdit')}</div>
              </Link>
            </Tooltip>
            <Tooltip title={t('therapist.dashboard.myClient.card.sixMonthGoal.finishEdit')} arrowPointAtCenter>
              <Link
                onClick={updateSixMonthGoal}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3px' }}
              >
                <SaveOutlined translate={undefined} style={{ fontSize: '18px' }} />
                <div>{t('therapist.dashboard.myClient.card.sixMonthGoal.finishEdit')}</div>
              </Link>
            </Tooltip>
          </div>
          <NoteEditor
            initialString={goal}
            placeholder={t('therapist.dashboard.myClient.card.sixMonthGoal.placeHolder')}
            setNote={setGoal}
          />
        </>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            {goal === '<p></p>' || goal === '' ? (
              <Link onClick={() => setEditing(!editing)}>{t('therapist.childInfo.goal.fillIn')}</Link>
            ) : (
              <Text>{ReactHtmlParser(goal)}</Text>
            )}
            <Link style={{ marginLeft: '5px' }}>
              <Tooltip title={t('therapist.dashboard.myClient.card.sixMonthGoal.edit')} arrowPointAtCenter>
                <EditOutlined translate={undefined} onClick={() => setEditing(!editing)} />
              </Tooltip>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SixMonthGoal;
