import gql from 'graphql-tag';
import { ReturnMessage, Feedback } from '../../types';

export const ADD_FEEDBACK = gql`
  mutation AddFeedback(
    $activityId: ID!
    $rating: Int!
    $activityCompleted: Boolean!
    $remarks: String!
    $childId: ID!
    $currentWeek: Int!
    $ratingReason: String!
  ) {
    addFeedback(
      activityId: $activityId
      rating: $rating
      activityCompleted: $activityCompleted
      remarks: $remarks
      childId: $childId
      currentWeek: $currentWeek
      ratingReason: $ratingReason
    ) {
      returnMessage {
        message
      }
    }
  }
`;

export interface Data {
  addFeedback: { returnMessage: ReturnMessage };
}

export interface Variables {
  activityId: number;
  rating: number;
  activityCompleted: boolean;
  remarks: string;
  childId: number;
  currentWeek: number;
  ratingReason: string;
}
