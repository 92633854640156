import gql from 'graphql-tag';

export const UPDATE_GOAL_PRESKILL_MASTERED_MUTATION = gql`
  mutation UpdateGoalPreskillMastered($skillId: ID!, $goalId: ID!) {
    updateGoalPreskillMastered(skillId: $skillId, goalId: $goalId) {
      returnMessage {
        status
        message
      }
      goalPreskill {
        id
        skillId
        mastered
      }
    }
  }
`;
