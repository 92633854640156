import gql from 'graphql-tag';

export const CHILD_HOMEWORKS = gql`
  query ChildHomeworks($childId: ID!) {
    child(childId: $childId) {
      id
      room {
        id
      }
      homeworks {
        id
        description
        submissionDate
        format
        homeworkSubmission {
          id
          message {
            id
            body
            user {
              id
              name
            }
            replies {
              id
              message {
                id
                body
                user {
                  id
                  name
                }
                replyMessageFileUrl
                replyMessageFileContentType
                createdAt
              }
            }
            createdAt
            homeworkUrl
            homeworkContentType
          }
        }
      }
    }
  }
`;
