import React, { useContext } from 'react';
import { Segment, Grid, Image, Button } from 'semantic-ui-react';
import { getAge } from '../../../helpers/functions';
import TherapistConsultationScreenContext from './TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';
import defaultChild from '../../../assets/defaultChild.png';
import { GeneralSegment } from '../../../style';

const TherapistConsultationScreenInfoComponent = () => {
  const {
    consultation: {
      child: { name, dob, gender, completedTasks, pictureUrl },
    },
  } = useContext(TherapistConsultationScreenContext);

  const { t } = useTranslation();
  const tPath = 'therapist.consultationScreen.infoComponent';
  const [year, month] = getAge(new Date(dob));
  return (
    <GeneralSegment>
      <Grid columns={2}>
        <Grid.Column width={8}>
          <Image
            circular
            size="tiny"
            src={pictureUrl ? pictureUrl : defaultChild}
            style={{
              width: '60px',
              height: '60px',
              display: 'inline-block',
              marginLeft: '10px',
              marginTop: '10px',
            }}
            centered
          />
          <div style={{ padding: '0px', margin: '0px' }}>
            <h4 style={{ padding: '2px', margin: '5px' }}>{name}</h4>
            <div style={{ justifyContent: 'space-around', display: 'flex', margin: '1px' }}>
              <p>{t(`therapist.genders.${gender}`)}</p>
              <p>
                {year} {t(`${tPath}.yearsOld`) + ' '}
                {month} {t(`${tPath}.monthOld`)}
              </p>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <p>
            {t(`${tPath}.completedTasks`)}: {completedTasks}
          </p>
        </Grid.Column>
      </Grid>
      <Button
        circular
        href="https://zoom.us/j/3689867590"
        style={{
          border: '2px solid #5BD6CA',
          color: '#5BD6CA',
          backgroundColor: 'white',
        }}
      >
        {t('therapist.consultationScreen.infoComponent.startVideoConsultation')}
      </Button>
    </GeneralSegment>
  );
};

export default TherapistConsultationScreenInfoComponent;
