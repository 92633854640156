// This file is to created campaign pop up modal for guide system
// You need to insert below SQL code into your localhost database

// Phase 1
// 1. Open database ( api_guide_development )
// 2. Open SQL Editor RUN below code to add new notification event
// *** Before running the code, you need to edit [ ??? ] which represent to new event name [ "name" ]
// INSERT INTO "public"."notification_events" ("notification_type", "name") VALUES ('modal_notification', '???');
// 3. Open SQL Editor RUN below code to add new modal notification
// *** Before running the code, you need to edit two [ ??? ] which represent to [ "user_id", "notification_event_id" ]
// INSERT INTO "public"."modal_notifications" ("user_id", "notification_event_id", "not_show_anymore", "consultation_id", "homework_id", "room_id", "child_id", "created_at", "updated_at") VALUES (???, ???, false, NULL, NULL, NULL, NULL, '2021-01-15 11:16:21.79302', '2021-01-27 03:42:37.984536');

// Phase 2
// Create a new campaign component that you can refer to sampleCampaign.tsx

// Phase 3
// Add code to getModalComponent at below, to called out the component that you have create on Phase 2

/* eslint-disable react/display-name */
import React, { useState, useLayoutEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { GET_MODAL_NOTIFICATIONS_QUERY } from '../../../graphql/queries/getModalNotifications';
import {
  GetModalNotifications,
  GetModalNotificationsVariables,
  ModalNotifications,
} from '../../../graphql/queries/types/GetModalNotifications';
import { RequestNotShowMore, RequestNotShowMoreVariables } from '../../../graphql/mutations/types/RequestNotShowMore';
import { REQUEST_NOT_SHOW_MORE_MUTATION } from '../../../graphql/mutations/requestNotShowMore';
import SampleCampaign from './components/sampleCampaign';
import ChineseNewYearCampaign from './components/ChineseNewYearCampaign';
import CNYAnnouncement2021 from './components/CNYAnnouncement2021';
import UpdateTermsAnnouncement2022 from './components/UpdateTermsAnnouncement2022';

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { ModalMask } from '../../../style';

interface Props {
  clientId: string;
}

const ModalNotificationCard = ({ clientId }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<ModalNotifications | undefined>(undefined);
  const [showModalComponent, setShowModalComponent] = useState<JSX.Element | undefined>(undefined);
  const { loading, error, data } = useQuery<GetModalNotifications, GetModalNotificationsVariables>(
    GET_MODAL_NOTIFICATIONS_QUERY,
    {
      variables: { clientId },
    },
  );
  const [requestNotShowTheNotification] = useMutation<RequestNotShowMore, RequestNotShowMoreVariables>(
    REQUEST_NOT_SHOW_MORE_MUTATION,
  );

  const language = i18n.use(LanguageDetector).language;

  const getModalComponent = (name: string, id: string) => {
    if (name === 'sample') {
      return <SampleCampaign notificationId={id} setOpen={setOpen} handleClickNotShowMore={handleClickNotShowMore} />;
    }
    if (name === 'ChineseNewYearCampaign' && (language === 'zh-TW' || language === 'zh-CN')) {
      return (
        <ChineseNewYearCampaign notificationId={id} setOpen={setOpen} handleClickNotShowMore={handleClickNotShowMore} />
      );
    }
    if (name === 'CNYAnnouncement2021' && (language === 'zh-TW' || language === 'zh-CN')) {
      return (
        <CNYAnnouncement2021 notificationId={id} setOpen={setOpen} handleClickNotShowMore={handleClickNotShowMore} />
      );
    }
    if (name === 'UpdateTermsAnnouncement2022') {
      return (
        <UpdateTermsAnnouncement2022
          notificationId={id}
          setOpen={setOpen}
          handleClickNotShowMore={handleClickNotShowMore}
        />
      );
    }
    return;
  };

  const handleClickNotShowMore = async (notificationId: string) => {
    await requestNotShowTheNotification({
      variables: {
        modalNotificationId: notificationId,
      },
    });
    setOpen(false);
  };

  useLayoutEffect(() => {
    if (data && data.getModalNotifications && data.getModalNotifications.length > 0) {
      const showModalComponent = getModalComponent(
        data.getModalNotifications[0].notificationEvent.name,
        data.getModalNotifications[0].id,
      );
      setNotification(data.getModalNotifications[0]);
      if (showModalComponent === undefined) {
        setOpen(false);
      } else {
        setShowModalComponent(showModalComponent);
        setOpen(true);
      }
    }
  }, [data]);
  if (notification === undefined) {
    return <></>;
  }

  return (
    <ModalMask open={open} onClick={() => setOpen(false)}>
      {showModalComponent}
    </ModalMask>
  );
};

export default ModalNotificationCard;
