import React, { useState, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
  HeaderData,
  HeaderRowContainer,
  SortButton,
  SortIcon,
  StickyHeaderColumnContainer,
  TrackingEmptyStateContainer,
} from '../styles';
import { Word } from '../../../../../graphql/queries/types/WordTracking';
import { EditWordData, EditWordVariables, EDIT_WORD_MUTATION } from '../../../../../graphql/mutations/editWord';
import TableRow from './TableRow';
import AddWordModal from './Modal/AddWordModal';
import EditWordModal from './Modal/EditWordModal';
import CountWordModal from './Modal/CountWordModal';
import DeleteWordModal from './Modal/DeleteWordModal';
import HurrayModal from './Modal/HurrayModal';
import ToolBox from '../../ToolBox';
import Pagination from '../../Pagination';
import { FilterType, FilterSign, ROW_PER_PAGE, IconName } from '../../../../../constants/wordTracking';
import { WordTrackingSegment, WordContainer } from '../styles';
import FilterWordModal from './Modal/FilterWordModal';
import {
  ActionTitle,
  EmptyStateButton,
  EmtpyStateContent,
  EmtpyStateTitle,
  SmallActionTitle,
} from '../../../../../style';
import { AddIcon } from '../../ToolBox/AddButton/styles';
type Props = {
  words: Word[];
  insertNewWord: (word: Word) => void;
  deleteWords: (wordIds: number[]) => void;
  completeWords: (words: Word[]) => void;
};

const getFilteredWords = (words: Word[], type: string, sign: string, count: number) => {
  if (sign === FilterSign['≥']) {
    return words.filter((word: Word) => word[type] > count || word[type] === count);
  } else if (sign === FilterSign['=']) {
    return words.filter((word: Word) => word[type] === count);
  } else if (sign === FilterSign['≤']) {
    return words.filter((word: Word) => word[type] < count || word[type] === count);
  } else {
    return words;
  }
};

const getSearchedAndFilteredWords = (
  words: Word[],
  searchString: string,
  type: string,
  sign: string,
  count: number,
) => {
  const searchWords = words.filter((word: Word) => word.word.includes(searchString));
  if (type === FilterType.imitated) {
    return getFilteredWords(searchWords, 'repeatCount', sign, count);
  } else if (type === FilterType.spontaneous) {
    return getFilteredWords(searchWords, 'speakCount', sign, count);
  } else if (type === FilterType.total) {
    return getFilteredWords(searchWords, 'totalCount', sign, count);
  } else {
    return searchWords;
  }
};

const sortById = (a: Word, b: Word) => {
  return a.id - b.id;
};

const sortByWord = (a: Word, b: Word) => {
  return a.word.localeCompare(b.word);
};

const sortByRepeatCount = (a: Word, b: Word) => {
  return a.repeatCount - b.repeatCount;
};

const sortBySpeakCount = (a: Word, b: Word) => {
  return a.speakCount - b.speakCount;
};

const sortByTotalCount = (a: Word, b: Word) => {
  return a.repeatCount - b.repeatCount + a.speakCount - b.speakCount;
};

type SortMethod =
  | 'ID'
  | 'ID desc'
  | 'word'
  | 'word desc'
  | 'repeatCount'
  | 'repeatCount desc'
  | 'speakCount'
  | 'speakCount desc'
  | 'totalCount'
  | 'totalCount desc';

const getSortFunction = (type: SortMethod) => {
  if (type.includes('ID')) {
    return sortById;
  } else if (type.includes('word')) {
    return sortByWord;
  } else if (type.includes('repeatCount')) {
    return sortByRepeatCount;
  } else if (type.includes('speakCount')) {
    return sortBySpeakCount;
  } else if (type.includes('totalCount')) {
    return sortByTotalCount;
  } else {
    return sortById;
  }
};

const TrackingWords = ({ words, insertNewWord, deleteWords, completeWords }: Props) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const [sortMethod, setSortMethod] = useState('ID desc' as SortMethod);
  const [filterType, setFilterType] = useState(FilterType.total);
  const [signFilter, setSignFilter] = useState(FilterSign['≥']);
  const [countFilter, setCountFilter] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as number[]);
  const [actionWordIndices, setActionWordIndices] = useState([] as number[]);
  const [pageOffset, setPageOffset] = useState(1);
  const [editWord] = useMutation<EditWordData, EditWordVariables>(EDIT_WORD_MUTATION);

  // modal set state
  const [countWordModalType, setCountWordModalType] = useState<string>('');
  const [showAddWordModal, setShowAddWordModal] = useState(false);
  const [showFilterWordModal, setShowFilterWordModal] = useState(false);
  const [showEditWordModal, setShowEditWordModal] = useState(false);
  const [showCountWordModal, setShowCountWordModal] = useState(false);
  const [showDeleteWordModal, setShowDeleteWordModal] = useState(false);
  const [showHurrayModal, setShowHurrayModal] = useState(false);

  // AddWordModal function
  const handleOpenAddWordModal = () => {
    setShowAddWordModal(true);
  };
  const handleCloseAddWordModal = () => setShowAddWordModal(false);

  // EditWord function
  const handleOpenEditWordModal = () => setShowEditWordModal(true);
  const handleCloseEditWordModal = () => {
    setShowEditWordModal(false);
    setActionWordIndices([]);
  };
  const handleEditWord = (index: number) => {
    setActionWordIndices([index]);
    handleOpenEditWordModal();
  };

  // countWord function
  const handleCloseCountWordModal = () => {
    setShowCountWordModal(false);
    setActionWordIndices([]);
  };
  const handleCountWord = (index: number, type: string) => {
    setActionWordIndices([index]);
    setShowCountWordModal(true);
    setCountWordModalType(type);
  };
  const handleEditCountWord = (wordId: number, value: number) => {
    if (countWordModalType === 'repeatCount') {
      return handleEditRepeatCount(wordId, value);
    } else if (countWordModalType === 'speakCount') {
      return handleEditSpeakCount(wordId, value);
    }
  };
  const handleEditRepeatCount = (wordId: number, value: number) => {
    editWord({
      variables: {
        wordId,
        attribute: 'repeat_count',
        value: value.toString(),
      },
    });
  };
  const handleEditSpeakCount = (wordId: number, value: number) => {
    editWord({
      variables: {
        wordId,
        attribute: 'speak_count',
        value: value.toString(),
      },
    });
  };

  // DeleteWord function
  const handleOpenDeleteWordModal = () => setShowDeleteWordModal(true);
  const handleCloseDeleteWordModal = () => {
    setShowDeleteWordModal(false);
    setActionWordIndices([]);
  };
  const handleSubmitDeleteWordModal = () => {
    setShowDeleteWordModal(false);
    const oldActionWordIds = actionWordIds;
    setActionWordIndices([]);
    deleteWords(oldActionWordIds);
    setSelectedRows([]);
    setPageOffset(1);
  };
  const handleDeleteOneWord = (index: number) => {
    setActionWordIndices([index]);
    handleOpenDeleteWordModal();
  };
  const handleDeleteWords = () => {
    setActionWordIndices([...selectedRows]);
    handleOpenDeleteWordModal();
  };

  // Hurray function
  const handleOpenHurrayModal = () => setShowHurrayModal(true);
  const handleCloseHurrayModal = () => setShowHurrayModal(false);

  // completeWords function
  const handleClearActionWords = () => {
    setActionWordIndices([]);
    setSelectedRows([]);
    setPageOffset(1);
  };
  const handleCompleteOneWord = (index: number) => {
    setActionWordIndices([index]);
    handleOpenHurrayModal();
  };
  const handleCompleteWords = () => {
    setActionWordIndices([...selectedRows]);
    handleOpenHurrayModal();
  };

  // FilterWord function
  const handleOpenFilterWordModal = () => setShowFilterWordModal(true);
  const handleCloseFilterWordModal = () => setShowFilterWordModal(false);
  const handleSubmitFilterWordModal = (type: string, sign: string, value: number) => {
    setFilterType(type);
    setSignFilter(sign);
    setCountFilter(value);
    setShowFilterWordModal(false);
  };
  const onSearchInput = (value: string) => {
    setSearchString(value);
    setPageOffset(1);
  };
  const onFilterTypeChange = (value: string) => {
    setFilterType(value);
    setPageOffset(1);
  };
  const onSignFilterChange = (value: string) => {
    setSignFilter(value);
    setPageOffset(1);
  };
  const onCountFilterChange = (value: number) => {
    setCountFilter(value);
    setPageOffset(1);
  };

  // pageChange function
  const handleChangePage = (page: number) => {
    setPageOffset(page);
    setSelectedRows([]);
  };

  // multi select function
  const handleCheckboxClick = (index: number, status: boolean) => {
    if (status) {
      setSelectedRows([...selectedRows, index]);
    } else {
      const newSelectedRows = selectedRows.filter((i: number) => i !== index);
      setSelectedRows([...newSelectedRows]);
    }
  };

  // sort function
  const handleHeaderClick = (type: SortMethod) => {
    if (sortMethod.includes(type)) {
      if (sortMethod.includes('desc')) {
        const newType = sortMethod.split(' ')[0] as SortMethod;
        setSortMethod(newType);
      } else {
        const newType = (type + ' desc') as SortMethod;
        setSortMethod(newType);
      }
    } else {
      setSortMethod(type);
    }
  };
  const handleSortIcon = (type: string) => {
    if (sortMethod.includes(type) === false) {
      return 'sort';
    } else {
      if (sortMethod.includes('desc')) {
        return IconName[type + ' desc'];
      } else {
        return IconName[type];
      }
    }
  };

  const hasSelectedItems = selectedRows.length > 0;

  const filteredAndSortedWords = useMemo(() => {
    const filteredWords = getSearchedAndFilteredWords(words, searchString, filterType, signFilter, countFilter);
    const sortedWords = filteredWords.sort(getSortFunction(sortMethod));
    if (sortMethod.includes('desc')) {
      sortedWords.reverse();
    }
    return sortedWords;
  }, [words, searchString, filterType, signFilter, countFilter, sortMethod]) as Word[];

  const pageCount = useMemo(() => Math.ceil(filteredAndSortedWords.length / ROW_PER_PAGE), [filteredAndSortedWords]);

  const displayWords = useMemo(() => {
    const start = (pageOffset - 1) * ROW_PER_PAGE;
    const target = pageOffset * ROW_PER_PAGE;
    const end = target < filteredAndSortedWords.length ? target : filteredAndSortedWords.length;
    return filteredAndSortedWords.slice(start, end);
  }, [filteredAndSortedWords, pageOffset]) as Word[];

  const [actionWords, actionWordIds] = useMemo(() => {
    const actionWords = actionWordIndices.map((index: number) => displayWords[index]);
    const actionWordIds = actionWords.map((word: Word) => word.id);
    return [actionWords, actionWordIds];
  }, [actionWordIndices, displayWords]) as [Word[], number[]];

  const headerTranslation = t('client.wordTracking.table.header', { returnObjects: true });
  const emptyState = displayWords.length == 0;

  return (
    <WordTrackingSegment>
      <AddWordModal showModal={showAddWordModal} insertNewWord={insertNewWord} handleClose={handleCloseAddWordModal} />
      <EditWordModal showModal={showEditWordModal} handleClose={handleCloseEditWordModal} wordItem={actionWords[0]} />
      <CountWordModal
        type={countWordModalType}
        wordItem={actionWords[0]}
        showModal={showCountWordModal}
        handleClose={handleCloseCountWordModal}
        handleEditCountWord={handleEditCountWord}
      />
      <DeleteWordModal
        showModal={showDeleteWordModal}
        handleClose={handleCloseDeleteWordModal}
        handleDelete={handleSubmitDeleteWordModal}
        wordIds={actionWordIds}
      />
      <HurrayModal
        showModal={showHurrayModal}
        handleClose={handleCloseHurrayModal}
        wordIds={actionWordIds}
        completeWords={completeWords}
        handleClearActionWords={handleClearActionWords}
      />
      <FilterWordModal
        filterType={filterType}
        signFilter={signFilter}
        countFilter={countFilter}
        showModal={showFilterWordModal}
        handleClose={handleCloseFilterWordModal}
        handleSubmitFilterWordModal={handleSubmitFilterWordModal}
      />
      <ToolBox
        hasSelectedItems={hasSelectedItems}
        searchString={searchString}
        setSearchString={onSearchInput}
        filterType={filterType}
        signFilter={signFilter}
        countFilter={countFilter}
        setFilterType={onFilterTypeChange}
        setSignFilter={onSignFilterChange}
        setCountFilter={onCountFilterChange}
        handleOpenAddWordModal={handleOpenAddWordModal}
        deleteWords={handleDeleteWords}
        completeWords={handleCompleteWords}
        handleOpenFilterWordModal={handleOpenFilterWordModal}
      />
      {emptyState ? (
        <TrackingEmptyStateContainer>
          <EmtpyStateTitle>{t('client.wordTracking.trackWordEmptyState.title')}</EmtpyStateTitle>
          <EmtpyStateContent>{t('client.wordTracking.trackWordEmptyState.content')}</EmtpyStateContent>
          <EmptyStateButton onClick={handleOpenAddWordModal}>
            <AddIcon className="plus" />
            <ActionTitle>{t('client.wordTracking.trackWordEmptyState.button')}</ActionTitle>
          </EmptyStateButton>
        </TrackingEmptyStateContainer>
      ) : (
        <>
          <WordContainer>
            <HeaderRowContainer>
              <StickyHeaderColumnContainer>
                <HeaderData center={true} canSort={false}></HeaderData>
                <HeaderData center={false} canSort={true} onClick={() => handleHeaderClick('word')}>
                  <SmallActionTitle>{headerTranslation['word']}</SmallActionTitle>
                  <SortButton status={sortMethod.includes('word') === false}>
                    <SortIcon className={handleSortIcon('word')} />
                  </SortButton>
                </HeaderData>
              </StickyHeaderColumnContainer>
              <HeaderData center={true} canSort={true} onClick={() => handleHeaderClick('repeatCount')}>
                <SmallActionTitle>{headerTranslation['timesImitated']}</SmallActionTitle>
                <SortButton status={sortMethod.includes('repeatCount') === false}>
                  <SortIcon className={handleSortIcon('repeatCount')} />
                </SortButton>
              </HeaderData>
              <HeaderData center={true} canSort={true} onClick={() => handleHeaderClick('speakCount')}>
                <SmallActionTitle>{headerTranslation['timesSpontaneouslyUsed']}</SmallActionTitle>
                <SortButton status={sortMethod.includes('speakCount') === false}>
                  <SortIcon className={handleSortIcon('speakCount')} />
                </SortButton>
              </HeaderData>
              <HeaderData center={true} canSort={true} onClick={() => handleHeaderClick('totalCount')}>
                <SmallActionTitle>{headerTranslation['totalUsage']}</SmallActionTitle>
                <SortButton status={sortMethod.includes('totalCount') === false}>
                  <SortIcon className={handleSortIcon('totalCount')} />
                </SortButton>
              </HeaderData>
              <HeaderData center={true} canSort={false}></HeaderData>
            </HeaderRowContainer>

            {displayWords.map((word: Word, index: number) => {
              return (
                <TableRow
                  index={index}
                  word={word}
                  isChecked={selectedRows.includes(index)}
                  hasSelectedItems={hasSelectedItems}
                  handleEditRepeatCount={handleEditRepeatCount}
                  handleEditSpeakCount={handleEditSpeakCount}
                  handleCompleteClick={handleCompleteOneWord}
                  handleEditWord={handleEditWord}
                  handleDeleteWord={handleDeleteOneWord}
                  handleCheckboxClick={handleCheckboxClick}
                  handleCountWord={handleCountWord}
                />
              );
            })}
          </WordContainer>
          {pageCount > 1 && (
            <Pagination pageCount={pageCount} selectedItem={pageOffset} handlePageChange={handleChangePage} />
          )}
        </>
      )}
    </WordTrackingSegment>
  );
};

export default TrackingWords;
