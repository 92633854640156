import styled from 'styled-components';
import { UserComment } from '../../../../../../../../style';
import { MainColor } from '../../../../../styles';
import { DataItemContainer } from '../../../../styles';

export const UsageContainer = styled(DataItemContainer)`
  justify-content: center;
`;

export const UsageText = styled(UserComment)`
  color: ${MainColor};
  font-weight: bold;
`;
