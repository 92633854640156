import React from 'react';
import { SkillsContainer, SkillsIcon, SkillStatusBadge } from '../../../styles';
import inProgressStar from '../../../assets/inProgressStar.svg';
import FullStar from '../../../assets/FullStar.svg';
import notYetStar from '../../../assets/notYetStar.svg';
import { Skill } from '../../../../../../util/Category';

interface Props {
  Skills: Array<Skill>;
  type: string;
}

const Skills = ({ Skills, type }: Props) => {
  const renderSkill = Skills.map((s) => {
    return (
      <SkillsContainer key={s.id}>
        {type === 'mastered' && <SkillsIcon src={FullStar} />}
        {type === 'inProgress' && <SkillsIcon src={inProgressStar} />}
        {type === 'notYetInProgress' && <SkillsIcon src={notYetStar} />}
        <SkillStatusBadge type={type}>{s.name}</SkillStatusBadge>
        {/* {Skills.count ? (
          <SkillsText mastered={Skills.mastered}>
            {' +'}
            {Skills.count}
          </SkillsText>
        ) : null} */}
      </SkillsContainer>
    );
  });

  return <>{renderSkill}</>;
};
export default Skills;
