export const now = Date.now();

export const beginningOfToday = new Date();
beginningOfToday.setHours(0, 0, 0, 0); // set to beginning of day

// edit time
export const addHours = (numOfHours, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};
export const minusHours = (numOfHours, date = new Date()) => {
  date.setTime(date.getTime() - numOfHours * 60 * 60 * 1000);
  return date;
};
export const addDays = (numOfDays, date = new Date()) => {
  date.setDate(date.getDate() + numOfDays);
  return date;
};

// time within
export const isPast = (millisecondsDate: number) => {
  return millisecondsDate < now;
};
export const isBetween = (startTime: number, endTime: number) => {
  return startTime < now && endTime > now;
};
export const isToday = (today: Date, compareDateTime: Date) => {
  return (
    today.getDate() === compareDateTime.getDate() &&
    today.getMonth() === compareDateTime.getMonth() &&
    today.getFullYear() === compareDateTime.getFullYear()
  );
};
export const isFuture = (millisecondsDate: number) => {
  return millisecondsDate > now;
};
export const getNearestDateInFuture = (dateArr: Date[], findDate: Date) => {
  const sortedByDiff = [...dateArr].sort((a, b) => {
    // Sort by the absolute difference in ms between dates.
    return Math.abs(Number(findDate) - Number(a)) - Math.abs(Number(findDate) - Number(b));
  });
  // Return the first date (the one with the smallest difference)
  return sortedByDiff[0];
};

// time showing
export const date = (dateTime: any, locale: string) => {
  return dateTime.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' });
};
export const shortDate = (dateTime: any, locale: string) => {
  return dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
};
export const dateTime = (dateTime: any, locale: string) => {
  return `${weekday(dateTime, locale, 'long')}, ${date(dateTime, locale)}, ${time(dateTime, locale)}`;
};
export const time = (dateTime: any, locale: string) => {
  return dateTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
};
export const weekday = (dateTime: any, locale: string, length: 'long' | 'short') => {
  return dateTime.toLocaleString(locale, { weekday: length });
};
export const month = (dateTime: any, locale: string, length: 'long' | 'short') => {
  return dateTime.toLocaleDateString(locale, { month: length });
};
export const year = (dateTime: any, locale: string) => {
  return dateTime.toLocaleDateString(locale, { year: 'numeric' });
};
