import React, { useContext, useEffect, useState } from 'react';
import { Menu, Container, Image, Icon } from 'semantic-ui-react';
import UserContext from './UserContext';
import HeaderChildDropdownComponent from './HeaderChildDropdownComponent';
import LogoutComponent from './LogoutComponent';
import { Client_client_children } from '../../graphql/queries/types/Client';
import Notification from './Notification';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Logo_EN from '../../assets/Logo_EN.png';
import Logo_CN from '../../assets/Logo_CN.png';
import Logo_TW from '../../assets/Logo_TW.png';
import { AccountLabel, NavbarContainer, NavbarItemsContainer, NavbarLogo, NavbarLogoContainer } from './styles';
import { NavbarIcon } from './Navbar/styles';
import Penguin from '../../assets/Penguin.svg';
import { GET_LANGUAGE } from './LoginComponent/helper';
interface Props {
  childs?: Client_client_children[];
  setChild?: (child: Client_client_children) => void;
}

const Header = ({ childs, setChild }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const language = GET_LANGUAGE();
  let Logo = Logo_EN;
  if (language === 'en') {
    Logo = Logo_EN;
  } else if (language === 'zh-CN') {
    Logo = Logo_CN;
  } else if (language === 'zh-TW') {
    Logo = Logo_TW;
  }

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 750);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 750);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    console.log('isMobile', isMobile);
  }, [isMobile]);

  return (
    <NavbarContainer>
      <NavbarLogoContainer>
        <NavbarLogo
          onClick={() => history.push('/')}
          src={isMobile ? Penguin : Logo}
          style={{ width: isMobile ? '50px' : '130px' }}
        />
      </NavbarLogoContainer>
      <NavbarItemsContainer>
        {user && user.id && <Notification userId={user.id} />}
        {user && user.loggedInBefore && user.id && (
          <AccountLabel style={{ cursor: 'pointer' }} onClick={() => history.push('/account')}>
            {t('auth.title')}
          </AccountLabel>
        )}
        {user && user.id && <LogoutComponent />}
      </NavbarItemsContainer>
    </NavbarContainer>
  );
};

export default Header;
