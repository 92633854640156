import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 810px) {
    flex-direction: column;
  }
`;

export const BigText = styled.p`
  font-size: 15rem;
  font-weight: bold;
  color: #93d5d1;
  margin: 0;
  text-align: left;
  @media only screen and (max-width: 810px) {
    font-size: 10rem;
    text-align: center;
  }
`;

export const SmallText = styled.p`
  font-size: 3rem;
  font-weight: bold;
  flex-wrap: wrap;
  margin: 0;
  @media only screen and (max-width: 810px) {
    font-size: 2rem;
    padding: 1rem 0rem;
    text-align: center;
  }
`;

export const Image = styled.img`
  margin: 0rem;
  max-height: 70vh;
  @media only screen and (max-width: 810px) {
    max-height: 35vh;
  }
`;

export const TextContainer = styled.div`
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 2rem;
  @media only screen and (max-width: 810px) {
    margin: 5rem;
  }
`;
