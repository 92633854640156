import gql from 'graphql-tag';
import { ConsultationNote, ReturnMessage } from '../../types';

export const CREATE_NOTES_MUTATION = gql`
  mutation CreateConsultationNote(
    $mainComplaint: String!
    $internalNotes: String!
    $commentForParents: String!
    $consultationId: ID!
  ) {
    createConsultationNote(
      mainComplaint: $mainComplaint
      internalNotes: $internalNotes
      commentForParents: $commentForParents
      consultationId: $consultationId
    ) {
      consultationNote {
        id
        mainComplaint
        internalNotes
        commentForParents
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  createConsultationNote: { consultationNote: ConsultationNote; returnMessage: ReturnMessage };
}

export interface Variables {
  mainComplaint: string;
  internalNotes: string;
  commentForParents: string;
  consultationId: number;
}
