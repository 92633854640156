import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { SaveButton } from '../../../style';

export const VideoRouteContainer = styled.div`
  display: flex;
  flex-direction: row;
  // background-color: #f9f9f9;
`;

export const SideBarContainer = styled.div`
  min-width: 300px;
  width: 100%;
  border-left: 1px solid rgb(247, 249, 250);
  right: 0;
  width: 25%;
  height: calc(100vh - 48px);
  position: sticky;
  top: 0;
  // padding-right: 24px;
  // padding-top: 24px;
  display: none;
  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

export const VideoListScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 54px);
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: -8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 8px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background: #bcc0c4;
    }
  }
`;

export const VideoContainer = styled.div`
  // max-width: calc((100vh - (56px + 24px + 136px)) * (16 / 9));
  // min-width: calc(360px * (16 / 9));
  display: flex;
  flex-direction: column;
  // padding: 24px 24px 0 0;
  // margin-left: 24px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: 75%;
  }
`;

export const LibraryVideoContainer = styled.div`
  // max-width: calc((100vh - (56px + 24px + 136px)) * (16 / 9));
  // min-width: calc(360px * (16 / 9));
  display: flex;
  flex-direction: column;
  // padding: 24px 24px 0 0;
  // margin-left: 24px;
  width: 100%;
`;

export const QnAVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Player = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const VideoWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  height: initial;
  min-height: initial;
  max-height: initial;
  padding-bottom: 56.25%; /* 16:9 */
  @media only screen and (min-width: 992px) {
    position: relative;
    z-index: 0;
    height: 100%;
    min-height: 40rem;
    max-height: calc(100vh - 29rem);
    padding-bottom: 0;
  }
`;

// export const ContentTitleContainer = styled.div`
//   display: block;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//   padding: 20px 24px 8px 24px;
// `;

export const DescriptionSection = styled.div`
  margin-bottom: 14px;
`;

export const OverviewContainer = styled.div`
  padding: 32px 16px 0 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TitleContainer = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

export const TabMenuContainer = styled.div`
  padding: 16px 0 0 0;
  min-height: 800px;
  @media only screen and (min-width: 992px) {
    padding: 16px 16px 0 16px;
  }
`;

export const GoToExternalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(247, 249, 250);
`;

export const GlassMaskContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const GlassMask = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

export const GoToExternalButton = styled(SaveButton)`
  z-index: 1;
`;

export const DefaultImg = styled.img`
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 992px) {
    width: 560px;
    height: 315px;
  }
`;

export const ExternalIcon = styled(Icon)`
  align-self: baseline;
  margin: 0 0 0 4px !important;
  font-size: 1.4rem !important;
`;

export const ButtonTextContainer = styled.div`
  display: flex;
`;
