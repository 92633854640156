import React from 'react';
import { ActionTitle } from '../../../style';
import { TabMenuContainer, TabMenuItem, TabContent, HighlightBottomEdge } from './styles';

type Props = {
  tabTitles: Array<string>;
  tabContents: React.ReactNode[];
  selection: number;
  handleTabClick: (index: number, title: string) => void;
};

const TabMenu = (props: Props) => {
  const { selection, tabTitles, tabContents, handleTabClick } = props;

  return (
    <>
      <TabMenuContainer>
        {tabTitles.map((title, index) => {
          return (
            <HighlightBottomEdge isSelected={index === selection}>
              <TabMenuItem active={index === selection} onClick={() => handleTabClick(index, title)}>
                <ActionTitle>{title}</ActionTitle>
              </TabMenuItem>
            </HighlightBottomEdge>
          );
        })}
      </TabMenuContainer>
      <TabContent>{tabContents[selection]}</TabContent>
    </>
  );
};

export default TabMenu;
