import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const ACCEPT_CONSULTATION_MUTATION = gql`
  mutation AcceptConsultation($consultationId: ID!) {
    acceptConsultation(consultationId: $consultationId) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  acceptConsultation: { returnMessage: ReturnMessage };
}

export interface Variables {
  consultationId: number;
}
