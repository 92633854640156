import React, { useState, ReactNode, useContext } from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { Button } from 'semantic-ui-react';
import { RoundBorderButton, BigTitle, Title, Text, PopUp } from './StyledComponents';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Consultation_consultation_child_goals } from '../../../../graphql/queries/types/Consultation';
import {
  UpdateGoalPreskillMastered,
  UpdateGoalPreskillMasteredVariables,
} from '../../../../graphql/mutations/types/UpdateGoalPreskillMastered';
// import { useMutation } from 'react-apollo';
import { UPDATE_GOAL_PRESKILL_MASTERED_MUTATION } from '../../../../graphql/mutations/updateGoalPreskillMastered';
// import { Consultation_consultation_child_goals_preskills } from '../../../../graphql/queries/types/Consultation';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useMutation } from 'react-apollo';
import { Consultation_consultation_child_goals_preskills } from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import { FAST_POLL } from '../constants';
import { REMOVE_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeActivitiesToChild';
import {
  RemoveActivitiesToChild,
  RemoveActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/RemoveActivitiesToChild';

interface Props {
  goal: Consultation_consultation_child_goals;
  skill: Consultation_consultation_child_goals_preskills;
  handleGoalAchieved: () => void;
  removeAllActivitesFromGoal: () => void;
  isGoalAchievedClicked: boolean;
  setIsGoalAchievedClicked: (isGoalAchievedClicked: boolean) => void;
}

const MARGIN_HEIGHT = 5;

const PopUpTextBox = styled.div`
  width: 425px;
`;

const mainSkillCheckedStyle = {
  border: '2.5px dashed teal',
  backgroundColor: '#60bcac',
  margin: '0.25px 0 0 0',
  cursor: 'default',
};

const preSkillCheckedStyle = {
  border: '2.5px solid #60bcac',
  backgroundColor: '#60bcac',
  margin: '0.25px 0 0 0',
  cursor: 'default',
};

const uncheckedStyle = {
  border: '2.5px dashed teal',
  backgroundColor: 'lightgrey',
  opacity: '0.5',
  margin: '0.25px 0 0 0',
};

const iconStyle = {
  color: 'white',
};

const SkillCheckbox = ({
  goal,
  skill,
  removeAllActivitesFromGoal,
  isGoalAchievedClicked,
  setIsGoalAchievedClicked,
}: Props) => {
  const { consultation, setPollingCount } = useContext(TherapistConsultationScreenContext);

  const curGoal = consultation.child.goals.find((g) => {
    return g.id === goal.id;
  });

  const preSkills = curGoal?.preskills.filter((s) => s.id !== goal.skill.id);

  const curSkill = curGoal?.goalPreskills.find((pS) => pS.skillId === skill.id);

  const isAchieved = curSkill?.mastered;

  const [isChecked, setIsChecked] = useState<boolean | undefined>(isAchieved);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const isMainSkill = skill.id === goal.skill.id;

  console.log(consultation);

  const [removeActivitiesToChild, { data }] = useMutation<RemoveActivitiesToChild, RemoveActivitiesToChildVariables>(
    REMOVE_ACTIVITIES_TO_CHILD_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
    },
  );

  const handleRemoveAllPreSkillActivities = async () => {
    const skillIds = [curSkill?.skillId];

    const activitiesToRemove = consultation.child.activityChildren.filter((a) =>
      skillIds.includes(a.activity.mainSkill.id),
    );

    const activityIdsToRemove = activitiesToRemove.map((a) => a.activity.id);
    const consultationIdsToRemove = activitiesToRemove.map((a) => a.consultation.id);

    try {
      setPollingCount((prevCount) => prevCount + 1);
      await removeActivitiesToChild({
        variables: {
          activityIds: activityIdsToRemove,
          childId: consultation.child.id,
          consultationIds: consultationIdsToRemove,
        },
      });
    } catch (error) {
      console.error('Mutation error:', error);
    }

    return true;
  };

  const handleCheckboxClick = () => {
    if (!isChecked) setIsPopUpOpen(true);
  };

  const [updateGoalPreskillMastered] = useMutation<UpdateGoalPreskillMastered, UpdateGoalPreskillMasteredVariables>(
    UPDATE_GOAL_PRESKILL_MASTERED_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
    },
  );

  const updateSkillMastered = async () => {
    await updateGoalPreskillMastered({
      variables: {
        goalId: goal.id,
        skillId: skill.id,
      },
    });
  };

  const handlePopUpConfirmClick = async () => {
    await updateSkillMastered();
    if (isMainSkill) {
      removeAllActivitesFromGoal();
    } else {
      handleRemoveAllPreSkillActivities();
    }
    setIsGoalAchievedClicked(false);
    setIsPopUpOpen(false);
    setIsChecked(!isChecked);
  };

  const PopUpTitleText = ({ title, text }: { title: string; text: string }) => {
    return (
      <Row align="middle" style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
        <Col span={5}>
          <Title>{title}</Title>
        </Col>
        <Col span={19}>
          <Text>{text}</Text>
        </Col>
      </Row>
    );
  };

  const BigTitleDiv = ({ children }: { children: ReactNode }) => {
    return <BigTitle style={{ textAlign: 'center', margin: `${MARGIN_HEIGHT}px 0 0 0` }}>{children}</BigTitle>;
  };

  const TextDiv = ({ children }: { children: ReactNode }) => {
    return <Text style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>{children}</Text>;
  };

  const { t } = useTranslation();

  const Confirm = () => {
    return (
      <Row justify="center" style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
        <RoundBorderButton onClick={handlePopUpConfirmClick}>
          {t('therapist.consultationScreen.confirm')}
        </RoundBorderButton>
      </Row>
    );
  };

  const handlePopUpClickClose = () => {
    setIsPopUpOpen(false);
    setIsGoalAchievedClicked(false);
  };

  const MainSkillPopUp = () => {
    return (
      <PopUp onClickClose={handlePopUpClickClose}>
        <PopUpTextBox>
          <BigTitleDiv>{t('therapist.consultationScreen.goalAchieved')}</BigTitleDiv>

          <TextDiv>{t('therapist.consultationScreen.goalAchievedConfirmation')}</TextDiv>

          <PopUpTitleText title={t('therapist.consultationScreen.activities.goalSkill')} text={skill.name} />
          <PopUpTitleText
            title={t('therapist.consultationScreen.progress.goalCard.description')}
            text={goal.description}
          />
          <PopUpTitleText
            title={t('therapist.consultationScreen.progress.goalCard.objective')}
            text={`${goal.successNumerator} / ${goal.successDenominator} ${t(
              `therapist.consultationScreen.goalTable.metrics.${goal.metric}`,
            )}`}
          />
          {preSkills && (
            <PopUpTitleText
              title={t('therapist.consultationScreen.activities.preSkill')}
              text={preSkills.map((pS) => pS.name).join(', ')}
            />
          )}
          <div style={{ marginTop: '8px' }}>
            <Confirm />
          </div>
        </PopUpTextBox>
      </PopUp>
    );
  };

  const PreSkillPopUp = () => {
    return (
      <PopUp onClickClose={() => setIsPopUpOpen(false)}>
        <PopUpTextBox>
          <BigTitleDiv>{t('therapist.consultationScreen.preSkillAcquired')}</BigTitleDiv>

          <TextDiv>{t('therapist.consultationScreen.preSkillAcquiredConfirmation')}</TextDiv>

          <PopUpTitleText title={t('therapist.consultationScreen.activities.preSkill')} text={skill.name} />

          <Confirm />
        </PopUpTextBox>
      </PopUp>
    );
  };

  const CheckBox = () => {
    return (
      <Button
        icon
        circular
        size="medium"
        style={isChecked ? (isMainSkill ? mainSkillCheckedStyle : preSkillCheckedStyle) : uncheckedStyle}
        onClick={handleCheckboxClick}
      >
        {isChecked ? (
          <StarFilled style={iconStyle} translate={undefined} />
        ) : (
          <StarOutlined style={iconStyle} translate={undefined} />
        )}
      </Button>
    );
  };

  return (
    <div>
      {(isPopUpOpen || isGoalAchievedClicked) && (isMainSkill ? <MainSkillPopUp /> : <PreSkillPopUp />)}
      <CheckBox />
    </div>
  );
};

export default SkillCheckbox;
