import moment from 'moment-timezone';

// Refer to https://momentjs.com/timezone/docs/ to change/overwrite new abbrs text
var abbrs = {
  '+01': 'UTC+01',
  '-03': 'UTC-03',
  '-04': 'UTC-04',
  '-05': 'UTC-05',
  '-02': 'UTC-02',
  '+00': 'UTC+00',
  '+11': 'UTC+11',
  '+07': 'UTC+07',
  '+10': 'UTC+10',
  '+05': 'UTC+05',
  '+03': 'UTC+03',
  '+02': 'UTC+02',
  '+06': 'UTC+06',
  '+12': 'UTC+12',
  '+04': 'UTC+04',
  '+08': 'UTC+08',
  '+09': 'UTC+09',
  '+0530': 'UTC+0530',
  '+0430': 'UTC+0430',
  '+0545': 'UTC+0545',
  '+0630': 'UTC+0630',
  '+0330': 'UTC+0330',
  '-01': 'UTC-01',
  '+0845': 'UTC+0845',
  '+1030': 'UTC+1030',
  '-06': 'UTC-06',
  '-10': 'UTC-10',
  '-11': 'UTC-11',
  '-12': 'UTC-12',
  '-07': 'UTC-07',
  '-08': 'UTC-08',
  '-09': 'UTC-09',
  '+13': 'UTC+13',
  '+14': 'UTC+14',
  '+1245': 'UTC+1245',
  '-0930': 'UTC-0930',
  GMT: 'UTC',
};

moment.fn.zoneName = function () {
  // overwrite default abbr when using zz: e.g. '+14' to 'UTC+14'
  var abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

function displayAllTz() {
  // for testing only
  const tzList = moment.tz.names(); // list of all timezones

  for (const id in tzList) {
    const tzName = tzList[id];
    const abbr = moment().tz(tzName).format('z');
    console.log(id, abbr, tzName);
  }
}

export function getAllTzDropDown() {
  const tzList = moment.tz.names();

  type TimeZone = {
    key: string;
    value: string;
    text: string;
  };

  const timeZoneList: TimeZone[] = [];

  for (const id in tzList) {
    const tzName = tzList[id];
    const abbr = moment().tz(tzName).format('zz');

    timeZoneList.push({
      key: id,
      value: tzName,
      text: `${tzName} (${abbr})`,
    });
  }
  return timeZoneList;
}

const timeFormat = 'h:mm a';

function getDateFormat(locale: string): string {
  switch (locale) {
    case 'en':
      return 'YYYY-MM-DD';
    default:
      return 'LL'; // for other locales such as chinese
  }
}

function getFullDateTimeFormat(locale: string): string {
  return `${getDateFormat(locale)}, ${timeFormat} (zz)`;
}

export function getUserBrowserTz(): string {
  // guess user's timezone via their browser
  return moment.tz.guess(true); // true: ignore browser's cache
}

function momentToStr(m: moment.Moment, timeZone: string, strFormat: string): string {
  return m.tz(timeZone).format(strFormat);
}

export function momentToFullDateTime(m: moment.Moment, timeZone: string, locale: string): string {
  return momentToStr(m.locale(locale), timeZone, getFullDateTimeFormat(locale));
}

export function momentToTzDate(m: moment.Moment, timeZone: string, locale: string): string {
  return momentToStr(m.locale(locale), timeZone, getDateFormat(locale));
}

export function utcToFullDateTime(utcTime: string, timeZone: string, locale: string): string {
  return momentToFullDateTime(moment(utcTime), timeZone, locale);
}

export function utcToDate(utcTime: string, timeZone: string, locale: string): string {
  return momentToTzDate(moment(utcTime), timeZone, locale);
}

export function tzToAbbr(tz: string): string {
  return moment().tz(tz).format('zz');
}
