import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import { ChildTasks_child_assignedActivities } from '../../../../../../../../graphql/queries/types/ChildTasks';
import { renderStringToList } from '../../../../../../../../util/renderStringToList';

interface Props {
  activity: ChildTasks_child_assignedActivities;
}

const SetupTab = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const notes = renderStringToList(activity.notes);

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.frequency`)}</Title>
        <Text>{activity['frequency'] != null ? activity['frequency'] : t(`client.task.activities.noConstraint`)}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.scenario`)}</Title>
        <Text>{activity['scenario'] != null ? activity['scenario'] : t(`client.task.activities.noConstraint`)}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.tools`)}</Title>
        <Text>{activity['tools'] != null ? activity['tools'] : t(`client.task.activities.noConstraint`)}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.tips`)}</Title>
        <Text>{activity.parentReaction}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.notes`)}</Title>
        <Text>{notes}</Text>
      </div>
    </Space>
  );
};

export default SetupTab;
