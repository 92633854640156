import React, { useMemo, useState } from 'react';
import { DashboardEmptyStateSegment, DashboardEmptyStateContainer } from '../styles';
import { Client_client_children_goals } from '../../../../graphql/queries/types/Client';
import { useTranslation } from 'react-i18next';
import { ContentTitle, EmtpyStateContent } from '../../../../style';
import GoalCard from './GoalCard';
import { GoalCardContainer, GoalCardScrollContainer } from './styles';
import GoalModal from './GoalModal';

interface Props {
  goals: Array<Client_client_children_goals>;
  productType: string;
}

const Goal = ({ goals, productType }: Props) => {
  const { t } = useTranslation();
  const emptyState = goals.length === 0;
  const reverseGoal = [...goals].reverse();
  const [showGoalModal, setShowGoalModal] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<string>(goals[0]?.id);
  const [modalStep, setModalStep] = useState<string>('one');

  const handleGoalModalOpen = useMemo(
    () => (id: string, step: string) => {
      setShowGoalModal(true);
      setGoalId(id);
      setModalStep(step);
    },
    [],
  );

  const handleGoalModalClose = useMemo(
    () => () => {
      setShowGoalModal(false);
    },
    [],
  );

  return (
    <>
      <GoalModal
        show={showGoalModal}
        goals={goals}
        goalId={goalId}
        productType={productType}
        handleClose={handleGoalModalClose}
        modalStep={modalStep}
      />
      {!emptyState && (
        <GoalCardContainer>
          <GoalCardScrollContainer>
            <GoalCard
              productType={productType}
              goals={reverseGoal}
              handleGoalModalOpen={handleGoalModalOpen}
              height={200}
              line={3}
              marginBottom={0}
              marginRight={15}
              step={'one'}
            />
          </GoalCardScrollContainer>
        </GoalCardContainer>
      )}
      {emptyState && (
        <DashboardEmptyStateSegment>
          <DashboardEmptyStateContainer>
            <ContentTitle>{t('client.dashboard.goal.emptyStateTitle')}</ContentTitle>
            <EmtpyStateContent>{t('client.dashboard.goal.emptyStateContent')}</EmtpyStateContent>
          </DashboardEmptyStateContainer>
        </DashboardEmptyStateSegment>
      )}
    </>
  );
};

export default Goal;
