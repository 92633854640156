import React from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_goals } from '../../../../../graphql/queries/types/Client';
import { SmallDescription } from '../../../../../style';
import {
  CurrentIndicatorText,
  IndicatorText,
  ProgressBar,
  ProgressBarIndicator,
  ProgressBarValue,
  ProgressFlexContainer,
} from './styles';

interface Props {
  goal: Client_client_children_goals;
  height: number;
}

const GoalProgressBar = ({ goal, height }: Props) => {
  const { t } = useTranslation();
  const goalNumerator = goal.currentNumerator - goal.initNumerator;
  const goalDenominator = goal.successNumerator - goal.initNumerator;
  const goalProgress = goalNumerator / goalDenominator;
  const goalProgressNumber = isNaN(goalProgress) ? 0 : goalProgress;
  const goalPercentageWidth = Math.round((goalProgressNumber <= 1 ? goalProgressNumber : 1) * 100);
  const goalPercentage = Math.round(goalProgressNumber * 100);
  return (
    <>
      <ProgressBarIndicator>
        <IndicatorText>{goal.initNumerator}</IndicatorText>
        <IndicatorText>{goal.successNumerator}</IndicatorText>
      </ProgressBarIndicator>
      <CurrentIndicatorText marginLeft={goalPercentageWidth}>{goal.currentNumerator}</CurrentIndicatorText>
      <ProgressBar height={height}>
        <ProgressBarValue height={height} width={goalPercentageWidth} />
      </ProgressBar>
      <ProgressFlexContainer>
        <SmallDescription>
          {goalNumerator}/{goalDenominator} {t(`client.program.goal.progressBar.metrics.${goal.metric}`)}
        </SmallDescription>
        <SmallDescription>
          {goalPercentage}
          {'%'}
        </SmallDescription>
      </ProgressFlexContainer>
    </>
  );
};

export default GoalProgressBar;
