import React, { useContext, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useMutation } from 'react-apollo';
import { CREATE_NOTES_MUTATION } from '../../../../graphql/mutations/createConsultationNote';
import {
  CreateConsultationNote,
  CreateConsultationNoteVariables,
} from '../../../../graphql/mutations/types/CreateConsultationNote';
import { useTranslation } from 'react-i18next';
import { ReturnMessage } from '../../../../types';
import ErrorPage from '../../../common/ErrorPage';

const TherapistConsultationScreenNotesComponent = () => {
  const {
    consultation: { id },
    setActiveItem,
    notes,
    setNotes,
    setReturnMessage,
  } = useContext(TherapistConsultationScreenContext);

  const [createConsultationNote, { loading, error, data }] = useMutation<
    CreateConsultationNote,
    CreateConsultationNoteVariables
  >(CREATE_NOTES_MUTATION);

  useEffect(() => {
    if (data && data.createConsultationNote) {
      setReturnMessage(data.createConsultationNote.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
      if (data.createConsultationNote.returnMessage.status == 'SUCCESS') {
        setActiveItem('LongerTermGoals');
      }
    }
  }, [data]); // <-- only update when data is changed

  const { t } = useTranslation();

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <h1>{t('system.loading')}</h1>;
  }

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setNotes({
      ...notes,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleNext = async () => {
    const { mainComplaint, internalNotes, commentForParents } = notes;
    if (!mainComplaint || !internalNotes) {
      if (!mainComplaint)
        setReturnMessage({
          message: t('therapist.consultationScreen.notes.noMainConcern'),
          status: 'false',
        } as ReturnMessage);
      if (!internalNotes)
        setReturnMessage({
          message: t('therapist.consultationScreen.notes.noInternalNotes'),
          status: 'false',
        } as ReturnMessage);

      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
      return;
    }
    await createConsultationNote({
      variables: {
        mainComplaint,
        internalNotes,
        commentForParents,
        consultationId: id,
      },
    });
  };

  const { mainComplaint, internalNotes } = notes;
  return (
    <>
      <TherapistConsultationScreenNavArrows onClickNext={() => handleNext()} />

      <Form>
        <h3>{t('therapist.consultationScreen.notes.mainConcern')}</h3>
        <Form.TextArea
          rows={2}
          name="mainComplaint"
          value={mainComplaint}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => handleChange(e)}
          style={{
            fontSize: '1.4rem',
            resize: 'none',
            borderRadius: '5px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          }}
          data-cy="mainConcernTextArea"
        />
        <h3>{t('therapist.consultationScreen.notes.internalNotes')}</h3>
        <Form.TextArea
          rows={8}
          name="internalNotes"
          value={internalNotes}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => handleChange(e)}
          style={{
            fontSize: '1.4rem',
            resize: 'none',
            borderRadius: '5px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          }}
          data-cy="internalNotesTextArea"
          placeholder={t('therapist.consultationScreen.notes.internalNotesWordLimit')}
        />
      </Form>
    </>
  );
};

export default TherapistConsultationScreenNotesComponent;
