import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionTitle } from '../../../../../style';
import { AddIcon, AddWordButton } from './styles';
export type AddButtonProps = {
  handleOpenAddWordModal: () => void;
};

export const AddButton = ({ handleOpenAddWordModal }: AddButtonProps) => {
  const { t } = useTranslation();
  return (
    <AddWordButton onClick={handleOpenAddWordModal}>
      <AddIcon className="plus" />
      <ActionTitle>{t('client.wordTracking.toolBox.add')}</ActionTitle>
    </AddWordButton>
  );
};
