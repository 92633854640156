import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenSearch from '../../assets/search.svg';
import { SearchBarContainer, SearchIcon, SearchBarInput } from './styles';
export type SearchBarProps = {
  searchString: string;
  setSearchString: (value: string) => void;
};

export const SearchBar = ({ searchString, setSearchString }: SearchBarProps) => {
  const { t } = useTranslation();
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.currentTarget.value);
  return (
    <SearchBarContainer>
      <SearchIcon src={GreenSearch} />
      <SearchBarInput
        value={searchString}
        onChange={onInputChange}
        placeholder={t('client.wordTracking.toolBox.search')}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
