import styled from 'styled-components';
import { Description, H2Title } from '../../../style';

export const SampleModalText = styled.div``;

export const HeaderTitle = styled(H2Title)`
  color: #5abaae;
`;

export const ContentText = styled(Description)`
  color: #030303;
`;
