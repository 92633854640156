import styled from 'styled-components';

export const HomeworkBox = styled.div`
  margin: 0px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
