import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { ChildTasks_childConsultations_activities } from '../../../../../../graphql/queries/types/ChildTasks';
import { findCatOrderIndexByCatId } from '../../../../../../util/Category';
import { DataContainer } from '../../../../../Client/WordTrackingRoute/WordsTable/styles';
import { TherapistChildContext } from '../../../TherapistChildContext';
import { ActivityRowContainer, EllipsisData, ActivityStickyColumnContainer, CategoryBadge } from '../styles';

interface Props {
  activity: ChildTasks_childConsultations_activities;
  checkInCount: number | null;
  assignedTime: string;
}

const ActivityRow = ({ activity, checkInCount, assignedTime }: Props) => {
  const { t } = useTranslation();
  const catOrderIndex = findCatOrderIndexByCatId(activity.mainSkill.category.id);
  const { setShowActivityModal } = useContext(TherapistChildContext);
  const match = useRouteMatch();
  const history = useHistory();

  if (!match) {
    return <Redirect to="/" />;
  }

  const handleShowActivityModal = (activityId: string) => {
    setShowActivityModal(true);
    history.push(`${match.url}?activity=${activityId}`);
  };

  return (
    <ActivityRowContainer onClick={() => handleShowActivityModal(activity.id)} isChecked={false}>
      <ActivityStickyColumnContainer isChecked={false}>
        <DataContainer center={false}>
          <EllipsisData>{activity.name}</EllipsisData>
        </DataContainer>
      </ActivityStickyColumnContainer>
      {/* <DataContainer center={false}>
        {checkInCount ? (
          <span style={{ color: '#5abaae' }}>{checkInCount}</span>
        ) : (
          <span style={{ color: 'red' }}>{checkInCount}</span>
        )}
      </DataContainer> */}
      <DataContainer center={false}>
        {checkInCount ? (
          <span style={{ color: '#5abaae' }}>{checkInCount}</span>
        ) : (
          <span style={{ color: 'red' }}>{checkInCount}</span>
        )}
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{assignedTime.substring(0, 10)}</EllipsisData>
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{activity.mainSkill.name}</EllipsisData>
      </DataContainer>
      <DataContainer center={false}>
        <CategoryBadge catIndex={catOrderIndex}>{activity.mainSkill.category.name}</CategoryBadge>
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{activity.mainSkill.subcategory.name}</EllipsisData>
      </DataContainer>
    </ActivityRowContainer>
  );
};

export default ActivityRow;
