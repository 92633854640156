import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { ChildTasks as Data, ChildTasksVariables as Variables } from '../../../../../graphql/queries/types/ChildTasks';
import { CHILD_TASKS } from '../../../../../graphql/queries/childTasks';
import Activity from '../../../../Client/DashboardRoute/Activity';
import { TherapistChildContext } from '../../TherapistChildContext';
import { Spin, Typography } from 'antd';
import { RouteContentContainer, LoadingContainer, RouteTitleContainer } from '../../layout/styles';
import { useTranslation } from 'react-i18next';

const DashboardRoute = () => {
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);
  const { loading, error, data } = useQuery<Data, Variables>(CHILD_TASKS, { variables: { childId } });
  const { Title } = Typography;
  const { t } = useTranslation();

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  if (error || !data) {
    return <p>Error...</p>;
  }

  const { childProgress } = data;

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.childInfo.dashboard.sectionTitle1')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        <Activity childProgress={childProgress} />
      </RouteContentContainer>
    </>
  );
};

export default React.memo(DashboardRoute);
