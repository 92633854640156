import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { AddWordModalMask, AddWordModalContainer, Header, ButtonContainer } from './styles';
import { AddWordData, AddWordVariables, ADD_WORD_MUTATION } from '../../../../../../../graphql/mutations/addWord';
import { Word, WordTracking, WordTrackingVariables } from '../../../../../../../graphql/queries/types/WordTracking';
import { ClientContext } from '../../../../../ClientContext';
import { ActionTitle, HeaderTitle, CancelButton, SaveButton, Input } from '../../../../../../../style';
import { CHILD_WORD_TRACKING_QUERY } from '../../../../../../../graphql/queries/childTrackingWords';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  insertNewWord: (word: Word) => void;
};

const AddWordModal = ({ showModal, insertNewWord, handleClose }: Props) => {
  const { t } = useTranslation();
  const wordInputRef = useRef() as React.RefObject<HTMLInputElement>;
  const { child } = useContext(ClientContext);
  const [word, setWord] = useState('');
  const [addWord] = useMutation<AddWordData, AddWordVariables>(ADD_WORD_MUTATION, {
    update(cache, { data }) {
      const wordData = cache.readQuery<WordTracking, WordTrackingVariables>({
        variables: { childId: child.id },
        query: CHILD_WORD_TRACKING_QUERY,
      });
      if (wordData != null && data != null) {
        wordData.words = [...wordData.words, data.addWord.word];
        cache.writeQuery<WordTracking, WordTrackingVariables>({
          variables: { childId: child.id },
          query: CHILD_WORD_TRACKING_QUERY,
          data: wordData,
        });
        insertNewWord(data.addWord.word);
        setWord('');
        handleClose();
      }
    },
  });

  useEffect(() => {
    if (wordInputRef.current != null && showModal) {
      wordInputRef.current.focus();
    }
  }, [showModal]);

  const onWordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  };

  const onCloseClick = () => {
    setWord('');
    handleClose();
  };

  const onSubmitClick = () => {
    if (word === '') {
      return;
    }
    addWord({
      variables: {
        childId: parseInt(child.id),
        word,
      },
    });
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitClick();
    }
  };

  return (
    <AddWordModalMask open={showModal}>
      <AddWordModalContainer>
        <Header>
          <HeaderTitle>{t('client.wordTracking.modal.add.title')}</HeaderTitle>
        </Header>
        <Input ref={wordInputRef} onChange={onWordInput} value={word} onKeyDown={onKeyDown} />
        <ButtonContainer>
          <CancelButton onClick={onCloseClick}>
            <ActionTitle>{t('client.wordTracking.modal.add.close')}</ActionTitle>
          </CancelButton>
          <SaveButton onClick={onSubmitClick}>
            <ActionTitle>{t('client.wordTracking.modal.add.submit')}</ActionTitle>
          </SaveButton>
        </ButtonContainer>
      </AddWordModalContainer>
    </AddWordModalMask>
  );
};

export default AddWordModal;
