import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Description, SmallActionTitle } from '../../../../../../../style';

export const SelectedColor = '#244a46';
export const SelectedBackgroundColor = '#eff8f7';

export const DropDownContainer = styled.div`
  width: max-content;
`;

export const DropDownHeader = styled.div<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 25px;
  margin: 15px 0 0 0;
  align-items: center;
  padding: 3px 10px;
  display: flex;
  // justify-content: space-between;
  background: ${({ selected }) => (selected ? SelectedBackgroundColor : 'white')};
  border: ${({ selected }) => (selected ? `solid 1px ${SelectedColor}` : 'solid 1px rgba(0, 0, 0, 0.1)')};
  :hover {
    border: solid 1px ${SelectedColor};
    background: ${SelectedBackgroundColor};
  }
  max-width: 250px;
`;

export const HeaderText = styled(Description)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? SelectedColor : '#030303')};
  // width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
`;

export const DropdownArrowContainer = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: flex;
  padding-bottom: 5px;
`;
export const DropdownArrow = styled(Icon)`
  position: absolute;
  right: 10px;
  color: #909090;
  margin: 0 !important;
  align-self: center;
`;
export const CalendarIcon = styled(Icon)<{ selected: boolean }>`
  margin: 0 !important;
  color: ${({ selected }) => (selected ? SelectedColor : '#030303')};
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  margin-top: 10px;
`;

export const DropDownList = styled.ul`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border-radius: 8px;
  margin: 0;
  min-width: fit-content;
  min-height: fit-content;
  max-height: 250px;
  width: max-content;
  background: white;
  overflow-y: scroll;
  box-sizing: border-box;
  list-style-type: none;
  text-align: left;
  padding: 10px;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

export const ListItem = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  list-style-type: none;
  padding: 0 12px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? SelectedBackgroundColor : 'white')};
  :hover {
    background: ${SelectedBackgroundColor};
  }
  // : first-child {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }
  // : last-child {
  //   border-bottom-left-radius: 4px;
  //   border-bottom-right-radius: 4px;
  // }
`;

export const ListItemText = styled(SmallActionTitle)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? SelectedColor : 'inherit')};
  @media (hover: hover) and (pointer: fine) {
    ${ListItem}: hover & {
      color: ${SelectedColor};
    }
  }
`;

export const Placeholder = styled(Description)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? SelectedColor : '#030303')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
`;
