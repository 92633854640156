import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';
import { ChildBasicInfo_child_ChildSixMonthGoal } from '../../../../../graphql/queries/types/ChildBasicInfo';
import NoteEditor from '../../../../common/NoteEditor';
import { CREATE_SIX_MONTH_GOAL_MUTATION } from '../../../../../graphql/mutations/createSixMonthGoal';
import {
  CreateSixMonthGoalVariables,
  CreateSixMonthGoal,
} from '../../../../../graphql/mutations/types/CreateSixMonthGoal';
import { CONSULTATION_QUERY } from '../../../../../graphql/queries/consultation';
import TherapistConsultationScreenContext from '../../TherapistConsultationScreenContext';

interface Props {
  childSixMonthGoal: ChildBasicInfo_child_ChildSixMonthGoal | null;
  childId: string;
  consultationId: string;
}

const SixMonthGoal = ({ childSixMonthGoal, childId, consultationId }: Props) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<boolean>(false);
  const [goal, setGoal] = useState<string>(childSixMonthGoal ? childSixMonthGoal.description : '');
  const [createSixMonthGoal, { loading, error, data }] = useMutation<CreateSixMonthGoal, CreateSixMonthGoalVariables>(
    CREATE_SIX_MONTH_GOAL_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultationId } }],
    },
  );

  const {
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
  } = useContext(TherapistConsultationScreenContext);

  const handleCancelEdit = () => {
    setEditing(false);
    setGoal(childSixMonthGoal ? childSixMonthGoal.description : '');
  };

  const updateSixMonthGoal = async () => {
    await createSixMonthGoal({
      variables: {
        description: goal,
        childId,
      },
    });
    setEditing(false);
  };

  return (
    <div style={{ marginTop: '15px' }}>
      {!editing && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '18px', fontWeight: 'bolder' }}>
              {t('therapist.dashboard.myClient.card.sixMonthGoal.title')}
            </div>
            {
              <div
                style={{ fontSize: '18px', cursor: 'pointer', color: '#4183c4' }}
                onClick={() => {
                  setEditing(true);
                }}
              >
                {t('therapist.dashboard.myClient.card.sixMonthGoal.edit')}
              </div>
            }
          </div>

          <div style={{ margin: '0px 0 10px 0' }}>
            <p>{`${t('therapist.dashboard.myClient.card.sixMonthGoal.6MonthsOrMore')}`}</p>
          </div>

          <div style={{ border: '1px solid #999999', padding: '3px' }}>
            {goal ? (
              ReactHtmlParser(goal)
            ) : (
              <span style={{ fontSize: '15px', fontWeight: 350 }}>
                {`${t('therapist.dashboard.myClient.card.sixMonthGoal.noGoal')}`}
              </span>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <p style={{ fontSize: '18px', fontWeight: 'bolder' }}>
            {`${t('therapist.dashboard.myClient.card.sixMonthGoal.title')} (${t(
              'therapist.dashboard.myClient.card.sixMonthGoal.wordLimit',
            )})`}
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ fontSize: '18px', cursor: 'pointer', color: '#4183c4' }} onClick={handleCancelEdit}>
              {t('therapist.dashboard.myClient.card.sixMonthGoal.cancelEdit')}
            </p>
            <p style={{ fontSize: '18px', cursor: 'pointer', color: '#4183c4' }} onClick={updateSixMonthGoal}>
              {t('therapist.dashboard.myClient.card.sixMonthGoal.finishEdit')}
            </p>
          </div>
          <NoteEditor initialString={goal} placeholder={``} setNote={setGoal} />
        </div>
      )}
    </div>
  );
};

export default SixMonthGoal;
