import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const options = {
  credentials: 'include',
  uri: process.env.REACT_APP_GRAPHQL_URI,
};

// const httpLink = createHttpLink(options);
const uploadLink = createUploadLink(options);

const link = ApolloLink.from([errorLink, uploadLink]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link,
});

export default client;
