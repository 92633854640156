import gql from 'graphql-tag';

export const GET_NEXT_HOMEWORK_QUERY = gql`
  query GetNextHomework($childId: ID!) {
    getNextHomework(childId: $childId) {
      id
      description
      format
      submissionDate
    }
  }
`;
