import gql from 'graphql-tag';

export const GET_NOTIFICATIONS_QUERY = gql`
  query GetNotifications($userId: ID!) {
    getNotifications(userId: $userId) {
      id
      body
      read
    }
  }
`;
