import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

// container
export const LatestContainer = styled(Segment)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px !important;
  padding: 25px 20px 25px 20px !important;
  color: #5abaae;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset !important;
`;
export const FutureContainer = styled(Segment)`
  border-radius: 15px !important;
  padding: 25px 20px 25px 20px !important;
`;
export const OldContainer = styled(Segment)`
  border-radius: 15px !important;
  padding: 25px 20px 25px 20px !important;
  margin-top: 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TimeContainer = styled.div`
  width: 45px;
`;
export const FlexContainer = styled.div`
  display: flex;
`;

// title
export const QATitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

// time
export const FutureDate = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
`;
export const LatestDate = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
  color: #5abaae;
`;
export const StartTime = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
export const EndTime = styled.div`
  color: #00000085;
  font-size: 12px;
  margin-top: 5px;
`;

// line
export const DividerLIne = styled.div`
  width: 0.5px;
  height: 40px;
  background-color: #00000040;
  margin: 0 20px;
`;

// button
export const InformationQnaButton = styled.div<{ isAvailable: boolean }>`
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: ${(props) => (props.isAvailable ? 'visible' : 'none')};
  padding: 10px 15px 10px 15px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: white;
  background-color: #5abaae;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const ReplayButton = styled.div<{ replayAvailable: boolean; readStatus: boolean }>`
  width: 80px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 10px 15px 10px 15px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${(props) => (props.replayAvailable ? (props.readStatus ? '#5ABAAE' : 'white') : '#00000085')};
  background-color: ${(props) => (props.replayAvailable ? (props.readStatus ? 'white' : '#5ABAAE') : '#E8E9E9')};
  border: ${(props) => (props.replayAvailable ? '1px solid #5ABAAE' : '1px solid #E8E9E9')};
  pointer-events: ${(props) => (props.replayAvailable ? 'cursor' : 'none')};
  :hover {
    box-shadow: ${(props) =>
      props.replayAvailable
        ? props.readStatus
          ? 'none'
          : 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        : 'none'};
  }
`;
