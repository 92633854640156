import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const UPLOAD_VIDEO_MUTATION = gql`
  mutation UploadVideo($consultationId: ID!, $fileName: String!) {
    uploadVideo(consultationId: $consultationId, fileName: $fileName) {
      uploadedVideo
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface UploadVideoData {
  uploadVideo: {
    uploadedVideo: string;
    returnMessage: ReturnMessage;
  };
}

export interface UploadVideoVariables {
  consultationId: number;
  fileName: string;
}
