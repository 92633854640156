import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { Modal, Button } from 'antd';
import CancelConsultationButton from './CancelConsultationButton';
import AcceptConsultationButton from './AcceptConsultationButton';
import SmsDropdownButton from './SmsDropdownButton';
import { Therapist_therapist_consultations } from '../../../../graphql/queries/types/Therapist';
import {
  SendSmsToClientBefConsultation,
  SendSmsToClientBefConsultationVariables,
} from '../../../../graphql/mutations/types/SendSmsToClientBefConsultation';
import { SEND_SMS_TO_CLIENT_BEF_CONSULTATION } from '../../../../graphql/mutations/sendSmsToClientBefConsultation';
import { openInNewTab, getAge } from '../../../../helpers/functions';
import { SHORTENED_BACKUP_LINK_EN, SHORTENED_BACKUP_LINK_CN } from '../../../../constants/backupLink';

import {
  TimelineEvent,
  Text,
  PreviousConcern,
  Wrapper,
  StartButton,
  StartVideoButton,
  AcceptedButton,
  MessageButton,
  DateTimeText,
  ConsultationInfoContainer,
  ConsultationButtonsContainer,
} from '../styles';
import { useTranslation } from 'react-i18next';
import { TherapistContext } from '../../TherapistContextt';

import MyTasksContext from '../MyTasksContext';
import ConfirmMsgModal from './ConfirmMsgModal';
import ErrorPage from '../../../common/ErrorPage';
import { CANCEL_CONSULTATION_MUTATION } from '../../../../graphql/mutations/cancelConsultation';
import { THERAPIST_CONSULTATIONS_QUERY } from '../../../../graphql/queries/therapist';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  CancelConsultation,
  CancelConsultationVariables,
} from '../../../../graphql/mutations/types/CancelConsultation';

interface Props {
  id: string;
  consultation: Therapist_therapist_consultations;
  therapistId: string;
}

const TimelineCard = ({ id, consultation, therapistId }: Props) => {
  const {
    therapist: { user, consultationUrl, cnConsultationUrl },
  } = useContext(TherapistContext);
  const { setReturnMessage } = useContext(TherapistContext);
  const {
    child: { id: childId, name, gender, dob, previousConsultation },
    consultationTime,
    smsLogs,
  } = consultation;

  const [backupLink] = useState(
    consultation.child.client.user.country === 'cn' ? SHORTENED_BACKUP_LINK_CN : SHORTENED_BACKUP_LINK_EN,
  );
  const [backupLinkAvailable, setBackupLinkAvailable] = useState(false);
  const [consultationIdOnCancel, setConsultationIdOnCancel] = useState('0');
  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const [cancelConsultationModalOpen, setCancelConsultationModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const history = useHistory();

  const [sendSmsToClientBefConsultation] = useMutation<
    SendSmsToClientBefConsultation,
    SendSmsToClientBefConsultationVariables
  >(SEND_SMS_TO_CLIENT_BEF_CONSULTATION);
  const today = new Date();

  useEffect(() => {
    for (const smsLog of smsLogs) {
      const smsLogDetails = smsLog.details !== '' ? JSON.parse(smsLog.details) : null;
      if (smsLogDetails && smsLogDetails.key === 3) {
        const smsDate = new Date(smsLog.createdAt);
        if ((today.getTime() - smsDate.getTime()) / (1000 * 60 * 60) <= 24) {
          setBackupLinkAvailable(true);
        }
      }
    }
  }, [smsLogs]);

  const { t } = useTranslation();
  const [cancelConsultation, { loading, error, data }] = useMutation<CancelConsultation, CancelConsultationVariables>(
    CANCEL_CONSULTATION_MUTATION,
    {
      refetchQueries: [
        { query: CONSULTATION_QUERY, variables: { consultationIdOnCancel } },
        { query: THERAPIST_CONSULTATIONS_QUERY, variables: { therapistId } },
      ],
    },
  );

  useEffect(() => {
    if (data && data.cancelConsultation) {
      setReturnMessage(data.cancelConsultation.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]);

  if (loading) {
    return <p>{t('system.loading')}</p>;
  }
  if (error) {
    return <ErrorPage />;
  }

  const consultationDateTime = new Date(consultationTime);

  const date = consultationDateTime.toLocaleDateString(user.locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const time = consultationDateTime.toLocaleTimeString(user.locale, { hour: '2-digit', minute: '2-digit' });

  const isToday: boolean =
    today.getDate() == consultationDateTime.getDate() &&
    today.getMonth() == consultationDateTime.getMonth() &&
    today.getFullYear() == consultationDateTime.getFullYear();

  const isFuture: boolean = consultationDateTime > today;
  const isPast: boolean = consultationDateTime < today;

  const handleClickStart = () => {
    history.push(`/consultation/${consultation.id}`);
  };

  const handleClickStartVideo = () => {
    const consultationLink = consultation.child.client.user.country === 'cn' ? cnConsultationUrl : consultationUrl;
    consultationLink && openInNewTab(consultationLink);
  };
  const handleClickBackupLink = () => {
    openInNewTab(backupLink);
  };
  const handleClickMessage = () => {
    history.push(`/child/${childId}/messages`);
  };

  const handleMsgModalOpen = () => {
    setMsgModalOpen(true);
  };

  const handleMsgModalClose = () => {
    setMsgModalOpen(false);
  };

  const setMsgOptions = (option) => {
    setOptions(option);
  };

  const sendSmsToClient = async (option) => {
    let msg = '';
    if (option.key === 3) {
      setBackupLinkAvailable(true);
      msg = option.msg + backupLink;
    } else {
      msg = option.msg;
    }
    await sendSmsToClientBefConsultation({
      variables: {
        consultationId: parseInt(consultation.id),
        msg: msg,
        details: JSON.stringify(option),
      },
    });
  };

  const openCancelConsultationModal = () => {
    setCancelConsultationModalOpen(true);
  };
  const closeCancelConsultationModal = () => {
    setCancelConsultationModalOpen(false);
  };
  const onCancelConsultation = () => {
    cancelConsultation({ variables: { consultationId: consultation.id } });
  };

  const disableScroll = () => {
    document.addEventListener('wheel', preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener('wheel', preventDefault, false);
  };

  const preventDefault = (e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };
  const tPath = 'therapist.dashboard.myTasks';
  const [year, month] = getAge(new Date(dob));
  return (
    <TimelineEvent>
      <DateTimeText>{isToday ? time : `${date}\n${time}`}</DateTimeText>
      <Modal
        title="Are you sure?"
        open={cancelConsultationModalOpen}
        onCancel={closeCancelConsultationModal}
        footer={[
          <Button key="back" type="ghost" onClick={closeCancelConsultationModal}>
            {t('therapist.dashboard.myTasks.buttons.no')}
          </Button>,
          <Button
            style={{ backgroundColor: '#FF7D7D', borderColor: '#FF7D7D' }}
            key="submit"
            type="primary"
            onClick={onCancelConsultation}
          >
            {t('therapist.dashboard.myTasks.buttons.yes')}
          </Button>,
        ]}
      >
        You are about to <b>CANCEL</b> this consultation, this action cannot be undone!
      </Modal>
      <Wrapper>
        <ConsultationInfoContainer>
          <Text size="1.2em" weight={200}>
            {name}
          </Text>
          <Text>{t(`therapist.genders.${gender}`)}</Text>
          <Text>
            {year} {t(`${tPath}.yearsOld`) + ' '}
            {month} {t(`${tPath}.monthOld`)}
          </Text>
          {/* Cancel button */}
          {/* {(isToday && consultation.accepted && consultation.published) ||
            (isFuture && !consultation.canceled && (
              <CancelConsultationButton
                consultationId={consultation.id}
                therapistId={therapistId}
                openCancelConsultationModal={openCancelConsultationModal}
              />
            ))} */}

          {/* <PreviousConcern text={t(`${tPath}.previousComplaint`)}>
            {previousConsultation && previousConsultation.notes
              ? previousConsultation.notes.mainComplaint
              : t(`${tPath}.incompleteNotes`)}
          </PreviousConcern> */}
        </ConsultationInfoContainer>
        {msgModalOpen && (
          <ConfirmMsgModal
            consultationId={id}
            modalHeader1={t('modal.header')}
            modalHeader2={t('modal.headerNoSubOption')}
            backupLink={backupLink}
            cancelText={'Exit'}
            actionText={'Send Message'}
            option={options}
            disableScroll={disableScroll}
            enableScroll={enableScroll}
            onCancel={handleMsgModalClose}
            onAction={sendSmsToClient}
          ></ConfirmMsgModal>
        )}
        <div>
          {consultation.canceled ? (
            <ConsultationButtonsContainer>Canceled</ConsultationButtonsContainer>
          ) : (
            <>
              {isFuture && !isToday && (
                <ConsultationButtonsContainer>
                  {consultation.accepted ? (
                    <AcceptedButton data-cy="acceptedButton">{t(`${tPath}.buttons.accepted`)}</AcceptedButton>
                  ) : (
                    <AcceptConsultationButton consultationId={consultation.id} />
                  )}
                </ConsultationButtonsContainer>
              )}
              {isToday && (
                <>
                  {consultation.accepted ? (
                    <>
                      {consultation.published ? (
                        <ConsultationButtonsContainer>
                          <MessageButton data-cy="messageButton" onClick={() => handleClickMessage()}>
                            {t(`${tPath}.buttons.message`)}
                          </MessageButton>
                          <StartButton onClick={() => handleClickStart()}>{t(`${tPath}.buttons.edit`)}</StartButton>
                        </ConsultationButtonsContainer>
                      ) : (
                        <ConsultationButtonsContainer>
                          {/* Start consultation */}
                          <StartVideoButton
                            isBackupLinkAvailable={backupLinkAvailable}
                            data-cy="startVideoButton"
                            disabled={backupLinkAvailable}
                            onClick={() => handleClickStartVideo()}
                          >
                            {t(`${tPath}.buttons.startVideo`)}
                          </StartVideoButton>

                          {backupLinkAvailable && (
                            <StartVideoButton data-cy="startVideoButton" onClick={() => handleClickBackupLink()}>
                              {t('button.yourBackupLink')}
                            </StartVideoButton>
                          )}
                          {/* Start notes */}
                          <StartButton data-cy="startButton" onClick={() => handleClickStart()}>
                            {t(`${tPath}.buttons.start`)}
                          </StartButton>
                          <SmsDropdownButton
                            therapist={true}
                            handleMsgModalOpen={handleMsgModalOpen}
                            setSms={setMsgOptions}
                          />
                        </ConsultationButtonsContainer>
                      )}
                    </>
                  ) : (
                    <ConsultationButtonsContainer>
                      <AcceptConsultationButton consultationId={consultation.id} />
                    </ConsultationButtonsContainer>
                  )}
                </>
              )}
              {isPast && !isToday && (
                <>
                  {consultation.accepted ? (
                    <>
                      {consultation.published ? (
                        <ConsultationButtonsContainer>
                          <MessageButton data-cy="messageButton" onClick={() => handleClickMessage()}>
                            {t(`${tPath}.buttons.message`)}
                          </MessageButton>
                          <StartVideoButton onClick={() => handleClickStart()}>
                            {t(`${tPath}.buttons.edit`)}
                          </StartVideoButton>
                        </ConsultationButtonsContainer>
                      ) : (
                        <ConsultationButtonsContainer>
                          <StartButton data-cy="startButton" onClick={() => handleClickStart()}>
                            {t(`${tPath}.buttons.start`)}
                          </StartButton>
                        </ConsultationButtonsContainer>
                      )}
                    </>
                  ) : (
                    <div>Consultation had past</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Wrapper>
    </TimelineEvent>
  );
};

export default TimelineCard;
