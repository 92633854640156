import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Client_client_children_goals,
  Client_client_children_goals_preskills,
} from '../../../../../../graphql/queries/types/Client';
import { SmallSectionTitle, UserComment } from '../../../../../../style';
import { ActivityMainSkill } from '../../../../TaskRoute/ActivityTab/ActivityModal/OverallTab/styles';
import GoalProgressBar from '../../GoalProgressBar';
import {
  GoalProgressSegment,
  SkillsContainer,
  DescriptionContainer,
  MoreDetailsButton,
  MoreDetailsContainer,
} from './styles';
import { CPC_PRODUCT_TYPES } from '../../../../../../util/Product';

interface Props {
  goal: Client_client_children_goals;
  productType: string;
  showMore: boolean;
  handleClick: () => void;
}

const OverallTab = ({ goal, productType, showMore, handleClick }: Props) => {
  const { t } = useTranslation();
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  return (
    <>
      {isCPC && (
        <>
          <SmallSectionTitle>{t('client.program.tasksGoalsCard.goal.popup.goalProgress')}</SmallSectionTitle>
          <MoreDetailsButton
            onClick={handleClick}
            data-duration={showMore ? '' : t('client.program.goal.progressBar.moreDetails')}
          >
            <GoalProgressSegment>
              {isCPC && <GoalProgressBar goal={goal} height={8} />}
              <MoreDetailsContainer show={showMore}>
                <UserComment>{`${t('client.program.goal.progressBar.initMetric')} ${goal.initNumerator} / ${
                  goal.currentDenominator
                }`}</UserComment>
                <UserComment>
                  {`${t('client.program.goal.progressBar.currentMetric')} ${goal.currentNumerator} / ${
                    goal.currentDenominator
                  }`}
                </UserComment>
                <UserComment>
                  {`${t('client.program.goal.progressBar.successMetric')} ${goal.successNumerator} / ${
                    goal.successDenominator
                  }`}
                </UserComment>
              </MoreDetailsContainer>
            </GoalProgressSegment>
          </MoreDetailsButton>
        </>
      )}
      <SmallSectionTitle>{t('client.program.tasksGoalsCard.goal.popup.goalDescription')}</SmallSectionTitle>
      <DescriptionContainer>
        <UserComment>{goal.description}</UserComment>
      </DescriptionContainer>
      <SmallSectionTitle>{t('client.program.tasksGoalsCard.goal.popup.preskills')}</SmallSectionTitle>
      <SkillsContainer>
        {goal.preskills.map((preskill: Client_client_children_goals_preskills) => (
          <ActivityMainSkill key={preskill.id}>{preskill.name}</ActivityMainSkill>
        ))}
      </SkillsContainer>
    </>
  );
};

export default OverallTab;
