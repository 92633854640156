import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionTitle, ModalContainer, ModalContent, ModalHeader, NextButton } from '../../../../style';
import { ContentText, HeaderTitle } from '../styles';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickNotShowMore: (notificationId: string) => void;
  notificationId: string;
}

const UpdateTermsAnnouncement2022 = ({ handleClickNotShowMore, notificationId }: Props) => {
  const { t } = useTranslation();
  return (
    <ModalContainer>
      <ModalHeader>
        <HeaderTitle>{t('modalNotification.UpdateTermsAnnouncement2022.header')}</HeaderTitle>
      </ModalHeader>
      <ModalContent>
        <ContentText>{t('modalNotification.UpdateTermsAnnouncement2022.content')}</ContentText>
      </ModalContent>
      <NextButton
        status={true}
        onClick={() => {
          handleClickNotShowMore(notificationId);
        }}
      >
        <ActionTitle>{t('modalNotification.UpdateTermsAnnouncement2022.button')}</ActionTitle>
      </NextButton>
    </ModalContainer>
  );
};

export default UpdateTermsAnnouncement2022;
