import styled from 'styled-components';

/*
        index.js
*/

export const ConsultationScreenGrid = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  grid-auto-rows: 1fr;
  padding: 10px;
  gap: 30px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
  }
`;

export const Segment = styled.div`
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  font-size: 0.9em;
  margin: 10px 0;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const GoalCardGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  margin: 5px 0;
  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 35% 65%;
  }
`;

/*
        Tasks/SelectActivitiesComponent.tsx
*/

export const SelectActivitiesContainer = styled.div`
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5em;
  border-radius: 5px;
`;

export const Title = styled.h2`
  margin-left: 15px;
  padding-left: 20px;
  border-left: 5px solid #5bd5ca;
`;

export const SelectActivitiesGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BoldText = styled.p`
  font-weight: bold;
`;

export const SelectActivitiesDiv = styled.div`
  justify-content: space-between;
  margin: 15px 5px 15px 5px;
  padding: 15px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
`;

export const SelectActivitiesInfoColumn = styled.div`
  width: 80%;
`;

export const SelectActivitiesInfoColumnContent = styled.div`
  width: 100%;
  padding: 3px 0;
`;

export const SelectActivitiesRemoveColumn = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectActivitiesRemoveColumnButton = styled.div`
  border-radius: 25px;
  margin: 15px 10px;
  color: white;
  background-color: #5dd9c4;
  cursor: pointer;
  height: 40px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  :hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const AddActivitiesButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  background-color: #47e4c2;
  color: white;
  /* width: 100%; */

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

export const RemoveActivitiesBtn = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;

  background-color: #ff6d93;
  color: white;
  /* width: 100%; */

  @media only screen and (max-width: 600px) {
    display: inline-block;
    width: auto;
  }
`;

/*
        Tasks/PreskillsComponent.tsx
*/

export const SelectSkillsText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #020202;
`;

export const PreskillsSegment = styled.div`
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0 10px 0;
`;

export const PreskillTitle = styled(SelectSkillsText)`
  color: black;
  text-align: center;
`;

export const PreskillsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PreskillDiv = styled.div<{ mastered: boolean }>`
  margin: 5px;
  cursor: ${({ mastered }) => (mastered ? 'inherit' : 'pointer')};
  color: ${({ mastered }) => (mastered ? 'gray' : '#000000')};
`;

/*
        Tasks/HomeworkComponent.tsx
*/

// export const HomeworkDiv = styled.div<{ hidden: boolean }>`
//   display: ${({ hidden }) => (hidden ? 'none' : 'block')};
// `;

export const HomeworkDiv = styled.div`
  display: block;
`;

export const HomeworkCreateCard = styled.div`
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0 0 0;
`;

export const HomeworkCardGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  margin: 10px 0;
  row-gap: 0;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 35% 65%;
  }
`;

export const HomeworkCardTextArea = styled.textarea`
  resize: none;
  border-radius: 5px;
  border-color: rgba(34, 36, 38, 0.15);
  width: 100%;
`;

export const HomeworkCardCalendar = styled.input`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 75%;
  }
`;

export const HomeworkCardFormatButton = styled.button<{ clicked: boolean }>`
  display: inline-block;
  margin: 5px;
  background-color: ${({ clicked }) => (clicked ? 'grey' : 'white')};
  color: ${({ clicked }) => (clicked ? 'white' : 'black')};
  border: 2px solid #47e4c2;
  padding: 5px 10px;
`;
