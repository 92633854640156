import React, { useContext } from 'react';
import { ConsultationsAndPlanObj, TherapistChildContext } from '../../TherapistChildContext';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import { TodoTabs } from '../TodoRoute/styles';
import { Badge, Tabs, Typography } from 'antd';
import NextTab from './TabItem/NextTab';
import { useTranslation } from 'react-i18next';

export const BadgeComponent = (count: number) => {
  return <Badge count={count} offset={[4, -5]}></Badge>;
};

const ConsultationRoute = () => {
  const { nextConsultation, ongoingConsultation, rescheduleConsultation, postConsultation } =
    useContext(TherapistChildContext);
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { Title } = Typography;

  const filterConsultation = (consultations) => {
    const filteredConsultation = consultations.filter(
      (consultation) => consultation.notes?.mainComplaint !== 'Auto Generated',
    );
    return filteredConsultation;
  };

  const nextConsultationFiltered = filterConsultation(nextConsultation);
  const ongoingConsultationFiltered = filterConsultation(ongoingConsultation);
  const rescheduleConsultationFiltered = filterConsultation(rescheduleConsultation);
  const postConsultationFiltered = filterConsultation(postConsultation);

  const nextTaskCount = nextConsultationFiltered.length + ongoingConsultationFiltered.length;

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.childInfo.consultation.pageTitle')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        <TodoTabs defaultActiveKey="1">
          <TabPane
            tab={
              <>
                {t('therapist.childInfo.general.status.consultationNext')}
                {BadgeComponent(nextTaskCount)}
              </>
            }
            key="2"
          >
            <NextTab consultation={[...ongoingConsultationFiltered, ...nextConsultationFiltered]} type={'next'} />
          </TabPane>
          <TabPane tab={t('therapist.childInfo.general.status.consultationReschedule')} key="4">
            <NextTab consultation={rescheduleConsultationFiltered} type={'reschedule'} />
          </TabPane>
          <TabPane tab={t('therapist.childInfo.general.status.consultationPost')} key="3">
            <NextTab consultation={postConsultationFiltered} type={'post'} />
          </TabPane>
        </TodoTabs>
      </RouteContentContainer>
    </>
  );
};

export default React.memo(ConsultationRoute);
