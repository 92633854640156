import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Consultation_consultation_child_goals_preskills } from '../../../../graphql/queries/types/Consultation';

import { Col, Row, Divider } from 'antd';
import { Consultation_consultation_child_goals } from '../../../../graphql/queries/types/Consultation';
import { BsCheckCircleFill } from 'react-icons/bs';
import SkillAndActivitiesComponent from './SkillAndActivitiesComponent';
import { GoalSkillDivider, RoundBorderButton } from './StyledComponents';
import { REMOVE_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeActivitiesToChild';
import {
  RemoveActivitiesToChild,
  RemoveActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/RemoveActivitiesToChild';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { FAST_POLL } from '../constants';
import { useTranslation } from 'react-i18next';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

interface Props {
  skills: Consultation_consultation_child_goals_preskills[];
  goal: Consultation_consultation_child_goals;
  handleGoalAchieved: () => void;
}

const AllSkillsAndActivitiesComponent = ({ skills, goal, handleGoalAchieved }: Props) => {
  const mainSkill = skills.filter((skills) => skills.id === goal.skill.id);
  const preSkills = skills.filter((skills) => skills.id !== goal.skill.id);

  const DROPDOWN_COL_WIDTH = 1;
  const SKILLNAME_COL_WIDTH = 5;
  const CHECKBOX_WIDTH = 4;

  const { t } = useTranslation();

  const {
    consultation,
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
    setReturnMessage,
    setPollingCount,
  } = useContext(TherapistConsultationScreenContext);
  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);

  const [isGoalAchievedClicked, setIsGoalAchievedClicked] = useState<boolean>(false);

  const [removeActivitiesToChild, { data }] = useMutation<RemoveActivitiesToChild, RemoveActivitiesToChildVariables>(
    REMOVE_ACTIVITIES_TO_CHILD_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
    },
  );

  useEffect(() => {
    if (data && data.removeActivitiesToChild) {
      setReturnMessage(data.removeActivitiesToChild.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]);

  const handleRemoveActivities = async () => {
    const skillIds = skills.map((s) => s.id);

    const activitiesToRemove = consultation.child.activityChildren.filter((a) =>
      skillIds.includes(a.activity.mainSkill.id),
    );

    const activityIdsToRemove = activitiesToRemove.map((a) => a.activity.id);
    const consultationIdsToRemove = activitiesToRemove.map((a) => a.consultation.id);

    try {
      await removeActivitiesToChild({
        variables: {
          activityIds: activityIdsToRemove,
          childId: consultation.child.id,
          consultationIds: consultationIdsToRemove,
        },
      });
    } catch (error) {
      console.error('Mutation error:', error);
    }

    return true;
  };

  const removeAllActivitesFromGoal = async () => {
    setPollingCount((prevCount) => prevCount + 1);
    handleGoalAchieved();
    await handleRemoveActivities();
  };

  const handleClickGoalAchieved = () => {
    setIsGoalAchievedClicked(true);
  };

  return (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Col span={SKILLNAME_COL_WIDTH} style={{ fontWeight: 'bold', color: 'teal' }}>
          {t('therapist.consultationScreen.activities.skillName')}
        </Col>
        <Col
          span={CHECKBOX_WIDTH}
          style={{ fontWeight: 'bold', color: 'teal', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {t('therapist.consultationScreen.activities.achieved')}
        </Col>
        <Col span={DROPDOWN_COL_WIDTH}></Col>
        <Col flex="auto" style={{ fontWeight: 'bold', margin: '0', color: 'teal' }}>
          {t('therapist.consultationScreen.activities.activityAssigned')}
        </Col>
      </Row>
      {preSkills.map((skill) => {
        return (
          <div>
            <Divider style={{ margin: '10px 0' }}></Divider>
            <SkillAndActivitiesComponent
              skill={skill}
              goal={goal}
              handleGoalAchieved={handleGoalAchieved}
              removeAllActivitesFromGoal={removeAllActivitesFromGoal}
              isGoalAchievedClicked={isGoalAchievedClicked}
              setIsGoalAchievedClicked={setIsGoalAchievedClicked}
              preSkills={preSkills}
            ></SkillAndActivitiesComponent>
          </div>
        );
      })}
      {mainSkill.map((skill) => {
        return (
          <div>
            <GoalSkillDivider />
            <SkillAndActivitiesComponent
              skill={skill}
              goal={goal}
              handleGoalAchieved={handleGoalAchieved}
              removeAllActivitesFromGoal={removeAllActivitesFromGoal}
              isGoalAchievedClicked={isGoalAchievedClicked}
              setIsGoalAchievedClicked={setIsGoalAchievedClicked}
              preSkills={preSkills}
            ></SkillAndActivitiesComponent>
          </div>
        );
      })}
      {isCPC && (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RoundBorderButton onClick={handleClickGoalAchieved} style={{ marginTop: '0px', padding: '0px 40px' }}>
            <div style={{ paddingRight: '5px' }}>{t('therapist.consultationScreen.activities.goalAchieved')}</div>{' '}
            <BsCheckCircleFill />
          </RoundBorderButton>
        </Row>
      )}
    </div>
  );
};

export default AllSkillsAndActivitiesComponent;
