import styled from 'styled-components';
import { BackgroundColor, MainColor } from '../../../../../styles';
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const UnChecked = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  background: white;
  border: 1px solid ${MainColor};
  :hover {
    background: ${BackgroundColor};
  }
`;

export const Checked = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  background: ${MainColor};
`;
