import styled from 'styled-components';
import { BackgroundColor, MainColor, RowHeight, SelectedColor } from '../styles';
import { Icon } from 'semantic-ui-react';
import { EmptyStateContainer } from '../../../../style';

export const WordTrackingSegment = styled.div`
  width: 100%;
  margin: 10px 0 30px 0;
  @media only screen and (min-width: 768px) {
    background: #fafbfc;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
    padding: 20px;
    border-radius: 8px;
  }
`;
export const WordContainer = styled.div`
  border-radius: 4px;
  @media only screen and (max-width: 900px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
`;

// tracking table empty state container
export const TrackingEmptyStateContainer = styled(EmptyStateContainer)`
  background: #fafbfc;
  @media only screen and (min-width: 768px) {
    background: white;
  }
`;

// completed table empty state container
export const CompletedEmptyStateContainer = styled(EmptyStateContainer)`
  background: #fafbfc;
`;

// table header
export const HeaderRowContainer = styled.div`
  height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 3fr 3fr 3fr 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 9fr 6fr 6fr 4fr 2fr;
  }
  min-width: 500px;
  width: 100%;
`;
export const StickyHeaderColumnContainer = styled.div`
  position: sticky;
  min-width: 150px;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  @media only screen and (min-width: 768px) {
    background: inherit;
  }
  height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  z-index: 10;
`;
export const HeaderData = styled.div<{ center: boolean; canSort: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  justify-content: ${(props) => (props.center ? 'center' : 'left')};
  cursor: ${(props) => (props.canSort ? 'pointer' : 'default')};
  :hover {
    background: ${(props) => (props.canSort ? `${BackgroundColor}` : 'inherit')};
    color: ${(props) => (props.canSort ? `${MainColor}` : 'inherit')};
  }
`;

// table row
export const RowContainer = styled.div<{ isChecked: boolean }>`
  position: relative;
  background: white;
  border-top: ${({ isChecked }) => (isChecked ? `1px solid ${MainColor}` : 'solid 1px white')};
  border-bottom: ${({ isChecked }) => (isChecked ? `1px solid ${MainColor}` : 'solid 1px white')};
  border-right: ${({ isChecked }) => (isChecked ? `1px solid ${MainColor}` : 'solid 1px white')};
  height: ${RowHeight}px;
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 3fr 3fr 3fr 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 9fr 6fr 6fr 4fr 2fr;
  }
  :hover {
    background: ${SelectedColor};
  }
  min-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
`;
export const StickyColumnContainer = styled.div<{ isChecked: boolean }>`
  border-left: ${({ isChecked }) => (isChecked ? `1px solid ${MainColor}` : 'solid 1px white')};
  position: sticky;
  min-width: 150px;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  height: 54px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  @media (hover: hover) and (pointer: fine) {
    ${RowContainer}:hover & {
      background: ${SelectedColor};
    }
  }
  z-index: 10;
`;
export const DataContainer = styled.div<{ center: boolean }>`
  height: ${RowHeight}px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  justify-content: ${(props) => (props.center ? 'center' : 'left')};
`;
export const DataItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

// completed table header
export const CompletedHeaderRowContainer = styled(HeaderRowContainer)`
  grid-template-columns: 6fr 6fr 6fr 6fr;
`;
export const CompletedStickyHeaderColumnContainer = styled(StickyHeaderColumnContainer)`
  padding-left: 10%;
  min-width: 120px;
  grid-template-columns: 1fr;
`;
// completed table row
export const CompleteRowContainer = styled(RowContainer)`
  grid-template-columns: 6fr 6fr 6fr 6fr;
`;
export const CompleteStickyColumnContainer = styled(StickyColumnContainer)`
  padding-left: 10%;
  min-width: 120px;
  grid-template-columns: 1fr;
`;

// sort button
export const SortButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const SortButton = styled.div<{ status: boolean }>`
  display: ${(props) => (props.status ? 'none' : 'flex')};
  align-items: center;
  margin: 0 0 5px 5px;
  ${HeaderData}: hover & {
    display: flex;
  }
`;
export const SortIcon = styled(Icon)``;
