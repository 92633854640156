import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { EditWordModalMask, EditWordModalContainer, Header, ButtonContainer } from './styles';
import { EditWordData, EditWordVariables, EDIT_WORD_MUTATION } from '../../../../../../../graphql/mutations/editWord';
import { Word } from '../../../../../../../graphql/queries/types/WordTracking';
import { SaveButton, HeaderTitle, CancelButton, ActionTitle, Input } from '../../../../../../../style';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  wordItem: Word;
};

const EditWordModal = ({ showModal, wordItem, handleClose }: Props) => {
  const { t } = useTranslation();
  const wordInputRef = useRef() as React.RefObject<HTMLInputElement>;
  const [word, setWord] = useState('');
  const [editWord] = useMutation<EditWordData, EditWordVariables>(EDIT_WORD_MUTATION, {
    update(cache, { data }) {
      if (data != null) {
        setWord('');
        handleClose();
      }
    },
  });

  useEffect(() => {
    if (wordInputRef.current != null && showModal) {
      wordInputRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    if (wordItem != null) {
      setWord(wordItem.word);
    }
  }, [wordItem]);

  const onWordInput = (e: React.ChangeEvent<HTMLInputElement>) => setWord(e.target.value);

  const onCloseClick = () => {
    setWord('');
    handleClose();
  };

  const onSubmitClick = () => {
    editWord({
      variables: {
        wordId: wordItem.id,
        attribute: 'word',
        value: word,
      },
    });
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitClick();
    }
  };

  return (
    <EditWordModalMask open={showModal}>
      <EditWordModalContainer>
        <Header>
          <HeaderTitle>{t('client.wordTracking.modal.edit.title')}</HeaderTitle>
        </Header>
        <Input ref={wordInputRef} onChange={onWordInput} value={word} type="text" onKeyDown={onKeyDown} />
        <ButtonContainer>
          <CancelButton onClick={onCloseClick}>
            <ActionTitle>{t('client.wordTracking.modal.edit.close')}</ActionTitle>
          </CancelButton>
          <SaveButton onClick={onSubmitClick}>
            <ActionTitle>{t('client.wordTracking.modal.edit.submit')}</ActionTitle>
          </SaveButton>
        </ButtonContainer>
      </EditWordModalContainer>
    </EditWordModalMask>
  );
};

export default EditWordModal;
