import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import { ChildTasks_child_assignedActivities } from '../../../../../../../../graphql/queries/types/ChildTasks';
import { StringToListComponent } from '../../../../../../../Client/TaskRoute/ActivityTab/ActivityModal/component/utilsComponent';

interface Props {
  activity: ChildTasks_child_assignedActivities;
}

const PracticeTab = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const step = StringToListComponent(activity.steps);

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <div>
        <Title level={5}>{t(`client.task.activities.practice.steps`)}</Title>
        {step}
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.more.title`)}</Title>
        <Text>{activity.extension ? activity.extension : t(`client.task.activities.no`)}</Text>
      </div>
    </Space>
  );
};

export default PracticeTab;
