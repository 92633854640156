import gql from 'graphql-tag';

export const EDIT_USER_INFORMATION = gql`
  mutation EditUserInformation($userId: ID!, $attribute: String!, $value: String!) {
    editUserInformation(userId: $userId, attribute: $attribute, value: $value) {
      user {
        id
        name
        phoneNumber
        activeChildId
        locale
        smsNotification
        firstName
        lastName
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
