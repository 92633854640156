import React, { useState, FormEvent, useEffect } from 'react';
import {
  UserInfo,
  UserInfoText,
  UserInfoEditButton,
  SaveButton,
  CancelButton,
  ButtonGroup,
  InputField,
  Label,
  NameEditContainer,
  NameContainer,
  ErrorText,
} from '../styles';
import { useMutation } from 'react-apollo';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { ReturnMessage } from '../../../../types';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../../constants/config';

interface Props {
  userId: string;
  title: string;
  userUuid: string;
  firstName: string;
  lastName: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
}

const UserInfoComponent: React.FC<Props> = ({ userId, title, setReturnMessage, userUuid, firstName, lastName }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newFirstName, setNewFirstName] = useState<string>(firstName);
  const [newLastName, setNewLastName] = useState<string>(lastName);
  const [error, setError] = useState<string>('');

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    const timer = setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
    return () => clearTimeout(timer);
  }, [data, setReturnMessage]);

  const handleClickCancel = () => {
    setNewFirstName(firstName);
    setNewLastName(lastName);
    setIsEdit(false);
    setError('');
  };

  const handleUpdateEvalName = async (name: string, userUuid: string, type: 'firstName' | 'lastName') => {
    const token = JS_COOKIE.get('token');
    const endpoint = type === 'firstName' ? 'updateFirstNameFromGuide' : 'updateLastNameFromGuide';
    const { data } = await axios.post(
      `${eval_backend_api_address}api/user/${endpoint}`,
      {
        [type]: name,
        userUuid: userUuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const handleClickSave = async () => {
    if (newFirstName.trim() === '' || newLastName.trim() === '') {
      setError(t('auth.nameError'));
      return;
    }

    setError('');
    await editUserInformation({ variables: { userId, attribute: 'First Name', value: newFirstName.trim() } });
    await editUserInformation({ variables: { userId, attribute: 'Last Name', value: newLastName.trim() } });
    await handleUpdateEvalName(newFirstName.trim(), userUuid, 'firstName');
    await handleUpdateEvalName(newLastName.trim(), userUuid, 'lastName');

    setIsEdit(false);
  };

  return (
    <UserInfo>
      <div>
        <UserInfoText>{title}</UserInfoText>
        {isEdit ? (
          <>
            <NameEditContainer>
              <NameContainer>
                <Label htmlFor="firstName">{t('auth.firstName')}</Label>
                <InputField
                  id="firstName"
                  type="text"
                  value={newFirstName}
                  onChange={(e: FormEvent<HTMLInputElement>) => setNewFirstName(e.currentTarget.value)}
                  placeholder={t('auth.firstName')}
                />
              </NameContainer>
              <NameContainer>
                <Label htmlFor="lastName">{t('auth.lastName')}</Label>
                <InputField
                  id="lastName"
                  type="text"
                  value={newLastName}
                  onChange={(e: FormEvent<HTMLInputElement>) => setNewLastName(e.currentTarget.value)}
                  placeholder={t('auth.lastName')}
                />
              </NameContainer>
            </NameEditContainer>
            {error && <ErrorText>{error}</ErrorText>}
          </>
        ) : (
          <p>{`${newFirstName} ${newLastName}`}</p>
        )}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={handleClickCancel}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={handleClickSave}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={() => setIsEdit(true)}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default UserInfoComponent;
