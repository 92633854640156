import React from 'react';
import { Image } from 'semantic-ui-react';
import { getAge } from '../../../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { CardInfo, CardInfoBoldText } from '../styles';
import { TherapistChildren_therapistChildren } from '../../../../graphql/queries/types/TherapistChildren';
import defaultChild from '../../../../assets/defaultChild.png';
import { getStatusColor } from '../helper';

interface Props {
  child: TherapistChildren_therapistChildren;
}

const ClientCard = ({ child }: Props) => {
  const { previousConsultation, nextConsultation, dob, product, startServiceAt } = child;
  const { t } = useTranslation();
  const tPath = 'therapist.dashboard.myClient.card';
  const [year, month] = getAge(new Date(dob));

  const statusColor = getStatusColor(child, product);

  return (
    <div
      data-cy="clientCard"
      style={{
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        height: '100%',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
      }}
    >
      <Image
        src={child.pictureUrl || defaultChild}
        circular
        bordered
        verticalAlign="top"
        style={{ display: 'inline-block', margin: '0px 5px', width: '60px', height: '60px' }}
      />
      <div style={{ display: 'inline-block', margin: '0px 5px' }}>
        <div style={{ margin: '5px' }}>
          <p
            data-cy="name"
            style={{
              display: 'inline',
              margin: '0px 8px',
              fontSize: '20px',
              color: statusColor,
            }}
          >
            {child.name}
          </p>
          <p
            style={{
              display: 'inline',
              margin: '0px 8px',
              color: statusColor,
              padding: '0px 4px',
              border: `solid ${statusColor} 1px`,
            }}
          >
            ID: {child.id}
          </p>
          <p style={{ display: 'inline', margin: '0px 8px' }}>
            {year} {t(`${tPath}.yearsOld`)} {month} {t(`${tPath}.monthOld`)}
          </p>
        </div>
      </div>
      <div style={{ margin: '10px 5px 10px 10px', justifyContent: 'space-between', display: 'flex', fontSize: '16px' }}>
        <p>
          <span style={{ fontWeight: 'bold' }}>{`${t(`${tPath}.previousComplaint`)}: `}</span>
          {previousConsultation?.notes?.mainComplaint || t(`${tPath}.firstNotScheduled`)}
        </p>
      </div>
      <CardInfo>
        <p>
          <CardInfoBoldText>{`${t(`${tPath}.nextConsultation`)}: `}</CardInfoBoldText>
          {nextConsultation?.consultationTime.substring(0, 10) || 'N/A'}
        </p>
        <p>
          <CardInfoBoldText>{`${t(`${tPath}.previousConsultation`)}: `}</CardInfoBoldText>
          {previousConsultation?.consultationTime.substring(0, 10) || 'N/A'}
        </p>
        <p>
          <CardInfoBoldText>{`${t(`${tPath}.caregiver`)}: `}</CardInfoBoldText>
          {child.client.user.name}
        </p>
        <p>
          <CardInfoBoldText>{`Program: `}</CardInfoBoldText>
          {product?.name}
        </p>
        <p>
          <CardInfoBoldText>{`Start Service: `}</CardInfoBoldText>
          {startServiceAt?.split(' ')[0] || ''}
        </p>
        <p>
          <CardInfoBoldText>{`Status: `}</CardInfoBoldText>
          <span style={{ color: statusColor, fontWeight: 'bold' }}>
            {statusColor === 'green' ? 'Active' : 'Finished'}
          </span>
        </p>
      </CardInfo>
    </div>
  );
};

export default ClientCard;
