import React, { useContext, useEffect } from 'react';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import HomeworkListComponent from './HomeworkListComponent';

const OverviewComponent = () => {
  const {
    consultation: {
      id,
      child: { remainingHomeworks },
    },
    setActiveItem,
  } = useContext(TherapistConsultationScreenContext);

  const handleNext = async () => {
    setActiveItem('CommentForParent');
  };

  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => setActiveItem('Activities')}
        onClickNext={() => handleNext()}
      />
      <HomeworkListComponent homeworks={remainingHomeworks} consultationId={id} />
    </div>
  );
};

export default OverviewComponent;
