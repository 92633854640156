import React from 'react';
import { Modal } from 'antd';
import { Video } from './styles';
interface Props {
  src: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

const ViewVideoModal = ({ src, isModalOpen, handleCloseModal }: Props) => {
  return (
    <Modal open={isModalOpen} onCancel={handleCloseModal} footer={null} width={800}>
      <Video controls>
        <source src={src} type="video/mp4" />
      </Video>
    </Modal>
  );
};

export default ViewVideoModal;
