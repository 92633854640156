import React, { useContext } from 'react';
import { Image } from 'semantic-ui-react';
import HomeworkReplyComponent from './HomeworkReplyComponent';
import { useTranslation } from 'react-i18next';
import { ChildHomeworks_child_homeworks } from '../../../../../../graphql/queries/types/ChildHomeworks';
import { TherapistContext } from '../../../../TherapistContextt';

interface Props {
  homework: ChildHomeworks_child_homeworks;
  roomId: string;
  childId: string;
}

const HomeworkCard = ({ homework, roomId, childId }: Props) => {
  const {
    therapist: { id: therapistId },
  } = useContext(TherapistContext);
  const { t } = useTranslation();

  const renderHwSubmission = () => {
    const { homeworkSubmission } = homework;
    if (!homeworkSubmission || homeworkSubmission.length == 0) {
      return <div>{t('therapist.consultationScreen.task.overview.noHomeworkSubmission')}</div>;
    }

    const value = homeworkSubmission.map((submission, index) => {
      const homeworType =
        submission.message.homeworkUrl && submission.message.homeworkContentType ? (
          submission.message.homeworkContentType.includes('image') ? (
            <div>
              <Image src={submission.message.homeworkUrl} size="small" />
              <p>{submission.message.body}</p>
            </div>
          ) : (
            <div>
              <video src={submission.message.homeworkUrl} width={320} height={240} controls />
              <p>{submission.message.body}</p>
            </div>
          )
        ) : (
          <div>{submission.message.body}</div>
        );
      return (
        <div style={{ marginBottom: '10px' }}>
          #{index + 1} --- Submitted at: <span>{new Date(submission.message.createdAt).toLocaleDateString()}</span>
          {homeworType}
          <HomeworkReplyComponent
            message={submission.message}
            roomId={roomId}
            homeworkId={homework.id}
            childId={childId}
            userId={therapistId}
          />
        </div>
      );
    });
    return value;
  };

  return (
    <div style={{ margin: '20px', padding: '10px 0px' }}>
      <div>
        <p style={{ fontSize: '1.2em', margin: '0' }}>
          <span style={{ fontWeight: 'bold' }}>{t('therapist.consultationScreen.task.overview.homework')}: </span>
          <span data-cy="homeworksDescription">{homework.description}</span>
        </p>
        <p style={{ fontSize: '.9em' }}>
          {t('therapist.consultationScreen.task.overview.dueDate')}
          <span>{new Date(homework.submissionDate).toLocaleDateString()}</span>
        </p>

        {renderHwSubmission()}
      </div>
    </div>
  );
};

export default HomeworkCard;
