import gql from 'graphql-tag';
import { Activity } from '../../types';

export const PRESKILL_ACTIVITIES_QUERY = gql`
  query GetActivitiesFromPreskills($preskillIds: [ID!]!) {
    getActivitiesFromPreskills(preskillIds: $preskillIds) {
      id
      name
      summary
      frequency
      steps
      tools
      parentInteraction
      interactionTactics
      scenario
      mainSkill {
        id
        name
        subcategory {
          id
          name
        }
      }
    }
  }
`;

export interface Data {
  getActivitiesFromPreskills: Activity[];
}

export interface Variables {
  preskillIds: number[];
}
