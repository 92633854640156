import React from 'react';
import { useTranslation } from 'react-i18next';
import { SideBarContainer, TitleContainer, VideoListScrollContainer } from '../../styles';
import { SectionTitle } from '../../../../../style';
import CourseContent from '../CourseContent';

interface Props {
  sectionIndex: number;
  itemIndex: number;
}

const VideoList = ({ sectionIndex, itemIndex }: Props) => {
  const { t } = useTranslation();

  return (
    <SideBarContainer>
      <TitleContainer>
        <SectionTitle style={{ marginBottom: '0' }}>{t('client.course.tabTitle.courseContent')}</SectionTitle>
      </TitleContainer>
      <VideoListScrollContainer>
        <CourseContent sectionIndex={sectionIndex} itemIndex={itemIndex} />
      </VideoListScrollContainer>
    </SideBarContainer>
  );
};

export default VideoList;
