import React, { useMemo, useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// graphql
import { LITE_QUERY } from '../../../graphql/queries/Client/lite';
import { LiteVariables, Lite } from '../../../graphql/queries/types/Client/Lite';
import { COURSE_QUERY } from '../../../graphql/queries/Client/course';
import { CourseVariables, Course as CourseInterface } from '../../../graphql/queries/types/Client/Course';
import { RouteSectionContainer } from '../styles';

// component
import Qna from './component/qna';
import { ClientContext } from '../ClientContext';
import ErrorPage from '../../common/ErrorPage';

interface Props {}

const QnaSessionsRoute = ({}: Props) => {
  window.scroll(0, 0);
  const { child, sessions, setSessions, forceUpdateSessions, setUserRead } = useContext(ClientContext);
  const history = useHistory();
  const [liteQuery, { loading, error, data }] = useLazyQuery<Lite, LiteVariables>(LITE_QUERY);
  const [courseQuery, { data: courseData }] = useLazyQuery<CourseInterface, CourseVariables>(COURSE_QUERY);
  const { t } = useTranslation();
  useEffect(() => {
    if (courseData && courseData.child) {
      setUserRead(courseData.child);
    }
  }, [courseData]);

  useEffect(() => {
    if (data && data.product) {
      //check for legacy product
      if (data.product.course.id == 0) {
        setSessions(data.product.qnaSessions);
      } else {
        setSessions(data.product.course.qnaSessions);
      }
    }
  }, [data]);

  if (sessions.length === 0 && !loading && !error && !data) {
    liteQuery({
      variables: { productId: child.product.id },
    });
    courseQuery({
      variables: { childId: child.id },
    });
  }
  const qnaComponent = useMemo(() => {
    return <Qna forceUpdate={forceUpdateSessions} />;
  }, []);

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  if (sessions.length === 0 && (error || !data || !data.product)) {
    return <ErrorPage />;
  }

  return <RouteSectionContainer>{qnaComponent}</RouteSectionContainer>;
};

export default QnaSessionsRoute;
