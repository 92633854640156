import React, { useContext, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { TherapistContext } from '../../TherapistContextt';
import { ACCEPT_CONSULTATION_MUTATION } from '../../../../graphql/mutations/acceptConsultation';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  AcceptConsultation,
  AcceptConsultationVariables,
} from '../../../../graphql/mutations/types/AcceptConsultation';
import { AcceptButton } from '../styles';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  consultationId: string;
}

const AcceptConsultationButton = ({ consultationId }: Props) => {
  const { setReturnMessage } = useContext(TherapistContext);

  const [acceptConsultation, { loading, error, data }] = useMutation<AcceptConsultation, AcceptConsultationVariables>(
    ACCEPT_CONSULTATION_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId } }],
    },
  );

  useEffect(() => {
    if (data && data.acceptConsultation) {
      setReturnMessage(data.acceptConsultation.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const { t } = useTranslation();

  if (loading) {
    return <Loader active />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <AcceptButton data-cy="acceptButton" onClick={() => acceptConsultation({ variables: { consultationId } })}>
      {t('therapist.dashboard.myTasks.buttons.accept')}
    </AcceptButton>
  );
};

export default AcceptConsultationButton;
