import gql from 'graphql-tag';

export const UPDATE_HOMEWORK_MUTATION = gql`
  mutation UpdateHomework(
    $homeworkId: ID!
    $description: String!
    $submissionDate: ISO8601DateTime!
    $format: [String!]!
    $finished: Boolean!
  ) {
    updateHomework(
      homeworkId: $homeworkId
      description: $description
      submissionDate: $submissionDate
      format: $format
      finished: $finished
    ) {
      returnMessage {
        status
        message
      }
      homework {
        id
        description
        format
        submissionDate
        finished
      }
    }
  }
`;
