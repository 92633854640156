import gql from 'graphql-tag';
import { Activity, ReturnMessage } from '../../types';

export const REMOVE_ACTIVITIES_TO_CHILD_MUTATION = gql`
  mutation RemoveActivitiesToChild($activityIds: [ID!]!, $childId: ID!, $consultationIds: [ID!]!) {
    removeActivitiesToChild(activityIds: $activityIds, childId: $childId, consultationIds: $consultationIds) {
      activities {
        id
        name
        summary
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  removeActivitiesToChild: { activities: Activity[]; returnMessage: ReturnMessage };
}

export interface Variables {
  activityIds: number[];
  childId: number;
  consultationIds: number[];
}
