import gql from 'graphql-tag';
import { Skill } from '../../types';

export const GET_PRESKILLS_QUERY = gql`
  query Preskills($childId: ID!) {
    getPreskillList(childId: $childId) {
      id
      name
      skillId
      mastered
      mainSkill
    }
  }
`;

export interface Data {
  getPreskillList: Skill[];
}

export interface Variables {
  childId: number;
}
