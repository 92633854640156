import React, { useState, useContext } from 'react';
import { Segment, Grid, Button, DropdownItemProps } from 'semantic-ui-react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import ActivitySwitchComponent from './ActivitySwitchComponent';
import { GetActivitiesFromPreskills_getActivitiesFromPreskills } from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import { useTranslation } from 'react-i18next';
import { GeneralSegment, SmallContentTitle, SmallDescription } from '../../../../style';

interface Props {
  activity: GetActivitiesFromPreskills_getActivitiesFromPreskills;
  index: number;
  setActivitiesDisplay: React.Dispatch<React.SetStateAction<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>>;
  activitiesStack: GetActivitiesFromPreskills_getActivitiesFromPreskills[];
  setActivitiesStack: React.Dispatch<React.SetStateAction<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>>;
}

const TherapistConsultationScreenSelectTaskCard = ({
  activity,
  index,
  setActivitiesDisplay,
  activitiesStack,
  setActivitiesStack,
}: Props) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { setSelectedActivities } = useContext(TherapistConsultationScreenContext);

  const handleClickSelect = () => {
    setIsSelected(!isSelected);
    setSelectedActivities((prevState: GetActivitiesFromPreskills_getActivitiesFromPreskills[]) => {
      if (prevState.includes(activity)) {
        return prevState.filter((a: GetActivitiesFromPreskills_getActivitiesFromPreskills) => a !== activity);
      }
      return [...prevState, activity];
    });
  };

  const handleClickSwitch = (data: DropdownItemProps) => {
    if (activitiesStack.length > 0) {
      const first = activitiesStack[0];
      setActivitiesStack(activitiesStack.slice(1));
      setActivitiesDisplay((prevState: GetActivitiesFromPreskills_getActivitiesFromPreskills[]) => {
        prevState[index] = first;
        return prevState;
      });
    } else {
      setActivitiesDisplay((prevState: GetActivitiesFromPreskills_getActivitiesFromPreskills[]) => {
        return prevState.filter((_a: GetActivitiesFromPreskills_getActivitiesFromPreskills, i: number) => i !== index);
      });
    }
  };

  const { t } = useTranslation();

  const stepString = activity.steps
    ? activity.steps.split('\n').map((s) => {
        return <div>{s !== '' ? <span>{s}</span> : ''}</div>;
      })
    : '';

  return (
    <GeneralSegment style={{ borderLeft: '7px solid #47E4C2', padding: '0px 5px' }}>
      <Grid columns={2}>
        <Grid.Column width={12}>
          <div style={{ padding: '10px 15px' }}>
            <SmallContentTitle>{activity.name}</SmallContentTitle>
            <SmallDescription>{activity.interactionTactics}</SmallDescription>
            <div style={{ margin: '10px 0px' }}>
              <p style={{ fontWeight: 'bold', margin: '0' }}>
                {`${activity.mainSkill.subcategory.name} - ${activity.name}`}
              </p>
              <div>
                <p style={{ fontSize: '11px', margin: '0' }}>
                  {activity.summary && (
                    <li>
                      {`${t('therapist.consultationScreen.task.taskAndHw.taskCard.forChild')}: `}
                      <b>{activity.summary}</b>
                    </li>
                  )}
                  {activity.parentInteraction && (
                    <li>
                      {`${t('therapist.consultationScreen.task.taskAndHw.taskCard.forParent')}: `}
                      <b>{activity.parentInteraction}</b>
                    </li>
                  )}
                </p>
              </div>
            </div>

            <div>
              <div style={{ margin: '10px 0px' }}>
                <p style={{ fontWeight: 'bold', margin: '0' }}>
                  {t('therapist.consultationScreen.task.taskAndHw.taskCard.steps')}
                </p>
                <p style={{ fontSize: '11px' }}>{stepString}</p>
              </div>
              <div style={{ margin: '10px 0px' }}>
                <p style={{ fontWeight: 'bold', margin: '0' }}>
                  {t('therapist.consultationScreen.task.taskAndHw.taskCard.scenario')}
                </p>
                <p style={{ fontSize: '11px' }}>{activity.scenario}</p>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Button.Group vertical size="tiny" floated="right" style={{ marginTop: '10px' }}>
            <Button
              style={
                isSelected
                  ? {
                      padding: '10px 10px',
                      borderRadius: '24px',
                      margin: '5px 0',
                      color: 'white',
                      backgroundColor: '#5DD9C4',
                    }
                  : {
                      padding: '10px 10px',
                      borderRadius: '24px',
                      margin: '5px 0',
                      color: '#5DD9C4',
                      backgroundColor: 'white',
                      border: '1px solid #5DD9C4',
                    }
              }
              compact
              onClick={() => handleClickSelect()}
              data-cy="selectTasksButton"
            >
              {isSelected
                ? t('therapist.consultationScreen.task.taskAndHw.taskCard.selected')
                : t('therapist.consultationScreen.task.taskAndHw.taskCard.select')}
            </Button>
            <ActivitySwitchComponent activityId={activity.id} handleClickSwitch={handleClickSwitch} />
          </Button.Group>
        </Grid.Column>
      </Grid>
    </GeneralSegment>
  );
};

export default TherapistConsultationScreenSelectTaskCard;
