import React from 'react';
import { SectionTitle } from '../../../style';
import { ArrowIcon, BackButton, MobileArrowIcon, TitleFlexBox, ViewMoreButton } from './styles';

interface Props {
  title: string;
  actionText: string;
  shorten: boolean;
  handleClick: () => void;
}

const SectionActionTitle = ({ title, actionText, shorten, handleClick }: Props) => {
  return (
    <>
      <TitleFlexBox>
        <SectionTitle style={{ marginBottom: '0' }}>{title}</SectionTitle>
        {shorten && (
          <>
            <ViewMoreButton onClick={handleClick}>
              {actionText}
              <ArrowIcon className="long arrow alternate right" link />
            </ViewMoreButton>
            <MobileArrowIcon className="circular long arrow alternate right" link onClick={() => handleClick()} />
          </>
        )}
        {!shorten && (
          <BackButton onClick={handleClick}>
            {actionText}
            <ArrowIcon className="long arrow alternate right" link />
          </BackButton>
        )}
      </TitleFlexBox>
    </>
  );
};

export default React.memo(SectionActionTitle);
