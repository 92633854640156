import styled from 'styled-components';

export const Segment = styled.div`
  background: white;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 0.9em;
  border-radius: 5px;
  font-size: 0.9em;
`;

export const UploadPictureLabel = styled.label`
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;
  color: black;
  background-color: white;
  border: 1px solid black;
`;

export const UploadPictureButton = styled.input`
  display: none;
`;

export const SavePictureButton = styled.div`
  cursor: pointer;
  border-radius: 22.5px;
  padding: 4px 12px;
  font-family: sans-serif;
  margin: 5px 0;
  color: white;
  background-color: #47e4c2;
`;

export const ViewMoreButton = styled.button`
  cursor: pointer;
  font-size: 12px;
  font-family: sans-serif;
  border: none;
  margin: 5px 0;
  background-color: white;
  color: #3d8d91;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }
`;

export const ActivitiesSegmentImage = styled.img`
  height: 80px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
