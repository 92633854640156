import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ShowErrNextButton } from '../../../../style';
import { MainColor } from '../styles';
import SVG from 'react-inlinesvg';

// tool box container
export const ToolBoxContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  justify-content: space-between;
`;
export const Container = styled.div`
  display: flex;
`;

// action container
export const ActionContainer = styled.div`
  display: flex;
  align-items: end;
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
`;
export const TrashButton = styled(ShowErrNextButton)`
  height: 38px;
  margin: 0 10px 10px 0;
  padding: 0 10px 0 10px;
  color: #fa3e3e;
  border: solid 1px #fa3e3e;
  :hover {
    color: white;
    background: #fa3e3e;
  }
`;
export const MasteredButton = styled(ShowErrNextButton)`
  height: 38px;
  margin: 0 10px 10px 0;
  padding: 0 10px 0 10px;
  :hover {
    color: white;
    background: ${MainColor};
  }
`;
export const TrashIcon = styled(Icon)``;
export const MasteredIcon = styled(SVG)`
  width: 17px;
  height: 17px;
  margin-right: 3px;
  @media (hover: hover) and (pointer: fine) {
    ${MasteredButton}: hover & path {
      stroke: white;
    }
    ${MasteredButton}: hover & rect {
      fill: ${MainColor};
      stroke: white;
    }
  }
`;
