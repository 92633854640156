import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import {
  ChildHomeworks as Data,
  ChildHomeworksVariables as Variables,
} from '../../../../../graphql/queries/types/ChildHomeworks';
import { CHILD_HOMEWORKS } from '../../../../../graphql/queries/childHomeworks';
import HomeworksComponent from './Component';
import { TherapistChildContext } from '../../TherapistChildContext';
import { LoadingContainer } from '../../layout/styles';
import { Spin } from 'antd';

const HomeworksRoute = () => {
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);
  const { loading, error, data } = useQuery<Data, Variables>(CHILD_HOMEWORKS, { variables: { childId } });

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  if (error || !data) {
    return <p>Error...</p>;
  }

  const { child } = data;

  return <HomeworksComponent child={child} />;
};

export default React.memo(HomeworksRoute);
