import gql from 'graphql-tag';

export const CHILD_MESSAGES = gql`
  query ChildMessages($childId: ID!) {
    child(childId: $childId) {
      id
      room {
        id
        directMessages {
          id
          body
          createdAt
          user {
            id
            name
          }
          meta {
            __typename
            ... on DirectMessage {
              id
            }
            ... on HomeworkSubmission {
              id
              homework {
                id
                description
              }
            }
          }
          replies {
            id
            message {
              id
              body
              user {
                id
                name
              }
              avatarUrl
              replyMessageFileUrl
              replyMessageFileContentType
              createdAt
            }
          }
          homeworkUrl
          avatarUrl
          homeworkContentType
          messageFileUrl
          messageFileContentType
        }
      }
    }
  }
`;
