import React from 'react';

export const renderStringToList = (data: string): JSX.Element[] => {
  const splitString = data.split('\n');
  return splitString.map((s, index) => {
    if (s === '') {
      <div></div>;
    }
    if (splitString.length === 1 && index === 0) {
      return <div key={index}>{s}</div>;
    }
    return (
      <div key={index} style={{ display: 'flex' }}>
        <div style={{ width: '40px' }}>{index + 1}</div>
        <div>{s}</div>
      </div>
    );
  });
};
