import styled from 'styled-components';

export const Container = styled.div`
  @media only screen and (max-width: 575px) {
    padding: 0 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 20%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 20%;
  }
`;

export const UserProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserProfilePictureContainer = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

export const TherapistContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TherapistSectionTitle = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  color: #030303;
  margin-bottom: 1rem;
`;

export const TherapistInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const TherapistName = styled.div`
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: bold;
  color: black;
`;

export const TherapistTitle = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  color: black;
`;

export const BioContainer = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  margin-bottom: 8px;
  color: #606060;
  display: flex;
  align-items: flex-start;
`;

export const BioContentContainer = styled.p`
  text-align: justify;
`;

export const BioBulletPoint = styled.li`
  list-style-position: outside;
`;
