import gql from 'graphql-tag';
import { Word } from '../queries/types/WordTracking';
import { ReturnMessage } from '../../types';

export const ADD_WORD_MUTATION = gql`
  mutation AddWord($childId: ID!, $word: String!) {
    addWord(childId: $childId, word: $word) {
      word {
        id
        childId
        word
        repeatCount
        speakCount
        editable
        complete
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface AddWordData {
  addWord: {
    word: Word;
    returnMessage: ReturnMessage;
  };
}

export interface AddWordVariables {
  childId: number;
  word: string;
}
