import gql from 'graphql-tag';

export const CHILD_WORD_TRACKING_QUERY = gql`
  query Words($childId: ID!) {
    words(childId: $childId) {
      id
      childId
      word
      repeatCount
      speakCount
      editable
      complete
    }
  }
`;
