import React, { useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router';
import { SmallDescription } from '../../../../style';
import { VideoContainer, VideoGridContainer, VideoTitle, TitleContainer, TimeContainer, VideoThumbnail } from './style';
import { ContentType } from '../../../../util/video';
import UserContext from '../../../common/UserContext';

interface Props {
  content: ContentType[];
  activeBadge: number;
}

interface Props {
  content: ContentType[];
  activeBadge: number;
}

const VideoComponent = ({ content, activeBadge }: Props) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const locale = user.user.locale;
  const handleOpenVideo = (video: ContentType, index: number) => {
    if (video.contentUrl.includes('youku.com')) {
      return history.push(
        '/video?s=library&t=' + video.title + '&d=' + video.body + '&v=1' + '&l=' + locale + '&u=' + video.contentUrl,
      );
    }
    if (video.contentUrl.includes('youtube.com')) {
      return history.push(
        '/video?s=library&t=' + video.title + '&d=' + video.body + '&v=1' + '&l=' + locale + '&u=' + video.contentUrl,
      );
    }
    window.open(video.contentUrl);
    mixpanel.track('LibraryVideo', {
      option: `${video.title}`,
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  return (
    <>
      {locale == 'en' ? (
        <div style={{ marginTop: '-15px', marginBottom: '15px', color: 'black', fontSize: '14px', fontWeight: 'bold' }}>
          We have curated quality self-learning content from trustworthy sources for your benefit. Please let us know if
          you have others resources to recommend
        </div>
      ) : null}
      <VideoGridContainer>
        {content.map((video: ContentType, index: number) => {
          return (
            <VideoContainer>
              <TimeContainer data-duration={video.readTime + ':00'}>
                <VideoThumbnail src={video.mediaUrl} onClick={() => handleOpenVideo(video, index)} />
              </TimeContainer>
              <TitleContainer>
                <VideoTitle onClick={() => handleOpenVideo(video, index)}>{video.title}</VideoTitle>
                <SmallDescription>{formatDate(video.publishedDate)}</SmallDescription>
              </TitleContainer>
            </VideoContainer>
          );
        })}
      </VideoGridContainer>
    </>
  );
};
export default VideoComponent;
