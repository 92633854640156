import styled from 'styled-components';
import { GeneralSegment } from '../../../../../style';

// card component
export const GoalCardSegment = styled(GeneralSegment)<{ height: number; marginBottom: number; marginRight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  min-width: 300px;
  height: ${(props) => props.height}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: #5abaae;
  }
`;
export const DescriptionContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
`;
