import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography, Image, Tabs } from 'antd';
import { ChildTasks_child_assignedActivities } from '../../../../../../../graphql/queries/types/ChildTasks';
import { BackgroundColor } from '../../../../../../Client/WordTrackingRoute/styles';
import OverallTab from './component/OverallTab';
import SetupTab from './component/SetupTab';
import PracticeTab from './component/PracticeTab';
import { SkillBadge } from '../styles';

interface Props {
  activity: ChildTasks_child_assignedActivities;
}

const ParentView = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { Title, Text } = Typography;

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <Space size="middle" style={{ display: 'flex', width: '-webkit-fill-available' }}>
        <div>
          <Image src={activity.mediaUrl || undefined} width={100} height={100} />
        </div>
        <div>
          <SkillBadge BackgroundColor={BackgroundColor}>
            <Text>{activity.mainSkill.name}</Text>
          </SkillBadge>
          <Title level={4}>{activity.name}</Title>
          <Text type="secondary">{t(`client.task.activities.selective`)}</Text>
        </div>
      </Space>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t(`client.task.activities.overall.title`)} key="1">
          <OverallTab activity={activity} />
        </TabPane>
        <TabPane tab={t(`client.task.activities.practice.activitySetup`)} key="2">
          <SetupTab activity={activity} />
        </TabPane>
        <TabPane tab={t(`client.task.activities.practice.title`)} key="3">
          <PracticeTab activity={activity} />
        </TabPane>
      </Tabs>
    </Space>
  );
};

export default ParentView;
