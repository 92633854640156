import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { SearchBarContainer, SearchBarIcon } from "../styles";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ setSearchInput }) => {
    const { t } = useTranslation();
    const [input, setInput] = useState("");
    const handleChange = (value) => {
        setInput(value);
        setSearchInput(value);
    }
    return (
        <SearchBarContainer>
            <SearchBarIcon>
                <FaSearch/>
            </SearchBarIcon>
            <input
                placeholder={t('therapist.dashboard.myClient.searchBarPlaceholder')}
                value={input}
                onChange={(e) => handleChange(e.target.value)}
            />
        </SearchBarContainer>
    )
}

export default SearchBar;