import gql from 'graphql-tag';

export const DELETE_GOAL = gql`
  mutation DeleteGoal($goalId: ID!, $reason: String!) {
    deleteGoal(goalId: $goalId, reason: $reason) {
      returnMessage {
        status
        message
      }
    }
  }
`;
