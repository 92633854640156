import React, { useContext, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  Consultation_consultation_child_goals,
  Consultation_consultation_child_goals_preskills,
  ConsultationVariables,
  Consultation,
} from '../../../../graphql/queries/types/Consultation';
import {
  UpdateGoalPreskillMastered as UpdateGoalPreskillMasteredData,
  UpdateGoalPreskillMasteredVariables as UpdateGoalPreskillMasteredVariables,
} from '../../../../graphql/mutations/types/UpdateGoalPreskillMastered';
import { UPDATE_GOAL_PRESKILL_MASTERED_MUTATION } from '../../../../graphql/mutations/updateGoalPreskillMastered';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import {
  UpdateMasteredModalText,
  UpdateMasteredModalTextSpan,
  UpdateMasteredModalButtonContainer,
  UpdateMasteredModalButtonConfirm,
  UpdateMasteredModalButtonCancel,
} from './styles';

interface Props {
  isOpen: boolean;
  consultationId: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goalId?: string;
  skill?: Consultation_consultation_child_goals_preskills;
  setPreskills: React.Dispatch<React.SetStateAction<Consultation_consultation_child_goals_preskills[]>>;
  updateGoal?: (goal: Consultation_consultation_child_goals) => void;
}

const UpdateMasteredModal = ({ isOpen, consultationId, setIsOpen, goalId, skill, setPreskills, updateGoal }: Props) => {
  const [updateGoalPreskillMastered, { data: updateData }] = useMutation<
    UpdateGoalPreskillMasteredData,
    UpdateGoalPreskillMasteredVariables
  >(UPDATE_GOAL_PRESKILL_MASTERED_MUTATION, {
    update(cache, { data }) {
      if (!data || !skill) {
        return null;
      }
      const queryResult = cache.readQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
      });

      if (!queryResult || !queryResult.consultation) {
        return null;
      }

      const updatedGoalIndex = queryResult.consultation.child.goals.findIndex((g) => {
        return g.id === goalId;
      });
      const updatedGoalPreSkillIndex = queryResult.consultation.child.goals[updatedGoalIndex].goalPreskills.findIndex(
        (s) => {
          return s.skillId === skill.id;
        },
      );
      queryResult.consultation.child.goals[updatedGoalIndex].goalPreskills[updatedGoalPreSkillIndex].mastered = true;
      if (updateGoal) {
        updateGoal(queryResult.consultation.child.goals[updatedGoalIndex]);
      }

      cache.writeQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
        data: { consultation: queryResult.consultation },
      });
    },
  });
  const { setReturnMessage } = useContext(TherapistConsultationScreenContext);

  useEffect(() => {
    if (updateData && updateData.updateGoalPreskillMastered) {
      setReturnMessage(updateData.updateGoalPreskillMastered.returnMessage);
      setPreskills((prevState) => {
        const tmp = prevState.map((s: Consultation_consultation_child_goals_preskills) => {
          if (s.id === updateData.updateGoalPreskillMastered.goalPreskill.skillId) {
            return { id: s.id, name: s.name, mastered: true };
          }
          return s;
        });
        return tmp;
      });
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [updateData]);

  const handleClickUpdateMastered = async () => {
    if (!goalId || !skill) return;

    await updateGoalPreskillMastered({
      variables: {
        goalId: goalId,
        skillId: skill.id,
      },
    });
    handleClickClose();
  };

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();
  const tPath = 'therapist.consultationScreen.progress.goalCard';

  return (
    <Modal open={isOpen} onClose={handleClickClose} size="mini" style={{ width: '300px' }}>
      <Modal.Content>
        <UpdateMasteredModalText>
          {t(`${tPath}.skillModal.text1`)}
          <UpdateMasteredModalTextSpan>{skill ? skill.name : ''}</UpdateMasteredModalTextSpan>
          {t(`${tPath}.skillModal.text2`)}
        </UpdateMasteredModalText>
        <UpdateMasteredModalButtonContainer>
          <UpdateMasteredModalButtonCancel onClick={handleClickClose}>
            {t(`${tPath}.buttons.cancel`)}
          </UpdateMasteredModalButtonCancel>
          <UpdateMasteredModalButtonConfirm onClick={handleClickUpdateMastered}>
            {t(`${tPath}.buttons.confirm`)}
          </UpdateMasteredModalButtonConfirm>
        </UpdateMasteredModalButtonContainer>
      </Modal.Content>
    </Modal>
  );
};

export default UpdateMasteredModal;
