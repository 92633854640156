import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  padding: 0 50px;
  @media only screen and (max-width: 600px) {
    padding: 0 5px;
  }
`;

export const MenuLink = styled(NavLink)`
  color: black;
  font-size: 18px;
  font-weight: 400;
  flex: 1;
  text-align: center;
  &.active {
    padding-bottom: 8px;
    border-bottom: 5px solid #5dd9c4;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
