import React, { useState, useContext } from 'react';
import UploadMessageComponent from './UploadMessageComponent';
import { useQuery } from 'react-apollo';
import { Divider, Radio } from 'semantic-ui-react';
import ShowMessageButtonComponent from './ShowMessageButtonComponent';
import { useTranslation } from 'react-i18next';
import { getDate } from '../../../../helpers/functions';
import { GET_NEXT_HOMEWORK_QUERY } from '../../../../graphql/queries/getNextHomework';
import { GetNextHomework, GetNextHomeworkVariables } from '../../../../graphql/queries/types/GetNextHomework';
import UserContext from '../../../common/UserContext';
import { HomeworkBox } from '../styles';
import ErrorPage from '../../../common/ErrorPage';
import { utcToDate } from '../../../../constants/timezoneConversion';
import { ClientContext } from '../../ClientContext';

interface Props {
  childId: string;
  roomId: string;
}

const HomeworkDashboardComponent = ({ childId, roomId }: Props) => {
  const user = useContext(UserContext);
  const { client } = useContext(ClientContext);
  const locale = user.user.locale;
  const [messageShow, setMessageShow] = useState<boolean>(false);
  const { loading, error, data } = useQuery<GetNextHomework, GetNextHomeworkVariables>(GET_NEXT_HOMEWORK_QUERY, {
    variables: { childId: childId },
  });

  const { t } = useTranslation();

  if (loading) {
    return <h2>{t('system.loading')}</h2>;
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  const { getNextHomework } = data;

  return (
    <>
      {getNextHomework != null ? (
        <>
          <HomeworkBox>
            <p style={{ fontSize: '12px', verticalAlign: 'middle' }}>
              {`${t('client.communicationCard.homework.thisWeeksHw')}: ${getNextHomework.description}`}
            </p>
            <p style={{ fontSize: '10px', verticalAlign: 'middle' }}>
              {`${t('client.communicationCard.homework.dueDate')}: ${utcToDate(
                getNextHomework.submissionDate,
                client.timeZone,
                locale,
              )}`}
            </p>
            <p style={{ fontSize: '10px', verticalAlign: 'middle' }}>
              {`${t('client.communicationCard.homework.format')}: `}
              <span>
                {getNextHomework.format.map(
                  (f: string, index: number) => `${f}${index == getNextHomework.format.length - 1 ? ' ' : ', '} `,
                )}
              </span>
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: '10px', marginRight: '10px' }}>{t('client.communicationCard.viewYourMessages')}</p>
              <Radio toggle onChange={() => setMessageShow(!messageShow)} />
            </div>
          </HomeworkBox>
          <Divider fitted />
          {messageShow ? (
            <ShowMessageButtonComponent roomId={roomId} messageType="HomeworkSubmission" />
          ) : (
            <UploadMessageComponent roomId={roomId} homeworkId={getNextHomework.id} />
          )}
        </>
      ) : (
        <ShowMessageButtonComponent roomId={roomId} messageType="HomeworkSubmission" />
      )}
    </>
  );
};

export default HomeworkDashboardComponent;
