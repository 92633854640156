import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { UploadPictureButton, UploadPictureLabel, SavePictureButton } from './styles';
import { useMutation } from 'react-apollo';
import { EditChildPicture, EditChildPictureVariables } from '../../../../graphql/mutations/types/EditChildPicture';
import { EDIT_CHILD_PICTURE } from '../../../../graphql/mutations/editChildPicture';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pic?: string;
  childId: string;
}

const ChildCardModal = ({ isOpen, setIsOpen, pic, childId }: Props) => {
  const [image, setImage] = useState<File | undefined>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const [editChildPicture] = useMutation<EditChildPicture, EditChildPictureVariables>(EDIT_CHILD_PICTURE);

  const handleChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setImage(e.currentTarget.files[0]);
      setImageUrl(URL.createObjectURL(e.currentTarget.files[0]));
    }
  };

  const handleClickSave = async () => {
    await editChildPicture({
      variables: {
        childId,
        file: image,
        fileName: 'test',
      },
    });
    setIsOpen(false);
  };

  const handleClickClose = () => {
    setIsOpen(false);
    setImage(undefined);
    setImageUrl(undefined);
  };

  const { t } = useTranslation();
  const tPath = 'client.program.modal';

  return (
    <Modal open={isOpen} onClose={() => handleClickClose()} closeIcon size="mini">
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={imageUrl || pic || 'https://place-hold.it/100'} width="100" height="100" />
          <UploadPictureButton
            type="file"
            accept="image/*"
            id="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePicture(e)}
          />
          <UploadPictureLabel htmlFor="file">{t(`${tPath}.uploadNewPicture`)}</UploadPictureLabel>
          {image && <SavePictureButton onClick={() => handleClickSave()}>{t(`${tPath}.save`)}</SavePictureButton>}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ChildCardModal;
