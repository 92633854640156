import styled from 'styled-components';
import FillHome from './assets/FillHome.svg';
import FillProgram from './assets/FillProgram.svg';
import FillTasks from './assets/FillTasks.svg';
import FillConsultations from './assets/FillConsultations.svg';
import FillProfile from './assets/FillProfile.svg';
import FillDashboard from './assets/FillDashboard.svg';
import FillCourses from './assets/FillCourses.svg';
import FillQnaSessions from './assets/FillQnaSessions.svg';
import FillLibrary from './assets/FillLibrary.svg';
import FillWordTools from './assets/FillWordTools.svg';
import GreyHome from './assets/GreyHome.svg';
import GreyProgram from './assets/GreyProgram.svg';
import GreyTasks from './assets/GreyTasks.svg';
import GreyConsultations from './assets/GreyConsultations.svg';
import GreyProfile from './assets/GreyProfile.svg';
import GreyDashboard from './assets/GreyDashboard.svg';
import GreyCourses from './assets/GreyCourses.svg';
import GreyQnaSessions from './assets/GreyQnaSessions.svg';
import GreyLibrary from './assets/GreyLibrary.svg';
import GreyWordTools from './assets/GreyWordTools.svg';
import WhiteHome from './assets/WhiteHome.svg';
import WhiteProgram from './assets/WhiteProgram.svg';
import WhiteTasks from './assets/WhiteTasks.svg';
import WhiteConsultations from './assets/WhiteConsultations.svg';
import WhiteProfile from './assets/WhiteProfile.svg';
import WhiteDashboard from './assets/WhiteDashboard.svg';
import WhiteCourses from './assets/WhiteCourses.svg';
import WhiteQnaSessions from './assets/WhiteQnaSessions.svg';
import WhiteLibrary from './assets/WhiteLibrary.svg';
import WhiteWordTools from './assets/WhiteWordTools.svg';
import { MainColor } from '../../Client/WordTrackingRoute/styles';
import { BiSolidBellRing, BiSolidBell } from 'react-icons/bi';

// NavBar
export const LeftNavBar = styled.div<{ show: boolean }>`
  position: fixed;
  background-color: #5abaae;
  z-index: 998;
  text-align: center;
  display: none;
  @media only screen and (min-width: 992px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
    left: 0;
    height: 100%;
    width: 220px;
  }
`;

export const TopNavBar = styled.div<{ status: boolean }>`
  z-index: 998;
  background-color: ${MainColor};
  top: 0;
  height: 48px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    left: 0;
    background: ${(props) => (props.status ? MainColor : 'transparent')};
  }
`;

export const BottomNavBar = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  background-color: #5abaae;
  z-index: 998;
  bottom: 0;
  height: calc(env(safe-area-inset-bottom) + 50px);
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

// Container
export const TopBarMenuContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 12px;
  @media only screen and (min-width: 992px) {
    // flex-direction: row-reverse;
    padding: 8px 20px;
  }
`;
export const BarMenuContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  @media only screen and (min-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }
`;

export const NavbarLinkContainer = styled.div`
  width: 100%;
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  padding: 10px 0;
  @media only screen and (min-width: 992px) {
    display: block;
    padding-top: 30px;
  }
`;

// Company Logo
export const TopNavbarCompanyLogo = styled.img<{ status: boolean }>`
  width: 110px;
  height: 38px;
  padding-top: 2px;
  cursor: pointer;
  @media only screen and (min-width: 992px) {
    display: ${(props) => (props.status ? 'block' : 'none')};
  }
`;

export const WordCompanyLogo = styled.img`
  width: 110px;
  height: auto;
  padding-top: 2px;
  @media only screen and (min-width: 992px) {
    width: 155px;
    height: auto;
    margin-top: 5px;
    margin-left: 15px;
  }
`;

// NavbarLink with five extend component
export const NavbarLinkButton = styled.div<{ status: boolean }>`
  border-radius: 6px;
  cursor: pointer;
  pointer-events: ${({ status }) => (status ? 'none' : 'cursor')};
  width: 60px;
  background-color: #5abaae;
  color: ${({ status }) => (status ? 'white' : '#E8E9E9')};
  @media only screen and (min-width: 992px) {
    width: 100%;
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    color: ${({ status }) => (status ? 'white' : '#E8E9E9')};
    background: ${({ status }) => (status ? '#81CAC1' : '#5ABAAE')};
    font-weight: ${({ status }) => (status ? '500' : '400')};
  }
  :hover {
    color: white;
  }
`;

// NavbarIcon with five extend component
export const NavbarIcon = styled.div<{ status: boolean }>`
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: inline-block;
`;
export const DashboardIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillDashboard : GreyDashboard)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteDashboard});
    }
  }
`;
export const CoursesIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillCourses : GreyCourses)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteCourses});
    }
  }
`;
export const QnaSessionsIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillQnaSessions : GreyQnaSessions)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteQnaSessions});
    }
  }
`;
export const HomeIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillHome : GreyHome)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteHome});
    }
  }
`;
export const ProgramIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillProgram : GreyProgram)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteProgram});
    }
  }
`;
export const TasksIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillTasks : GreyTasks)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteTasks});
    }
  }
`;
export const ConsultationsIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillConsultations : GreyConsultations)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteConsultations});
    }
  }
`;
export const LibraryIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillLibrary : GreyLibrary)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteLibrary});
    }
  }
`;

export const WordToolsIcon = styled(NavbarIcon)<{ status: boolean }>`
  content: url(${({ status }) => (status ? FillWordTools : GreyWordTools)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteWordTools});
    }
  }
`;

export const ProfileIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillProfile : GreyProfile)});
  @media (hover: hover) and (pointer: fine) {
    ${NavbarLinkButton}: hover & {
      content: url(${WhiteProfile});
    }
  }
`;

// Navbar Text
export const NavbarText = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  margin-top: -5px;
  @media only screen and (min-width: 992px) {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-left: 20px;
    margin-top: 0;
  }
`;

// top navbar item
export const TopProfileIcon = styled.img<{ status: boolean; show: boolean }>`
  content: url(${({ status }) => (status ? FillProfile : GreyProfile)});
  cursor: pointer;
  margin: 0 10px 0 10px;
  height: 20px;
  width: 20px;
  @media only screen and (min-width: 992px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;
export const TopRightButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Notification Bell
export const RingingSolidBellIcon = styled(BiSolidBellRing)<{ status: boolean }>`
  color: white;
  @media only screen and (min-width: 992px) {
    color: ${({ status }) => (status ? 'white' : 'grey')};
  }
`;

export const SolidBellIcon = styled(BiSolidBell)<{ status: boolean }>`
  color: white;
  @media only screen and (min-width: 992px) {
    color: ${({ status }) => (status ? 'white' : 'grey')};
  }
`;
