import React, { useContext, useState, useEffect } from 'react';
import { getDate } from '../../../../helpers/functions';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { Consultation_consultation_child_remainingHomeworks } from '../../../../graphql/queries/types/Consultation';
import { UPDATE_HOMEWORK_MUTATION } from '../../../../graphql/mutations/updateHomework';
import { UpdateHomework, UpdateHomeworkVariables } from '../../../../graphql/mutations/types/UpdateHomework';
import { useTranslation } from 'react-i18next';
import {
  HomeworkDiv,
  HomeworkCreateCard,
  HomeworkCardGrid,
  HomeworkCardCalendar,
  HomeworkCardTextArea,
  HomeworkCardFormatButton,
} from '../styles';
import { ReturnMessageStatus } from '../../../../types/graphql-global-types';
import { UpdateHomework_updateHomework_returnMessage } from '../../../../graphql/mutations/types/UpdateHomework';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';

interface Props {
  homework: Consultation_consultation_child_remainingHomeworks;
  index: number;
  consultationId: string;
}

const HomeworkCard = ({ homework, index, consultationId }: Props) => {
  const {
    consultation: {
      child,
      therapist: {
        user: { locale },
      },
    },
  } = useContext(TherapistConsultationScreenContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(homework.description);
  const [submissionDate, setSubmissionDate] = useState<string>(homework.submissionDate.slice(0, 10));
  const [format, setFormat] = useState<string[]>(homework.format);

  const [updateHomework, { loading, error, data }] = useMutation<UpdateHomework, UpdateHomeworkVariables>(
    UPDATE_HOMEWORK_MUTATION,
  );

  const onClickFormat = (f: string) => {
    if (!format.includes(f)) {
      setFormat((prevState: string[]) => [...prevState, f]);
    } else {
      setFormat((prevState: string[]) => prevState.filter((p: string) => p !== f));
    }
  };

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  const handleClickCancel = () => {
    setDescription(homework.description);
    setSubmissionDate(homework.submissionDate.slice(0, 10));
    setIsEdit(false);
  };

  const { setReturnMessage } = useContext(TherapistConsultationScreenContext);
  useEffect(() => {
    if (data && data.updateHomework) {
      setReturnMessage(data.updateHomework.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const today = Date.now();
  const inputDate = new Date(submissionDate).valueOf();
  var difference = Math.round(inputDate / (1000 * 3600 * 24)) - Math.round(today / (1000 * 3600 * 24));

  const validateInput = () => {
    const messageStatus: ReturnMessageStatus = ReturnMessageStatus.ERROR;

    const returnMessage: UpdateHomework_updateHomework_returnMessage = {
      status: messageStatus,
      message: '',
    };
    if (description !== '' && submissionDate !== '' && difference > 0 && format.length > 0) return true;

    if (description === '') {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.descriptionRequired');
    } else if (submissionDate === '') {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.submissionDateRequired');
    } else if (difference < 0) {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.biggerSubmissionDateRequired');
    } else if (format.length === 0) {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.formatRequired');
    }
    setReturnMessage(returnMessage);
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 6000);
    return false;
  };

  const handleClickSave = async () => {
    if (validateInput()) {
      await updateHomework({
        variables: {
          homeworkId: homework.id,
          description,
          submissionDate,
          format,
          finished: false,
        },
      });
      setIsEdit(false);
    }
  };

  const handleClickFinished = async () => {
    await updateHomework({
      variables: { homeworkId: homework.id, description, submissionDate, format, finished: true },
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultationId } }],
    });
  };

  const { t } = useTranslation();

  return (
    <div style={{ margin: '20px 5px' }}>
      <div>
        {isEdit ? (
          <HomeworkDiv>
            <HomeworkCreateCard>
              <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: '3px 0' }}>
                {`${t('therapist.consultationScreen.task.overview.homework')} #${index + 1}`}
              </p>
              <HomeworkCardGrid>
                <p>{`${t('therapist.consultationScreen.task.overview.description')}: `}</p>
                <HomeworkCardTextArea
                  style={{ width: '100%' }}
                  value={description}
                  onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setDescription(e.currentTarget.value)}
                />
              </HomeworkCardGrid>
              <HomeworkCardGrid>
                <p>{`${t('therapist.consultationScreen.task.overview.dueDate')}: `}</p>
                <HomeworkCardCalendar
                  type="date"
                  value={submissionDate}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setSubmissionDate(e.currentTarget.value)}
                />
              </HomeworkCardGrid>
              <HomeworkCardGrid>
                <p>{`${t('therapist.consultationScreen.task.taskAndHw.homeworkFormat')}: `}</p>
                <div>
                  <HomeworkCardFormatButton clicked={format.includes('video')} onClick={() => onClickFormat('video')}>
                    {t('therapist.consultationScreen.task.taskAndHw.formats.video')}
                  </HomeworkCardFormatButton>
                  <HomeworkCardFormatButton clicked={format.includes('image')} onClick={() => onClickFormat('image')}>
                    {t('therapist.consultationScreen.task.taskAndHw.formats.image')}
                  </HomeworkCardFormatButton>
                  <HomeworkCardFormatButton
                    clicked={format.includes('text')}
                    onClick={() => onClickFormat('text')}
                    data-cy="selectTextFormat"
                  >
                    {t('therapist.consultationScreen.task.taskAndHw.formats.text')}
                  </HomeworkCardFormatButton>
                </div>
              </HomeworkCardGrid>
            </HomeworkCreateCard>
            <Button.Group fluid>
              <Button
                attached="bottom"
                style={{ backgroundColor: '#5DD9C4', color: 'white', margin: '0' }}
                onClick={() => handleClickSave()}
              >
                <Icon name="pencil" />
                {t('therapist.consultationScreen.task.overview.buttons.save')}
              </Button>
              <Button attached="bottom" color="grey" onClick={() => handleClickCancel()}>
                <Icon name="x" />
                {t('therapist.consultationScreen.task.overview.buttons.cancel')}
              </Button>
            </Button.Group>
          </HomeworkDiv>
        ) : (
          <div>
            <p style={{ fontSize: '1.2em', fontWeight: 'bold', margin: '3px 0' }}>
              {`${t('therapist.consultationScreen.task.overview.homework')} #${index + 1}`}
            </p>
            <div style={{ margin: '2px 8px' }}>
              <p style={{ fontSize: '1em', fontWeight: 'bold', margin: '3px 0' }}>
                {`${t('therapist.consultationScreen.task.overview.description')}: ${homework.description}`}
              </p>
              <p style={{ fontSize: '.8em', opacity: 0.75, margin: '3px 0', fontStyle: 'italic' }}>
                {`${t('therapist.consultationScreen.task.overview.dueDate')}: 
                ${getDate(homework.submissionDate, locale)}`}
              </p>
              <Button basic size="mini" icon onClick={() => handleClickEdit()}>
                <Icon name="pencil" />
                {t('therapist.consultationScreen.task.overview.buttons.edit')}
              </Button>
              <Button color="green" basic size="mini" icon onClick={() => handleClickFinished()}>
                <Icon name="check" />
                {t('therapist.consultationScreen.task.overview.buttons.finish')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeworkCard;
