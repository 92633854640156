import styled from 'styled-components';
import { Button } from 'antd';
import { MainColor } from '../../../Client/WordTrackingRoute/styles';

export const CancelButton = styled(Button)<{ status: boolean; disable: boolean }>`
  &:hover {
    border-color: #ff7875;
    color: #ff7875;
  }
  border-color: ${({ status, disable }) => (status ? '#d9363e50' : disable ? '#d9d9d9' : 'default')};
  background-color: ${({ status, disable }) => (status ? '#d9363e80' : disable ? '#f5f5f5' : 'default')};
  color: ${({ status, disable }) => (status ? 'white' : disable ? 'rgba(0, 0, 0, 0.25)' : 'default')};
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
`;

export const AcceptButton = styled(Button)<{ status: boolean; disable: boolean }>`
  border-color: ${({ status, disable }) =>
    disable ? (status ? '#5abaae50' : '#d9d9d9') : status ? MainColor : 'default'};
  background-color: ${({ status, disable }) =>
    disable ? (status ? '#5abaae80' : '#f5f5f5') : status ? MainColor : 'default'};
  color: ${({ status, disable }) => (status ? 'white' : disable ? 'rgba(0, 0, 0, 0.25)' : 'default')};
  pointer-events: ${({ status, disable }) => (disable ? 'none' : status ? 'none' : 'auto')};
`;

export const ButtonWrapper = styled.div<{ disable: boolean }>`
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'default')};
`;
