import React from 'react';
import BrowserCheckModal from './components/common/BrowserCheckModal';
import UserContainer from './UserContainer';
import { useQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { VIEWER_QUERY } from './graphql/queries/viewer';
import { Viewer, ViewerVariables } from './graphql/queries/types/Viewer';
import ErrorPage from './components/common/ErrorPage';

const Root = (props) => {
  const { loading, error, data } = useQuery<Viewer, ViewerVariables>(VIEWER_QUERY, { variables: { eval: false } });
  if (loading) {
    return <Loader active>Loading</Loader>;
  }
  if (error || !data) {
    return <ErrorPage />;
  }

  const { viewer } = data;
  return (
    <>
      <BrowserCheckModal />
      <UserContainer deferredPrompt={props.deferredPrompt} user={viewer} />
    </>
  );
};

export default Root;
