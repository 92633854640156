import gql from 'graphql-tag';
import { User } from '../../types';

export const VIEWER_QUERY = gql`
  query Viewer($eval: Boolean) {
    viewer(eval: $eval) {
      id
      uuid
      name
      email
      activeChildId
      locale
      phoneNumber
      country
      smsNotification
      metaId
      metaType
      loggedInBefore
      installBannerAppear
      firstName
      lastName
      deviceNotifications {
        id
        title
        description
        url
        showBefore
      }
    }
  }
`;

export interface Data {
  viewer: User;
}
