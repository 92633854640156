import styled from 'styled-components';
import { MainColor, BackgroundColor } from '../../../../../styles';
import { DataItemContainer } from '../../../../styles';
import { DropDownList, DropDownListContainer, ListItem } from '../../../../../Dropdown/styles';
import { SmallActionTitle } from '../../../../../../../../style';
import { Icon } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';

export const Container = styled(DataItemContainer)``;

export const ActionButton = styled.div<{ toggle: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 4px;
  color: ${MainColor};
  background: ${({ toggle }) => (toggle ? BackgroundColor : 'white')};
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: white;
      background: ${MainColor};
    }
  }
`;

export const MoreOptionsDropdown = styled(DropDownListContainer)<{ toggle: boolean }>`
  ${({ toggle }) => (toggle ? '' : 'display: none;')}
  top: 40px;
  right: 2%;
  @media only screen and (max-width: 992px) {
    display: ${(props) => (props.toggle ? 'flex' : 'none')};
    flex-direction: column-reverse;
    background-color: #00000030;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    margin: 0;
  }
`;

export const MoreOptionsDropdownList = styled(DropDownList)<{ toggle: boolean }>`
  @media only screen and (max-width: 992px) {
    opacity: ${(props) => (props.toggle ? 1 : 0)};
    animation: ${(props) => (props.toggle ? 'fadeIn' : 'fadeOut')} 250ms linear;
    transition: visibility 250ms linear;
    margin: 0 20px 20px 20px;
  }
`;

export const Item = styled(ListItem)`
  @media only screen and (max-width: 992px) {
    height: 50px;
  }
`;

export const DeleteItem = styled(ListItem)`
  color: #fa3e3e;
  :hover {
    background: #fa3e3e;
    color: white;
  }
  @media only screen and (max-width: 992px) {
    height: 50px;
  }
`;

export const DeleteListItemText = styled(SmallActionTitle)`
  color: #fa3e3e;
  @media (hover: hover) and (pointer: fine) {
    ${ListItem}: hover & {
      color: white;
    }
  }
`;

export const RemoveIcon = styled(Icon)`
  height: 14px !important;
  width: 14px !important;
  font-size: 14px !important;
  margin: -23px 8px 0 -4px !important;
`;

export const EditIcon = styled(SVG)`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  margin-left: -4px;
  ${ListItem}: hover & path {
    stroke: ${MainColor};
  }
`;

export const MasteredIcon = styled(SVG)`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  margin-left: -4px;
  path {
    stroke: #606060;
    stroke-width: 3;
  }
  rect {
    stroke: #606060;
    stroke-width: 3;
  }
  ${ListItem}: hover & path {
    stroke: ${MainColor};
  }
  ${ListItem}: hover & rect {
    fill: ${BackgroundColor};
    stroke: ${MainColor};
  }
`;
