import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/en-ca';
import { Lite_product_qna_sessions } from '../../../../graphql/queries/types/Client/Lite';
import { useMutation } from 'react-apollo';
import {
  UpdateQnaSessionRead,
  UpdateQnaSessionReadVariables,
} from '../../../../graphql/mutations/types/UpdateQnaSessionRead';
import { UPDATE_QNA_SESSION_READ_MUTATION } from '../../../../graphql/mutations/updateQnaSessionRead';
import { Course_child_sessions } from '../../../../graphql/queries/types/Client/Course';

import {
  InformationQnaButton,
  FutureDate,
  LatestDate,
  OldContainer,
  QATitle,
  LatestContainer,
  FutureContainer,
  EndTime,
  ReplayButton,
} from '../styles';
import { ClientContext } from '../../ClientContext';
import { useHistory } from 'react-router-dom';

interface Props {
  qnaSession: Lite_product_qna_sessions;
  currentStatus: string;
}

const QnaLink = ({ qnaSession, currentStatus }: Props) => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const { updateUserRead, userRead, setUserRead, client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const { id, description, link, read, startTime, endTime, order } = qnaSession;
  const childId = child.id;
  const isIcc = child.product.productTypesName === 'peaICC' ? true : false;
  const currentLocale = client.user.locale;
  const handleLocale = (currentLocale: String) => {
    switch (currentLocale) {
      case 'en':
        return 'en-ca';
      case 'zh-CN':
        return 'zh-cn';
      case 'zh-TW':
        return 'zh-tw';
      default:
        return 'zh-cn';
    }
  };
  const replayAvailable = order < child.currentWeek;
  const QADate = moment(child.startServiceAt, 'YYYY-MM-DD  hh:mm:ss Z')
    .startOf('day')
    .add(14 * (order - 1) * (isIcc ? 2 : 1) + (isIcc ? 2 : 1) * 7, 'days');
  const QADescription = description;
  // const QADateTime = moment(startTime).locale(handleLocale(currentLocale)).format('YYYY/MM/DD dddd'); //gonna use this one again in the future
  const QAStartTime = moment(startTime, 'YYYY-MM-DD  hh:mm:ss Z').format('HH:mm');
  const QAEndTime = moment(startTime, 'YYYY-MM-DD  hh:mm:ss Z').add(1, 'hours').format('HH:mm');
  const [updateQnaSessionRead] = useMutation<UpdateQnaSessionRead, UpdateQnaSessionReadVariables>(
    UPDATE_QNA_SESSION_READ_MUTATION,
    {
      update(cache, { data }) {
        if (!data) {
          return null;
        }
        if (!userRead) {
          return data;
        }

        if (data.updateQnaSessionRead.returnMessage.status === 'SUCCESS') {
          const cl = userRead.qnaSessions.find((l) => l.id === id);

          if (!cl) {
            userRead.qnaSessions.push({
              id: id,
              read: true,
            } as Course_child_sessions);
          } else {
            cl.read = true;
          }
          updateUserRead(userRead, false, true);
        }

        return data;
      },
    },
  );

  const handleClickLink = () => {
    updateQnaSessionRead({
      variables: {
        childId: childId,
        qnaSessionId: id,
      },
    });
    if (link.includes('youku.com') || link.includes('youtube.com') || link.includes('youtu.be')) {
      return history.push('/video?s=qna&t=' + description + '&v=1' + '&l=' + currentLocale + '&u=' + link);
    }
    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <div>
      {currentStatus === 'latest' && (
        <div>
          <LatestDate>{QADate.format('YYYY/MM/DD')}</LatestDate>
          <LatestContainer>
            <div>
              <QATitle>{QADescription}</QATitle>
              {/* <EndTime>
                {QAStartTime} - {QAEndTime}
              </EndTime> */}
            </div>
            <InformationQnaButton isAvailable={QADate <= moment()} onClick={handleClickLink}>
              {t('client.qnaSession.start')}
            </InformationQnaButton>
          </LatestContainer>
        </div>
      )}
      {currentStatus === 'future' && (
        <div>
          <FutureDate>{QADate.format('YYYY/MM/DD')}</FutureDate>
          <FutureContainer>
            <QATitle>{QADescription}</QATitle>
            {/* <EndTime>
              {QAStartTime} - {QAEndTime}
            </EndTime> */}
          </FutureContainer>
        </div>
      )}
      {currentStatus === 'old' && (
        <OldContainer>
          <div>
            <QATitle>{QADescription}</QATitle>
            <EndTime>
              <span>{QADate.format('YYYY/MM/DD')}</span>
            </EndTime>
          </div>
          <ReplayButton replayAvailable={replayAvailable} readStatus={read} onClick={handleClickLink}>
            {t('client.qnaSession.replay')}
          </ReplayButton>
        </OldContainer>
      )}
    </div>
  );
};

export default QnaLink;
