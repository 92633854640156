import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const CANCEL_CONSULTATION_MUTATION = gql`
  mutation CancelConsultation($consultationId: ID!) {
    cancelConsultation(consultationId: $consultationId) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  cancelConsultation: { returnMessage: ReturnMessage };
}

export interface Variables {
  consultationId: number;
}
