import gql from 'graphql-tag';

export const UPDATE_CONSENT_FORM_COMPLETED_STATUS_MUTATION = gql`
  mutation UpdateConsentFormStatus($inputChildId: ID!, $status: Boolean!) {
    updateConsentFormStatus(inputChildId: $inputChildId, status: $status) {
      returnMessage {
        status
        message
      }
    }
  }
`;
