import { useTranslation } from 'react-i18next';

export const getBackupRoomSmsLog = (consultation: any, consultationId: string, userId: string): boolean => {
  const today = new Date();
  const smsLog = consultation?.smsLogs;
  try {
    if (smsLog) {
      for (let i = 0; i < smsLog.length; i++) {
        const smsLogDetails = smsLog[i].details !== '' ? JSON.parse(smsLog[i].details) : null;
        if (smsLogDetails && smsLogDetails.key === 3) {
          const smsDate = new Date(smsLog[i].createdAt);
          if ((today.getTime() - smsDate.getTime()) / (1000 * 60 * 60) <= 24) {
            return true;
          }
        }
      }
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const GetTherapistSmsOptions = () => {
  const { t } = useTranslation();
  return [
    {
      key: 0,
      title: t('therapist.smsDropdown.messageTitle.checkOtherParty'),
      msg: t('therapist.smsDropdown.message.checkOtherParty'),
    },
    {
      key: 1,
      title: t('therapist.smsDropdown.messageTitle.delay'),
      subOptions: [
        {
          key: '1-1',
          value: { msg: t('therapist.smsDropdown.message.delayOnTime'), index: 0, key: '1-1' },
          label: t('therapist.smsDropdown.subMessageTitle.delayOnTime'),
        },
        {
          key: '1-2',
          value: { msg: t('therapist.smsDropdown.message.delayNotOnTime'), index: 1, key: '1-2' },
          label: t('therapist.smsDropdown.subMessageTitle.delayNotOnTime'),
        },
      ],
    },
    {
      key: 2,
      title: t('therapist.smsDropdown.messageTitle.reschedule'),
      subOptions: [
        {
          key: '2-1',
          value: { msg: t('therapist.smsDropdown.message.rescheduleDelayed'), index: 0, key: '2-1' },
          label: t('therapist.smsDropdown.subMessageTitle.rescheduleDelayed'),
        },
        {
          key: '2-2',
          value: { msg: t('therapist.smsDropdown.message.rescheduleTech'), index: 1, key: '2-2' },
          label: t('therapist.smsDropdown.subMessageTitle.rescheduleTech'),
        },
      ],
    },
    {
      key: 3,
      title: t('therapist.smsDropdown.messageTitle.backupRoom'),
      msg: t('therapist.smsDropdown.message.sendBackupRoom'),
    },
    {
      key: 4,
      title: t('therapist.smsDropdown.messageTitle.concludeSession'),
      subOptions: [
        {
          key: '4-1',
          value: { msg: t('therapist.smsDropdown.message.concludeAfter10Mins'), index: 0, key: '4-1' },
          label: t('therapist.smsDropdown.subMessageTitle.concludeAfter10Mins'),
        },
        {
          key: '4-2',
          value: { msg: t('therapist.smsDropdown.message.concludeTimeLimit'), index: 1, key: '4-2' },
          label: t('therapist.smsDropdown.subMessageTitle.concludeTimeLimit'),
        },
      ],
    },
    {
      key: 5,
      title: t('therapist.smsDropdown.messageTitle.signBackIn'),
      subOptions: [
        {
          key: '5-1',
          value: { msg: t('therapist.smsDropdown.message.signBackInTimeLimit'), index: 0, key: '5-1' },
          label: t('therapist.smsDropdown.subMessageTitle.signBackInTimeLimit'),
        },
        {
          key: '5-2',
          value: { msg: t('therapist.smsDropdown.message.signBackIn'), index: 1, key: '5-2' },
          label: t('therapist.smsDropdown.subMessageTitle.signBackIn'),
        },
      ],
    },
  ];
};

export const GetClientSmsOptions = () => {
  const { t } = useTranslation();
  return [
    {
      key: 0,
      title: t('client.smsDropdown.messageTitle.checkOtherParty'),
      msg: t('client.smsDropdown.message.checkOtherParty'),
    },
    {
      key: 1,
      title: t('client.smsDropdown.messageTitle.delay'),
      msg: t('client.smsDropdown.message.delay'),
    },
    {
      key: 2,
      title: t('client.smsDropdown.messageTitle.reschedule'),
      msg: t('client.smsDropdown.message.rescheduleTech'),
      subOptions: [
        {
          value: { msg: t('client.smsDropdown.message.rescheduleCannotWait'), index: 0, key: '2-1' },
          label: t('client.smsDropdown.subMessageTitle.rescheduleCannotWait'),
        },
        {
          value: { msg: t('client.smsDropdown.message.rescheduleTech'), index: 1, key: '2-2' },
          label: t('client.smsDropdown.subMessageTitle.rescheduleTech'),
        },
      ],
    },
    {
      key: 4,
      title: t('client.smsDropdown.messageTitle.backupRoom'),
      msg: t('client.smsDropdown.message.requestBackupRoom'),
    },
  ];
};
