import React, { useMemo, useContext, useState } from 'react';
import { ClientContext } from '../../../ClientContext';
import {
  TabMenuContainer,
  Player,
  VideoContainer,
  GlassMask,
  VideoWrapper,
  OverviewContainer,
  GoToExternalContainer,
  GlassMaskContainer,
  GoToExternalButton,
  DefaultImg,
  ExternalIcon,
  ButtonTextContainer,
} from '../../styles';
import { useTranslation } from 'react-i18next';
import TabMenu from '../../../../common/TabMenu';
import CourseContent from '../CourseContent';
import Overview from './Overview';
import defaultDocument from '../../assets/defaultDocument.jpg';
import { ActionTitle } from '../../../../../style';
interface Props {
  platform: string;
  sectionIndex: number;
  itemIndex: number;
  video: string;
}

const VideoDetail = ({ platform, sectionIndex, itemIndex, video }: Props) => {
  const { t } = useTranslation();
  const { courseSections } = useContext(ClientContext);
  const [selection, setSelection] = useState<number>(0);
  const [title, href, lecturer, description, link] = useMemo(() => {
    if (courseSections[sectionIndex] === undefined) {
      return [];
    }
    if (courseSections[sectionIndex].courseLinks[itemIndex] === undefined) {
      return [];
    }
    const sortCourseLinks = courseSections[sectionIndex].courseLinks.sort((a, b) => {
      return parseInt(a.id as string) - parseInt(b.id as string);
    });
    const { lecturer, description } = courseSections[sectionIndex];
    const title = sortCourseLinks[itemIndex].description;
    const link = sortCourseLinks[itemIndex].link;
    const platformHref = (platform: string) => {
      switch (platform) {
        case 'yk':
          return `https://player.youku.com/embed/${video}`;
        case 'yt':
          return `https://www.youtube-nocookie.com/embed/${video}`;
        default:
          return `https://google.com`;
      }
    };
    const href = platformHref(platform);
    return [title, href, lecturer, description, link];
  }, [courseSections, sectionIndex, itemIndex, platform, video]);

  let width = window.screen.width;
  const showCourseContentTab = width < 992;

  const tabTitles = showCourseContentTab
    ? [t('client.course.tabTitle.courseContent'), t('client.course.tabTitle.overview')]
    : [t('client.course.tabTitle.overview')];

  const tabContents = showCourseContentTab
    ? [
        <OverviewContainer>
          <CourseContent sectionIndex={sectionIndex} itemIndex={itemIndex} />
        </OverviewContainer>,
        <Overview lecturer={lecturer} description={description} />,
      ]
    : [<Overview lecturer={lecturer} description={description} />];

  const isWeiYun = platform === 'wy';
  const isZoho = platform === 'zh';

  const handleClick = () => {
    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <VideoContainer>
      <VideoWrapper>
        {isWeiYun || isZoho ? (
          <GoToExternalContainer>
            <GoToExternalButton onClick={handleClick}>
              <ButtonTextContainer>
                <ActionTitle>{t('client.course.openDocument')}</ActionTitle>
                <ExternalIcon className="external alternate" />
              </ButtonTextContainer>
            </GoToExternalButton>
            <DefaultImg src={defaultDocument} />
            <GlassMaskContainer>
              <GlassMask></GlassMask>
            </GlassMaskContainer>
          </GoToExternalContainer>
        ) : (
          <Player
            width={560}
            height={315}
            src={href}
            title={title}
            frameBorder={0}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
            allowFullScreen
          />
        )}
      </VideoWrapper>
      <TabMenuContainer>
        <TabMenu tabTitles={tabTitles} tabContents={tabContents} selection={selection} handleTabClick={setSelection} />
      </TabMenuContainer>
    </VideoContainer>
  );
};

export default VideoDetail;
