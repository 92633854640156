import React, { useState, useContext, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useMutation } from 'react-apollo';
import { CREATE_NOTES_MUTATION } from '../../../../graphql/mutations/createConsultationNote';
import {
  CreateConsultationNote,
  CreateConsultationNoteVariables,
} from '../../../../graphql/mutations/types/CreateConsultationNote';
import {
  FinalizeConsultation,
  FinalizeConsultationVariables,
} from '../../../../graphql/mutations/types/FinalizeConsultation';
import { FINALIZE_CONSULTATION } from '../../../../graphql/mutations/finalizeConsultation';
import { useTranslation } from 'react-i18next';
import NoteEditor from '../../../common/NoteEditor';
import { AddActivitiesButton } from '../styles';
import ErrorPage from '../../../common/ErrorPage';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

const TherapistConsultationScreenCommentForParentComponent = () => {
  const {
    consultation: { id, child },
    setActiveItem,
    notes,
    setNotes,
    setReturnMessage,
  } = useContext(TherapistConsultationScreenContext);

  const [createConsultationNote, { loading, error, data }] = useMutation<
    CreateConsultationNote,
    CreateConsultationNoteVariables
  >(CREATE_NOTES_MUTATION);

  const { t } = useTranslation();
  const isCPC = CPC_PRODUCT_TYPES.includes(child.product.productTypesName);

  const noteHTMLTitleStyle = `<h3 style="font-weight: bold;">`;
  const noteHTMLTitleStyleEnd = `</h3>`;

  const thisWeekObjectiveHTML = `${noteHTMLTitleStyle}${t(
    'therapist.consultationScreen.notes.defaultNotes1',
  )}:${noteHTMLTitleStyleEnd}`;
  const encouragementFromLeadCoachHTML = `${noteHTMLTitleStyle}${t(
    'therapist.consultationScreen.notes.defaultNotes2',
  )}:${noteHTMLTitleStyleEnd}`;

  const regex1 = `${thisWeekObjectiveHTML}(.*)${encouragementFromLeadCoachHTML}`;
  const regex2 = `${encouragementFromLeadCoachHTML}(.*)`;

  const [thisWeekObjective, setThisWeekObjective] = useState<string>(notes.commentForParents.match(regex1)?.[1] ?? '');
  const [encouragementFromLeadCoach, setEncouragementFromLeadCoach] = useState<string>(
    notes.commentForParents.match(regex2)?.[1] ?? '',
  );

  const [tempSave, setTempSave] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.createConsultationNote) {
      setReturnMessage(data.createConsultationNote.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
      if (data.createConsultationNote.returnMessage.status == 'SUCCESS' && !tempSave) {
        finalizeConsultation({
          variables: {
            consultationId: id,
          },
        });
      }
    }
  }, [data]);

  const [finalizeConsultation, { data: finalizeData }] = useMutation<
    FinalizeConsultation,
    FinalizeConsultationVariables
  >(FINALIZE_CONSULTATION);

  useEffect(() => {
    setNotes({
      ...notes,
      commentForParents: `${thisWeekObjectiveHTML}${thisWeekObjective}${encouragementFromLeadCoachHTML}${encouragementFromLeadCoach}`,
    });
  }, [thisWeekObjective, encouragementFromLeadCoach]);

  useEffect(() => {
    if (finalizeData && finalizeData.finalizeConsultation.returnMessage.status == 'ERROR') {
      setReturnMessage(finalizeData.finalizeConsultation.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
    if (finalizeData && finalizeData.finalizeConsultation.returnMessage.status == 'SUCCESS') {
      window.location.href = '/';
    }
  }, [finalizeData]);

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <h1>{t('system.loading')}</h1>;
  }

  const handleNext = async () => {
    const { mainComplaint, internalNotes, commentForParents } = notes;
    setTempSave(false);
    await createConsultationNote({
      variables: {
        mainComplaint,
        internalNotes,
        commentForParents,
        consultationId: id,
      },
    });
  };

  const handleTmpSave = async () => {
    const { mainComplaint, internalNotes, commentForParents } = notes;
    setTempSave(true);
    await createConsultationNote({
      variables: {
        mainComplaint,
        internalNotes,
        commentForParents,
        consultationId: id,
      },
    });
  };

  const handleClickBack = () => {
    if (isCPC) {
      setActiveItem('Plan');
    } else {
      setActiveItem('Progress');
    }
  };
  const { commentForParents } = notes;
  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => handleClickBack()}
        onClickNext={() => handleNext()}
        nextText={t('therapist.consultationScreen.publish')}
      />
      <Form>
        <Form.Field label={t('therapist.consultationScreen.notes.commentForParents')} style={{ fontSize: '2rem' }} />
        <h2 style={{ fontWeight: 'bold' }}>{`${t('therapist.consultationScreen.notes.defaultNotes1')}`}</h2>
        <p style={{ fontSize: 15 }}>{`(${t('therapist.consultationScreen.notes.thisWeekObjectiveWordLimit')})`}</p>
        <NoteEditor initialString={thisWeekObjective} placeholder={``} setNote={setThisWeekObjective} />
        <br></br>
        <h2 style={{ fontWeight: 'bold' }}>{`${t('therapist.consultationScreen.notes.defaultNotes2')}`}</h2>
        <p style={{ fontSize: 15 }}>{`(${t(
          'therapist.consultationScreen.notes.encouragementFromLeadCoachWordLimit',
        )})`}</p>
        <NoteEditor
          initialString={encouragementFromLeadCoach}
          placeholder={``}
          setNote={setEncouragementFromLeadCoach}
        />
        <br></br>
        <AddActivitiesButton onClick={handleTmpSave} data-cy="addActivitiesButton">
          {t('therapist.consultationScreen.notes.tempSave')}
        </AddActivitiesButton>
      </Form>
    </div>
  );
};

export default TherapistConsultationScreenCommentForParentComponent;
