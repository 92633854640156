import React, { useContext, Fragment, useEffect, useState } from 'react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { JS_COOKIE } from '../../../../util/Cookie';
import { Segment, Grid, Icon } from 'semantic-ui-react';
import {
  Consultation_consultation_child_goals_skill_category,
  Consultation_consultation_child_goals_preskills,
  Consultation_consultation_child_goals_preskills_info,
} from '../../../../graphql/queries/types/Consultation';
import {
  Category_categories_subcategories_skills,
  Category_categories,
  Category_categories_subcategories,
} from '../../../../graphql/queries/types/Category';

import { GoStarFill } from 'react-icons/go';
import { eval_backend_api_address } from '../../../../constants/config';
import axios from 'axios';
interface Props {
  category: Consultation_consultation_child_goals_skill_category | Category_categories;
  setSkill?: React.Dispatch<React.SetStateAction<Category_categories_subcategories_skills | null>>;
  setPreskills?: React.Dispatch<React.SetStateAction<Consultation_consultation_child_goals_preskills[]>>;
  setSubCategory?: React.Dispatch<React.SetStateAction<Category_categories_subcategories | null>>;
  preskills?: Consultation_consultation_child_goals_preskills[];
  goalPreSkills?: Consultation_consultation_child_goals_preskills_info[];
}

const TherapistConsultationScreenSkillsComponent = ({
  category,
  setSkill,
  setSubCategory,
  setPreskills,
  preskills,
  goalPreSkills,
}: Props) => {
  const [weakerSkillList, setWeakerSkillList] = useState<string[]>([]);

  const {
    consultation: {
      child: {
        client: {
          user: { uuid },
        },
      },
    },
  } = useContext(TherapistConsultationScreenContext);

  function getGuideSkillIds(obj): string[] {
    const skillIds: string[] = [];

    function findSkillIds(obj: any) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          findSkillIds(obj[key]);
        } else {
          if (key === 'skillId') {
            skillIds.push(obj[key].toString());
          }
        }
      }
    }

    findSkillIds(obj);
    return skillIds;
  }

  const getWeakerSkills = async () => {
    const token = JS_COOKIE.get('token');
    const userUuid = uuid;
    const res = await axios.get(eval_backend_api_address + 'api/results/getResultByUserUuid', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userUuid,
      },
    });

    if (res) {
      setWeakerSkillList(getGuideSkillIds(res.data.weakerSkills));
      return res;
    }
  };

  useEffect(() => {
    getWeakerSkills();
  }, []);

  const renderSkillsComponent = () => {
    if (setSkill && setSubCategory) {
      const c = category as Category_categories;

      return (
        <Fragment>
          {c.subcategories.map((subcategory: Category_categories_subcategories) =>
            subcategory.skills.length > 0 ? (
              <Grid.Column key={subcategory.id} textAlign="center">
                <Segment.Group>
                  <Segment style={{ backgroundColor: '#D9FFF9', color: '#007C7A' }}>
                    <h4>
                      {subcategory.name}
                      <Icon name="angle down" color="teal" size="large"></Icon>
                    </h4>
                  </Segment>
                  {subcategory.skills
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s: Category_categories_subcategories_skills) => (
                      <div key={s.id}>
                        <Segment
                          onClick={() => {
                            const subC = subcategory as Category_categories_subcategories;
                            setSubCategory(subC);
                            setSkill(s);
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            color: preskills && preskills.some((skill) => skill.id == s.id) ? 'teal' : 'black',
                            fontWeight: weakerSkillList.includes(s.id) ? '7' : 'default',
                          }}
                          data-cy="subcategoriesSkillList"
                        >
                          {/* {weakerSkillList.includes(s.uid) && (
                          <GoStarFill
                            style={{
                              marginRight: '5px',
                              fontSize: '12px',
                            }}
                          />
                        )} */}
                          {`${s.name}`}
                          {/* {weakerSkillList.includes(s.uid) && (
                          <GoStarFill
                            style={{
                              marginLeft: '5px',
                              fontSize: '12px',
                            }}
                          />
                        )} */}
                        </Segment>
                      </div>
                    ))}
                </Segment.Group>
              </Grid.Column>
            ) : null,
          )}
        </Fragment>
      );
    }

    if (setPreskills && goalPreSkills) {
      const c = category as Consultation_consultation_child_goals_skill_category;
      return (
        <Fragment>
          {c.subcategories.map((subcategory) =>
            subcategory.skills.length > 0 ? (
              <Grid.Column key={subcategory.id} textAlign="center">
                <Segment.Group>
                  <Segment style={{ backgroundColor: '#D9FFF9', color: '#007C7A' }}>
                    <h4>
                      {subcategory.name}
                      <Icon name="angle down" color="teal" size="large"></Icon>
                    </h4>
                  </Segment>
                  {subcategory.skills
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s) => (
                      <div key={s.id}>
                        <Segment
                          onClick={() => {
                            const gp = goalPreSkills.find((o) => {
                              return o.skillId === s.id;
                            });
                            if (gp && gp.mastered) {
                              return;
                            }

                            setPreskills((prevState) => {
                              let result;
                              const sPreskill = {
                                ...s,
                                mastered: gp ? gp.mastered : false,
                              } as Consultation_consultation_child_goals_preskills;
                              if (prevState.some((skill) => skill.id == s.id)) {
                                result = prevState.filter((skill) => skill.id !== s.id);
                              } else {
                                result = [...prevState, sPreskill];
                              }
                              return result;
                            });
                          }}
                          style={{
                            cursor: 'pointer',
                            color: preskills && preskills.some((skill) => skill.id == s.id) ? 'teal' : 'black',
                          }}
                          data-cy="subcategoriesPreskillList"
                        >
                          {s.name}
                        </Segment>
                      </div>
                    ))}
                </Segment.Group>
              </Grid.Column>
            ) : null,
          )}
        </Fragment>
      );
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Segment textAlign="center">
        <h4>
          {category.name}
          <Icon name="angle down" color="teal" size="large"></Icon>
        </h4>
      </Segment>
      <Grid columns={2} stackable>
        {renderSkillsComponent()}
      </Grid>
    </div>
  );
};

export default TherapistConsultationScreenSkillsComponent;
