import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lite_product_courses_links } from '../../../../graphql/queries/types/Client/Lite';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router';
import {
  UpdateCourseLinkRead,
  UpdateCourseLinkReadVariables,
} from '../../../../graphql/mutations/types/UpdateCourseLinkRead';
import { UPDATE_COURSE_LINK_READ_MUTATION } from '../../../../graphql/mutations/updateCourseLinkRead';
import { Course_child_course_links } from '../../../../graphql/queries/types/Client/Course';
import {
  ActionContainer,
  ActionText,
  FileIcon,
  InformationCourseInfoLinkButton,
  ItemContainer,
  KeyIcon,
  PlayIcon,
  ReadTimeContainer,
  ReadTimeText,
} from '../styles';
import { ClientContext } from '../../ClientContext';
import { UserComment } from '../../../../style';
import PasswordModal from './PasswordModal';

interface Props {
  courseLink: Lite_product_courses_links;
  timeStatus: boolean;
  cIndex: number;
  clIndex: number;
  selected: boolean;
  ordinal: number;
}

const CourseLink = ({ ordinal, courseLink, timeStatus, cIndex, clIndex, selected }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { child, userRead, updateUserRead, setReturnMessage } = useContext(ClientContext);
  const childId = child.id;
  const { id, description, link, linkType, read, linkLength, linkLengthMinutes, linkLengthSeconds, videoPassword } =
    courseLink;
  const [show, setShow] = useState(false);
  const [updateCourseLinkRead] = useMutation<UpdateCourseLinkRead, UpdateCourseLinkReadVariables>(
    UPDATE_COURSE_LINK_READ_MUTATION,
    {
      update(cache, { data }) {
        if (!data) {
          return null;
        }
        if (!userRead) {
          return data;
        }
        if (data.updateCourseLinkRead.returnMessage.status === 'SUCCESS') {
          const cl = userRead.courseLinks.find((l) => l.id === id);
          if (!cl) {
            userRead.courseLinks.push({
              id: id,
              read: true,
            } as Course_child_course_links);
          } else {
            cl.read = true;
          }
          updateUserRead(userRead, true, false);
        }
        return data;
      },
    },
  );
  const emptyReadTime = linkLength === null;
  const havePassword = videoPassword !== null;
  const isVideo = linkType === 'video';
  const isDocument = linkType === 'document';

  const copyPassword = () => {
    copyToClipboard(videoPassword);
    copyNotification();
  };

  const copyNotification = () => {
    if (havePassword) {
      setReturnMessage({ status: 'SUCCESS', message: t('client.video.successCopy') });
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 4000);
    }
  };

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleClickLink = () => {
    updateCourseLinkRead({
      variables: {
        childId: childId,
        courseLinkId: id,
      },
    });
    const allowExternalDomains = ['google'];
    const isExternalLink = allowExternalDomains.some((domain: string) => {
      return link.includes(domain);
    });
    if (isExternalLink) {
      window.open(link, '_blank', 'noreferrer');
    }
    if (link.includes('youku.com')) {
      const splitURL = link.split(/[_.]/);
      const keyURL = splitURL[splitURL.length - 2];
      copyPassword();
      return history.push('/video?s=course&p=yk&k=' + cIndex + '&i=' + clIndex + '&v=' + keyURL);
    }
    if (link.includes('youtu.be')) {
      const splitURL = link.split('/');
      const keyURL = splitURL[splitURL.length - 1];
      return history.push('/video?s=course&p=yt&k=' + cIndex + '&i=' + clIndex + '&v=' + keyURL);
    }
    if (link.includes('zohoexternal')) {
      return history.push('/video?s=course&p=zh&k=' + cIndex + '&i=' + clIndex);
    }
    if (link.includes('weiyun')) {
      return history.push('/video?s=course&p=wy&k=' + cIndex + '&i=' + clIndex);
    }
  };

  const roundToNearestMinute = () => {
    if (emptyReadTime) {
      return;
    }
    const getNumberSeconds = linkLengthSeconds;
    const getNumberMinutes = linkLengthMinutes;
    if (getNumberSeconds > 30) {
      return getNumberMinutes + 1;
    }
    return getNumberMinutes;
  };

  const handleClickPassword = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  // TODO: need to fix read status
  // console.log(read);
  // const status = read? 'true' : 'false';
  return (
    <>
      <PasswordModal
        show={show}
        selected={selected}
        videoPassword={videoPassword}
        copyPassword={copyPassword}
        handleCloseModal={handleCloseModal}
      />
      <InformationCourseInfoLinkButton
        selected={selected}
        readStatus={read}
        availableTime={timeStatus}
        onClick={handleClickLink}
      >
        {/* <div>{status}</div> */}
        <UserComment>
          {ordinal}
          {'. '}
          {description}
        </UserComment>
        <ItemContainer>
          <ReadTimeContainer selected={selected}>
            <>
              {isVideo && <PlayIcon className="play circle" />}
              {isDocument && <FileIcon className="file outline" />}
              {!emptyReadTime && (
                <ReadTimeText selected={selected}>
                  {roundToNearestMinute()}
                  {t('utils.minutes')}
                </ReadTimeText>
              )}
            </>
          </ReadTimeContainer>
          {havePassword && (
            <ActionContainer selected={selected} onClick={(e) => handleClickPassword(e)}>
              <KeyIcon className={'key'} />
              <ActionText>{t('client.video.password')}</ActionText>
            </ActionContainer>
          )}
        </ItemContainer>
      </InformationCourseInfoLinkButton>
    </>
  );
};

export default CourseLink;
