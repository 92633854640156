import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildTasks_child_assignedActivities } from '../../../../../../../graphql/queries/types/ChildTasks';
import { Space, Typography } from 'antd';
import { renderStringToList } from '../../../../../../../util/renderStringToList';
interface Props {
  activity: ChildTasks_child_assignedActivities;
}
const TherapistView = ({ activity }: Props) => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const parentInteraction = renderStringToList(activity.parentInteraction);

  return (
    <>
      <Title level={3}>{activity.name}</Title>
      <Space size="large" direction="vertical">
        <div>
          <Title level={5}>{t('therapist.childInfo.activity.general.activity')}</Title>
          <Text>{activity.interactionTactics}</Text>
        </div>
        <div>
          <Title level={5}>{t('therapist.childInfo.activity.activityModal.activitySummary')}</Title>
          <Text>{activity.summary}</Text>
        </div>
        <div>
          <Title level={5}>{t('therapist.childInfo.activity.activityModal.parentInteraction')}</Title>
          <Text>{parentInteraction}</Text>
        </div>
      </Space>
    </>
  );
};

export default TherapistView;
