import React, { useState, useContext } from 'react';
import Header from '../Header';
import { Card, UserInfoDiv, GeneralSegment, RouteSectionContainer } from './styles';
import UserInfoComponent from './components/UserInfoComponent';
import { ReturnMessage } from '../../../types';
import ReturnMessageComponent from '../ReturnMessageComponent';
import LocaleDropdown from './components/LocaleDropdown';
import { useTranslation } from 'react-i18next';
import PhoneComponent from './components/PhoneComponent';
import UserContext from '../UserContext';
import UserNameInfoComponent from './components/UserNameInfoComponent';

interface Props {}

const Account = () => {
  const { user } = useContext(UserContext);
  const { id, name, email, phoneNumber, locale, uuid, firstName, lastName } = user;
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);

  const { t } = useTranslation();

  return (
    <div>
      <Header />
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <RouteSectionContainer>
        <GeneralSegment>
          <h2>{t('auth.title')}</h2>
          <hr />
          <UserInfoDiv>
            <UserNameInfoComponent
              userId={id}
              title={t('auth.name')}
              firstName={firstName}
              lastName={lastName}
              setReturnMessage={setReturnMessage}
              userUuid={uuid}
            />
            <UserInfoComponent
              userId={id}
              title={t('auth.email')}
              attribute="Email"
              value={email}
              setReturnMessage={setReturnMessage}
              userUuid={uuid}
            />

            <PhoneComponent
              userId={id}
              userUuid={uuid}
              title={t('auth.phoneNumber')}
              attribute="Phone Number"
              value={phoneNumber ? phoneNumber : ''}
              setReturnMessage={setReturnMessage}
            />
            <LocaleDropdown userId={id} value={locale} setReturnMessage={setReturnMessage} />
          </UserInfoDiv>
        </GeneralSegment>
      </RouteSectionContainer>
    </div>
  );
};

export default Account;
