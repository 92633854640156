import styled from 'styled-components';
import { MainColor } from '../../Client/WordTrackingRoute/styles';

export const TabMenuContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const TabMenuItem = styled.div<{ active: boolean }>`
  text-align: center;
  margin: 0 4px;
  color: ${({ active }) => (active ? '#5abaae' : 'grey')};
  padding: 3px 8px 10px 8px;
  cursor: pointer;
  width: fit-content;
`;

export const TabContent = styled.div`
  padding: 0 8px;
`;

export const ActivitiesGoalsContainer = styled.div`
  padding: 5px 8px;
`;

export const HighlightBottomEdge = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  cursor: pointer;
  :: before {
    box-sizing: border-box;
    background: ${(props) => (props.isSelected ? MainColor : 'rgba(0,0,0,0)')};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    content: '';
    height: 3px;
    left: 8px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 16px);
  }
`;
