import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UserComment } from '../../../style';
import BrowserDetector from '../../../util/Hooks/BrowserDetector';
import { PasswordModalMask } from '../CoursesRoute/styles';
import {
  ChromeTooltip,
  ChromeTooltipContainer,
  EllipsisIcon,
  SafariTooltip,
  SafariTooltipContainer,
  ShareIcon,
} from './styles';
import iosShare from './assets/iosShare.png';
import iosAdd from './assets/iosAdd.png';

const AddShortcutTooltip = React.forwardRef((props, ref) => {
  const [showSafariTooltip, setShowSafariTooltip] = useState<boolean>(false);
  const [showChromeTooltip, setShowChromeTooltip] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();

  let width = window.screen.width;
  const mobile = width <= 768;

  React.useImperativeHandle(ref, () => ({ handleShowTooltip }));

  const handleShowTooltip = () => {
    let browser = BrowserDetector();
    let recognizeBrowser = browser !== 'No browser detection';
    if (recognizeBrowser && mobile) {
      if (browser === 'safari') {
        setShowSafariTooltip(true);
      } else {
        setShowChromeTooltip(true);
      }
    } else {
      history.push('/addShortcut');
    }
  };

  return (
    <>
      <SafariTooltipContainer show={showSafariTooltip}>
        <PasswordModalMask onClick={() => setShowSafariTooltip(false)}></PasswordModalMask>
        <SafariTooltip>
          <UserComment>
            {t('utils.addShortcut.tooltip.safari1')}
            <span>
              <ShareIcon src={iosShare} alt="ios share"></ShareIcon>
            </span>
            {t('utils.addShortcut.tooltip.safari2')}
            <span>
              <ShareIcon src={iosAdd} alt="ios add to homescreen"></ShareIcon>
            </span>
            {t('utils.addShortcut.tooltip.safari3')}
          </UserComment>
        </SafariTooltip>
      </SafariTooltipContainer>
      <ChromeTooltipContainer show={showChromeTooltip}>
        <PasswordModalMask onClick={() => setShowChromeTooltip(false)}></PasswordModalMask>
        <ChromeTooltip>
          <UserComment>
            {t('utils.addShortcut.tooltip.chrome1')}
            <span>
              <EllipsisIcon className="ellipsis vertical"></EllipsisIcon>
            </span>
            {t('utils.addShortcut.tooltip.chrome2')}
          </UserComment>
        </ChromeTooltip>
      </ChromeTooltipContainer>
    </>
  );
});

export default AddShortcutTooltip;
