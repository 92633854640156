import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { CHILD_WORD_TRACKING_QUERY } from '../../../graphql/queries/childTrackingWords';
import { Word, WordTracking, WordTrackingVariables } from '../../../graphql/queries/types/WordTracking';
import { BiggerRouteSectionContainer } from '../styles';
import { ClientContext } from '../ClientContext';
import TabMenu from '../../common/TabMenu';
import Summary from './Summary';
import TrackingWords from './WordsTable/TrackingWords';
import { CompletedWords } from './WordsTable/CompletedWords';

const WordTrackingRoute = () => {
  window.scroll(0, 0);
  const { t } = useTranslation();
  const { child } = useContext(ClientContext);
  const [selection, setSelection] = useState(0);
  const [words, setWords] = useState([] as Word[]);

  const { loading, error, data } = useQuery<WordTracking, WordTrackingVariables>(CHILD_WORD_TRACKING_QUERY, {
    variables: { childId: child.id },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const wordList =
      data != null
        ? data.words.map((word: Word) => {
            return {
              ...word,
              totalCount: word.repeatCount + word.speakCount,
            };
          })
        : [];
    setWords([...wordList]);
  }, [data]);

  const insertNewWord = (word: Word) => {
    const newWord: Word = { ...word, totalCount: word.repeatCount + word.speakCount };
    setWords([...words, newWord]);
  };
  const deleteWords = (wordIds: number[]) => {
    const newWords = words.filter((word) => !wordIds.includes(word.id));
    setWords([...newWords]);
  };

  const completeWords = (words: Word[]) => {
    const newWords = words.map((word: Word) => ({
      ...word,
      totalCount: word.repeatCount + word.speakCount,
    }));
    setWords([...newWords]);
  };

  const totalWordCount = useMemo(() => {
    return words.length;
  }, [words]) as number;

  const [trackingWords, Over20RepeatCount, Over20SpeakCount] = useMemo(() => {
    const trackingWords = words.filter((word: Word) => !word.complete);
    const Over20RepeatCount = words.filter((word: Word) => word.repeatCount >= 20).length;
    const Over20SpeakCount = words.filter((word: Word) => word.speakCount >= 20).length;
    return [trackingWords, Over20RepeatCount, Over20SpeakCount];
  }, [words]) as [Word[], number, number];

  const [completedWords, completedWordCount] = useMemo(() => {
    const completedWords = words.filter((word: Word) => word.complete);
    return [completedWords, completedWords.length];
  }, [words]) as [Word[], number];

  const trackingWordsEmpty = trackingWords.length === 0;
  return (
    <BiggerRouteSectionContainer>
      <Summary
        totalWordCount={totalWordCount}
        totalCompleteCount={completedWordCount}
        Over20RepeatCount={Over20RepeatCount}
        Over20SpeakCount={Over20SpeakCount}
      />
      <TabMenu
        tabTitles={[t('client.wordTracking.tabTitle.tracker'), t('client.wordTracking.tabTitle.mastered')]}
        tabContents={[
          <TrackingWords
            words={trackingWords}
            insertNewWord={insertNewWord}
            deleteWords={deleteWords}
            completeWords={completeWords}
          />,
          <CompletedWords
            words={completedWords}
            trackingWordsEmpty={trackingWordsEmpty}
            handleChangeTabState={setSelection}
          />,
        ]}
        selection={selection}
        handleTabClick={setSelection}
      />
    </BiggerRouteSectionContainer>
  );
};

export default WordTrackingRoute;
