import React, { useContext, useState } from 'react';
import { TherapistChildContext, ConsultationsAndPlanObj } from '../../../TherapistChildContext';
import { ConfigProvider, Empty, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import type { TableProps } from 'antd';
import { statusTagColor, typeTagColor, typeTagIcon } from '..';
import { todoTableActionButton, dueTimeTextSelector, dueTimeValueSelector } from './todoTableUtils';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

interface Props {
  todoList: ConsultationsAndPlanObj[];
  onSelect: (todoId: string, todoType: string) => void;
  tableType: 'next' | 'today' | 'overdue';
}

const TodoTable = ({ onSelect, todoList, tableType }: Props) => {
  const { child, setShowUserInfoDrawer } = useContext(TherapistChildContext);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ConsultationsAndPlanObj>>({});
  const { t } = useTranslation();

  const handleChange: TableProps<ConsultationsAndPlanObj>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<ConsultationsAndPlanObj>);
  };

  const onClickUserTag = (event) => {
    event.stopPropagation();
    setShowUserInfoDrawer(true);
  };

  // table columns
  const title: ColumnType<ConsultationsAndPlanObj> = {
    key: 'title',
    ellipsis: {
      showTitle: false,
    },
    render: (todo: ConsultationsAndPlanObj) => (
      <Tooltip placement="topLeft" title={todo.title}>
        {todo.title}
      </Tooltip>
    ),
    fixed: 'left',
  };

  const status: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.status'),
    render: (todo: ConsultationsAndPlanObj) => (
      <Tag style={{ cursor: 'not-allowed' }} color={statusTagColor(todo.status)}>
        {t(`therapist.childInfo.general.status.${todo.status}`)}
      </Tag>
    ),
    width: 120,
  };

  const type: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.type'),
    render: (todo: ConsultationsAndPlanObj) => (
      <Tag style={{ cursor: 'not-allowed' }} icon={typeTagIcon(todo.type)} color={typeTagColor(todo.type)}>
        {t(`therapist.childInfo.general.todoType.${todo.type}`)}
      </Tag>
    ),
    width: 140,
  };

  const dueOn: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.dueTime'),
    key: 'dueOn',
    sorter: (a, b) => dueTimeValueSelector(a) - dueTimeValueSelector(b),
    sortOrder: sortedInfo.columnKey === 'dueOn' ? sortedInfo.order : null,
    ellipsis: {
      showTitle: false,
    },
    render: (todo: ConsultationsAndPlanObj) => (
      <Tooltip placement="topLeft" title={dueTimeTextSelector(todo, 'short')}>
        {dueTimeTextSelector(todo, 'short')}
      </Tooltip>
    ),
    width: 160,
  };

  const consultationOn: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.consultationTime'),
    key: 'consultationOn',
    sorter: (a, b) => a.consultationStartMilliseconds - b.consultationStartMilliseconds,
    sortOrder: sortedInfo.columnKey === 'consultationOn' ? sortedInfo.order : null,
    ellipsis: {
      showTitle: false,
    },
    render: (todo: ConsultationsAndPlanObj) => (
      <Tooltip placement="topLeft" title={todo.consultationStartShortDate}>
        {todo.consultationStartShortDate}
      </Tooltip>
    ),
    width: 160,
  };

  const caregiver: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.caregiver'),
    render: () => (
      <Tag style={{ cursor: 'pointer' }} onClick={(e) => onClickUserTag(e)}>
        {child.client.user.name}
      </Tag>
    ),
    width: 120,
  };
  const action: ColumnType<ConsultationsAndPlanObj> = {
    title: t('therapist.childInfo.todo.tableAndDrawer.action'),
    render: (todo: ConsultationsAndPlanObj) => <>{todoTableActionButton(todo, false, t)}</>,
    width: 100,
  };

  // table columns setting
  const todayColumns: ColumnsType<ConsultationsAndPlanObj> = [
    title,
    status,
    type,
    dueOn,
    consultationOn,
    caregiver,
    action,
  ];
  const dueColumns: ColumnsType<ConsultationsAndPlanObj> = [
    title,
    status,
    type,
    dueOn,
    consultationOn,
    caregiver,
    action,
  ];
  const nextColumns: ColumnsType<ConsultationsAndPlanObj> = [
    title,
    status,
    type,
    dueOn,
    consultationOn,
    caregiver,
    action,
  ];

  // table selector
  const tableSelector = (): ColumnsType<ConsultationsAndPlanObj> => {
    if (tableType === 'next') {
      return nextColumns;
    } else if (tableType === 'today') {
      return todayColumns;
    } else if (tableType === 'overdue') {
      return dueColumns;
    } else {
      return nextColumns;
    }
  };

  // table selector
  const tableMinWidthSelector = (): number => {
    if (tableType === 'next') {
      return 880;
    } else if (tableType === 'today') {
      return 1040;
    } else if (tableType === 'overdue') {
      return 1040;
    } else {
      return 0;
    }
  };

  // table empty state
  const customizeRenderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No TO-DO</span>}></Empty>
  );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        className="todoTable"
        dataSource={todoList}
        columns={tableSelector()}
        onChange={handleChange}
        pagination={false}
        onRow={(todo) => {
          return {
            onClick: () => {
              onSelect(todo.id, todo.type);
            },
          };
        }}
        scroll={{ x: tableMinWidthSelector() }}
      />
    </ConfigProvider>
  );
};

export default TodoTable;
