import gql from 'graphql-tag';

export const CHILD_GOALS = gql`
  query ChildConsultationnotes($childId: ID!) {
    childConsultations(childId: $childId) {
      id
      consultationTime
      goalRecords {
        id
        currentDenominator
        currentNumerator
        goal {
          id
          description
          metric
          initNumerator
          initDenominator
          currentNumerator
          currentDenominator
          successNumerator
          successDenominator
          achieved
          deleted
          skill {
            id
            name
            category {
              id
              name
            }
            subcategory {
              id
              name
            }
          }
          goalPreskills {
            skillId
            name
            mastered
          }
        }
      }
    }
    child(childId: $childId) {
      id
      childSixMonthGoal {
        id
        description
      }
    }
  }
`;
