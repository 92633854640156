import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, UnOrderList } from '../../../../../../style';
import { SkillCategory } from '../../../../../../util/Category';
import CollapsibleContent from '../../../../CoursesRoute/component/CollapsibleContent';

interface Props {
  category: SkillCategory;
  show: boolean;
}

const DefinitionSegment = ({ category, show }: Props) => {
  const { t } = useTranslation();
  const getDefinationSegment = () => {
    if (category.id === '2') {
      return (
        <CollapsibleContent
          defaultOpen={false}
          show={show}
          header={<>{t('client.dashboard.skill.definition')}</>}
          content={
            <>
              <>{t(`utils.fiveCategories.${category.id}.content`)}</>
              <UnOrderList>
                <List>{t(`utils.fiveCategories.${category.id}.content-1`)}</List>
                <List>{t(`utils.fiveCategories.${category.id}.content-2`)}</List>
                <List>{t(`utils.fiveCategories.${category.id}.content-3`)}</List>
              </UnOrderList>
            </>
          }
        />
      );
    } else if (category.id === '4' || category.id === '7') {
      return <></>;
    }
    return (
      <CollapsibleContent
        defaultOpen={false}
        show={show}
        header={<>{t('client.dashboard.skill.definition')}</>}
        content={<>{t(`utils.fiveCategories.${category.id}.content`)}</>}
      />
    );
  };

  return <>{getDefinationSegment()}</>;
};
export default DefinitionSegment;
