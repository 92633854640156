/* eslint-disable react/display-name */
import React from 'react';
import { SampleModalText } from '../styles';
import { Modal, Button, Icon } from 'semantic-ui-react';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickNotShowMore: (notificationId: string) => void;
  notificationId: string;
}

const SampleCampaign = ({ setOpen, handleClickNotShowMore, notificationId }: Props) => {
  return (
    <div
      style={{
        top: '200px',
        marginTop: '0px',
        width: '600px',
        height: '400px',
        textAlign: 'center',
        color: '#A19D9D',
        paddingTop: '40px',
      }}
    >
      <Modal.Content>
        <SampleModalText>sample</SampleModalText>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            handleClickNotShowMore(notificationId);
          }}
          basic
          color="green"
        >
          Not Show Anymore <Icon name="chevron right" />
        </Button>
        <Button onClick={() => setOpen(false)} primary>
          Proceed <Icon name="chevron right" />
        </Button>
      </Modal.Actions>
    </div>
  );
};

export default SampleCampaign;
