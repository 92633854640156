import { Collapse, Tabs } from 'antd';
import styled from 'styled-components';
import { FixedTopHeight } from '../../layout/styles';

export const TodoTabs = styled(Tabs)`
  .ant-tabs-nav {
    top: ${FixedTopHeight}px;
    position: sticky;
    z-index: 300;
    background-color: white;
  }
`;

export const CollapseContainer = styled(Collapse)`
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  .ant-collapse-header {
    background-color: #fafbfc;
    padding: 16px !important;
  }
`;

export const TodoContainer = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background: #bcc0c4;
  }
`;
