import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserContext from './components/common/UserContext';
import LoginComponent from './components/common/LoginComponent';
import ClientRoutes from './components/Client/Routes';
import TherapistRoutes from './components/Therapist/Routes';
import ResetPasswordComponent from './components/common/ResetPassword';
import ForgotPasswordComponent from './components/common/ForgotPassword';
import ResetPasswordToken from './components/common/ResetPasswordToken';

import ShowNotification from './components/common/BrowserNotification/showNotification';
import { Viewer_viewer, Viewer_viewer_deviceNotification } from './graphql/queries/types/Viewer';
import { UPDATE_DEVICE_NOTIFICATION_SHOWN_MUTATION } from './graphql/mutations/updateDeviceNotificationShown';
import {
  UpdateDeviceNotificationShown,
  UpdateDeviceNotificationShownVariables,
} from './graphql/mutations/types/UpdateDeviceNotificationShown';

interface Props {
  user: Viewer_viewer | null;
  deferredPrompt: any | null;
}

const UserContainer = ({ user, deferredPrompt }: Props) => {
  const [updateDeviceNotificationShown] = useMutation<
    UpdateDeviceNotificationShown,
    UpdateDeviceNotificationShownVariables
  >(UPDATE_DEVICE_NOTIFICATION_SHOWN_MUTATION);

  useEffect(() => {
    if (user && user.loggedInBefore) {
      if (!('Notification' in window)) {
        return;
      }
      Notification.requestPermission();
      if (Notification.permission !== 'granted') {
        return;
      }

      user.deviceNotifications.forEach((notification: Viewer_viewer_deviceNotification) => {
        ShowNotification(notification);
      });
      const deviceNotificationIds = user.deviceNotifications.map(
        (notification: Viewer_viewer_deviceNotification): string => {
          return notification.id;
        },
      );
      updateDeviceNotificationShown({
        variables: {
          deviceNotificationIds,
        },
      });
    }
  }, [user]);

  return (
    <Router>
      {!user && (
        <Switch>
          <Route exact path="/reset-password" render={() => <ResetPasswordToken />} />
          <Route exact path="/forgot-password" render={() => <ForgotPasswordComponent />} />
          <Route path="/" render={() => <LoginComponent />} />
        </Switch>
      )}
      {user && !user.loggedInBefore && <ResetPasswordComponent locale={user.locale} userId={user.id} />}
      {user && user.loggedInBefore && (
        <UserContext.Provider
          value={{
            user: user,
          }}
        >
          {user.metaType === 'Client' && <ClientRoutes deferredPrompt={deferredPrompt} />}
          {user.metaType === 'Therapist' && <TherapistRoutes />}
        </UserContext.Provider>
      )}
    </Router>
  );
};

export default UserContainer;
