import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Input, ShowErrNextButton } from '../../../../../style';

export const FilterContainer = styled.div`
  margin-bottom: 10px;
`;

export const DesktopContainer = styled.div`
  display: none;
  @media only screen and (min-width: 1110px) {
    display: grid;
    grid-template-columns: 135px 65px 65px;
    grid-gap: 10px;
  }
`;

export const MobileContainer = styled.div`
  display: block;
  @media only screen and (min-width: 1110px) {
    display: none;
  }
`;

export const FilterButton = styled(ShowErrNextButton)`
  margin: 0;
  height: 40px;
  width: 40px;
  padding: 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: #909090;
  box-shadow: none;
`;
export const FilterIcon = styled(Icon)`
  margin: 0 !important;
`;
export const FilterInput = styled(Input)`
  text-align: center;
  ::-webkit-inner-spin-button {
    display: none;
  }
`;
