import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import TherapistConsultationScreenContext from './TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';
import { GeneralSegment } from '../../../style';

const TherapistConsultationScreenGoalsComponent = () => {
  const {
    consultation: { child },
  } = useContext(TherapistConsultationScreenContext);

  const COL_COUNT = 2; // number of columns

  // Total Width Length = 16
  const GOAL_WIDTH_LEN = 11; // Grid.Column width value
  const OBJECTIVE_WIDTH_LEN = 5; // Grid.Column width value

  const { t } = useTranslation();
  const tPath = 'therapist.consultationScreen';
  return (
    <GeneralSegment>
      <h3>{t('therapist.consultationScreen.thisWeeksGoals')}</h3>
      <Grid columns={COL_COUNT}>
        <Grid.Row>
          <Grid.Column width={GOAL_WIDTH_LEN}>{t('therapist.consultationScreen.goalTable.goal')}</Grid.Column>
          <Grid.Column width={OBJECTIVE_WIDTH_LEN}>{t('therapist.consultationScreen.goalTable.objective')}</Grid.Column>
        </Grid.Row>
        {child.goals.map((goal) => {
          if (!goal.achieved) {
            return (
              <Grid.Row
                key={goal.id}
                style={{ backgroundColor: 'rgba(241, 241, 241, 0.45)', margin: '5px 0px', padding: '10px 5px' }}
              >
                <Grid.Column width={GOAL_WIDTH_LEN} textAlign="left">
                  {goal.description}
                </Grid.Column>
                <Grid.Column width={OBJECTIVE_WIDTH_LEN} textAlign="left">
                  {`${goal.successNumerator} / ${goal.successDenominator} ${t(
                    `${tPath}.goalTable.metrics.${goal.metric}`,
                  )}`}
                </Grid.Column>
              </Grid.Row>
            );
          }
        })}
      </Grid>
    </GeneralSegment>
  );
};

export default TherapistConsultationScreenGoalsComponent;
