import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = (props) => {
  const [rating, setRating] = useState(0);

  const handleClick = (value) => {
    setRating(value);
  };

  useEffect(() => {
    const starData = rating;
    props.onData(starData);
  }, [rating]);

  return (
    <div className="rating">
      {[1, 2, 3, 4, 5].map((value) => (
        <FaStar
          key={value}
          color={value <= rating && !props.didNotComplete ? '#ffc107' : '#e4e5e9'}
          style={{ transitionDuration: '0.3s', cursor: 'pointer' }}
          size={40}
          onClick={() => handleClick(value)}
        ></FaStar>
      ))}
    </div>
  );
};

export default StarRating;
