import styled from 'styled-components';
import { MainColor } from './components/Client/WordTrackingRoute/styles';

// navbar
export const NavbarLabel = styled.div<{ isSelect: boolean }>`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${(props) => (props.isSelect ? '500' : '400')};
  color: ${(props) => (props.isSelect ? '#030303' : '#606060')};
`;
export const SmallNavbarLabel = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
`;

// title
export const H1Title = styled.div`
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.2rem;
  color: #030303;
`;
export const H2Title = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  color: #030303;
`;

// section title
export const SectionTitle = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 400;
  // color: #030303;
  margin: 1rem 0;
  text-transform: capitalize;
`;
// export const MediumSectionTitle = styled.div`
//   font-size: 1.8rem;
//   font-weight: 400;
//   line-height: 2.4rem;
// `;
export const SmallSectionTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: normal;
  margin-bottom: 1rem;
`;

// metric
export const MetricsTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: #030303;
`;
export const MetricsNumber = styled.div`
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 3.6rem;
  color: #030303;
`;
export const ActionMetricsTitle = styled(MetricsTitle)`
  color: inherit;
  text-transform: uppercase;
`;
export const NoActionMetricsTitle = styled(MetricsTitle)`
  color: #606060;
`;
export const ActionMetricsNumber = styled(MetricsNumber)<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? '#030303' : '#606060')};
`;

// video or article title
export const ContentTitle = styled.div`
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 400;
  color: #030303;
`;
export const SmallContentTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  color: #030303;
`;

// Badge
export const BadgeTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: normal;
`;
export const SmallBadgeTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: unset;
`;

// hashtag
export const MiniAttribution = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

// description
export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: #606060;
`;
export const SmallDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #606060;
`;

// user comment message
export const UserComment = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: normal;
`;
export const CommentLink = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #606060;
`;

// link
export const Link = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: normal;
`;

// Action button
// navbar title, tab title, subscribe button
export const ActionTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.007px;
  text-transform: uppercase;
`;
export const SmallActionTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: #606060;
  text-transform: uppercase;
`;

// // 13px
// export const ThumbnailAttribution = styled.div`
//   font-size: 1.3rem;
//   font-weight: 400;
//   line-height: 1.8rem;
//   letter-spacing: inherit;
//   text-transform: none;
// `;
// export const Caption = styled.div`
//   font-size: 1.3rem;
//   line-height: normal;
//   font-weight: 500;
//   letter-spacing: 0.007px;
//   text-transform: uppercase;
// `;

// append
export const FooterText = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #909090;
`;

// segment
export const GeneralSegment = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
`;

// modal
export const ModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000090;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
`;
export const ModalContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background-color: white;
  border-radius: 15px;
  height: max-content;
  width: max-content;
  position: relative;
  padding: 40px 30px;
  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 50px;
  }
`;
export const ModalContainerWithClose = styled.div`
  height: max-content;
  width: max-content;
`;
export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;
export const ContainerWidth = styled.div`
  width: 300px;
  @media only screen and (min-width: 768px) {
    width: 330px;
  }
  @media only screen and (min-width: 992px) {
    width: 360px;
  }
  @media only screen and (min-width: 1200px) {
    width: 400px;
  }
`;
export const ModalHeader = styled(ContainerWidth)`
  margin-bottom: 20px;
  text-align: left;
`;
export const HeaderTitle = styled(H2Title)`
  color: ${MainColor};
`;
export const HeaderDescription = styled(Description)`
  margin-top: 10px;
`;
export const ModalContent = styled(ContainerWidth)`
  overflow: auto;
  max-height: 400px;
  text-align: left;
`;
export const ContentText = styled(Description)`
  color: #030303;
`;
export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// button
export const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 50px;
  padding: 0 40px 0 40px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;
export const NextButton = styled(ButtonStyle)<{ status: boolean }>`
  background: ${({ status }) => (status ? '#5abaae' : '#E8E9E9')};
  pointer-events: ${({ status }) => (status ? 'cursor' : 'none')};
  color: ${({ status }) => (status ? 'white' : '#00000085')};
  :hover {
    box-shadow: ${({ status }) =>
      status
        ? 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        : 'none'};
  }
`;
export const ShowErrNextButton = styled(ButtonStyle)<{
  status: boolean;
}>`
  background: ${({ status }) => (status ? `${MainColor}` : 'white')};
  color: ${({ status }) => (status ? 'white' : `${MainColor}`)};
  border: solid 1px ${MainColor};
  :hover {
    box-shadow: ${({ status }) =>
      status
        ? 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        : 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'};
  }
`;

export const CancelButton = styled(ButtonStyle)`
  background: white;
  box-shadow: none;
  height: 44px;
  padding: 0 20px 0 20px;
  margin-right: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  :hover {
    background: #eef8f6;
  }
`;
export const SaveButton = styled(ButtonStyle)`
  background: ${MainColor};
  color: white;
  border: solid 1px ${MainColor};
  padding: 0 20px 0 20px;
  height: 44px;
`;

// empty state
export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 600px;
  padding: 0 18%;
  text-align: center;
`;
export const EmptyStateImg = styled.img`
  // height: 50%;
  // width: 50%;
`;
export const EmtpyStateTitle = styled(ContentTitle)`
  margin: 30px 0 0 0;
`;
export const EmtpyStateContent = styled(Description)`
  margin: 15px 0 0 0;
`;
export const EmptyStateButton = styled(ButtonStyle)`
  height: 44px;
  background: #5abaae;
  color: white;
  padding: 0 30px;
  margin: 15px 0 0 0;
`;

// textbox input
export const Input = styled.input`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: #030303;
  text-align: left;
  letter-spacing: 0.3px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  :focus {
    border-bottom: none;
    outline: none;
    border: solid 1px ${MainColor};
  }
  :hover {
    border: solid 1px ${MainColor};
  }
  :: placeholder {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #909090;
  }
`;

// list
export const OrderList = styled.ol`
  padding-inline-start: 20px;
`;
export const UnOrderList = styled.ul`
  padding-inline-start: 20px;
`;
export const List = styled.li`
  margin-bottom: 8px;
`;
