import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallSectionTitle } from '../../../../../../style';
import { DescriptionContainer } from '../../../Goal/GoalModal/OverallTab/styles';
import { SkillCountBadge } from '../../../styles';
import Skills from './Skills';
import DefinitionSegment from './DefinitionSegment';
import { SkillTitleContainer } from './styles';
import { SkillCategory } from '../../../../../../util/Category';
import { CPC_PRODUCT_TYPES } from '../../../../../../util/Product';
interface Props {
  productType: string;
  category: SkillCategory;
  show: boolean;
}

const OverallTab = ({ category, productType, show }: Props) => {
  const { t } = useTranslation();

  const [CPCmastered, CPCinProgress, CPCnotYetInProgress, PEAinProgress, PEAnotYetInProgress] = useMemo(() => {
    // CPC skills
    const CPCmastered = category.skills.filter((s) => s.mastered);
    const CPCinProgress = category.skills.filter((s) => s.count > 0 && !s.mastered);
    const CPCnotYetInProgress = category.skills.filter((s) => s.count === 0 && !s.mastered);
    // PEA skills
    const PEAinProgress = category.skills.filter((s) => s.count > 0);
    const PEAnotYetInProgress = category.skills.filter((s) => s.count === 0);
    return [CPCmastered, CPCinProgress, CPCnotYetInProgress, PEAinProgress, PEAnotYetInProgress];
  }, [category]);

  // CPC skills count
  const masteredCount = CPCmastered.length;
  const CPCinProgressCount = CPCinProgress.length;
  const CPCnotYetInProgressCount = CPCnotYetInProgress.length;

  // PEA skills count
  const PEAinProgressCount = PEAinProgress.length;
  const PEAnotYetInProgressCount = PEAnotYetInProgress.length;

  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  // show skills
  const showInProgress = isCPC ? CPCinProgress : PEAinProgress;
  const showNotYetInProgress = isCPC ? CPCnotYetInProgress : PEAnotYetInProgress;
  // show skills count
  const showInProgressCount = isCPC ? CPCinProgressCount : PEAinProgressCount;
  const showNotYetInProgressCount = isCPC ? CPCnotYetInProgressCount : PEAnotYetInProgressCount;

  return (
    <div>
      <DefinitionSegment category={category} show={show} />
      {isCPC && (
        <>
          <SkillTitleContainer>
            <SmallSectionTitle>{t('client.dashboard.skill.totalMastered')}</SmallSectionTitle>
            <SkillCountBadge type={'mastered'}>{masteredCount}</SkillCountBadge>
          </SkillTitleContainer>
          <DescriptionContainer>
            <Skills Skills={CPCmastered} type={'mastered'} />
          </DescriptionContainer>
        </>
      )}
      <SkillTitleContainer>
        <SmallSectionTitle>{t('client.dashboard.skill.practicedSkill')}</SmallSectionTitle>
        <SkillCountBadge type={'inProgress'}>{showInProgressCount}</SkillCountBadge>
      </SkillTitleContainer>
      <DescriptionContainer>
        <Skills Skills={showInProgress} type={'inProgress'} />
      </DescriptionContainer>
      <SkillTitleContainer>
        <SmallSectionTitle>{t('client.dashboard.skill.notYetInProgressSkill')}</SmallSectionTitle>
        <SkillCountBadge type={'notYetInProgress'}>{showNotYetInProgressCount}</SkillCountBadge>
      </SkillTitleContainer>
      <DescriptionContainer>
        <Skills Skills={showNotYetInProgress} type={'notYetInProgress'} />
      </DescriptionContainer>
    </div>
  );
};
export default OverallTab;
