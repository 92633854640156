export enum PenguinSmartDays {
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
}

export enum PenguinSmartTimeSlot {
  'breakfast',
  'morning',
  'lunch',
  'afternoon',
  'dinner',
  'bath',
  'night',
  'before_sleep',
}

export const GetLengthOfEnum = (enumVar: any) => {
  return Object.keys(enumVar).length / 2;
};

export const CurrentWeekday = () => {
  const today = new Date().getDay();
  const thisDay = ((today + 6) % 7) + 1;
  return thisDay;
};
