import React, { useState, useContext, useEffect } from 'react';
import { Button, Icon, TextArea, Modal } from 'semantic-ui-react';
import TherapistConsultationScreenSkillsComponent from './SkillsComponent';
import TherapistConsultationScreenMetrics from './GoalsCardMetrics';
import CreateGoalButton from './CreateGoalButton';
import {
  Consultation_consultation_child_goals_skill_category,
  Consultation_consultation_child_goals,
  Consultation_consultation_child_goals_preskills,
  ConsultationVariables,
  Consultation,
  Consultation_consultation_child_goals_skill_subcategory,
} from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import { GoalCardGrid } from '../styles';
import {
  CreateGoalRecord as Data,
  CreateGoalRecordVariables as Variables,
} from '../../../../graphql/mutations/types/CreateGoalRecord';
import { CREATE_GOAL_RECORD_MUTATION } from '../../../../graphql/mutations/createGoalRecord';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useMutation } from 'react-apollo';
import { DeleteGoal, DeleteGoalVariables } from '../../../../graphql/mutations/types/DeleteGoal';
import { DELETE_GOAL } from '../../../../graphql/mutations/deleteGoal';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { ReturnMessageStatus } from '../../../../types/graphql-global-types';
import { CreateGoal_createGoal_returnMessage } from '../../../../graphql/mutations/types/CreateGoal';
import UpdateMasteredModal from './UpdateMasteredModal';
import {
  GoalsCardSkillsContainer,
  GoalsCardSkillsTabContainer,
  GoalsCardSkillsTabText,
  GoalsCardSkillsTabImg,
  GoalsCardDeleteGoalDiv,
  GoalsCardDeleteGoalTitle,
  GoalsCardDeleteGoalSubTitle,
  GoalCardDeleteGoalButtonDiv,
  GoalCardDeleteGoalButton,
} from './styles';
import Click from '../../../../assets/click.svg';
import { GeneralSegment } from '../../../../style';
import AllSkillsAndActivitiesComponent from './AllSkillsAndActivitiesComponent';
import { RoundBorderButton } from './StyledComponents';
import { FiEdit } from 'react-icons/fi';
import { PiUserCircleGearBold } from 'react-icons/pi';
import { Col } from 'antd';
import { FAST_POLL } from '../constants';
import { REMOVE_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeActivitiesToChild';
import {
  RemoveActivitiesToChild,
  RemoveActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/RemoveActivitiesToChild';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

interface Props {
  goal: Consultation_consultation_child_goals;
  index?: number;
  isEdit: boolean;
  category: Consultation_consultation_child_goals_skill_category;
  subCategory: Consultation_consultation_child_goals_skill_subcategory;
  achieveGoal?: (id: string) => void;
  updateGoal?: (goal: Consultation_consultation_child_goals) => void;
  setGoalDeleted?: (id: string) => void;
  addGoal?: (goal: Consultation_consultation_child_goals) => void;
  isSelectingActivity?: boolean;
}

interface masteredModalDataInterface {
  goalId: string;
  skill: Consultation_consultation_child_goals_preskills;
}

const TherapistConsultationScreenGoalsCardComponent = ({
  goal,
  isEdit,
  category,
  subCategory,
  achieveGoal,
  updateGoal,
  setGoalDeleted,
  addGoal,
  isSelectingActivity,
}: Props) => {
  const {
    consultation,
    consultation: {
      id: consultationId,
      child: {
        product: { productTypesName },
      },
    },
    setReturnMessage,
    setNewGoal,
    setPollingCount,
    setCategory,
    setSubCategory,
    setSkill,
  } = useContext(TherapistConsultationScreenContext);

  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);
  const [isEditable, setIsEditable] = useState<boolean>(isEdit);
  const [isAddPreskills, setIsAddPreskills] = useState<boolean>(false);
  const resetPreskills = () => {
    return goal.preskills.map((s) => {
      const gp = goal.goalPreskills.find((o) => {
        return o.skillId === s.id;
      });
      return {
        ...s,
        mastered: gp ? gp.mastered : false,
      };
    });
  };
  const [preskills, setPreskills] = useState<Consultation_consultation_child_goals_preskills[]>(resetPreskills());

  const [description, setDescription] = useState<string>(goal.description);
  const [metric, setMetric] = useState<string>(goal.metric);
  const [currentNumerator, setCurrentNumerator] = useState<number>(goal.currentNumerator);
  const [successNumerator, setSuccessNumerator] = useState<number>(goal.successNumerator);
  const [successDenominator, setSuccessDenominator] = useState<number>(goal.successDenominator);
  const [updateMasteredModal, setUpdateMasteredModal] = useState<boolean>(false);
  const [masteredModalData, setMasteredModalData] = useState<masteredModalDataInterface | undefined>(undefined);

  const [askDelete, setAskDelete] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteReason, setDeleteReason] = useState<string>('');

  const [deleteGoal, { data: deleteData }] = useMutation<DeleteGoal, DeleteGoalVariables>(DELETE_GOAL, {
    variables: { goalId: goal.id, reason: deleteReason },
    onCompleted: () => {
      setTimeout(() => {
        setPollingCount((prevCount) => prevCount - 1);
      }, FAST_POLL);
    },
    update(cache, { data }) {
      if (!data) {
        return null;
      }
      const queryResult = cache.readQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
      });

      if (!queryResult || !queryResult.consultation) {
        return null;
      }
      if (setGoalDeleted) {
        setGoalDeleted(goal.id);
      }
      const updatedGoals = queryResult.consultation.child.goals.filter((g) => g.id !== goal.id);
      queryResult.consultation.child.goals = updatedGoals;

      cache.writeQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
        data: { consultation: queryResult.consultation },
      });
    },
  });

  useEffect(() => {
    if (deleteData && deleteData.deleteGoal) {
      setReturnMessage(deleteData.deleteGoal.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [deleteData, setReturnMessage]);

  const [createGoalRecord, { data: createData }] = useMutation<Data, Variables>(CREATE_GOAL_RECORD_MUTATION, {
    refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultationId } }],
    update(cache, { data }) {
      if (!data) {
        return null;
      }
      const queryResult = cache.readQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
      });

      if (!queryResult || !queryResult.consultation) {
        return null;
      }

      const updatedGoalIndex = queryResult.consultation.child.goals.findIndex((g) => {
        return g.id === goal.id;
      });
      queryResult.consultation.child.goals[updatedGoalIndex].preskills = preskills;
      queryResult.consultation.child.goals[updatedGoalIndex].currentNumerator = currentNumerator;
      queryResult.consultation.child.goals[updatedGoalIndex].description = description;

      if (currentNumerator >= successNumerator) {
        queryResult.consultation.child.goals[updatedGoalIndex].achieved = true;
        if (achieveGoal) {
          achieveGoal(goal.id);
        }
      } else {
        if (updateGoal) {
          updateGoal(queryResult.consultation.child.goals[updatedGoalIndex]);
        }
      }

      cache.writeQuery<Consultation, ConsultationVariables>({
        query: CONSULTATION_QUERY,
        variables: { consultationId },
        data: { consultation: queryResult.consultation },
      });
    },
  });

  useEffect(() => {
    if (createData && createData.createGoalRecord) {
      setReturnMessage(createData.createGoalRecord.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [createData, setReturnMessage]);

  const validateInput = () => {
    const messageStatus: ReturnMessageStatus = ReturnMessageStatus.ERROR;

    const returnMessage: CreateGoal_createGoal_returnMessage = {
      status: messageStatus,
      message: '',
    };
    if (description === '' || currentNumerator > successDenominator || preskills.length === 0) {
      if (description === '') {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.descriptionRequired');
      }
      if (currentNumerator > successDenominator) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.numeratorOverDenominator');
      }
      if (preskills.length === 0) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.preskillEmpty');
      }
      setReturnMessage(returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 6000);
      return false;
    }
    return true;
  };

  const handleClickSave = async () => {
    if (validateInput()) {
      await createGoalRecord({
        variables: {
          goalId: goal.id,
          currentNumerator: 0,
          currentDenominator: successDenominator,
          description,
          preskillIds: preskills.map((preskill: Consultation_consultation_child_goals_preskills) => preskill.id),
          consultationId,
        },
      });
      setIsEditable(false);
      setIsAddPreskills(false);
    }
  };

  const handleClickAchieved = async () => {
    await createGoalRecord({
      variables: {
        goalId: goal.id,
        currentNumerator: successNumerator,
        currentDenominator: successDenominator,
        description,
        preskillIds: preskills.map((preskill: Consultation_consultation_child_goals_preskills) => preskill.id),
        consultationId,
      },
    });

    if (achieveGoal) {
      achieveGoal(goal.id);
    }
    setIsEditable(false);
    setIsAddPreskills(false);
  };

  const handleClickUpdateMasteredModal = (skill: Consultation_consultation_child_goals_preskills, goalId: string) => {
    setMasteredModalData({ skill, goalId });
    setUpdateMasteredModal(!updateMasteredModal);
  };

  const handleClickCancel = () => {
    setIsEditable(false);
    setIsAddPreskills(false);
    setNewGoal(false);
    setSkill(null);
    setCategory(null);
    setSubCategory(null);
    setPreskills(resetPreskills());
  };

  const handleClickDelete = async () => {
    setAskDelete(true);
  };

  const [removeActivitiesToChild, { data }] = useMutation<RemoveActivitiesToChild, RemoveActivitiesToChildVariables>(
    REMOVE_ACTIVITIES_TO_CHILD_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
    },
  );

  const handleRemoveActivities = async () => {
    const skillIds = goal.preskills.map((s) => s.id);

    const activitiesToRemove = consultation.child.activityChildren.filter((a) =>
      skillIds.includes(a.activity.mainSkill.id),
    );

    const activityIdsToRemove = activitiesToRemove.map((a) => a.activity.id);
    const consultationIdsToRemove = activitiesToRemove.map((a) => a.consultation.id);

    try {
      setPollingCount((prevCount) => prevCount + 1);
      await removeActivitiesToChild({
        variables: {
          activityIds: activityIdsToRemove,
          childId: consultation.child.id,
          consultationIds: consultationIdsToRemove,
        },
      });
    } catch (error) {
      console.error('Mutation error:', error);
    }

    return true;
  };

  const handleClickAskDelete = (text: string) => {
    setDeleteReason(text);
    setAskDelete(false);
    setIsDelete(true);
    handleRemoveActivities();
  };

  const confirmDelete = async () => {
    setPollingCount((prevCount) => prevCount + 1);
    await deleteGoal({ variables: { goalId: goal.id, reason: deleteReason } });
    setIsDelete(false);
  };

  const cancelDelete = () => {
    setAskDelete(false);
    setIsDelete(false);
  };

  const handleTextChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  };

  const { t } = useTranslation();

  return (
    <div style={{ margin: '20px 0' }}>
      {masteredModalData && (
        <UpdateMasteredModal
          consultationId={consultationId}
          isOpen={updateMasteredModal}
          setIsOpen={setUpdateMasteredModal}
          skill={masteredModalData.skill}
          goalId={masteredModalData.goalId}
          setPreskills={setPreskills}
          updateGoal={updateGoal}
        />
      )}
      <GeneralSegment>
        <div style={{ position: 'relative' }}>
          <p
            style={{ color: 'teal', fontWeight: 'bold' }}
          >{`${category.name} - ${subCategory.name} - ${goal.skill.name}`}</p>
          {isEditable && !goal.achieved && (
            <span
              style={{ position: 'absolute', top: '-20px', right: '-20px', cursor: 'pointer' }}
              onClick={() => handleClickDelete()}
            >
              {goal.id ? <Icon name="times" color="red" title="Delete" /> : null}
            </span>
          )}
        </div>
        <GoalCardGrid>
          <p>{`${t('therapist.consultationScreen.progress.goalCard.description')} `} </p>
          {isEditable ? (
            <div>
              <TextArea
                rows={5}
                placeholder="description"
                value={description}
                onChange={handleTextChange}
                style={{
                  resize: 'none',
                  borderRadius: '5px',
                  borderColor: 'rgba(34, 36, 38, 0.15)',
                  width: '100%',
                }}
                data-cy="descriptionTextArea"
              />
            </div>
          ) : (
            <span>{goal.description}</span>
          )}
        </GoalCardGrid>
        {isEditable ? (
          <div>
            {isEdit ? (
              <div>
                <TherapistConsultationScreenMetrics
                  goal={goal}
                  numerator={successNumerator}
                  setNumerator={setSuccessNumerator}
                  denominator={successDenominator}
                  setDenominator={setSuccessDenominator}
                  metric={metric}
                  setMetric={setMetric}
                  disabled={!isEditable}
                  title={t('therapist.consultationScreen.progress.goalCard.objective')}
                  edit={true}
                />
              </div>
            ) : (
              <div>
                <TherapistConsultationScreenMetrics
                  goal={goal}
                  numerator={successNumerator}
                  setNumerator={setSuccessNumerator}
                  denominator={successDenominator}
                  setDenominator={setSuccessDenominator}
                  metric={metric}
                  setMetric={setMetric}
                  disabled={isEditable}
                  title={t('therapist.consultationScreen.progress.goalCard.objective')}
                  edit={true}
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            <GoalCardGrid>
              <p>{t('therapist.consultationScreen.progress.goalCard.objective')}</p>
              <span>{`${goal.successNumerator} / ${goal.successDenominator} ${t(
                `therapist.consultationScreen.goalTable.metrics.${goal.metric}`,
              )}`}</span>
            </GoalCardGrid>
          </div>
        )}
        {!isSelectingActivity && (
          <GoalCardGrid>
            <p>{t('therapist.consultationScreen.progress.goalCard.preskills')}</p>
            <GoalsCardSkillsContainer>
              {preskills.map((preskill) => {
                if (goal.skill.id !== preskill.id) {
                  const mastered = preskill.mastered ? true : false;
                  return (
                    <GoalsCardSkillsTabContainer
                      key={preskill.id}
                      pointer={isEditable && !preskill.mastered ? true : false}
                      onClick={() => {
                        isEditable && !preskill.mastered ? handleClickUpdateMasteredModal(preskill, goal.id) : void 0;
                      }}
                    >
                      <GoalsCardSkillsTabText mastered={mastered}>{preskill.name}</GoalsCardSkillsTabText>
                      <GoalsCardSkillsTabImg display={mastered} src={Click} />
                    </GoalsCardSkillsTabContainer>
                  );
                }
              })}
            </GoalsCardSkillsContainer>
            {isEditable && (
              <Col>
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  <RoundBorderButton
                    onClick={() => {
                      setIsAddPreskills(true);
                    }}
                    style={{ height: '30px', padding: '0 15px' }}
                  >
                    {t('therapist.consultationScreen.progress.goalCard.buttons.addPreskills')}
                    <PiUserCircleGearBold style={{ fontSize: '20px', padding: '0 0 0 3px' }} />
                  </RoundBorderButton>
                </div>
                <p>{`(${t('therapist.consultationScreen.progress.goalCard.buttons.multiselectPossible')})`}</p>
              </Col>
            )}
          </GoalCardGrid>
        )}

        {isEditable ? (
          <div style={{ margin: '12px 0 0 0' }}>
            {isEdit ? (
              <div>
                <Button.Group fluid>
                  <CreateGoalButton
                    inputs={{
                      description: description,
                      metric: metric,
                      initNumerator: currentNumerator,
                      initDenominator: successDenominator,
                      successNumerator: successNumerator,
                      successDenominator: successDenominator,
                      skill: goal.skill,
                      preskills: preskills,
                    }}
                    addGoal={addGoal}
                  />
                  <Button attached="bottom" color="grey" onClick={() => handleClickCancel()}>
                    {t('therapist.consultationScreen.progress.goalCard.buttons.cancel')}
                  </Button>
                </Button.Group>
                {isAddPreskills ? (
                  <TherapistConsultationScreenSkillsComponent
                    category={category}
                    setPreskills={setPreskills}
                    preskills={preskills}
                    goalPreSkills={goal.goalPreskills}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div>
                <Button.Group fluid>
                  <Button
                    icon
                    attached="bottom"
                    color="blue"
                    onClick={() => handleClickSave()}
                    data-cy="editGoalConfirmButton"
                  >
                    {t('therapist.consultationScreen.progress.goalCard.buttons.save')}
                    <Icon name="save" />
                  </Button>
                  <Button attached="bottom" color="grey" onClick={() => handleClickCancel()}>
                    {t('therapist.consultationScreen.progress.goalCard.buttons.cancel')}
                  </Button>
                </Button.Group>
                {isAddPreskills ? (
                  <TherapistConsultationScreenSkillsComponent
                    category={category}
                    setPreskills={setPreskills}
                    preskills={preskills}
                    goalPreSkills={goal.goalPreskills}
                  />
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        ) : (
          !goal.achieved &&
          !isSelectingActivity &&
          isCPC && (
            <div
              onClick={() => setIsEditable(true)}
              style={{ display: 'flex', justifyContent: 'flex-end', margin: '-15px 0 0 0' }}
            >
              <RoundBorderButton style={{ height: '25px', padding: '0 14px' }}>
                <div style={{ padding: '2px 0 0 0' }}>
                  {t('therapist.consultationScreen.progress.goalCard.editGoal')}
                </div>
                <FiEdit style={{ margin: '0 0 0 3px' }} />
              </RoundBorderButton>
            </div>
          )
        )}

        {isSelectingActivity && !isEditable && (
          <AllSkillsAndActivitiesComponent skills={preskills} goal={goal} handleGoalAchieved={handleClickAchieved} />
        )}
      </GeneralSegment>
      <Modal
        open={askDelete}
        onClose={() => setAskDelete(false)}
        style={{ width: '330px', border: '1px solid #7FD6C4', borderRadius: '10px' }}
      >
        <GoalsCardDeleteGoalDiv>
          <GoalsCardDeleteGoalTitle>
            {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.text1')}
          </GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle
            goal={true}
          >{`${category.name} - ${subCategory.name} - ${goal.skill.name}`}</GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle goal={true}>{goal.description}</GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle>
            {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.text2')}
          </GoalsCardDeleteGoalTitle>
          <GoalCardDeleteGoalButtonDiv>
            <GoalCardDeleteGoalButton
              onClick={() =>
                handleClickAskDelete(t('therapist.consultationScreen.progress.goalCard.askDeleteReason.mergeGoal'))
              }
              reason={true}
            >
              {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.mergeGoal')}
            </GoalCardDeleteGoalButton>
            <GoalCardDeleteGoalButton
              onClick={() =>
                handleClickAskDelete(t('therapist.consultationScreen.progress.goalCard.askDeleteReason.tooSimple'))
              }
              reason={true}
            >
              {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.tooSimple')}
            </GoalCardDeleteGoalButton>
            <GoalCardDeleteGoalButton
              onClick={() =>
                handleClickAskDelete(t('therapist.consultationScreen.progress.goalCard.askDeleteReason.tooDifficult'))
              }
              reason={true}
            >
              {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.tooDifficult')}
            </GoalCardDeleteGoalButton>
            <GoalCardDeleteGoalButton
              onClick={() =>
                handleClickAskDelete(
                  t('therapist.consultationScreen.progress.goalCard.askDeleteReason.notSuitableChild'),
                )
              }
              reason={true}
            >
              {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.notSuitableChild')}
            </GoalCardDeleteGoalButton>
            <GoalCardDeleteGoalButton
              onClick={() =>
                handleClickAskDelete(
                  t('therapist.consultationScreen.progress.goalCard.askDeleteReason.notSuitableCaregiver'),
                )
              }
              reason={true}
            >
              {t('therapist.consultationScreen.progress.goalCard.askDeleteReason.notSuitableCaregiver')}
            </GoalCardDeleteGoalButton>
          </GoalCardDeleteGoalButtonDiv>
        </GoalsCardDeleteGoalDiv>
      </Modal>
      <Modal
        open={isDelete}
        onClose={() => setIsDelete(false)}
        style={{ width: '330px', border: '1px solid #7FD6C4', borderRadius: '10px' }}
      >
        <GoalsCardDeleteGoalDiv>
          <GoalsCardDeleteGoalTitle>
            {t('therapist.consultationScreen.progress.goalCard.confirmDelete.text1')}
          </GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle
            goal={true}
          >{`${category.name} - ${subCategory.name} - ${goal.skill.name}`}</GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle goal={true}>{goal.description}</GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalTitle>
            {t('therapist.consultationScreen.progress.goalCard.confirmDelete.text2')}
          </GoalsCardDeleteGoalTitle>
          <GoalsCardDeleteGoalSubTitle>
            {t('therapist.consultationScreen.progress.goalCard.confirmDelete.subtitle')}
          </GoalsCardDeleteGoalSubTitle>
          <GoalCardDeleteGoalButtonDiv>
            <GoalCardDeleteGoalButton onClick={() => confirmDelete()} confirm={true}>
              {t('therapist.consultationScreen.progress.goalCard.confirmDelete.yes')}
            </GoalCardDeleteGoalButton>
            <GoalCardDeleteGoalButton onClick={() => cancelDelete()} confirm={false}>
              {t('therapist.consultationScreen.progress.goalCard.confirmDelete.no')}
            </GoalCardDeleteGoalButton>
          </GoalCardDeleteGoalButtonDiv>
        </GoalsCardDeleteGoalDiv>
      </Modal>
    </div>
  );
};

export default TherapistConsultationScreenGoalsCardComponent;
