import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { OverviewHeader, OverviewTextSection, OverviewText, OverviewBoldText, IntroText } from '../styles';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';

const PEAOverview = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const tPath = 'utils.ParentEducationAcademy';
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/profile')} />
      <OverviewHeader>
        <h2>
          <b>{t(`${tPath}.title`)}</b>
        </h2>
        <br />
      </OverviewHeader>
      <OverviewTextSection>
        <IntroText>{t(`${tPath}.introText`)}</IntroText>
        <br />
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.consultation`)}: </OverviewBoldText>
          <ul>
            <li>{t(`${tPath}.consultationText1`)}</li>
            <li>{t(`${tPath}.consultationText2`)}</li>
          </ul>
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.course`)}: </OverviewBoldText>
          {t(`${tPath}.courseText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.task`)}: </OverviewBoldText>
          {t(`${tPath}.taskText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.qnaSession`)}: </OverviewBoldText>
          {t(`${tPath}.qnaSessionText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.evaluation`)}: </OverviewBoldText>
          {t(`${tPath}.evaluationText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.account`)}: </OverviewBoldText>
          {t(`${tPath}.accountText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.replay`)}: </OverviewBoldText>
          {t(`${tPath}.replayText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.surveyTracking`)}: </OverviewBoldText>
          {t(`${tPath}.surveyTrackingText`)}
        </OverviewText>
      </OverviewTextSection>
    </RouteSectionContainer>
  );
};

export default PEAOverview;
