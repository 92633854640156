import gql from 'graphql-tag';

export const UPDATE_INSTALL_BANNER_APPEAR_STATUS = gql`
  mutation UpdateInstallBannerAppearStatus($inputUserId: ID!, $status: Boolean!) {
    updateInstallBannerAppearStatus(inputUserId: $inputUserId, status: $status) {
      returnMessage {
        status
        message
      }
    }
  }
`;
