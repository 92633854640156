import gql from 'graphql-tag';

export const EDIT_CHILD_PICTURE = gql`
  mutation EditChildPicture($childId: ID!, $file: Upload!, $fileName: String!) {
    editChildPicture(childId: $childId, file: $file, fileName: $fileName) {
      child {
        id
        name
        dob
        gender
        pictureUrl
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
