import gql from 'graphql-tag';

export const UPDATE_NOTIFICATION_READ_MUTATION = gql`
  mutation UpdateNotificationRead($notificationId: [ID!]!) {
    updateNotificationRead(notificationId: $notificationId) {
      returnMessage {
        status
        message
      }
    }
  }
`;
