import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, OverviewContainer, DescriptionSection, VideoWrapper, LibraryVideoContainer } from '../../styles';
import { UserComment, SmallContentTitle } from '../../../../../style';

interface Props {
  videoTitle: string;
  description: string;
  videoUrl: string;
}

const LibraryVideoPage = ({ videoTitle, description, videoUrl }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <LibraryVideoContainer>
        <VideoWrapper>
          <Player
            width={560}
            height={315}
            src={videoUrl}
            title={videoTitle}
            frameBorder={0}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
            allowFullScreen
          />
        </VideoWrapper>
        <OverviewContainer>
          <DescriptionSection>
            <SmallContentTitle>{videoTitle}</SmallContentTitle>
            <UserComment>
              {description !== undefined ? t('client.video.description') + ': ' + description : null}
            </UserComment>
          </DescriptionSection>
        </OverviewContainer>
      </LibraryVideoContainer>
    </>
  );
};

export default LibraryVideoPage;
