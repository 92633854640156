import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { CPC_PRODUCT_TYPES } from '../../../../../../util/Product';

type Props = {
  masteredSkill: number;
  inProgressSkill: number;
  notYetInProgressSkill: number;
  productType: string;
};

type obj = { value: number };

const ColorForCPC = ['#FF7A2F', '#fecea9', '#f5f5f5'];
const ColorForPEA = ['#FF7A2F', '#f5f5f5'];

// const COLORS = ['#FFF300', '#FFE194', '#f5f5f5'];
// const COLORS = ['#ff8c10', '#FFBB28', '#f5f5f5'];
export default class SkillPieChart extends PureComponent<Props> {
  data: obj[];
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.data = (() => {
      const { masteredSkill, inProgressSkill, notYetInProgressSkill, productType } = this.props;
      const isCPC = CPC_PRODUCT_TYPES.includes(productType);
      if (isCPC) {
        return [{ value: masteredSkill }, { value: inProgressSkill }, { value: notYetInProgressSkill }];
      }
      return [{ value: inProgressSkill }, { value: notYetInProgressSkill }];
    })();
  }

  render() {
    const { productType } = this.props;
    const isCPC = CPC_PRODUCT_TYPES.includes(productType);
    return (
      <PieChart width={130} height={80}>
        <Pie
          data={this.data}
          cx={56}
          cy={36}
          innerRadius={25}
          outerRadius={40}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
        >
          {this.data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={isCPC ? ColorForCPC[index % ColorForCPC.length] : ColorForPEA[index % ColorForPEA.length]}
            />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
