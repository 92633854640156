import React from 'react';
import { useMutation } from 'react-apollo';
import mixpanel from 'mixpanel-browser';
import { Data, LOGOUT_MUTATION } from '../../../../graphql/mutations/logout';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../common/ErrorPage';

export const LogoutComponent = () => {
  const { t } = useTranslation();
  const [logout, { loading, error }] = useMutation<Data, {}>(LOGOUT_MUTATION);

  const handleClick = async () => {
    await logout();
    mixpanel.track('logout');
    document.cookie = 'X-CSRF-Token=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
    window.location.href = '/';
  };

  if (error) {
    return <ErrorPage />;
  }
  if (loading) {
    return <Spin />;
  }
  return <div onClick={handleClick}>{t('auth.logout')}</div>;
};
