import React from 'react';
import { List, Tooltip, Typography } from 'antd';
import { ConsultationsAndPlanObj } from '../../TherapistChildContext';
import { useTranslation } from 'react-i18next';
import CreatePlanButton from '../../feature/CreatePlanButton';
import { ButtonContainer, DetailsContainer, DetailsItemContainer, FooterContainer } from './styles';
import { AlertOutlined, SnippetsOutlined, CommentOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import DefaultDetails from './DefaultDetails';

interface Props {
  consultationList: ConsultationsAndPlanObj[];
}

const PlanListItem = ({ consultationList }: Props) => {
  const { t } = useTranslation();
  const { Text, Title } = Typography;

  return (
    <List
      itemLayout="vertical"
      dataSource={consultationList}
      grid={{ column: 1 }}
      renderItem={(item) => (
        <List.Item style={{ border: '1px solid rgba(0,0,0,.06)', marginBottom: '20px', minWidth: '600px' }}>
          <List.Item.Meta
            style={{ padding: '16px 16px 0 16px', marginBottom: 0 }}
            title={<Title level={5}>{item.title}</Title>}
          />
          <DetailsContainer>
            <DefaultDetails consultation={item}></DefaultDetails>
            {item.published && (
              <>
                <DetailsItemContainer>
                  <Tooltip title={t('therapist.dashboard.myClient.clientInfo.notes.mainComplaint')} arrowPointAtCenter>
                    <AlertOutlined translate={undefined} style={{ paddingTop: '3px' }} />
                  </Tooltip>
                  <Text>
                    {item.notes ? item.notes.mainComplaint : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
                  </Text>
                </DetailsItemContainer>
                <DetailsItemContainer>
                  <Tooltip title={t('therapist.dashboard.myClient.clientInfo.notes.internalNotes')} arrowPointAtCenter>
                    <SnippetsOutlined translate={undefined} style={{ paddingTop: '3px' }} />
                  </Tooltip>
                  <Text>
                    {item.notes ? item.notes.internalNotes : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
                  </Text>
                </DetailsItemContainer>
                <DetailsItemContainer>
                  <Tooltip
                    title={t('therapist.dashboard.myClient.clientInfo.notes.commentsForParents')}
                    arrowPointAtCenter
                  >
                    <CommentOutlined translate={undefined} style={{ paddingTop: '3px' }} />
                  </Tooltip>
                  <Text>
                    {item.notes
                      ? ReactHtmlParser(item.notes.commentForParents)
                      : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
                  </Text>
                </DetailsItemContainer>
              </>
            )}
          </DetailsContainer>
          <FooterContainer>
            <ButtonContainer>
              <CreatePlanButton consultation={item} showTitle={true}></CreatePlanButton>
            </ButtonContainer>
          </FooterContainer>
        </List.Item>
      )}
    />
  );
};

export default PlanListItem;
