import gql from 'graphql-tag';

export const SEND_SMS_TO_THERAPIST_BEF_CONSULTATION = gql`
  mutation SendSmsToTherapistBefConsultation($consultationId: ID!, $msg: String!, $details: String!) {
    sendSmsToTherapistBefConsultation(consultationId: $consultationId, msg: $msg, details: $details) {
      returnMessage {
        status
        message
      }
    }
  }
`;
