import { Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TherapistChildContext } from '../TherapistChildContext';
import { BreadCrumbContainer } from './styles';
interface MatchParams {
  consultationId: string;
  childId: string;
}

const BreadCrumbComponent = () => {
  const { child, publishedPlan } = useContext(TherapistChildContext);
  const location = useLocation();
  const { consultationId, childId } = useParams<MatchParams>();
  const { t } = useTranslation();

  const breadcrumbNameMap: Record<string, string> = {
    '/todo': t('therapist.childInfo.sider.todo'),
    '/consultation': t('therapist.childInfo.sider.consultation'),
    '/plan': t('therapist.childInfo.sider.plan'),
    '/homeworks': t('therapist.dashboard.myClient.clientInfo.topbar.homeworks'),
    '/messages': t('therapist.dashboard.myClient.clientInfo.topbar.messages'),
    '/dashboard': t('therapist.childInfo.sider.dashboard'),
    '/goal': t('therapist.childInfo.sider.goal'),
    '/tasks': t('therapist.dashboard.myClient.clientInfo.topbar.tasks'),
  };

  if (consultationId !== undefined) {
    const plan = publishedPlan.filter((plan) => plan.id === consultationId)[0];
    if (plan !== undefined) {
      breadcrumbNameMap[`/plan/${consultationId}`] = `${t('therapist.childInfo.general.todoType.plan')} ${
        plan.planSequence
      }`;
    }
  }

  const pathSnippets = location.pathname.split('/');
  let indexToRemove = 0;
  let numberToRemove = 3;
  pathSnippets.splice(indexToRemove, numberToRemove);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={`/child/${childId}${url}`}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="clients">
      <Link to="/clients">{t('therapist.dashboard.topbar.myClients')}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="child">
      <Link to={`/child/${childId}/todo`}>{child.name}</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <BreadCrumbContainer>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </BreadCrumbContainer>
  );
};

export default BreadCrumbComponent;
