let api_address = '';
let eval_backend_api_address = '';

if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  api_address = 'https://eval.staging.qierbao.com/';
  eval_backend_api_address = 'https://staging-api.evaluation.qierbao.com/';
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  api_address = 'https://eval.qierbao.com/';
  eval_backend_api_address = 'https://production-api.evaluation.qierbao.com/';
} else {
  api_address = 'http://localhost:3001/';
  eval_backend_api_address = 'http://localhost:8000/';
}

export default api_address;
export { eval_backend_api_address };
