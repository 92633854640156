import React, { useState, useContext, useRef } from 'react';
import { Button, Icon, Form, Loader } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { CREATE_MESSAGE } from '../../../../graphql/mutations/createMessage';
import { CreateMessage, CreateMessageVariables } from '../../../../graphql/mutations/types/CreateMessage';
import UserContext from '../../../common/UserContext';
import { GET_ROOM_MESSAGES_QUERY } from '../../../../graphql/queries/getRoomMessagesByType';
import { useHistory } from 'react-router';
import { SmallDescription } from '../../../../style';
import ErrorPage from '../../../common/ErrorPage';
import { CHILD_MESSAGES } from '../../../../graphql/queries/childMessage';
import { TherapistChildContext } from '../../../Therapist/ChildInfo/TherapistChildContext';
import { ClientContext } from '../../ClientContext';
import { CHILD_HOMEWORKS } from '../../../../graphql/queries/childHomeworks';

interface Props {
  roomId: string;
  homeworkId?: string;
  mainMessageId?: string;
}

const UploadMessageComponent = ({ roomId, homeworkId, mainMessageId }: Props) => {
  const textRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLInputElement>(null);
  const [body, setBody] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);

  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);

  const { client } = useContext(ClientContext);

  const [createMessage, { loading, error, data }] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    refetchQueries: [
      { query: GET_ROOM_MESSAGES_QUERY, variables: { roomId, messageType: 'DirectMessage' } },
      { query: GET_ROOM_MESSAGES_QUERY, variables: { roomId, messageType: 'HomeworkSubmission' } },
      { query: CHILD_MESSAGES, variables: { childId: childId == '' ? client.user.activeChildId : childId } },
      { query: CHILD_HOMEWORKS, variables: { childId: childId == '' ? client.user.activeChildId : childId } },
    ],
  });

  const { user } = useContext(UserContext);
  const history = useHistory();

  const handleClickUpload = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.click();
  };

  const { t } = useTranslation();

  if (error || !user) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  const handleClickSend = () => {
    if (!body && files.length === 0) return;
    createMessage({
      variables: {
        body: !!body ? body : files[0].name,
        userId: user.id,
        roomId,
        homeworkId: homeworkId || null,
        mainMessageId: mainMessageId || null,
        file: files.length > 0 ? files[0] : null,
        fileName: files.length > 0 ? files[0].name : null,
      },
    });
    setFiles([]);
    setBody('');
  };

  return (
    <>
      <>
        <Button.Group compact style={{ padding: '10px 0' }}>
          <Button icon style={{ backgroundColor: 'white' }} onClick={() => handleClickUpload(textRef)}>
            <input
              type="file"
              accept="text/*, application/*, video/*"
              ref={textRef}
              style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFiles(Array.prototype.slice.call(e.currentTarget.files));
              }}
            />
            <Icon name="paperclip" size="large" />
          </Button>
          <Button icon style={{ backgroundColor: 'white' }} onClick={() => handleClickUpload(imageRef)}>
            <input
              type="file"
              accept="image/*"
              ref={imageRef}
              style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFiles(Array.prototype.slice.call(e.currentTarget.files));
              }}
            />
            <Icon name="picture" size="large" />
          </Button>
          <Button icon style={{ backgroundColor: 'white' }} onClick={() => handleClickUpload(videoRef)}>
            <input
              type="file"
              accept="video/*"
              ref={videoRef}
              style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFiles(Array.prototype.slice.call(e.currentTarget.files));
              }}
            />
            <Icon name="youtube" size="large" />
          </Button>
        </Button.Group>
        <span style={{ padding: '10px', marginLeft: '20px' }}>
          {files.map((file: File) => (
            <div key={file.name} style={{ margin: '0 10px' }}>
              {file.name}
            </div>
          ))}
        </span>
      </>
      <Form onSubmit={() => handleClickSend()}>
        <Form.TextArea
          style={{ width: '100%' }}
          rows={6}
          value={body}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setBody(e.currentTarget.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SmallDescription>
            {t('client.communicationCard.homework.warningMessage')}
            <div
              onClick={() => history.push('/uploadfile')}
              style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'none' }}
            >
              {t('client.communicationCard.homework.instructionLink')}
            </div>
          </SmallDescription>
          <Form.Button floated="right" style={{ marginBottom: '20px', width: '70px', paddingLeft: '15px' }}>
            {t('client.communicationCard.submit')}
          </Form.Button>
        </div>
      </Form>
    </>
  );
};

export default UploadMessageComponent;
