import styled from 'styled-components';

export const Card = styled.div`
  margin: 0 25%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  @media only screen and (max-width: 600px) {
    display: block;
    margin: 30px 2rem;
  }
`;

export const UserInfoDiv = styled.div`
  margin-top: 15px;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 5px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 30px 5px;
  }
`;

export const UserInfoText = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
`;

export const UserInfoEditButton = styled.p`
  color: #365899;
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    margin: 5px 0px;
  }
`;

export const CancelButton = styled.p`
  padding: 2px 5px;
  margin: 0 3px;
  background-color: red;
  color: white;
  cursor: pointer;
  float: left;
`;

export const SaveButton = styled.p`
  padding: 2px 5px;
  margin: 0 3px;
  background-color: #47e4c2;
  color: white;
  cursor: pointer;
  float: left;
`;

export const InputField = styled.input`
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
`;

export const EmailInputField = styled.input`
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;

  @media only screen and (max-width: 600px) {
    width: 345px;
    max-width: 345px;
  }
`;

export const ErrorText = styled.p`
  color: red;
  margin: 5px 0 0;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  gap: 10px;
`;

export const NameEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 20px;
`;

export const NameContainer = styled.div`
  flex: 1;
`;

export const Select = styled.select<{ width: number }>`
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: ${({ width }) => width + 5}px;
  @media only screen and (max-width: 600px) {
    max-width: 345px;
    height: 55px;
    text-wrap: wrap;
  }
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  width: 150px;
`;

export const PhoneInput = styled.input`
  padding: '10px',
  fontSize: '14px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  flexGrow: 1,
  width: '150px',
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
  color: gray;
`;

export const GeneralSegment = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
`;

export const RouteSectionContainer = styled.div`
  padding: 20px 2%;
  margin: 0 auto; /* Center the container */
  max-width: 1200px; /* Optional: set a max-width to control the container's width */

  @media only screen and (min-width: 576px) {
    padding: 20px 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 20px 8%;
  }
  @media only screen and (min-width: 992px) {
    margin-left: auto; /* Ensure container is centered */
    margin-right: auto; /* Ensure container is centered */
    padding: 0 15%;
  }
`;
