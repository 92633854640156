import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getAge } from '../../../../../helpers/functions';
import defaultChild from '../../../../../assets/defaultChild.png';
import { Avatar, Space, Tooltip, Typography } from 'antd';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { TherapistChildContext } from '../../TherapistChildContext';

const BasicInfo = () => {
  const {
    child: { name, dob, gender, pictureUrl },
    setShowUserInfoDrawer,
  } = useContext(TherapistChildContext);
  const [year, month] = getAge(new Date(dob));
  const { Text } = Typography;

  const { t } = useTranslation();
  const tPath = 'therapist.dashboard.myClient';
  return (
    <Space style={{ padding: '24px 30px', width: '100%' }}>
      <Avatar
        size="large"
        style={{
          border: '1px solid rgba(0,0,0, 0.06)',
        }}
        src={pictureUrl ? pictureUrl : defaultChild}
      />
      <Tooltip title="View Profile" placement="right">
        <div
          onClick={() => setShowUserInfoDrawer(true)}
          style={{
            cursor: 'pointer',
          }}
        >
          <div style={{ lineHeight: '22px', textTransform: 'uppercase' }}>
            <Text style={{ fontSize: '18px' }}>{name}</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ lineHeight: '14px' }}>
              <Text type="secondary" style={{ fontSize: '12px' }}>
                {year}
                {t(`${tPath}.card.yearsOld`) + ' '}
                {month}
                {t(`${tPath}.card.monthOld`)}
              </Text>
            </div>
            {gender === 'male' ? (
              <ManOutlined
                style={{ marginLeft: '4px', fontSize: '12px', color: 'rgb(95,197,250)' }}
                translate={undefined}
              />
            ) : (
              <WomanOutlined
                style={{ marginLeft: '4px', fontSize: '12px', color: 'rgb(250,104,109)' }}
                translate={undefined}
              />
            )}
          </div>
        </div>
      </Tooltip>
    </Space>
  );
};

export default React.memo(BasicInfo);
