import React from 'react';
import { Row, Col } from 'antd';
import { GetActivitiesFromPreskills_getActivitiesFromPreskills } from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import { ActivityCardDiv, BigTitle, Title, Text, TextHiddenEllipsis } from './StyledComponents';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const MARGIN_HEIGHT = 10;
const TEXT_PADDING_WIDTH = 10;
const TEXT_PADDING_WIDTH_LARGE = 35;
interface Props {
  activity: GetActivitiesFromPreskills_getActivitiesFromPreskills;
  isSelected: boolean;
}

const ActivityCard = ({ activity, isSelected }: Props) => {
  const { id, name, summary, frequency, steps, interactionTactics, parentInteraction, scenario, tools } = activity;

  const WINDOW_WIDTH = window.innerWidth;
  const CARD_WIDTH = isSelected ? WINDOW_WIDTH * 0.6 : WINDOW_WIDTH * 0.6 * 0.97; // selected activity will appear larger than non-selected activities
  const CARD_BORDER = isSelected ? '2px outset teal' : 'none';

  const { t } = useTranslation();

  return (
    <ActivityCardDiv
      style={{ width: `${CARD_WIDTH}px`, border: CARD_BORDER, cursor: isSelected ? 'default' : 'pointer' }}
    >
      <BigTitle>{`${name} (ID: ${id})`}</BigTitle>
      {isSelected ? (
        <div>
          <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
            {t('therapist.consultationScreen.activityPopUp.summary')}
          </Title>
          <Text>{summary}</Text>

          <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
            {t('therapist.consultationScreen.activityPopUp.interactionTactics')}
          </Title>
          <Text>{interactionTactics}</Text>
        </div>
      ) : (
        <Col>
          <Row>
            <Col span={13}>
              <Title>{t('therapist.consultationScreen.activityPopUp.summary')}</Title>
              <TextHiddenEllipsis style={{ padding: `0 ${TEXT_PADDING_WIDTH_LARGE}px 0 0` }}>
                {summary}
              </TextHiddenEllipsis>
            </Col>
            <Col span={10}>
              <Title>{t('therapist.consultationScreen.activityPopUp.interactionTactics')}</Title>
              <TextHiddenEllipsis>{interactionTactics}</TextHiddenEllipsis>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: `0px 0 -12px 0`, fontSize: '30px', color: 'lightgrey' }}>
            <RiArrowDropDownLine />
          </Row>
        </Col>
      )}

      {isSelected && (
        <div>
          <Row style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
            <Col span={15}>
              <Title>{t('therapist.consultationScreen.activityPopUp.frequency')}</Title>
              <Text style={{ padding: `0 ${TEXT_PADDING_WIDTH}px 0 0` }}>{frequency}</Text>
            </Col>
            <Col span={4}>
              <Title>{t('therapist.consultationScreen.activityPopUp.scenario')}</Title>
              <Text style={{ padding: `0 ${TEXT_PADDING_WIDTH}px 0 0` }}>{scenario}</Text>
            </Col>
            <Col span={5}>
              <Title>{t('therapist.consultationScreen.activityPopUp.tools')}</Title>
              <Text>{tools}</Text>
            </Col>
          </Row>

          <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
            {t('therapist.consultationScreen.activityPopUp.steps')}
          </Title>
          <Text>{steps}</Text>
          <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
            {t('therapist.consultationScreen.activityPopUp.parentInteraction')}
          </Title>
          <Text>{parentInteraction}</Text>
        </div>
      )}
    </ActivityCardDiv>
  );
};

export default ActivityCard;
