import React from 'react';
import { Badge } from 'antd';
import { RingingSolidBellIcon, SolidBellIcon } from './styles';

export function NotificationBellIcon({ unreadNotifications, showTopNavBar, handleReadNotification }) {
  const IconSize = '23px';

  let IconComponent = unreadNotifications ? (
    <RingingSolidBellIcon status={showTopNavBar} size={IconSize} onClick={() => handleReadNotification()} />
  ) : (
    <SolidBellIcon status={showTopNavBar} size={IconSize} onClick={() => handleReadNotification()} />
  );

  return (
    <Badge dot={unreadNotifications} offset={[3, 0]}>
      {IconComponent}
    </Badge>
  );
}
