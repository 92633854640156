import React, { useEffect, useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import NotificationDropdown from './NotificationDropdown';
import { useMutation, useQuery } from 'react-apollo';
import { GetNotifications, GetNotificationsVariables } from '../../../graphql/queries/types/GetNotifications';
import { GET_NOTIFICATIONS_QUERY } from '../../../graphql/queries/getNotifications';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage';
import { UPDATE_NOTIFICATION_READ_MUTATION } from '../../../graphql/mutations/updateNotificationRead';
import {
  UpdateNotificationRead,
  UpdateNotificationReadVariables,
} from '../../../graphql/mutations/types/UpdateNotificationRead';
import { Badge } from 'antd';

interface Props {
  userId: string;
}

const Notification = ({ userId }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [unreadNotifications, setUnreadNotifications] = useState<boolean>(false);

  const [updateNotificationRead] = useMutation<UpdateNotificationRead, UpdateNotificationReadVariables>(
    UPDATE_NOTIFICATION_READ_MUTATION,
  );
  const { loading, error, data } = useQuery<GetNotifications, GetNotificationsVariables>(GET_NOTIFICATIONS_QUERY, {
    variables: { userId },
  });
  useEffect(() => {
    if (data && data.getNotifications && data.getNotifications.some((notification) => !notification.read)) {
      setUnreadNotifications(true);
    }
  }, [data]);

  const { t } = useTranslation();

  if (loading) {
    return (
      <div>
        <Loader active={true} />
      </div>
    );
  }

  if (error || !data || !data.getNotifications) {
    return <ErrorPage />;
  }

  const { getNotifications } = data;

  const handleReadNotification = () => {
    setIsActive(!isActive);
    if (data?.getNotifications && unreadNotifications) {
      updateNotificationRead({
        variables: {
          notificationId: data.getNotifications.map((notification) => notification.id),
        },
      });
    }
    setUnreadNotifications(false);
  };

  return (
    <div>
      <Badge dot={true} offset={[-4, 4]} count={unreadNotifications ? 1 : 0}>
        <Icon
          name="bell"
          size="large"
          style={{ color: 'grey', cursor: 'pointer', marginRight: '7px' }}
          onClick={() => handleReadNotification()}
        />
      </Badge>
      {isActive && <NotificationDropdown notifications={getNotifications} />}
    </div>
  );
};

export default Notification;
