import styled from 'styled-components';
import { GeneralSegment } from '../../../../../../style';
import { MainColor } from '../../../../WordTrackingRoute/styles';

export const GoalProgressSegment = styled(GeneralSegment)`
  width: 100%;
  padding: 20px 20px 40px 20px;
`;

export const MoreDetailsContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 20px;
`;

export const MoreDetailsButton = styled.span`
  position: relative;
  display: flex;
  cursor: pointer;
  :: before {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 500;
    color: ${MainColor};
    text-transform: uppercase;
    position: absolute;
    right: 20px;
    bottom: 45px;
    content: attr(data-duration);
  }
`;

export const DescriptionContainer = styled.div`
  padding-bottom: 30px;
`;

export const SkillsContainer = styled.div`
  display: inline-flex;
  padding-bottom: 30px;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
