import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { GET_PRESKILLS_QUERY } from '../../../../graphql/queries/getPreskillList';
import { useQuery } from 'react-apollo';
import { Preskills, PreskillsVariables, Preskills_getPreskillList } from '../../../../graphql/queries/types/Preskills';
import { useTranslation } from 'react-i18next';
import { SelectSkillsText, PreskillsSegment, PreskillsGrid, PreskillDiv, PreskillTitle } from '../styles';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  childId: string;
}

const PreskillsComponent = ({ childId }: Props) => {
  const { selectedPreskills, setSelectedPreskills } = useContext(TherapistConsultationScreenContext);

  const { loading, error, data } = useQuery<Preskills, PreskillsVariables>(GET_PRESKILLS_QUERY, {
    variables: { childId },
  });

  const { t } = useTranslation();

  if (loading) {
    return <p>{t('system.loading')}</p>;
  }
  if (error || !data || !data.getPreskillList) {
    return <ErrorPage />;
  }

  const handleCheckboxChange = (preskill: Preskills_getPreskillList) => {
    if (preskill.mastered) return;

    setSelectedPreskills((prevState: Preskills_getPreskillList[]) => {
      if (prevState.includes(preskill)) {
        return prevState.filter((skill: Preskills_getPreskillList) => skill !== preskill);
      }
      return [...prevState, preskill];
    });
  };

  const preskills = data.getPreskillList;

  return (
    <div>
      <SelectSkillsText>
        {t('therapist.consultationScreen.task.preskills.selectSkills')}
        <span style={{ color: 'gray' }}> {t('therapist.consultationScreen.task.preskills.noMoreThan7')}</span>
      </SelectSkillsText>
      <PreskillsSegment>
        <PreskillTitle>
          {t('therapist.consultationScreen.task.preskills.title')}
          <Icon name="angle down" style={{ color: 'teal', marginLeft: 5 }} />
        </PreskillTitle>
      </PreskillsSegment>
      <PreskillsSegment>
        <PreskillsGrid>
          {preskills
            .sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            })
            .map((preskill: Preskills_getPreskillList) => (
              <PreskillDiv
                key={preskill.id}
                onClick={() => handleCheckboxChange(preskill)}
                mastered={preskill.mastered ? true : false}
              >
                <input type="checkbox" checked={selectedPreskills.includes(preskill)} data-cy="preskillsCheckbox" />
                {preskill.name}
              </PreskillDiv>
            ))}
        </PreskillsGrid>
      </PreskillsSegment>
    </div>
  );
};

export default PreskillsComponent;
