import React, { useState, useEffect } from 'react';
import { UserInfo, UserInfoText } from '../styles';
import { ReturnMessage } from '../../../../types';
import { useMutation } from 'react-apollo';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { JS_COOKIE } from '../../../../util/Cookie';
import axios from 'axios';
import { eval_backend_api_address } from '../../../../constants/config';
import { CheckBox, CheckBoxContainer } from './styles';

interface Props {
  userId: string;
  value: boolean;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
  userUuid: string;
}

const SmsCheckbox = ({ userId, value, setReturnMessage, userUuid }: Props) => {
  const [smsEnabled, setSmsEnabled] = useState<boolean>(value);

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  // update message near header
  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
  }, [data]);

  const handleChange = () => {
    const updatedSmsEnabled = !smsEnabled;
    setSmsEnabled(updatedSmsEnabled);
    editUserInformation({ variables: { userId, attribute: 'SMS Notification', value: updatedSmsEnabled.toString() } }); //update Guide
    handleUpdateEval(updatedSmsEnabled, userUuid); // update eval
  };

  const handleUpdateEval = async (smsNotification, userUuid) => {
    const token = JS_COOKIE.get('token');
    const { data } = await axios.post(
      eval_backend_api_address + 'api/user/updateSmsNotificationFromGuide',
      {
        smsNotification: smsNotification,
        userUuid: userUuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const { t } = useTranslation();

  return (
    <UserInfo>
      <div>
        <UserInfoText>{t('auth.smsNotification')}</UserInfoText>
        <CheckBoxContainer>
          <CheckBox type="checkbox" checked={smsEnabled} onChange={handleChange}></CheckBox>
          <p>
            {t('onboarding.consentForm.smsConsentPolicy1')}
            <br />
            <a href="https://eval.qierbao.com/privacy-policy" target="_blank" rel="noreferrer">
              {t('onboarding.consentForm.smsConsentPolicy2')}
            </a>
          </p>
        </CheckBoxContainer>
      </div>
    </UserInfo>
  );
};

export default SmsCheckbox;
