import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SummaryContainer,
  Title,
  Count,
  TotalWordContainer,
  WordImitatedContainer,
  TotalMasteredContainer,
  WordSpontaneousContainer,
  WordSpontaneousAvatar,
  WordImitatedAvatar,
  TotalMasteredAvatar,
  TotalWordAvatar,
} from './styles';

type Props = {
  totalWordCount: number;
  totalCompleteCount: number;
  Over20RepeatCount: number;
  Over20SpeakCount: number;
};

const Summary = ({ totalWordCount, totalCompleteCount, Over20RepeatCount, Over20SpeakCount }: Props) => {
  const { t } = useTranslation();
  return (
    <SummaryContainer>
      <TotalWordContainer>
        <TotalWordAvatar />
        <Title>{t('client.wordTracking.summary.totalWord')}</Title>
        <Count>{totalWordCount}</Count>
      </TotalWordContainer>
      <TotalMasteredContainer>
        <TotalMasteredAvatar />
        <Title>{t('client.wordTracking.summary.totalMastered')}</Title>
        <Count>{totalCompleteCount}</Count>
      </TotalMasteredContainer>
      <WordImitatedContainer>
        <WordImitatedAvatar />
        <Title>{t('client.wordTracking.summary.wordImitated')}</Title>
        <Count>{Over20RepeatCount}</Count>
      </WordImitatedContainer>
      <WordSpontaneousContainer>
        <WordSpontaneousAvatar />
        <Title>{t('client.wordTracking.summary.wordSpontaneous')}</Title>
        <Count>{Over20SpeakCount}</Count>
      </WordSpontaneousContainer>
    </SummaryContainer>
  );
};

export default React.memo(Summary);
