import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

// RJR = React JoyRide
// general
export const RJRBigTooltipContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 500px;
  }
`;
export const RJRSmallTooltipContainer = styled.div`
  text-align: left;
  max-width: 290px;
`;
export const RJRTooltipHeaderContainer = styled.div`
  min-height: 60px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RJRTooltipContentContainer = styled.div`
  padding-top: 16px;
  text-align: left;
`;

// action button
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  height: max-content;
  padding: 0 8px;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  @media only screen and (min-width: 768px) {
    background-color: white;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
export const ActionFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const IconContainer = styled.div<{ type: string }>`
  height: 40px;
  width: 40px;
  margin: ${({ type }) => (type === 'left' ? '8px 12px 8px 0' : '8px 0 8px 12px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextContainer = styled.div`
  margin: 8px 0;
`;
export const MobileIcon = styled(Icon)`
  font-size: 2rem !important;
  margin: 1px 0 0 0 !important;
  background-color: rgb(228, 230, 235) !important;
  color: black;
  box-shadow: none !important;
`;
export const ChevronIcon = styled(Icon)`
  margin: 0 0 2px 0 !important;
`;

// add shortcut tooltip
export const SafariTooltipContainer = styled.div<{ show: boolean }>`
  bottom: 32px;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: 998;
  justify-content: center;
  align-items: center;
`;
export const SafariTooltip = styled.div`
  z-index: 998;
  height: max-content;
  text-align: left;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 25px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  :before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background: white;
    box-sizing: border-box;
    bottom: -16px;
    transform: rotate(45deg);
  }
`;
export const ChromeTooltipContainer = styled.div<{ show: boolean }>`
  top: 32px;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: 998;
  justify-content: right;
`;
export const ChromeTooltip = styled.div`
  z-index: 998;
  height: max-content;
  text-align: left;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 25px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  :before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background: white;
    box-sizing: border-box;
    top: -16px;
    right: 11px;
    transform: rotate(45deg);
  }
`;
export const ShareIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 4px -5px 4px;
`;
export const EllipsisIcon = styled(Icon)`
  margin: 0 4px !important;
`;
