import React, { useContext } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import HomeworkCard from './HomeworkCard';
import CreateHomeworkComponent from './CreateHomeworkComponent';
import { useTranslation } from 'react-i18next';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { Consultation_consultation_child_remainingHomeworks } from '../../../../graphql/queries/types/Consultation';
import { Title, SelectActivitiesContainer } from '../styles';
interface Props {
  homeworks: Consultation_consultation_child_remainingHomeworks[];
  consultationId: string;
}

const HomeworkListComponent = ({ homeworks, consultationId }: Props) => {
  const { newHomework, setNewHomework } = useContext(TherapistConsultationScreenContext);
  const { t } = useTranslation();

  return (
    <SelectActivitiesContainer>
      <div>
        <Title>{t('therapist.consultationScreen.task.overview.homework')}</Title>
        {newHomework ? (
          <CreateHomeworkComponent />
        ) : (
          <div style={{ display: 'flex' }}>
            <Button
              icon
              circular
              size="large"
              style={{ margin: '10px', padding: '10px', backgroundColor: '#47E4C2', color: 'white' }}
              onClick={() => setNewHomework(true)}
              data-cy="addGoalButton"
            >
              <Icon name="plus" bordered={false} />
            </Button>
            <h2 data-cy="addGoalTitle" style={{ marginTop: '15px', fontSize: '15pt' }}>
              {t('therapist.consultationScreen.task.taskAndHw.addHw')}
            </h2>
          </div>
        )}
      </div>
      <div>
        {homeworks.map((homework: Consultation_consultation_child_remainingHomeworks, index: number) => (
          <HomeworkCard key={homework.id} homework={homework} index={index} consultationId={consultationId} />
        ))}
      </div>
    </SelectActivitiesContainer>
  );
};

export default HomeworkListComponent;
