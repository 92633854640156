import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText } from '../../../../../Dropdown/styles';
import {
  Container,
  ActionButton,
  Item,
  DeleteItem,
  DeleteListItemText,
  RemoveIcon,
  EditIcon,
  MasteredIcon,
  MoreOptionsDropdown,
  MoreOptionsDropdownList,
} from './styles';
import GreenMastered from '../../../../../assets/GreenMastered.svg';
import Edit from '../../../../../assets/Edit.svg';
import useLockBodyScroll from '../../../../../../../../util/Hooks/useLockBodyScroll';

export type Props = {
  editable: boolean;
  index: number;
  handleEditWord: (indices: number) => void;
  handleDeleteWord: (index: number) => void;
  handleCompleteClick: (index: number) => void;
};

const MoreOptionsButton = ({ editable, index, handleEditWord, handleDeleteWord, handleCompleteClick }: Props) => {
  const { t } = useTranslation();
  const dropdownRef = useRef() as React.RefObject<HTMLDivElement>;
  const [toggle, setToggle] = useState(false);
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && dropdownRef.current != null && !dropdownRef.current.contains(event.target as Node)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useLockBodyScroll(toggle);

  const onActionClick = () => {
    setToggle(!toggle);
  };

  return (
    <Container ref={dropdownRef}>
      <ActionButton toggle={toggle} onClick={onActionClick}>
        ⋯
      </ActionButton>
      <MoreOptionsDropdown toggle={toggle} onClick={onActionClick}>
        <MoreOptionsDropdownList toggle={toggle}>
          {editable && (
            <Item onClick={() => handleEditWord(index)}>
              <EditIcon src={Edit} />
              <ListItemText>{t('client.wordTracking.table.moreOptions.edit')}</ListItemText>
            </Item>
          )}
          <Item onClick={() => handleCompleteClick(index)}>
            <MasteredIcon src={GreenMastered} />
            <ListItemText>{t('client.wordTracking.table.moreOptions.mastered')}</ListItemText>
          </Item>
          <DeleteItem onClick={() => handleDeleteWord(index)}>
            <RemoveIcon className="trash alternate outline" />
            <DeleteListItemText>{t('client.wordTracking.table.moreOptions.remove')}</DeleteListItemText>
          </DeleteItem>
        </MoreOptionsDropdownList>
      </MoreOptionsDropdown>
    </Container>
  );
};

export default MoreOptionsButton;
