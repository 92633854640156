import gql from 'graphql-tag';

export const SEND_RESET_PASSWORD = gql`
  mutation SendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(email: $email) {
      returnMessage {
        status
        message
      }
    }
  }
`;
