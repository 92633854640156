import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { SmallContentTitle } from '../../../../../../style';

export const CollapsibleSegment = styled.div`
  cursor: pointer;
  background: white;
  border-top: 1px solid #d1d7dc;
`;

export const Header = styled(SmallContentTitle)<{ isOpen: boolean }>`
  max-height: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #f7f9fa;
`;

export const ContentContainer = styled.div<{ isOpen: boolean }>`
  cursor: default;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const Content = styled.div`
  padding: 0;
  margin: 0;
  height: max-content;
  box-sizing: border-box;
  text-align: left;
  white-space: pre-line;
`;

export const Arrow = styled(Icon)`
  align-self: center;
  ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? 'transform: rotate(180deg); transition: transform .2s ease-in-out'
      : 'transform: rotate(360deg); transition: transform .2s ease-in-out'};
`;
