import React, { useState, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Segment, TextArea, Button, Image, Loader } from 'semantic-ui-react';
import { CREATE_MESSAGE } from '../../../../../../graphql/mutations/createMessage';
import { DesktopOrLaptop, TabletOrMobile } from '../../../../../common/MediaQueries';
import { CreateMessage, CreateMessageVariables } from '../../../../../../graphql/mutations/types/CreateMessage';
import { ChildMessages_child_room_directMessages } from '../../../../../../graphql/queries/types/ChildMessages';
import { CHILD_MESSAGES } from '../../../../../../graphql/queries/childMessage';
import MessagesContext from '../MessagesContext';
import { TherapistContext } from '../../../../TherapistContextt';
import { useTranslation } from 'react-i18next';
import defaultChild from '../../../../../../assets/defaultChild.png';
import Linkify from 'react-linkify';
import UploadMessageComponent from '../../../../../Client/Communication/components/UploadMessageComponent';
interface Props {
  message: ChildMessages_child_room_directMessages;
}

const ReplyComponent = ({ message }: Props) => {
  const { roomId, childId } = useContext(MessagesContext);
  const { therapist } = useContext(TherapistContext);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');

  const [createMessage, { loading, error }] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    // update(cache, { data }) {
    //   if (!data) {
    //     return null;
    //   }
    //   const messageType = message.meta.__typename;
    //   const getRoomMessages = cache.readQuery<GetRoomMessagesByType, GetRoomMessagesByTypeVariables>({
    //     query: GET_ROOM_MESSAGES_QUERY,
    //     variables: { roomId, messageType },
    //   });
    //   const existingRoomMessages = getRoomMessages ? getRoomMessages.getRoomMessagesByType : [];
    //   const newReply = data.createMessage.meta as CreateMessage_createMessage_meta_Reply;
    //   const mainMessageIndex = existingRoomMessages.findIndex(
    //     (m: GetRoomMessagesByType_getRoomMessagesByType) => m.id == newReply.mainMessage.id,
    //   );
    //   if (mainMessageIndex == -1) {
    //     return null;
    //   }
    //   existingRoomMessages[mainMessageIndex].replies.push(newReply);
    //   cache.writeQuery<GetRoomMessagesByType, GetRoomMessagesByTypeVariables>({
    //     query: GET_ROOM_MESSAGES_QUERY,
    //     variables: { roomId, messageType },
    //     data: { getRoomMessagesByType: existingRoomMessages },
    //   });
    // },
    refetchQueries: [{ query: CHILD_MESSAGES, variables: { childId } }],
  });

  const { t } = useTranslation();

  const handleClickSend = async () => {
    await createMessage({
      variables: {
        body: replyText,
        userId: therapist.user.id,
        roomId,
        homeworkId: null,
        mainMessageId: message.id,
        file: null,
        fileName: null,
      },
    });

    setIsReply(false);
    setReplyText('');
  };

  if (error) {
    return <h2>Error</h2>;
  }

  const { replies } = message;

  const renderMessageWithFile = (url: string, contentType: string, body: string) => {
    if (contentType.includes('image')) {
      return (
        <div>
          <Image src={url} size="small" />
          <br />
          <p>{body}</p>
        </div>
      );
    } else if (contentType.includes('video')) {
      return (
        <div>
          <video src={url} width={320} height={240} controls /> <br />
          <p>{body}</p>
        </div>
      );
    } else {
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {body}
        </a>
      );
    }
  };

  const renderReplyContent = (message) => {
    if (message.homeworkUrl && message.homeworkContentType) {
      return renderMessageWithFile(message.homeworkUrl, message.homeworkContentType, message.body);
    }

    if (message.messageFileUrl && message.messageFileContentType) {
      return renderMessageWithFile(message.messageFileUrl, message.messageFileContentType, message.body);
    }

    if (message.replyMessageFileUrl && message.replyMessageFileContentType) {
      return renderMessageWithFile(message.replyMessageFileUrl, message.replyMessageFileContentType, message.body);
    }

    return (
      <Linkify>
        <span>{message.body}</span>
      </Linkify>
    );
  };

  return (
    <div>
      <DesktopOrLaptop>
        {replies.length > 0 && (
          <Segment.Group>
            {replies.map((reply) => (
              <Segment key={reply.id} compact>
                <div style={{ display: 'flex' }}>
                  <Image
                    circular
                    style={{ height: '35px', width: '35px', objectFit: 'cover' }}
                    size="mini"
                    bordered
                    src={reply.message.avatarUrl ? reply.message.avatarUrl : defaultChild}
                  />
                  <p style={{ margin: '8px' }}>{reply.message.user.name}</p>
                </div>
                <div style={{ marginLeft: '45px' }}>
                  {renderReplyContent(reply.message)}
                  <p style={{ color: '#B2B2B2', fontSize: '11px' }}>{reply.message.createdAt}</p>
                </div>
              </Segment>
            ))}
          </Segment.Group>
        )}
        {isReply ? (
          <div>
            <UploadMessageComponent roomId={roomId} mainMessageId={message.id} />
            <Button
              size="tiny"
              style={{ color: 'teal', border: 'none', background: 'none' }}
              onClick={() => setIsReply(false)}
              data-cy="cancel"
            >
              {t('client.communicationCard.cancel')}
            </Button>
          </div>
        ) : (
          <Button
            data-cy="reply"
            size="tiny"
            color="teal"
            basic
            style={{ borderRadius: '22.5px' }}
            onClick={() => setIsReply(true)}
          >
            {t('client.communicationCard.reply')}
          </Button>
        )}
      </DesktopOrLaptop>
      <TabletOrMobile>
        {replies.length > 0 && (
          <Segment.Group>
            {replies.map((reply) => (
              <Segment key={reply.id} compact>
                <div style={{ display: 'flex' }}>
                  <Image
                    circular
                    style={{ height: '35px', width: '35px', objectFit: 'cover' }}
                    size="mini"
                    bordered
                    src={reply.message.avatarUrl ? reply.message.avatarUrl : defaultChild}
                  />
                  <p style={{ margin: '8px' }}>{reply.message.user.name}</p>
                </div>
                <div style={{ marginLeft: '45px' }}>
                  {renderReplyContent(reply.message)}
                  <p style={{ color: '#B2B2B2', fontSize: '11px' }}>{reply.message.createdAt}</p>
                </div>
              </Segment>
            ))}
          </Segment.Group>
        )}
        {isReply ? (
          <div>
            <UploadMessageComponent roomId={roomId} mainMessageId={message.id} />
            <Button
              size="tiny"
              style={{ color: 'teal', border: 'none', background: 'none' }}
              onClick={() => setIsReply(false)}
              data-cy="cancel"
            >
              {t('client.communicationCard.cancel')}
            </Button>
          </div>
        ) : (
          <Button
            data-cy="reply"
            size="tiny"
            color="teal"
            basic
            style={{ borderRadius: '22.5px' }}
            onClick={() => setIsReply(true)}
          >
            {t('client.communicationCard.reply')}
          </Button>
        )}
      </TabletOrMobile>
    </div>
  );
};

export default ReplyComponent;
