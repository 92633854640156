import React from 'react';

const DoubleQuotesRightIcon = () => {
  return (
    <svg
      style={{ position: 'absolute', bottom: 0, right: 0 }}
      width="40"
      height="18"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.20002 9.76C10.4 8.68 11.76 6.6 11.8 4.84C11.56 5.04 11.12 5.2 10.68 5.2C9.16002 5.2 8.20002 4.04 8.20002 2.6C8.20002 1.36 9.08002 0 10.88 0C12.64 0 14.04 1.6 14.04 3.8C14.04 7.36 11.6 10.12 9.04002 11.28L8.20002 9.76ZM0.56002 9.76C2.72002 8.68 4.08002 6.6 4.12002 4.84C3.88002 5.04 3.44002 5.2 3.00002 5.2C1.48002 5.2 0.52002 4.04 0.52002 2.6C0.52002 1.36 1.44002 0 3.20002 0C4.96002 0 6.36002 1.6 6.36002 3.8C6.36002 7.36 3.92002 10.12 1.36002 11.28L0.56002 9.76Z"
        fill="#8B8B8B"
      />
    </svg>
  );
};

export default DoubleQuotesRightIcon;
