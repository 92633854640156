/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { Consultation_consultation_child_goals } from '../../../../../graphql/queries/types/Consultation';
import TherapistConsultationScreenGoalsCardComponent from '../GoalsCardComponent';
import { Title } from './styles';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goals: Consultation_consultation_child_goals[];
}

const AchievedGoalModal = ({ open, setOpen, goals }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} centered={false} onClose={() => setOpen(false)} closeIcon>
      <Modal.Content styles={{ backgroundColor: '#7FD6C4' }}>
        <Title>{t('therapist.consultationScreen.progress.achievedGoal.title')}</Title>
        {goals.map((goal, index) => (
          <TherapistConsultationScreenGoalsCardComponent
            key={goal.id}
            subCategory={goal.skill.subcategory}
            category={goal.skill.category}
            goal={goal}
            index={index}
            isEdit={false}
          />
        ))}
      </Modal.Content>
    </Modal>
  );
};

export default AchievedGoalModal;
