import { Divider, Drawer, Tag, Typography } from 'antd';
import React, { useContext } from 'react';
import DefaultDetails from '../../../components/ConsultationAndPlanCard/DefaultDetails';
import { DescriptionItem, DescriptionWrapper } from '../../../layout/styles';
import { TherapistChildContext, ConsultationsAndPlanObj } from '../../../TherapistChildContext';
import { statusTagColor, typeTagColor } from '..';
import { dueTimeTextSelector, todoTableActionButton } from './todoTableUtils';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  openData: ConsultationsAndPlanObj;
  onClose: () => void;
  open: boolean;
}

const TodoDrawer = ({ openData, onClose, open }: Props) => {
  const { Title, Text } = Typography;
  const { publishedPlan } = useContext(TherapistChildContext);
  const history = useHistory();
  const { t } = useTranslation();
  const {
    child: { id: childId },
  } = useContext(TherapistChildContext);

  return (
    <Drawer
      title={
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '4px 0' }}>
          {todoTableActionButton(openData, true, t)}
        </div>
      }
      placement="right"
      width={500}
      onClose={onClose}
      visible={open}
    >
      <Title level={4}>{openData.title}</Title>
      <DescriptionWrapper>
        <DescriptionItem style={{ minWidth: '80px', marginBottom: 0 }}>
          {t('therapist.childInfo.todo.tableAndDrawer.status')}
        </DescriptionItem>
        <Tag color={statusTagColor(openData.status)}>{t(`therapist.childInfo.general.status.${openData.status}`)}</Tag>
      </DescriptionWrapper>
      <DescriptionWrapper>
        <DescriptionItem style={{ minWidth: '80px', marginBottom: 0 }}>
          {t('therapist.childInfo.todo.tableAndDrawer.type')}
        </DescriptionItem>
        <div>
          <Tag color={typeTagColor(openData.type)}>{t(`therapist.childInfo.general.todoType.${openData.type}`)}</Tag>
        </div>
      </DescriptionWrapper>
      <DescriptionWrapper>
        <DescriptionItem style={{ minWidth: '80px', marginBottom: 0 }}>
          {t('therapist.childInfo.todo.tableAndDrawer.dueTime')}
        </DescriptionItem>
        {dueTimeTextSelector(openData, 'long')}
      </DescriptionWrapper>
      <Divider></Divider>
      <Title level={5} style={{ marginBottom: '10px' }}>
        {t('therapist.childInfo.todo.tableAndDrawer.drawer.sectionTitle1')}
      </Title>
      <DefaultDetails consultation={openData} />
      {(openData.isNextConsultation || openData.status === 'consultationOngoing') && (
        <>
          <Divider></Divider>
          <Title level={5} style={{ marginBottom: '10px' }}>
            {t('therapist.childInfo.todo.tableAndDrawer.drawer.sectionTitle2')}
          </Title>
          <div style={{ display: 'grid', gap: '10px' }}>
            <DescriptionWrapper>
              <DescriptionItem style={{ minWidth: '80px', marginBottom: 0 }}>
                {t('therapist.childInfo.todo.tableAndDrawer.drawer.plan')}
              </DescriptionItem>
              <Tag
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/child/${childId}/plan/${publishedPlan[0].id}`)}
              >
                Plan {publishedPlan[0].planSequence}
              </Tag>
            </DescriptionWrapper>
            <div style={{ marginBottom: '7px' }}>
              <Text strong style={{ marginBottom: '7px' }}>
                {t('therapist.childInfo.todo.tableAndDrawer.drawer.internalNotes')}
              </Text>
              <div>{publishedPlan[0].notes?.internalNotes}</div>
            </div>
            <div style={{ marginBottom: '7px' }}>
              <Text strong style={{ marginBottom: '7px' }}>
                {t('therapist.childInfo.todo.tableAndDrawer.drawer.mainComplaint')}
              </Text>
              <div>{publishedPlan[0].notes?.mainComplaint}</div>
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};
export default TodoDrawer;
