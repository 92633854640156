import React, { useState, useEffect, memo } from 'react';
import {
  ArrowButtonLeft,
  ArrowButtonRight,
  ArrowContainerLeft,
  ArrowContainerRight,
  BadgeContainer,
  BadgeScrollListContainer,
} from './style';
import Arrow from './assets/arrow.svg';

interface Props {
  badge: JSX.Element[];
}

const BadgeScrollList = ({ badge }: Props) => {
  const [outerWidth, setOuterWidth] = useState(0);
  const [optionListWidth, setOptionContainerWidth] = useState(0);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);

  useEffect(() => {
    // scroll distance per click
    const outerContainer = document.getElementById('OuterContainer') as HTMLElement;
    const outerContainerWidth = outerContainer != null ? outerContainer.scrollWidth : 0;
    setOuterWidth(outerContainerWidth);
    setScrollDistance(outerContainerWidth / 2);
    // option list total width
    const optionContainer = document.getElementById('OptionContainer') as HTMLElement;
    const optionContainerWidth = optionContainer != null ? optionContainer.scrollWidth : 0;
    setOptionContainerWidth(optionContainerWidth);
  }, [badge]);

  const optionContainer = document.getElementById('OptionContainer') as HTMLElement;
  if (optionContainer) {
    optionContainer.addEventListener('scroll', () => {
      // handle button show/hide
      setCurrentScroll(optionContainer.scrollLeft);
    });
  }

  const handleScrollNext = () => {
    const optionContainer = document.getElementById('OptionContainer') as HTMLElement;
    if (optionContainer.scrollLeft < optionListWidth) {
      optionContainer.scrollLeft += scrollDistance;
    }
  };

  const handleScrollPrev = () => {
    const optionContainer = document.getElementById('OptionContainer') as HTMLElement;
    if (optionListWidth > optionContainer.scrollLeft) {
      optionContainer.scrollLeft -= scrollDistance;
    }
  };

  return (
    <BadgeScrollListContainer id={'OuterContainer'}>
      {currentScroll !== 0 && (
        <ArrowContainerLeft>
          <ArrowButtonLeft src={Arrow} onClick={() => handleScrollPrev()} />
        </ArrowContainerLeft>
      )}
      {currentScroll !== optionListWidth - outerWidth && (
        <ArrowContainerRight>
          <ArrowButtonRight src={Arrow} onClick={() => handleScrollNext()} />
        </ArrowContainerRight>
      )}
      <BadgeContainer id="OptionContainer">{badge}</BadgeContainer>
    </BadgeScrollListContainer>
  );
};

export default memo(BadgeScrollList);
