import { Avatar, Button, Col, Dropdown, Image, Menu, Row, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import {
  ChildInfoHeader,
  HamburgerMenuContainer,
  HeaderContainer,
  HeaderHeight,
  MenuDrawer,
  ProfileMenuContainer,
} from './styles';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import Logo_EN_black from '../../../../assets/Logo_EN_black.png';
import Logo_CN_black from '../../../../assets/Logo_CN_black.png';
import Logo_TW_black from '../../../../assets/Logo_TW_black.png';
import { MenuOutlined } from '@ant-design/icons';
import { TherapistContext } from '../../TherapistContextt';
import ProfileDropdown from '../components/ProfileDropdown';

type MenuMode = 'horizontal' | 'inline';

const ChildHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { therapist } = useContext(TherapistContext);

  const language = i18n.use(LanguageDetector).language;
  let Logo = Logo_CN_black;
  if (language === 'en') {
    Logo = Logo_EN_black;
  } else if (language === 'zh-CN') {
    Logo = Logo_CN_black;
  } else if (language === 'zh-TW') {
    Logo = Logo_TW_black;
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const MenuItem = (mode: MenuMode) => {
    return (
      <Menu
        theme="light"
        mode={mode}
        defaultSelectedKeys={['2']}
        style={{
          width: `${mode === 'horizontal' ? '100%' : 256}`,
          height: `${mode === 'horizontal' ? '100%' : 'inherit'}`,
          borderBottom: 'none',
          borderRight: 'none',
        }}
      >
        <Menu.Item key="1" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <span>{t('therapist.dashboard.topbar.myTasks')}</span>
          <NavLink to={`/tasks`} />
        </Menu.Item>
        <Menu.Item key="2" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <span>{t('therapist.dashboard.topbar.myClients')}</span>
          <NavLink to={`/clients`} />
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <ChildInfoHeader>
      <HamburgerMenuContainer onClick={showDrawer}>
        <Tooltip title={'Menu'} placement="right">
          <Button icon={<MenuOutlined translate={undefined} />} style={{ border: 'none' }}></Button>
        </Tooltip>
      </HamburgerMenuContainer>
      <ProfileMenuContainer>
        <Dropdown overlay={<ProfileDropdown />}>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{therapist.user.name.charAt(0)}</Avatar>
        </Dropdown>
      </ProfileMenuContainer>
      <MenuDrawer title="Menu" width={256} placement="left" closable={true} onClose={onClose} visible={visible}>
        {MenuItem('inline')}
      </MenuDrawer>
      <Row style={{ flexFlow: 'nowrap', height: `${HeaderHeight}px` }}>
        <Col xs={24} sm={24} md={5} lg={5} xl={4} xxl={3}>
          <HeaderContainer>
            <Image
              src={Logo}
              preview={false}
              style={{ height: '47px', width: '150px', cursor: 'pointer' }}
              onClick={() => history.push(`/`)}
            />
          </HeaderContainer>
        </Col>
        <Col xs={0} sm={0} md={19} lg={19} xl={20} xxl={21}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
            {MenuItem('horizontal')}
          </div>
        </Col>
      </Row>
    </ChildInfoHeader>
  );
};

export default React.memo(ChildHeader);
