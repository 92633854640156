import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { OverviewHeader, OverviewTextSection, OverviewText, OverviewBoldText } from '../styles';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';

const Overview = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const tPath = 'utils.overview';
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/profile')} />
      <OverviewHeader>
        <h2>{t(`${tPath}.title`)}</h2>
        <img src={require(`./assets/${t(`${tPath}.imgName`)}.png`)} alt="client overview" width="50%" height="25%" />
      </OverviewHeader>
      <OverviewTextSection>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.expertInteraction`)}: </OverviewBoldText>
          {t(`${tPath}.expertInteractionText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.planGeneration`)}: </OverviewBoldText>
          {t(`${tPath}.planGenerationText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.homework`)}: </OverviewBoldText>
          {t(`${tPath}.homeworkText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.therapistContact`)}: </OverviewBoldText>
          {t(`${tPath}.therapistContactText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.insuranceReimbursement`)}: </OverviewBoldText>
          {t(`${tPath}.insuranceReimbursementText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.concludingSurvey`)}: </OverviewBoldText>
          {t(`${tPath}.concludingSurveyText`)}
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.afterEndService`)}</OverviewBoldText>
        </OverviewText>
        <OverviewText>
          <OverviewBoldText>{t(`${tPath}.closeService`)}</OverviewBoldText>
        </OverviewText>
      </OverviewTextSection>
    </RouteSectionContainer>
  );
};

export default Overview;
