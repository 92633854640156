import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Description, SmallActionTitle } from '../../../../style';
import { MainColor, BackgroundColor } from '../styles';

export const DropDownContainer = styled.div<{
  size?: 'small' | 'medium' | 'large' | string;
}>`
  cursor: pointer;
  width: ${({ size }) => {
    if (size === 'small') {
      return '28%';
    } else if (size === 'medium') {
      return '60%';
    } else if (size === 'large') {
      return '100%';
    } else {
      return '28%';
    }
  }};
`;

export const DropDownHeader = styled.div`
  position: relative;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 5px 10px 5px 15px;
  height: 40px;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  background: white;
  :hover {
    border: solid 1px ${MainColor};
  }
`;

export const HeaderText = styled(Description)`
  color: #030303;
`;

export const Arrow = styled(Icon)<{ isOpen: boolean }>`
  position: absolute;
  right: 10px;
  color: #909090;
  ${({ isOpen }) =>
    isOpen
      ? 'transform: rotate(180deg); transition: transform .2s ease-in-out'
      : 'transform: rotate(360deg); transition: transform .2s ease-in-out'};
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  margin-top: 10px;
`;

export const DropDownList = styled.ul`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border-radius: 8px;
  margin: 0;
  min-width: fit-content;
  min-height: fit-content;
  max-height: 250px;
  background: white;
  overflow-y: scroll;
  box-sizing: border-box;
  list-style-type: none;
  text-align: left;
  padding: 10px 5px 10px 10px;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  list-style-type: none;
  padding: 0 12px;
  cursor: pointer;
  :hover {
    background: ${BackgroundColor};
  }
  // : first-child {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }
  // : last-child {
  //   border-bottom-left-radius: 4px;
  //   border-bottom-right-radius: 4px;
  // }
`;

export const ListItemText = styled(SmallActionTitle)`
  @media (hover: hover) and (pointer: fine) {
    ${ListItem}: hover & {
      color: ${MainColor};
    }
  }
`;

export const Placeholder = styled(Description)``;
