import { createContext } from 'react';
import { Consultation_consultation_child_activityChildren } from '../../../../graphql/queries/types/Consultation';

interface Props {
  isOverview: boolean;
  setIsOverview: React.Dispatch<React.SetStateAction<boolean>>;
  isHomework: boolean;
  setIsHomework: React.Dispatch<React.SetStateAction<boolean>>;
  removedActivityChildren: Consultation_consultation_child_activityChildren[];
  setRemovedActivityChildren: React.Dispatch<React.SetStateAction<Consultation_consultation_child_activityChildren[]>>;
  isAgree: boolean;
  setIsAgree: React.Dispatch<React.SetStateAction<boolean>>;
}

const TasksContext: Props = {
  isOverview: true,
  setIsOverview: () => {},
  isHomework: true,
  setIsHomework: () => {},
  removedActivityChildren: [],
  setRemovedActivityChildren: () => {},
  isAgree: false,
  setIsAgree: () => {},
};

export default createContext(TasksContext);
