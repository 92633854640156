import styled from 'styled-components';

export const DropdownContent = styled.div`
  position: fixed;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  top: 38px;
  padding: 10px 10px 10px 10px;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 8px;
  @media only screen and (max-width: 575px) {
    height: min-content;
    max-height: 85%;
    width: 92%;
    right: 4%;
  }
  @media only screen and (min-width: 576px) {
    height: min-content;
    max-height: 85%;
    width: 92%;
    right: 4%;
  }
  @media only screen and (min-width: 768px) {
    height: min-content;
    max-height: 90%;
    width: 35%;
    right: 25px;
    max-width: 450px;
  }
  @media only screen and (min-width: 992px) {
    height: min-content;
    max-height: 90%;
    width: 35%;
    right: 25px;
    max-width: 450px;
  }
  @media only screen and (min-width: 1200px) {
    height: min-content;
    max-height: 90%;
    width: 35%;
    right: 25px;
    max-width: 450px;
  }
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: -8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 8px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background: #bcc0c4;
    }
  }
`;

export const NotificationTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0 15px 10px;
`;

export const NotificationContainer = styled.div`
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const NotificationText = styled.div`
  display: inline-block;
  font-size: 15px;
  padding-left: 10px;
`;

export const NotificationIcon = styled.div`
  display: inline-block;
  width: 45px;
  vertical-align: top;
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
`;
