import React, { useState } from 'react';
import { ReturnMessage } from '../../types';
import { Viewer_viewer } from '../../graphql/queries/types/Viewer';
import { useTranslation } from 'react-i18next';
import { ChildConsultationNotes_consultations } from '../../graphql/queries/types/ChildConsultationnotes';
import { useQuery } from 'react-apollo';
import { THERAPIST_CONSULTATIONS_QUERY } from '../../graphql/queries/therapist';
import { Therapist_therapist, Therapist, TherapistVariables } from '../../graphql/queries/types/Therapist';
import { Loader } from 'semantic-ui-react';
import ErrorPage from '../common/ErrorPage';

interface State {
  // user: Viewer_viewer;
  therapist: Therapist_therapist;
  returnMessage?: ReturnMessage;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
  consultations: ChildConsultationNotes_consultations[];
  setConsultations: React.Dispatch<React.SetStateAction<ChildConsultationNotes_consultations[]>>;
}

const defaultUser = {
  id: '',
  uuid: '',
  name: '',
  email: '',
  locale: '',
  phoneNumber: '',
  metaId: '',
  metaType: '',
  loggedInBefore: true,
  deviceNotifications: [],
};

const defaultTherapistContext: State = {
  // user: defaultUser,
  therapist: {
    id: '',
    user: { id: '', locale: '', name: '', email: '' },
    consultationUrl: '',
    cnConsultationUrl: '',
    consultations: [],
  },
  returnMessage: undefined,
  setReturnMessage: () => {},
  consultations: [],
  setConsultations: () => {},
};

export const TherapistContext = React.createContext(defaultTherapistContext);

export const TherapistContextProvider = ({ therapistId, children }) => {
  const { t } = useTranslation();
  const [consultations, setConsultations] = useState<ChildConsultationNotes_consultations[]>([]);
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);

  const { loading, error, data } = useQuery<Therapist, TherapistVariables>(THERAPIST_CONSULTATIONS_QUERY, {
    variables: { therapistId },
  });

  // render
  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }
  if (error || !data || !data.therapist) {
    return <ErrorPage />;
  }
  const { therapist } = data;

  return (
    <TherapistContext.Provider
      value={{
        // user,
        therapist,
        returnMessage,
        setReturnMessage,
        consultations,
        setConsultations,
      }}
    >
      {children}
    </TherapistContext.Provider>
  );
};
