import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import i18n from 'i18next';
import { TherapistContext } from '../../TherapistContextt';

export type localeValue = 'zh-CN' | 'zh-TW' | 'en';

export function useLanguageSwitcher(value: localeValue) {
  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );
  const { therapist, setReturnMessage } = useContext(TherapistContext);

  // update message near header
  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
  }, [data]);

  return async () => {
    await editUserInformation({ variables: { userId: therapist.user.id, attribute: 'Locale', value } });
    i18n.changeLanguage(value);
  };
}
