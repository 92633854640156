import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderData } from '../../../../../Client/WordTrackingRoute/WordsTable/styles';
import { ActivityHeaderRowContainer, ActivityStickyHeaderColumnContainer } from '../styles';

interface Props {
  group: boolean;
}
const TableHeader = ({ group }: Props) => {
  const { t } = useTranslation();
  const tPath = 'therapist.childInfo.activity';

  return (
    <ActivityHeaderRowContainer group={group}>
      <ActivityStickyHeaderColumnContainer group={group}>
        <HeaderData center={false} canSort={false}>
          {t('therapist.childInfo.activity.general.activity')}
        </HeaderData>
      </ActivityStickyHeaderColumnContainer>
      {/* <HeaderData center={false} canSort={false}>
        Consultation Check-ins
      </HeaderData> */}
      <HeaderData center={false} canSort={false}>
        {t(`${tPath}.general.activityTable.totalCheckIns`)}
      </HeaderData>
      <HeaderData center={false} canSort={false}>
        {t(`${tPath}.general.activityTable.assignedDate`)}
      </HeaderData>
      <HeaderData center={false} canSort={false}>
        {t(`${tPath}.general.mainSkill`)}
      </HeaderData>
      <HeaderData center={false} canSort={false}>
        {/* {t('therapist.dashboard.myClient.clientInfo.tasks.activitySkill')} */}
        {t(`${tPath}.general.category`)}
      </HeaderData>
      <HeaderData center={false} canSort={false}>
        {t(`${tPath}.general.subcategory`)}
      </HeaderData>
      {/* <HeaderData center={true} canSort={false}>
              {t('therapist.dashboard.myClient.clientInfo.tasks.forChild')}
            </HeaderData>
            <HeaderData center={true} canSort={false}>
              {t('therapist.dashboard.myClient.clientInfo.tasks.forParent')}
            </HeaderData> */}
    </ActivityHeaderRowContainer>
  );
};

export default TableHeader;
