import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalContent, ModalHeader, ModalMask } from '../../../../../../../style';
import SVG from 'react-inlinesvg';

export const FilterWordModalMask = styled(ModalMask)``;

export const FilterWordModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)`
  text-align: center;
`;

export const FilterModalContent = styled(ModalContent)`
  width: 80%;
`;

export const ButtonContainer = styled(ModalButtonContainer)``;

export const FilterContentFlexContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 10px;
  margin: 10px 0 15px 0;
`;

export const FilterImg = styled(SVG)`
  width: inherit;
  margin-top: -121px;
  height: 140px;
`;
