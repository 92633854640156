import React, { useContext } from 'react';
import { Button, Icon, Divider } from 'semantic-ui-react';
import TherapistConsultationScreenContext from './TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';

interface Props {
  onClickBack?: () => void;
  onClickNext?: () => void;
  nextText?: string;
}

const TherapistConsultationScreenNavArrows = ({ onClickBack, onClickNext, nextText }: Props) => {
  const { t } = useTranslation();

  const { pollingCount } = useContext(TherapistConsultationScreenContext);

  return (
    <>
      <div style={{ display: onClickBack ? 'flex' : 'none', float: 'left' }}>
        <Button
          icon
          circular
          style={{ margin: '10px', color: '#5BD6C7', border: '2px solid #5BD6C7' }}
          size="tiny"
          onClick={() => (onClickBack ? onClickBack() : null)}
        >
          <Icon name="angle left" bordered={false} />
        </Button>
        <h3 style={{ marginTop: '18px' }}>{t('therapist.consultationScreen.back')}</h3>
      </div>
      <div style={{ display: onClickNext ? 'flex' : 'none', float: 'right' }}>
        <h3 style={{ marginTop: '18px' }} data-cy="buttonTitle">
          {nextText || t('therapist.consultationScreen.next')}
        </h3>
        <Button
          icon
          disabled={pollingCount !== 0}
          circular
          style={{ margin: '10px', color: '#5BD6C7', border: '2px solid #5BD6C7' }}
          size="tiny"
          onClick={() => (onClickNext ? onClickNext() : null)}
          hidden={onClickNext == undefined}
          data-cy="consultationScreenNextButton"
        >
          <Icon name="angle right" bordered={false} />
        </Button>
      </div>
      <Divider clearing hidden fitted />
    </>
  );
};

export default TherapistConsultationScreenNavArrows;
