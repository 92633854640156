import styled from 'styled-components';
import { MainColor } from '../../../WordTrackingRoute/styles';
import SVG from 'react-inlinesvg';
import { SmallActionTitle } from '../../../../../style';

export const FloatButton = styled.div<{ status: boolean }>`
  right: 20px;
  bottom: 70px;
  height: 44px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #5abaae;
  padding: 0 15px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => (props.status ? MainColor : 'white')};
  color: ${(props) => (props.status ? 'white' : MainColor)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  @media only screen and (min-width: 992px) {
    right: 20px;
    bottom: 20px;
  }
  z-index: 998;
`;

export const TodayButton = styled(SmallActionTitle)`
  display: flex;
  cursor: pointer;
  padding: 0 8px;
  align-items: center;
  height: 28px;
  :hover {
    color: ${MainColor};
  }
`;

export const MasteredIcon = styled(SVG)`
  width: 17px;
  height: 17px;
  margin-right: 6px;
`;

export const TimeSlotContainer = styled.div`
  margin-bottom: 15px;
`;

export const DailyPlanCalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0 25px 0;
`;

// activities check in opacity mask
export const ContentContainer = styled.div<{ status: boolean }>`
  display: flex;
  opacity: ${(props) => (props.status ? '0.4' : '1')};
`;
export const ActivityListContainer = styled.div`
  min-height: 600px;
`;

// date card to be select
export const DateContainer = styled.div<{ status: boolean; disabled: boolean; check: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 15px 0 15px 0;
  font-size: 12px;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  background-color: ${(props) => (props.check ? 'white' : 'white')};
  color: ${(props) => (props.status ? '#5abaae' : 'black')};
  width: 12%;
  height: 105px;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: #5abaae;
  }
`;
export const DateTitle = styled.div`
  font-size: 18px;
`;
export const WeekDayTitle = styled.div<{ status: boolean }>`
  font-size: 10px;
  color: ${(props) => (props.status ? '#5abaae' : '#808080')};
`;

export const SectionActionContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
