import React from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router';
import TasksRoute from '../route/TasksRoute';
import HomeworksRoute from '../route/HomeworksRoute';
import MessagesRoute from '../route/MessagesRoute';
import GoalsRoute from '../route/GoalsRoute';
import DashboardRoute from '../route/DashboardRoute';
import TodoRoute from '../route/TodoRoute';
import PlanRoute from '../route/PlanRoute';
import ConsultationRoute from '../route/ConsultationRoute';
import BreadCrumbComponent from './BreadCrumbComponent';
import { ChildInfoContentContainer } from './styles';
import PlanDetailsRoute from '../route/PlanDetailsRoute';

const ChildContent = () => {
  const match = useRouteMatch();
  if (!match) {
    return <Redirect to="/" />;
  }

  return (
    <ChildInfoContentContainer>
      <Switch>
        <Route exact path={`${match.path}/todo`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <TodoRoute />
        </Route>
        <Route exact path={`${match.path}/consultation`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <ConsultationRoute />
        </Route>
        <Route exact path={`${match.path}/plan`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <PlanRoute />
        </Route>
        <Route exact path={`${match.path}/plan/:consultationId`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <PlanDetailsRoute />
        </Route>
        <Route exact path={`${match.path}/homeworks`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <HomeworksRoute />
        </Route>
        <Route exact path={`${match.path}/messages`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <MessagesRoute />
        </Route>
        <Route exact path={`${match.path}/dashboard`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <DashboardRoute />
        </Route>
        <Route exact path={`${match.path}/goal`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <GoalsRoute />
        </Route>
        <Route exact path={`${match.path}/tasks`}>
          <BreadCrumbComponent></BreadCrumbComponent>
          <TasksRoute />
        </Route>
        <Redirect from={`${match.path}`} to={`${match.path}/todo`} />
      </Switch>
    </ChildInfoContentContainer>
  );
};

export default ChildContent;
