import React, { useContext, useState } from 'react';
import TherapistConsultationScreenGoalsCardComponent from './GoalsCardComponent';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import { useMutation } from 'react-apollo';
import { ADD_GOALS_TO_CONSULTATION_MUTATION } from '../../../../graphql/mutations/addGoalsToConsultation';
import TherapistConsultationScreenCategoriesComponent from './CategoriesComponent';
import {
  AddGoalsToConsultation,
  AddGoalsToConsultationVariables,
} from '../../../../graphql/mutations/types/AddGoalsToConsultation';
import { Consultation_consultation_child_goals } from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import AchievedGoalModal from './AchievedGoal';
import { RoundBorderButton } from './StyledComponents';
import { MdAddCircle } from 'react-icons/md';
import { Row } from 'antd';
import WeakerSkills from './WeakerSkills';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

const TherapistConsultationScreenProgressComponent = () => {
  const {
    consultation: { id, child },
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
    setActiveItem,
    newGoal,
    setNewGoal,
    category,
    setCategory,
    setSubCategory,
    skill,
    setSkill,
  } = useContext(TherapistConsultationScreenContext);

  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);

  const [addGoalsToConsultation] = useMutation<AddGoalsToConsultation, AddGoalsToConsultationVariables>(
    ADD_GOALS_TO_CONSULTATION_MUTATION,
  );
  const [showAchievedGoals, setShowAchievedGoals] = useState<boolean>(false);

  const [achievedGoals, setAchievedGoals] = useState<Consultation_consultation_child_goals[]>(
    child.goals.filter((g) => g.achieved),
  );
  const [onGoingGoals, setOnGoingGoals] = useState<Consultation_consultation_child_goals[]>(
    child.goals
      .filter((g) => !g.achieved)
      .slice()
      .reverse(),
  );
  const setGoalAchieved = (id: string) => {
    const goal = onGoingGoals.find((g) => g.id == id);
    if (!goal) {
      return;
    }
    goal.achieved = true;
    setAchievedGoals([...achievedGoals, goal]);
    setOnGoingGoals(onGoingGoals.filter((g) => g.id !== id));
  };

  const updateGoal = (goal: Consultation_consultation_child_goals) => {
    setOnGoingGoals(
      onGoingGoals.map((g: Consultation_consultation_child_goals) => {
        return g.id == goal.id ? goal : g;
      }),
    );
  };

  const addGoal = (goal: Consultation_consultation_child_goals) => {
    setOnGoingGoals([goal, ...onGoingGoals]);
  };

  const setGoalDeleted = (id: string) => {
    setOnGoingGoals(onGoingGoals.filter((g) => g.id !== id));
  };

  const handleClickBack = () => {
    if (skill) {
      setSkill(null);
      return;
    }
    if (category) {
      setCategory(null);
      setSubCategory(null);
      return;
    }
    setActiveItem('LongerTermGoals');
  };

  const handleClickNext = async () => {
    addGoalsToConsultation({
      variables: {
        goalIds: child.goals.map((goal) => goal.id),
        consultationId: id,
      },
    });

    if (isCPC) {
      setActiveItem('Activities');
    } else {
      setActiveItem('CommentForParent');
    }
  };

  const { t } = useTranslation();

  const ProgressTitleButton = ({
    text,
    icon,
    clickFn,
  }: {
    clickFn: () => void;
    icon: React.ReactElement;
    text: string;
  }) => {
    return (
      <RoundBorderButton
        onClick={() => {
          clickFn();
        }}
        style={{ padding: '18px 15px' }}
      >
        <div style={{ fontSize: '14px', padding: '0 4px 0 0' }}>{text}</div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '22px' }}>{icon}</div>
      </RoundBorderButton>
    );
  };

  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => handleClickBack()}
        onClickNext={() => handleClickNext()}
      />
      <WeakerSkills />

      <div>
        <AchievedGoalModal open={showAchievedGoals} setOpen={setShowAchievedGoals} goals={achievedGoals} />
        {newGoal ? (
          isCPC ? (
            <TherapistConsultationScreenCategoriesComponent addGoal={addGoal} />
          ) : null
        ) : (
          <Row justify={'space-between'}>
            <div>
              <div style={{ fontSize: '18px', fontWeight: 'bolder', marginTop: '20px' }}>
                {t('therapist.consultationScreen.progress.GoalsForThisModule')}
              </div>
              <div>{`(${t('therapist.consultationScreen.progress.3Months')})`}</div>
            </div>
            {isCPC && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ProgressTitleButton
                  clickFn={() => {
                    setNewGoal(true);
                  }}
                  icon={<MdAddCircle />}
                  text={t('therapist.consultationScreen.progress.addGoal')}
                ></ProgressTitleButton>
              </div>
            )}
          </Row>
        )}
        <div style={{ marginBottom: '15px' }}>
          {onGoingGoals.map((goal, index) => (
            <TherapistConsultationScreenGoalsCardComponent
              key={goal.id}
              subCategory={goal.skill.subcategory}
              category={goal.skill.category}
              goal={goal}
              index={index}
              isEdit={false}
              updateGoal={updateGoal}
              achieveGoal={setGoalAchieved}
              setGoalDeleted={setGoalDeleted}
              isSelectingActivity={false}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TherapistConsultationScreenProgressComponent;
