import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { Button, Form, Grid, Loader, Message, Divider } from 'semantic-ui-react';
import mixpanel from 'mixpanel-browser';

import Header from '../Header';
import LoginPopup from './components/LoginPopup';
import { LOGIN_MUTATION, Data, Variables } from '../../../graphql/mutations/login';
import { useHistory } from 'react-router';
import GoogleLoginComponent from './GoogleLogin';
import { DivideContainer, DivideLine, OrText } from './styles';
import { GET_LANGUAGE } from './helper';

const LoginComponent: React.FC = () => {
  const [login, { loading: mutationLoading, data }] = useMutation<Data, Variables>(LOGIN_MUTATION);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [locale, setLocale] = useState(GET_LANGUAGE());
  const history = useHistory();
  const [returnMessage, setReturnMessage] = useState<Data['login']['returnMessage'] | null>(null);
  const loginValidation = (): { isValid: boolean; errors: string[] } => {
    const errors: string[] = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      errors.push(t('auth.login.emailMissing'));
    } else if (!emailRegex.test(email)) {
      errors.push(t('auth.login.invalidEmail'));
    } else if (!password) {
      errors.push(t('auth.login.passwordMissing'));
    }
    return { isValid: errors.length === 0, errors };
  };

  const handleOAuthLogin = async (OAuthResponse: any) => {
    mixpanel.track('Login', {
      action: 'click login button',
    });
    setError(null);
    setIsSubmitting(true);

    try {
      const response = await login({
        variables: { email: OAuthResponse.email, password: OAuthResponse.sub, locale: locale },
      });

      if (response.data) {
        const { user, returnMessage } = response.data.login;

        if (user && returnMessage.status === 'SUCCESS') {
          window.location.href = '/';
        } else if (user && !user.isOauth) {
          // User registered through normal process
          setReturnMessage({ status: 'ERROR', message: t('auth.login.oauthLoginError') });
        } else {
          setReturnMessage(returnMessage);
        }
      } else {
        console.error('No response data');
      }
    } catch (error: any) {
      console.error('OAuth login error:', error.message || error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onLoginSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { isValid, errors } = loginValidation();
    if (!isValid) {
      setError(errors.join(', '));
      setReturnMessage(null);
      return;
    }
    mixpanel.track('Login', { action: 'email login' });
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await login({ variables: { email, password, locale: locale } });
      if (response.data && response.data.login.returnMessage.status === 'ERROR') {
        setReturnMessage(response.data.login.returnMessage);
      } else if (response.data && response.data.login.user) {
        window.location.href = '/';
      }
    } catch (error: any) {
      console.error('Login error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginClick = () => {
    mixpanel.track('Login', {
      action: 'click login button',
    });
  };

  const onForgotPasswordClick = () => {
    mixpanel.track('Login', { action: 'forgot password' });
    history.push('/forgot-password');
  };

  if (mutationLoading || isSubmitting) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  return (
    <>
      <Header />
      <Grid textAlign="center" style={{ height: '90vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 400 }}>
          <Form size="mini" onSubmit={onLoginSubmit}>
            {/* Display error message if there is an error from frontend */}
            {error && (
              <Message negative size="huge">
                {error}
              </Message>
            )}
            {/* Display returnMessage message if there is an error from backend */}
            {returnMessage && <LoginPopup returnMessage={returnMessage} />}
            <Divider hidden />
            <Divider hidden />
            <Form.Input
              data-cy="insertEmail"
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('auth.login.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ fontSize: '1.4rem' }}
            />
            <Form.Input
              data-cy="insertPassword"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={t('auth.login.password')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ fontSize: '1.4rem' }}
            />
            <div style={{ textAlign: 'right' }}>
              <a
                style={{ margin: '10px 0', cursor: 'pointer', fontSize: '1.2rem' }}
                onClick={() => onForgotPasswordClick()}
              >
                {t('auth.login.forgotPassword')}
              </a>
            </div>
            <Button
              color="teal"
              fluid
              onClick={handleLoginClick}
              style={{ margin: '10px 0', fontSize: '16px' }}
              data-cy="submit"
              type="submit"
            >
              {t('auth.login.login')}
            </Button>
            <DivideContainer>
              <DivideLine />
              <OrText>{t('auth.login.or')}</OrText>
              <DivideLine />
            </DivideContainer>
            <GoogleLoginComponent handleOAuthLogin={handleOAuthLogin} />
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LoginComponent;
