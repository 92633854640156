/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GenderEnum {
  female = 'female',
  male = 'male',
}

export enum ReturnMessageStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
