import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIn, ChildProgress_childProgress } from '../../../../graphql/queries/types/ChildProgress';
import { ActionMetricsNumber, ActionMetricsTitle, ContentTitle, EmtpyStateContent } from '../../../../style';
import {
  MetricsFlexBox,
  HighlightTopEdge,
  ChartContainer,
  ScrollContainer,
  InteractiveMetricsBox,
  DashboardEmptyStateContainer,
} from '../styles';
import CheckInChart from './CheckInChart';
import ActivityCountChart from './ActivityCountChart';
import { ActivityMetricsSegment } from './styles';
import { ChildTasks_childProgress } from '../../../../graphql/queries/types/ChildTasks';

interface Props {
  childProgress: ChildProgress_childProgress | ChildTasks_childProgress;
}

const Activity = ({ childProgress }: Props) => {
  const { t } = useTranslation();
  const { weeklyCheckIns } = childProgress;
  const [activeItem, setActiveItem] = useState('totalCheckIn');
  const isTotalCheckIn = activeItem === 'totalCheckIn';
  const isTotalTrainActivity = activeItem === 'totalTrainActivity';
  const emptyState = weeklyCheckIns.length === 0;
  const checkInCount = useMemo(
    () =>
      (weeklyCheckIns as CheckIn[]).reduce((accumulator, weekData) => {
        return accumulator + weekData.checkInCount;
      }, 0),
    [weeklyCheckIns],
  );
  const trainedActivityCount = useMemo(
    () =>
      (weeklyCheckIns as CheckIn[]).reduce((accumulator, weekData) => {
        return accumulator + weekData.newlyTrainedActivityCount;
      }, 0),
    [weeklyCheckIns],
  );

  // metric that can be use in the future

  // const masteredSkillPercentage = () => {
  //   if (childProgress.requiredSkillsCount === 0) {
  //     return 0;
  //   }
  //   return Math.round((100 * childProgress.masteredSkillsCount) / childProgress.requiredSkillsCount);
  // };
  // const practicedActivities = assignedActivities.length;
  // const maxCountActivities = Math.max(...assignedActivities.map((a) => a.checkInCount));
  // const renderActivitiesCheckin = assignedActivities.map((a) => {
  //   return (
  //     <ProgressActivityContentContainer>
  //       <ProgressActivityContentName>{a.name}</ProgressActivityContentName>
  //       <ProgressActivityContentBar>
  //         <ProgressActivityContentBarInner percent={(100 * a.checkInCount) / maxCountActivities} />
  //         <ActivityIndexCount index={a.checkInCount}>{a.checkInCount}</ActivityIndexCount>
  //       </ProgressActivityContentBar>
  //     </ProgressActivityContentContainer>
  //   );
  // });

  // {childProgress.goalImprovementPercent}
  // {childProgress.goalImprovementPercent}>
  // {childProgress.homeworkFinishCount}
  // {practicedActivities}

  return (
    <ActivityMetricsSegment>
      <MetricsFlexBox>
        <HighlightTopEdge className="tour_dashboard_activity_checkIn" isSelected={isTotalCheckIn}>
          <InteractiveMetricsBox
            id="totalCheckIn"
            isSelected={isTotalCheckIn}
            onClick={() => setActiveItem('totalCheckIn')}
          >
            <ActionMetricsTitle>{t('client.dashboard.activity.checkIn')}</ActionMetricsTitle>
            <ActionMetricsNumber isSelected={isTotalCheckIn}>{checkInCount}</ActionMetricsNumber>
          </InteractiveMetricsBox>
        </HighlightTopEdge>
        <HighlightTopEdge className="tour_dashboard_activity_train" isSelected={isTotalTrainActivity}>
          <InteractiveMetricsBox
            id="totalTrainActivity"
            isSelected={isTotalTrainActivity}
            onClick={() => setActiveItem('totalTrainActivity')}
          >
            <ActionMetricsTitle>{t('client.dashboard.activity.trainedActivities')}</ActionMetricsTitle>
            <ActionMetricsNumber isSelected={isTotalTrainActivity}>{trainedActivityCount}</ActionMetricsNumber>
          </InteractiveMetricsBox>
        </HighlightTopEdge>
        {/* <HighlightTopEdge isSelected={false} style={{ cursor: 'default' }}>
            <MetricsBox>
              <ActivityMetricsNoActionTitle>
                {t('client.dashboard.activity.completedDailyPlan')}
              </ActivityMetricsNoActionTitle>
              <ActionMetricsNumber isSelected={false}>1</ActionMetricsNumber>
            </MetricsBox>
          </HighlightTopEdge> */}
      </MetricsFlexBox>
      {emptyState && (
        <DashboardEmptyStateContainer>
          <ContentTitle>{t('client.dashboard.activity.emptyStateTitle')}</ContentTitle>
          <EmtpyStateContent>{t('client.dashboard.activity.emptyStateContent')}</EmtpyStateContent>
        </DashboardEmptyStateContainer>
      )}
      {!emptyState && (
        <ScrollContainer>
          <ChartContainer>
            {activeItem === 'totalCheckIn' && <CheckInChart weeklyCheckIns={weeklyCheckIns as any[]} />}
            {activeItem === 'totalTrainActivity' && <ActivityCountChart weeklyCheckIns={weeklyCheckIns as any[]} />}
          </ChartContainer>
        </ScrollContainer>
      )}
    </ActivityMetricsSegment>
  );
};

export default Activity;
