import gql from 'graphql-tag';

export const USER_WITH_RESET_PASSWORD_TOKEN = gql`
  query UserWithResetPasswordToken($token: String!) {
    userWithResetPasswordToken(token: $token) {
      id
      name
      email
      locale
    }
  }
`;
