import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TherapistAppComponent from './';
import TherapistConsultationScreenComponent from './ConsultationScreen';
import ChildInfoComponent from './ChildInfo';
import Account from '../common/Account';
import i18n from '../../i18n';
import { TherapistContextProvider } from './TherapistContextt';
import UserContext from '../common/UserContext';

const TherapistRoutes = () => {
  const { user } = useContext(UserContext);
  const { metaId: therapistId, locale } = user;

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }

  if (!therapistId) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <TherapistContextProvider therapistId={therapistId}>
        <TherapistRoutesChild />
      </TherapistContextProvider>
    </Switch>
  );
};

const TherapistRoutesChild = () => {
  return (
    <Switch>
      <Route path="/consultation/:id" render={() => <TherapistConsultationScreenComponent />} />
      <Route path="/child/:childId" render={() => <ChildInfoComponent />} />
      <Route path="/account" render={() => <Account />} />
      <Route path="/" render={() => <TherapistAppComponent />} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default TherapistRoutes;
