import { useTranslation } from 'react-i18next';

const existLanguage = ['en', 'zh-CN', 'zh-TW'];

export const GET_LANGUAGE = () => {
  const {
    i18n: { language },
  } = useTranslation();

  if (language == null) {
    return 'en';
  } else if (existLanguage.includes(language)) {
    return language;
  } else if (language.includes('en')) {
    return 'en';
  } else {
    return 'en';
  }
};
