import React from 'react';
import { ReturnMessage } from '../../types';
import { IconContainer, NotificationContent, Notification } from './styles';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';

interface Props {
  returnMessage: ReturnMessage;
}

const ReturnMessageComponent = ({ returnMessage }: Props) => {
  const { Text } = Typography;
  const success = returnMessage.status === 'SUCCESS';
  return (
    <Notification>
      <NotificationContent status={success}>
        <IconContainer>
          {success ? <CheckCircleFilled translate={undefined} /> : <CloseCircleFilled translate={undefined} />}
        </IconContainer>
        <Text type={success ? 'success' : 'danger'}>{returnMessage.message}</Text>
      </NotificationContent>
    </Notification>
  );
};

export default ReturnMessageComponent;
