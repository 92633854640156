import React, { useContext, useState, useEffect, FC } from 'react';
import mixpanel from 'mixpanel-browser';
import { useMutation, useQuery } from 'react-apollo';
import { GetNotifications, GetNotificationsVariables } from '../../../graphql/queries/types/GetNotifications';
import {
  UpdateNotificationRead,
  UpdateNotificationReadVariables,
} from '../../../graphql/mutations/types/UpdateNotificationRead';
import { GET_NOTIFICATIONS_QUERY } from '../../../graphql/queries/getNotifications';
import { UPDATE_NOTIFICATION_READ_MUTATION } from '../../../graphql/mutations/updateNotificationRead';
import UserContext from '../UserContext';
import { ClientContext } from '../../Client/ClientContext';
import NotificationDropdown from '../Notification/NotificationDropdown';
import { useHistory } from 'react-router';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Client_client_children_product } from '../../../graphql/queries/types/Client';
import { BackgroundContainer } from '../Notification/NotificationDropdown/styles';

import Logo_EN from '../../../assets/Logo_EN.png';
import Logo_CN from '../../../assets/Logo_CN.png';
import Logo_TW from '../../../assets/Logo_TW.png';

import { NotificationBellIcon } from './NotificationBellIcon';

import {
  LeftNavBar,
  BarMenuContainer,
  WordCompanyLogo,
  NavbarLinkContainer,
  TopNavBar,
  TopBarMenuContainer,
  BottomNavBar,
  HomeIcon,
  DashboardIcon,
  TasksIcon,
  ConsultationsIcon,
  LibraryIcon,
  ProfileIcon,
  ProgramIcon,
  WordToolsIcon,
  TopNavbarCompanyLogo,
  QnaSessionsIcon,
  CoursesIcon,
  TopProfileIcon,
  TopRightButtonContainer,
} from './styles';
import NavbarLink from './NavbarLink';
import ErrorPage from '../ErrorPage';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../../util/Product';

const navPaths = [
  'consultations',
  'courses',
  'dashboard',
  'home',
  'library',
  'qnaSessions',
  'profile',
  'program',
  'task',
  'video',
  'word-tools',
] as const;
export type NavPathLink = typeof navPaths[number];
export type NavPathLinkClass = `tour--${NavPathLink}--${'side' | 'bottom'}`;

interface Props {
  product: Client_client_children_product;
}

type NavIcon = FC<{ status: boolean }>;

const navIconMap: { [key in NavPathLink]: NavIcon } = {
  consultations: ConsultationsIcon,
  courses: CoursesIcon,
  dashboard: DashboardIcon,
  home: HomeIcon,
  library: LibraryIcon,
  qnaSessions: QnaSessionsIcon,
  profile: ProfileIcon,
  program: ProgramIcon,
  task: TasksIcon,
  video: LibraryIcon,
  'word-tools': WordToolsIcon,
};

const peaLeft: NavPathLink[] = [
  'home',
  'dashboard',
  'courses',
  'task',
  'qnaSessions',
  'consultations',
  'word-tools',
  'profile',
];
const cpcLeft: NavPathLink[] = ['dashboard', 'task', 'consultations', 'library', 'word-tools', 'profile'];
const cpcBottom: NavPathLink[] = ['dashboard', 'library', 'task', 'consultations', 'word-tools'];
const peaBottom: NavPathLink[] = ['home', 'dashboard', 'task', 'courses', 'qnaSessions'];

function useNavPath() {
  const [navPath, setNavPath] = useState<NavPathLink | null>(null);
  const pathname = window.location.pathname;
  useEffect(() => {
    navPaths.forEach((path) => {
      if (pathname.includes(path)) {
        setNavPath(path);
      }
    });
  }, [pathname]);

  return navPath;
}

const Navbar = ({ product }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const userId = user ? user.id : '';

  const navPath = useNavPath();
  const showLeftNavBar = navPath !== 'video';
  const showTopNavBar = navPath === 'video';
  const showBottomNavBar = navPath !== 'video';

  const [unreadNotifications, setUnreadNotifications] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { loading, error, data } = useQuery<GetNotifications, GetNotificationsVariables>(GET_NOTIFICATIONS_QUERY, {
    variables: { userId },
  });
  const [updateNotificationRead] = useMutation<UpdateNotificationRead, UpdateNotificationReadVariables>(
    UPDATE_NOTIFICATION_READ_MUTATION,
  );

  useEffect(() => {
    if (data && data.getNotifications && data.getNotifications.some((notification) => !notification.read)) {
      setUnreadNotifications(true);
    }
  }, [data]);

  const history = useHistory();
  const language = i18n.use(LanguageDetector).language;
  const [DesktopLogo, MobileLogo] = chooseLogos(language);

  const { client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);
  const leftNavBar = isPEA ? peaLeft : cpcLeft;
  const bottomNavBar = isPEA ? peaBottom : cpcBottom;

  function handleNavClick(url: NavPathLink) {
    mixpanel.track('NavBar', {
      option: url,
    });

    if (url === 'task') {
      history.push('/task/activity');
      window.scroll(0, 0);
    } else {
      history.push(`/${url}`);
    }
  }

  function renderNavBarItems(navBarList: NavPathLink[], location: 'side' | 'bottom') {
    return navBarList.map((navPathLink) => {
      const selected = navPath === navPathLink;
      const Icon = navIconMap[navPathLink];
      const className = `tour--${navPathLink}--` + location;

      return (
        <NavbarLink
          key={navPathLink}
          className={className}
          selected={selected}
          handleClick={() => handleNavClick(navPathLink)}
          icon={<Icon status={selected} />}
          text={t(`navBar.${hypenToCamel(navPathLink)}`)}
        />
      );
    });
  }

  const handleClickLogo = (productType: string) => {
    mixpanel.track('Logo', {
      option: 'defaultHome',
    });
    if (isCPC) {
      history.push('/dashboard');
    } else {
      history.push('/home');
    }
  };

  const handleReadNotification = () => {
    setIsActive(!isActive);
    if (data?.getNotifications && unreadNotifications) {
      updateNotificationRead({
        variables: {
          notificationId: data.getNotifications.map((notification) => notification.id),
        },
      });
    }
    setUnreadNotifications(false);
  };

  const hasUser = user && user.loggedInBefore && user.id;

  if (loading) {
    return <></>;
  }
  if (error || !data || !data.getNotifications) {
    return <ErrorPage />;
  }
  const { getNotifications } = data;
  return (
    <div>
      <LeftNavBar show={showLeftNavBar}>
        <BarMenuContainer>
          <WordCompanyLogo src={DesktopLogo} onClick={() => handleClickLogo(productType)} />
          {hasUser && (
            <>
              <NavbarLinkContainer>{renderNavBarItems(leftNavBar, 'side')}</NavbarLinkContainer>
            </>
          )}
        </BarMenuContainer>
      </LeftNavBar>
      <TopNavBar status={showTopNavBar}>
        <TopBarMenuContainer>
          <div>
            <TopNavbarCompanyLogo
              status={showTopNavBar}
              src={MobileLogo}
              onClick={() => handleClickLogo(productType)}
            />
          </div>
          {hasUser && (
            <TopRightButtonContainer>
              <NotificationBellIcon
                unreadNotifications={unreadNotifications}
                showTopNavBar={showTopNavBar}
                handleReadNotification={handleReadNotification}
              />
              <TopProfileIcon
                show={showTopNavBar}
                status={navPath === 'profile'}
                onClick={() => handleNavClick('profile')}
              />
            </TopRightButtonContainer>
          )}
        </TopBarMenuContainer>
      </TopNavBar>
      {isActive && (
        <div>
          <BackgroundContainer onClick={() => setIsActive(!isActive)} />
          <NotificationDropdown notifications={getNotifications} />
        </div>
      )}
      <BottomNavBar show={showBottomNavBar}>
        {hasUser && (
          <>
            <NavbarLinkContainer>{renderNavBarItems(bottomNavBar, 'bottom')}</NavbarLinkContainer>
          </>
        )}
      </BottomNavBar>
    </div>
  );
};

function chooseLogos(language: string) {
  switch (language) {
    default:
    case 'en':
      return [Logo_EN, Logo_EN];
    case 'zh-CN':
      return [Logo_CN, Logo_CN];
    case 'zh-TW':
      return [Logo_TW, Logo_TW];
  }
}

function hypenToCamel(s: string) {
  return s.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

export default Navbar;
