import gql from 'graphql-tag';

export const REQUEST_BUY_MORE_MUTATION = gql`
  mutation RequestBuyMore($childId: ID!, $productId: ID) {
    requestBuyMore(childId: $childId, productId: $productId) {
      returnMessage {
        status
        message
      }
    }
  }
`;
