import React, { useContext, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useMutation } from 'react-apollo';
import { CREATE_GOAL_MUTATION } from '../../../../graphql/mutations/createGoal';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  Consultation_consultation_child_goals,
  Consultation_consultation_child_goals_skill,
  Consultation_consultation_child_goals_preskills,
} from '../../../../graphql/queries/types/Consultation';
import { CreateGoal, CreateGoalVariables } from '../../../../graphql/mutations/types/CreateGoal';
import { useTranslation } from 'react-i18next';
import { CreateGoal_createGoal_returnMessage } from '../../../../graphql/mutations/types/CreateGoal';
import { ReturnMessageStatus } from '../../../../types/graphql-global-types';
import ErrorPage from '../../../common/ErrorPage';
import { FAST_POLL } from '../constants';

interface Props {
  inputs: {
    description: string;
    initNumerator: number;
    initDenominator: number;
    successNumerator: number;
    successDenominator: number;
    metric: string;
    skill: Consultation_consultation_child_goals_skill;
    preskills: Consultation_consultation_child_goals_preskills[];
  };
  addGoal?: (goal: Consultation_consultation_child_goals) => void;
}

const CreateGoalButton = ({
  inputs: {
    description,
    initNumerator,
    initDenominator,
    successNumerator,
    successDenominator,
    metric,
    skill,
    preskills,
  },
  addGoal,
}: Props) => {
  const { consultation, setNewGoal, setCategory, setSubCategory, setSkill, setReturnMessage, setPollingCount } =
    useContext(TherapistConsultationScreenContext);

  const [createGoal, { loading, error, data }] = useMutation<CreateGoal, CreateGoalVariables>(CREATE_GOAL_MUTATION, {
    refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
    onCompleted: () => {
      setTimeout(() => {
        setPollingCount((prevCount) => prevCount - 1);
      }, FAST_POLL);
    },
  });

  const { t } = useTranslation();
  // update message near header
  useEffect(() => {
    if (data && data.createGoal) {
      setReturnMessage(data.createGoal.returnMessage);
      if (addGoal && data.createGoal.goal) {
        addGoal(data.createGoal.goal);
      }
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const validateInput = () => {
    const messageStatus: ReturnMessageStatus = ReturnMessageStatus.ERROR;

    const returnMessage: CreateGoal_createGoal_returnMessage = {
      status: messageStatus,
      message: '',
    };
    if (
      description === '' ||
      successNumerator === 0 ||
      metric === '' ||
      successNumerator <= initNumerator ||
      initNumerator > initDenominator ||
      successNumerator > successDenominator ||
      preskills.length == 0
    ) {
      if (description === '') {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.descriptionRequired');
      }
      if (successNumerator === 0) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.successMetricNotZero');
      }
      if (metric === '') {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.metricsRequired');
      }
      if (successNumerator <= initNumerator) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.successMetricLessThanInit');
      }
      if (initNumerator > initDenominator || successNumerator > successDenominator) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.numeratorOverDenominator');
      }
      if (preskills.length == 0) {
        returnMessage.message = t('therapist.consultationScreen.progress.goalCard.errors.preskillEmpty');
      }
      setReturnMessage(returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 6000);
      return false;
    }
    return true;
  };

  const handleClick = async () => {
    if (validateInput()) {
      setPollingCount((prevCount) => prevCount + 1);
      await createGoal({
        variables: {
          description,
          initNumerator,
          initDenominator,
          successNumerator,
          successDenominator,
          metric,
          skillId: skill.id,
          preskillIds: preskills.map((preskill) => preskill.id),
          childId: consultation.child.id,
          consultationId: consultation.id,
        },
      });
      setNewGoal(false);
      setCategory(null);
      setSubCategory(null);
      setSkill(null);
    }
  };

  if (loading) {
    return <p>{t('system.loading')}</p>;
  }
  if (error) {
    return <ErrorPage />;
  }

  return (
    <Button
      icon
      attached="bottom"
      style={{ backgroundColor: '#5DD9C4', color: 'white', margin: '0' }}
      onClick={() => handleClick()}
      data-cy="createGoalConfirmButton"
    >
      {t('therapist.consultationScreen.progress.goalCard.buttons.confirm')}
      <Icon name="save" />
    </Button>
  );
};

export default CreateGoalButton;
