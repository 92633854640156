import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddButton, AddButtonProps } from './AddButton';
import { ActionContainer, TrashButton, MasteredButton, ToolBoxContainer, TrashIcon, MasteredIcon } from './styles';
import Filter, { FilterProps } from './Filter';
import { Container } from './styles';
import SearchBar, { SearchBarProps } from './SearchBar';
import { ActionTitle } from '../../../../style';
import GreenMastered from '../assets/GreenMastered.svg';

type Props = {
  hasSelectedItems: boolean;
  deleteWords: () => void;
  completeWords: () => void;
} & AddButtonProps &
  FilterProps &
  SearchBarProps;

export const ToolBox = (props: Props) => {
  const { t } = useTranslation();
  return props.hasSelectedItems ? (
    <ActionContainer>
      <TrashButton status={false} onClick={props.deleteWords}>
        <TrashIcon className="trash alternate outline" />
        <ActionTitle>{t('client.wordTracking.toolBox.action.remove')}</ActionTitle>
      </TrashButton>
      <MasteredButton status={false} onClick={props.completeWords}>
        <MasteredIcon src={GreenMastered} />
        <ActionTitle>{t('client.wordTracking.toolBox.action.mastered')}</ActionTitle>
      </MasteredButton>
    </ActionContainer>
  ) : (
    <ToolBoxContainer>
      <AddButton {...props} />
      <Container>
        <SearchBar {...props} />
        <Filter {...props} />
      </Container>
    </ToolBoxContainer>
  );
};

export default ToolBox;
