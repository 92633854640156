import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompleteRowContainer,
  HeaderData,
  DataContainer,
  CompletedHeaderRowContainer,
  CompletedStickyHeaderColumnContainer,
  CompleteStickyColumnContainer,
  CompletedEmptyStateContainer,
} from '../styles';
import { Word } from '../../../../../graphql/queries/types/WordTracking';
import Pagination from '../../Pagination';
import WordText from '../TrackingWords/TableRow/RowFunction/Word';
import Usage from '../TrackingWords/TableRow/RowFunction/Usage';
import { ROW_PER_PAGE } from '../../../../../constants/wordTracking';
import { WordContainer, WordTrackingSegment } from '../styles';
import {
  ActionTitle,
  SmallActionTitle,
  EmptyStateButton,
  EmptyStateImg,
  EmtpyStateContent,
  EmtpyStateTitle,
} from '../../../../../style';
import wordTrackingEmptyState from '../../assets/wordTrackingEmptyState.svg';

type Props = {
  words: Word[];
  trackingWordsEmpty: boolean;
  handleChangeTabState: (selection: number) => void;
};

export const CompletedWords = ({ words, trackingWordsEmpty, handleChangeTabState }: Props) => {
  const { t } = useTranslation();
  const [pageOffset, setPageOffset] = useState(1);
  const pageCount = useMemo(() => Math.ceil(words.length / ROW_PER_PAGE), [words]);
  const headerTranslation = t('client.wordTracking.table.header', { returnObjects: true });
  const displayWords = useMemo(() => {
    const start = (pageOffset - 1) * ROW_PER_PAGE;
    const target = pageOffset * ROW_PER_PAGE;
    const end = target < words.length ? target : words.length;
    return words.slice(start, end);
  }, [words, pageOffset]) as Word[];
  const emptyState = displayWords.length == 0;
  if (trackingWordsEmpty && emptyState) {
    return (
      <CompletedEmptyStateContainer>
        <EmtpyStateTitle>{t('client.wordTracking.trackWordEmptyState.title')}</EmtpyStateTitle>
        <EmtpyStateContent>{t('client.wordTracking.trackWordEmptyState.content')}</EmtpyStateContent>
        <EmptyStateButton onClick={() => handleChangeTabState(0)}>
          <ActionTitle>{t('client.wordTracking.completedWordEmptyState.backButton')}</ActionTitle>
        </EmptyStateButton>
      </CompletedEmptyStateContainer>
    );
  }
  if (emptyState) {
    return (
      <CompletedEmptyStateContainer>
        <EmptyStateImg src={wordTrackingEmptyState} />
        <EmtpyStateTitle>{t('client.wordTracking.completedWordEmptyState.title')}</EmtpyStateTitle>
        <EmtpyStateContent>{t('client.wordTracking.completedWordEmptyState.content')}</EmtpyStateContent>
        <EmptyStateButton onClick={() => handleChangeTabState(0)}>
          <ActionTitle>{t('client.wordTracking.completedWordEmptyState.button')}</ActionTitle>
        </EmptyStateButton>
      </CompletedEmptyStateContainer>
    );
  }
  return (
    <WordTrackingSegment>
      <WordContainer>
        <CompletedHeaderRowContainer>
          <CompletedStickyHeaderColumnContainer>
            <HeaderData center={false} canSort={false}>
              <SmallActionTitle>{headerTranslation['word']}</SmallActionTitle>
            </HeaderData>
          </CompletedStickyHeaderColumnContainer>
          <HeaderData center={true} canSort={false}>
            <SmallActionTitle>{headerTranslation['timesImitated']}</SmallActionTitle>
          </HeaderData>
          <HeaderData center={true} canSort={false}>
            <SmallActionTitle>{headerTranslation['timesSpontaneouslyUsed']}</SmallActionTitle>
          </HeaderData>
          <HeaderData center={true} canSort={false}>
            <SmallActionTitle>{headerTranslation['totalUsage']}</SmallActionTitle>
          </HeaderData>
        </CompletedHeaderRowContainer>
        {displayWords.map((word: Word) => {
          return (
            <CompleteRowContainer isChecked={false}>
              <CompleteStickyColumnContainer isChecked={false}>
                <DataContainer center={false}>
                  <WordText word={word.word} editable={word.editable} />
                </DataContainer>
              </CompleteStickyColumnContainer>
              <DataContainer center={true}>
                <div>{word.repeatCount}</div>
              </DataContainer>
              <DataContainer center={true}>
                <div>{word.speakCount}</div>
              </DataContainer>
              <DataContainer center={true}>
                <Usage totalCount={word.totalCount}></Usage>
              </DataContainer>
            </CompleteRowContainer>
          );
        })}
      </WordContainer>
      {pageCount > 1 && <Pagination pageCount={pageCount} selectedItem={pageOffset} handlePageChange={setPageOffset} />}
    </WordTrackingSegment>
  );
};
