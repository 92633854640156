import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CPC_PRODUCT_TYPES } from '../../../../../util/Product';

interface Props {
  productType: string;
}

const BeforeConsultation = ({ productType }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tPath = 'client.profile.howToUse';
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  return (
    <div style={{ padding: '0px 10px' }}>
      <div
        onClick={() => history.push('/guidelines')}
        style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'none' }}
      >
        {t(`${tPath}.guidelinesLinkText`)}
      </div>
      <p
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          borderLeft: '5px solid #47E4C2',
          paddingLeft: '10px',
          margin: '5px 0px',
        }}
      >
        {t('client.profile.beforeConsultation.consultationReminder')}
      </p>
      <ul>
        <li>{t('client.profile.beforeConsultation.point1')}</li>
        {isCPC && <li>{t('client.profile.beforeConsultation.point2')}</li>}
      </ul>
    </div>
  );
};

export default BeforeConsultation;
