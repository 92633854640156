/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeforeFirstConsultation from './components/BeforeFirstConsultation';
import BeforeConsultation from './components/BeforeConsultation';
import HowToUseGuide from './components/HowToUseGuide';
import { NotificationMenu, NotificationMenuItem, NotificationContainer } from './styles';

interface Props {
  onBoardDocumentFinished: boolean;
  onBoardDocumentUrl: string;
  locale: string;
  productType: string;
}

const ClientDashboardNotificationCard = ({
  onBoardDocumentFinished,
  onBoardDocumentUrl,
  locale,
  productType,
}: Props) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<string>(
    onBoardDocumentFinished ? 'Before Consultation' : 'Before First Consultation',
  );
  const renderActiveItem = () => {
    switch (activeItem) {
      case 'Before First Consultation':
        return (
          <BeforeFirstConsultation onBoardDocumentUrl={onBoardDocumentUrl} locale={locale} productType={productType} />
        );
      case 'Before Consultation':
        return <BeforeConsultation productType={productType} />;
      case 'During Consultation':
        return <p style={{ padding: '0px 10px' }}>{t('client.profile.duringConsultation')}</p>;
      case 'How To Use':
        return <HowToUseGuide />;
      default:
        return <div>Choose a tab</div>;
    }
  };

  return (
    <div>
      <NotificationMenu>
        <NotificationMenuItem
          active={activeItem === 'Before First Consultation'}
          onClick={() => setActiveItem('Before First Consultation')}
        >
          {t('client.profile.tabs.beforeFirstConsultation')}
        </NotificationMenuItem>
        <NotificationMenuItem
          active={activeItem === 'Before Consultation'}
          onClick={() => setActiveItem('Before Consultation')}
        >
          {t('client.profile.tabs.beforeConsultation')}
        </NotificationMenuItem>
        <NotificationMenuItem
          active={activeItem === 'During Consultation'}
          onClick={() => setActiveItem('During Consultation')}
        >
          {t('client.profile.tabs.duringConsultation')}
        </NotificationMenuItem>
        <NotificationMenuItem active={activeItem === 'How To Use'} onClick={() => setActiveItem('How To Use')}>
          {t('client.profile.tabs.howToUse')}
        </NotificationMenuItem>
        {/* <NotificationMenuItem active={activeItem === 'FAQ'} onClick={() => setActiveItem('FAQ')} disabled>
              {t('client.profile.tabs.faq')}
            </NotificationMenuItem> */}
      </NotificationMenu>
      <NotificationContainer>{renderActiveItem()}</NotificationContainer>
    </div>
  );
};

export default ClientDashboardNotificationCard;
