import { Col, Row, Segmented } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChildTasks_child_assignedActivities } from '../../../../../../graphql/queries/types/ChildTasks';
import { ActivityModalContainer, ScrollContainer } from './styles';
import ParentView from './ParentView';
import CollapseContent from './CollapseContent';
import TherapistView from './TherapistView';
import { useHistory } from 'react-router-dom';

interface Props {
  show: boolean;
  setShow: (boolean: boolean) => void;
  activityData: ChildTasks_child_assignedActivities;
}
const ActivityModal = ({ show, setShow, activityData }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | number>(0);
  const history = useHistory();

  const onClose = () => {
    setShow(false);
    history.push(window.location.pathname);
  };

  return (
    <ActivityModalContainer
      width={1200}
      centered
      title={[
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px', alignItems: 'center' }}>
          {activityData.name}
          <Segmented
            value={value}
            onChange={setValue}
            options={[
              { label: t('therapist.childInfo.activity.activityModal.therapistView'), value: 0 },
              { label: t('therapist.childInfo.activity.activityModal.parentView'), value: 1 },
            ]}
            translate={undefined}
          />
        </div>,
      ]}
      visible={show}
      footer={null}
      onOk={onClose}
      onCancel={onClose}
    >
      <Row style={{ height: '100%' }}>
        <Col flex="1 1 600px" style={{ height: '100%' }}>
          <ScrollContainer style={{ paddingRight: '24px', paddingLeft: '8px', paddingBottom: '50px' }}>
            {value === 0 && <TherapistView activity={activityData} />}
            {value === 1 && <ParentView activity={activityData} />}
          </ScrollContainer>
        </Col>
        <Col flex="1 1" style={{ height: '100%' }}>
          <ScrollContainer>
            <CollapseContent activity={activityData} />
          </ScrollContainer>
        </Col>
      </Row>
    </ActivityModalContainer>
  );
};

export default ActivityModal;
