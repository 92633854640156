import React, { useRef, useEffect, useState } from 'react';
import {
  DropDownContainer,
  DropDownHeader,
  HeaderText,
  DropDownListContainer,
  DropDownList,
  DropdownArrow,
  Placeholder,
  DropdownArrowContainer,
} from './styles';
import Item, { optionType } from './Item';
import { SmallDescription } from '../../../../../../../style';

type Props = {
  size?: 'small' | 'medium' | 'large';
  type?: 'multiSelect' | 'option';
  selectedText: string;
  icon: JSX.Element;
  options: Array<optionType>;
  placeHolder?: string;
  handleOptionChange: (value: number) => void;
  selected: boolean;
  listHeader: string;
  selectedItem: number[];
};

const TableDropdown = ({
  icon,
  type,
  placeHolder,
  selectedText,
  options,
  handleOptionChange,
  selected,
  listHeader,
  selectedItem,
}: Props) => {
  const toggling = (): void => setToggle(!toggle);

  const onOptionClicked = (option: optionType) => {
    const { value } = option;
    setToggle(false);
    handleOptionChange(value);
  };

  const dropdownRef = useRef() as React.RefObject<HTMLDivElement>;
  const [toggle, setToggle] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && dropdownRef.current != null && !dropdownRef.current.contains(event.target as Node)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropDownContainer id={type} ref={dropdownRef}>
      <DropDownHeader selected={selected} onClick={toggling}>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          {icon}
          {selectedText === '' ? (
            <Placeholder selected={selected}>{placeHolder}</Placeholder>
          ) : (
            <HeaderText selected={selected}>{selectedText}</HeaderText>
          )}
        </div>
        <DropdownArrowContainer>
          <DropdownArrow className="caret down" />
        </DropdownArrowContainer>
      </DropDownHeader>
      {toggle && (
        <DropDownListContainer>
          <DropDownList>
            <div style={{ paddingBottom: '10px' }}>
              <SmallDescription>{listHeader}</SmallDescription>
            </div>
            {options.map((option: optionType, index: number) => (
              <Item
                selected={selectedItem.includes(option.value)}
                option={option}
                index={index}
                onOptionClicked={onOptionClicked}
              />
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default TableDropdown;
