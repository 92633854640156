import styled from 'styled-components';
import { ModalButtonContainer, ModalContainer, ModalContent, ModalHeader, ModalMask } from '../../../../../../../style';

export const HurrayModalMask = styled(ModalMask)``;

export const HurrayModalContainer = styled(ModalContainer)``;

export const Header = styled(ModalHeader)``;

export const ButtonContainer = styled(ModalButtonContainer)``;

export const Content = styled(ModalContent)``;

export const HurrayImg = styled.img`
  width: inherit;
  margin-top: -150px;
`;
