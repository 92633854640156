import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { SmallActionTitle } from '../../../style';
import { MainColor } from '../../Client/WordTrackingRoute/styles';

// general section title container
export const TitleFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 0 5px 0;
`;

// general long arrow view more button
export const ViewMoreButton = styled(SmallActionTitle)`
  display: none;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
  :hover {
    color: ${MainColor};
  }
  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;
export const BackButton = styled(SmallActionTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
  :hover {
    color: ${MainColor};
  }
`;
export const ArrowIcon = styled(Icon)`
  color: #808080;
  align-self: baseline;
  margin-left: 2px !important;
  @media (hover: hover) and (pointer: fine) {
    ${ViewMoreButton}:hover & {
      color: ${MainColor};
    }
    ${BackButton}:hover & {
      color: ${MainColor};
    }
  }
`;
export const MobileArrowIcon = styled(Icon)`
  color: #808080;
  align-self: baseline;
  margin-left: 2px !important;
  @media (hover: hover) and (pointer: fine) {
    ${ViewMoreButton}:hover & {
      color: ${MainColor};
    }
  }
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`;
