import React, { useState, useEffect } from 'react';
import { UserInfo, UserInfoText, ButtonGroup, CancelButton, SaveButton, UserInfoEditButton } from '../styles';
import { ReturnMessage } from '../../../../types';
import { useMutation } from 'react-apollo';
import {
  EditUserInformation,
  EditUserInformationVariables,
} from '../../../../graphql/mutations/types/EditUserInformation';
import { EDIT_USER_INFORMATION } from '../../../../graphql/mutations/editUserInformation';
import { VIEWER_QUERY } from '../../../../graphql/queries/viewer';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

interface Props {
  userId: string;
  value: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
}

const LocaleDropdown = ({ userId, value, setReturnMessage }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);

  const [editUserInformation, { data }] = useMutation<EditUserInformation, EditUserInformationVariables>(
    EDIT_USER_INFORMATION,
    { refetchQueries: [{ query: VIEWER_QUERY, variables: { eval: false } }] },
  );

  // update message near header
  useEffect(() => {
    if (data && data.editUserInformation) {
      setReturnMessage(data.editUserInformation.returnMessage);
    }
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 3000);
  }, [data]);

  function handleChange(value) {
    setNewValue(value);
  }

  const handleClickCancel = () => {
    setNewValue(value);
    setIsEdit(false);
  };

  const handleClickSave = async () => {
    await editUserInformation({ variables: { userId, attribute: 'Locale', value: newValue } });
    setIsEdit(false);
    i18n.changeLanguage(newValue);
  };

  const localeToDisplay = (locale: String) => {
    switch (locale) {
      case 'zh-CN':
        return '简体中文';
      case 'zh-TW':
        return '繁體中文';
      case 'en':
        return 'English';
      default:
        return 'English';
    }
  };

  const { t, i18n } = useTranslation();
  const { Option } = Select;

  return (
    <UserInfo>
      <div>
        <UserInfoText>{t('auth.locale')}</UserInfoText>
        {isEdit ? (
          <Select
            // style={{ width: '100%' }}
            placeholder="Please select language"
            defaultValue={value}
            onChange={handleChange}
          >
            <Option value="en">{localeToDisplay('en')}</Option>
            <Option value="zh-CN">{localeToDisplay('zh-CN')}</Option>
            <Option value="zh-TW">{localeToDisplay('zh-TW')}</Option>
          </Select>
        ) : (
          <p>{localeToDisplay(value)}</p>
        )}
      </div>
      {isEdit ? (
        <ButtonGroup>
          <CancelButton onClick={() => handleClickCancel()}>{t('auth.buttons.cancel')}</CancelButton>
          <SaveButton onClick={() => handleClickSave()}>{t('auth.buttons.save')}</SaveButton>
        </ButtonGroup>
      ) : (
        <UserInfoEditButton onClick={() => setIsEdit(true)}>{t('auth.buttons.edit')}</UserInfoEditButton>
      )}
    </UserInfo>
  );
};

export default LocaleDropdown;
