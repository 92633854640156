import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { LAST_NOTES_QUERY } from '../../../../graphql/queries/lastNote';
import { getLastNote, getLastNoteVariables } from '../../../../graphql/queries/types/getLastNote';
import SectionActionTitle from '../../../common/SectionActionTitle';
import { ClientContext } from '../../ClientContext';
import ClientConsultationTherapistNotes from './component/ClientConsultationTherapistNotes';
import Category from '../../DashboardRoute/Category';
import ErrorPage from '../../../common/ErrorPage';

const PlanTab = (props) => {
  const { t } = useTranslation();
  const { client, planCategories } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const history = useHistory();

  const productType = child.product.productTypesName;
  const { loading, error, data } = useQuery<getLastNote, getLastNoteVariables>(LAST_NOTES_QUERY, {
    variables: { childId: child.id },
  });

  const goToActivity = useMemo(
    () => () => {
      history.push('/task/activity');
    },
    [],
  );

  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }
  if (error || !data) {
    return <ErrorPage />;
  }
  return (
    <>
      <ClientConsultationTherapistNotes notesData={data.getLastNote} />
      <SectionActionTitle
        title={t('client.task.planTab.skillTitle')}
        actionText={t('client.task.planTab.goToPractice')}
        shorten={false}
        handleClick={goToActivity}
      />
      <Category productType={productType} child={child} category={planCategories} />
    </>
  );
};

export default PlanTab;
