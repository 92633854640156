import React from 'react';
import { Form, DropdownProps } from 'semantic-ui-react';
import { Consultation_consultation_child_goals } from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import { GoalCardGrid } from '../styles';

interface Props {
  goal: Consultation_consultation_child_goals;
  numerator: number;
  setNumerator: React.Dispatch<React.SetStateAction<number>>;
  denominator: number;
  setDenominator: React.Dispatch<React.SetStateAction<number>>;
  metric: string;
  setMetric: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  disabled: boolean;
  edit: boolean;
}

const TherapistConsultationScreenMetrics = ({
  goal,
  metric,
  setMetric,
  numerator,
  setNumerator,
  denominator,
  setDenominator,
  title,
  disabled,
  edit,
}: Props) => {
  const { t } = useTranslation();

  const metricOptions = [
    { key: 'times', text: t('therapist.consultationScreen.progress.goalCard.metrics.times'), value: 'times' },
    { key: 'words', text: t('therapist.consultationScreen.progress.goalCard.metrics.words'), value: 'words' },
    { key: 'types', text: t('therapist.consultationScreen.progress.goalCard.metrics.types'), value: 'types' },
    { key: 'count', text: t('therapist.consultationScreen.progress.goalCard.metrics.count'), value: 'count' },
  ];

  const numberOptions: Array<{ key: number; text: number; value: number }> = [];
  for (let i = 0; i < 11; i++) {
    numberOptions.push({ key: i, text: i, value: i });
  }

  return (
    <GoalCardGrid>
      <p>{title}</p>

      <Form size="tiny" style={{ margin: '0px', padding: '0px' }}>
        {edit ? (
          <Form.Group>
            <Form.Select
              options={numberOptions}
              fluid
              value={numerator}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setNumerator(data.value as number);
              }}
              disabled={disabled}
              data-cy="goalNumeratorDropdown"
            />
            <h1 style={{ margin: '0px' }}>/</h1>
            <Form.Select
              options={numberOptions}
              fluid
              value={denominator}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setDenominator(data.value as number);
              }}
              disabled={disabled}
              data-cy="goalDenominatorDropdown"
            />
            <Form.Select
              options={metricOptions}
              placeholder="Success Rate"
              value={metric}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setMetric(data.value as string);
              }}
              disabled={disabled}
              data-cy="metricOptionsDropdown"
            />
          </Form.Group>
        ) : (
          <Form.Group>
            <Form.Select
              options={numberOptions}
              fluid
              value={numerator}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setNumerator(data.value as number);
              }}
              disabled={false}
              data-cy="goalNumeratorDropdown"
            />
            <h1 style={{ margin: '0px' }}>/</h1>
            <Form.Select
              options={numberOptions}
              fluid
              value={denominator}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setDenominator(data.value as number);
              }}
              disabled={true}
              data-cy="goalDenominatorDropdown"
            />
            <Form.Select
              options={metricOptions}
              placeholder="Success Rate"
              value={metric}
              onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                event.preventDefault();
                setMetric(data.value as string);
              }}
              disabled={true}
              data-cy="metricOptionsDropdown"
            />
          </Form.Group>
        )}
      </Form>
    </GoalCardGrid>
  );
};

export default TherapistConsultationScreenMetrics;
