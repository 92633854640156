import styled from 'styled-components';
import Courses from './assets/courses.svg';
import QnaSessions from './assets/qnaSessions.svg';
import Tasks from './assets/tasks.svg';
import Consultations from './assets/consultations.svg';
import Program from './assets/program.svg';
import WordTracker from './assets/wordTracker.svg';
import { GeneralSegment, SectionTitle, Description } from '../../../style';

// main container
export const HomeContainer = styled.div`
  padding: 0 8px;
`;
export const SegmentContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin: 30px 0 20px 0;
`;

// segment container
export const SectionContainer = styled(GeneralSegment)`
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-width: 350px;
  min-height: 250px;
  background-size: 40%;
  background-position: 8% 85%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: #5abaae;
  }
`;
export const CoursesSegment = styled(SectionContainer)`
  background-image: url(${Courses});
`;
export const QnaSessionsSegment = styled(SectionContainer)`
  background-image: url(${QnaSessions});
`;
export const TasksSegment = styled(SectionContainer)`
  background-image: url(${Tasks});
`;
export const ConsultationsSegment = styled(SectionContainer)`
  background-image: url(${Consultations});
`;
export const ProgramSegment = styled(SectionContainer)`
  background-image: url(${Program});
`;
export const WordTrackerSegment = styled(SectionContainer)`
  background-image: url(${WordTracker});
`;

// segment title
export const SegmentTitle = styled(SectionTitle)`
  margin-bottom: 5px;
`;

// segment description
export const SectionDes = styled(Description)``;

// right bottom button in every segment
export const GoToButton = styled.span`
  position: relative;
  display: flex;
  cursor: pointer;
  :: before {
    position: absolute;
    right: 20px;
    bottom: 20px;
    content: attr(data-duration);
    color: #5abaae;
  }
`;
