import gql from 'graphql-tag';

export const COURSE_QUERY = gql`
  query Child($childId: ID!) {
    child(childId: $childId) {
      id
      courseLinks {
        id
        read
      }
      qnaSessions {
        id
        read
      }
    }
  }
`;
