import gql from 'graphql-tag';

export const THERAPIST_CHILDREN_QUERY = gql`
  query TherapistChildren($therapistId: ID!) {
    therapistChildren(therapistId: $therapistId) {
      id
      name
      gender
      dob
      pictureUrl
      startServiceAt
      consultations {
        id
        consultationTime
        notes {
          id
          mainComplaint
        }
      }
      product {
        id
        name
        maxConsultation
        productTypesName
      }
      client {
        id
        user {
          id
          name
        }
        consultationTimesRemain
        membershipDue
      }
      previousConsultation {
        id
        consultationTime
        notes {
          id
          mainComplaint
        }
      }
      nextConsultation {
        id
        consultationTime
      }
    }
  }
`;
