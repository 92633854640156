import gql from 'graphql-tag';
import { ChildSixMonthGoal, ReturnMessage } from '../../types';

export const CREATE_SIX_MONTH_GOAL_MUTATION = gql`
  mutation CreateSixMonthGoal($description: String!, $childId: ID!) {
    createSixMonthGoal(description: $description, childId: $childId) {
      goal {
        id
        description
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  createGoal: { goal: ChildSixMonthGoal; returnMessage: ReturnMessage };
}

export interface Variables {
  description: string;
  childId: number;
}
