import React from 'react';
import { useTranslation } from 'react-i18next';
import { SmallContentTitle, UserComment } from '../../../../../../style';
import { OverviewContainer, DescriptionSection } from '../../../styles';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  lecturer: string | undefined;
  description: string | undefined;
}

const Overview = ({ lecturer, description }: Props) => {
  const { t } = useTranslation();
  const desString = description ? ReactHtmlParser(description) : <>{t('client.course.noCourseDes')}</>;

  return (
    <OverviewContainer>
      <DescriptionSection>
        <SmallContentTitle>{t('client.course.courseTeacherTitle')}</SmallContentTitle>
        <UserComment>{lecturer}</UserComment>
      </DescriptionSection>
      <DescriptionSection>
        <SmallContentTitle>{t('client.course.courseDesTitle')}</SmallContentTitle>
        <UserComment>{desString}</UserComment>
      </DescriptionSection>
    </OverviewContainer>
  );
};

export default Overview;
