import React from 'react';
import { ListItem, ListItemText } from '../styles';

export type optionType = {
  key: number;
  text: string;
  value: number;
};

type DropDownListProps = {
  option: optionType;
  index: number;
  onOptionClicked: (option: optionType) => void;
  selected: boolean;
};

const Item = ({ option, index, onOptionClicked, selected }: DropDownListProps) => {
  const { text, value } = option;
  return (
    <ListItem selected={selected} onClick={() => onOptionClicked(option)} key={index} value={value}>
      <ListItemText selected={selected}>{text}</ListItemText>
    </ListItem>
  );
};

export default Item;
