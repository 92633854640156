import React, { useContext, useState } from 'react';
import { Badge, Col, ConfigProvider, Empty, Progress, Row, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import type { TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { CategoryBadge } from '../TasksRoute/styles';
import { findCatOrderIndexByCatId } from '../../../../../util/Category';
import { PlanGoalObj, TherapistChildContext } from '../../TherapistChildContext';
import { CategoryOption, goalPercentage } from '../../utils';

interface Props {
  planGoal: PlanGoalObj[];
  onSelect: (activityId: string) => void;
}

export const changedGoalPercentage = (goalRecord: PlanGoalObj): number => {
  const goalNumerator = goalRecord.changedInNumerator;
  const goalDenominator = goalRecord.goal.successNumerator - goalRecord.goal.initNumerator;
  const goalProgress = goalNumerator / goalDenominator;
  const goalProgressNumber = isNaN(goalProgress) ? 0 : goalProgress;
  const goalPercentage = Math.round(goalProgressNumber * 100);
  return goalPercentage;
};

export const developmentProgressTooltipData = (goalRecord: PlanGoalObj, t) => {
  return (
    <Row gutter={16}>
      <Col>
        <div>{t('therapist.childInfo.goal.general.developmentProgress')}:</div>
        <div>{t('therapist.dashboard.myClient.clientInfo.notes.progress')}:</div>
        <div style={{ margin: '10px 0', height: '1px', backgroundColor: 'white' }}></div>
        <div>{t('therapist.childInfo.goal.general.initProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.objectiveProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.highestProgress')}:</div>
        <div>{t('therapist.childInfo.goal.general.metric')}:</div>
      </Col>
      <Col>
        <div>
          {goalPercentage(goalRecord.currentNumerator, goalRecord.goal.initNumerator, goalRecord.goal.successNumerator)}
          %
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {goalRecord.goal.currentNumerator}
          {goalRecord.changedInNumerator > 0 && (
            <div style={{ margin: '-1px 0 0 5px' }}>
              <Badge count={'↑' + goalRecord.changedInNumerator} style={{ backgroundColor: '#52c41a' }}></Badge>
            </div>
          )}
          {goalRecord.changedInNumerator < 0 && (
            <div style={{ margin: '-1px 0 0 5px' }}>
              <Badge count={'↓' + -goalRecord.changedInNumerator}></Badge>
            </div>
          )}
        </div>
        <div style={{ margin: '10px 0 10px -16px', height: '1px', backgroundColor: 'white' }}></div>
        <div>{goalRecord.goal.initNumerator}</div>
        <div>{goalRecord.goal.successNumerator}</div>
        <div>{goalRecord.goal.initDenominator}</div>
        <div>{t(`therapist.consultationScreen.goalTable.metrics.${goalRecord.goal.metric}`)}</div>
      </Col>
    </Row>
  );
};

const PlanGoalTable = ({ planGoal, onSelect }: Props) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { goals } = useContext(TherapistChildContext);

  const [sortedInfo, setSortedInfo] = useState<SorterResult<PlanGoalObj>>({});
  const handleChange: TableProps<PlanGoalObj>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<PlanGoalObj>);
  };

  const goalSequence = (goalRecordGoalId: string) => {
    const sequence = goals.filter((goal) => goal.id === goalRecordGoalId)[0]?.goalSequence;
    if (sequence) {
      return sequence;
    } else {
      return '';
    }
  };

  // table columns
  const goalName: ColumnType<PlanGoalObj> = {
    title: t('therapist.dashboard.myClient.clientInfo.notes.goal'),
    render: (goalRecord: PlanGoalObj) => (
      <>
        <Tooltip
          placement="topLeft"
          title={`${t('therapist.childInfo.general.todoType.goal')} ${goalSequence(goalRecord.goal.id)}`}
        >
          {`${t('therapist.childInfo.general.todoType.goal')} ${goalSequence(goalRecord.goal.id)}`}
        </Tooltip>
      </>
    ),
    fixed: 'left',
  };

  const description: ColumnType<PlanGoalObj> = {
    title: t('therapist.childInfo.goal.goalTable.description'),
    render: (goalRecord: PlanGoalObj) => (
      <>
        {goalRecord.new && (
          <div style={{ marginBottom: '5px' }}>
            <Badge count={'New'}></Badge>
          </div>
        )}
        <Tooltip placement="topLeft" title={goalRecord.goal.description}>
          {goalRecord.goal.description}
        </Tooltip>
      </>
    ),
    width: 360,
  };

  const developmentProgress: ColumnType<PlanGoalObj> = {
    title: t('therapist.childInfo.goal.general.developmentProgress'),
    key: 'developmentProgress',
    sorter: (a, b) =>
      goalPercentage(a.currentNumerator, a.goal.initNumerator, a.goal.successNumerator) -
      goalPercentage(b.currentNumerator, b.goal.initNumerator, b.goal.successNumerator),
    sortOrder: sortedInfo.columnKey === 'developmentProgress' ? sortedInfo.order : null,
    ellipsis: {
      showTitle: false,
    },
    render: (goalRecord: PlanGoalObj) => (
      <>
        <Tooltip
          placement="topLeft"
          title={developmentProgressTooltipData(goalRecord, t)}
          style={{ minWidth: 'fit-content' }}
        >
          <div style={{ display: 'flex' }}>
            <Progress
              percent={goalPercentage(
                goalRecord.currentNumerator,
                goalRecord.goal.initNumerator,
                goalRecord.goal.successNumerator,
              )}
              success={{ percent: changedGoalPercentage(goalRecord) }}
              size="small"
              status="active"
              format={() =>
                `${goalPercentage(
                  goalRecord.currentNumerator,
                  goalRecord.goal.initNumerator,
                  goalRecord.goal.successNumerator,
                )} %`
              }
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text type="secondary">{`${goalRecord.goal.initNumerator} / ${goalRecord.currentNumerator} / ${
              goalRecord.goal.successNumerator
            }  ${t(`therapist.consultationScreen.goalTable.metrics.${goalRecord.goal.metric}`)}
            `}</Text>
            {goalRecord.changedInNumerator > 0 && (
              <div style={{ margin: '0 0 2px 5px' }}>
                <Badge count={'↑' + goalRecord.changedInNumerator} style={{ backgroundColor: '#52c41a' }}></Badge>
              </div>
            )}
            {goalRecord.changedInNumerator < 0 && (
              <div style={{ margin: '0 0 2px 5px' }}>
                <Badge count={'↓' + -goalRecord.changedInNumerator}></Badge>
              </div>
            )}
          </div>
        </Tooltip>
      </>
    ),
    width: 200,
  };

  const mainSkill: ColumnType<PlanGoalObj> = {
    title: t('therapist.dashboard.myClient.clientInfo.notes.skill'),
    key: 'mainSkill',
    ellipsis: {
      showTitle: false,
    },
    render: (goalRecord: PlanGoalObj) => (
      <Tooltip placement="topLeft" title={goalRecord.goal.skill.name}>
        {goalRecord.goal.skill.name}
      </Tooltip>
    ),
    width: 240,
  };

  const category: ColumnType<PlanGoalObj> = {
    title: t('therapist.childInfo.goal.goalTable.category'),
    key: 'category',
    filters: CategoryOption(),
    onFilter: (value, goalRecord) => findCatOrderIndexByCatId(goalRecord.goal.skill.category.id) === value,
    sorter: (a, b) =>
      findCatOrderIndexByCatId(a.goal.skill.category.id) - findCatOrderIndexByCatId(b.goal.skill.category.id),
    sortOrder: sortedInfo.columnKey === 'category' ? sortedInfo.order : null,
    render: (goalRecord: PlanGoalObj) => (
      <CategoryBadge catIndex={findCatOrderIndexByCatId(goalRecord.goal.skill.category.id)}>
        {goalRecord.goal.skill.category.name}
      </CategoryBadge>
    ),
    width: 240,
  };

  // table columns setting
  const Columns: ColumnsType<PlanGoalObj> = [goalName, description, developmentProgress, mainSkill, category];

  // table empty state
  const customizeRenderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Activity</span>}></Empty>
  );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        className={'planDetailGoalTable'}
        dataSource={planGoal}
        columns={Columns}
        onChange={handleChange}
        pagination={false}
        onRow={(goal) => {
          return {
            onClick: () => {
              onSelect(goal.id);
            },
          };
        }}
        scroll={{ x: 1120 }}
      />
    </ConfigProvider>
  );
};

export default PlanGoalTable;
