import React, { useState } from 'react';
import UploadMessageComponent from '../../Client/Communication/components/UploadMessageComponent';
import { Divider, Radio } from 'semantic-ui-react';
import ShowMessageButtonComponent from '../../Client/Communication/components/ShowMessageButtonComponent';
// import { Therapist_therapist_assistantRooms_receiver } from '../../../graphql/queries/types/Therapist';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import {
  TherapistAssistantRooms as Data,
  TherapistAssistantRoomsVariables as Variables,
} from '../../../graphql/queries/types/TherapistAssistantRooms';
import { THERAPIST_ASSISTANT_ROOMS_QUERY } from '../../../graphql/queries/therapistAssistantRooms';
import ErrorPage from '../../common/ErrorPage';

interface Props {
  therapistId: string;
}

const AssistantInteractionComponent = ({ therapistId }: Props) => {
  const [messageShow, setMessageShow] = useState<boolean>(false);
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<Data, Variables>(THERAPIST_ASSISTANT_ROOMS_QUERY, {
    variables: { therapistId },
  });

  if (loading) {
    return <p>{t('system.loading')}</p>;
  }
  if (error || !data || !data.therapistAssistantRooms) {
    return <ErrorPage />;
  }

  const { therapistAssistantRooms: rooms } = data;
  const roomId = rooms[0].id;
  const assistant = rooms[0].receiver;

  return (
    <div>
      <div style={{ margin: '0px 10px 10px 20px', display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontSize: '12px', verticalAlign: 'middle' }}>
          {`${t('client.communicationCard.therapistInteraction.message')} ${assistant.name}`}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px' }}>
          <p data-cy="viewYourMessages" style={{ fontSize: '10px', margin: '0px 10px' }}>
            {t('client.communicationCard.viewYourMessages')}
          </p>
          <Radio toggle onChange={() => setMessageShow(!messageShow)} />
        </div>
      </div>
      <Divider fitted />
      {messageShow ? (
        <ShowMessageButtonComponent roomId={roomId} messageType="DirectMessage" />
      ) : (
        <UploadMessageComponent roomId={roomId} />
      )}
    </div>
  );
};

export default AssistantInteractionComponent;
