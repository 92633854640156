import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography } from 'antd';
import { TherapistChildContext } from '../../TherapistChildContext';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import SectionTitleWithCount from '../../components/SectionTitleWithCount';
import { CaretRightOutlined } from '@ant-design/icons';
import { CollapseContainer } from './styles';
import TodoDrawer from './component/TodoDrawer';
import TodoTable from './component/TodoTable';
import { VideoCameraOutlined, SolutionOutlined } from '@ant-design/icons';

export const statusTagColor = (todoStatus) => {
  if (todoStatus === 'planOverdue') {
    return 'red';
  } else if (todoStatus === 'consultationOngoing') {
    return 'blue';
  } else {
    return 'gold';
  }
};

export const typeTagColor = (todoType) => {
  if (todoType === 'consultation') {
    return 'geekblue';
  } else {
    return 'volcano';
  }
};

export const typeTagIcon = (todoType) => {
  if (todoType === 'consultation') {
    return <VideoCameraOutlined translate={undefined} />;
  } else {
    return <SolutionOutlined translate={undefined} />;
  }
};

const TodoRoute = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { Panel } = Collapse;
  const { todayTodo, duePlan, consultationTodo, nextConsultation, nextPlan, planTodo } =
    useContext(TherapistChildContext);

  const [open, setOpen] = useState<boolean>(false);
  const [openTodoId, setOpenTodoId] = useState<string>('');
  const [openTodoType, setOpenTodoType] = useState<string>('');

  const filterConsultations = (consultations) => {
    return consultations.filter((consultation) => consultation.notes?.mainComplaint !== 'Auto Generated');
  };

  const filteredTodayTodo = filterConsultations(todayTodo);
  const filteredDuePlan = filterConsultations(duePlan);
  const filteredConsultationTodo = filterConsultations(consultationTodo);
  const filteredNextConsultation = filterConsultations(nextConsultation);
  const filteredNextPlan = filterConsultations(nextPlan);
  const filteredPlanTodo = filterConsultations(planTodo);

  const todayTaskCount = filteredTodayTodo.length;
  const dueTaskCount = filteredDuePlan.length;
  const nextTaskCount = filteredNextConsultation.length + filteredNextPlan.length;

  let taskCountArray = [todayTaskCount, dueTaskCount, nextTaskCount];
  let key = [0, 1, 2, 3];
  const defaultActiveKeyArray = taskCountArray.map((taskCount, index) => (taskCount > 0 ? key[index] : -1));

  const onSelect = (todoId: string, todoType: string) => {
    setOpenTodoId(todoId);
    setOpenTodoType(todoType);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openData =
    openTodoType === 'plan'
      ? filteredPlanTodo.find((plan) => plan.id === openTodoId)
      : filteredConsultationTodo.find((consultation) => consultation.id === openTodoId);

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.childInfo.todo.pageTitle')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        {openData !== undefined && <TodoDrawer openData={openData} onClose={onClose} open={open}></TodoDrawer>}
        <CollapseContainer
          bordered={false}
          defaultActiveKey={defaultActiveKeyArray}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} translate={undefined} />}
        >
          <Panel
            header={
              <SectionTitleWithCount
                title={t('therapist.childInfo.todo.todaySectionTitle')}
                titleLevel={5}
                count={todayTaskCount}
                status={'red'}
              ></SectionTitleWithCount>
            }
            key={key[0]}
          >
            <TodoTable onSelect={onSelect} todoList={filteredTodayTodo} tableType={'today'} />
          </Panel>
          <Panel
            header={
              <SectionTitleWithCount
                title={t('therapist.childInfo.todo.overdueSectionTitle')}
                titleLevel={5}
                count={dueTaskCount}
                status={'red'}
              ></SectionTitleWithCount>
            }
            key={key[2]}
          >
            <TodoTable onSelect={onSelect} todoList={filteredDuePlan} tableType={'overdue'} />
          </Panel>
          <Panel
            header={
              <SectionTitleWithCount
                title={t('therapist.childInfo.todo.nextSectionTitle')}
                titleLevel={5}
                count={nextTaskCount}
                status={'red'}
              ></SectionTitleWithCount>
            }
            key={key[3]}
          >
            <TodoTable
              onSelect={onSelect}
              todoList={[...filteredNextPlan, ...filteredNextConsultation]}
              tableType={'next'}
            />
          </Panel>
        </CollapseContainer>
      </RouteContentContainer>
    </>
  );
};

export default React.memo(TodoRoute);
