import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../Dropdown';
import { DesktopContainer, FilterButton, FilterContainer, FilterIcon, FilterInput, MobileContainer } from './styles';
import { FilterTypes, FilterSigns } from '../../../../../constants/wordTracking';

export type FilterProps = {
  filterType: string;
  signFilter: string;
  countFilter: number;
  setFilterType: (type: string) => void;
  setSignFilter: (sign: string) => void;
  setCountFilter: (value: number) => void;
  handleOpenFilterWordModal: () => void;
};

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  const signOption = FilterSigns.map((value: string, index: number) => ({
    key: index,
    value,
    text: t('client.wordTracking.toolBox.filter.sign.' + value),
  }));

  const typeOption = FilterTypes.map((value: string, index: number) => ({
    key: index,
    value,
    text: t('client.wordTracking.toolBox.filter.type.' + value),
  }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => props.setCountFilter(+e.currentTarget.value);

  return (
    <FilterContainer>
      <DesktopContainer>
        <Dropdown
          size="large"
          placeHolder="Type"
          selectedText={t('client.wordTracking.toolBox.filter.type.' + props.filterType)}
          options={typeOption}
          handleOptionChange={props.setFilterType}
        />
        <Dropdown
          size="large"
          selectedText={props.signFilter}
          options={signOption}
          handleOptionChange={props.setSignFilter}
        />
        <FilterInput type="number" value={`${props.countFilter}`} onChange={handleInputChange} />
      </DesktopContainer>
      <MobileContainer>
        <FilterButton status={false} onClick={props.handleOpenFilterWordModal}>
          <FilterIcon className="filter" />
        </FilterButton>
      </MobileContainer>
    </FilterContainer>
  );
};

export default Filter;
