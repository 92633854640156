import React, { useContext, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { CREATE_HOMEWORK_MUTATION, Data, Variables } from '../../../../graphql/mutations/createHomework';
import { useMutation } from 'react-apollo';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { CreateHomework, CreateHomeworkVariables } from '../../../../graphql/mutations/types/CreateHomework';
import { useTranslation } from 'react-i18next';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { ReturnMessageStatus } from '../../../../types/graphql-global-types';
import { CreateHomework_createHomework_returnMessage } from '../../../../graphql/mutations/types/CreateHomework';
import ErrorPage from '../../../common/ErrorPage';

interface Props {
  description: string;
  submissionDate: string;
  format: string[];
}

const CreateHomeworkButton = ({ description, format, submissionDate }: Props) => {
  const { consultation, setNewHomework, setReturnMessage } = useContext(TherapistConsultationScreenContext);

  const [createHomework, { loading, error, data }] = useMutation<CreateHomework, CreateHomeworkVariables>(
    CREATE_HOMEWORK_MUTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
    },
  );

  // update message near header
  useEffect(() => {
    if (data && data.createHomework) {
      setReturnMessage(data.createHomework.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const { t } = useTranslation();

  const today = Date.now();
  const inputDate = new Date(submissionDate).valueOf();
  var difference = Math.round(inputDate / (1000 * 3600 * 24)) - Math.round(today / (1000 * 3600 * 24));

  const validateInput = () => {
    const messageStatus: ReturnMessageStatus = ReturnMessageStatus.ERROR;

    const returnMessage: CreateHomework_createHomework_returnMessage = {
      status: messageStatus,
      message: '',
    };
    if (description !== '' && submissionDate !== '' && difference > 0 && format.length > 0) return true;

    if (description === '') {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.descriptionRequired');
    } else if (submissionDate === '') {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.submissionDateRequired');
    } else if (difference < 0) {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.biggerSubmissionDateRequired');
    } else if (format.length === 0) {
      returnMessage.message = t('therapist.consultationScreen.task.taskAndHw.errors.formatRequired');
    }
    setReturnMessage(returnMessage);
    setTimeout(() => {
      setReturnMessage(undefined);
    }, 6000);
    return false;
  };

  const handleClick = async () => {
    if (validateInput()) {
      await createHomework({
        variables: {
          description,
          submissionDate: new Date(submissionDate).toISOString(),
          format,
          childId: consultation.child.id,
        },
      });
      setNewHomework(false);
    }
  };

  if (loading) {
    return <h1>{t('system.loading')}</h1>;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Button
      attached="top"
      style={{ backgroundColor: '#5DD9C4', color: 'white', margin: '0' }}
      onClick={() => handleClick()}
      data-cy="createHwButton"
    >
      {t('therapist.consultationScreen.task.taskAndHw.confirm')}
    </Button>
  );
};

export default CreateHomeworkButton;
