import React, { useContext } from 'react';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import PreskillsComponent from './PreskillsComponent';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import TasksContext from './TasksContext';

const SelectPreskillsComponent = () => {
  const {
    consultation: { child },
    setActiveItem,
    selectedPreskills,
  } = useContext(TherapistConsultationScreenContext);
  const { setIsOverview, setIsHomework } = useContext(TasksContext);
  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => (!!child.previousConsultation ? setIsOverview(true) : setActiveItem('Progress'))}
        onClickNext={selectedPreskills.length > 0 ? () => setIsHomework(true) : undefined}
      />
      <PreskillsComponent childId={child.id} />
    </div>
  );
};

export default SelectPreskillsComponent;
