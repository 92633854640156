import gql from 'graphql-tag';

export const THERAPIST_ASSISTANT_ROOMS_QUERY = gql`
  query TherapistAssistantRooms($therapistId: ID!) {
    therapistAssistantRooms(therapistId: $therapistId) {
      id
      receiver {
        id
        name
      }
    }
  }
`;
