import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getAge } from '../../../../helpers/functions';
import { Col, Divider, Drawer, Row, Typography } from 'antd';
import { DescriptionItem, DescriptionWrapper } from '../layout/styles';
import { TherapistChildContext } from '../TherapistChildContext';
import api_address from '../../../../constants/config';

interface Props {
  show: boolean;
  setShow: (boolean: boolean) => void;
}
const BasicInfoDrawer = ({ show, setShow }: Props) => {
  const {
    child: { name, dob, gender, product, client },
  } = useContext(TherapistChildContext);
  const [year, month] = getAge(new Date(dob));
  const { Title } = Typography;

  const onClose = () => {
    setShow(false);
  };

  const { t } = useTranslation();
  const tPath = 'therapist.dashboard.myClient';
  return (
    <Drawer width={640} placement="right" closable={false} onClose={onClose} visible={show}>
      <Title level={5} style={{ marginBottom: '24px' }}>
        {t(`${tPath}.userProfile.title`)}
      </Title>
      <Title level={5} style={{ marginBottom: '16px' }}>
        {t(`${tPath}.userProfile.childInfo`)}
        <p style={{
          display: 'inline',
          margin: '0px 16px',
          color: '#5abaae',
          border: 'solid #5abaae 2px',
          padding: '0px 8px'
        }}>
          ID: {client.user.id}
        </p>
      </Title>
      <Row>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.name`)}:</DescriptionItem>
            {name}
          </DescriptionWrapper>
        </Col>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.gender`)}:</DescriptionItem>
            {t(`therapist.genders.${gender}`)}
          </DescriptionWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.birthday`)}:</DescriptionItem>
            {dob}
          </DescriptionWrapper>
        </Col>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.age`)}:</DescriptionItem>
            {year} {t(`${tPath}.card.yearsOld`) + ' '}
            {month} {t(`${tPath}.card.monthOld`)}
          </DescriptionWrapper>
        </Col>
      </Row>
      <Divider />
      <Title level={5} style={{ marginBottom: '16px' }}>
        {t(`${tPath}.card.caregiver`)}
      </Title>
      <Row>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.name`)}:</DescriptionItem>
            {client.user.name}
          </DescriptionWrapper>
        </Col>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t('therapist.dashboard.myClient.card.language')}:</DescriptionItem>
            {t(`therapist.dashboard.myClient.card.languageOptions.${client.user.locale}`)}
          </DescriptionWrapper>
        </Col>
      </Row>
      {/* <Row>
          <Col span={12}>
            <DescriptionWrapper>
              <DescriptionItem label="City">HangZhou</DescriptionItem>
            </DescriptionWrapper>
          </Col>
          <Col span={12}>
            <DescriptionWrapper>
              <DescriptionItem label="Country">China🇨🇳</DescriptionItem>
            </DescriptionWrapper>
          </Col>
        </Row> */}
      <Divider />
      <Title level={5} style={{ marginBottom: '16px' }}>
        {t(`${tPath}.userProfile.accountStatus`)}
      </Title>
      <Row>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.membershipDue`)}:</DescriptionItem>
            {client.membershipDue}
          </DescriptionWrapper>
        </Col>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.remainingConsultations`)}:</DescriptionItem>
            {client.consultationTimesRemain}
          </DescriptionWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.currentProgram`)}:</DescriptionItem>
            {product.description}
          </DescriptionWrapper>
        </Col>
      </Row>
      <Divider />
      <Title level={5} style={{ marginBottom: '16px' }}>
        {t(`${tPath}.userProfile.coreEvaluationResult`)}
      </Title>
      {/* <Row>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.uploadedDate`)}:</DescriptionItem>
            {client.membershipDue}
          </DescriptionWrapper>
        </Col>
        <Col span={12}>
          <DescriptionWrapper>
            <DescriptionItem>{t(`${tPath}.userProfile.evaluationResult`)}:</DescriptionItem>
            {client.consultationTimesRemain}
          </DescriptionWrapper>
        </Col>
      </Row> */}
      <a href={api_address + 'getResultByUserUuid/' + client.user.uuid} target="_blank" rel="noreferrer noopener">
        {t(`${tPath}.userProfile.viewResult`)}
      </a>
      {/* <p>{t(`${tPath}.userProfile.viewResult`)}</p> */}
      <p style={{ marginTop: '14px' }}>{t(`${tPath}.userProfile.notes`)}</p>
    </Drawer>
  );
};

export default React.memo(BasicInfoDrawer);
