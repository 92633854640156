import styled from 'styled-components';
import { Collapse, Modal } from 'antd';

export const ActivityModalContainer = styled(Modal)`
  @media only screen and (min-width: 1105px) {
    height: calc(100% - 80px);
  }
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .ant-modal-body {
    height: calc(100% - 65px);
  }
`;

export const ScrollContainer = styled.div`
  overflow: hidden auto;
  height: 100%;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: -8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 8px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background: #bcc0c4;
    }
  }
`;

export const CollapseContentBox = styled(Collapse)`
  background-color: rgb(255, 255, 255);
  min-width: 415px;
  .ant-collapse-item {
    border-radius: 3px;
    margin-bottom: 18px;
    border-bottom: none;
  }
  .ant-collapse-header {
    background-color: #fafafa !important;
    min-height: 56px;
    align-items: center !important;
  }
  .ant-collapse-content {
    padding: 16px;
    background-color: rgb(255, 255, 255) !important;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
  }
`;

export const DetailsContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  align-items: ;
`;

export const DetailsTitleContainer = styled.div`
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
  box-sizing: border-box;
  padding-right: 24px;
  min-width: 120px;
  width: 40%;
  padding-top: 8px;
  max-width: 170px;
`;

export const DetailsDataContainer = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 60%;
  box-sizing: border-box;
  padding-top: 8px;
`;

export const HistoryListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

// badge
export const SkillBadge = styled.span<{ BackgroundColor: string }>`
  width: max-content;
  height: max-content;
  padding: 2px 7px;
  border-radius: 4px;
  background-color: ${({ BackgroundColor }) => BackgroundColor};
  margin-right: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
  display: inline-block;
`;
export const HistoryBadge = styled.div<{ BackgroundColor: string }>`
  width: max-content;
  padding: 1px 7px;
  border-radius: 4px;
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ BackgroundColor }) => BackgroundColor};
  margin: 0 5px;
`;
