import React from 'react';
import { Message } from 'semantic-ui-react';
import { ReturnMessage } from '../../../../types';
import i18n from '../../../../i18n';

interface Props {
  returnMessage: ReturnMessage;
}

const LoginPopup = ({ returnMessage }: Props) => (
  <Message negative={returnMessage.status === 'ERROR'} positive={returnMessage.status === 'SUCCESS'} size="huge">
    {i18n.t(returnMessage.message)}
  </Message>
);

export default LoginPopup;
