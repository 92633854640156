import { createContext } from 'react';

interface State {
  roomId: string;
  childId: string;
}

const defaultContext: State = {
  roomId: '',
  childId: '',
};

export default createContext(defaultContext);
