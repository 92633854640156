import React from 'react';
import { ChildHomeworks_child } from '../../../../../graphql/queries/types/ChildHomeworks';
import { useTranslation } from 'react-i18next';
import HomeworkCard from './components/HomeworkCard';
import { Typography } from 'antd';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';

interface Props {
  child: ChildHomeworks_child;
}

const HomeworksComponent = ({ child }: Props) => {
  const {
    id: childId,
    homeworks,
    room: { id: roomId },
  } = child;
  const { t } = useTranslation();
  const { Title } = Typography;
  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.dashboard.myClient.clientInfo.tasks.thisWeeksHw')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        {homeworks.length > 0 &&
          homeworks
            .reverse()
            .map((homework) => (
              <HomeworkCard key={homework.id} homework={homework} roomId={roomId} childId={childId} />
            ))}
      </RouteContentContainer>
    </>
  );
};

export default HomeworksComponent;
