import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TherapistChildContext } from '../../TherapistChildContext';
import PlanListItem from '../../components/ConsultationAndPlanCard/PlanListItem';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import { TodoTabs } from '../TodoRoute/styles';
import { Tabs, Typography } from 'antd';
import { BadgeComponent } from '../ConsultationRoute';
import PublishedPlanList from './PublishedPlanList';
import * as qs from 'query-string';

const PlanRoute = () => {
  const { t } = useTranslation();
  const { publishedPlan, duePlan, nextPlan } = useContext(TherapistChildContext);
  const { TabPane } = Tabs;
  const { Title } = Typography;
  const { tab } = qs.parse(window.location.search);
  const defaultTab: string = tab ? tab.toString() : '1';

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.childInfo.plan.pageTitle')}</Title>
      </RouteTitleContainer>
      <RouteContentContainer>
        <TodoTabs defaultActiveKey={defaultTab}>
          <TabPane
            tab={
              <>
                {t('therapist.childInfo.general.status.planOverdue')}
                {BadgeComponent(duePlan.length)}
              </>
            }
            key="1"
          >
            <PlanListItem consultationList={duePlan} />
          </TabPane>
          <TabPane
            tab={
              <>
                {t('therapist.childInfo.general.status.planNext')}
                {BadgeComponent(nextPlan.length)}
              </>
            }
            key="2"
          >
            <PlanListItem consultationList={nextPlan} />
          </TabPane>
          <TabPane tab={t('therapist.childInfo.general.status.planPublished')} key="3">
            <PublishedPlanList consultationList={publishedPlan} />
          </TabPane>
        </TodoTabs>
      </RouteContentContainer>
    </>
  );
};

export default React.memo(PlanRoute);
