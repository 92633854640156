import React from 'react';
import { Image } from 'semantic-ui-react';
import { ChildMessages_child_room_directMessages } from '../../../../../../graphql/queries/types/ChildMessages';
import ReplyComponent from './Reply';
import defaultChild from '../../../../../../assets/defaultChild.png';
import Linkify from 'react-linkify';
interface Props {
  message: ChildMessages_child_room_directMessages;
}

const MessageComponent = ({ message }: Props) => {
  const renderMessageWithFile = (url: string, contentType: string, body: string) => {
    if (contentType.includes('image')) {
      return (
        <div>
          <Image src={url} size="small" />
          <br />
          <p>{body}</p>
        </div>
      );
    } else if (contentType.includes('video')) {
      return (
        <div>
          <video src={url} width={320} height={240} controls /> <br />
          <p>{body}</p>
        </div>
      );
    } else {
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {body}
        </a>
      );
    }
  };

  const handleClick = (e) => {
    // Check if the message contains a link
    if (message.body.includes('http://') || message.body.includes('https://')) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const matches = message.body.match(urlRegex);
      const matchesResult = matches && matches?.length > 0 ? matches[0] : null;
      // Open the link in a new tab
      e.preventDefault();
      if (matchesResult) {
        window.open(matchesResult, '_blank');
      }
    }
  };
  const renderMessageContent = () => {
    if (message.homeworkUrl && message.homeworkContentType) {
      return renderMessageWithFile(message.homeworkUrl, message.homeworkContentType, message.body);
    }

    if (message.messageFileUrl && message.messageFileContentType) {
      return renderMessageWithFile(message.messageFileUrl, message.messageFileContentType, message.body);
    }
    return (
      <Linkify>
        <span onClick={handleClick}>{message.body}</span>
      </Linkify>
    );
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <div style={{ display: 'inline-block', margin: '0px 10px' }}>
            <Image
              circular
              style={{ height: '35px', width: '35px', objectFit: 'cover' }}
              size="mini"
              bordered
              src={message.avatarUrl ? message.avatarUrl : defaultChild}
            />
          </div>
          <div style={{ display: 'flex', margin: '3px', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{message.user.name}</p>
            <p style={{ color: '#B2B2B2', fontSize: '12px' }}>{message.createdAt}</p>
          </div>
        </div>
      </div>
      <div style={{ margin: '0px 50px', fontSize: '12px' }}>
        <div data-cy="messagesContent" style={{ margin: '10px 5px' }}>
          {renderMessageContent()}
        </div>
        <ReplyComponent message={message} />
      </div>
    </div>
  );
};

export default MessageComponent;
