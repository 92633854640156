import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const UPDATE_DOWNLOADED_VIDEO_STATUS_MUTATION = gql`
  mutation UpdateDownloadedVideoStatus($uploadedVideoId: ID!) {
    updateDownloadedVideoStatus(uploadedVideoId: $uploadedVideoId) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface UpdateDownloadedVideoStatusData {
  updateDownloadedVideoStatus: {
    returnMessage: ReturnMessage;
  };
}

export interface UpdateDownloadedVideoStatusVariables {
  uploadedVideoId: number;
}
