import gql from 'graphql-tag';

export const REQUEST_NOT_SHOW_MORE_MUTATION = gql`
  mutation RequestNotShowMore($modalNotificationId: ID!) {
    requestNotShowMore(modalNotificationId: $modalNotificationId) {
      returnMessage {
        status
        message
      }
    }
  }
`;
