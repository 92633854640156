import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, SmallText, BigText, HorizontalContainer, Container, TextContainer } from './styles';

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Container>
        <HorizontalContainer>
          <Image src="https://guide-staging-qierbao.s3.ap-northeast-2.amazonaws.com/cute-penguin.png" />
          <TextContainer>
            <BigText>{t('system.error1')}</BigText>
            <SmallText>{t('system.error2')}</SmallText>
            <SmallText>{t('system.error3')}</SmallText>
          </TextContainer>
        </HorizontalContainer>
      </Container>
    </div>
  );
};

export default ErrorPage;
