import React from 'react';
import { Modal, Button, Icon, Image } from 'semantic-ui-react';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import zhTW from '../assets/zh-TW.png';
import zhCN from '../assets/zh-CN.png';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickNotShowMore: (notificationId: string) => void;
  notificationId: string;
}

const ChineseNewYearCampaign = ({ handleClickNotShowMore, notificationId }: Props) => {
  const language = i18n.use(LanguageDetector).language;
  let image = '';
  if (language === 'zh-TW') {
    image = zhTW;
  } else if (language === 'zh-CN') {
    image = zhCN;
  }

  return (
    <div>
      <Modal.Content style={{ padding: '0px', borderRadius: '15px' }}>
        <img src={image} style={{ width: '400px' }} />
        <div style={{ marginTop: '-65px' }}>
          <Button
            onClick={() => {
              handleClickNotShowMore(notificationId);
            }}
            style={{ backgroundColor: '#fb7053' }}
          >
            <div style={{ fontSize: '20px', color: '#fff' }}>我知道了</div>
          </Button>
        </div>
      </Modal.Content>
    </div>
  );
};

export default ChineseNewYearCampaign;
