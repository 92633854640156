import gql from 'graphql-tag';

export const UPDATE_COURSE_LINK_READ_MUTATION = gql`
  mutation UpdateCourseLinkRead($courseLinkId: ID!, $childId: ID!) {
    updateCourseLinkRead(courseLinkId: $courseLinkId, childId: $childId) {
      returnMessage {
        status
        message
      }
    }
  }
`;
