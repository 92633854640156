import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, OverviewContainer, DescriptionSection, VideoWrapper, QnAVideoContainer } from '../../styles';
import { UserComment, SmallContentTitle } from '../../../../../style';

interface Props {
  videoTitle: string;
  videoUrl: string;
}

const QnAVideoPage = ({ videoTitle, videoUrl }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <QnAVideoContainer>
        <VideoWrapper>
          <Player
            width={560}
            height={315}
            src={videoUrl}
            title={videoTitle}
            frameBorder={0}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
            allowFullScreen
          />
        </VideoWrapper>
        <OverviewContainer>
          <DescriptionSection>
            <SmallContentTitle>{videoTitle}</SmallContentTitle>
          </DescriptionSection>
        </OverviewContainer>
      </QnAVideoContainer>
    </>
  );
};

export default QnAVideoPage;
