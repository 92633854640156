import React, { useContext, useEffect, useState } from 'react';
import {
  InstallBannerButton,
  InstallBannerContainer,
  InstallBannerIcon,
  InstallBannerText,
  InstallBannerAntiActionButton,
  BigContainer,
  InstallBannerButtonsContainer,
  CheckboxContainer,
  Checkbox,
  CheckboxWrapper,
} from './InstallBannerStyles';
import { useMutation } from 'react-apollo';
import {
  UpdateInstallBannerAppearStatus,
  UpdateInstallBannerAppearStatusVariables,
} from '../../../graphql/mutations/types/UpdateInstallBannerAppearStatus';
import { UPDATE_INSTALL_BANNER_APPEAR_STATUS } from '../../../graphql/mutations/updateInstallBannerAppearStatus';
import UserContext from '../UserContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const InstallBanner = (props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [installBannerDisplay, setInstallBannerDisplay] = useState(false);
  const [installBannerAppear, setInstallBannerAppear] = useState(false);
  const deferredPrompt = props.deferredPrompt;
  const location = useLocation();

  const onInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setInstallBannerDisplay(false);
  };

  const onClickClose = () => {
    updateInstallBannerAppearStatus({ variables: { inputUserId: userId, status: !isChecked } });
    setInstallBannerDisplay(false);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const userId = user.id;

  useEffect(() => {
    setInstallBannerAppear(user.installBannerAppear);
  }, [user, userId]);

  //If user has not installed the pwa, has 'dont show this again' not checked and timing is hit
  useEffect(() => {
    if (
      deferredPrompt &&
      installBannerAppear &&
      location.pathname !== '/consentFormCPC' &&
      location.pathname !== '/consentFormPEA'
    )
      setInstallBannerDisplay(true);
    else setInstallBannerDisplay(false);
  }, [deferredPrompt, installBannerAppear, location]);

  //Mutation to update banner appear status
  const [updateInstallBannerAppearStatus, {}] = useMutation<
    UpdateInstallBannerAppearStatus,
    UpdateInstallBannerAppearStatusVariables
  >(UPDATE_INSTALL_BANNER_APPEAR_STATUS);

  return (
    <BigContainer display={installBannerDisplay}>
      <InstallBannerContainer>
        <InstallBannerText>{t('installBanner.mainText')}</InstallBannerText>
        <CheckboxContainer>
          <Checkbox type="checkbox" onChange={handleChecked} />
          {t('installBanner.dontShowAgain')}
        </CheckboxContainer>
        <InstallBannerButtonsContainer>
          <InstallBannerButton onClick={onInstallClick}>{t('installBanner.actionButtonText')}</InstallBannerButton>
          <InstallBannerAntiActionButton onClick={onClickClose}>
            {t('installBanner.antiActionButtonText')}
          </InstallBannerAntiActionButton>
        </InstallBannerButtonsContainer>
      </InstallBannerContainer>
    </BigContainer>
  );
};

export default InstallBanner;
