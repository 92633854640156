import gql from 'graphql-tag';
import { Homework, ReturnMessage } from '../../types';

export const CREATE_HOMEWORK_MUTATION = gql`
  mutation CreateHomework(
    $description: String!
    $submissionDate: ISO8601DateTime!
    $format: [String!]!
    $childId: ID!
  ) {
    createHomework(description: $description, submissionDate: $submissionDate, format: $format, childId: $childId) {
      homework {
        id
        description
        submissionDate
        format
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  createHomework: { homework: Homework; returnMessage: ReturnMessage };
}

export interface Variables {
  description: string;
  submissionDate: string;
  format: string[];
  childId: number;
}
