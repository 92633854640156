import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../../../../util/Product';

interface Props {
  onBoardDocumentUrl: string;
  locale: string;
  productType: string;
}

const BeforeFirstConsultation = ({ onBoardDocumentUrl, locale, productType }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tPath = 'client.profile.beforeFirstConsultation';
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);
  const orientationFormLink = (locale: string) => {
    switch (locale) {
      case 'en':
        return 'https://www.wenjuan.com/s/ARfeQzI/#';
      case 'zh-CN':
        return 'https://www.wenjuan.com/s/myERnaY/#';
      case 'zh-TW':
        return 'https://www.wenjuan.com/s/FzuAZrI/#';
      default:
        return 'https://www.wenjuan.com/s/myERnaY/#';
    }
  };
  const preServiceFormLink = (locale: string) => {
    switch (locale) {
      case 'en':
        return 'https://forms.gle/qb3bjLLKMfSkUMJ27';
      case 'zh-CN':
        return 'https://www.wenjuan.com/s/QbiAJzM/';
      case 'zh-TW':
        return 'https://forms.gle/SDtDVSnqRmK2LHQn7';
      default:
        return 'https://www.wenjuan.com/s/QbiAJzM/';
    }
  };

  return (
    <div style={{ padding: '0px 10px' }}>
      {isPEA && (
        <>
          <p>{t(`${tPath}.liteOnBoardingNote`)}</p>
          <a target="_blank" rel="noopener noreferrer" href={'https://www.wenjuan.com/s/AJvAZn/#'}>
            {t(`${tPath}.liteOrientationForm`)}
          </a>
          <div></div>
          <a target="_blank" rel="noopener noreferrer" href={preServiceFormLink(locale)}>
            {t(`${tPath}.preServiceForm`)}
          </a>
          <div
            onClick={() => history.push('/PEAOverview')}
            style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'none' }}
          >
            {t('client.profile.howToUse.liteOverviewLinkText')}
          </div>
        </>
      )}
      {isCPC && (
        <>
          <p>{t(`${tPath}.onBoardingNote`)}</p>
          <a target="_blank" rel="noopener noreferrer" href={orientationFormLink(locale)}>
            {t(`${tPath}.orientationForm`)}
          </a>
          <div></div>
          <a target="_blank" rel="noopener noreferrer" href={preServiceFormLink(locale)}>
            {t(`${tPath}.preServiceForm`)}
          </a>
          <div
            onClick={() => history.push('/CPCOverview')}
            style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'none' }}
          >
            {t('client.profile.howToUse.overviewLinkText')}
          </div>
        </>
      )}
    </div>
  );
};

export default BeforeFirstConsultation;
