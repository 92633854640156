import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
`;

export const RouteSectionContainer = styled.div`
  padding: 20px 2%;
  @media only screen and (min-width: 576px) {
    padding: 20px 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 20px 8%;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 220px;
    padding: 0 15%;
  }
`;

export const BiggerRouteSectionContainer = styled.div`
  padding: 20px 4%;
  @media only screen and (min-width: 576px) {
    padding: 20px 5%;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 8%;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 220px;
  }
`;

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: auto;
  flex-direction: column;
  @media only screen and (max-width: 460px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
export const NoChildLogo = styled.img`
  width: 206px;
  height: auto;
  max-width: 150px;

  @media only screen and (max-width: 650px) {
    max-width: 100px;
  }
`;
export const NoChildTitle = styled.div`
  font-size: 40px;
  font-weight: 500;
  @media only screen and (max-width: 650px) {
    font-size: 30px;
  }
`;
export const NoChildSubtitle = styled.div`
  font-size: 18px;
  margin-left: 25px;
  margin-right: 25px;
  @media only screen and (max-width: 650px) {
    font-size: 16px;
    margin-top: 12px;
  }
`;

export const NoChildEmail = styled.a`
  font-weight: bold;
  font-size: 18px;
  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`;

export const NoChildContainer = styled.div`
  height: 90vh;
  margin: 0px;
  text-align: center;
`;
