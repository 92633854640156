import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ClientContext } from '../../../ClientContext';
import CourseLink from '../../../CoursesRoute/component/courseLink';
import Collapsible from '../VideoList/Collapsible';
import { SmallDescription, SmallSectionTitle } from '../../../../../style';
import { CollapsibleHeaderText } from '../../../CoursesRoute/styles';

interface Props {
  sectionIndex: number;
  itemIndex: number;
}

const CourseContent = ({ sectionIndex, itemIndex }: Props) => {
  const { t } = useTranslation();
  const { courseSections } = useContext(ClientContext);

  const openIndex = useMemo(() => {
    if (sectionIndex === undefined) {
      let nearestDate = -1;
      const dateToCheckFor = moment().format('YYYY/MM/DD');
      courseSections.forEach((c1, index1) => {
        const datesToBeChecked = moment(c1.startTime).format('YYYY/MM/DD');

        let diff = moment(datesToBeChecked).diff(dateToCheckFor, 'days');
        if (diff <= 0) {
          nearestDate = index1;
        }
      });
      return nearestDate;
    }
    return sectionIndex;
  }, [sectionIndex, courseSections]);

  const [ordinalArray] = useMemo(() => {
    const ordinal = courseSections.map((c) => c.courseLinks.length);
    const ordinalArray = [] as number[];
    let count = 0;
    for (let i = 0; i < ordinal.length; i++) {
      ordinalArray.push(count);
      count += ordinal[i];
    }
    return [ordinalArray];
  }, [courseSections]);

  const show = courseSections.map((c, index) => {
    const availableTime = moment(c.startTime).isBefore();
    const sortCourseLinks = c.courseLinks.sort((a, b) => {
      return a.order - b.order;
    });
    const links = sortCourseLinks.map((cl, index2) => {
      return (
        <CourseLink
          cIndex={index}
          clIndex={index2}
          courseLink={cl}
          timeStatus={availableTime}
          selected={itemIndex === index2 && sectionIndex === index}
          ordinal={ordinalArray[index] + index2 + 1}
        />
      );
    });
    return (
      <Collapsible
        defaultOpen={openIndex === index}
        show={true}
        header={
          <CollapsibleHeaderText>
            <SmallSectionTitle>
              {t(`client.course.videoList.${index}`)}
              {c.title}
            </SmallSectionTitle>
            <SmallDescription>{moment(c.startTime).format('YYYY/MM/DD')}</SmallDescription>
          </CollapsibleHeaderText>
        }
        content={<>{links}</>}
      />
    );
  });

  return <div>{show}</div>;
};

export default CourseContent;
