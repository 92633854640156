import React from 'react';
import { useTranslation } from 'react-i18next';
import { SmallSectionTitle } from '../../../../../../style';
import {
  ContentSectionContainer,
  MobileSpace,
  PracticeContent,
  SetupCardContainer,
  SetupCardScrollContainer,
  StepContainer,
  TipsIcon,
} from '../styles';
import loudspeaker from '../../../assets/loudspeaker.svg';
import love from '../../../assets/love.svg';
import { Client_client_children_assignedActivities } from '../../../../../../graphql/queries/types/Client';
import { SetupScrollCard, StringToListComponent } from '../component/utilsComponent';
interface Props {
  activity: Client_client_children_assignedActivities;
  tPath: string;
}

const PracticeTab = ({ activity, tPath }: Props) => {
  const { t } = useTranslation();
  const setupCard = SetupScrollCard(activity);
  const activityStep = StringToListComponent(activity.steps);
  const video = activity.videoUrl;
  const videoRemarks = activity.videoRemarks;
  return (
    <>
      {video !== '' ? (
        <>
          <SmallSectionTitle>{t('client.dashboard.activity.video')}</SmallSectionTitle>
          {videoRemarks !== '' ? (
            <>
              <p>{videoRemarks}</p>
            </>
          ) : null}
          <iframe
            width="100%"
            height="315"
            margin-top="1rem"
            src={video}
            title="activity video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </>
      ) : null}
      <SmallSectionTitle>{t(`${tPath}.practice.activitySetup`)}</SmallSectionTitle>
      <SetupCardContainer>
        <SetupCardScrollContainer>{setupCard}</SetupCardScrollContainer>
      </SetupCardContainer>
      <SmallSectionTitle>{t(`${tPath}.practice.steps`)}</SmallSectionTitle>
      <ContentSectionContainer>{activityStep}</ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.practice.tips`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <StepContainer>
          <div style={{ width: '40px' }}>
            <TipsIcon src={love} />
          </div>
          <PracticeContent>{activity.parentReaction}</PracticeContent>
        </StepContainer>
        <StepContainer>
          <div style={{ width: '40px' }}>
            <TipsIcon src={loudspeaker} />
          </div>
          <PracticeContent>{activity.notes ? activity.notes : t(`${tPath}.no`)}</PracticeContent>
        </StepContainer>
      </ContentSectionContainer>
      <MobileSpace />
    </>
  );
};

export default React.memo(PracticeTab);
