import React from 'react';
import { Viewer_viewer } from '../../graphql/queries/types/Viewer';

interface State {
  user: Viewer_viewer;
}

const defaultUserContext: State = {
  user: {
    id: '',
    uuid: '',
    name: '',
    email: '',
    metaType: '',
    activeChildId: '',
    metaId: '',
    locale: '',
    phoneNumber: '',
    country: '',
    smsNotification: false,
    loggedInBefore: false,
    deviceNotifications: [],
    installBannerAppear: true,
    firstName: '',
    lastName: '',
  },
};

export default React.createContext(defaultUserContext);
