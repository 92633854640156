import moment from 'moment';
import { PEA_PRODUCT_TYPES, CPC_PRODUCT_TYPES, FLEX_PRODUCT_TYPES } from '../../../util/Product';

const INACTIVE_COLOR = 'red';
const ACTIVE_COLOR = 'green';

export const sortConsultations = (consultations) => {
  return consultations.sort(
    (a, b) => new Date(a.consultation_time).getTime() - new Date(b.consultation_time).getTime(),
  );
};

export const filterChildrenData = (
  therapistChildrenConsultations,
  productName,
  filterActiveOnlyPEA,
  filterActiveOnlyCPC,
) => {
  const filterActiveOnly = productName === 'pea' ? filterActiveOnlyPEA : filterActiveOnlyCPC;

  return therapistChildrenConsultations.filter((child) => {
    const { consultations = [], product = {} } = child;
    const filteredConsultations = consultations.filter((c) => c.notes?.mainComplaint !== 'Auto Generated');
    const isPEA = PEA_PRODUCT_TYPES.includes(product.productTypesName);
    const isCPC = CPC_PRODUCT_TYPES.includes(product.productTypesName);
    const isLastConsultationPassed = () => {
      const sortedConsultations = sortConsultations(filteredConsultations);
      const lastConsultation = sortedConsultations[filteredConsultations.length - 1];
      if (lastConsultation && lastConsultation.consultationTime) {
        const now = moment();
        const consultationTime = moment(lastConsultation.consultationTime);
        return consultationTime.add(1, 'day').isBefore(now);
      }
      return true;
    };

    if ((productName === 'cpc' && isPEA) || (productName === 'pea' && isCPC)) return false;

    const consultationsRemaining = child.client.consultationTimesRemain;
    const maxConsultations = product.maxConsultation;
    const consultationsDone = filteredConsultations.length;
    const lastConsultationNotPassed = !isLastConsultationPassed();

    if (filterActiveOnly) {
      const now = moment();
      const membershipDue = moment(child.client.membershipDue);
      if (membershipDue && membershipDue.isBefore(now)) {
        return false;
      }
      if (FLEX_PRODUCT_TYPES.includes(product.productTypesName)) {
        if (consultationsDone >= consultationsRemaining && !lastConsultationNotPassed) return false;
      }
      if (consultationsDone >= maxConsultations && !lastConsultationNotPassed) return false;
    }

    return true;
  });
};

export const isLastConsultationPassed = (consultations) => {
  const sortedConsultations = sortConsultations(consultations);
  const lastConsultation = sortedConsultations[sortedConsultations.length - 1];
  if (lastConsultation && lastConsultation.consultationTime) {
    const now = moment();
    const consultationTime = moment(lastConsultation.consultationTime);
    return consultationTime.add(1, 'day').isBefore(now);
  }
  return true;
};

export const getStatusColor = (child, product) => {
  const now = moment();
  const membershipDue = moment(child.client.membershipDue);

  if (membershipDue && moment(membershipDue).isBefore(now)) {
    return INACTIVE_COLOR;
  }

  const filteredConsultations = child.consultations.filter((c) => c.notes?.mainComplaint !== 'Auto Generated');
  const consultationsRemaining = child.client.consultationTimesRemain;
  const maxConsultations = product.maxConsultation;
  const consultationsDone = filteredConsultations.length;
  const lastConsultationNotPassed = !isLastConsultationPassed(filteredConsultations);
  const isFlexProduct = FLEX_PRODUCT_TYPES.includes(product.productTypesName);

  if (isFlexProduct) {
    if (
      consultationsDone < consultationsRemaining ||
      (consultationsDone < consultationsRemaining && lastConsultationNotPassed)
    ) {
      return ACTIVE_COLOR;
    }
  }
  if (consultationsDone < maxConsultations || (consultationsDone === maxConsultations && lastConsultationNotPassed)) {
    return ACTIVE_COLOR;
  }
  return INACTIVE_COLOR;
};
