import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  UploadfileContainer,
  UploadfileTitle,
  UploadfileInstructionContainer,
  UploadfileInstructionTitle,
  UploadfileInstructionContentContainer,
  UploadfileInstructionContentText,
  UploadfileInstructionContentImg,
} from '../styles';
import UserContext from '../../../../common/UserContext';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';
interface Props {}

const UploadFile = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const user = useContext(UserContext);
  const locale = user.user.locale;
  const { t } = useTranslation();
  const tPath = 'utils.uploadfile';
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/task/hw')} />
      <UploadfileContainer>
        <UploadfileTitle>{t(`${tPath}.title`)}</UploadfileTitle>
        <UploadfileInstructionContainer>
          <UploadfileInstructionTitle>{t(`${tPath}.register.title`)}</UploadfileInstructionTitle>
          <UploadfileInstructionContentContainer>
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.register.img1`)}`).default} />
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.register.img2`)}`).default} />
            <UploadfileInstructionContentText>{t(`${tPath}.register.des`)}</UploadfileInstructionContentText>
          </UploadfileInstructionContentContainer>
        </UploadfileInstructionContainer>
        <UploadfileInstructionContainer>
          <UploadfileInstructionTitle>{t(`${tPath}.upload.title`)}</UploadfileInstructionTitle>
          <UploadfileInstructionContentContainer>
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.upload.img1`)}`).default} />
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.upload.img2`)}`).default} />
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.upload.img3`)}`).default} />
          </UploadfileInstructionContentContainer>
        </UploadfileInstructionContainer>
        {locale != 'zh-CN' && (
          <UploadfileInstructionContainer>
            <UploadfileInstructionTitle>{t(`${tPath}.setup.title`)}</UploadfileInstructionTitle>
            <UploadfileInstructionContentContainer>
              <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.setup.img1`)}`).default} />
              <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.setup.img2`)}`).default} />
              <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.setup.img3`)}`).default} />
            </UploadfileInstructionContentContainer>
          </UploadfileInstructionContainer>
        )}
        <UploadfileInstructionContainer>
          <UploadfileInstructionTitle>{t(`${tPath}.share.title`)}</UploadfileInstructionTitle>
          <UploadfileInstructionContentContainer>
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.share.img1`)}`).default} />
            <UploadfileInstructionContentImg src={require(`./assets/${t(`${tPath}.share.img2`)}`).default} />
            <UploadfileInstructionContentText>{t(`${tPath}.share.des`)}</UploadfileInstructionContentText>
          </UploadfileInstructionContentContainer>
        </UploadfileInstructionContainer>
      </UploadfileContainer>
    </RouteSectionContainer>
  );
};

export default UploadFile;
