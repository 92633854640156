export const HBI_LITE_PRODUCTS: string[] = [
  'Parent Education Academy - A',
  'Parent Education Academy - B',
  'Parent Education Academy - C',
];

export const DEFAULT_ROUTE = (productName: string): string => {
  if (HBI_LITE_PRODUCTS.includes(productName)) {
    return 'home';
  }
  return 'dashboard';
};

export const HBI_LITE_PRODUCT_INFO: HBI_LITE = {
  'Parent Education Academy - A': {
    name: 'Parent Education Academy - A',
    groupLink: 'https://google.com',
  },
  'Parent Education Academy - B': {
    name: 'Parent Education Academy - B',
    groupLink: 'https://google.com',
  },
  'Parent Education Academy - C': {
    name: 'Parent Education Academy - C',
    groupLink: 'https://google.com',
  },
};

export interface HBI_LITE {
  [key: string]: HBI_LITE_INFO;
}

export interface HBI_LITE_INFO {
  name: string;
  groupLink: string;
}

export const CPC_PRODUCT_TYPES: string[] = ['cpc', 'cpcFlex'];

export const PEA_PRODUCT_TYPES: string[] = ['pea', 'peaICC'];

export const ICC_PRODUCT_TYPES: string[] = ['peaICC'];

export const FLEX_PRODUCT_TYPES: string[] = ['cpcFlex'];
