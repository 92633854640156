import styled from 'styled-components';

// index.tsx

export const Section = styled.div`
  background: white;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 0.9em;
  border-radius: 5px;
  font-size: 0.85em;
`;

export const SectionTitleDiv = styled.div`
  margin: -5px 10px 5px 20px;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SectionTitle = styled.span`
  font-weight: bold;
  color: black;
  margin-left: 5px;
`;

export const HideText = styled.span`
  display: none;
  @media only screen and (max-width: 600px) {
    display: inline;
    cursor: pointer;
    color: #00b5ad;
    margin-left: 5px;
  }
`;

export const HorizontalLine = styled.hr`
  color: grey;
  opacity: 50%;
  margin: 0;
  padding: 0;
`;

export const NotificationMenu = styled.div`
  width: 100%;
  padding: 5px;
`;

export const NotificationMenuItem = styled.div<{ active: boolean; disabled?: boolean }>`
  display: inline-block;
  text-align: center;
  border-bottom: ${({ active }) => (active ? '5px solid #00b5ad' : 'none')};
  margin: 0 10px;
  color: ${({ active }) => (active ? '#00b5ad' : 'grey')};
  padding: 3px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media only screen and (max-width: 600px) {
    display: block;
    margin: 5px 0;
  }
`;

export const NotificationContainer = styled.div`
  padding: 5px 15px;
  min-height: 120px;
`;

export const OverviewHeader = styled.div`
  text-align: center;
`;

export const OverviewTextSection = styled.ol`
  font-size: 14px;
`;

export const IntroText = styled.p`
  margin: 5px 0;
`;

export const OverviewText = styled.li`
  margin: 5px 0;
`;

export const OverviewBoldText = styled.span`
  font-weight: bold;
`;

export const GuidelineTextSection = styled(OverviewTextSection)`
  font-size: 18px;
`;

export const GuidelineText = styled(OverviewText)`
  margin: 20px 0;
`;

export const GuidelineBoldText = styled(OverviewBoldText)``;

export const GuidelineImportantText = styled.span`
  font-weight: bold;
  background-color: yellow;
`;

export const GuideLineModifyingText = styled.span`
  text-decoration: underline;
`;

// uploadfile
export const UploadfileContainer = styled.div`
  min-height: 600px;
  color: #6d6d6d;
  line-height: 20px;
`;
export const UploadfileTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const UploadfileInstructionContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 40%);
  border-radius: 3px;
  padding: 25px;
  margin-bottom: 15px;
`;
export const UploadfileInstructionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #2ab2ac;
  margin-bottom: 15px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const UploadfileInstructionContentContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const UploadfileInstructionContentText = styled.div`
  width: 240px;
  height: 200px;
  min-width: 200px;
  margin: 0px 10px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const UploadfileInstructionContentImg = styled.img`
  src: ${(props) => props.src};
  width: 240px;
  min-width: 200px;
  margin: 0px 10px;
  @media only screen and (max-width: 800px) {
    margin: 0 0 20px 0;
  }
`;
