import styled from 'styled-components';
import { MainColor, BackgroundColor } from '../../../../../styles';
import { DataItemContainer } from '../../../../styles';
import SVG from 'react-inlinesvg';

export const CountContainer = styled(DataItemContainer)`
  justify-content: center;
`;

export const CountButton = styled.div`
  display: none;
  width: 0px;
  height: 0px;
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 0 4px;
    border: 1.5px solid ${MainColor};
    border-radius: 8px;
    font-size: 18px;
    font-family: Times New Roman;
    font-weight: bold;
    color: ${MainColor};
    cursor: pointer;
    background: white;
    @media (hover: hover) and (pointer: fine) {
      :hover {
        background: ${MainColor};
        color: white;
      }
    }
  }
`;

export const Count = styled.div`
  display: flex;
  text-align-last: center;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${BackgroundColor};
  cursor: pointer;
  // @media only screen and (min-width: 768px) {
  //   background: none;
  // }
`;

export const PlusButton = styled.div`
  position: absolute;
  height: 40px;
  width: 50px;
  right: -5px;
  bottom: 0;
  // @media only screen and (min-width: 768px) {
  //   display: none;
  // }
`;

export const PlusIcon = styled(SVG)`
  position: absolute;
  right: 0;
  bottom: 2px;
  height: 16px;
  width: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  border-radius: 25px;
`;
