import gql from 'graphql-tag';
import { Goal } from '../../types';

export const ADD_GOALS_TO_CONSULTATION_MUTATION = gql`
  mutation AddGoalsToConsultation($goalIds: [ID!]!, $consultationId: ID!) {
    addGoalsToConsultation(goalIds: $goalIds, consultationId: $consultationId) {
      id
      description
      metric
    }
  }
`;

export interface Data {
  addGoalsToConsultation: Goal;
}

export interface Variables {
  goalIds: number[];
  consultationId: number;
}
