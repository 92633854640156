import React from 'react';
import { UserComment } from '../../../../../../../../style';
import { CountContainer, CountButton, Count, PlusIcon, PlusButton } from './styles';
import PlusBtn from '../../../../../assets/PlusBtn.svg';
type Props = {
  index: number;
  count: number;
  rowIndex: number;
  type: string;
  setCount: (wordId: number, value: number) => void;
  handleCountWord: (indices: number, type: string) => void;
};

const CountWord = ({ index, count, type, rowIndex, setCount, handleCountWord }: Props) => {
  const addCount = () => {
    setCount(index, count + 1);
  };

  const subtractCount = () => {
    if (count === 0) {
      return;
    }
    setCount(index, count - 1);
  };

  return (
    <CountContainer>
      {/* <CountButton onClick={subtractCount}>&#8722;</CountButton> */}
      <Count>
        <UserComment>{count}</UserComment>
        <PlusButton onClick={() => handleCountWord(rowIndex, type)}>
          <PlusIcon src={PlusBtn} />
        </PlusButton>
      </Count>
      {/* <CountButton onClick={addCount}>+</CountButton> */}
    </CountContainer>
  );
};

export default CountWord;
