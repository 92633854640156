import gql from 'graphql-tag';

export const GET_MODAL_NOTIFICATIONS_QUERY = gql`
  query GetModalNotifications($clientId: ID!) {
    getModalNotifications(clientId: $clientId) {
      id
      notificationEvent {
        id
        name
      }
    }
  }
`;
