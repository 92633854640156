import React, { useState, useContext } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import CreateHomeworkButton from './CreateHomeworkButton';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';
import {
  HomeworkDiv,
  HomeworkCreateCard,
  HomeworkCardGrid,
  HomeworkCardCalendar,
  HomeworkCardTextArea,
  HomeworkCardFormatButton,
} from '../styles';

const CreateHomeworkComponent = () => {
  const { setNewHomework } = useContext(TherapistConsultationScreenContext);
  const [description, setDescription] = useState<string>('');
  const [submissionDate, setSubmissionDate] = useState<string>('');
  const [format, setFormat] = useState<string[]>([]);

  const onClickFormat = (f: string) => {
    if (!format.includes(f)) {
      setFormat((prevState: string[]) => [...prevState, f]);
    } else {
      setFormat((prevState: string[]) => prevState.filter((p: string) => p !== f));
    }
  };

  const handleClickCancel = () => {
    setNewHomework(false);
  };

  const { t } = useTranslation();

  return (
    <HomeworkDiv>
      <h2 style={{ marginTop: '15px', fontSize: '15pt' }}>{t('therapist.consultationScreen.task.taskAndHw.addHw')}</h2>
      <HomeworkCreateCard>
        <HomeworkCardGrid>
          <p>{`${t('therapist.consultationScreen.task.taskAndHw.description')}: `}</p>
          <HomeworkCardTextArea
            placeholder={t('therapist.consultationScreen.task.taskAndHw.descriptionPlaceholder')}
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
            rows={5}
            data-cy="homeworkCardTextArea"
          />
        </HomeworkCardGrid>
        <HomeworkCardGrid>
          <p>{`${t('therapist.consultationScreen.task.taskAndHw.submissionDate')}: `}</p>
          <HomeworkCardCalendar
            type="date"
            onChange={(e) => setSubmissionDate(e.target.value)}
            value={submissionDate}
            data-cy="submissionDate"
          />
        </HomeworkCardGrid>
        <HomeworkCardGrid>
          <p>{`${t('therapist.consultationScreen.task.taskAndHw.homeworkFormat')}: `}</p>
          <div>
            <HomeworkCardFormatButton clicked={format.includes('video')} onClick={() => onClickFormat('video')}>
              {t('therapist.consultationScreen.task.taskAndHw.formats.video')}
            </HomeworkCardFormatButton>
            <HomeworkCardFormatButton clicked={format.includes('image')} onClick={() => onClickFormat('image')}>
              {t('therapist.consultationScreen.task.taskAndHw.formats.image')}
            </HomeworkCardFormatButton>
            <HomeworkCardFormatButton
              clicked={format.includes('text')}
              onClick={() => onClickFormat('text')}
              data-cy="selectTextFormat"
            >
              {t('therapist.consultationScreen.task.taskAndHw.formats.text')}
            </HomeworkCardFormatButton>
          </div>
        </HomeworkCardGrid>
      </HomeworkCreateCard>
      <Button.Group fluid>
        <CreateHomeworkButton description={description} format={format} submissionDate={submissionDate} />
        <Button attached="bottom" color="grey" onClick={() => handleClickCancel()}>
          {t('therapist.consultationScreen.progress.goalCard.buttons.cancel')}
        </Button>
      </Button.Group>
    </HomeworkDiv>
  );
};

export default CreateHomeworkComponent;
