import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// graphql
import { LITE_QUERY } from '../../../graphql/queries/Client/lite';
import { LiteVariables, Lite } from '../../../graphql/queries/types/Client/Lite';
import { COURSE_QUERY } from '../../../graphql/queries/Client/course';
import { CourseVariables, Course as CourseInterface } from '../../../graphql/queries/types/Client/Course';
import { RouteSectionContainer } from '../styles';

// component
import Course from './component/course';

// assets
import { ClientContext } from '../ClientContext';
import ErrorPage from '../../common/ErrorPage';

const CoursesRoute = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const { child, courseSections, setCourseSections, forceUpdateCourses, setUserRead } = useContext(ClientContext);
  const [liteQuery, { loading, error, data }] = useLazyQuery<Lite, LiteVariables>(LITE_QUERY);
  const [courseQuery, { data: courseData }] = useLazyQuery<CourseInterface, CourseVariables>(COURSE_QUERY);
  useEffect(() => {
    if (courseData && courseData.child) {
      setUserRead(courseData.child);
    }
  }, [courseData]);

  useEffect(() => {
    if (data && data.product) {
      //check for legacy product
      if (data.product.course.id == 0) {
        setCourseSections(data.product.courseSections);
      } else {
        setCourseSections(data.product.course.courseSections);
      }
    }
  }, [data]);

  if (courseSections.length === 0 && !loading && !error && !data) {
    liteQuery({
      variables: { productId: child.product.id },
    });
    courseQuery({
      variables: { childId: child.id },
    });
  }
  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }

  if (courseSections.length === 0 && (error || !data || !data.product)) {
    return <ErrorPage />;
  }

  return (
    <RouteSectionContainer>
      <Course />
    </RouteSectionContainer>
  );
};

export default CoursesRoute;
