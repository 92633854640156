import React, { useContext, useState } from 'react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import SixMonthGoal from './SixMonthGoal';
import AchievedGoalModal from './AchievedGoal';

const TherapistConsultationScreenLongerTermGoalsComponent = () => {
  const {
    consultation: { id, child },
    setActiveItem,
    category,
    setCategory,
    setSubCategory,
    skill,
    setSkill,
  } = useContext(TherapistConsultationScreenContext);

  const [showAchievedGoals, setShowAchievedGoals] = useState<boolean>(false);

  let achievedGoals = child.goals.filter((g) => g.achieved);

  const handleClickBack = () => {
    if (skill) {
      setSkill(null);
      return;
    }
    if (category) {
      setCategory(null);
      setSubCategory(null);
      return;
    }
    setActiveItem('Notes');
  };

  const handleClickNext = async () => {
    setActiveItem('Progress');
  };

  return (
    <div>
      <TherapistConsultationScreenNavArrows
        onClickBack={() => handleClickBack()}
        onClickNext={() => handleClickNext()}
      />
      <div>
        <AchievedGoalModal open={showAchievedGoals} setOpen={setShowAchievedGoals} goals={achievedGoals} />
        <SixMonthGoal childSixMonthGoal={child.childSixMonthGoal} childId={child.id} consultationId={id} />
      </div>
    </div>
  );
};

export default TherapistConsultationScreenLongerTermGoalsComponent;
