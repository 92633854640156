import React, { useState, useContext } from 'react';
import { TextArea, Button } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { CREATE_MESSAGE } from '../../../../../../graphql/mutations/createMessage';
import { DesktopOrLaptop, TabletOrMobile } from '../../../../../common/MediaQueries';
import { CreateMessage, CreateMessageVariables } from '../../../../../../graphql/mutations/types/CreateMessage';
import { ChildMessages, ChildMessagesVariables } from '../../../../../../graphql/queries/types/ChildMessages';
import { CHILD_MESSAGES } from '../../../../../../graphql/queries/childMessage';
import { useTranslation } from 'react-i18next';
import MessagesContext from '../MessagesContext';
import UploadMessageComponent from '../../../../../Client/Communication/components/UploadMessageComponent';

interface Props {
  roomId: string;
  therapistId: string;
}

const NewMessageComponent = ({ roomId, therapistId }: Props) => {
  const { childId } = useContext(MessagesContext);
  const [body, setBody] = useState<string>('');
  const { t } = useTranslation();
  const [createMessage, { loading, error, data }] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    refetchQueries: [{ query: CHILD_MESSAGES, variables: { childId } }],
  });

  const handleClickSend = async () => {
    await createMessage({
      variables: {
        body,
        userId: therapistId,
        roomId,
        homeworkId: null,
        mainMessageId: null,
        file: null,
        fileName: null,
      },
    });
    setBody('');
  };

  return (
    <div>
      <DesktopOrLaptop>
        <UploadMessageComponent roomId={roomId} />
        {/* <Button floated="right" size="tiny" color="teal" circular onClick={() => handleClickSend()}>
          {t('therapist.dashboard.myClient.clientInfo.contact.send')}
        </Button> */}
      </DesktopOrLaptop>
      <TabletOrMobile>
        <div style={{ border: 'solid 1px #dededf' }}>
          <TextArea
            rows="2"
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setBody(e.currentTarget.value)}
            style={{ width: '100%', border: '1px solid' }}
            value={body}
          />
        </div>
        <Button
          floated="right"
          size="tiny"
          color="teal"
          circular
          style={{ marginTop: '0.5rem' }}
          onClick={() => handleClickSend()}
        >
          {t('therapist.dashboard.myClient.clientInfo.contact.send')}
        </Button>
      </TabletOrMobile>
    </div>
  );
};

export default NewMessageComponent;
