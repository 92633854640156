import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityImage, ActivityHeaderContainer, ActivityNameContainer, Selective, TitleMainSkill } from './styles';
import {
  ContentContainer,
  GoalModalContainer,
  GoalModalContainerWithClose,
  GoalModalContent,
  GoalModalHeader,
  GoalModalMask,
  GoalModalTabContainer,
  GoalTitle,
} from '../../../DashboardRoute/styles';
import useLockBodyScroll from '../../../../../util/Hooks/useLockBodyScroll';
import { TabMenuContainer } from '../../../../common/TabMenu/styles';
import OverallTab from './OverallTab';
import PracticeTab from './PracticeTab';
import ExtensionTab from './ExtensionTab';
import ModalInsideCloseButton from '../../../../common/ModalInsideCloseButton';
import Tab from './component/Tab';
import CheckinButton from './component/CheckinButton';
import { Client_client_children_assignedActivities } from '../../../../../graphql/queries/types/Client';

interface HeaderComponentProps {
  activity: Client_client_children_assignedActivities;
  tPath: string;
}
const HeaderComponent = React.memo(({ activity, tPath }: HeaderComponentProps) => {
  const { t } = useTranslation();
  return (
    <ActivityHeaderContainer>
      <ActivityImage src={activity.mediaUrl || undefined} />
      <ActivityNameContainer>
        <TitleMainSkill>{activity.mainSkill.name}</TitleMainSkill>
        <GoalTitle>{activity.name}</GoalTitle>
        <Selective>{t(`${tPath}.selective`)}</Selective>
      </ActivityNameContainer>
    </ActivityHeaderContainer>
  );
});

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activity: Client_client_children_assignedActivities;
  handleActivityCheckin: (activityIDs: Array<string>) => void;
  currentWeekday: number;
}

const ActivityModal = ({ open, setOpen, activity, handleActivityCheckin, currentWeekday }: Props) => {
  useLockBodyScroll(open);
  const { t } = useTranslation();
  const [selection, setSelection] = useState(0);
  const tPath = useMemo(() => {
    return 'client.task.activities';
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelection(0);
    localStorage.removeItem('activityId');
  }, [setOpen]);

  const tabTitles = [t(`${tPath}.overall.title`), t(`${tPath}.practice.title`), t(`${tPath}.more.title`)];

  const tabContents = [
    <OverallTab activity={activity} tPath={tPath} currentWeekday={currentWeekday} />,
    <PracticeTab activity={activity} tPath={tPath} />,
    <ExtensionTab activity={activity} tPath={tPath} />,
  ];

  return (
    <GoalModalMask open={open}>
      <GoalModalContainerWithClose>
        <GoalModalContainer>
          <GoalModalHeader>
            <ModalInsideCloseButton handleClose={handleClose} />
            <HeaderComponent activity={activity} tPath={tPath} />
          </GoalModalHeader>
          <GoalModalTabContainer>
            <TabMenuContainer>
              {tabTitles.map((title, index) => {
                const isSelect = index === selection;
                return <Tab isSelect={isSelect} index={index} title={title} handleTabSelect={setSelection} />;
              })}
            </TabMenuContainer>
          </GoalModalTabContainer>
          <GoalModalContent>
            <ContentContainer>{tabContents[selection]}</ContentContainer>
            <CheckinButton activityId={activity.id} handleActivityCheckin={handleActivityCheckin} />
          </GoalModalContent>
        </GoalModalContainer>
      </GoalModalContainerWithClose>
    </GoalModalMask>
  );
};

export default React.memo(ActivityModal);
