import styled from 'styled-components';
import { ContentTitle, Description } from '../../../../style';

// blog container
export const BlogContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  margin-bottom: 48px;
  @media only screen and (min-width: 576px) {
    height: 100px;
  }
  @media only screen and (min-width: 768px) {
    height: 100px;
  }
  @media only screen and (min-width: 992px) {
    height: 134px;
  }
  @media only screen and (min-width: 1200px) {
    height: 134px;
  }
`;

// blog thumbnail
export const BlogThumbnail = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
  @media only screen and (min-width: 576px) {
    height: 100px;
    width: 100px;
  }
  @media only screen and (min-width: 768px) {
    height: 100px;
    width: 150px;
  }
  @media only screen and (min-width: 992px) {
    height: 134px;
    width: 200px;
  }
  @media only screen and (min-width: 1200px) {
    height: 134px;
    width: 200px;
  }
`;

// blog content
export const TitleContainer = styled.div`
  width: 80%;
  padding: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const BlogTitle = styled(ContentTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  text-decoration: underline;
`;
export const DesContainer = styled.div`
  display: flex;
`;
export const PointDivider = styled.span`
  padding: 0 5px 1px 5px;
  display: flex;
  align-items: center;
`;
export const RecommendedPointDivider = styled(PointDivider)`
  display: none;
  @media only screen and (min-width: 576px) {
    display: none;
  }
  @media only screen and (min-width: 1100px) {
    display: block;
  }
`;
export const RecommendedWord = styled(Description)`
  display: none;
  @media only screen and (min-width: 576px) {
    display: none;
  }
  @media only screen and (min-width: 1100px) {
    display: block;
  }
`;
