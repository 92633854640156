import gql from 'graphql-tag';

export const CHILD_PROGRESS = gql`
  query ChildProgress($childId: ID!) {
    childProgress(childId: $childId) {
      id
      weeklyCheckIns {
        startDate
        endDate
        checkInCount
        trainedActivityCount
        assignedActivityCount
        newlyTrainedActivityCount
        newlyAssignedActivityCount
      }
      completedTasks
      homeworkFinishCount
      goalImprovementPercent
      masteredSkillsCount
      requiredSkillsCount
      goals {
        id
        goalPreskills {
          id
          skillId
          mastered
          name
          skill {
            id
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;
