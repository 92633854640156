import { Col, Row, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { findCatOrderIndexByCatId } from '../../../../../util/Category';
import { BackgroundColor } from '../../../../Client/WordTrackingRoute/styles';
import { ActivityModalContainer, ScrollContainer, SkillBadge } from '../../route/TasksRoute/ActivityModal/styles';
import { CategoryBadge } from '../../route/TasksRoute/styles';
import { PlanGoalObj, TherapistChildContext } from '../../TherapistChildContext';
import CollapseContent from './CollapseContent';

interface Props {
  show: boolean;
  setShow: (boolean: boolean) => void;
  goalData: PlanGoalObj;
  consultationId: string;
}
const GoalModal = ({ show, setShow, goalData, consultationId }: Props) => {
  const { t } = useTranslation();
  const { publishedPlan, goals } = useContext(TherapistChildContext);
  const history = useHistory();
  const { Title, Text } = Typography;
  const catOrderIndex = findCatOrderIndexByCatId(goalData.goal.skill.category.id);
  const planSequence = publishedPlan.filter((plan) => plan.id === consultationId)[0]?.planSequence;
  const goalSequence = goals.filter((goal) => goal.id === goalData.goal.id)[0]?.goalSequence;

  const onClose = () => {
    setShow(false);
    history.push(window.location.pathname);
  };

  return (
    <ActivityModalContainer
      width={1200}
      centered
      title={[
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px', alignItems: 'center' }}>
          {t('therapist.childInfo.sider.plan')} {planSequence} - {t('therapist.childInfo.goal.pageTitle')}{' '}
          {goalSequence} {t('therapist.childInfo.goal.goalModal.details')}
        </div>,
      ]}
      visible={show}
      footer={null}
      onOk={onClose}
      onCancel={onClose}
    >
      <Row style={{ height: '100%' }}>
        <Col flex="1 1 600px" style={{ height: '100%' }}>
          <ScrollContainer style={{ paddingRight: '24px', paddingLeft: '8px', paddingBottom: '50px' }}>
            <>
              <Space size="large" direction="vertical" style={{ width: '100%' }}>
                <div>
                  <Title level={5}>
                    {`${t('therapist.childInfo.activity.general.category')} - ${t(
                      'therapist.childInfo.activity.general.subcategory',
                    )} - ${t('therapist.childInfo.activity.general.mainSkill')}`}
                  </Title>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <CategoryBadge catIndex={catOrderIndex}>
                      <Text>{goalData.goal.skill.category.name}</Text>
                    </CategoryBadge>
                    - <Text>{goalData.goal.skill.subcategory.name}</Text> -
                    <SkillBadge BackgroundColor={BackgroundColor}>
                      <Text>{goalData.goal.skill.name}</Text>
                    </SkillBadge>
                  </div>
                </div>
                <div>
                  <Title level={5}>{t('therapist.childInfo.goal.goalTable.description')}</Title>
                  <Text>{goalData.goal.description}</Text>
                </div>

                <div>
                  <Title level={5}>{t('therapist.childInfo.goal.goalTable.preSkills')}</Title>
                  <div>
                    {goalData.goal.goalPreskills.map((preskill) => {
                      return (
                        <SkillBadge BackgroundColor={BackgroundColor}>
                          <Text>{preskill.name}</Text>
                        </SkillBadge>
                      );
                    })}
                  </div>
                </div>
              </Space>
            </>
          </ScrollContainer>
        </Col>
        <Col flex="1 1" style={{ height: '100%' }}>
          <ScrollContainer>
            <CollapseContent goalData={goalData} />
          </ScrollContainer>
        </Col>
      </Row>
    </ActivityModalContainer>
  );
};

export default GoalModal;
