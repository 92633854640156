import gql from 'graphql-tag';

export const UPDATE_DEVICE_NOTIFICATION_SHOWN_MUTATION = gql`
  mutation UpdateDeviceNotificationShown($deviceNotificationIds: [ID!]!) {
    updateDeviceNotificationShown(deviceNotificationIds: $deviceNotificationIds) {
      returnMessage {
        status
        message
      }
    }
  }
`;
