import styled from 'styled-components';

export const Title = styled.h2`
  margin-left: 40px;
  padding-left: 20px;
  border-left: 5px solid #5bd5ca;
`;

export const BackButton = styled.button`
  border-radius: 50%;
  background-color: white;
  color: black;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  margin: 2px;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 1fr;
  padding: 10px;
  gap: 10px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;

export const CardItem = styled.div`
  height: 100%;
`;

export const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 1fr;
  margin-left: 10px;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const CardInfoBoldText = styled.span`
  font-weight: bold;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 16px;
  padding: 16px 48px;
`

export const SearchBarIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-size: 1.5em;
`
