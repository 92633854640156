import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import Header from '../Header';
import { Container, SubmitButton, PasswordInput } from './styles';
import { RESET_PASSWORD } from '../../../graphql/mutations/resetPassword';
import { ResetPassword, ResetPasswordVariables } from '../../../graphql/mutations/types/ResetPassword';
import { ReturnMessage } from '../../../types';
import ReturnMessageComponent from '../ReturnMessageComponent';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface Props {
  userId: string;
  locale: string;
}

const ResetPasswordComponent = ({ userId, locale }: Props) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);

  const [resetPassword, { data }] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD);

  const { t } = useTranslation();
  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }

  const tPath = 'auth.resetPassword';
  // update message near header
  useEffect(() => {
    if (data && data.resetPassword) {
      setReturnMessage(data.resetPassword.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
        window.location.href = '/';
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const passwordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      let tmpReturnMessage: ReturnMessage = {
        status: '300',
        message: t(`${tPath}.passwordNotSame`),
      };
      setReturnMessage(tmpReturnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
      return;
    }

    await resetPassword({ variables: { userId, newPassword: password } });
  };

  return (
    <div>
      <Header />
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <h2 data-cy="firstTimeLoggedInTitle" style={{ textAlign: 'center' }}>
        {t(`${tPath}.firstTimeLoggedIn`)}
      </h2>
      <Container>
        <form onSubmit={(e) => handleSubmit(e)}>
          <label style={{ textAlign: 'left' }}>{t(`${tPath}.password`)}: </label>
          <br />
          <PasswordInput type="password" value={password} onChange={passwordChange} size={40} />
          <br />
          <br />
          <label>{t(`${tPath}.confirmPassword`)}: </label>
          <br />
          <PasswordInput
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            size={40}
          />
          <br />
          <br />
          <SubmitButton type="submit">{t(`${tPath}.submit`)}</SubmitButton>
        </form>
      </Container>
    </div>
  );
};

export default ResetPasswordComponent;
