import gql from 'graphql-tag';
import { Activity, ReturnMessage } from '../../types';

export const ADD_ACTIVITY_REJECT_MUTATION = gql`
  mutation AddActivityReject($activityId: ID!, $childId: ID!, $reason: String!) {
    addActivityReject(activityId: $activityId, childId: $childId, reason: $reason) {
      success
      activityReject {
        id
        activity {
          id
          name
        }
        child
        reason
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  addActivityReject: {
    activityReject: {
      id: number;
      activity: Activity;
      child: string;
      reason: string;
    };
    returnMessage: ReturnMessage;
    success: boolean;
  };
}

export interface Variables {
  activityId: number;
  childId: number;
  reason: string;
}
