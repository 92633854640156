import React from 'react';
import { getLastNote_getLastNote } from '../../../../../graphql/queries/types/getLastNote';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { NotesContainer } from '../styles';
import { SectionTitle } from '../../../../../style';
import { TitleFlexBox } from '../../../../common/SectionActionTitle/styles';

interface Props {
  notesData: getLastNote_getLastNote | null;
}

const ClientConsultationTherapistNotes = ({ notesData }: Props) => {
  const { t } = useTranslation();

  const lastNote =
    notesData && notesData.commentForParents !== '<p></p>' && notesData.commentForParents !== ''
      ? ReactHtmlParser(notesData.commentForParents)
      : t('client.task.planTab.noNote');

  return (
    <>
      <TitleFlexBox>
        <SectionTitle>{t('client.task.planTab.notesFromLastConsultation')}</SectionTitle>
      </TitleFlexBox>
      <NotesContainer>
        <p>{lastNote}</p>
      </NotesContainer>
    </>
  );
};

export default ClientConsultationTherapistNotes;
