import React from 'react';
import { Icon } from 'semantic-ui-react';
import { InsideCloseButtonContainer } from '../Client/DashboardRoute/styles';

interface Props {
  handleClose: () => void;
}
const ModalInsideCloseButton = ({ handleClose }: Props) => {
  return (
    <InsideCloseButtonContainer>
      <Icon className="close" size="large" link style={{ margin: '15px 0 15px 0' }} onClick={handleClose} />
    </InsideCloseButtonContainer>
  );
};

export default React.memo(ModalInsideCloseButton);
