import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
  Course_child,
  Course_child_course_links,
  Course_child_sessions,
} from '../../graphql/queries/types/Client/Course';
import { CLIENT_QUERY } from '../../graphql/queries/client';
import {
  Client,
  ClientVariables,
  Client_client,
  Client_client_children,
  Client_client_children_assignedActivities,
} from '../../graphql/queries/types/Client';
import { ReturnMessage } from '../../types';
import Header from '../common/Header';
import { Lite_product_qna_sessions, Lite_product_course_sections } from '../../graphql/queries/types/Client/Lite';
import { DEFAULT_ROUTE } from '../../util/Product';
import { GenderEnum } from '../../types/graphql-global-types';
import { getCatData, getCategory, getPlanCatData, SkillCategory } from '../../util/Category';
import ErrorPage from '../common/ErrorPage';
import { CentralisingDiv, NoChildContainer, NoChildEmail, NoChildLogo, NoChildSubtitle, NoChildTitle } from './styles';
import LogoOnly from '../../assets/Logo_Icon.png';

interface State {
  client: Client_client;
  child: Client_client_children;
  setChild: React.Dispatch<React.SetStateAction<Client_client_children>>;
  returnMessage?: ReturnMessage;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
  sessions: Lite_product_qna_sessions[];
  setSessions: React.Dispatch<React.SetStateAction<Lite_product_qna_sessions[]>>;
  courseSections: Lite_product_course_sections[];
  setCourseSections: React.Dispatch<React.SetStateAction<Lite_product_course_sections[]>>;
  userRead: Course_child | undefined;
  setUserRead: React.Dispatch<React.SetStateAction<Course_child | undefined>>;
  updateUserRead: (userRead: Course_child, updateCourse: boolean, updateSession: boolean) => void;
  defaultRoute: string;
  setDefaultRoute: React.Dispatch<React.SetStateAction<string>>;
  forceUpdateSessions: number;
  forceUpdateCourses: number;
  categories: SkillCategory[];
  planCategories: SkillCategory[];
  handleActivityCheckin: (activityIDs: string[]) => void;
}

const defaultClient = {
  id: '',
  user: {
    id: '',
    name: '',
    email: '',
    metaId: '',
    locale: '',
    activeChildId: '',
    country: '',
    smsNotification: false,
  },
  children: [],
  onBoardDocumentFinished: false,
  onBoardDocumentUrl: '',
  therapistAssistant: {
    id: '',
    user: {
      id: '',
      name: '',
    },
  },
  timeZone: '',
  requestBuyMore: false,
  consultationTimesRemain: 0,
  referral: '',
  membershipDue: '',
  welcomeFinished: false,
};

const defaultChild: Client_client_children = {
  id: '',
  name: '',
  dob: '',
  gender: GenderEnum.male,
  completedTasks: 0,
  consentFormCompleted: false,
  therapist: {
    id: '',
    avatarFilename: '',
    title: '',
    therapistBios: [],
    user: {
      id: '',
      name: '',
      email: '',
    },
    consultationUrl: null,
    cnConsultationUrl: null,
    pictureUrl: null,
  },
  consultations: [],
  product: {
    id: '',
    name: '',
    description: '',
    owner: '',
    productTypesName: '',
  },
  goals: [],
  room: {
    id: '',
  },
  assistantRoom: {
    id: '',
  },
  pictureUrl: null,
  childSixMonthGoal: null,
  nextConsultation: null,
  assignedActivities: [],
  lastAssignedActivities: [],
  activityPlan: [],
  latestConsultation: {
    createdAt: '',
  },
  startServiceAt: '',
  currentWeek: 1,
  currentCourse: 1,
};

const defaultClientContext: State = {
  client: defaultClient,
  child: defaultChild,
  setChild: () => {},
  sessions: [],
  setSessions: () => {},
  courseSections: [],
  setCourseSections: () => {},
  setReturnMessage: () => {},
  userRead: {
    id: '',
    qnaSessions: [],
    courseLinks: [],
  },
  handleActivityCheckin: () => {},
  setUserRead: () => {},
  updateUserRead: () => {},
  defaultRoute: '',
  setDefaultRoute: () => {},
  forceUpdateSessions: 0,
  forceUpdateCourses: 0,
  categories: [],
  planCategories: [],
};

export const ClientContext = React.createContext(defaultClientContext);

export const ClientContextProvider = ({ clientId, locale, children }) => {
  const { t } = useTranslation();
  const [child, setChild] = useState<Client_client_children>(defaultChild);
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);
  const [sessions, setSessions] = useState<Lite_product_qna_sessions[]>([]);
  const [courseSections, setCourseSections] = useState<Lite_product_course_sections[]>([]);
  const [userRead, setUserRead] = useState<Course_child | undefined>(undefined);
  const [defaultRoute, setDefaultRoute] = useState<string>('dashboard');
  const [forceUpdateSessions, setForceUpdateSessions] = useState<number>(0);
  const [forceUpdateCourses, setForceUpdateCourses] = useState<number>(0);

  const skills = useMemo(() => {
    return child.goals.map((g) => g.goalPreskills).flat();
  }, [child]);

  const skillTrainedByAllActivities = useMemo(() => {
    let skillTrainedByAcDict: { [name: number]: number } = {};
    child.assignedActivities.forEach((ac) => {
      if (ac.mainSkill.id in skillTrainedByAcDict) {
        skillTrainedByAcDict[ac.mainSkill.id] += ac.checkInCount;
      } else {
        skillTrainedByAcDict[ac.mainSkill.id] = ac.checkInCount;
      }
      ac.skills.forEach((acs) => {
        if (acs.id in skillTrainedByAcDict) {
          skillTrainedByAcDict[acs.id] += ac.checkInCount;
        } else {
          skillTrainedByAcDict[acs.id] = ac.checkInCount;
        }
      });
    });
    return skillTrainedByAcDict;
  }, [child]);

  const skillTrainedByLastActivities = useMemo(() => {
    let skillTrainedByAcDict: { [name: number]: number } = {};
    child.lastAssignedActivities.forEach((ac) => {
      if (ac.mainSkill.id in skillTrainedByAcDict) {
        skillTrainedByAcDict[ac.mainSkill.id] += ac.checkInCount;
      } else {
        skillTrainedByAcDict[ac.mainSkill.id] = ac.checkInCount;
      }
      ac.skills.forEach((acs) => {
        if (acs.id in skillTrainedByAcDict) {
          skillTrainedByAcDict[acs.id] += ac.checkInCount;
        } else {
          skillTrainedByAcDict[acs.id] = ac.checkInCount;
        }
      });
    });
    return skillTrainedByAcDict;
  }, [child]);

  const categories = useMemo(
    () => getCategory(getCatData(skills, skillTrainedByAllActivities)),
    [skillTrainedByAllActivities, skills],
  );

  const removeDuplicates = useMemo(() => {
    const latestPlanMainSkill = child.lastAssignedActivities.map(
      (ac: Client_client_children_assignedActivities) => ac.mainSkill,
    );
    const latestPlanSkill = child.lastAssignedActivities
      .map((ac: Client_client_children_assignedActivities) => ac.skills.map((sk) => sk))
      .flat();
    return [...new Set([...latestPlanMainSkill, ...latestPlanSkill])];
  }, [child.lastAssignedActivities]);

  const planCategories = useMemo(
    () => getCategory(getPlanCatData(removeDuplicates, skillTrainedByLastActivities)),
    [skillTrainedByLastActivities, removeDuplicates],
  );

  const { loading, error, data } = useQuery<Client, ClientVariables>(CLIENT_QUERY, {
    variables: { clientId },
  });

  useEffect(() => {
    if (data && data.client && data.client.children.length > 0) {
      const activeChild =
        data.client.user.activeChildId !== null
          ? data.client.children.filter((child) => child.id === data.client?.user.activeChildId.toString())[0]
          : data.client.children[0];

      setChild(activeChild);
      setDefaultRoute(DEFAULT_ROUTE(activeChild.product ? activeChild.product.name : ''));
    }
  }, [data]); // <-- only update when data is changed

  useEffect(() => {
    if (userRead) {
      resetCourses(userRead.courseLinks);
      resetSessions(userRead.qnaSessions);
    }
  }, [userRead]);

  const updateUserRead = (userRead: Course_child, updateCourse: boolean, updateSession: boolean) => {
    setUserRead(userRead);
    if (updateCourse) {
      resetCourses(userRead.courseLinks);
    }
    if (updateSession) {
      resetSessions(userRead.qnaSessions);
    }
  };

  const resetSessions = (qnaSessions: Course_child_sessions[]) => {
    const productData: Lite_product_qna_sessions[] = sessions;
    if (sessions.length === 0) return;
    const userData: Course_child_sessions[] = qnaSessions;
    userData.forEach((u) => {
      const p = productData.find((p) => p.id === u.id);
      if (p) {
        p.read = u.read;
      }
    });
    setSessions(productData);
    setForceUpdateSessions(forceUpdateSessions + 1);
  };

  const resetCourses = (courseLinks: Course_child_course_links[]) => {
    const productData: Lite_product_course_sections[] = courseSections;
    if (courseSections.length === 0) return;
    const userData: Course_child_course_links[] = courseLinks.sort((a, b) => {
      return Number(a.id) - Number(b.id);
    });
    let index = 0;
    const courseLen = productData.length;
    userData.forEach((u) => {
      while (index < courseLen) {
        const p = productData[index].courseLinks.find((cl) => cl.id === u.id);
        if (p) {
          p.read = u.read;
          break;
        } else {
          index++;
        }
      }
    });
    setCourseSections(productData);
    setForceUpdateCourses(forceUpdateCourses + 1);
  };

  const handleActivityCheckin = useMemo(
    () => (activityIDs: Array<string>) => {
      const lastActivities = child.lastAssignedActivities;
      if (lastActivities.length > 0) {
        let newActivities = [...lastActivities];
        activityIDs.forEach((activity, acIndex) => {
          const index = lastActivities.findIndex(
            (object: Client_client_children_assignedActivities) => object.id === activity,
          );
          newActivities[index].done = true;
          newActivities[index].checkInCountDaily = [...newActivities[index].checkInCountDaily];
          newActivities[index].checkInCountDaily[0] += 1;
          newActivities[index].checkInCount += 1;
        });
        child.lastAssignedActivities = newActivities;
      }
      const allActivities = child.assignedActivities;
      if (allActivities.length > 0) {
        let newActivities = [...allActivities];
        activityIDs.forEach((activity, acIndex) => {
          const index = allActivities.findIndex(
            (object: Client_client_children_assignedActivities) => object.id === activity,
          );
          newActivities[index].done = true;
          newActivities[index].checkInCountDaily = [...newActivities[index].checkInCountDaily];
          newActivities[index].checkInCountDaily[0] += 1;
          newActivities[index].checkInCount += 1;
        });
        child.assignedActivities = allActivities;
      }
      setChild({ ...child });
    },
    [child],
  );

  // render
  if (loading) {
    return <Loader active>{t('system.loading')}</Loader>;
  }
  if (error || !data || !data.client) {
    return <ErrorPage />;
  }
  const { client } = data;
  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }
  if (child.id === '') {
    return (
      <div>
        <Header />
        <NoChildContainer>
          <CentralisingDiv>
            <NoChildLogo src={LogoOnly}></NoChildLogo>
            <NoChildTitle>{t('system.noChildTitle')}</NoChildTitle>
            <NoChildSubtitle>{t('system.noChildSubtitle2')}</NoChildSubtitle>
            <NoChildSubtitle>
              {t('system.noChildSubtitle')}
              <NoChildEmail href={`mailto: ${t('system.noChildEmail')}`}>{t('system.noChildEmail')}</NoChildEmail>
              {t('system.noChildSubtitle3')}
            </NoChildSubtitle>
          </CentralisingDiv>
        </NoChildContainer>
      </div>
    );
  }

  return (
    <ClientContext.Provider
      value={{
        client,
        child,
        setChild,
        returnMessage,
        setReturnMessage,
        sessions,
        setSessions,
        courseSections,
        setCourseSections,
        userRead,
        setUserRead,
        updateUserRead,
        defaultRoute,
        setDefaultRoute,
        forceUpdateSessions,
        forceUpdateCourses,
        categories,
        planCategories,
        handleActivityCheckin,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
