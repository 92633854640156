import React from 'react';
import { useHistory } from 'react-router';
import {
  GuidelineTextSection,
  GuidelineText,
  GuidelineBoldText,
  GuidelineImportantText,
  GuideLineModifyingText,
} from '../styles';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';

const Guidelines = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/profile')} />
      <h2 style={{ textAlign: 'center' }}>{t('utils.guidelines.title')}</h2>
      <GuidelineTextSection>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.consultationTimeSlot')}: </GuidelineBoldText>
          {t('utils.guidelines.consultationTimeSlotText')}
        </GuidelineText>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.confirmation')}: </GuidelineBoldText>
          {t('utils.guidelines.confirmationText')}
        </GuidelineText>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.notifications')}: </GuidelineBoldText>
          {t('utils.guidelines.notificationsText')}
          <GuidelineImportantText>{t('utils.guidelines.notificationsImportantText')}</GuidelineImportantText>
        </GuidelineText>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.latePolicy')}: </GuidelineBoldText>
          {t('utils.guidelines.latePolicyText')}
          <GuidelineImportantText>{t('utils.guidelines.latePolicyImportantText')}</GuidelineImportantText>
        </GuidelineText>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.modifying')}: </GuidelineBoldText>
          <ul>
            <li>
              <GuideLineModifyingText>{t('utils.guidelines.modifyingPoint1')}: </GuideLineModifyingText>
              {t('utils.guidelines.modifyingPoint1Text')}
            </li>
            <li>
              <GuideLineModifyingText>{t('utils.guidelines.modifyingPoint2')}: </GuideLineModifyingText>
              {t('utils.guidelines.modifyingPoint2Text')}
            </li>
          </ul>
        </GuidelineText>
        <GuidelineText>
          <GuidelineBoldText>{t('utils.guidelines.contactPolicy')}: </GuidelineBoldText>
          {t('utils.guidelines.contactPolicyText')}
        </GuidelineText>
      </GuidelineTextSection>
    </RouteSectionContainer>
  );
};

export default Guidelines;
