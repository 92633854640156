import styled from 'styled-components';
import { BackgroundColor, MainColor } from '../../../../WordTrackingRoute/styles';

export const ActivityMainSkill = styled.div`
  background-color: ${BackgroundColor};
  border-radius: 15px;
  min-width: max-content;
  max-width: min-content;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${MainColor};
  margin: 0 10px 10px 0;
`;

export const CheckInContainer = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 20px;
  border-radius: 8px;
`;
export const CheckInIndicator = styled.div<{ status: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 5px;
  font-size: 12px;
  background-color: ${(props) => (props.status ? '#5ABAAE' : '#E8E9E9')};
  color: white;
  width: 20px;
  height: 20px;
`;

export const CheckInCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20px;
  margin-right: 15px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;
