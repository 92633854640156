import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientCard from './components/ClientCard';
import { CardGrid, CardItem, Title } from './styles';
import { useHistory } from 'react-router';
import { useQuery } from 'react-apollo';
import {
  TherapistChildren as Data,
  TherapistChildrenVariables as Variables,
} from '../../../graphql/queries/types/TherapistChildren';
import { THERAPIST_CHILDREN_QUERY } from '../../../graphql/queries/therapistChildren';
import { GeneralSegment } from '../../../style';
import ErrorPage from '../../common/ErrorPage';
import Switch from 'antd/lib/switch';
import SearchBar from './components/SearchBar';
import { filterChildrenData } from './helper';

interface Props {
  therapistId: string;
}

const MyClientsComponent = ({ therapistId }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterActiveOnlyPEA, setFilterActiveOnlyPEA] = useState(true);
  const [filterActiveOnlyCPC, setFilterActiveOnlyCPC] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const { loading, error, data } = useQuery<Data, Variables>(THERAPIST_CHILDREN_QUERY, {
    variables: { therapistId },
    fetchPolicy: 'cache-first',
  });

  const onChangeFilterPEA = (checked: boolean) => setFilterActiveOnlyPEA(checked);
  const onChangeFilterCPC = (checked: boolean) => setFilterActiveOnlyCPC(checked);
  const handleClickCard = (childId: string) => history.push(`/child/${childId}`);

  const getChildrenData = (productName) => {
    if (data && data.therapistChildren) {
      const childrenData = filterChildrenData(
        data.therapistChildren,
        productName,
        filterActiveOnlyPEA,
        filterActiveOnlyCPC,
      );
      return searchInput ? childrenData.filter((child) => child.id.includes(searchInput)) : childrenData;
    }
    return [];
  };

  const renderChildren = (productName, filterActiveOnly, onChangeFilter) => {
    const childrenData = getChildrenData(productName);

    return (
      <>
        <Title data-cy="patientInfo">
          {t(`therapist.dashboard.myClient.patient${productName.toUpperCase()}Info`)}
          <Switch
            checkedChildren="Ongoing"
            unCheckedChildren="All"
            defaultChecked={filterActiveOnly}
            onChange={onChangeFilter}
            style={{ display: 'inline-block', marginLeft: '10px' }}
          />
        </Title>
        {childrenData.length > 0 ? (
          <CardGrid>
            {childrenData.map((child) => (
              <CardItem key={child.id} onClick={() => handleClickCard(child.id)}>
                <ClientCard child={child} />
              </CardItem>
            ))}
          </CardGrid>
        ) : null}
      </>
    );
  };

  if (loading) return <p>{t('system.loading')}</p>;
  if (error || !data || !data.therapistChildren) return <ErrorPage />;

  return (
    <GeneralSegment>
      <SearchBar setSearchInput={setSearchInput} />
      {renderChildren('cpc', filterActiveOnlyCPC, onChangeFilterCPC)}
      {renderChildren('pea', filterActiveOnlyPEA, onChangeFilterPEA)}
    </GeneralSegment>
  );
};

export default MyClientsComponent;
