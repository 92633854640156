import React, { useContext } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ConsultationsDateObj, TherapistChildContext } from '../TherapistChildContext';
import { useHistory } from 'react-router-dom';

interface Props {
  consultation: ConsultationsDateObj;
  showTitle: boolean;
}

const CreatePlanButton = ({ consultation, showTitle }: Props) => {
  const { t } = useTranslation();
  const { publishedPlan } = useContext(TherapistChildContext);
  const consultationId = consultation.id;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/consultation/${consultationId}`);
  };

  const disableClick = (consultation: ConsultationsDateObj) => {
    // allowed to create/edit a consultation when it is not yet published or it is the latest published
    if (!consultation.published || publishedPlan[0].id === consultation.id) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Button
      disabled={disableClick(consultation)}
      type="default"
      shape="round"
      icon={<EditOutlined translate={undefined} />}
      onClick={handleClick}
    >
      {showTitle && (
        <>
          {consultation.published
            ? t('therapist.childInfo.general.button.edit')
            : t('therapist.childInfo.general.button.create')}
        </>
      )}
    </Button>
  );
};

export default CreatePlanButton;
