import gql from 'graphql-tag';

export const UPDATE_QNA_SESSION_READ_MUTATION = gql`
  mutation UpdateQnaSessionRead($qnaSessionId: ID!, $childId: ID!) {
    updateQnaSessionRead(qnaSessionId: $qnaSessionId, childId: $childId) {
      returnMessage {
        status
        message
      }
    }
  }
`;
