import React, { useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { Description } from '../../../../style';
import {
  BlogContainer,
  BlogThumbnail,
  DesContainer,
  PointDivider,
  TitleContainer,
  BlogTitle,
  RecommendedWord,
  RecommendedPointDivider,
} from './style';
import Star from './assets/star.svg';
import { ContentType } from './utils';
import UserContext from '../../../common/UserContext';

interface Props {
  content: ContentType[];
}

const ContentComponent = ({ content }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const locale = user.user.locale;
  const handleOpenBlog = (blog: ContentType) => {
    window.open(blog.contentUrl);
    mixpanel.track('LibraryBlog', {
      option: `${blog.title}`,
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  return (
    <>
      {locale == 'en' ? (
        <div style={{ marginTop: '-15px', marginBottom: '15px', color: 'black', fontSize: '14px', fontWeight: 'bold' }}>
          We have curated quality self-learning content from trustworthy sources for your benefit. Please let us know if
          you have others resources to recommend
        </div>
      ) : null}

      {content.map((blog) => {
        return (
          <BlogContainer>
            <TitleContainer>
              <BlogTitle onClick={() => handleOpenBlog(blog)}>{blog.title}</BlogTitle>
              <DesContainer>
                <Description>{formatDate(blog.publishedDate)}</Description>
                <PointDivider>·</PointDivider>
                <Description>{blog.readTime}</Description>
                <Description style={{ whiteSpace: 'break-spaces' }}>{t('client.library.blog.readTime')}</Description>
                <RecommendedPointDivider>·</RecommendedPointDivider>
                <RecommendedWord>{t('client.library.blog.selective')}</RecommendedWord>
                <img src={Star} style={{ marginLeft: '5px' }} />
              </DesContainer>
            </TitleContainer>
            <BlogThumbnail src={blog.mediaUrl} onClick={() => handleOpenBlog(blog)} />
          </BlogContainer>
        );
      })}
    </>
  );
};
export default ContentComponent;
