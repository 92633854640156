import React from 'react';
import { DropdownContent } from './styles';
import { GetNotifications_getNotifications } from '../../../../graphql/queries/types/GetNotifications';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { NotificationTitle, NotificationContainer, NotificationText, NotificationIcon } from './styles';
interface Props {
  notifications: GetNotifications_getNotifications[];
}

const NotificationDropdown = ({ notifications }: Props) => {
  const { t } = useTranslation();
  if (notifications.length === 0) {
    return <DropdownContent>{t('navBar.notification.noNotification')}</DropdownContent>;
  }
  return (
    <DropdownContent>
      <NotificationTitle>{t('navBar.notification.notificationTitle')}</NotificationTitle>
      {notifications.map((notification: GetNotifications_getNotifications) => (
        <NotificationContainer key={notification.id}>
          <NotificationIcon>
            <Icon
              name="calendar check outline"
              size="large"
              style={{
                verticalAlign: 'top',
                margin: '0px',
                backgroundColor: 'rgba(71, 228, 194, 0.5)',
                height: '45px',
                width: '45px',
                borderRadius: '25px',
                padding: '12px',
                color: 'white',
              }}
            />
          </NotificationIcon>
          <NotificationText>{notification.body}</NotificationText>
        </NotificationContainer>
      ))}
    </DropdownContent>
  );
};

export default NotificationDropdown;
