import React, { useState, useEffect } from 'react';
import { RadioButton, RadioButtonContainer, RadioButtonLabel, RadioButtonsContainer, RadioTextBox } from './styles';
import { useTranslation } from 'react-i18next';

const RadioButtons = (props) => {
  const [ratingReason, setRatingReason] = useState('');
  const [ratingReasonRemarks, setRatingReasonRemarks] = useState('');
  const [openHighRatingReason, setopenHighRatingReason] = useState(false);
  const { t } = useTranslation();

  const handleOptionChange = (event) => {
    setRatingReason(event.target.value);
    setRatingReasonRemarks('');
  };

  //Updates the remarks state whenever there is a change in text
  const handleRemarksChange = (event) => {
    setRatingReasonRemarks(event.target.value);
  };

  //If rating is more than 4 then no need to show the reasons but provide them a way to give feedback about why its so good
  useEffect(() => {
    if (props.rating >= 4) {
      setopenHighRatingReason(true);
    }
  }, []);

  //Update the data being sent up everytime one of them changes
  useEffect(() => {
    props.onData({ ratingReason, ratingReasonRemarks });
  }, [ratingReason, ratingReasonRemarks]);

  const radioButtonItems = props.didNotComplete
    ? [
        // Did not complete
        {
          id: 1,
          title: t('feedback.tooEasy'),
          value: 'Too Easy',
          group: 'Options',
        },
        {
          id: 2,
          title: t('feedback.tooHard'),
          value: 'Too Hard',
          group: 'Options',
        },
        {
          id: 3,
          title: t('feedback.unclear'),
          value: 'Instructions Unclear',
          group: 'Options',
        },
        {
          id: 4,
          title: t('feedback.noMaterials'),
          value: 'No Materials',
          group: 'Options',
        },
        {
          id: 5,
          title: t('feedback.noTime'),
          value: 'No Time',
          group: 'Options',
        },
        {
          id: 6,
          title: t('feedback.other'),
          value: 'Other',
          group: 'Options',
        },
      ]
    : [
        //1-3 stars reasons
        {
          id: 1,
          title: t('feedback.tooEasy'),
          value: 'Too Easy',
          group: 'Options',
        },
        {
          id: 2,
          title: t('feedback.tooHard'),
          value: 'Too Hard',
          group: 'Options',
        },
        {
          id: 3,
          title: t('feedback.unclear'),
          value: 'Instructions Unclear',
          group: 'Options',
        },
        {
          id: 4,
          title: t('feedback.notInterested'),
          value: 'Child Not Interested',
          group: 'Options',
        },
        {
          id: 5,
          title: t('feedback.other'),
          value: 'Other',
          group: 'Options',
        },
      ];
  return (
    <>
      <RadioButtonsContainer>
        {!openHighRatingReason ? (
          <>
            {radioButtonItems.map((item) => (
              <RadioButtonContainer key={item.id}>
                <RadioButtonLabel>
                  <RadioButton type="radio" value={item.value} onChange={handleOptionChange} name={item.group} />
                  {item.title}
                </RadioButtonLabel>
                <RadioTextBox
                  style={{ display: ratingReason === item.value ? '' : 'none' }}
                  placeholder={t('feedback.reasonModalDetails')}
                  onChange={handleRemarksChange}
                />
              </RadioButtonContainer>
            ))}
          </>
        ) : (
          <>
            <RadioTextBox
              style={{ width: '220px' }}
              placeholder={t('feedback.reasonModalDetails')}
              onChange={handleRemarksChange}
            />
          </>
        )}
      </RadioButtonsContainer>
    </>
  );
};

export default RadioButtons;
