import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { SmallActionTitle, SmallDescription } from '../../../style';
import { MainColor } from '../WordTrackingRoute/styles';

export const InformationCourseInfoLinkButton = styled.div<{
  availableTime: boolean;
  readStatus: boolean;
  selected: boolean;
}>`
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  cursor: pointer;
  // pointer-events: ${(props) => (props.availableTime ? 'cursor' : 'none')};
  background-color: ${({ selected }) => (selected ? 'rgb(209, 215, 220)' : 'white')};
  :hover {
    background-color: rgb(209, 215, 220);
  }
`;

export const CollapsibleHeaderText = styled.div``;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
`;

// course link time length
export const ReadTimeContainer = styled.div<{ selected: boolean }>`
  display: flex;
  color: ${({ selected }) => (selected ? 'black' : '#606060')};
  align-items: center;
  @media (hover: hover) and (pointer: fine) {
    ${InformationCourseInfoLinkButton}:hover & {
      color: black;
    }
  }
`;
export const ReadTimeText = styled(SmallDescription)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? 'black' : '#606060')};
  @media (hover: hover) and (pointer: fine) {
    ${InformationCourseInfoLinkButton}:hover & {
      color: black;
    }
  }
`;
export const PlayIcon = styled(Icon)`
  font-size: 1.4rem !important;
  margin: 0 0.4rem 5px 0 !important;
`;

export const FileIcon = styled(Icon)`
  font-size: 1.3rem !important;
  margin: 0 0.4rem 5px 0 !important;
`;

// course link action button
export const ActionContainer = styled.div<{ selected: boolean }>`
  height: 28px;
  color: #1c1d1f;
  padding: 0 8px;
  border: 1px solid #1c1d1f;
  width: max-content;
  display: flex;
  align-items: center;
  pointer-events: ${({ selected }) => (selected ? 'default' : 'none')};
  cursor: ${({ selected }) => (selected ? 'pointer' : 'not-allowed')};
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
export const ActionText = styled(SmallActionTitle)`
  color: #1c1d1f;
`;
export const KeyIcon = styled(Icon)`
  font-size: 1.4rem !important;
  margin: 0 0.4rem 5px 0 !important;
`;

// modal password container
export const PasswordActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  margin: 16px 10px 16px 0;
`;
export const CloneIcon = styled(Icon)`
  font-size: 1.4rem !important;
  margin: 0 1rem 5px 0 !important;
`;
export const ExampleGif = styled.img`
  border-radius: 8px;
  margin: 16px 0;
  object-fit: contain;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
`;

// password modal
export const ModalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
`;
export const PasswordModalMask = styled.div`
  background-color: rgba(244, 244, 244, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 50;
`;
export const PasswordModalContainer = styled.div`
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  background-color: white;
  border-radius: 8px;
  height: max-content;
  width: 100%;
  position: relative;
  padding: 0 0 16px;
  @media only screen and (min-width: 768px) {
    width: max-content;
    min-width: 500px;
  }
  @media only screen and (min-width: 992px) {
    width: max-content;
    min-width: 500px;
  }
  z-index: 999;
`;
export const PasswordModalHeaderContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PasswordModalContentContainer = styled.div`
  padding: 16px 16px 0;
  text-align: left;
`;
export const PasswordModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0;
`;
export const PasswordModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${MainColor};
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;
