import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { GeneralSegment, SmallActionTitle } from '../../../../../style';
import {
  HeaderRowContainer,
  RowContainer,
  StickyColumnContainer,
  StickyHeaderColumnContainer,
} from '../../../../Client/WordTrackingRoute/WordsTable/styles';
import { FixedTopHeight, RoutePadding } from '../../layout/styles';
import { SelectedBackgroundColor, SelectedColor } from './components/TableDropdown/styles';

export const NotionYellowLight = '#fbf3da';
export const NotionRedLight = '#fbe4e3';
export const NotionPurpleLight = '#eae3f2';
export const NotionPinkLight = '#fadfeb';
export const NotionOrangeLight = '#faebdd';
export const NotionGreenLight = '#ddedea';
export const NotionGrayLight = '#ebeced';
export const NotionBrownLight = '#e5e1df';
export const NotionBlueLight = '#DDEBF1';
export const NotionBlueRegular = '#0c6e99';
export const NotionPurpleRegular = '#693fa5';

const CategoryColor = [
  NotionRedLight,
  NotionOrangeLight,
  NotionYellowLight,
  NotionGreenLight,
  NotionBlueLight,
  NotionPinkLight,
  NotionPurpleLight,
];

const SmallScreenGridTemplateColums = '3fr 3fr 3fr 3fr 6fr 6fr 6fr';
const BigScreenGridTemplateColums = '3fr 1.5fr 1.5fr 1.5fr 3fr 3fr 3fr';

export const ToolBoxContainer = styled.div`
  position: sticky;
  margin: 0 ${RoutePadding}px;
  top: ${FixedTopHeight}px;
  background-color: white;
  z-index: 300;
`;

export const ToolboxActionContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  flex-direction: row-reverse;
`;

export const ActivityRowContainer = styled(RowContainer)`
  grid-template-columns: ${SmallScreenGridTemplateColums};
  min-width: 1400px;
  @media only screen and (min-width: 768px) {
    grid-template-columns: ${BigScreenGridTemplateColums};
  }
`;

export const ActivityHeaderRowContainer = styled(HeaderRowContainer)<{ group: boolean }>`
  border-bottom: ${({ group }) => (group ? 'none' : '1px solid #e4e4e4')};
  min-width: 1400px;
  grid-template-columns: ${SmallScreenGridTemplateColums};
  @media only screen and (min-width: 768px) {
    grid-template-columns: ${BigScreenGridTemplateColums};
  }
`;

export const ActivityStickyColumnContainer = styled(StickyColumnContainer)`
  grid-template-columns: 1fr;
`;

export const ActivityStickyHeaderColumnContainer = styled(StickyHeaderColumnContainer)<{ group: boolean }>`
  border-bottom: ${({ group }) => (group ? 'none' : '1px solid #e4e4e4')};
  grid-template-columns: 1fr;
  background: ${({ group }) => (group ? 'rgba(255, 255, 255, 0.9)' : 'rgba(250,251,252, 0.9)')};
  @media only screen and (min-width: 768px) {
    background: ${({ group }) => (group ? 'rgba(255, 255, 255, 0.9)' : 'rgba(250,251,252, 0.9)')};
  }
`;

export const HeaderStickyContainer = styled.div<{ inView: boolean; stickyHeight: number }>`
  position: ${({ inView }) => (inView ? 'sticky' : 'inherit')};
  top: calc(${({ stickyHeight }) => `${stickyHeight}px + ${FixedTopHeight}px`});
  z-index: 200;
`;

export const SectionTitleStickyContainer = styled.div<{ inView: boolean; stickyHeight: number }>`
  position: ${({ inView }) => (inView ? 'sticky' : 'inherit')};
  top: calc(${({ stickyHeight }) => `${stickyHeight}px + ${FixedTopHeight}px`});
  display: flex;
  z-index: 200;
  height: fit-content;
  padding: 10px 10px 10px 0;
  margin-left: -18px;
  background: white;
  @media only screen and (min-width: 768px) {
    background: #fafbfc;
  }
`;

export const TableHeaderStickyContainer = styled.div<{ group: boolean; stickyHeight: number }>`
  background-color: ${({ group }) => (group ? 'rgb(255, 255, 255)' : 'rgb(250,251,252)')};
  box-shadow: ${({ group }) => (group ? '0 0 10px rgb(0 0 0 / 5%)' : 'none')};
  position: sticky;
  top: calc(${({ stickyHeight }) => `${stickyHeight}px + ${FixedTopHeight}px`});
  z-index: 200;
`;

export const CategoryBadge = styled.div<{ catIndex: number }>`
  width: max-content;
  padding: 2px 7px;
  border-radius: 4px;
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ catIndex }) => CategoryColor[catIndex]};
`;

export const SectionListCountContainer = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

export const EllipsisData = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
`;

export const SycnScrollContainer = styled.div`
  border-radius: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const EmptySectionContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  background: rgb(250, 251, 252);
  @media only screen and (min-width: 768px) {
    background: rgb(255, 255, 255);
  }
`;

export const HideContainer = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

export const SectionDivider = styled.div<{ hide: boolean }>`
  height: ${({ hide }) => (hide ? '10px' : '50px')};
  border-bottom: ${({ hide }) => (hide ? '1px solid #e4e4e4' : 'none')};
  margin-bottom: ${({ hide }) => (hide ? '10px' : '0')};
`;

export const ArrowContainer = styled.div<{ hide: boolean }>`
  cursor: pointer;
  transform: ${({ hide }) => (hide ? 'rotate(270deg)' : 'rotate(360deg)')};
  transition: transform 0.2s ease-in-out;
  margin: 0 2px 0 0;
`;
export const Arrow = styled(Icon)`
  align-self: center;
  margin: 0 !important;
`;

export const MetricsGridContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin: 40px 0 40px 0;
`;

export const MetricsContainer = styled(GeneralSegment)`
  height: 100%;
  width: 100%;
  min-width: 150px;
  min-height: 120px;
  background-size: 40%;
  background-position: 8% 85%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
`;

export const FilterGridContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

export const FilterButton = styled.div<{ toggle: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 3px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 5px 5px;
  background: ${({ toggle }) => (toggle ? SelectedBackgroundColor : 'white')};
  border: ${({ toggle }) => (toggle ? `solid 1px ${SelectedColor}` : 'solid 1px rgba(0, 0, 0, 0.1)')};
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;

export const FilterButtonText = styled(SmallActionTitle)<{ toggle: boolean }>`
  color: ${({ toggle }) => (toggle ? SelectedColor : '#606060')};
`;

// textarea
export const TextareaContainer = styled.div`
  min-height: 48px;
  position: relative;
  vertical-align: top;
  width: 100%;
  padding: 0 0 10px 0;
`;
export const Textarea = styled.div`
  :: placeholder {
    color: ${(props: { isEmpty: boolean }) => (props.isEmpty ? 'red' : '#70757a')};
  }
  height: auto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  color: #202124;
  background-color: transparent;
  border: none;
  outline: none;
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  resize: none;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
