import React, { useState, useEffect } from 'react';
import { Card, Dropdown, DropdownProps } from 'semantic-ui-react';
import TimelineCard from './TimelineCard';
import { useMediaQuery } from 'react-responsive';
import { Therapist_therapist_consultations } from '../../../../graphql/queries/types/Therapist';
import { TimelineComponent } from '../styles';
import { useTranslation } from 'react-i18next';

interface Props {
  consultations: Therapist_therapist_consultations[];
  title: string;
  therapistId: string;
}

const TaskList = ({ consultations, title, therapistId }: Props) => {
  const [displayConsultations, setDisplayConsultations] = useState<Therapist_therapist_consultations[]>(consultations);

  // update display consultations when consultations prop changes
  useEffect(() => {
    setDisplayConsultations(
      consultations.filter(
        (consultation) =>
          (consultation.accepted === false || consultation.published === false) && consultation.canceled === false,
      ),
    );
  }, [consultations]);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  const handleDropdown = (filter: string) => {
    switch (filter) {
      case 'All':
        return setDisplayConsultations(consultations);
      case 'Todo':
        return setDisplayConsultations(
          consultations.filter(
            (consultation) =>
              (consultation.accepted === false || consultation.published === false) && consultation.canceled === false,
          ),
        );
      case 'Completed':
        return setDisplayConsultations(consultations.filter((consultation) => consultation.published === true));
      case 'Canceled':
        return setDisplayConsultations(consultations.filter((consultation) => consultation.canceled === true));
      default:
        return setDisplayConsultations(consultations);
    }
  };

  const options = [
    { key: 'Todo', text: 'To-Do', value: 'Todo', content: <p style={{ fontSize: '10px' }}>To-Do</p> },
    {
      key: 'Completed',
      text: 'Completed',
      value: 'Completed',
      content: <p style={{ fontSize: '10px' }}>Completed</p>,
    },
    { key: 'Canceled', text: 'Canceled', value: 'Canceled', content: <p style={{ fontSize: '10px' }}>Canceled</p> },
    { key: 'All', text: 'All', value: 'All', content: <p style={{ fontSize: '10px' }}>All</p> },
  ];

  const renderDropdown = () => {
    return (
      <Dropdown
        defaultValue={options[0].value}
        style={{ fontSize: '14px' }}
        selection
        compact
        options={options}
        onChange={(_e, data: DropdownProps) => handleDropdown(String(data.value))}
      />
    );
  };

  const { t } = useTranslation();

  return (
    <div>
      {isDesktopOrLaptop && (
        <Card fluid style={{ padding: '40px' }}>
          <Card.Content>
            <Card.Header>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <h1 data-cy="consultationsTitle" style={{ borderLeft: '5px solid #5dd9c4', paddingLeft: '20px' }}>
                    {title}
                  </h1>
                </div>
                <div>{renderDropdown()}</div>
              </div>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            {consultations.length == 0 ? (
              <h2 data-cy="noConsultations">{t('therapist.dashboard.myTasks.noConsultations')}</h2>
            ) : (
              <TimelineComponent>
                {displayConsultations.map((consultation) => (
                  <TimelineCard
                    key={consultation.id}
                    id={consultation.id}
                    consultation={consultation}
                    therapistId={therapistId}
                  />
                ))}
              </TimelineComponent>
            )}
          </Card.Content>
        </Card>
      )}
      {isTabletOrMobileDevice && (
        <Card fluid style={{ padding: '40px' }}>
          <Card.Content>
            <Card.Header>
              <p
                data-cy="consultationsTitle"
                style={{
                  borderLeft: '5px solid #5dd9c4',
                  paddingLeft: '20px',
                  fontSize: '20px',
                }}
              >
                {title}
              </p>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            {displayConsultations.length > 0 && (
              <TimelineComponent>
                {displayConsultations.map((consultation) => (
                  <TimelineCard
                    key={consultation.id}
                    id={consultation.id}
                    consultation={consultation}
                    therapistId={therapistId}
                  />
                ))}
              </TimelineComponent>
            )}
          </Card.Content>
        </Card>
      )}
    </div>
  );
};

export default TaskList;
